import { ContainerTypes } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MissingMiniMopItemsContext } from './machine';

type MissingMiniMopItemsServices = MachineOptions<
  MissingMiniMopItemsContext,
  any
>['services'];

export const missingMiniMopItemsServices: MissingMiniMopItemsServices = {
  fetchContainerType: async (_ctx, _event) => {
    const { data } = await api.get('/container-type/name', {
      params: {
        name: ContainerTypes.CUBETA
      }
    });
    return {
      containerType: data
    };
  },
  fetchPickItemCount: async (ctx, _event) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/pending-pick-items-splitted`,
      {}
    );
    return {
      pickItemsTotalCount:  data.totalItems,
      pickItemsPickedCount: data.pickedItems
    };
  },
  fetchNextPickItem: async (ctx, _event) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/next-pick-item`,
      {}
    );
    return {
      nextPickItem: data
    };
  },
  fetchPackingStation: async (ctx, _event) => {
    const { data } = await api.get(`/location/${ctx.packingStationId}`, {});
    return {
      packingStationLocation: data
    };
  },
  fetchDestinyContainer: async (ctx, _event) => {
    const { data } = await api.get(
      `/auditing-wave/${ctx.auditingWaveId}/target-container`,
      {}
    );
    return {
      destinyContainer: data
    };
  }
};

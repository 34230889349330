import { NoveltyMoveContainerToBufferItemStatuses } from '../NoveltyMoveContainerToBufferItem';
import { NoveltyMoveContainerToBufferItemStatus } from './NoveltyMoveContainerToBufferItemStatus';

export class NoveltyMoveContainerToBufferItemStatusMovedStatus extends NoveltyMoveContainerToBufferItemStatus {
  public displayName = 'Movido';

  public internalName = NoveltyMoveContainerToBufferItemStatuses.Moved;

  public statusColor = '#Ffffff';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../../modules/shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { ContainerMergeMachine } from './machine';

export const ContainerMergeComponent = withActorRef(ContainerMergeMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Fusión de contenedores{' '}
            {current.context.staging?.name
              ? `en canal ${current.context.staging!.name}`
              : ''}
          </Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.hasTag('loading') ? <Ink.Text> Cargando...</Ink.Text> : null}

          {current.matches('ScanningCheckDigit') ? (
            <>
              <GetLocationByCheckDigit />
            </>
          ) : null}

          {current.matches('SelectingStaging') ? (
            <>
              <Ink.Text>Escanee el dígito verificador de canal:</Ink.Text>
              <ScanControlDigit />
            </>
          ) : null}

          {current.matches('SelectingOriginContainer') ? (
            <>
              <GetContainerByLpn id='SelectingOriginContainer' />
              <Ink.Text bold color='redBright'>
                {current.context.errorMsg}
              </Ink.Text>
            </>
          ) : null}

          {current.matches('SelectingTargetContainer') ? (
            <>
              <GetContainerByLpn id='SelectingTargetContainer' />
            </>
          ) : null}

          {current.matches('ScanningItem') ? (
            <>
              <Ink.Text>Indique bulto/SKU a trasladar</Ink.Text>
              <ScanLabel />
              <Ink.Text bold color='redBright'>
                {current.context.errorMsg}
              </Ink.Text>
            </>
          ) : null}

          {current.matches('ScanningItemQuantity') ? (
            <>
              <Ink.Text>Indique la cantidad de bultos a trasladar</Ink.Text>
              <HighlightedInfo
                attribute='Bulto/SKU'
                value={current.context.stagingAuditingItemToMove?.label!}
              />

              <HighlightedInfo
                attribute='Cantidad en contenedor de origen'
                value={
                  current.context.stagingAuditingItemToMove?.inventoryItem
                    ?.quantity ||
                  current.context.stagingAuditingItemToMove!.expectedQuantity
                }
              />
              <EnterQuantity />
            </>
          ) : null}

          {current.matches('ConfirmingTargetContainer') ? (
            <>
              <HighlightedInfo
                attribute='Traslade bulto/SKU'
                value={current.context.stagingAuditingItemToMove?.label!}
              />
              <HighlightedInfo
                attribute='A Contenedor de destino'
                value={current.context.targetContainer?.lpn!}
              />
              <GetContainerByLpn id='ConfirmingTargetContainer' />
              <Ink.Text bold color='redBright'>
                {current.context.errorMsg}
              </Ink.Text>
            </>
          ) : null}

          {current.matches('FinishingMerge') ? (
            <>
              <Ink.Text>Movimiento realizado!</Ink.Text>
              <Ink.Text>¿Desea realizar otro movimiento de fusion?</Ink.Text>
              <Ink.Button onClick={() => send('CONTINUE')} label='SI' />

              <Ink.Button onClick={() => send('FINISH')} label='NO' />
            </>
          ) : null}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

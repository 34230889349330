import { DispatchingItem, DispatchingWave, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getPendingDispatchingWaveItemByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-pendingDispatchingItem-by-label';
import {
  GetItemByLabelMachine,
  GetItemByLabelMachineId
} from '../../layer-4/label/get-item-by-label/machine';
import { getRequestedCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-requested-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { dispatchSSActions } from './actions';
import { dispatchSSGuards } from './guards';
import { dispatchSSServices } from './services';

export interface DispatchSSContext {
  task: Task;
  dispatchingWaveId: number;
  stagingId: number;
  stagingName: string | null;
  nonReturnPoint: boolean;

  dispatchingWave: DispatchingWave | null;

  suggestedDispatchingItem: DispatchingItem | null;
  scannedLabel: string | null;

  pickedItems: number;
  totalItems: number;

  isPartiallyDispatch: boolean;

  error: string | null;

  touchedEndOfLoading: boolean;
}

export const DefaultDispatchSSContext: DispatchSSContext = {
  task:              {} as Task,
  stagingName:       null,
  dispatchingWaveId: -1,
  stagingId:         -1,
  nonReturnPoint:    false,

  dispatchingWave: null,

  suggestedDispatchingItem: null,
  scannedLabel:             null,

  pickedItems: 0,
  totalItems:  0,

  isPartiallyDispatch: false,

  touchedEndOfLoading: false,

  error: null
};

export const DispatchSSMachine = createMachine(
  {
    id:                         'DispatchSS',
    predictableActionArguments: true,
    schema:                     {
      context: {} as DispatchSSContext
    },
    initial: 'Initializing',
    states:  {
      Initializing: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchDispatchingWaveDetails',
          onDone: {
            target:  'ScanningCheckDigit',
            actions: 'assignDispatchingWaveDetails'
          }
        }
      },

      ScanningCheckDigit: {
        tags:   ['saveable'],
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Confirme Ingresando Digito de Control del Canal',
              getRequestedCheckDigit(
                'Ocurrió un error, intente más tarde.',
                'Ingrese el dígito de control requerido',
                ctx.dispatchingWave!.staging.checkDigit
              )
            ),
          onDone: {
            target:  'FetchingSuggestedDispatchingItem',
            actions: 'clearError'
          }
        }
      },

      FetchingSuggestedDispatchingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedDispatchingItem',
          onDone: [
            {
              cond:    'doesNotHaveSuggestedDispatchingItem',
              actions: ['assignItemCounts'],
              target:  'ConfirmingDispatch'
            },
            {
              target:  'ScanningDispatchingItem',
              actions: ['assignSuggestedDispatchingItem', 'assignItemCounts']
            }
          ]
        }
      },
      ScanningDispatchingItem: {
        tags:   ['saveable'],
        invoke: {
          id:  GetItemByLabelMachineId,
          src: ctx =>
            GetItemByLabelMachine(
              'Escanee etiqueta de contenedor',
              getPendingDispatchingWaveItemByLabel(
                'Ocurrió un error, por favor reintente más tarde.',
                ctx.dispatchingWaveId
              ),
              {
                canScanBuckets: true
              }
            ),
          onDone: {
            actions: ['assignScannedLabel', 'clearError'],
            target:  'MarkingItemAsDispatched'
          }
        }
      },
      MarkingItemAsDispatched: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingItemDispatched',
              payload: {
                label: ctx.scannedLabel
              }
            };
          },
          onDone: {
            target: 'FetchingSuggestedDispatchingItem'
          },
          onError: {
            target:  'FetchingSuggestedDispatchingItem',
            actions: 'assignError'
          }
        }
      },
      ConfirmingDispatch: {
        tags: ['saveable'],
        on:   {
          confirm: 'MarkingWaveAsCompleted'
        },
        entry: 'markAsNonReturningPoint'
      },
      MarkingWaveAsCompleted: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingWaveCompleted',
              payload: {
                dispatchingWaveId:   ctx.dispatchingWaveId,
                touchedEndOfLoading: ctx.touchedEndOfLoading
              }
            };
          },
          onDone: {
            target: 'PartiallyDispatching'
          },
          onError: {
            actions: 'assignError'
          }
        }
      },

      PartiallyDispatching: {
        initial: 'FetchingPartiallyDispatchedReservation',
        states:  {
          FetchingPartiallyDispatchedReservation: {
            tags:   ['loading'],
            invoke: {
              src:    'checkPartialDispatch',
              onDone: [
                {
                  cond:    'isPartiallyDispatch',
                  actions: 'assignIsPartiallyDispatch',
                  target:  'CreatePartiallyDispatchIncident'
                },
                {
                  target: 'FinishedPartiallyDispatch'
                }
              ],
              onError: {
                actions: 'assignError'
              }
            }
          },
          CreatePartiallyDispatchIncident: {
            invoke: {
              src:    'createPartiallyDispatchIncident',
              onDone: {
                target: 'FinishedPartiallyDispatch'
              }
            }
          },

          FinishedPartiallyDispatch: {
            type: 'final'
          }
        },
        onDone: 'Done'
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      partiallyDispatch: {
        actions: 'assignTouchedEndOfLoading',
        target:  'MarkingWaveAsCompleted'
      }
    }
  },
  {
    guards:  dispatchSSGuards,
    actions: {
      ...dispatchSSActions,
      triggerMenuScreen: (ctx, { triggerMenuScreen }: GoToOptionsEvent) => {
        const options: MenuItemProps[] = [];
        // if (!ctx.nonReturnPoint) {
        //   options.push({
        //     label:   'Fin de Carga',
        //     onClick: () => send('partiallyDispatch')
        //   });
        // }
        triggerMenuScreen(options);
      }
    },
    services: dispatchSSServices
  }
);

import { Container, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { TransferToPackingLocationActions } from './actions';
import { transferToPackingLocationGuards } from './guards';
import { transferToPackingLocationServices } from './services';

export interface MoveToPackingLocationContext {
  task: Task | null;

  container: Container | null;
  locationId: number;
}

export const DefaultMoveToPackingLocationContext: MoveToPackingLocationContext =
  {
    task: null,

    container:  null,
    locationId: -1
  };

export const MoveToPackingLocationMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5gF8A0IB2B7CdGgFkBLDIgBSIGMBrEqAdQEMA3MfEABy1iIBcisGdgA9EAWgBs6AJ7iArADoAnCtVq1AZmRoQxUhRp0mrBQAksAGyLwknbnwFDbohABYATDMQAOAIwLXAHYlX1c5QIkABg0NQLklbXQ9cipaDAYWMAUAYQALRgBXdi4efkERRA8vBF8NJWUJb0DfJVcguTlXb21tIA */
    id:                         'MiniMoveToPackingLocation',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MoveToPackingLocationContext
    },
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:   ScanContainerMachine.id,
          src:  ScanContainerMachine,
          data: (_ctx, _ev) => ({
            ...DefaultScanContainerContext
          }),
          onDone: [
            {
              target:  'ScanningLocation',
              actions: 'assignContainer'
            }
          ]
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee la ubicacion de destino',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            target:  'ConfirmingMovement',
            actions: 'assignLocationId'
          }
        }
      },
      ConfirmingMovement: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniPackingLocationItemMoved',
              payload: {
                label:      ctx.container?.lpn,
                locationId: ctx.locationId
              }
            };
          },
          onDone: {
            target: 'MoveConfirmed'
          },
          onError: {
            target: 'Error'
          }
        }
      },
      MoveConfirmed: {
        on: {
          confirm: 'Done'
        }
      },
      Done: {
        type: 'final'
      },
      Error: {}
    }
  },
  {
    guards:   transferToPackingLocationGuards,
    actions:  TransferToPackingLocationActions,
    services: transferToPackingLocationServices
  }
);

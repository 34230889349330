import {
  ContainerTypes,
  Location,
  NoveltyUnloadingContainer,
  Product,
  ProductAttributeVolume,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';

import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { GoToOptionsEvent } from '../../core/GenericOptions';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-any-product-by-sku';
import { getExistingProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-existing-product-by-sku';
import { GetProductBySKUMachine } from '../../layer-4/product/get-product-by-sku/machine';
import { noveltyUnloadingMachineActions } from './actions';
import { noveltyUnloadingMachineGuards } from './guards';
import { noveltyUnloadingMachineServices } from './services';

export interface NoveltyUnloadingContext {
  task: Task;
  noveltyUnloadingProcessId: number;
  stagingLocation: Location | null;
  productScanned: Product | null;
  noveltyProductType: string | null;
  noveltyContainer: NoveltyUnloadingContainer | null;
  noveltyContainerLpnToCreate: string | null;
  productsUnloaded: number;
  totalProductsToUnload: number;
  targetLocation: Location | null;
  error: string | null;
}

export enum NoveltyUnloadingContainerCreationSteps {
  Apt = 0,
  NotApt = 1,
  SelectingType = 2
}

export const DefaultNoveltyUnloadingContext: NoveltyUnloadingContext = {
  task:                        {} as Task,
  noveltyUnloadingProcessId:   -1,
  stagingLocation:             null,
  productScanned:              null,
  noveltyProductType:          null,
  noveltyContainer:            null,
  noveltyContainerLpnToCreate: null,
  productsUnloaded:            0,
  totalProductsToUnload:       0,
  targetLocation:              null,
  error:                       null
};

export const NoveltyUnloadingMachine = createMachine(
  {
    id:      'NoveltyUnloading',
    initial: 'FetchingStagingLocation',
    context: {} as NoveltyUnloadingContext,
    states:  {
      FetchingStagingLocation: {
        invoke: {
          src:    'fetchStagingLocation',
          onDone: {
            target:  'ScanningControlDigit',
            actions: 'assignStagingLocation'
          },
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      ScanningControlDigit: {
        invoke: {
          src:  ScanControlDigitMachine,
          id:   ScanControlDigitMachine.id,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanee digito de control',
            requiredLocation: ctx.stagingLocation!
          }),
          onDone: {
            target:  'FetchingNoveltySKUCount',
            actions: ['clearError']
          }
        }
      },
      FetchingNoveltySKUCount: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNoveltySKUCount',
          onDone: [
            {
              cond:    'hasNoveltySKUToUnload',
              target:  'ScanningSKU',
              actions: ['assignTotalProductsToUnload', 'assignProductsUnloaded']
            },
            {
              target: 'ConfirmingScreen'
            }
          ],
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      ScanningSKU: {
        invoke: {
          id:  'ScanningSKU',
          src: () =>
            GetProductBySKUMachine(
              'Confirme escaneando SKU del producto',
              getAnyProductBySKU(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: {
            target:  'CheckingIfSKUIsInNovelty',
            actions: ['assignProductScanned', 'clearError']
          }
        }
      },
      CheckingIfSKUIsInNovelty: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfSKUInNovelty',
          onDone: [
            {
              cond:   'isSKUInNovelty',
              target: 'SelectingIfIsAptOrNotApt'
            },
            {
              target:  'ScanningSKU',
              actions: 'assignErrorIsNotInNovelty'
            }
          ],
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      SelectingIfIsAptOrNotApt: {
        on: {
          SELECTED: {
            target:  'FetchingNoveltyContainer',
            actions: 'assignNoveltyProductType'
          }
        }
      },
      FetchingNoveltyContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNoveltyContainer',
          onDone: [
            {
              cond:    'hasNoveltyContainerToUnload',
              target:  'ScanningNoveltyContainer',
              actions: ['assignNoveltyContainer', 'assignTargetLocation']
            },
            {
              target:  'ScanningNoveltyContainerToCreate',
              actions: 'assignTargetLocation'
            }
          ],
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      ScanningNoveltyContainerToCreate: {
        invoke: {
          id:   EnterLpnMachine.id,
          src:  EnterLpnMachine,
          data: ctx => ({
            ...DefaultEnterLpnContext,
            hint:              'Escanee el contenedor de novedades a crear',
            containerTypeName:
              ctx.productScanned!.sizeType === ProductAttributeVolume.MIDI
                ? ContainerTypes.PALLETARLOG
                : ContainerTypes.ROLLCONTAINER
          }),
          onDone: {
            target:  'CreatingNoveltyContainer',
            actions: 'assignNoveltyContainerLPNToCreate'
          }
        }
      },
      CreatingNoveltyContainer: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'NoveltyCreateContainer',
              payload: {
                containerLpn:      ctx.noveltyContainerLpnToCreate,
                locationId:        ctx.stagingLocation!.id,
                typeApt:           ctx.noveltyProductType,
                targetSectorId:    ctx.targetLocation?.id,
                containerTypeName:
                  ctx.productScanned!.sizeType === ProductAttributeVolume.MIDI
                    ? ContainerTypes.PALLETARLOG
                    : ContainerTypes.ROLLCONTAINER,
                noveltyUnloadingProcessId: ctx.noveltyUnloadingProcessId
              }
            };
          },
          onDone:  'FetchingNoveltyContainer',
          onError: {
            target:  'ScanningNoveltyContainerToCreate',
            actions: 'assignError'
          }
        }
      },
      ScanningNoveltyContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor',
              getRequestedContainerByLpn(
                'Ocurrio un error, por favor intente nuevamente',
                'El contenedor es invalido',
                ctx.noveltyContainer!.container.lpn
              )
            ),
          onDone:  'ConfirmingSKUInContainer',
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      ConfirmingSKUInContainer: {
        invoke: {
          id:  'ConfirmingSKUInContainer',
          src: ctx =>
            GetProductBySKUMachine(
              'Confirme escaneando SKU del producto',
              getExistingProductBySKU(
                'Ocurrió un error, por favor reintente más tarde.'
              ),
              [ctx.productScanned!.sku]
            ),
          onDone: {
            target:  'SendingProcessingUnloadedItem',
            actions: ['clearError']
          }
        }
      },
      SendingProcessingUnloadedItem: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'NoveltyUnloadItem',
              payload: {
                sku:          ctx.productScanned!.sku,
                containerLpn:
                  ctx.noveltyContainer?.container.lpn ||
                  ctx.noveltyContainerLpnToCreate,
                noveltyUnloadingProcessId: ctx.noveltyUnloadingProcessId
              }
            };
          },
          onDone: {
            target:  'FetchingNoveltySKUCount',
            actions: 'clearCtx'
          },
          onError: {
            target:  'ScanningSKU',
            actions: ['assignError']
          }
        }
      },
      ConfirmingScreen: {
        on: {
          CONFIRMED: 'SendingCompletingUnloadingProcess'
        }
      },
      SendingCompletingUnloadingProcess: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'NoveltyUnloadingCompleteTask',
              payload: {
                taskId:                    ctx.task!.id,
                noveltyUnloadingProcessId: ctx.noveltyUnloadingProcessId
              }
            };
          },
          onDone:  'Done',
          onError: {
            target:  'ScanningSKU',
            actions: 'assignError'
          }
        }
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      CREATING_NOVELTY_UNLOADING_CONTAINER: {
        target:  'ScanningNoveltyContainerToCreate',
        actions: 'clearError'
      },
      SELECTING_NOVELTY_TYPE: {
        target:  'SelectingIfIsAptOrNotApt',
        actions: 'clearError'
      }
    }
  },
  {
    actions: {
      ...noveltyUnloadingMachineActions,

      triggerMenuScreen: (
        ctx,
        { send, state, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        triggerMenuScreen([
          ...(state.matches('ScanningNoveltyContainer')
            ? [
                {
                  label:   'Crear nuevo contenedor',
                  onClick: () => send('CREATING_NOVELTY_UNLOADING_CONTAINER')
                }
              ]
            : []),
          ...(state.matches('ScanningNoveltyContainer') ||
          state.matches('ScanningNoveltyContainerToCreate')
            ? [
                {
                  label:   'Volver a elegir tipo de contenedor',
                  onClick: () => send('SELECTING_NOVELTY_TYPE')
                }
              ]
            : [])
        ]);
      }
    },
    services: noveltyUnloadingMachineServices,
    guards:   noveltyUnloadingMachineGuards
  }
);

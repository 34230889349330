import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusNotDispatchedStatus extends OrderItemStatus {
  public displayName = 'No despachado';

  public internalName = OrderItemStatuses.NotDispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

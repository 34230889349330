import { DispatchingProcessStatuses } from '../DispatchingProcess';
import { DispatchingProcessStatus } from './DispatchingProcessStatus';

export class DispatchingProcessCompletedStatus extends DispatchingProcessStatus {
  public displayName = 'Completado';

  public internalName = DispatchingProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

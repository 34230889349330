import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { ClassificationContainer } from './ClassificationContainer';
import { Driver } from './Driver';
import { Location } from './Location';
import { Order } from './Order';
import { Receipt } from './Receipt';

@Entity()
export class Route {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'route_type' })
  routeType: string;

  @Field({ name: 'shift_id' })
  shiftId: number;

  @Field()
  pcg?: number;

  @Field({ name: 'courier_center_code' })
  courierCenterCode: string;

  @Field({ name: 'wms_route_id' })
  wmsRouteId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Driver, { name: 'driver_id' })
  driverId: number;

  @Knows(() => Driver, 'driverId')
  driver: Driver;

  @ForeignKey(() => Driver, { name: 'co_driver_id' })
  coDriverId: number;

  @Knows(() => Driver, 'coDriverId')
  coDriver: Driver;

  @Knows(() => Order, 'routeId')
  order: Order;

  @Knows(() => Receipt, 'routeId')
  receipt: Receipt;

  @Knows(() => Location, 'routeId')
  location: Location;

  @Knows(() => ClassificationContainer, 'routeId')
  classificationContainer: ClassificationContainer;
}

import { OrderType, OrderTypes } from '@wms/domain';
import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MiniPackingWaveContext } from './machine';

type MiniPackingWaveActions = MachineOptions<
  MiniPackingWaveContext,
  any
>['actions'];

export const miniPackingWaveActions: MiniPackingWaveActions = {
  assignProduct: assign({
    product: (_ctx, event) => event.data
  }),
  assignPackingItem: assign({
    currentPackingItem: (_ctx, event) => event.data.packingItem
  }),
  assignSuggestedPackageType: assign({
    suggestedPackageType: (_ctx, event) => event.data.packType
  }),
  assignPackageType: assign({
    selectedPackageType: (_ctx, event) => event.data.packType
  }),
  assignContainer: assign({
    container: (_ctx, event) => event.data
  }),
  assignLabel: assign({
    label: (_ctx, event) => event.data.label
  }),
  assignOtherPackageType: assign({
    otherPackageTypes: (_ctx, event) => event.data.packTypes
  }),
  assignReason: assign({
    reason: (_ctx, event) => event.data.reason
  }),
  assignSKUError: assign({
    error: (_ctx, event) => event.data.response.data.errorView
  }),
  assignLabelError: assign({
    error: (_ctx, event) => getErrorView(event.data?.data || event.data)
  }),
  clearPackingCtx: assign({
    currentPackingItem:   (_, __) => null,
    label:                (_, __) => null,
    product:              (_, __) => null,
    selectedPackageType:  (_, __) => null,
    suggestedPackageType: (_, __) => null,
    error:                (_, __) => null
  }),
  clearErrorCtx: assign({
    error: (_, __) => null
  }),
  assignCourierType: assign({
    labelCourier: (ctx, _event) => {
      const orderTypeName = ctx.currentPackingItem?.orderItem.order.orderType
        .name as OrderTypes;

      return OrderType.getCourier(orderTypeName);
    }
  }),
  assignTypeHD: assign({
    typeOfLabel: () => 'hd'
  }),
  assignTypeSS: assign({
    typeOfLabel: () => 'ss'
  }),
  assignPendingItemsCount: assign({
    pendingItems: (_ctx, evt) => evt.data.count,
    totalItems:   (_ctx, evt) => evt.data.total
  })
};

import { ReceiptBatchStatuses } from '../ReceiptBatch';
import { ReceiptBatchStatus } from './ReceiptBatchStatus';

export class ReceiptBatchStoringStatus extends ReceiptBatchStatus {
  public displayName = 'Almacenado';

  public internalName = ReceiptBatchStatuses.Storing;

  public statusColor = '#fbe7d9';

  public textColor = '#ea881b';
}

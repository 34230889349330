import { StandardUnloadingProcessStatuses } from '../StandardUnloadingProcess';
import { StandardUnloadingProcessStatus } from './StandardUnloadingProcessStatus';

export class StandardUnloadingProcessStatusCancelledStatus extends StandardUnloadingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = StandardUnloadingProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerQuantityMismatchedStatus extends StagingAuditingContainerStatus {
  public displayName = 'Auditado con descuadres';

  public internalName = StagingAuditingContainerStatuses.QuantityMismatched;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

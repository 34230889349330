import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { ProductDetailContext } from './machine';

type ProductDetailActionsType = MachineOptions<
  ProductDetailContext,
  any
>['actions'];

export const ProductDetailActions: ProductDetailActionsType = {
  assignProduct: assign({
    product: (_ctx, event) => {
      return event.data;
    }
  }),
  assignInventoryItems: assign({
    inventoryItems: (_ctx, event) => {
      return event.data.inventoryItems;
    }
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return (
        getErrorView(event.data ?? event.data.data) ||
        'Ha ocurrido un error al buscar el detalle de la Etiqueta'
      );
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => {
      return null;
    }
  }),
  assingFethProductAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningSKU'
      };
    }
  }),
  restartValues: assign({
    product:        null,
    inventoryItems: [],
    error:          null
  })
};

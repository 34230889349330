import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByCheckDigitFn } from '../machine';

export function getNoSortableSlottingLocationByCheckDigit(
  defaultErrorMessage: string,
  noSortableSlottingProcessId: number
): GetLocationByCheckDigitFn {
  return fetchResult(defaultErrorMessage, checkDigit =>
    api.get<Location>(
      `no-sortable-slotting-process/${noSortableSlottingProcessId}/validate-staging-check-digit`,
      {
        params: {
          checkDigit
        }
      }
    )
  );
}

import {
  Container,
  Location,
  Product,
  RestockingItem,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import { getStorageLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-storage-location-by-name';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { palletRestockingTaskActions } from './actions';
import { palletRestockingTaskGuards } from './guards';
import { palletRestockingTaskServices } from './services';

export interface PalletRestockingTaskContext {
  task: Task;
  restockingItemId: number;
  restockingItem: RestockingItem;
  originLocation: Location | null;
  location: Location | null;
  container: Container | null;
  restockedQuantity: number;
  error: string | null;
  flagOriginLocation: boolean;
  product: Product | null;
  restockingRuleNotMetFlag: boolean;
}

export const DefaultPalletRestockingTaskContext: PalletRestockingTaskContext = {
  task:                     {} as Task,
  restockingItemId:         -1,
  restockingItem:           {} as RestockingItem,
  originLocation:           null,
  container:                null,
  location:                 null,
  restockedQuantity:        -1,
  error:                    null,
  flagOriginLocation:       false,
  restockingRuleNotMetFlag: false,
  product:                  {} as Product
};

export const PalletRestockingTaskMachine = createMachine(
  {
    id:                         'PalletRestockingTask',
    predictableActionArguments: true,
    schema:                     {
      context: {} as PalletRestockingTaskContext
    },
    initial: 'Initializing',
    states:  {
      Initializing: {
        entry:  ['setTask', 'clearError'],
        invoke: {
          src:    'getRestockingItem',
          onDone: {
            target:  'GetSuggestedOriginLocationMeetingRestockingRule',
            actions: ['setRestockingItem']
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },
      GetSuggestedOriginLocationMeetingRestockingRule: {
        invoke: {
          src:    'getSuggestedOrigingLocation',
          onDone: [
            {
              cond:    'isOriginLocationRequired',
              actions: ['setOriginLocation'],
              target:  'ScanOriginLocationRequired'
            },
            {
              actions: ['setOriginLocation'],
              target:  'ScanOriginLocation'
            }
          ],
          onError: [
            {
              cond:   'isNotFoundException',
              target: 'NoRestockingLocationSatisfiesRule'
            },
            {
              actions: 'assignError',
              target:  'Error'
            }
          ]
        }
      },
      NoRestockingLocationSatisfiesRule: {
        on: {
          CONTINUE: {
            target:  'GetAnySuggestedOriginLocation',
            actions: 'setRestockingRuleNotMetFlag'
          }
        }
      },
      GetAnySuggestedOriginLocation: {
        invoke: {
          src:    'getAnySuggestedOriginLocation',
          onDone: [
            {
              cond:    'isOriginLocationRequired',
              actions: ['setOriginLocation'],
              target:  'ScanOriginLocationRequired'
            },
            {
              actions: ['setOriginLocation'],
              target:  'ScanOriginLocation'
            }
          ],
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },
      ScanOriginLocationRequired: {
        tags:   ['location'],
        invoke: {
          id:  'ScanOriginLocationRequired',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion origen',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'Por favor, Escanee la ubicacion requerida',
                ctx.originLocation!.name
              ),
              {
                name:     ctx.originLocation!.name,
                hint:     'UBI. REQUERIDA',
                required: true
              }
            ),
          onDone: {
            actions: ['assignOriginLocation', 'clearError'],
            target:  'UpdateRestockingItem'
          }
        }
      },
      ScanOriginLocation: {
        tags:   ['location'],
        invoke: {
          id:  'ScanOriginLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion origen',
              getStorageLocationByName(
                'Ocurrió un error, por favor reintente más tarde.'
              ),
              {
                name:     ctx.originLocation!.name,
                hint:     'UBI. SUGERIDA',
                required: false
              }
            ),
          onDone: [
            {
              actions: ['assignOriginLocation', 'clearError'],
              cond:    'restockingRuleNotMetFlag',
              target:  'UpdateRestockingItem'
            },
            {
              target:  'ValidateOriginLocation',
              actions: ['assignOriginLocation', 'clearError']
            }
          ]
        }
      },
      FetchOriginContainer: {
        invoke: {
          src:    'getContainerInOrigingLocation',
          onDone: [
            {
              actions: ['setOriginContainer'],
              target:  'ScanOriginContainer'
            }
          ],
          onError: [
            {
              actions: 'assignError',
              target:  'GetSuggestedOriginLocationMeetingRestockingRule'
            }
          ]
        }
      },
      ValidateOriginLocation: {
        invoke: {
          src:    'validateOriginLocation',
          onDone: {
            target: 'UpdateRestockingItem'
          },
          onError: {
            actions: 'assignError',
            target:  'GetSuggestedOriginLocationMeetingRestockingRule'
          }
        }
      },
      UpdateRestockingItem: {
        invoke: {
          src:    'updateRestockingItem',
          onDone: {
            target: 'FetchOriginContainer'
          },
          onError: {
            actions: 'assignError',
            target:  'GetSuggestedOriginLocationMeetingRestockingRule'
          }
        }
      },
      ScanOriginContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanear contenedor',
              getRequestedContainerByLpn(
                'Ocurrion un error, por favor intente de nuevo',
                'El contenedor escaneado es invalido',
                ctx.container!.lpn
              ),
              {
                lpn:  ctx.container!.lpn,
                hint: 'CONT. REQUERIDO '
              }
            ),
          onDone: [
            {
              actions: 'assignOriginContainer',
              target:  'ScanTargetLocation'
            }
          ]
        }
      },
      ScanTargetLocation: {
        invoke: {
          id:  'ScanTargetLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion destino',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'Por favor, Escanee la ubicacion requerida',
                ctx.location!.name
              ),
              {
                name:     ctx.location!.name,
                hint:     'UBI. REQUERIDA',
                required: true
              }
            ),
          onDone: {
            actions: 'assignLocation',
            target:  'CheckAvailableLocation'
          }
        }
      },
      CheckAvailableLocation: {
        invoke: {
          src:    'checkLocationAvailable',
          onDone: [
            {
              cond:   'locationAvailable',
              target: 'CanMoveToLocation'
            },
            {
              target:  'ScanTargetLocation',
              actions: ['assignBlockedLocationError']
            }
          ],
          onError: {
            target:  'ScanTargetLocation',
            actions: ['assignError']
          }
        }
      },
      CanMoveToLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfCanMoveToLocation',
          onDone: [
            {
              // cond:    'canMoveToLocation', @Comment this condition until REFACTOR CONTAINER IN PICKTOWER
              actions: 'clearError',
              target:  'MoveContainer'
            },
            {
              actions: 'assignMaxNumberOfContainersExceededError',
              target:  'ScanTargetLocation'
            }
          ],
          onError: [
            {
              actions: 'assignError',
              target:  'ScanTargetLocation'
            }
          ]
        }
      },
      MoveContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'moveContainer',
          onDone: {
            target:  'Complete',
            actions: 'addToRestockedItems'
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },
      Complete: {
        tags:   ['loading'],
        invoke: {
          src:    'completeRestockingTask',
          onDone: {
            target: 'RestockComplete'
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },
      Cancel: {
        tags:   ['loading'],
        invoke: {
          src:    'cancelRestockingTask',
          onDone: {
            target: 'Done'
          },
          onError: {
            actions: 'assignError',
            target:  'Error'
          }
        }
      },
      RestockComplete: {
        on: {
          CONFIRM: 'Done'
        }
      },

      Error: {
        on: {
          CANCEL:      'Cancel',
          RECALCULATE: 'Initializing'
        }
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      markAsCancel: {
        target: 'Cancel'
      }
    }
  },
  {
    guards:  palletRestockingTaskGuards,
    actions: {
      ...palletRestockingTaskActions,
      triggerMenuScreen: (
        ctx,
        { send, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        const options: MenuItemProps[] = [];
        options.push({
          label:   'Cancelar Restocking',
          onClick: () => send('markAsCancel')
        });
        triggerMenuScreen(options);
      }
    },
    services: palletRestockingTaskServices
  }
);

/* eslint-disable @typescript-eslint/no-unused-vars */
import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MissingMiniMopItemsMachine } from './machine';

export const MissingMiniMopItemsTask = withActorRef(MissingMiniMopItemsMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        <Ink.Box flexDirection='column' paddingX={1}>
          <Ink.Header>
            Pickeo faltantes Mini Mop Nº{' '}
            {current.context.mopAuditingPickingWaveId}
          </Ink.Header>
          {/* Header */}
          <Ink.Box flexDirection='column'>
            <>
              {current.hasTag('loading') && (
                <Ink.Box flexDirection='column'>
                  <Ink.Text>Cargando... </Ink.Text>
                </Ink.Box>
              )}

              {current.matches('CreatingContainer') && (
                <>
                  <CreateContainer />
                </>
              )}

              {current.matches('ScanningPickingLocation') && (
                <>
                  <Ink.Text>
                    SKU {current.context.pickItemsPickedCount! + 1} de{' '}
                    {current.context.pickItemsTotalCount}
                  </Ink.Text>
                  <Ink.Text> </Ink.Text>
                  <IntercalatedText
                    texts={['SKU', '-']}
                    values={[
                      current.context.nextPickItem!.sku,
                      current.context.nextPickItem!.inventoryItem!.product!.name
                    ]}
                  />

                  <HighlightedInfo
                    attribute='Ubicacion de picking'
                    value={
                      current.context.nextPickItem!.inventoryItem!.location!
                        .name
                    }
                  />
                  <GetLocationByName id='ScanningPickingLocation' />

                  {current.context.error ? (
                    <ErrorMessage error={current.context.error} />
                  ) : null}
                </>
              )}

              {current.matches('EnteringPickingQuantity') && (
                <>
                  <Ink.Text>
                    SKU {current.context.pickItemsPickedCount! + 1} de{' '}
                    {current.context.pickItemsTotalCount}
                  </Ink.Text>
                  <Ink.Text> </Ink.Text>
                  <IntercalatedText
                    texts={['SKU', '-']}
                    values={[
                      current.context.nextPickItem!.sku,
                      current.context.nextPickItem!.inventoryItem!.product!.name
                    ]}
                  />
                  <HighlightedInfo
                    attribute='Cantidad'
                    value={current.context.nextPickItem!.quantity}
                  />
                  <EnterQuantity />
                </>
              )}

              {current.matches('ScanningDestinyLocation') && (
                <>
                  <HighlightedInfo
                    attribute='Escanee ubicacion de destino'
                    value={current.context.packingStationLocation!.name}
                  />
                  <GetLocationByName id='ScanningDestinyLocation' />
                  {current.context.error ? (
                    <ErrorMessage error={current.context.error} />
                  ) : null}
                </>
              )}

              {current.matches('ScanningDestinyContainer') && (
                <>
                  <HighlightedInfo
                    attribute='Escanee contenedor de destino'
                    value={current.context.destinyContainer!.lpn}
                  />

                  <GetContainerByLpn />
                </>
              )}
            </>
          </Ink.Box>
        </Ink.Box>
      </>
    );
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  OwnedBy,
  PrimaryKey
} from '@skyframe/core';
import { ReceiptItem } from './ReceiptItem';
import { ReceiptRejectionReport } from './ReceiptRejectionReport';

@Entity()
export class ReceiptRejectionReportItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => ReceiptRejectionReport, {
    name: 'receipt_rejection_report_id'
  })
  receiptRejectionReportId: number;

  @ForeignKey(() => ReceiptItem, {
    name: 'receipt_item_id'
  })
  receiptItemId: number;

  @Field({ name: 'rejected_quantity' })
  rejectedQuantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @OwnedBy(() => ReceiptRejectionReport, 'receiptRejectionReportId')
  receiptRejectionReport: ReceiptRejectionReport;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;
}

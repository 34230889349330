import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { Incident } from './Incident';
import { InventoryItem } from './InventoryItem';
import { Lot } from './Lot';
import { ReceiptItem } from './ReceiptItem';
import { RejectionReason } from './RejectionReason';
import {
  PutAwayItemQualityStatus,
  PutAwayItemQualityStatusAnomalyStatus,
  PutAwayItemQualityStatusNormalStatus
} from './put-away-item-quality-status';
import {
  PutAwayItemStatus,
  PutAwayItemStatusConfirmedStatus,
  PutAwayItemStatusCosolidatedStatus,
  PutAwayItemStatusMissingStatus,
  PutAwayItemStatusPreparedStatus,
  PutAwayItemStatusReceivedStatus,
  PutAwayItemStatusRejectedStatus
} from './put-away-item-status';

export const PutAwayItemStatuses = {
  Prepared:     'prepared',
  Received:     'received',
  Confirmed:    'confirmed',
  Consolidated: 'consolidated',
  Missing:      'missing',
  Rejected:     'rejected',
  Stored:       'stored'
};

export const PutAwayItemQualityStatuses = {
  Normal:  'normal',
  Anomaly: 'anomaly'
};

export const AcceptedPutAwayItemStatuses = [
  PutAwayItemStatuses.Prepared,
  PutAwayItemStatuses.Received,
  PutAwayItemStatuses.Confirmed,
  PutAwayItemStatuses.Consolidated,
  PutAwayItemStatuses.Stored
];

@Entity()
export class PutAwayItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @ForeignKey(() => Lot, { name: 'lot_id' })
  lotId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'received_quantity' })
  receivedQuantity: number;

  @Field({ name: 'packages_quantity' })
  packagesQuantity: number;

  @Field({ name: 'unit_quantity_per_package' })
  unitQuantityPerPackage: number;

  @Status({
    [PutAwayItemStatuses.Prepared]:     PutAwayItemStatusPreparedStatus,
    [PutAwayItemStatuses.Received]:     PutAwayItemStatusReceivedStatus,
    [PutAwayItemStatuses.Confirmed]:    PutAwayItemStatusConfirmedStatus,
    [PutAwayItemStatuses.Consolidated]: PutAwayItemStatusCosolidatedStatus,
    [PutAwayItemStatuses.Missing]:      PutAwayItemStatusMissingStatus,
    [PutAwayItemStatuses.Rejected]:     PutAwayItemStatusRejectedStatus
  })
  status: PutAwayItemStatus;

  @Status(
    {
      [PutAwayItemQualityStatuses.Normal]:  PutAwayItemQualityStatusNormalStatus,
      [PutAwayItemQualityStatuses.Anomaly]:
        PutAwayItemQualityStatusAnomalyStatus
    },
    { name: 'quality_status' }
  )
  qualityStatus: PutAwayItemQualityStatus;

  @Field({ name: 'status_description' })
  statusDescription: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Knows(() => Lot, 'lotId')
  lot: Lot;

  @Knows(() => Container, 'containerId')
  container: Container;

  public get sku() {
    return this.receiptItem && this.receiptItem.sku;
  }

  public get quantity() {
    return this.receiptItem && this.receiptItem.quantity;
  }

  public set quantity(qty) {
    this.receivedQuantity = qty;
  }

  public get product() {
    return this.receiptItem && this.receiptItem.product;
  }

  @Knows(() => Incident, 'putAwayItemId')
  incidents: Incident[];

  @ForeignKey(() => RejectionReason, { name: 'rejection_reason_id' })
  rejectionReasonId: number;

  @Knows(() => RejectionReason, 'rejectionReasonId')
  rejectionReason: RejectionReason;
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { MiniPickingWaveMachine } from './machine';

export const MiniPickingWaveTask = withActorRef(MiniPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    const QuantityLeft =
      (current.context.currentPickItem?.quantity as number) -
      current.context.pickedQuantity;
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Ola N° {current.context.pickingWaveId || 'N/A'} - HD - Mezzanine
            Nivel {current.context.level}
          </Ink.Text>
        </Ink.Header>
        {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}
        {current.matches('SelectingContainerType') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Text>Tome Contenedor</Ink.Text>
              <Ink.Text>Se Sugiere contenedor tipo</Ink.Text>
              <Ink.Text>
                {' '}
                {current.context.suggestedContainerType?.name}{' '}
              </Ink.Text>

              {current.context.containerTypes?.map(item => (
                <Ink.Button
                  key={item.id}
                  onClick={() => {
                    send({
                      type:          'containerTypeSelected',
                      containerType: item
                    });
                  }}
                  label={item.name}
                />
              ))}
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ScanningContainer') ? (
          <>
            <EnterLpn />
          </>
        ) : null}

        {current.matches('CreatingContainer') ? (
          <>
            <CreateContainer />
          </>
        ) : null}

        {current.matches('ScanningLocation') ? (
          <>
            <Ink.Box flexDirection='column'>
              <HighlightedInfo
                attribute='Cubeta'
                value={current.context.createdContainer!.lpn}
              />
              <GetLocationByName />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('AwaitingRestocking') && (
          <>
            <Ink.Text> </Ink.Text>
            <Ink.Text>El item actual está esperando ser restockeado</Ink.Text>
          </>
        )}

        {current.matches('OpportunisticCounting') && (
          <>
            <Ink.Text bold>Confirme cantidad restante en ubicación</Ink.Text>
            <Ink.Chalk greenBright bold>
              {`${current.context.currentPickItem!.sku} - ${
                current.context.currentPickItem!.product.name
              }`}
            </Ink.Chalk>
            <HighlightedInfo
              attribute='Ubicación'
              value={
                current.context.currentPickItem!.pickingLocation.name || 'N/A'
              }
            />
            <Ink.Text> </Ink.Text>
            <EnterQuantity />
          </>
        )}

        {current.matches('ScanningProduct') ? (
          <>
            <Ink.Box flexDirection='column'>
              <HighlightedInfo
                attribute='Cubeta'
                value={current.context.createdContainer!.lpn}
              />
              <HighlightedInfo
                attribute='Ubicación'
                value={current.context.currentPickItem!.pickingLocation!.name}
              />
              <HighlightedInfo
                attribute='Cantidad a Pickear'
                value={current.context.currentPickItem!.quantity}
              />
              <HighlightedInfo attribute='Restantes' value={QuantityLeft} />
              <HighlightedInfo
                attribute='Escanee SKU del producto'
                value={current.context.currentPickItem!.sku}
              />
              <Ink.Chalk bold color='greenBright'>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
              <GetProductBySKU />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ConfirmingContainer') ? (
          <>
            <Ink.Box flexDirection='column'>
              <HighlightedInfo
                attribute='Ubicación'
                value={
                  current.context.currentPickItem?.pickingLocation!.name ??
                  'N/A'
                }
              />
              <IntercalatedText
                texts={['Deposite', 'unidad/es de']}
                values={[current.context.currentPickItem?.quantity ?? 0]}
              />
              <HighlightedInfo
                attribute='SKU'
                value={current.context.currentPickItem!.sku}
              />
              <Ink.Chalk bold color='greenBright'>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
              <HighlightedInfo
                attribute='En la cubeta'
                value={current.context.createdContainer?.lpn ?? 'N/A'}
              />
              <ScanContainer />
              <Ink.Button
                label='Espacio insuficiente en cubeta'
                onClick={() => send('closeContainer')}
              />
            </Ink.Box>
          </>
        ) : null}

        {current.matches({
          PrematurelyCloseContainer: 'ConfirmCloseEmptyContainer'
        }) ? (
          <>
            <Ink.Text color='orangeBright' bold>
              Cerrando cubeta prematuramente por espacio insuficiente
            </Ink.Text>
            <Ink.Text>
              La cubeta que está queriendo cerrar está vacía. No se puede cerrar
              prematuramente una cubeta sin haber pickeado ningún item con la
              cubeta.
            </Ink.Text>
            <Ink.Text>
              Si la cubeta tenía items de antemano, contacte a soporte.
            </Ink.Text>
            <Ink.Button
              label='Cancelar cierre de cubeta'
              onClick={() => send('confirm')}
            />
          </>
        ) : null}

        {current.matches({
          PrematurelyCloseContainer: 'ScanningOldContainer'
        }) ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Box marginBottom={2} flexDirection='column'>
                <Ink.Chalk bold color='orangeBright'>
                  Cerrando cubeta prematuramente por espacio insuficiente
                </Ink.Chalk>
                <Ink.Text bold>
                  Acerquese a la zona del sorter con el contendor, escaneelo y
                  depositelo sobre la cinta.
                </Ink.Text>
                <Ink.Text>
                  Recuerde dejar el item que estaba pickeando en la ubicación
                  original
                </Ink.Text>
                <HighlightedInfo
                  attribute='Ubicación'
                  value={
                    current.context.currentPickItem?.pickingLocation.name ||
                    'N/A'
                  }
                />
              </Ink.Box>
              <HighlightedInfo
                attribute='LPN'
                value={current.context.createdContainer!.lpn}
              />
              <ScanContainer />
              <Ink.Button
                label='Cancelar cierre'
                onClick={() => send('cancelCloseContainer')}
              />
            </Ink.Box>
          </>
        ) : null}

        {current.matches({
          PrematurelyCloseContainer: 'EnteringNewContainer'
        }) ? (
          <>
            <Ink.Text color='greenBright' bold>
              Creacion de cubeta nueva
            </Ink.Text>
            <Ink.Text>
              Ahora busque una nueva cubeta y vuelva a la ubicación del picking
              donde dejó el item con ella.
            </Ink.Text>
            <HighlightedInfo
              attribute='Ubicación'
              value={
                current.context.currentPickItem?.pickingLocation.name || 'N/A'
              }
            />
            <EnterLpn />
          </>
        ) : null}

        {current.matches('ScanningContainerToSorter') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Box marginBottom={2} flexDirection='column'>
                <Ink.Chalk bold color='greenBright'>
                  Picking Completado
                </Ink.Chalk>
                <Ink.Text bold>
                  El contenedor ha sido cerrado. Escaneelo y depositelo en la
                  cinta del sorter.
                </Ink.Text>
              </Ink.Box>
              <HighlightedInfo
                attribute='Cubeta'
                value={current.context.createdContainer!.lpn}
              />
              <ScanContainer />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ConfirmEmptyContainerInLocation') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Box marginBottom={2} flexDirection='column'>
                <Ink.Chalk bold color='greenBright'>
                  Picking terminado sin ítems
                </Ink.Chalk>
                <Ink.Text bold>
                  Se ha terminado prematuramente la ola de picking.
                </Ink.Text>
                <Ink.Text bold>
                  Como no has pickeado ningun ítem, deja el contenedor donde lo
                  habias agarrado.
                </Ink.Text>
              </Ink.Box>
              <HighlightedInfo
                attribute='Cubeta'
                value={current.context.createdContainer!.lpn}
              />
              <Ink.Button
                style={{ width: '100%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Ya dejé el contenedor en su lugar'
              />
            </Ink.Box>
          </>
        ) : null}

        {current.matches('ConfirmingScreen') ? (
          <>
            <Ink.Box flexDirection='column'>
              <Ink.Box paddingBottom={2}>
                <Ink.Text bold color='greenBright'>
                  Picking Completado
                </Ink.Text>
              </Ink.Box>
              {current.context.missingItems?.length !== 0 && (
                <>
                  <Ink.Text>Los siguientes items estan faltantes:</Ink.Text>
                  {current.context.missingItems!.map(item => (
                    <>
                      <Ink.Text key={item.id}>
                        {item.inventoryItem?.sku} -{' '}
                        {item.inventoryItem?.product?.name} - Cantidad:{' '}
                        {item.quantity}
                      </Ink.Text>
                    </>
                  ))}
                </>
              )}
              {current.context.missingItems?.length === 0 && (
                <Ink.Text>Todos los items han sido tomados.</Ink.Text>
              )}
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '50%' }}
                  onClick={() => {
                    send('confirm');
                  }}
                  label='Confirmar y salir'
                />
              </Ink.Box>
            </Ink.Box>
          </>
        ) : null}

        {current.matches('AwaitingRestocking') && (
          <>
            <Ink.Text bold>¿Que deseas hacer?</Ink.Text>
            <Ink.Button
              onClick={() => {
                send('refetch');
              }}
              label='Consultar disponibilidad del producto'
            />
            <Ink.Button
              onClick={() => {
                send('skip');
              }}
              label='Ir al siguiente item'
            />
          </>
        )}

        {current.matches('MissingItemErrorScreen') && (
          <>
            {current.context.error && (
              <ErrorMessage error={current.context.error!!} />
            )}
            <Ink.Box flexDirection='row' justifyContent='space-between'>
              <Ink.Button
                style={{ width: '40%' }}
                onClick={() => {
                  send('return');
                }}
                label='Volver'
              />
            </Ink.Box>
          </>
        )}
        {current.context.error &&
          !current.matches('MissingItemErrorScreen') &&
          !current.matches('ScanningContainer') && (
            <ErrorMessage bold error={current.context.error!!} />
          )}
      </Ink.Box>
    );
  }
);

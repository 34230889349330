import { MoveToBufferItemStatuses } from '../MoveToBufferItem';
import { MoveToBufferItemStatus } from './MoveToBufferItemStatus';

export class MoveToBufferItemStatusPendingStatus extends MoveToBufferItemStatus {
  public displayName = 'Pendiente';

  public internalName = MoveToBufferItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { FeedingToSorterItemStatuses } from '../FeedingToSorterItem';
import { FeedingToSorterItemStatus } from './FeedingToSorterItemStatus';

export class FeedingToSorterItemStatusFedStatus extends FeedingToSorterItemStatus {
  public displayName = 'Inducido';

  public internalName = FeedingToSorterItemStatuses.Fed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

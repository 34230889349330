import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';

import { Container } from './Container';
import { MovePendingDispatchingItem } from './MovePendingDispatchingItem';
import { PendingDispatchingPickItem } from './PendingDispatchingPickItem';
import { ReservationPendingItem } from './ReservationPendingItem';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import {
  ReservationPendingContainerStatus,
  ReservationPendingContainerStatusCancelledStatus,
  ReservationPendingContainerStatusMovedStatus,
  ReservationPendingContainerStatusPendingStatus,
  ReservationPendingContainerStatusProcessingStatus
} from './reservation-pending-container-status';

export const ReservationPendingContainerStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Moved:      'moved',
  Cancelled:  'cancelled'
};

@Entity()
export class ReservationPendingContainer {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => StagingAuditingContainer, {
    name: 'staging_auditing_container_id'
  })
  stagingAuditingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => StagingAuditingContainer, 'stagingAuditingContainerId')
  stagingAuditingContainer: StagingAuditingContainer;

  @NeededBy(() => PendingDispatchingPickItem, 'reservationPendingContainerId')
  pendingDispatchingPickItem: PendingDispatchingPickItem;

  @NeededBy(() => ReservationPendingItem, 'reservationPendingContainerId')
  reservationPendingItems: ReservationPendingItem[];

  @Knows(() => MovePendingDispatchingItem, 'reservationPendingContainerId')
  movePendingDispatchingItem: MovePendingDispatchingItem;

  @Status({
    [ReservationPendingContainerStatuses.Pending]:
      ReservationPendingContainerStatusPendingStatus,
    [ReservationPendingContainerStatuses.Processing]:
      ReservationPendingContainerStatusProcessingStatus,
    [ReservationPendingContainerStatuses.Moved]:
      ReservationPendingContainerStatusMovedStatus,
    [ReservationPendingContainerStatuses.Cancelled]:
      ReservationPendingContainerStatusCancelledStatus
  })
  status: ReservationPendingContainerStatus;
}

import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AuthenticationPayload } from '../api/auth';

export const SESSION_TOKEN = 'session_token';
export const SESSION_USER = 'session_user';

type UserPayload = AuthenticationPayload['user'];

export interface SessionApi {
  token: string | null;
  user: UserPayload | null;
  openSession: (res: AuthenticationPayload) => void;
  closeSession: () => void;
}

interface SessionState {
  token: string;
  user: UserPayload;
}

export function useSession(): SessionApi {
  const [session, setSession] = useState<SessionState | null>(() => {
    if (typeof localStorage !== 'undefined') {
      const token = localStorage.getItem(SESSION_TOKEN);
      const userData = localStorage.getItem(SESSION_USER);

      let user: UserPayload | null = null;
      if (userData) {
        try {
          user = JSON.parse(userData);
        } catch {
          user = null;
        }
      }

      return token && user ? { token, user } : null;
    } else {
      return null;
    }
  });

  const [, , removeCookie] = useCookies();

  const openSession = useCallback(
    (res: AuthenticationPayload) => {
      if (res) {
        setSession({ token: res.token, user: res.user });
      }
    },
    [setSession]
  );

  const closeSession = useCallback(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(SESSION_TOKEN);
      localStorage.removeItem(SESSION_USER);
    }

    removeCookie(`${process.env.WMS_AUTH_COOKIE}`);
    removeCookie('X-FRAVEGA-AUTH');
    setSession(null);
  }, [setSession]);

  useEffect(() => {
    if (session && typeof localStorage !== 'undefined') {
      localStorage.setItem(SESSION_TOKEN, session.token);
      localStorage.setItem(SESSION_USER, JSON.stringify(session.user));
    }
  }, [session]);

  return {
    token: session?.token ?? null,
    user:  session?.user ?? null,
    openSession,
    closeSession
  };
}

import { ReceiptBatchStatuses } from '../ReceiptBatch';
import { ReceiptBatchStatus } from './ReceiptBatchStatus';

export class ReceiptBatchConfirmedStatus extends ReceiptBatchStatus {
  public displayName = 'Confirmado';

  public internalName = ReceiptBatchStatuses.Confirmed;

  public statusColor = '#f3ecfe';

  public textColor = '#8240f2';
}

import { MovingToPreSorterContainerStatuses } from '../MovingToPreSorterContainer';
import { MovingToPreSorterContainerStatus } from './MovingToPreSorterContainerStatus';

export class MovingToPreSorterContainerMovedStatus extends MovingToPreSorterContainerStatus {
  public displayName = 'Movido';

  public internalName = MovingToPreSorterContainerStatuses.Moved;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

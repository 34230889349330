import { DispatchingWaveStatuses } from '../DispatchingWave';
import { DispatchingWaveStatus } from './DispatchingWaveStatus';

export class DispatchingWaveProcessingStatus extends DispatchingWaveStatus {
  public displayName = 'Procesando';

  public internalName = DispatchingWaveStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  Owns,
  PrimaryKey
} from '@skyframe/core';
import { CustomerOrderItem } from './CustomerOrderItem';
import { Order } from './Order';
import { OrderCustomerOrder } from './OrderCustomerOrder';
import { Reservation } from './Reservation';

export interface IntegrationData {
  customerName: string;
  identityNumber: string;
  zipCode: string;
  province: string;
  town: string;
  street: string;
  streetNumber: string;
  floor: string;
  aparment: string;
  betweenStreets: string;
  contactNumber: string;
  reference: string;
}

@Entity()
export class CustomerOrder {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  rawIntegrationData: IntegrationData;

  @Field({ name: 'customer_name' })
  customerName: string;

  @Field({ name: 'identity_number' })
  identityNumber: number;

  @Field({ name: 'zip_code' })
  zipCode: string;

  @Field()
  province: string;

  @Field()
  town: string;

  @Field()
  street: string;

  @Field({ name: 'street_number' })
  streetNumber: number;

  @Field({ name: 'apartment_comment' })
  apartmentComment: string;

  @Field()
  floor: string;

  @Field()
  apartment: string;

  @Field()
  reference: string;

  @Field({ name: 'between_streets' })
  betweenStreets: string;

  @Field({ name: 'contact_number' })
  contactNumber: string;

  @Field({ name: 'external_ref_id' })
  externalRefId: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'estimated_departure_date' })
  estimatedDepartureDate: Date;

  @Owns(() => CustomerOrderItem, 'customerOrderId')
  customerOrderItems: CustomerOrderItem[];

  @Knows(() => Order, 'customerOrderId')
  orders: Order[];

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @NeededBy(() => OrderCustomerOrder, 'customerOrderId')
  orderCustomerOrders: OrderCustomerOrder[];
}

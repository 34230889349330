import { MachineOptions, assign } from 'xstate';
import { ContainerMergeContext } from './machine';

type ContainerMergeActionsType = MachineOptions<
  ContainerMergeContext,
  any
>['actions'];

export const containerMergeActions: ContainerMergeActionsType = {
  assignStagingLocations: assign({
    stagingLocations: (_ctx, event) => event.data.stagingLocations
  }),
  assignSelectedStagingCheckDigit: assign({
    selectedStagingCheckDigit: (_ctx, event) => event.data.key
  }),
  assignStaging: assign({
    staging: (_ctx, event) => event.data
  }),
  assignOriginContainer: assign({
    originContainer: (_ctx, event) => event.data
  }),
  assignTargetContainer: assign({
    targetContainer: (_ctx, event) => event.data
  }),
  assignOriginStagingAuditingContainer: assign({
    originStagingAuditingContainer: (_ctx, event) =>
      event.data.originStagingAuditingContainer
  }),
  assignTargetStagingAuditingContainer: assign({
    targetStagingAuditingContainer: (_ctx, event) =>
      event.data.targetStagingAuditingContainer
  }),
  assignErrorMsg: assign({
    errorMsg: (_ctx, event) => event.data.response.data.errorView
  }),
  clearErrorMsg: assign({
    errorMsg: (_ctx, _event) => null
  }),
  assignItemToMoveLabel: assign({
    itemToMoveLabel: (_ctx, event) => event.data.label
  }),
  assignStagingAuditingItemToMove: assign({
    stagingAuditingItemToMove: (_ctx, event) => event.data.stagingAuditingItem
  }),
  assignQuantityToMove: assign({
    quantityToMove: (_ctx, event) => event.data.quantity
  }),
  assignOrderType: assign({
    orderType: (_ctx, event) => event.data.orderType
  }),
  clearCtx: assign({
    stagingLocations: (_ctx, _event) => null,

    selectedStagingCheckDigit: (_ctx, _event) => null,

    originContainer: (_ctx, _event) => null,
    targetContainer: (_ctx, _event) => null,

    originStagingAuditingContainer: (_ctx, _event) => null,
    targetStagingAuditingContainer: (_ctx, _event) => null,

    itemToMoveLabel:           (_ctx, _event) => null,
    stagingAuditingItemToMove: (_ctx, _event) => null,

    errorMsg: (_ctx, _event) => null
  })
};

import { LocationStatuses } from '../BaseLocation';
import { LocationStatus } from './LocationStatus';

export class LocationStatusBlockedStatus extends LocationStatus {
  public displayName = 'Bloqueada';

  public internalName = LocationStatuses.Blocked;

  public statusColor = '#db7e04';
}

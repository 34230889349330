import { FeedingToSorterItemStatuses } from '../FeedingToSorterItem';
import { FeedingToSorterItemStatus } from './FeedingToSorterItemStatus';

export class FeedingToSorterItemStatusPendingStatus extends FeedingToSorterItemStatus {
  public displayName = 'Pendiente';

  public internalName = FeedingToSorterItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

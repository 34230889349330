import { InventoryAdjustItem, Product, mismatchTypes } from '@wms/domain/.';
import { createMachine } from 'xstate';

import { EnterQuantityMachine } from '../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanSKUContext,
  ScanSKUMachine
} from '../../capa-4/scan-sku/ScanSKUMachine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { inventoryAdjustWaveActions } from './actions';
import { inventoryAdjustWaveGuards } from './guards';
import { inventoryAdjustWaveServices } from './services';

export interface InventoryAdjustWaveContext {
  error: string | null;
  inventoryAdjustWaveId: number | null;
  actualAdjustItem: InventoryAdjustItem | null;
  reportedQuantity: number;
  taskName: string | null;
  mismatchItems: Array<{
    sku: string;
    physicalQuantity: number;
    logicalQuantity: number;
    name: string;
    mismatchType: mismatchTypes;
    containerId: number | null;
  }>;
  productScanned: Product | null;
}

export const DefaultInventoryAdjustWaveContext: InventoryAdjustWaveContext = {
  error:                 null,
  inventoryAdjustWaveId: null,
  actualAdjustItem:      null,
  reportedQuantity:      0,
  taskName:              null,
  mismatchItems:         [],
  productScanned:        null
};

export const InventoryAdjustWaveMachine = createMachine(
  {
    id:                         'InventoryAdjustWave',
    predictableActionArguments: true,
    schema:                     {
      context: {} as InventoryAdjustWaveContext
    },
    initial: 'FetchingNextInventoryAdjustItem',
    states:  {
      FetchingNextInventoryAdjustItem: {
        tags:   'loading',
        invoke: {
          src:    'fetchNextInventoryAdjustItem',
          onDone: [
            {
              actions: ['assignActualAdjustItem', 'clearError'],
              cond:    'hasNextInventoryAdjustItem',
              target:  'ScanLocation'
            },
            { target: 'ConfirmAdjustWaveCompleted', actions: ['clearError'] }
          ],
          onError: {
            actions: ['assignError']
          }
        }
      },
      ScanLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee ubicacion del producto a ajustar',
              getAnyLocationByName(
                'Ocurrió un error, por favor reintente más tarde.'
              ),
              {
                name:     ctx.actualAdjustItem?.location.name as string,
                required: true,
                hint:     'Diríjase a la ubicación'
              }
            ),
          onDone: {
            target:  'ScanSku',
            actions: ['clearError']
          },
          onError: {
            target:  'ScanLocation',
            actions: ['assignError']
          }
        }
      },
      ScanSku: {
        invoke: {
          id:   ScanSKUMachine.id,
          src:  ScanSKUMachine,
          data: _ctx => ({
            ...DefaultScanSKUContext,
            hint: 'Escanee SKU del producto '
          }),
          onDone: {
            target:  'SelectingQuantity',
            actions: ['clearError', 'assignProductScanned']
          }
        },
        on: {
          EMPTY_LOCATION: 'VerifyStock'
        }
      },
      SelectingQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: {
            min:  0,
            hint: 'Ingrese cantidad'
          },
          onDone: [
            {
              actions: ['setQuantity', 'clearError'],
              target:  'VerifyStock'
            }
          ],
          onError: {
            target:  'SelectingQuantity',
            actions: ['assignError']
          }
        }
      },
      VerifyStock: {
        invoke: {
          src:    'verifyStock',
          onDone: {
            actions: 'setMismatchItems',
            target:  'DetailOfVerification'
          },
          onError: {
            target:  'SelectingQuantity',
            actions: ['assignError']
          }
        }
      },
      DetailOfVerification: {
        on: {
          CONFIRM_INCIDENT: 'MismatchIventoryControl',
          SELECT_QUANTITY:  'SelectingQuantity',
          CONFIRM:          'ProcessAdjustItem'
        }
      },
      MismatchIventoryControl: {
        invoke: {
          src:    'mismatchInventoryAdjustment',
          onDone: {
            target: 'ConfirmIncident'
          },
          onError: {
            target:  'FetchingNextInventoryAdjustItem',
            actions: ['assignError', 'clearContext']
          }
        }
      },
      ConfirmIncident: {
        on: {
          NEXT_ITEM: 'ProcessAdjustItem'
        }
      },
      ProcessAdjustItem: {
        invoke: {
          src:    'processAdjustItem',
          onDone: {
            target:  'FetchingNextInventoryAdjustItem',
            actions: ['clearContext']
          },
          onError: {
            target:  'FetchingNextInventoryAdjustItem',
            actions: ['assignError', 'clearContext']
          }
        }
      },
      ConfirmAdjustWaveCompleted: {
        on: {
          CONFIRM: 'CompleteInventoryAdjustWave'
        }
      },
      CompleteInventoryAdjustWave: {
        tags:   'loading',
        invoke: {
          src:    'completeInventoryAdjustWave',
          onDone: {
            target: 'Done'
          },
          onError: {
            target:  'FetchingNextInventoryAdjustItem',
            actions: ['assignError', 'clearContext']
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   inventoryAdjustWaveGuards,
    actions:  inventoryAdjustWaveActions,
    services: inventoryAdjustWaveServices
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { NoveltyUnloadingContainer } from './NoveltyUnloadingContainer';
import { NoveltyUnloadingItemV2 } from './NoveltyUnloadingItemV2';
import { NoveltyUnloadingPackage } from './NoveltyUnloadingPackage';
import { Receipt } from './Receipt';
import {
  NoveltyUnloadingProcessStatus,
  NoveltyUnloadingProcessStatusCancelledStatus,
  NoveltyUnloadingProcessStatusCompletedStatus,
  NoveltyUnloadingProcessStatusPendingStatus,
  NoveltyUnloadingProcessStatusProcessingStatus
} from './novelty-unloading-process-status';

export const NoveltyUnloadingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class NoveltyUnloadingProcess {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Status({
    [NoveltyUnloadingProcessStatuses.Pending]:
      NoveltyUnloadingProcessStatusPendingStatus,
    [NoveltyUnloadingProcessStatuses.Processing]:
      NoveltyUnloadingProcessStatusProcessingStatus,
    [NoveltyUnloadingProcessStatuses.Completed]:
      NoveltyUnloadingProcessStatusCompletedStatus,
    [NoveltyUnloadingProcessStatuses.Cancelled]:
      NoveltyUnloadingProcessStatusCancelledStatus
  })
  status: NoveltyUnloadingProcessStatus;

  @NeededBy(() => NoveltyUnloadingPackage, 'noveltyUnloadingProcessId')
  noveltyUnloadingPackages: NoveltyUnloadingPackage[];

  @NeededBy(() => NoveltyUnloadingContainer, 'noveltyUnloadingProcessId')
  noveltyUnloadingContainers: NoveltyUnloadingContainer[];

  @NeededBy(() => NoveltyUnloadingItemV2, 'noveltyUnloadingProcessId')
  noveltyUnloadingItemsV2: NoveltyUnloadingItemV2[];
}

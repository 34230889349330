import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemAuditedStatus extends StagingAuditingItemStatus {
  public displayName = 'Auditado';

  public internalName = StagingAuditingItemStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchShippedStatus extends OrderBatchStatus {
  public displayName = 'Expedido';

  public internalName = OrderBatchStatuses.Shipped;

  public statusColor = '#f3ecfe';

  public textColor = '#358c42';
}

import { OrderType, StagingAuditingItem, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';
import { ResolveMismatchedAuditingWaveActions } from './actions';
import { ResolveMismatchedAuditingWaveGuards } from './guards';
import { ResolveMismatchedAuditingWaveServices } from './services';

export interface ResolveItem extends StagingAuditingItem {
  data: {
    packageItems: number;
    mopSorterExitItem: string;
    warehouseName: string;
  };
}

export interface ResolveMismatchedAuditingWaveContext {
  task: Task;
  orderType: OrderType;
  stagingAuditingWaveId: number;
  orderId: number;

  stagingAuditingItems: ResolveItem[] | null;
}

export const DefaultResolveMismatchedAuditingWaveContext: ResolveMismatchedAuditingWaveContext =
  {
    task:                  {} as Task,
    orderType:             {} as OrderType,
    stagingAuditingWaveId: -1,
    orderId:               -1,

    stagingAuditingItems: null
  };

export const ResolveMismatchedAuditingWaveMachine = createMachine(
  {
    id:                         'ResolveMismatchedAuditingWave',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ResolveMismatchedAuditingWaveContext
    },
    context: DefaultResolveMismatchedAuditingWaveContext,
    initial: 'Initializing',
    states:  {
      Initializing: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchingStagingAuditingWaveOrderType',
          onDone: {
            target:  'FetchingMismatchingItems',
            actions: ['assignOrderType']
          }
        }
      },
      FetchingMismatchingItems: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchingStagingAuditingWaveItems',
          onDone: {
            target:  'ShowingWaveDetails',
            actions: ['assignStagingAuditingItems']
          }
        }
      },
      ShowingWaveDetails: {},
      FinishingTask:      {
        tags:   ['loading'],
        invoke: {
          src:    'closeMismatchingAuditingWave',
          onDone: {
            target: 'Done'
          }
        }
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      closeMismatch: {
        target: 'FinishingTask'
      }
    }
  },
  {
    guards:  ResolveMismatchedAuditingWaveGuards,
    actions: {
      ...ResolveMismatchedAuditingWaveActions,
      triggerMenuScreen: (
        _ctx,
        { send, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        const options: MenuItemProps[] = [];
        options.push({
          label:   'Fin arreglo de descuadre',
          onClick: () => send('closeMismatch')
        });
        triggerMenuScreen(options);
      }
    },
    services: ResolveMismatchedAuditingWaveServices
  }
);

import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getAnyContainerInLocationByLpn(
  defaultErrorMessage: string,
  locationId: number
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn =>
    api.get<Container>(`container/location/${locationId}/lpn/${lpn}`)
  );
}

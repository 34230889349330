import { UnloadingItemStatuses } from '../UnloadingItem';
import { UnloadingItemStatus } from './UnloadingItemStatus';

export class UnloadingItemStatusPendingStatus extends UnloadingItemStatus {
  public displayName = 'Pendiente';

  public internalName = UnloadingItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

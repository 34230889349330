import { ContainerStatuses } from '../Container';
import { ContainerStatus } from './ContainerStatus';

export class ContainerStatusProcessStatus extends ContainerStatus {
  public displayName = 'Procesando';

  public internalName = ContainerStatuses.Process;

  public statusColor = '#Ffffff';
}

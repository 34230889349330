import Swal from 'sweetalert2';
import { AlertData } from '../types/alerData';

export const handleErrorPopUp = ({
  errorMessage,
  negativeTarget
}: AlertData) => {
  return Swal.fire({
    allowOutsideClick: false,
    title:             errorMessage && ' <strong>Error</strong>',
    icon:              errorMessage ? 'error' : 'success',
    text:              errorMessage ?? '-',
    showDenyButton:    !!negativeTarget,
    denyButtonText:    'Cancelar',
    confirmButtonText: 'OK'
  });
};

export const handleSuccessPopUp = ({ successMessage }: AlertData) => {
  return Swal.fire({
    allowOutsideClick: false,
    title:             successMessage && ' <strong>Éxito</strong>',
    icon:              'success',
    text:              successMessage ?? '-',
    showDenyButton:    false,
    denyButtonText:    'Cancelar',
    confirmButtonText: 'OK'
  });
};

import { MovingToPreSorterProcessStatuses } from '../MovingToPreSorterProcess';
import { MovingToPreSorterProcessStatus } from './MovingToPreSorterProcessStatus';

export class MovingToPreSorterProcessStatusProcessingStatus extends MovingToPreSorterProcessStatus {
  public displayName = 'Procesando';

  public internalName = MovingToPreSorterProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { FeedingToSorterProcessStatuses } from '../FeedingToSorterProcess';
import { FeedingToSorterProcessStatus } from './FeedingToSorterProcessStatus';

export class FeedingToSorterProcessStatusCompletedStatus extends FeedingToSorterProcessStatus {
  public displayName = 'Completado';

  public internalName = FeedingToSorterProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

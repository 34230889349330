import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { ManualRestockingContext } from './machine';

type ManualRestockingActionsType = MachineOptions<
  ManualRestockingContext,
  any
>['actions'];

export const manualRestockingActions: ManualRestockingActionsType = {
  assignOriginLocation: assign({
    originLocation: (_ctx, event) => event.data.location,
    container:      (_ctx, event) => event.data,
    productId:      (_ctx, event) =>
      event.data.inventoryItems[0].productId as number,
    restockedQuantity: (_ctx, event) => {
      let quantity: number = 0;
      event.data.inventoryItems.forEach(inventoryItem => {
        quantity += inventoryItem.quantity;
      });
      return quantity;
    }
  }),
  assignTargetLocation: assign({
    targetLocation: (_ctx, event) => event.data
  }),
  assignLocationAttribute: assign({
    locationAttribute: (_ctx, event) => event.data.locationAttribute
  }),
  assignRestockingItem: assign({
    restockingItem: (_ctx, event) => event.data.restockingItem
  }),
  assignError: assign({
    errorMsg: (_ctx, event) => getErrorView(event.data || '')
  }),
  assignEmptyLocationError: assign({
    errorMsg: (_ctx, _event) =>
      'La ubicación escaneada no tiene productos a reponer'
  }),
  assignSameLocationError: assign({
    errorMsg: (_ctx, _event) =>
      'La ubicación de destino no puede ser la misma que la de origen'
  }),
  assignErrorLocationIsNotAvailableForThisUtility: assign({
    errorMsg: (_ctx, _event) =>
      'La ubicación escaneada no posee contenedor, utilice la utilidad Traslado de producto a granel'
  }),
  assignRestockingLevelError: assign({
    errorMsg: (_ctx, _event) => 'No hay lugar para el traslado'
  }),
  assignUnavailableLocationError: assign({
    errorMsg: (_ctx, _event) => 'La ubicación escaneada no está disponible'
  }),
  assignBlockedLocationError: assign({
    errorMsg: (_ctx, _event) =>
      'No es posible realizar el movimiento ya que la ubicación escaneada se encuentra bloqueada'
  }),
  assignMaxNumberOfContainersExceededError: assign({
    errorMsg: (_ctx, _event) =>
      'Los dos espacios asignados para contenedor en esta ubicacion se encuentran ocupados, por favor, libere uno para realizar esta operación.'
  }),
  clearError: assign({
    errorMsg: (_ctx, _event) => ''
  }),
  assignExternalTask: assign({
    externalTask: (_ctx, event) => event.data.task
  }),
  assignTaskPayload: assign({
    taskPayload: (_ctx, event) => event.data.taskPayload
  }),
  assignTargetLocationFromTask: assign({
    targetLocation: (_ctx, event) => event.data.targetLocation
  })
};

import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusCancelledStatus extends ReservationItemStatus {
  public displayName = 'Cancelada';

  public internalName = ReservationItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

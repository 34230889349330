import { Task, TaskRoleNames } from '@wms/domain';
import {
  AnyActorRef,
  AnyInterpreter,
  AnyStateMachine,
  InterpreterFrom,
  StateNode,
  assign,
  createMachine,
  spawn
} from 'xstate';
import { AuthenticationPayload } from '../../../api';
import { api } from '../../../api/utils/axios-instance';
import { PackingAuditingDesignatorMachine } from '../pre-layer-3/packing-auditing-designator/machine';
import { TaskSearcherMachine } from '../pre-layer-3/task-searcher/machine';
import { TaskIndex } from '../taskIndex';
import { MenuItemProps } from './GenericOptions';
import { ROOT_TASK_ID } from './constants';

export type RootInterpreter = InterpreterFrom<typeof RootMachine>;

export interface RootContext {
  session: AuthenticationPayload | null;
  awaitingTaskId: number | null;
  currentTask: Task | null;
  currentTaskRef: AnyActorRef | null;
  helpScreenText: string | null;
  menuScreenItems: MenuItemProps[] | null;
  menuItemKey: string | null;
}

function getRehydratedMachine(machine: AnyStateMachine, state: any): StateNode {
  const config = { ...machine.config, initial: state.value };
  return new StateNode(config, machine.options).withContext(state.context);
}

export const RootMachine = createMachine<RootContext>(
  {
    id:                         'Root',
    predictableActionArguments: true,
    schema:                     {
      context: {} as RootContext
    },
    context: {
      session:         null,
      awaitingTaskId:  null,
      currentTask:     null,
      currentTaskRef:  null,
      helpScreenText:  null,
      menuScreenItems: null,
      menuItemKey:     null
    },
    initial: 'initializingOptions',
    states:  {
      initializingOptions: {
        always: 'login'
      },
      login: {
        on: {
          loggedIn: [
            {
              target:  'menu',
              cond:    'hasAdminAccess',
              actions: 'assignSession'
            },
            {
              target:  'awaitingForAutomaticTask',
              actions: 'assignSession'
            }
          ]
        }
      },
      menu: {
        on: {
          findTask: {
            target: 'awaitingForAutomaticTask'
          },
          NavigateToInboundMenu:             'inboundMenu',
          NavigateToOutboundMenu:            'outboundMenu',
          NavigateToInventoryManagementMenu: 'inventoryManagementMenu',
          NavigateToLogisticsMenu:           'logisticsMenu',
          NavigateToCrossdockingMenu:        'crossdockingMenu',
          NavigateToConfigurationMenu:       'configurationMenu',
          logout:                            [
            {
              target: 'logoutAPi'
            }
          ]
        }
      },
      logoutAPi: {
        invoke: {
          src:    'logout',
          onDone: [
            {
              cond:    'shouldRedirectToLogout',
              actions: ['removeSession', 'redirectToLogout']
            },
            {
              target:  'login',
              actions: 'removeSession'
            }
          ]
        }
      },
      crossdockingMenu: {
        on: {
          Back: 'menu'
        }
      },
      logisticsMenu: {
        on: {
          Back:         'menu',
          taskSelected: {
            actions: ['assignTask'],
            target:  'inTask'
          }
        }
      },
      configurationMenu: {
        on: {
          Back:         'menu',
          taskSelected: {
            actions: ['assignTask'],
            target:  'inTask'
          }
        }
      },
      outboundMenu: {
        initial: 'idle',
        states:  {
          idle: {
            on: {
              goToPackingAuditingDesignator: 'inPackingAuditingDesignator',
              // goToPackingTaskAssigner:       'inPackingTaskAssigner',
              // goToAuditingTaskAssigner:      'inAuditingTaskAssigner',
              taskSelected:                  {
                actions: ['assignTask'],
                target:  '#Root.inTask'
              }
            }
          },
          inPackingAuditingDesignator: {
            tags:   ['withOptions'],
            invoke: {
              id:     PackingAuditingDesignatorMachine.id,
              src:    PackingAuditingDesignatorMachine,
              onDone: {
                actions: 'assignDesignatorTask',
                target:  'inPackingAuditingDesignatorTask'
              }
            },
            on: {
              triggerHelpScreen: {
                actions: 'assignHelpScreenText'
              },
              triggerMenuScreen: {
                actions: 'assignMenuScreenItems'
              },
              quitHelpScreen: {
                actions: 'removeHelpScreenText'
              },
              quitMenuScreen: {
                actions: 'clearMenuScreenItems'
              },
              backToMenu: {
                actions: ['goToMenu'],
                target:  '#Root.menu'
              }
            }
          },
          // inAuditingTaskAssigner: {
          //   tags:   ['withOptions'],
          //   invoke: {
          //     id:     AuditingTaskAssignerMachine.id,
          //     src:    AuditingTaskAssignerMachine,
          //     onDone: {
          //       actions: 'assignAuditingTask',
          //       target:  'inAuditingTask'
          //     }
          //   },
          //   on: {
          //     triggerHelpScreen: {
          //       actions: 'assignHelpScreenText'
          //     },
          //     triggerMenuScreen: {
          //       actions: 'assignMenuScreenItems'
          //     },
          //     quitHelpScreen: {
          //       actions: 'removeHelpScreenText'
          //     },
          //     quitMenuScreen: {
          //       actions: 'clearMenuScreenItems'
          //     },
          //     backToMenu: {
          //       actions: ['goToMenu'],
          //       target:  '#Root.menu'
          //     }
          //   }
          // },
          // inAuditingTask: {
          //   tags:  ['withOptions'],
          //   entry: 'spawnTaskActor',
          //   on:    {
          //     triggerHelpScreen: {
          //       actions: 'assignHelpScreenText'
          //     },
          //     triggerMenuScreen: {
          //       actions: 'assignMenuScreenItems'
          //     },
          //     quitHelpScreen: {
          //       actions: 'removeHelpScreenText'
          //     },
          //     quitMenuScreen: {
          //       actions: 'clearMenuScreenItems'
          //     },
          //     backToMenu: {
          //       target: '#Root.pausingTask'
          //     }
          //   }
          // },
          // inPackingTaskAssigner: {
          //   tags:   ['withOptions'],
          //   invoke: {
          //     id:     PackingTaskAssignerMachine.id,
          //     src:    PackingTaskAssignerMachine,
          //     onDone: {
          //       actions: 'assignPackingTask',
          //       target:  'inPackingTask'
          //     }
          //   },
          //   on: {
          //     triggerHelpScreen: {
          //       actions: 'assignHelpScreenText'
          //     },
          //     triggerMenuScreen: {
          //       actions: 'assignMenuScreenItems'
          //     },
          //     quitHelpScreen: {
          //       actions: 'removeHelpScreenText'
          //     },
          //     quitMenuScreen: {
          //       actions: 'clearMenuScreenItems'
          //     },
          //     backToMenu: {
          //       actions: ['goToMenu'],
          //       target:  '#Root.menu'
          //     }
          //   }
          // },
          inPackingAuditingDesignatorTask: {
            tags:  ['withOptions'],
            entry: 'spawnTaskActor',
            on:    {
              triggerHelpScreen: {
                actions: 'assignHelpScreenText'
              },
              triggerMenuScreen: {
                actions: 'assignMenuScreenItems'
              },
              quitHelpScreen: {
                actions: 'removeHelpScreenText'
              },
              quitMenuScreen: {
                actions: 'clearMenuScreenItems'
              },
              backToMenu: {
                target: '#Root.pausingTask'
              }
            }
          }
          // inPackingTask: {
          //   tags:  ['withOptions'],
          //   entry: 'spawnTaskActor',
          //   on:    {
          //     triggerHelpScreen: {
          //       actions: 'assignHelpScreenText'
          //     },
          //     triggerMenuScreen: {
          //       actions: 'assignMenuScreenItems'
          //     },
          //     quitHelpScreen: {
          //       actions: 'removeHelpScreenText'
          //     },
          //     quitMenuScreen: {
          //       actions: 'clearMenuScreenItems'
          //     },
          //     backToMenu: {
          //       target: '#Root.pausingTask'
          //     }
          //   }
          // }
        },
        on: {
          Back: {
            target:  '#Root.menu',
            actions: ['goToMenu']
          }
        }
      },
      inboundMenu: {
        on: {
          Back:         'menu',
          taskSelected: {
            actions: ['assignTask'],
            target:  '#Root.inTask'
          }
        }
      },
      inventoryManagementMenu: {
        on: {
          taskSelected: {
            actions: ['assignTask'],
            target:  'inTask'
          },
          Back: 'menu'
        }
      },
      awaitingForAutomaticTask: {
        on: {
          taskReceived: {
            actions: 'assignTask',
            target:  'inTask'
          },
          backToMenu: {
            target: 'menu'
          }
        }
      },
      awaitingForTask: {
        on: {
          taskReceived: {
            actions: 'assignTask',
            target:  'inTask'
          },
          backToMenu: {
            target:  'menu',
            actions: ['goToMenu']
          }
        }
      },
      inMenuScreen: {
        on: {
          Back: {
            target:  'menu',
            actions: 'clearMenuScreenItems'
          }
        }
      },
      inTask: {
        tags:  ['withOptions'],
        entry: 'spawnTaskActor',
        on:    {
          triggerHelpScreen: {
            actions: 'assignHelpScreenText'
          },
          triggerMenuScreen: {
            actions: 'assignMenuScreenItems'
          },
          quitHelpScreen: {
            actions: 'removeHelpScreenText'
          },
          quitMenuScreen: {
            actions: 'clearMenuScreenItems'
          }
        }
      },
      inTaskSearcher: {
        invoke: {
          src:  TaskSearcherMachine,
          id:   TaskSearcherMachine.id,
          data: ctx => ({
            task:        null,
            list:        [],
            menuItemKey: ctx.menuItemKey
          }),
          onDone: {
            actions: assign({
              awaitingTaskId: (ctx, ev) => ev.data.id
            }),
            target: 'awaitingForTask'
          }
        }
      },
      pausingTask: {
        invoke: {
          src:    'pauseTask',
          onDone: {
            target:  'menu',
            actions: ['goToMenu']
          }
        }
      }
    },
    on: {
      backToMenu: {
        target: 'pausingTask'
      },
      navigateToTaskSearcher: {
        actions: ['assignMenuItemKey'],
        target:  'inTaskSearcher'
      }
    }
  },
  {
    guards: {
      shouldRedirectToLogout: (_context, _event) =>
        !!process.env.LIRA_LOGOUT_URL,
      hasAdminAccess: (_context, event) => {
        const userTaskRoles = event.session?.user?.userTaskRoles || [];
        return userTaskRoles.some(userTaskRole =>
          [TaskRoleNames.Admin, TaskRoleNames.Developer].includes(
            userTaskRole.taskRole.name
          )
        );
      }
    },
    actions: {
      createTask: (ctx, event) => {
        event.task = { type: event.taskType } as Task;
      },
      redirectToLogout: () => {
        window.location.href = `${
          process.env.LIRA_LOGOUT_URL || window.location.href
        }?redirect=${process.env.AUTH_URL}`;
      },
      assignTask: assign({
        currentTask: (context, event) => event.task
      }),
      assignDesignatorTask: assign({
        currentTask: (context, event) => event.data
      }),
      // assignPackingTask: assign({
      //   currentTask: (context, event) => event.data
      // }),
      // assignAuditingTask: assign({
      //   currentTask: (context, event) => event.data
      // }),
      assignMenuItemKey: assign({
        menuItemKey: (ctx, event) => event.menuItemKey
      }),
      spawnTaskActor: assign({
        currentTaskRef: ctx => {
          const { machine, initialContext } = TaskIndex[ctx!.currentTask!.type];

          const payload = ctx.currentTask?.payload;
          const initialized = (payload as any)?.$initialized;
          // @Hack to dont break the machines spawn when is a substate saved in the payload
          const stateValue =
            typeof (payload as any)?.value === 'object'
              ? Object.keys((payload as any)?.value)[0]
              : (payload as any)?.value;
          const spawnMachine = initialized
            ? getRehydratedMachine(machine, {
                ...payload,
                value: stateValue
              })
            : machine.withContext({
                ...initialContext,
                task: ctx.currentTask,
                ...ctx.currentTask,
                ...(payload || {})
              });

          const taskRef = spawn(spawnMachine, {
            name: ROOT_TASK_ID,
            sync: true
          }) as AnyInterpreter;

          taskRef.onDone(() => taskRef.parent?.send('backToMenu'));

          return taskRef;
        }
      }),
      goToMenu: assign({
        currentTask:    (_ctx, _ev) => null,
        currentTaskRef: (ctx, _ev) => {
          ctx.currentTaskRef?.stop?.();
          return null;
        },
        awaitingTaskId:  (_ctx, _ev) => null,
        menuScreenItems: (_ctx, _ev) => null
      }),
      assignHelpScreenText: assign({
        helpScreenText: (_ctx, ev) => ev.data
      }),
      removeHelpScreenText: assign({
        helpScreenText: (_ctx, _ev) => null
      }),
      assignMenuScreenItems: assign({
        menuScreenItems: (_ctx, ev) => ev.data
      }),
      clearMenuScreenItems: assign({
        menuScreenItems: (_ctx, _ev) => null
      }),
      assignSession: assign({
        session: (_ctx, ev) => ev.session
      }),
      removeSession: assign({
        session: (_ctx, _ev) => null
      })
    },
    services: {
      logout: ctx => {
        return api.post('/user-auth/logout/', { session: ctx.session });
      }
    }
  }
);

import { ContainerTypes } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MiniPtwPickingWaveContext } from './machine';

type MiniPtwPickingWaveGuards = MachineOptions<
  MiniPtwPickingWaveContext,
  any
>['services'];

export const miniPtwPickingWaveServices: MiniPtwPickingWaveGuards = {
  autoAssignBucketContainerType: async (_ctx, _evt) => {
    const { data } = await api.get('/container-type/name', {
      params: {
        name: ContainerTypes.CUBETA
      }
    });
    return {
      selectedContainerType: data
    };
  },
  fetchSuggestedContainerTypesAndInitialLocation: async (_ctx, _evt) => {
    const [{ data: containerTypeData }, { data: locationData }] =
      await Promise.all([
        api.get('/container-type/suggested', {
          params: {
            type: 'mini'
          }
        }),
        api.get('location/mezzanine')
      ]);

    return {
      suggestedContainerType: containerTypeData.suggestedContainerType,
      containerTypes:         containerTypeData.containerTypes,
      location:               locationData
    };
  },
  fetchPendingItemsCount: async (ctx, _evt) => {
    const params = new URLSearchParams();
    params.append('containerId', ctx.createdContainer!.id.toString());

    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/pending-pick-items`,
      {
        params
      }
    );

    return {
      pendingItems: data.pendingItems,
      totalItems:   data.totalItems,
      missingItems: data.missingItems
    };
  },
  fetchingPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );

    return data;
  },
  fetchContainerByPickingWaveId: async (ctx, _evt) => {
    const { data } = await api.get(`container/lpn/${ctx.lpn}`);
    return data;
  },
  markItemAsMissing: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/mark-as-missing`,
      {
        params: {
          userId: ctx.userId
        }
      }
    );
    return data;
  },
  checkAvailableOpportunisticCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/opportunistic-count`
    );
    return data;
  },
  validateOpportunisticCounting: async (ctx, _evt) => {
    await api.post(`pick-item/${ctx.currentPickItem?.id}/opportunistic-count`, {
      count:      ctx.countQuantity,
      locationId: ctx.currentPickItem!.pickingLocationId
    });
  },
  checkIfPickItemIsCancelled: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem?.id}/status`
    );
    return data;
  }
};

import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';

import { Novelty } from './Novelty';
import { NoveltyMoveContainerToBufferItem } from './NoveltyMoveContainerToBufferItem';
import { NoveltyUnloadingContainerV2 } from './NoveltyUnloadingContainerV2';
import { NoveltyUnloadingProcess } from './NoveltyUnloadingProcess';
import { ReceiptItem } from './ReceiptItem';
import {
  NoveltyUnloadingItemV2Status,
  NoveltyUnloadingItemV2StatusPendingStatus,
  NoveltyUnloadingItemV2StatusUnloadedStatus
} from './novelty-unloading-item-v2-status';

export const NoveltyUnloadingItemV2Statuses = {
  Pending:  'pending',
  Unloaded: 'unloaded'
};

@Entity()
export class NoveltyUnloadingItemV2 {
  @PrimaryKey()
  id: number;

  @IsNotEmpty()
  @Field({ name: 'sku' })
  sku: string;

  @ForeignKey(() => NoveltyUnloadingProcess, {
    name: 'novelty_unloading_process_id'
  })
  noveltyUnloadingProcessId: number;

  @ForeignKey(() => Novelty, { name: 'novelty_id' })
  noveltyId: number;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @ForeignKey(() => NoveltyUnloadingContainerV2, {
    name: 'novelty_unloading_container_v2_id'
  })
  noveltyUnloadingContainerV2Id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => NoveltyUnloadingProcess, 'noveltyUnloadingProcessId')
  noveltyUnloadingProcess: NoveltyUnloadingProcess;

  @Knows(() => NoveltyUnloadingContainerV2, 'noveltyUnloadingContainerV2Id')
  noveltyUnloadingContainerV2: NoveltyUnloadingContainerV2;

  @Needs(() => Novelty, 'noveltyId')
  novelty: Novelty;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Status({
    [NoveltyUnloadingItemV2Statuses.Pending]:
      NoveltyUnloadingItemV2StatusPendingStatus,
    [NoveltyUnloadingItemV2Statuses.Unloaded]:
      NoveltyUnloadingItemV2StatusUnloadedStatus
  })
  status: NoveltyUnloadingItemV2Status;

  @NeededBy(() => NoveltyMoveContainerToBufferItem, 'noveltyUnloadingItemV2Id')
  noveltyMoveContainerToBufferItems: NoveltyMoveContainerToBufferItem[];
}

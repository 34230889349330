import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { ImpureSortableCrossdockClassificationContainer } from './ImpureSortableCrossdockClassificationContainer';
import { ImpureSortableCrossdockClassificationItem } from './ImpureSortableCrossdockClassificationItem';
import { Receipt } from './Receipt';
import {
  ImpureSortableCrossdockClassificationProcessStatus,
  ImpureSortableCrossdockClassificationProcessStatusCancelledStatus,
  ImpureSortableCrossdockClassificationProcessStatusCompletedStatus,
  ImpureSortableCrossdockClassificationProcessStatusPausedStatus,
  ImpureSortableCrossdockClassificationProcessStatusPendingStatus,
  ImpureSortableCrossdockClassificationProcessStatusProcessingStatus
} from './impure-sortable-crossdock-classification-process-status';

export const ImpureSortableCrossdockClassificationProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class ImpureSortableCrossdockClassificationProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [ImpureSortableCrossdockClassificationProcessStatuses.Pending]:
      ImpureSortableCrossdockClassificationProcessStatusPendingStatus,
    [ImpureSortableCrossdockClassificationProcessStatuses.Processing]:
      ImpureSortableCrossdockClassificationProcessStatusProcessingStatus,
    [ImpureSortableCrossdockClassificationProcessStatuses.Completed]:
      ImpureSortableCrossdockClassificationProcessStatusCompletedStatus,
    [ImpureSortableCrossdockClassificationProcessStatuses.Cancelled]:
      ImpureSortableCrossdockClassificationProcessStatusCancelledStatus,
    [ImpureSortableCrossdockClassificationProcessStatuses.Paused]:
      ImpureSortableCrossdockClassificationProcessStatusPausedStatus
  })
  status: ImpureSortableCrossdockClassificationProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(
    () => ImpureSortableCrossdockClassificationContainer,
    'impureSortableCrossdockClassificationProcessId'
  )
  impureSortableCrossdockClassificationContainers: ImpureSortableCrossdockClassificationContainer[];

  @NeededBy(
    () => ImpureSortableCrossdockClassificationItem,
    'impureSortableCrossdockClassificationProcessId'
  )
  impureSortableCrossdockClassificationItems: ImpureSortableCrossdockClassificationItem[];
}

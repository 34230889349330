import Ink from '@ink';
import { InventoryItemStatuses } from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpnOrLocationLabel } from '../../capa-4/enter-lpn-or-location-label/EnterLpnOrLocationLabel';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { PopUp } from '../../layer-4/pop-up/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { BulkMovementMachine } from './machine';

export const BulkMovementComponent = withActorRef(BulkMovementMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Traslado de producto a granel</Ink.Text>
        </Ink.Header>

        {current.context.itemStatus === InventoryItemStatuses.Anomaly ? (
          <Ink.Text color='greenBright' bold>
            Estas moviendo un item declarado como anomalia
          </Ink.Text>
        ) : null}

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.hasTag('loading') ? <Ink.Text>Cargando...</Ink.Text> : null}

          {current.matches('ScanningOriginEntity.ScanningOriginLabel') && (
            <Ink.Box flexDirection='column'>
              <EnterLpnOrLocationLabel />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningDestinyEntity.ScanningDestinyLabel') && (
            <Ink.Box flexDirection='column'>
              <EnterLpnOrLocationLabel />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningOriginEntity.ScanningOriginContainer') && (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn id='ScanningOriginContainer' />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches(
            'ScanningDestinyEntity.ScanningDestinyContainer'
          ) && (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn id='ScanningDestinyContainer' />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningSku') && (
            <Ink.Box flexDirection='column'>
              <GetProductBySKU />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('EnteringQuantity') && (
            <Ink.Box flexDirection='column'>
              <HighlightedInfo
                attribute='Producto a mover SKU '
                value={current.context.productToMove!!.sku as string}
              />
              <EnterQuantity />
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('CompletingMoving') && (
            <>
              <Ink.Box flexDirection='column'>
                <Ink.Text bold>Tarea completada</Ink.Text>
              </Ink.Box>
              <Ink.Button
                label='Confirmar'
                onClick={() => {
                  send('CONFIRM');
                }}
              />
            </>
          )}

          {current.matches('GoBackTo') && (
            <Ink.Box flexDirection='column'>
              <Ink.Box flexDirection='column'>
                <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
                <Ink.Button
                  label='Volver a seleccionar la cantidad'
                  onClick={() => {
                    send('ENTER_QUANTITY');
                  }}
                />
                <Ink.Button
                  label='Volver a seleccionar el destino'
                  onClick={() => {
                    send('ENTER_DESTINY');
                  }}
                />
              </Ink.Box>
            </Ink.Box>
          )}

          {current.matches('HandleErrors') && <PopUp />}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

import { NoveltyMoveContainerToBufferProcessStatuses } from '../NoveltyMoveContainerToBufferProcess';
import { NoveltyMoveContainerToBufferProcessStatus } from './NoveltyMoveContainerToBufferProcessStatus';

export class NoveltyMoveContainerToBufferProcessStatusProcessingStatus extends NoveltyMoveContainerToBufferProcessStatus {
  public displayName = 'Procesando';

  public internalName = NoveltyMoveContainerToBufferProcessStatuses.Processing;

  public statusColor = '#Ffffff';
}

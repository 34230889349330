import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { UnloadingTaskContext } from './machine';

type UnloadingTaskServices = MachineOptions<
  UnloadingTaskContext,
  any
>['services'];

export const unloadingTaskServices: UnloadingTaskServices = {
  fetchPendingContainersToCreate: async (ctx, _event) => {
    const { data } = await api.get(
      `unloading-process/containers-to-create/${ctx.unloadingProcessId}`
    );

    return {
      pendingContainersToCreate: data.pending,
      totalContainersToCreate:   data.total
    };
  },

  fetchUnloadingItemsToUnload: async (ctx, _event) => {
    const { data } = await api.get(
      `unloading-process/items-count/${ctx.unloadingProcessId}`
    );

    return {
      totalUnloadingItems:    data.totalUnloadingItems,
      unloadedUnloadingItems: data.unloadedUnloadingItems
    };
  },

  fetchSuggestedUnloadingItemToUnload: async (ctx, _event) => {
    const { data } = await api.get(
      `unloading-process/suggest-seller-product-unloading-process/${ctx.unloadingProcessId}`
    );

    return {
      suggestedUnloadingItemCode: data.code
    };
  },

  fetchSuggestedContainerToUnload: async (ctx, _event) => {
    const { data } = await api.get('unloading-process/suggest-container', {
      params: {
        unloadingProcessId: ctx.unloadingProcessId,
        sellerProductCode:  ctx.sellerProductToUnload?.code
      }
    });

    return {
      suggestedContainerToUnload: data
    };
  }
};

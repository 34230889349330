import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Receipt } from './Receipt';

export enum ReceiptOriginType {
  Provinter = 'provinter',
  Unknown = 'unknown',
  Warehouse = 'warehouse',
  Generic = 'GENERICO'
}
@Entity()
export class ReceiptOrigin {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'wms_wh_code' })
  wmsWhCode: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Receipt, 'receiptOriginId')
  receipts: Receipt[];
}

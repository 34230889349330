import { FeedingToSorterContainerStatuses } from '../FeedingToSorterContainer';
import { FeedingToSorterContainerStatus } from './FeedingToSorterContainerStatus';

export class FeedingToSorterContainerStatusCompletedStatus extends FeedingToSorterContainerStatus {
  public displayName = 'Completado';

  public internalName = FeedingToSorterContainerStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

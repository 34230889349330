import { UnloadingItemStatuses } from '../UnloadingItem';
import { UnloadingItemStatus } from './UnloadingItemStatus';

export class UnloadingItemStatusCancelledStatus extends UnloadingItemStatus {
  public displayName = 'Cancelado';

  public internalName = UnloadingItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import Ink from '@ink';
import React from 'react';
import { HighlightedInfo } from '../../shared/HighlightedInfo';

export const truncate = str => {
  const maxLength = 15;
  return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
};

export const WarehouseInfo = (ctx: any) => (
  <Ink.Box flexDirection='column'>
    {ctx.order?.branchOfficesToGo ? (
      <Ink.Box>
        <Ink.Box flexDirection='column'>
          <Ink.Text bold={ctx.bold ?? true}>Sucursales:</Ink.Text>
          {ctx.order?.branchOfficesToGo
            .split(',')
            .map((branchOffice, index) => (
              <Ink.Chalk key={index} color='greenBright' bold>
                {branchOffice}
              </Ink.Chalk>
            ))}
        </Ink.Box>
      </Ink.Box>
    ) : null}
    {!ctx.order?.branchOfficesToGo && ctx.warehouse ? (
      <HighlightedInfo
        attribute='Codigo de sucursal'
        value={`${ctx.warehouse.wmsWhCode} - ${truncate(ctx.warehouse.name)}`}
      />
    ) : null}
    {ctx.destinationWarehouse ? (
      <HighlightedInfo
        attribute='Destino'
        value={`${ctx.destinationWarehouse.wmsWhCode} - ${ctx.destinationWarehouse.name}`}
      />
    ) : null}
    {ctx.location ? (
      <HighlightedInfo attribute='Rampa' value={ctx.location?.name || ''} />
    ) : null}
    {ctx.route ? (
      <HighlightedInfo
        attribute='Transportista'
        value={`${ctx.route.courierCenterCode} - ${truncate(
          ctx.route.courierCenterCode
        )}`}
      />
    ) : null}
  </Ink.Box>
);

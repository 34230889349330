import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { MoveToBufferProcess } from './MoveToBufferProcess';
import { SlottingContainer } from './SlottingContainer';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import {
  MoveToBufferItemStatus,
  MoveToBufferItemStatusCancelledStatus,
  MoveToBufferItemStatusMovedStatus,
  MoveToBufferItemStatusPendingStatus
} from './move-to-buffer-item-status';

export const MoveToBufferItemStatuses = {
  Pending:   'pending',
  Moved:     'moved',
  Cancelled: 'cancelled'
};

@Entity()
export class MoveToBufferItem {
  @PrimaryKey()
  id: number;

  @Status({
    [MoveToBufferItemStatuses.Pending]:   MoveToBufferItemStatusPendingStatus,
    [MoveToBufferItemStatuses.Moved]:     MoveToBufferItemStatusMovedStatus,
    [MoveToBufferItemStatuses.Cancelled]: MoveToBufferItemStatusCancelledStatus
  })
  status: MoveToBufferItemStatus;

  @ForeignKey(() => MoveToBufferProcess, {
    name: 'move_to_buffer_process_id'
  })
  moveToBufferProcessId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => StandardUnloadingContainer, {
    name: 'standard_unloading_container_id'
  })
  standardUnloadingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => MoveToBufferProcess, 'moveToBufferProcessId')
  moveToBufferProcess: MoveToBufferProcess;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Needs(() => StandardUnloadingContainer, 'standardUnloadingContainerId')
  standardUnloadingContainer: StandardUnloadingContainer;

  @Knows(() => SlottingContainer, 'moveToBufferItemId')
  slottingContainer: SlottingContainer;
}

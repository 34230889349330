import { UnloadingProcessStatuses } from '../UnloadingProcess';
import { UnloadingProcessStatus } from './UnloadingProcessStatus';

export class UnloadingProcessStatusPendingStatus extends UnloadingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = UnloadingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

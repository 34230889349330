import { MachineOptions, assign } from 'xstate';
import { escalate, sendParent } from 'xstate/lib/actions';
import { GetItemByLabelContext } from './machine';

type GetItemByLabelActions = MachineOptions<
  GetItemByLabelContext,
  any
>['actions'];

export const getItemByLabelActions: GetItemByLabelActions = {
  updateItemLabel: assign({
    itemLabel: (_context, event) => event.itemLabel
  }),
  assignItem: assign({
    item: (_context, event) => event.data.data
  }),
  assignError: assign({
    error: (_context, event) => event.data.error
  }),
  assignInvalidLabelError: assign({
    error: (_context, _event) => 'La etiqueda escaneada es inválida'
  }),
  clearError: assign({
    error: (_context, _event) => null
  }),
  clearParentError: sendParent('clearError'),
  clearItemLabel:   assign({
    itemLabel: (_context, _event) => ''
  }),
  throwError: escalate((_, event) => ({
    message: event.data.error
  }))
};

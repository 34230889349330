import { api } from '../../../../api/utils/axios-instance';

export const slottingItemServices = {
  fetchStagingLocation: async (ctx, _evt) => {
    const { data } = await api.get('slotting-container/stage-location', {
      params: {
        slottingContainerId: ctx.slottingContainerId
      }
    });

    return {
      stagingLocation: data
    };
  },
  fetchSuggestedSlottingItem: async (ctx, _evt) => {
    const { data } = await api.get('slotting-item/suggest', {
      params: {
        slottingContainerId: ctx.slottingContainerId
      }
    });

    return {
      suggestedSlottingItem: data
    };
  },
  fetchDestinyLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      'slotting-process/pallet/suggested-destiny-location',
      {
        params: {
          containerId: ctx.container.id
        }
      }
    );

    return {
      destinyLocation: data
    };
  },
  checkIfLocationIsValid: async (ctx, _evt) => {
    const { data } = await api.get('slotting-process/is-valid-location', {
      params: {
        containerId: ctx.container.id,
        locationId:  ctx.scannedLocation.id
      }
    });

    return data;
  }
};

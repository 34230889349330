import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { PickPendingDispatchingTaskMachine } from './machine';

export const PickPendingDispatchingTask = withActorRef(
  PickPendingDispatchingTaskMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>Ola Pickeo Remanente</Ink.Text>
      </Ink.Header>

      {current.matches('ScanPendingDispachingLocation') && (
        <>
          <HighlightedInfo
            attribute='Trasladese hasta la ubicación'
            value={current.context.pickingDispatchingLocation?.name || ''}
          />
          <GetLocationByName />
        </>
      )}

      {current.matches('ScanningPickItem') && (
        <>
          <GetContainerByLpn />
        </>
      )}

      {current.matches('ScanningStagingLocation') && (
        <>
          <HighlightedInfo
            attribute='Trasladese hasta el canal'
            value={current.context.stagingLocation?.name || ''}
          />

          <GetLocationByCheckDigit />
        </>
      )}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </Ink.Box>
  );
});

import {
  Container,
  Location,
  Order,
  StagingAuditingContainer,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import {
  DefaultScanLabelContext,
  ScanLabelMachine
} from '../../capa-4/scan-label/machine';
import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { stagingAuditingActions } from './actions';
import { StagingAuditingGuards } from './guards';
import { StagingAuditingServices } from './services';

export interface StagingAuditingContext {
  task: Task;
  stagingAuditingWaveId: number;
  stageName: string;

  stagingLocation: Location | null;

  containersToAudit: number | null;
  containersAudited: number | null;
  totalContainers: number | null;
  containersMismatched: number | null;
  auditingContainer: Container | null;
  stagingAuditingContainer: StagingAuditingContainer | null;
  order: Order | null;

  itemLabel: string | null;

  error: string | null;
}

export const DefaultStagingAuditingContext: StagingAuditingContext = {
  task:                     {} as Task,
  stagingAuditingWaveId:    -1,
  stageName:                '',
  stagingLocation:          null,
  containersToAudit:        null,
  containersAudited:        null,
  totalContainers:          null,
  containersMismatched:     null,
  auditingContainer:        null,
  stagingAuditingContainer: null,
  itemLabel:                null,
  order:                    null,
  error:                    null
};

export const StagingAuditingMachine = createMachine(
  {
    id:                         'stagingAuditing',
    predictableActionArguments: true,
    schema:                     {
      context: {} as StagingAuditingContext
    },
    initial: 'FetchingStagingLocation',
    states:  {
      FetchingStagingLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStagingLocation',
          onDone: {
            target:  'ScanningStagingLocation',
            actions: ['assignStagingLocation']
          },
          onError: {
            target:  'ScanningStagingLocation',
            actions: ['assignError']
          }
        }
      },
      ScanningStagingLocation: {
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanee digito de control',
            requiredLocation: ctx.stagingLocation!
          }),
          onDone: {
            target:  'FetchingStagingAuditingContainersCount',
            actions: ['clearError']
          },
          onError: {
            target:  'ScanningStagingLocation',
            actions: ['assignError']
          }
        }
      },
      FetchingStagingAuditingContainersCount: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStagingAuditingContainersCount',
          onDone: [
            {
              target:  'AuditingContainers',
              actions: [
                'assignContainersToAudit',
                'assignContainersAudited',
                'assingTotalContainers'
              ],
              cond: 'hasContainersToAudit'
            },
            {
              target:  'FetchingMismatchedContainers',
              actions: [
                'assignContainersToAudit',
                'assignContainersAudited',
                'assingTotalContainers'
              ]
            }
          ],
          onError: {
            target:  'AuditingContainers',
            actions: ['assignError']
          }
        }
      },
      AuditingContainers: {
        invoke: {
          id:  'AuditingContainers',
          src: _ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando etiqueta de contenedor',
              getAnyContainerByLpn(
                'Ocurrion un error, por favor intente de nuevo'
              )
            ),
          onDone: [
            {
              target:  'CheckingIfHasStagingAuditingContainer',
              actions: ['assignAuditingContainer', 'clearError']
            },
            {
              target: 'FetchingMismatchedContainers'
            }
          ]
        }
        // on: {
        //   COMPLETE_AUDITING: {
        //     target: 'FetchingMismatchedContainers'
        //   }
        // }
      },
      CheckingIfHasStagingAuditingContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfHasStagingAuditingContainer',
          onDone: [
            {
              target:  'ScanningLabel',
              actions: ['assignStagingAuditingContainer']
            }
          ],
          onError: {
            target:  'AuditingContainers',
            actions: ['assignError']
          }
        }
      },
      ScanningLabel: {
        invoke: {
          id:   ScanLabelMachine.id,
          src:  ScanLabelMachine,
          data: _ctx => ({
            ...DefaultScanLabelContext,
            hint:            'Confirme escaneando Bulto',
            allowContainers: true
          }),
          onDone: {
            target:  'MarkingItemAsAuditing',
            actions: ['assignItemLabel', 'clearError']
          },
          onError: {
            target:  'ScanningLabel',
            actions: ['assignError']
          }
        },
        on: {
          CLOSE_CONTAINER: {
            target: 'MarkingContainerAsAudited'
          }
        }
      },
      MarkingContainerAsAudited: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StagingAuditingContainerAudited',
              payload: {
                stagingAuditingContainerId: ctx.stagingAuditingContainer!.id
              }
            };
          },
          onDone: {
            target:  'FetchingStagingAuditingContainersCount',
            actions: ['clearAuditingContainer']
          },
          onError: {
            target:  'ScanningLabel',
            actions: ['assignError']
          }
        }
      },
      MarkingItemAsAuditing: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StagingAuditingItemAuditing',
              payload: {
                label:                          ctx.itemLabel!,
                stagingAuditingWaveContainerId:
                  ctx.stagingAuditingContainer!.id,
                stagingAuditingWaveId: ctx.stagingAuditingWaveId
              }
            };
          },
          onDone: {
            target:  'ScanningLabel',
            actions: ['clearItemLabel']
          },
          onError: {
            target:  'ScanningLabel',
            actions: ['assignError', 'clearItemLabel']
          }
        }
      },
      FetchingMismatchedContainers: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchMismatchedContainersCount',
          onDone: [
            {
              target:  'FinishingWithMismatchedContainers',
              actions: ['assignContainersMismatched'],
              cond:    'hasMismatchedContainers'
            },
            {
              target: 'Finishing'
            }
          ],
          onError: {
            target:  'ScanningLabel',
            actions: ['assignError']
          }
        }
      },
      FinishingWithMismatchedContainers: {
        on: {
          FINISHING: 'CompletingStagingAuditingWave',
          CANCEL:    'FetchingStagingAuditingContainersCount'
        }
      },
      Finishing: {
        on: {
          FINISHING: 'CompletingStagingAuditingWave',
          CANCEL:    'FetchingStagingAuditingContainersCount'
        }
      },

      CompletingStagingAuditingWave: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StagingAuditingWaveCompleted',
              payload: {
                stagingAuditingWaveId: ctx.stagingAuditingWaveId,
                taskId:                ctx.task.id
              }
            };
          },
          onDone: {
            target: 'Done'
          },
          onError: {
            target:  'FetchingMismatchedContainers',
            actions: 'assignError'
          }
        }
      },
      Done: {
        type: 'final'
      },
      PartiallyFinishingAuditingWave: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StagingAuditingWavePartiallyCompleted',
              payload: {
                stagingAuditingWaveId: ctx.stagingAuditingWaveId
              }
            };
          },
          onDone: {
            target: 'FetchingStagingAuditingContainersCount'
          }
        }
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      EndingAudit: {
        target: 'PartiallyFinishingAuditingWave'
      }
    }
  },
  {
    guards:  StagingAuditingGuards,
    actions: {
      ...stagingAuditingActions,
      triggerMenuScreen: (
        _ctx,
        { send, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        const options: MenuItemProps[] = [];
        options.push({
          label:   'Fin de auditoría',
          onClick: () => send('EndingAudit')
        });
        triggerMenuScreen(options);
      }
    },
    services: StagingAuditingServices
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { NoSortableSlottingContainer } from './NoSortableSlottingContainer';
import { Receipt } from './Receipt';
import {
  NoSortableSlottingProcessStatus,
  NoSortableSlottingProcessStatusCompletedStatus,
  NoSortableSlottingProcessStatusPausedStatus,
  NoSortableSlottingProcessStatusPendingStatus,
  NoSortableSlottingProcessStatusProcessingStatus
} from './no-sortable-slotting-process-status';

export const NoSortableSlottingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Paused:     'paused'
};

@Entity()
export class NoSortableSlottingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [NoSortableSlottingProcessStatuses.Pending]:
      NoSortableSlottingProcessStatusPendingStatus,
    [NoSortableSlottingProcessStatuses.Processing]:
      NoSortableSlottingProcessStatusProcessingStatus,
    [NoSortableSlottingProcessStatuses.Completed]:
      NoSortableSlottingProcessStatusCompletedStatus,
    [NoSortableSlottingProcessStatuses.Paused]:
      NoSortableSlottingProcessStatusPausedStatus
  })
  status: NoSortableSlottingProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => NoSortableSlottingContainer, 'noSortableSlottingProcessId')
  noSortableSlottingContainers: NoSortableSlottingContainer[];
}

import { AxiosError } from 'axios';

export function getErrorView(
  error?: AxiosError,
  defaultErrorMessage: string = 'Error.'
): string | null {
  return error
    ? error.response?.data?.errorView
      ? error.response.data.errorView
      : error.message
      ? error.message
      : defaultErrorMessage
    : null;
}

export function getErrorType(error?: AxiosError): string | null {
  return error
    ? error.response?.data?.type
      ? error.response.data.type
      : null
    : null;
}

export function getErrorCode(error?: AxiosError): number | null {
  return error
    ? error.response?.status
      ? error.response.status
      : error.response?.data?.statusCode
      ? error.response.data.statusCode
      : null
    : null;
}

import { MovePendingDispatchingItemStatuses } from '../MovePendingDispatchingItem';
import { MovePendingDispatchingItemStatus } from './MovePendingDispatchingItemStatus';

export class MovePendingDispatchingItemMovedStatus extends MovePendingDispatchingItemStatus {
  public displayName = 'Movido';

  public internalName = MovePendingDispatchingItemStatuses.Moved;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { ImpureSortableCrossdockClassificationProcessStatuses } from '../ImpureSortableCrossdockClassificationProcess';
import { ImpureSortableCrossdockClassificationProcessStatus } from './ImpureSortableCrossdockClassificationProcessStatus';

export class ImpureSortableCrossdockClassificationProcessStatusPendingStatus extends ImpureSortableCrossdockClassificationProcessStatus {
  public displayName = 'Pendiente';

  public internalName =
    ImpureSortableCrossdockClassificationProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MovingToPreSorterProcessStatuses } from '../MovingToPreSorterProcess';
import { MovingToPreSorterProcessStatus } from './MovingToPreSorterProcessStatus';

export class MovingToPreSorterProcessStatusCompletedStatus extends MovingToPreSorterProcessStatus {
  public displayName = 'Completado';

  public internalName = MovingToPreSorterProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { MachineOptions, assign } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { GetSellerProductByCodeContext } from './machine';

type GetSellerProductByCodeActions = MachineOptions<
  GetSellerProductByCodeContext,
  any
>['actions'];

export const getSellerProductByCodeActions: GetSellerProductByCodeActions = {
  updateSellerProductCode: assign({
    sellerProductCode: (_context, event) => event.sellerProductCode
  }),
  assignSellerProduct: assign({
    sellerProduct: (_context, event) => event.data.data
  }),
  assignError: assign({
    error: (_context, event) => event.data.error
  }),
  clearParentError: sendParent('clearError'),
  clearError:       assign({
    error: (_context, _event) => null
  })
};

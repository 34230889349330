import { PendingDispatchingPickingWaveStatuses } from '../PendingDispatchingPickingWave';
import { PendingDispatchingPickingWaveStatus } from './PendingDispatchingPickingWaveStatus';

export class PendingDispatchingPickingWaveCompletedStatus extends PendingDispatchingPickingWaveStatus {
  public displayName = 'Completado';

  public internalName = PendingDispatchingPickingWaveStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

import {
  StagingAuditingItem,
  StagingAuditingItemPositiveMismatchedStatus
} from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { Page } from '../../../../types/page';
import { ResolveMismatchedAuditingWaveContext } from './machine';

type ResolveMismatchedAuditingWaveServicesType = MachineOptions<
  ResolveMismatchedAuditingWaveContext,
  any
>['services'];

export const ResolveMismatchedAuditingWaveServices: ResolveMismatchedAuditingWaveServicesType =
  {
    fetchingStagingAuditingWaveItems: async (ctx, _evt) => {
      const { data } = await api.get<Page<StagingAuditingItem>>(
        `staging-auditing-wave/${ctx.stagingAuditingWaveId}/staging-auditing-item`
      );

      // Devolver solo los items con descuadre. Agarrando logica del frontend.
      return {
        stagingAuditingItems: data.rows.filter(item => {
          if (
            item.expectedQuantity === item.auditedQuantity &&
            item.scannedStagingAuditingContainerId ===
              item.stagingAuditingContainerId &&
            !(
              item.status instanceof StagingAuditingItemPositiveMismatchedStatus
            )
          ) {
            return false;
          }
          return true;
        })
      };
    },
    fetchingStagingAuditingWaveOrderType: async (ctx, _evt) => {
      const { data } = await api.get(
        `staging-auditing-wave/${ctx.stagingAuditingWaveId}/order-type`
      );

      return {
        orderType: data
      };
    },
    closeMismatchingAuditingWave: async (ctx, _evt) => {
      const params = new URLSearchParams();
      params.append('taskId', ctx.task.id.toString());
      await api.post(
        `staging-auditing-wave/${ctx.stagingAuditingWaveId}/complete-mismatch`,
        null,
        { params }
      );
    }
  };

import { Location, PendingDispatchingPickItem, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-requested-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { pickPendingDispatchingTaskActions } from './actions';
import { pickPendingDispatchingTaskGuards } from './guards';
import { pickPendingDispatchingTaskServices } from './services';

export interface PickPendingDispatchingTaskContext {
  task: Task;
  error: string | null;
  pickingDispatchingLocation: Location | null;
  pickingWaveId: number;
  totalItems: number | null;
  pendingItems: number | null;
  currentPickItem: PendingDispatchingPickItem | null;
  stagingLocation: Location | null;
}

export const DefaultPickPendingDispatchingTaskContext: PickPendingDispatchingTaskContext =
  {
    task:                       {} as Task,
    error:                      null,
    pickingDispatchingLocation: null,
    pickingWaveId:              -1,
    totalItems:                 0,
    pendingItems:               0,
    currentPickItem:            null,
    stagingLocation:            null
  };

export const PickPendingDispatchingTaskMachine = createMachine(
  {
    id:      'PickPendingDispatchingTask',
    initial: 'Initializing',
    states:  {
      Initializing: {
        invoke: {
          src:    'getPendingDispachingLocation',
          onDone: {
            target:  'FetchingStagingLocation',
            actions: 'setPickingDispatchingLocation'
          },
          onError: {
            actions: 'assignError',
            target:  ''
          }
        }
      },
      FetchingStagingLocation: {
        invoke: {
          src:    'getStagingLocation',
          onDone: {
            target:  'ScanPendingDispachingLocation',
            actions: 'assignStagingLocation'
          }
        }
      },
      ScanPendingDispachingLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion requerida',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'Por favor, escanee la ubicación requerida',
                ctx.pickingDispatchingLocation!.name
              ),
              {
                name:     ctx.pickingDispatchingLocation!.name,
                hint:     'UBI. REQUERIDA',
                required: true
              }
            ),
          onDone: {
            target: 'FetchingItemsCount'
          }
        }
      },
      FetchingItemsCount: {
        invoke: {
          src:    'fetchPendingItemsCount',
          onDone: [
            {
              target:  'FetchingPickItem',
              actions: 'assignPendingAndTotalItems',
              cond:    'pendingItemsRemaining'
            },
            {
              target: 'FinishingPickingWave'
            }
          ]
        }
      },
      FetchingPickItem: {
        invoke: {
          src:    'getNextPendingDispachingpickItem',
          onDone: {
            target:  'ScanningPickItem',
            actions: 'assignCurrentPickItem'
          }
        }
      },
      ScanningPickItem: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee etiqueta de contenedor',
              getRequestedContainerByLpn(
                'Ocurrio un error, intenta nuevamente',
                'El contenedor escaneado no es valido',
                ctx.currentPickItem!.reservationPendingContainer.container.lpn
              ),
              {
                lpn: ctx.currentPickItem!.reservationPendingContainer.container
                  .lpn,
                hint: 'CONT. REQUERIDO'
              }
            ),
          onDone: [
            {
              target: 'ScanningStagingLocation'
            }
          ]
        }
      },
      ScanningStagingLocation: {
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Ingrese dígito de control requerido',
              getRequestedCheckDigit(
                'No se encontro una ubicación con ese codigo de verificación',
                'Ingrese el dígito de control requerido',
                ctx.stagingLocation!.checkDigit
              ),
              {
                checkDigit: ctx.stagingLocation!.checkDigit,
                hint:       'Dígito de control',
                required:   true
              }
            ),
          onDone: [
            {
              target: 'MarkAsPicked'
            }
          ]
        }
      },
      MarkAsPicked: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'PendingDispatchingItemPicked',
              payload: {
                currentPickItem: ctx.currentPickItem,
                stagingLocation: ctx.stagingLocation
              }
            };
          },
          onDone: {
            target: 'FetchingItemsCount'
          }
        }
      },
      FinishingPickingWave: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'PendingDispatchingWaveCompleted',
              payload: {
                pendingDispatchingPickingWaveId: ctx.pickingWaveId
              }
            };
          },
          onDone: {
            target: 'PendingDispatchingPickItemMoved'
          }
        }
      },
      PendingDispatchingPickItemMoved: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'PendingDispatchingPickItemMoved',
              payload: {
                pendingDispatchingPickingWaveId: ctx.pickingWaveId,
                containerId:
                  ctx.currentPickItem!.reservationPendingContainer.container.id
              }
            };
          },
          onDone: {
            target: 'Finished'
          }
        }
      },
      Finished: {
        type: 'final'
      }
    }
  },
  {
    guards:   pickPendingDispatchingTaskGuards,
    actions:  pickPendingDispatchingTaskActions,
    services: pickPendingDispatchingTaskServices
  }
);

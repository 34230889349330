import { ImpureSortableCrossdockClassificationProcessStatuses } from '../ImpureSortableCrossdockClassificationProcess';
import { ImpureSortableCrossdockClassificationProcessStatus } from './ImpureSortableCrossdockClassificationProcessStatus';

export class ImpureSortableCrossdockClassificationProcessStatusCompletedStatus extends ImpureSortableCrossdockClassificationProcessStatus {
  public displayName = 'Completado';

  public internalName =
    ImpureSortableCrossdockClassificationProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { SlottingItemMachine } from './machine';

export const SlottingItemTask = withActorRef(SlottingItemMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Guardado Pallet {current.context.containerLpn || 'N/A'}
          </Ink.Text>
        </Ink.Header>
        {/* Header */}
        <Ink.Box flexDirection='column'>
          {current.matches('ScanningControlDigit') ? (
            <>
              <HighlightedInfo
                attribute='Dirijase al canal'
                value={current.context.stagingLocation!.name}
              />
              <ScanControlDigit />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          ) : null}

          {current.matches('ScanningContainer') ? (
            <>
              <Ink.Text> Tome posesion del contenedor</Ink.Text>
              <HighlightedInfo
                attribute='Se Requiere'
                value={
                  current.context.suggestedSlottingItem!.slottingContainer
                    .container!.lpn
                }
              />
              <GetContainerByLpn />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          ) : null}

          {current.matches('ScanningLocation') ? (
            <>
              <Ink.Text>Contenedor</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.containerType!.name} -{' '}
                {
                  current.context.suggestedSlottingItem!.slottingContainer
                    .container.lpn
                }{' '}
              </Ink.Text>
              <Ink.Text />
              <Ink.Text> Destino de almacenamiento</Ink.Text>
              <HighlightedInfo
                attribute='Trasladese hasta ubicacion'
                value={current.context.suggestedDestinyLocation!.name}
              />
              <GetLocationByName />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          ) : null}
          {current.matches('SlottingConfirmedScreen') ? (
            <>
              <Ink.Text color='greenBright' bold>
                ¡El contenedor fue almacenado con exito!
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Button label='Continuar' onClick={() => send('CONFIRM')} />
            </>
          ) : null}

          {current.hasTag('loading') ? <Ink.Text>Cargando...</Ink.Text> : null}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

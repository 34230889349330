import { DispatchingWaveStatuses } from '../DispatchingWave';
import { DispatchingWaveStatus } from './DispatchingWaveStatus';

export class DispatchingWaveCompletedStatus extends DispatchingWaveStatus {
  public displayName = 'Completado';

  public internalName = DispatchingWaveStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

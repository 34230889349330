import {
  Container,
  InventoryItem,
  InventoryItemStatuses,
  Location,
  Lot
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';

import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../scan-item/ScanItemMachine';

export interface RestockLineContext {
  container: Container | null;
  item: InventoryItem | null;
  lot: Lot | null;
  restockedItems: InventoryItem[];
  itemToRestock: InventoryItem | null;
  containers: Container[];
  destinationContainer?: Container | null;
  location?: Location | null;
  pickingWaveId?: number;
  pickingProcessId?: number;
  orderId?: number;
  isRestocking: boolean | null;
  isBulkItem: boolean | null;
  containersInLocation: Container[];
}

export const DefaultRestockLineContext: RestockLineContext = {
  location:             null,
  container:            null,
  item:                 null,
  lot:                  null,
  restockedItems:       [],
  itemToRestock:        null,
  containers:           [],
  isRestocking:         null,
  isBulkItem:           null,
  containersInLocation: []
};

export const RestockLineMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlk3XwEkAXMVAYggHtCSCA3ZgazBIGUK1OqkSgADs1i4auVmJAAPRACYAjAA4SKgMwAGACwA2AJxqA7AfMaVugDQgAnogvmSxgKwmdRvScsmBt4AvsEOaFh4hKTiADZYYLT0VPgACriY3ARQAMKsNOgEYABOTKx8nDx8qfGYiSIpNMx5+AVFxQCykUUKktKy8khKiDo2JBoaHgZ6ah7mRjazHg7OCCZ6JCbrGmZ6RgY65rYaoeEYOEUxtfXJaRlZ+Ln5hYSlVAAqAKIdAPqpADIAQRynwAIj8qAA5H45ADykPegKhnwASr0pDI5PgFMoEOZDCR9jp1FYjCo9DpDisXB4dO5zEcVKZzKNNP5QmEQPhmBA4AoIhdomQhElREM+pjBqBcQYVNSEGojB53GSFmqNEY1OpzKcQAKosQSHEEqKUulMtkWm1Xuj+licSMtuN1mS9HoPBoDmp5ZoVJsPZTrIYNOY1AZdfrLiRzQ8nqg4mA6LbJdihriTBo6RZM0c9rKPHpzPLyVoDEEQ6TTIETCoI+cDURkwNU9KXBofVo3W61Do1N4PKYThygA */
  createMachine<RestockLineContext>(
    {
      id:      'RestockLine',
      initial: 'Initialize',
      context: DefaultRestockLineContext,
      states:  {
        Initialize: {
          entry: assign({
            itemToRestock: ctx => ctx.item
          }),
          always: [{ target: 'UpdateQuantityToRestock' }]
        },

        UpdateQuantityToRestock: {
          invoke: {
            src:    'getQuantityToRestock',
            onDone: [
              {
                cond:   (_ctx, evt) => evt.data.suggestedQuantity === 0,
                target: 'RestockComplete'
              },
              {
                cond:    'checkIfBulkItem',
                target:  'ScanItem',
                actions: assign({
                  item: (ctx, evt) =>
                    ({
                      ...ctx.item,
                      quantity: evt.data.suggestedQuantity
                    } as InventoryItem)
                })
              },
              {
                target:  'ScanOriginContainer',
                actions: assign({
                  item: (ctx, evt) =>
                    ({
                      ...ctx.item,
                      quantity: evt.data.suggestedQuantity
                    } as InventoryItem)
                })
              }
            ]
          }
        },

        UpdateQuantityToRestockForStartedTask: {
          invoke: {
            src:    'getQuantityToRestock',
            onDone: [
              {
                cond:   (_ctx, evt) => evt.data.suggestedQuantity === 0,
                target: 'RestockComplete'
              },
              {
                target:  'ScanItem',
                actions: assign({
                  item: (ctx, evt) =>
                    ({
                      ...ctx.item,
                      quantity: evt.data.suggestedQuantity
                    } as InventoryItem)
                })
              }
            ]
          }
        },

        ScanOriginContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: ctx.container
            }),
            onDone:  'ScanItem',
            onError: 'ScanOriginContainer'
          }
        },

        ScanItem: {
          invoke: {
            id:   ScanItemMachine.id,
            src:  ScanItemMachine,
            data: (context: RestockLineContext) => ({
              ...DefaultScanItemContext,
              requestedItem: context.item,
              location:      (context.item as InventoryItem)?.location,
              container:
                context.container || (context.item as InventoryItem).container,
              lotNumber:
                context.lot?.lotNumber ||
                (context.item as InventoryItem)?.lotNumber,
              status: !context.location
                ? InventoryItemStatuses.Reserved
                : undefined,
              isBulkItem:     !!context.isBulkItem,
              findNearestQty: true
            }),
            onDone: [
              {
                actions: 'assignItem',
                target:  'GetContainersInDestinyLocation'
              }
            ]
          }
        },
        GetContainersInDestinyLocation: {
          invoke: {
            src:    'getContainersInLocation',
            onDone: [
              {
                cond:   (_ctx, evt) => evt.data.containersInLocation.length === 0,
                target: 'MoveItem'
              },
              {
                target:  'AskMoveToContainerOrLocation',
                actions: assign({
                  containersInLocation: (_ctx, evt) =>
                    evt.data.containersInLocation
                })
              }
            ]
          }
        },
        AskMoveToContainerOrLocation: {
          on: {
            CONTAINER_SELECTED: 'ScanDestinationContainer',
            LOCATION_SELECTED:  {
              actions: assign({
                destinationContainer: (_ctx, _evt) => null
              }),
              target: 'MoveItem'
            }
          }
        },
        ScanDestinationContainer: {
          invoke: {
            src:  ScanContainerMachine,
            id:   ScanContainerMachine.id,
            data: ctx => ({
              ...DefaultScanContainerContext,
              suggestedContainer: ctx.containersInLocation[0],
              validContainers:    ctx.containersInLocation
            }),
            onDone: {
              actions: 'assignDestinationContainer',
              target:  'MoveItem'
            }
          }
        },

        MoveItem: {
          invoke: {
            src:    'updateItemContainerAndLocation',
            onDone: [
              {
                cond:    'quantityRestocked',
                target:  'RestockComplete',
                actions: 'addToRestockedItems'
              },
              {
                target:  'UpdateQuantityToRestockForStartedTask',
                actions: 'addToRestockedItems'
              }
            ],
            onError: 'FatalError'
          }
        },

        RestockComplete: {
          type: 'final',
          data: ctx => ({
            item: ctx.item
          })
        },

        FatalError: {}
      }
    },
    {
      guards: {
        quantityRestocked: (ctx, event) => {
          const restockedQuantity: number =
            ctx.restockedItems.reduce(
              (quantity, accItem) => quantity + accItem.quantity,
              0
            ) + (event.data.item?.quantity || 0);
          return restockedQuantity >= (ctx.itemToRestock?.quantity || 0);
        },
        isReadjusting:           ctx => !ctx.location,
        hasDestinationContainer: ctx => !!ctx.destinationContainer,
        checkIfBulkItem:         ctx => !!ctx.isBulkItem
      },
      actions: {
        ...UtilityActions,
        assignContainers: assign({
          containers: (_ctx, event) => event.data.containers
        }),
        addToRestockedItems: assign({
          restockedItems: (ctx, event) => [
            ...ctx.restockedItems,
            event.data.item
          ]
        }),
        addToContainers: assign({
          containers: (ctx, event) => [...ctx.containers, event.data.container]
        }),
        assignDestinationContainer: assign({
          destinationContainer: (_ctx, event) => event.data.container
        })
      },
      services: {
        getProcessContainers: async ctx => {
          const { path, id } = ctx.pickingWaveId
            ? { path: 'picking-wave', id: ctx.pickingWaveId }
            : { path: 'picking-process', id: ctx.pickingProcessId };

          const { data: containers } = await api.get(
            `${path}/${id}/containers`
          );

          return { containers };
        },
        updateItemContainerAndLocation: async ctx => {
          const { data: item } = await api.post(
            `inventory-item/${ctx.item?.id}/move`,
            {
              ...(ctx.destinationContainer
                ? { newContainerLpn: ctx.destinationContainer.lpn }
                : {}),
              location:         ctx.destinationContainer?.location || ctx.location,
              quantityToChange: ctx.item?.quantity
            }
          );

          return { item };
        },
        getQuantityToRestock: async ctx => {
          const { data: response } = await api.get(
            `location/${ctx.location?.id}/suggest-restocking-quantity`,
            {
              params: {
                productId:         ctx.item?.product?.id,
                originContainerId: ctx.container?.id
              }
            }
          );

          return { suggestedQuantity: response.suggestedQuantity };
        },
        ...API
      }
    }
  );

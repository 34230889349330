import { useActor } from '@xstate/react';
import React, { useEffect } from 'react';
import { handleErrorPopUp } from '../../../../utils/pop-up-layer-4';
import { withActorRef } from '../../../shared/Machine';
import { PopUpMachine, PopUpMachineId } from './machine';

export const PopUp = withActorRef(
  PopUpMachine,
  PopUpMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  useEffect(() => {
    current.context.alertData &&
      handleErrorPopUp(current.context.alertData).then(result => {
        if (result.isConfirmed) {
          send({ type: 'closePopUp' });
        }
      });
  }, [current.context.alertData]);

  return <></>;
});

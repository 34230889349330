import { StandardUnloadingProcessStatuses } from '../StandardUnloadingProcess';
import { StandardUnloadingProcessStatus } from './StandardUnloadingProcessStatus';

export class StandardUnloadingProcessStatusCompletedStatus extends StandardUnloadingProcessStatus {
  public displayName = 'Completado';

  public internalName = StandardUnloadingProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

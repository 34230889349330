import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemPositiveMismatchedStatus extends StagingAuditingItemStatus {
  public displayName = 'Auditado con descuadre positivo';

  public internalName = StagingAuditingItemStatuses.PositiveMismatched;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

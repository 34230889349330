import { api } from '../../../../api/utils/axios-instance';

export const bulkMovementServices = {
  fetchOriginContainer: async ctx => {
    const { data } = await api.get(`container/lpn/${ctx.originLabel}`);
    return {
      originContainer: data
    };
  },
  fetchDestinyContainer: async ctx => {
    const { data } = await api.get(`container/lpn/${ctx.destinyLabel}`);
    return {
      destinyContainer: data
    };
  },

  fetchOriginLocation: async ctx => {
    const { data } = await api.get('location/name', {
      params: { name: ctx.originLabel }
    });
    return {
      originLocation: data
    };
  },

  fetchDestinyLocation: async ctx => {
    const { data } = await api.get(
      'location/valid-bulk-movement-destiny-location',
      {
        params: { name: ctx.destinyLabel }
      }
    );
    return {
      destinyLocation: data
    };
  },

  checkIfOriginLocationHasMoreThanOneContainer: async ctx => {
    const { data } = await api.get(
      `location/${ctx.originLocation.id!}/containers`
    );
    return data.length > 1;
  },

  checkIfDestinyLocationHasMoreThanOneContainer: async ctx => {
    const { data } = await api.get(
      `location/${ctx.destinyLocation.id!}/containers`
    );
    return data.length > 1;
  },

  checkIfProductIsInOriginContainer: async ctx => {
    const { data } = await api.get(
      'location/verify-availability-in-location-by-id',
      {
        params: {
          locationId:  ctx.originContainer?.locationId || ctx.originLocation?.id,
          itemSku:     ctx.productToMove?.sku,
          containerId: ctx.originContainer?.id
        }
      }
    );

    return !!data;
  },

  validateInventoryItemQuantity: async ctx => {
    const { data } = await api.post(
      `product/${ctx.productToMove.id}/validating-quantity-to-bulk-movement`,
      {
        originLocationId:  ctx.originLocation.id,
        originContainerId: ctx.originContainer.id,
        quantity:          ctx.quantity
      }
    );
    return data;
  },

  moveProductToLocation: async ctx => {
    await api.post(
      `product/${ctx.productToMove.id}/bulk-movement-to-location`,
      {
        originLocationId:   ctx.originLocation?.id,
        destinyLocationId:  ctx.destinyLocation?.id,
        originContainerId:  ctx.originContainer?.id,
        destinyContainerId: ctx.destinyContainer?.id,
        quantity:           ctx.quantity,
        itemStatus:         ctx.itemStatus
      }
    );
  },

  checkDestinyAvailabledQuantity: async ctx => {
    const locationId =
      ctx?.destinyLocation?.id || ctx?.destinyContainer?.locationId;
    const { data } = await api.get(
      `location/${locationId}/available-quantity`,
      {
        params: {
          productId: ctx.productToMove?.id
        }
      }
    );
    return data;
  }
};

import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusConsolidatingStatus extends OrderStatus {
  public displayName = 'Consolidando';

  public internalName = OrderStatuses.Consolidating;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

import { FeedingToSorterContainerStatuses } from '../FeedingToSorterContainer';
import { FeedingToSorterContainerStatus } from './FeedingToSorterContainerStatus';

export class FeedingToSorterContainerStatusPendingStatus extends FeedingToSorterContainerStatus {
  public displayName = 'Pendiente';

  public internalName = FeedingToSorterContainerStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

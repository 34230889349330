import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../../capa-4/create-container/CreateContainer';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { PopUp } from '../../layer-4/pop-up/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { ReportAnomalyMachine } from './machine';

export const ReportAnomaly = withActorRef(ReportAnomalyMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const handleClick = useCallback(
      containerType => send({ type: 'select', data: { containerType } }),
      [send]
    );

    const goTo = useCallback(state => send({ type: state }), [send]);
    return (
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Reportar Anomalía</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column'>
          {current.matches('ScanningLocation') && (
            <>
              <GetLocationByName id='ScanningLocation' />
            </>
          )}
          {current.matches('ScanningSKU') && (
            <>
              <GetProductBySKU />
            </>
          )}
          {current.matches('EnteringItemsQuantity') && (
            <>
              <EnterQuantity />
            </>
          )}
          {current.matches('PickingContainerType') && (
            <>
              <Ink.Text>
                {' '}
                Seleccione el tipo de contenedor a utilizar:{' '}
              </Ink.Text>
              {current.context.availableContainerTypes!.map(item => (
                <Ink.Button
                  key={item.id}
                  id={item.id.toString()}
                  label={item.name}
                  onClick={() => handleClick(item)}
                />
              ))}
            </>
          )}
          {current.matches('ScanningContainerLpn') && (
            <>
              <HighlightedInfo
                attribute='Tipo de contenedor'
                value={current.context.containerType?.name!}
              />
              <CreateContainer />
            </>
          )}
          {current.matches('ScanningAnomalyContainerCreated') && (
            <>
              <GetContainerByLpn id='ScanningAnomalyContainerCreated' />
            </>
          )}
          {current.matches('ConfirmingTransfer') && (
            <>
              <Ink.Text>Mueva el item</Ink.Text>

              <HighlightedInfo
                attribute='- SKU'
                value={current.context.product!.sku}
              />
              <HighlightedInfo
                attribute='- Cantidad'
                value={current.context.productQuantity}
              />
              <HighlightedInfo
                attribute='- Producto'
                value={current.context.product!.name}
              />
              <HighlightedInfo
                attribute='Al contenedor'
                value={current.context.anomalyContainer!.lpn}
              />

              <Ink.Button
                label='Confirmar Traspaso'
                onClick={() => send({ type: 'confirm' })}
              />
            </>
          )}
          {current.matches('ScanningConfirmationContainerLpn') && (
            <>
              <Ink.Text bold>
                Traslade a la ubicación de guardería y luego escanee
              </Ink.Text>
              <HighlightedInfo
                attribute='Contenedor sugerido'
                value={current.context.anomalyContainer!.lpn}
              />
              <GetContainerByLpn id='ScanningConfirmationContainerLpn' />
            </>
          )}
          {current.matches('AskingUserDecision') && (
            <>
              <Ink.Button
                label='Trasladar contenedor a zona de guarderia'
                onClick={() => {
                  goTo('completionOfAnomalyMarking');
                }}
              />
              <Ink.Button
                label='Continuar declarando anomalias'
                onClick={() => {
                  goTo('keepMarking');
                }}
              />
            </>
          )}
          {current.matches('ScanningDestinationLocation') && (
            <>
              <Ink.Text bold>
                Traslade a la ubicación de guardería y luego escanee
              </Ink.Text>
              <HighlightedInfo
                attribute='Ubicación sugerida'
                value={current.context.targetLocation!.name}
              />

              <GetLocationByName id='ScanningDestinationLocation' />
            </>
          )}
          {current.matches('AskToMoveContainersOrContinue') && (
            <>
              <Ink.Text>
                Quedan los siguientes contenedores a trasladar:
                <Ink.Chalk color='greenBright' bold>
                  {current.context.pendingContainersToMoveLpns?.toString()}
                </Ink.Chalk>
              </Ink.Text>

              <Ink.Text>
                ¿Desea trasladarlos a guardería o continuar declarando
                anomalias?
              </Ink.Text>

              <Ink.Button
                label='Trasladar contenedor a zona de guarderia'
                onClick={() => {
                  goTo('movePendingContainer');
                }}
              />
              <Ink.Button
                label='Continuar declarando anomalias'
                onClick={() => {
                  goTo('keepMarking');
                }}
              />
            </>
          )}
          {current.matches('ConfirmingScreen') && (
            <>
              <Ink.Text>
                Anomalía marcada&nbsp;
                <Ink.Chalk color='greenBright' bold>
                  correctamente
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Button
                label='Volver al menú'
                onClick={() => send({ type: 'finish' })}
              />
            </>
          )}

          {current.matches('HandleErrors') && <PopUp />}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { DispatchHDContext } from './machine';

type DispatchHDActions = MachineOptions<DispatchHDContext, any>['actions'];

export const dispatchHDActions: DispatchHDActions = {
  assignDispatchingWaveDetails: assign({
    dispatchingWave: (_ctx, event) => event.data.dispatchingWave
  }),
  assignSuggestedDispatchingItem: assign({
    suggestedDispatchingItem: (_ctx, event) => event.data.dispatchingItem
  }),
  assignItemCounts: assign({
    totalItems:  (_ctx, evt) => evt.data.counts.totalItems,
    pickedItems: (_ctx, evt) => evt.data.counts.pickedItems
  }),
  assignScannedLabel: assign({
    scannedLabel: (_ctx, evt) => evt.data.label
  }),
  assignTouchedEndOfLoading: assign({
    touchedEndOfLoading: (_ctx, _evt) => true
  }),
  assignPartiallyDispatchedReservation: assign({
    itemsToLoad:                     (_ctx, evt) => evt.data.itemsToLoad,
    itemsToUnload:                   (_ctx, evt) => evt.data.itemsToUnload,
    labelsToLoad:                    (_ctx, evt) => evt.data.labelsToLoad,
    labelsToUnload:                  (_ctx, evt) => evt.data.labelsToUnload,
    partiallyDispatchedReservations: (_ctx, evt) =>
      evt.data.partiallyDispatchedReservations
  }),
  assignError: assign({
    error: (_ctx, evt) =>
      getErrorView(
        evt.data?.data ?? evt.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearError: assign({
    error: (_, __) => null
  }),
  markAsNonReturningPoint: assign({
    nonReturnPoint: (_ctx, _evt) => true
  })
};

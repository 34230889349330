import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusDispatchingStatus extends OrderItemStatus {
  public displayName = 'Despachando';

  public internalName = OrderItemStatuses.Dispatching;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { ReceiptBatch } from './ReceiptBatch';

export enum ReceiptBatchTypes {
  // Las receiptBatch van a tener los mismos tipos que las receipts?
  Transferencia = 'Transferencia',
  StorePickup = 'colecta-sellers',
  Proveedor = 'Proveedor',
  Sellers = 'colecta-sellers',
  WMS = 'wms',
  TransferWmsFravega = 'transferencia-wms',
  SupplierWms = 'proveedor-wms',
  Novelty = 'novedades',
  Return = 'retiro-cambio'
}

@Entity()
export class ReceiptBatchType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'display_name' })
  displayName: string;

  @Field({ name: 'default_workflow' })
  defaultWorkflow: string;

  @Field()
  enabled: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ReceiptBatch, 'receiptBatchTypeId')
  receiptBatchs: ReceiptBatch[];
}

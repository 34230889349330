import { MachineOptions, assign } from 'xstate';
import { InventoryAdjustWaveContext } from './machine';

type InventoryAdjustWaveActions = MachineOptions<
  InventoryAdjustWaveContext,
  any
>['actions'];

export const inventoryAdjustWaveActions: InventoryAdjustWaveActions = {
  assignActualAdjustItem: assign({
    actualAdjustItem: (_ctx, event) => event.data.actualAdjustItem
  }),
  setQuantity: assign({
    reportedQuantity: (_ctx, evt) => evt.data.quantity
  }),
  setMismatchItems: assign({
    mismatchItems: (_ctx, evt) => evt.data.mismatchItems
  }),
  clearContext: assign({
    actualAdjustItem: _ctx => null,
    reportedQuantity: _ctx => 0,
    mismatchItems:    _ctx => [],
    productScanned:   _ctx => null
  }),
  assignError: assign({
    error: (_ctx, event) => event.data.errorView
  }),
  clearError: assign({
    error: (___, __) => null
  }),
  assignProductScanned: assign({
    productScanned: (_ctx, evt) => evt.data.product
  })
};

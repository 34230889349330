import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { DispatchingWave } from './DispatchingWave';
import { InventoryItem } from './InventoryItem';
import { MovePendingDispatchingItem } from './MovePendingDispatchingItem';
import { StagingAuditingItem } from './StagingAuditingItem';
import {
  DispatchingItemCancelledStatus,
  DispatchingItemDispatchedStatus,
  DispatchingItemNotDispatchedStatus,
  DispatchingItemPendingStatus,
  DispatchingItemStatus
} from './dispatching-item-status';

export const DispatchingItemStatuses = {
  Pending:       'pending',
  Dispatched:    'dispatched',
  Cancelled:     'cancelled',
  NotDispatched: 'not-dispatched'
};

@Entity()
export class DispatchingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [DispatchingItemStatuses.Pending]:       DispatchingItemPendingStatus,
    [DispatchingItemStatuses.Dispatched]:    DispatchingItemDispatchedStatus,
    [DispatchingItemStatuses.Cancelled]:     DispatchingItemCancelledStatus,
    [DispatchingItemStatuses.NotDispatched]: DispatchingItemNotDispatchedStatus
  })
  status: DispatchingItemStatus;

  @ForeignKey(() => DispatchingWave, { name: 'dispatching_wave_id' })
  dispatchingWaveId: number;

  @Field({ name: 'label' })
  label: string;

  @ForeignKey(() => StagingAuditingItem, { name: 'staging_auditing_item_id' })
  stagingAuditingItemId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => DispatchingWave, 'dispatchingWaveId')
  dispatchingWave: DispatchingWave;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Knows(() => StagingAuditingItem, 'stagingAuditingItemId')
  stagingAuditingItem: StagingAuditingItem;

  @NeededBy(() => MovePendingDispatchingItem, 'dispatchingItemId')
  dispatchingItem: MovePendingDispatchingItem;
}

import { api } from '../../../../api/utils/axios-instance';

export const ptwPackageInductionServices = {
  fetchPackingItemsFromPackageByLabel: async ctx => {
    const { data } = await api.get('package/package-label', {
      params: { label: ctx.packageLabel }
    });
    return data;
  },
  fetchTaskByPackageLabel: async ctx => {
    const { data } = await api.get(`task/task-by-package/${ctx.packageLabel}`);
    return data;
  }
};

import { Product, TransportOrder } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { GetItemByLabelContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetItemByLabelContext,
  any
>['guards'];

export const getItemByLabelGuards: GetLocationByNameGuards = {
  isValidItemLabel: (ctx, event) => {
    if (ctx.canScanBuckets) {
      if (TransportOrder.isValidTransportOrder(ctx.itemLabel)) {
        return true;
      } else {
        event.data = {
          ...event.data,
          error: 'La etiqueda escaneada es inválida'
        };
        return false;
      }
    }

    if (Product.isValidLabel(ctx.itemLabel)) {
      return true;
    } else {
      event.data = {
        ...event.data,
        error: 'La etiqueda escaneada es inválida'
      };
      return false;
    }
  },
  isSuccess:             (_context, event) => event.data.type === 'success',
  scalateErrorsToParent: context => context.scalateErrorsToParent
};

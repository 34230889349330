import { InventoryAdjustWaveStatuses } from '../InventoryAdjustWave';
import { InventoryAdjustWaveStatus } from './InventoryAdjustWaveStatus';

export class InventoryAdjustWavePausedStatus extends InventoryAdjustWaveStatus {
  public displayName = 'Pausada';

  public internalName = InventoryAdjustWaveStatuses.Paused;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingItem } from './AuditingItem';
import { AuditingWave } from './AuditingWave';
import { Comment } from './Comment';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { Order } from './Order';
import { OrderItem } from './OrderItem';
import { Product } from './Product';
import { PutAwayItem } from './PutAwayItem';
import { RejectionReason } from './RejectionReason';
import { StagingAuditingItem } from './StagingAuditingItem';
import { User } from './User';
import { WMSTransportOrder } from './WMSTransportOrder';
import { Warehouse } from './Warehouse';
import {
  IncidentStatus,
  IncidentStatusReportedStatus,
  IncidentStatusResolvedStatus
} from './incident-status';

export const IncidentStatuses = {
  Reported: 'reported',
  Resolved: 'resolved'
};

export enum mismatchTypes {
  NEGATIVE = 1,
  POSITIVE = 2,
  MIX = 0,
  EQUAL = 3,
  DIFF_CONTAINER = 4
}

export const IncidentTypes = {
  inventoryItem:                   'Anomalia de inventario',
  inventoryControl:                'Control de inventario',
  inventoryAdjustment:             'Ajuste de inventario',
  InsufficientQuantityInLocation:  'insufficientQuantityInLocation',
  TransportOrderClosedWithUtility: 'transportOrderClosedWithUtility',
  auditingQuantityMismatch:        'auditing-quantity-mismatch',
  miniMopMismatchedItem:           'miniMopMismatchedItem',
  miniMopMissingItems:             'miniMopMissingItems',
  ItemNotQualified:                'ItemNotQualified',
  PartiallyDispatchedOrder:        'partiallyDispatchedOrder',
  OpportunisticCountMismatch:      'opportunisticCountMismatch'
};

export const IncidentTypesVisibleForInventarist = [
  IncidentTypes.inventoryItem,
  IncidentTypes.inventoryControl,
  IncidentTypes.inventoryAdjustment,
  IncidentTypes.InsufficientQuantityInLocation,
  IncidentTypes.auditingQuantityMismatch,
  IncidentTypes.ItemNotQualified,
  IncidentTypes.OpportunisticCountMismatch
];

export const IncidentTypeDisplayName = {
  [IncidentTypes.inventoryItem]:                  'Anomalia de inventario',
  [IncidentTypes.inventoryControl]:               'Control de inventario',
  [IncidentTypes.inventoryAdjustment]:            'Ajuste de inventario',
  [IncidentTypes.InsufficientQuantityInLocation]:
    'Cantidad insuficiente en ubicación',
  [IncidentTypes.TransportOrderClosedWithUtility]:
    'Orden de transporte cerrada con utilidad',
  [IncidentTypes.auditingQuantityMismatch]: 'Descuadre en auditoría',
  [IncidentTypes.miniMopMismatchedItem]:
    'Producto sobrante en auditoría Mini Mop',
  [IncidentTypes.miniMopMissingItems]:
    'Productos faltantes en auditoría Mini Mop',
  [IncidentTypes.ItemNotQualified]:           'Item Anomalo',
  [IncidentTypes.PartiallyDispatchedOrder]:   'Orden despachada parcialmente',
  [IncidentTypes.OpportunisticCountMismatch]:
    'Descuadre en Conteo Oportunístico'
};

@Entity()
export class Incident {
  @PrimaryKey()
  id: number;

  type: string;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field()
  identifier: string;

  @Status({
    [IncidentStatuses.Reported]: IncidentStatusReportedStatus,
    [IncidentStatuses.Resolved]: IncidentStatusResolvedStatus
  })
  status: IncidentStatus;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => User, { name: 'reporter_id' })
  reporterId: number;

  @ForeignKey(() => User, { name: 'resolver_id' })
  resolverId: number;

  @Knows(() => User, 'reporterId')
  reporter: User;

  @Knows(() => User, 'resolverId')
  resolver: User;

  @Field({ name: 'previous_quantity' })
  previousQuantity: number;

  @Field({ name: 'current_quantity' })
  currentQuantity: number;

  @ForeignKey(() => RejectionReason, { name: 'rejection_reason_id' })
  rejectionReasonId: number;

  @Knows(() => RejectionReason, 'rejectionReasonId')
  rejectionReason: RejectionReason;

  @ForeignKey(() => OrderItem, { name: 'order_item_id' })
  orderItemId: number;

  @Knows(() => OrderItem, 'orderItemId')
  orderItem: OrderItem;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @ForeignKey(() => PutAwayItem, { name: 'put_away_item_id' })
  putAwayItemId: number;

  @Knows(() => PutAwayItem, 'putAwayItemId')
  putAwayItem: PutAwayItem;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Knows(() => Location, 'locationId')
  location: Location;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Knows(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Knows(() => Container, 'containerId')
  container: Container;

  @ForeignKey(() => WMSTransportOrder, { name: 'wms_transport_order_id' })
  wmsTransportOrderId: number;

  @Knows(() => WMSTransportOrder, 'wmsTransportOrderId')
  wmsTransportOrder: WMSTransportOrder;

  @Knows(() => Comment, 'incidentId')
  comment: Comment[];

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @Knows(() => Order, 'orderId')
  order: Order;

  @ForeignKey(() => StagingAuditingItem, { name: 'staging_auditing_item_id' })
  stagingAuditingItemId: number;

  @Knows(() => StagingAuditingItem, 'stagingAuditingItemId')
  stagingAuditingItem: StagingAuditingItem;

  @ForeignKey(() => AuditingItem, { name: 'auditing_item_id' })
  auditingItemId: number;

  @Knows(() => AuditingItem, 'auditingItemId')
  auditingItem: AuditingItem;

  @ForeignKey(() => AuditingWave, { name: 'auditing_wave_id' })
  auditingWaveId: number;

  @Knows(() => AuditingWave, 'auditingWaveId')
  auditingWave: AuditingWave;
}

import { api } from '../../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../../utils/fetch-result';
import { PrintFn } from '../../../machine';

export function printMiniMopInteriorContainer(
  defaultErrorMessage: string,
  auditingWaveId: number,
  locationId: number
): PrintFn {
  return fetchResult(defaultErrorMessage, () =>
    api.post('printer/mini-mop/auditing-item', {
      auditingWaveId: auditingWaveId,
      locationId:     locationId
    })
  );
}

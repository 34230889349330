import { Entity, Field, Knows, PrimaryKey } from '@skyframe/core';
import { Package } from './Package';

export enum PackTypes {
  CajaGrande = 'caja grande',
  CajaMediana = 'caja mediana',
  CajaPequeña = 'caja pequeña',
  BolsaGrande = 'bolsa grande',
  BolsaMediana = 'bolsa mediana',
  BolsaPequeña = 'bolsa pequeña'
}

@Entity()
export class PackType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  description: string;

  @Field()
  width: number;

  @Field()
  height: number;

  @Field()
  length: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Package, 'packTypeId')
  packages: Package[];
}

import { IncidentStatuses } from '../Incident';
import { IncidentStatus } from './IncidentStatus';

export class IncidentStatusReportedStatus extends IncidentStatus {
  public displayName = 'Reportado';

  public internalName = IncidentStatuses.Reported;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

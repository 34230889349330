import {
  Container,
  FT100LocationName,
  Location,
  MovePendingDispatchingItem,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getMovePendingDispatchingItemContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-move-pending-dispatching-item-container';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { movePendingDispatchingItemsMachineActions } from './actions';
import { movePendingDispatchingItemsMachineGuards } from './guards';
import { movePendingDispatchingItemsMachineServices } from './services';

export interface MovePendingDispatchingItemsContext {
  task: Task;
  movePendingDispatchingItemsWaveId: number;
  stagingLocation: Location | null;
  totalPendingDispatchingItemsToMove: number;
  pendingPendingDispatchingItemsToMove: number;
  suggestedNextPendingDispatchingItemToMove: MovePendingDispatchingItem | null;
  movePendingDispatchingItemContainer: Container | null;
  pendingDispatchingItemToMove: MovePendingDispatchingItem | null;
  destinyLocation: Location | null;
  error: string | null;
  suggestedDestinyLocationName: string | null;
}

export const DefaultMovePendingDispatchingItemsContext: MovePendingDispatchingItemsContext =
  {
    task:                                      {} as Task,
    movePendingDispatchingItemsWaveId:         -1,
    stagingLocation:                           null,
    totalPendingDispatchingItemsToMove:        0,
    pendingPendingDispatchingItemsToMove:      0,
    suggestedNextPendingDispatchingItemToMove: null,
    movePendingDispatchingItemContainer:       null,
    pendingDispatchingItemToMove:              null,
    destinyLocation:                           null,
    error:                                     null,
    suggestedDestinyLocationName:              FT100LocationName
  };

export const MovePendingDispatchingItemsMachine = createMachine(
  {
    id:      'movePendingDispatchingItems',
    initial: 'FetchingStagingLocation',
    context: {} as MovePendingDispatchingItemsContext,
    states:  {
      FetchingStagingLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'movePendingDispatchingItemsFetchStagingLocation',
          onDone: {
            target:  'ScanningControlDigit',
            actions: ['assignStagingLocation']
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: ['assignError']
          }
        }
      },
      ScanningControlDigit: {
        invoke: {
          src:  ScanControlDigitMachine,
          id:   ScanControlDigitMachine.id,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanee digito de control',
            requiredLocation: ctx.stagingLocation!
          }),
          onDone: {
            target: 'FetchingSuggestedNextMovePendingDispatchingItemToMove'
          },
          onError: {
            target:  'FetchingStagingLocation',
            actions: ['assignError']
          }
        }
      },

      FetchingSuggestedNextMovePendingDispatchingItemToMove: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPendingDispatchingItemsToMove',
          onDone: [
            {
              target:  'ScanningContainer',
              cond:    'hasPendingDispatchingItemsToMove',
              actions: 'assignPendingDispatchingItemsToMove'
            },
            {
              target: 'ConfirmingScreen'
            }
          ],
          onError: {
            target:  'FetchingStagingLocation',
            actions: ['assignError']
          }
        }
      },

      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando etiqueta de contenedor',
              getMovePendingDispatchingItemContainerByLpn(
                'Ocurrion un error, por favor intente de nuevo',
                ctx.movePendingDispatchingItemsWaveId
              )
            ),
          onDone: [
            {
              target:  'FetchingPendingDispatchingItemToMove',
              actions: [
                'assignMovePendingDispatchingItemContainer',
                'clearError'
              ]
            },
            {
              target: 'FetchingSuggestedNextMovePendingDispatchingItemToMove'
            }
          ],
          onError: {
            target:  'FetchingStagingLocation',
            actions: ['assignError']
          }
        }
      },

      FetchingPendingDispatchingItemToMove: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPendingDispatchingItemToMove',
          onDone: {
            target:  'ScanningDestinyLocation',
            actions: ['assignPendingDispatchingItemToMove']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },

      ScanningDestinyLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Confirme escaneando ubicación',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente.',
                'Por favor, Escanee la ubicacion requerida',
                ctx.suggestedDestinyLocationName!
              )
            ),
          onDone: {
            actions: ['assignDestinyLocation'],
            target:  'MarkingItemAsMoved'
          },
          onError: {
            actions: ['assignError'],
            target:  'ScanningDestinyLocation'
          }
        }
      },

      MarkingItemAsMoved: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingPendingItemMoved',
              payload: {
                movePendingDispatchingItemId:
                  ctx.pendingDispatchingItemToMove?.id,
                targetLocationId: ctx.destinyLocation!.id
              }
            };
          },
          onDone: [
            {
              target: 'FetchingSuggestedNextMovePendingDispatchingItemToMove'
            }
          ],
          onError: {
            target:  'FetchingStagingLocation',
            actions: ['assignError']
          }
        }
      },

      ConfirmingScreen: {
        on: {
          CONFIRM: {
            target: 'MarkingWaveAsCompleted'
          }
        }
      },

      MarkingWaveAsCompleted: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'DispatchingPendingWaveCompleted',
            payload: {
              movePendingDispatchingItemsWaveId:
                ctx.movePendingDispatchingItemsWaveId
            }
          }),
          onDone: {
            target: 'Done'
          },
          onError: {
            target:  'FetchingStagingLocation',
            actions: ['assignError']
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    services: movePendingDispatchingItemsMachineServices,
    actions:  movePendingDispatchingItemsMachineActions,
    guards:   movePendingDispatchingItemsMachineGuards
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Container } from './Container';
import { MovingToPreSorterContainer } from './MovingToPreSorterContainer';
import { NoSortableSlottingContainer } from './NoSortableSlottingContainer';
import { UnloadingProcess } from './UnloadingProcess';

@Entity()
export class UnloadingContainer {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sortable' })
  sortable: boolean;

  @Field({ name: 'pure' })
  pure: boolean;

  @Field({ name: 'expected_date' })
  expectedDate: Date;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => UnloadingProcess, { name: 'unloading_process_id' })
  unloadingProcessId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => UnloadingProcess, 'unloadingProcessId')
  unloadingProcess: UnloadingProcess;

  @NeededBy(() => MovingToPreSorterContainer, 'unloadingContainerId')
  movingToPreSorterContainer: MovingToPreSorterContainer;

  @NeededBy(() => NoSortableSlottingContainer, 'unloadingContainerId')
  noSortableSlottingContainer: NoSortableSlottingContainer;
}

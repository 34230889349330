import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingProcess } from './AuditingProcess';
import { ClassificationProcess } from './ClassificationProcess';
import { ConsolidateContainersProcess } from './ConsolidateContainersProcess';
import { ConsolidatingContainersToStagingProcess } from './ConsolidatingContainersToStagingProcess';
import { DispatchingProcess } from './DispatchingProcess';
import { MopSorterExitProcess } from './MopSorterExitProcess';
import { MovePendingDispatchingItemsProcess } from './MovePendingDispatchingItemsProcess';
import { MovingToAuditingLocationProcess } from './MovingToAuditingLocationProcess';
import { Order } from './Order';
import { PTWLocationProcess } from './PTWLocationProcess';
import { PackingLocationProcess } from './PackingLocationProcess';
import { PackingProcess } from './PackingProcess';
import { PendingDispatchingPickingProcess } from './PendingDispatchingPickingProcess';
import { PickingProcess } from './PickingProcess';
import { Process } from './Process';
import { SorterExitProcess } from './SorterExitProcess';
import { StagingAuditingProcess } from './StagingAuditingProcess';
import { Task } from './Task';
import { User } from './User';
import { Warehouse } from './Warehouse';
import {
  OrderBatchAuditedStatus,
  OrderBatchAuditingStatus,
  OrderBatchCancelledStatus,
  OrderBatchConsolidatedStatus,
  OrderBatchConsolidatingStatus,
  OrderBatchDeletedStatus,
  OrderBatchDispatchedStatus,
  OrderBatchDispatchingStatus,
  OrderBatchNotPreparedStatus,
  OrderBatchPreparedStatus,
  OrderBatchPreparingStatus,
  OrderBatchShippedStatus,
  OrderBatchStatus
} from './order-batch-status';
import { OrderBatchPartiallyDispatchedStatus } from './order-batch-status/OrderBatchStatusPartiallyDispatchedStatus';

export const OrderBatchStatuses = {
  NotPrepared:         'not-prepared',
  Preparing:           'preparing',
  Prepared:            'prepared',
  Auditing:            'auditing',
  Audited:             'audited',
  Dispatching:         'dispatching',
  Dispatched:          'dispatched',
  PartiallyDispatched: 'partially-dispatched',
  Consolidating:       'consolidating',
  Consolidated:        'consolidated',
  Shipped:             'shipped',
  Cancelled:           'cancelled',
  Deleted:             'deleted'
};

@Entity()
export class OrderBatch {
  @PrimaryKey()
  id: number;

  @Field()
  key: string;

  @Field()
  description: string;

  @Status({
    [OrderBatchStatuses.NotPrepared]:         OrderBatchNotPreparedStatus,
    [OrderBatchStatuses.Preparing]:           OrderBatchPreparingStatus,
    [OrderBatchStatuses.Prepared]:            OrderBatchPreparedStatus,
    [OrderBatchStatuses.Auditing]:            OrderBatchAuditingStatus,
    [OrderBatchStatuses.Audited]:             OrderBatchAuditedStatus,
    [OrderBatchStatuses.Dispatching]:         OrderBatchDispatchingStatus,
    [OrderBatchStatuses.Dispatched]:          OrderBatchDispatchedStatus,
    [OrderBatchStatuses.PartiallyDispatched]:
      OrderBatchPartiallyDispatchedStatus,
    [OrderBatchStatuses.Consolidated]:  OrderBatchConsolidatedStatus,
    [OrderBatchStatuses.Consolidating]: OrderBatchConsolidatingStatus,
    [OrderBatchStatuses.Shipped]:       OrderBatchShippedStatus,
    [OrderBatchStatuses.Cancelled]:     OrderBatchCancelledStatus,
    [OrderBatchStatuses.Deleted]:       OrderBatchDeletedStatus
  })
  status: OrderBatchStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'launched_at' })
  launchedAt: Date;

  @Knows(() => Order, 'orderBatchId')
  orders: Order[];

  @Knows(() => Task, 'orderBatchId')
  tasks: Task[];

  @NeededBy(() => ConsolidatingContainersToStagingProcess, 'orderBatchId')
  consolidatingContainersToStagingProcesses: ConsolidatingContainersToStagingProcess[];

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => User, { name: 'launched_by_user_id' })
  launchedByUserId: number;

  @Knows(() => User, 'launchedByUserId')
  launchedBy: User;

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Knows(() => PickingProcess, 'orderBatchId')
  pickingProcesses: PickingProcess[];

  @NeededBy(() => ClassificationProcess, 'orderBatchId')
  classificationProcesses: ClassificationProcess[];

  @NeededBy(() => PackingLocationProcess, 'orderBatchId')
  packingLocationProcess: PackingLocationProcess[];

  @NeededBy(() => PackingProcess, 'orderBatchId')
  packingProcesses: PackingProcess[];

  @NeededBy(() => SorterExitProcess, 'orderBatchId')
  sorterExitProcesses: SorterExitProcess[];

  @NeededBy(() => ConsolidateContainersProcess, 'orderBatchId')
  consolidateContainersProcesses: ConsolidateContainersProcess[];

  @NeededBy(() => AuditingProcess, 'orderBatchId')
  auditingProcesses: AuditingProcess[];

  @NeededBy(() => MovingToAuditingLocationProcess, 'orderBatchId')
  movingToAuditingLocationProcesses: MovingToAuditingLocationProcess[];

  @NeededBy(() => MopSorterExitProcess, 'orderBatchId')
  mopSorterExitProcesses: MopSorterExitProcess[];

  @NeededBy(() => StagingAuditingProcess, 'orderBatchId')
  stagingAuditingProcess: StagingAuditingProcess[];

  @NeededBy(() => DispatchingProcess, 'orderBatchId')
  dispatchingProcess: DispatchingProcess;

  @NeededBy(() => MovePendingDispatchingItemsProcess, 'orderBatchId')
  movePendingDispatchingItemsProcess: MovePendingDispatchingItemsProcess;

  @NeededBy(() => PTWLocationProcess, 'orderBatchId')
  PTWLocationProcesses: PTWLocationProcess[];

  @NeededBy(() => PendingDispatchingPickingProcess, 'orderBatchId')
  pendingDispatchingPickingProcess: PendingDispatchingPickingProcess;
}

import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusPartiallyDispatchedStatus extends ReservationOrderStatus {
  public displayName = 'Parcialmente despachado';

  public internalName = ReservationOrderStatuses.PartiallyDispatched;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryAdjustWave } from './InventoryAdjustWave';
import { Location } from './Location';
import { Product } from './Product';
import {
  InventoryAdjustItemAdjustedStatus,
  InventoryAdjustItemCancelledStatus,
  InventoryAdjustItemControlledStatus,
  InventoryAdjustItemPendingStatus,
  InventoryAdjustItemStatus
} from './inventory-adjust-item-status';

export const InventoryAdjustItemStatuses = {
  Pending:    'pending',
  Adjusted:   'adjusted',
  Controlled: 'controlled',
  Cancelled:  'cancelled'
};

export const InventoryAdjustItemCompletedStatuses = [
  InventoryAdjustItemStatuses.Adjusted,
  InventoryAdjustItemStatuses.Controlled
];

@Entity()
export class InventoryAdjustItem {
  @PrimaryKey()
  id: number;

  @Status({
    [InventoryAdjustItemStatuses.Pending]:    InventoryAdjustItemPendingStatus,
    [InventoryAdjustItemStatuses.Adjusted]:   InventoryAdjustItemAdjustedStatus,
    [InventoryAdjustItemStatuses.Controlled]:
      InventoryAdjustItemControlledStatus,
    [InventoryAdjustItemStatuses.Cancelled]: InventoryAdjustItemCancelledStatus
  })
  status: InventoryAdjustItemStatus;

  @Field({ name: 'reported_quantity' })
  reportedQuantity: number;

  @Field({ name: 'expected_quantity' })
  expectedQuantity: number;

  @Field()
  order: number;

  @ForeignKey(() => InventoryAdjustWave, { name: 'inventory_adjust_wave_id' })
  inventoryAdjustWaveId: number;

  @Needs(() => InventoryAdjustWave, 'inventoryAdjustWaveId')
  inventoryAdjustWave: InventoryAdjustWave;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Knows(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Needs(() => Location, 'locationId')
  location: Location;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { ManualRestockingMachine } from './machine';

export const ManualRestockingComponent = withActorRef(ManualRestockingMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Traslado de contenedores</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.hasTag('loading') ? <Ink.Text>Cargando...</Ink.Text> : null}

          {current.matches('ScanningOriginLocation') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName id='ScanOriginLocation' />
              <Ink.Text color='redBright'>{current.context.errorMsg}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningOriginContainer') && (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
              <Ink.Text color='redBright'>{current.context.errorMsg}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningTargetLocation') && (
            <Ink.Box flexDirection='column'>
              {current.context.targetLocation ? (
                <>
                  <HighlightedInfo
                    attribute='Ingrese ubicación de destino'
                    value={current.context.targetLocation!.name || ' '}
                  />
                  <Ink.Text />{' '}
                </>
              ) : null}
              <GetLocationByName id='ScanTargetLocation' />
              <Ink.Text color='redBright'>{current.context.errorMsg}</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('MovingContainerWithoutRestocking') && (
            <>
              <Ink.Box flexDirection='column'>
                <IntercalatedText
                  texts={[
                    'La ubicación escaneada no es de reposición, ¿desea realizar el traslado?'
                  ]}
                  values={[]}
                />
              </Ink.Box>
              <Ink.Button
                label='Confirmar'
                onClick={() => {
                  send('CONFIRM');
                }}
              />
              <Ink.Button
                label='Volver a escanear'
                onClick={() => {
                  send('CANCEL');
                }}
              />
            </>
          )}

          {current.matches('CompletingMoving') && (
            <>
              <Ink.Box flexDirection='column'>
                <HighlightedInfo
                  attribute='La ubicación'
                  value={current.context.targetLocation!.name}
                />
                <Ink.Text>
                  Se ha restockeado correctamente con el contenedor
                </Ink.Text>
                <Ink.Text bold color='greenBright'>
                  {current.context.container!.lpn}
                </Ink.Text>
              </Ink.Box>
              <Ink.Button
                label='Confirmar'
                onClick={() => {
                  send('CONFIRM');
                }}
              />
            </>
          )}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { MoveToBufferItem } from './MoveToBufferItem';
import { SlottingItem } from './SlottingItem';
import { SlottingProcess } from './SlottingProcess';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import {
  SlottingContainerStatus,
  SlottingContainerStatusCancelledStatus,
  SlottingContainerStatusPendingStatus,
  SlottingContainerStatusProcessingStatus,
  SlottingContainerStatusSlottedStatus
} from './slotting-container-status';

export const SlottingContainerStatuses = {
  Slotted:    'slotted',
  Pending:    'pending',
  Processing: 'processing',
  Cancelled:  'cancelled'
};

@Entity()
export class SlottingContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [SlottingContainerStatuses.Slotted]:    SlottingContainerStatusSlottedStatus,
    [SlottingContainerStatuses.Pending]:    SlottingContainerStatusPendingStatus,
    [SlottingContainerStatuses.Processing]:
      SlottingContainerStatusProcessingStatus,
    [SlottingContainerStatuses.Cancelled]:
      SlottingContainerStatusCancelledStatus
  })
  status: SlottingContainerStatus;

  @ForeignKey(() => SlottingProcess, { name: 'slotting_process_id' })
  slottingProcessId: number;

  @ForeignKey(() => StandardUnloadingContainer, {
    name: 'standard_unloading_container_id'
  })
  standardUnloadingContainerId: number;

  @ForeignKey(() => MoveToBufferItem, { name: 'move_to_buffer_item_id' })
  moveToBufferItemId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => SlottingProcess, 'slottingProcessId')
  slottingProcess: SlottingProcess;

  @Needs(() => StandardUnloadingContainer, 'standardUnloadingContainerId')
  standardUnloadingContainer: StandardUnloadingContainer;

  @Knows(() => MoveToBufferItem, 'moveToBufferItemId')
  moveToBufferItem: MoveToBufferItem;

  @Knows(() => Container, 'containerId')
  container: Container;

  @NeededBy(() => SlottingItem, 'slottingContainerId')
  slottingItems: SlottingItem[];
}

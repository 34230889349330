import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ReservationItem } from './ReservationItem';
import { ReservationPendingContainer } from './ReservationPendingContainer';
import {
  ReservationPendingItemCompletedStatus,
  ReservationPendingItemPendingStatus,
  ReservationPendingItemStatus
} from './reservation-pending-item-status';

export const ReservationPendingItemStatuses = {
  Pending:   'pending',
  Completed: 'completed'
};

@Entity()
export class ReservationPendingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => ReservationItem, { name: 'reservation_item_id' })
  reservationItemId: number;

  @ForeignKey(() => ReservationPendingContainer, {
    name: 'reservation_pending_container_id'
  })
  reservationPendingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ReservationItem, 'reservationItemId')
  reservationItem: ReservationItem;

  @Needs(() => ReservationPendingContainer, 'reservationPendingContainerId')
  reservationPendingContainer: ReservationPendingContainer;

  @Status({
    [ReservationPendingItemStatuses.Pending]:
      ReservationPendingItemPendingStatus,
    [ReservationPendingItemStatuses.Completed]:
      ReservationPendingItemCompletedStatus
  })
  status: ReservationPendingItemStatus;
}

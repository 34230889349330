import { NoveltyMoveContainerToBufferProcessStatuses } from '../NoveltyMoveContainerToBufferProcess';
import { NoveltyMoveContainerToBufferProcessStatus } from './NoveltyMoveContainerToBufferProcessStatus';

export class NoveltyMoveContainerToBufferProcessStatusCompletedStatus extends NoveltyMoveContainerToBufferProcessStatus {
  public displayName = 'Completado';

  public internalName = NoveltyMoveContainerToBufferProcessStatuses.Completed;

  public statusColor = '#Ffffff';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { Location } from './Location';
import { MoveToBufferItem } from './MoveToBufferItem';
import { SlottingContainer } from './SlottingContainer';
import { StandardUnloadingItem } from './StandardUnloadingItem';
import { StandardUnloadingProcess } from './StandardUnloadingProcess';
import {
  StandardUnloadingContainerStatus,
  StandardUnloadingContainerStatusCancelledStatus,
  StandardUnloadingContainerStatusClosedStatus,
  StandardUnloadingContainerStatusOpenStatus
} from './standard-unloading-container-status';

export const StandardUnloadingContainerStatuses = {
  Closed:    'closed',
  Open:      'open',
  Cancelled: 'cancelled'
};

@Entity()
export class StandardUnloadingContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [StandardUnloadingContainerStatuses.Closed]:
      StandardUnloadingContainerStatusClosedStatus,
    [StandardUnloadingContainerStatuses.Open]:
      StandardUnloadingContainerStatusOpenStatus,
    [StandardUnloadingContainerStatuses.Cancelled]:
      StandardUnloadingContainerStatusCancelledStatus
  })
  status: StandardUnloadingContainerStatus;

  @ForeignKey(() => StandardUnloadingProcess, {
    name: 'standard_unloading_process_id'
  })
  standardUnloadingProcessId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => Location, { name: 'target_sector_id' })
  targetSectorId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => StandardUnloadingProcess, 'standardUnloadingProcessId')
  standardUnloadingProcess: StandardUnloadingProcess;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Knows(() => Location, 'targetSectorId')
  targetSector: Location;

  @NeededBy(() => StandardUnloadingItem, 'standardUnloadingContainerId')
  standardUnloadingItems: StandardUnloadingItem[];

  @NeededBy(() => MoveToBufferItem, 'standardUnloadingContainerId')
  moveToBufferItem: MoveToBufferItem;

  @NeededBy(() => SlottingContainer, 'standardUnloadingContainerId')
  slottingContainer: SlottingContainer[];
}

import {
  LocationStatuses,
  PickTowerSections,
  PositionStatuses
} from '@wms/domain/.';
import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { LocationDetailContext } from './machine';

type LabelDetailActionsType = MachineOptions<
  LocationDetailContext,
  any
>['actions'];

export const locationDetailActions: LabelDetailActionsType = {
  assignLocation: assign({
    location: (_, event) => event.data
  }),
  assignItems: assign({
    items: (_ctx, event) => {
      return event.data.items;
    }
  }),
  assignOperationTypes: assign({
    operationTypes: (_ctx, event) => {
      return event.data.operationTypes;
    }
  }),
  assignLocationDetails: assign({
    allowMultiSku: (_ctx, event) => {
      return event.data.allowMultiSku;
    },
    locationStatus: (ctx, event) => {
      const data = event.data;
      const location = event.data.location;
      const sectorName = location?.parent?.parent?.name;
      const pickTowerSectors = [...PickTowerSections];
      const isPickTowerLocation = pickTowerSectors.includes(sectorName);
      switch (true) {
        case location.status === LocationStatuses.Unavailable:
          return PositionStatuses.Blocked;
        case location.status === LocationStatuses.Damaged:
          return PositionStatuses.Damaged;
        case !!data.lastInventoryItemInLocation || data.neverHadItemsInlocation:
          return PositionStatuses.Empty;
        case data.containerCount === 1 && isPickTowerLocation:
          return PositionStatuses.PartiallyFull;
        default:
          return PositionStatuses.Full;
      }
    },
    lastInventoryItemInLocation: (_ctx, event) => {
      return event.data.lastInventoryItemInLocation;
    },
    neverHadItemsInlocation: (_ctx, event) => {
      return event.data.neverHadItemsInlocation;
    }
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return (
        getErrorView(event.data ?? event.data.data) ||
        'Ha ocurrido un error al buscar el detalle de la Etiqueta'
      );
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  clearDetails: assign({
    location:                    null,
    items:                       [],
    locationStatus:              null,
    operationTypes:              [],
    allowMultiSku:               false,
    containerCount:              null,
    lastInventoryItemInLocation: null,
    neverHadItemsInlocation:     false
  })
};

import { MachineOptions, assign } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { GetLocationByNameContext } from './machine';

type GetLocationByNameActions = MachineOptions<
  GetLocationByNameContext,
  any
>['actions'];

export const getLocationByNameActions: GetLocationByNameActions = {
  updateLocationName: assign({
    locationName: (_context, event) => event.locationName
  }),
  updateLevel: assign({
    level: (_context, event) => event.level
  }),
  assignLocation: assign({
    location: (context, event) => event.data.data
  }),
  assignError: assign({
    error: (context, event) => event.data?.error || 'La ubicación no es valida.'
  }),
  clearError: assign({
    error: (_context, _event) => null
  }),
  clearParentError:       sendParent('clearError'),
  addLevelToLocationName: assign({
    locationName: (context, _event) => {
      const location = context.locationName;
      const level = context.level;
      let newLocation: string;
      if (location.includes('PR')) {
        newLocation = location.replace('X', level.toString().padStart(1, '0'));
      } else {
        newLocation = location.replace('XX', level.padStart(2, '0'));
      }

      return newLocation;
    }
  }),
  unmaskLocation: assign({
    locationName: (ctx, _evt) => {
      // Convertir el string del tipo 'UBLA0070100104' a uno desenmascarado 'LA-007-010-01-04'

      const maskedLocation = ctx.locationName;

      const section = maskedLocation.slice(2, 4);
      const aisle = maskedLocation.slice(4, 7);
      const column = maskedLocation.slice(7, 10);
      const level = maskedLocation.slice(10, 12);
      const compartment = maskedLocation.slice(12, 14);

      const unmaskedLocation = `${section}-${aisle}-${column}-${level}-${compartment}`;

      return unmaskedLocation;
    }
  }),
  clearLevel: assign({
    level: (_context, _event) => ''
  }),
  clearLocationName: assign({
    locationName: (_context, _event) => ''
  })
};

import { TransportOrderStatuses } from '../TransportOrder';
import { TransportOrderStatus } from './TransportOrderStatus';

export class TransportOrderStatusCompletedWithUtilityStatus extends TransportOrderStatus {
  public displayName = 'Completado por utilidad';

  public internalName = TransportOrderStatuses.CompletedWithUtility;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

import { MachineOptions } from 'xstate';
import { DispatchHDContext } from './machine';

type DispatchHDGuards = MachineOptions<DispatchHDContext, any>['guards'];

export const dispatchHDGuards: DispatchHDGuards = {
  doesNotHaveSuggestedDispatchingItem: (_ctx, evt) => {
    return !evt.data.dispatchingItem;
  },
  hasPartiallyDispatchedReservation: (_ctx, evt) => {
    return evt.data.hasPartiallyDispatchedReservation;
  }
};

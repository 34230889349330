import { PendingDispatchingPickItemStatuses } from '../PendingDispatchingPickItem';
import { PendingDispatchingPickItemStatus } from './PendingDispatchingPickItemStatus';

export class PendingDispatchingPickItemMissingStatus extends PendingDispatchingPickItemStatus {
  public displayName = 'Faltante';

  public internalName = PendingDispatchingPickItemStatuses.Missing;

  public statusColor = '#b90c0c';
}

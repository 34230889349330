import { StandardUnloadingProcessStatuses } from '../StandardUnloadingProcess';
import { StandardUnloadingProcessStatus } from './StandardUnloadingProcessStatus';

export class StandardUnloadingProcessStatusProcessingStatus extends StandardUnloadingProcessStatus {
  public displayName = 'Procesando';

  public internalName = StandardUnloadingProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import Ink from '@ink';
import { InventoryItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { Counter } from '../../../shared/Counter';
import { withActorRef } from '../../../shared/Machine';
import { CreateContainer } from '../create-container/CreateContainer';
import { MoveItem } from '../move-item/MoveItem';
import { RemoveItemFromContainer } from '../remove-item-from-container/RemoveItemFromContainer';
import { ScanContainer } from '../scan-container/ScanContainer';
import { ScanItem } from '../scan-item/ScanItem';
import { RestockLineMachine } from './RestockLineMachine';

export const RestockLine: FC = withActorRef(RestockLineMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const isReadjusting = (): boolean => {
      return !current.context.location;
    };

    const lotNumber: string | undefined =
      (current.context.item as InventoryItem)?.lot?.lotNumber ||
      current.context.lot?.lotNumber;

    return (
      <Ink.Box flexDirection='column' paddingTop={1}>
        {current.matches('CreateContainer') ? <CreateContainer /> : null}
        {current.matches('ScanOriginContainer') ? (
          <>
            <Ink.Text>
              Escanee contenedor donde se encuentra el ítem a restockear
            </Ink.Text>
            <ScanContainer />
          </>
        ) : null}
        {current.matches('ScanDestinationContainer') ? (
          <>
            <Ink.Text>Escanee el contenedor destino:</Ink.Text>
            <ScanContainer />
          </>
        ) : null}

        {current.matches('SelectDestinationContainer') ? (
          <>
            <Ink.Text>
              Escoger contenedor de destino en ubicación de consolidación (GRID
              1)
            </Ink.Text>

            {current.context.containers.map(container => (
              <Ink.Button
                key={container.id.toString()}
                id={container.id.toString()}
                label={container.lpn}
                onClick={() => send({ type: 'select', data: { container } })}
              />
            ))}

            <Ink.Button
              key='create'
              id='create'
              label='Crear nuevo contenedor'
              onClick={() => send('create')}
            />
          </>
        ) : null}
        {current.matches('ScanItem') ? (
          <>
            {current.context.item ? (
              <>
                <Ink.Text>
                  Alcance{' '}
                  <Ink.Chalk bold greenBright>
                    {'SKU ' + current.context.item?.sku || ''}
                  </Ink.Chalk>
                  {isReadjusting() ? (
                    <Ink.Text>
                      para completar Orden de Salida #
                      <Ink.Text bold color='greenBright' contents>
                        {current.context?.orderId}
                      </Ink.Text>
                    </Ink.Text>
                  ) : (
                    <Ink.Text>
                      {' '}
                      a la ubicación
                      <Ink.Text bold color='greenBright'>
                        {current.context.location?.name}
                      </Ink.Text>
                    </Ink.Text>
                  )}
                </Ink.Text>
                {isReadjusting() ? (
                  <Ink.Text>Traslade a ubicación de consolidación</Ink.Text>
                ) : null}
                <Ink.Box>
                  Cantidad:{' '}
                  <Counter
                    count={{
                      total:     current.context.itemToRestock?.quantity || 0,
                      processed: current.context.restockedItems.reduce(
                        (quantity, item) => quantity + item.quantity,
                        0
                      )
                    }}
                  />
                </Ink.Box>
              </>
            ) : null}

            {current.context.container ? (
              <>
                <Ink.Text>
                  Cont.:{' '}
                  <Ink.Chalk bold greenBright>
                    {current.context.container.lpn || 'no se encontró'}
                  </Ink.Chalk>
                </Ink.Text>
                <Ink.Text>
                  UBI.:{' '}
                  <Ink.Chalk bold greenBright>
                    {current.context.container.location.name ||
                      'no se encontró'}
                  </Ink.Chalk>
                </Ink.Text>
              </>
            ) : null}
            {lotNumber ? (
              <Ink.Text>
                Lot.:{' '}
                <Ink.Chalk bold greenBright>
                  {lotNumber || 'no se encontró'}
                </Ink.Chalk>
              </Ink.Text>
            ) : null}
            <ScanItem />
          </>
        ) : null}

        {current.matches('AskMoveToContainerOrLocation') ? (
          <>
            <Ink.Text>
              Elija donde desea colocar los items: suelto en la ubicacion o
              dentro de un contenedor.
            </Ink.Text>

            <Ink.Button
              key='location'
              id='location'
              label='Ubicación'
              onClick={() => send({ type: 'LOCATION_SELECTED' })}
            />

            <Ink.Button
              key='container'
              id='container'
              label='Contenedor'
              onClick={() => send({ type: 'CONTAINER_SELECTED' })}
            />
          </>
        ) : null}

        {current.matches('RemoveItemFromContainer') ? (
          <RemoveItemFromContainer />
        ) : null}

        {current.matches('MoveItem') ? <MoveItem /> : null}
      </Ink.Box>
    );
  }
);

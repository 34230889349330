import {
  Container,
  ContainerType,
  Location,
  SlottingItem,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { slottingItemActions } from './actions';
import { slottingItemGuards } from './guards';
import { slottingItemServices } from './services';

export interface SlottingItemContext {
  task: Task;
  slottingContainerId: number;
  containerLpn: string;

  stagingLocation: Location | null;

  suggestedSlottingItem: SlottingItem | null;
  container: Container | null;
  containerType: ContainerType | null;
  suggestedDestinyLocation: Location | null;

  scannedLocation: Location | null;

  error: string | null;
}

export const DefaultSlottingItemContext: SlottingItemContext = {
  task:                {} as Task,
  slottingContainerId: -1,
  containerLpn:        '',

  stagingLocation:          null,
  suggestedSlottingItem:    null,
  container:                null,
  containerType:            null,
  suggestedDestinyLocation: null,

  scannedLocation: null,

  error: null
};

export const SlottingItemMachine = createMachine(
  {
    id:                         'slottingItem',
    predictableActionArguments: true,
    schema:                     {
      context: {} as SlottingItemContext
    },
    initial: 'FetchingStagingLocation',
    states:  {
      FetchingStagingLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStagingLocation',
          onDone: {
            target:  'ScanningControlDigit',
            actions: ['assignStagingLocation']
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: ['assignError']
          }
        }
      },
      ScanningControlDigit: {
        invoke: {
          id:   ScanControlDigitMachine.id,
          src:  ScanControlDigitMachine,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            requiredLocation: ctx.stagingLocation
          }),
          onDone: {
            target:  'FetchingSuggestedSlottingItem',
            actions: ['clearError']
          }
        }
      },
      FetchingSuggestedSlottingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedSlottingItem',
          onDone: {
            target:  'ScanningContainer',
            actions: [
              'assignSuggestedSlottingItem',
              'assignContainerType',
              'assignContainer'
            ]
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: ['assignError']
          }
        }
      },
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee etiqueta para continuar',
              getRequestedContainerByLpn(
                'Ocurrió un error al obtener el contenedor, intenta nuevamente',
                'El contenedor escaneado es invalido',
                ctx.suggestedSlottingItem!.slottingContainer!.container.lpn
              )
            ),
          onDone: {
            target:  'FetchingDestinyLocation',
            actions: ['clearError']
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: ['assignError']
          }
        }
      },
      FetchingDestinyLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchDestinyLocation',
          onDone: {
            target:  'ScanningLocation',
            actions: ['assignDestinyLocation']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: _ctx =>
            GetLocationByNameMachine(
              'Escanee ubicacion para continuar',
              getAnyLocationByName(
                'Ocurrió un error al obtener la ubicación, intenta nuevamente'
              )
            ),
          onDone: [
            {
              cond:    'IsSuggestedLocation',
              target:  'MarkingItemAsSlotted',
              actions: ['assignScannedLocation', 'clearError']
            },
            {
              target:  'CheckingIfLocationIsValid',
              actions: ['assignScannedLocation', 'clearError']
            }
          ]
        }
      },
      CheckingIfLocationIsValid: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfLocationIsValid',
          onDone: [
            {
              target: 'MarkingItemAsSlotted',
              cond:   'IsValidLocation'
            },
            {
              target:  'FetchingDestinyLocation',
              actions: 'assignNotValidLocationError'
            }
          ],
          onError: {
            target:  'ScanningLocation',
            actions: ['assignError']
          }
        }
      },
      MarkingItemAsSlotted: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'SlottingItemSlotted',
              payload: {
                taskId:         ctx.task.id,
                slottingItemId: ctx.suggestedSlottingItem?.id,
                locationId:     ctx.scannedLocation?.id
              }
            };
          },
          onDone: {
            target: 'SlottingConfirmedScreen'
          }
        }
      },
      SlottingConfirmedScreen: {
        on: {
          CONFIRM: {
            target: 'Done'
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    actions:  slottingItemActions,
    guards:   slottingItemGuards,
    services: slottingItemServices
  }
);

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { PrintPutToWallLabelsContext } from './machine';

type PrintPutToWallLabelsServices = MachineOptions<
  PrintPutToWallLabelsContext,
  any
>['services'];

export const printPutToWallLabelsServices: PrintPutToWallLabelsServices = {
  printReservationLabel: context => {
    return api.post(
      'printer/mini-ptw/reprint-package-label',
      {},
      {
        params: {
          reservationId: context.reservationId
        }
      }
    );
  }
};

import { MachineOptions } from 'xstate';
import { GetSellerProductByCodeContext } from './machine';

type GetSellerProductbyCodeGuards = MachineOptions<
  GetSellerProductByCodeContext,
  any
>['guards'];

export const getSellerProductByCodeGuards: GetSellerProductbyCodeGuards = {
  isValidSellerProductCode: (context, _event) => !!context.sellerProductCode,
  isSuccess:                (_context, event) => event.data.type === 'success'
};

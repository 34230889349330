import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { withActorRef } from '../../../../shared/Machine';
import { GetItemByLabelMachine, GetItemByLabelMachineId } from './machine';

export const GetItemByLabel = withActorRef(
  GetItemByLabelMachine,
  GetItemByLabelMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateItemLabel = useCallback(
    (itemLabel: string) => send({ type: 'UpdateItemLabel', itemLabel }),
    [send]
  );

  const submitItemLabel = useCallback(
    () => send({ type: 'SubmitItemLabel' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          <Ink.TextInput
            value={current.context.itemLabel}
            onChange={updateItemLabel}
            onSubmit={submitItemLabel}
          />

          {current.context.error ? (
            <Ink.Text bold color='redBright'>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});

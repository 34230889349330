import { Location, Product, SlottingItem, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanSKUContext,
  ScanSKUMachine
} from '../../capa-4/scan-sku/ScanSKUMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { slottingRollContainerActions } from './actions';
import { slottingRollContainerGuards } from './guards';
import { slottingRollContainerServices } from './service';

export interface SlottingRollContainerContext {
  task: Task;
  slottingContainerId: number;
  containerLpn: string;
  containerLocationName: string;

  itemsTotal: number | null;
  itemsSlotted: number;
  slottingItem: SlottingItem | null;

  skuUnloadedQuantity: number | null;

  locationScanned: Location | null;

  productAssigned: Product | null;
  skuSelectedDestinyLocationAndQuantity: {
    location: Location;
    quantity: number;
  } | null;
  availableQuantityInLocationScanned: number | null;
  quantityDeposited: number;

  error: string | null;
}

export const DefaultSlottingRollContainerContext: SlottingRollContainerContext =
  {
    task:                                  {} as Task,
    slottingContainerId:                   -1,
    containerLpn:                          '',
    containerLocationName:                 '',
    itemsTotal:                            0,
    itemsSlotted:                          0,
    slottingItem:                          null,
    skuUnloadedQuantity:                   null,
    locationScanned:                       null,
    productAssigned:                       null,
    skuSelectedDestinyLocationAndQuantity: null,
    availableQuantityInLocationScanned:    null,
    quantityDeposited:                     0,
    error:                                 null
  };

export const SlottingRollContainerMachine = createMachine(
  {
    id:                         'slotting-roll-container',
    predictableActionArguments: true,
    schema:                     {
      context: {} as SlottingRollContainerContext
    },
    initial: 'ScanningRollContainerLocation',
    states:  {
      ScanningRollContainerLocation: {
        invoke: {
          id:  'ScanningRollContainerLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee etiqueta de ubicacion',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'La ubicación escaneada es invalida.',
                ctx.containerLocationName!
              )
            ),
          onDone: {
            target: 'ScanningRollContainer'
          }
        }
      },
      ScanningRollContainer: {
        invoke: {
          id:  'ScanningRollContainer',
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee etiqueta de contenedor para continuar',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'El contenedor escaneado es invalido.',
                ctx.containerLpn!
              )
            ),
          onDone: {
            target:  'FetchingSkuCount',
            actions: ['clearError']
          }
        }
      },
      FetchingSkuCount: {
        invoke: {
          src:    'fetchSkuCount',
          onDone: [
            {
              cond:    'hasItemsToScan',
              target:  'FetchingItem',
              actions: ['assignItemsCount', 'clearSkuSelectedCtx']
            },
            {
              target: 'SendingToParentConpleteTask'
            }
          ],
          onError: {
            target:  'ScanningRollContainer',
            actions: ['assignError']
          }
        }
      },
      FetchingItem: {
        invoke: {
          src:    'fetchItem',
          onDone: {
            target:  'FetchingSkuUnloadedQuantity',
            actions: ['assignProduct', 'assignSlottingItem']
          },
          onError: {
            target:  'ScanningRollContainer',
            actions: ['assignError']
          }
        }
      },
      FetchingSkuUnloadedQuantity: {
        invoke: {
          src:    'fetchSkuUnloadedQuantity',
          onDone: [
            {
              target:  'ScanningSku',
              actions: ['assignSkuUnloadedQuantity']
            }
          ],
          onError: {
            target:  'ScanningRollContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningSku: {
        invoke: {
          src:  ScanSKUMachine,
          id:   ScanSKUMachine.id,
          data: ctx => ({
            ...DefaultScanSKUContext,
            hint:             'Escanee etiqueta de SKU para confirmar',
            validIdentifiers: [ctx.productAssigned!.sku || null]
          }),
          onDone: {
            target: 'FetchingDestinySkuLocation'
          }
        }
      },
      FetchingDestinySkuLocation: {
        invoke: {
          src:    'fetchDestinySkuLocation',
          onDone: [
            {
              target:  'ScanningDestinySkuLocation',
              actions: [
                'assignDestinySkuLocation',
                'assignQuantityDestinyLocation'
              ]
            }
          ],
          onError: {
            target:  'ScanningSku',
            actions: 'assignError'
          }
        }
      },
      ScanningDestinySkuLocation: {
        invoke: {
          id:  'ScanningDestinySkuLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee Ubicacion de destino para confirmar',
              getAnyLocationByName(
                ctx.skuSelectedDestinyLocationAndQuantity!.location.name
              )
            ),
          onDone: {
            target:  'CheckAvailableSuggestedLocation',
            actions: 'assignLocationScanned'
          }
        }
      },
      CheckAvailableSuggestedLocation: {
        invoke: {
          src:    'checkLocationAvailable',
          onDone: [
            {
              cond:   'locationAvailable',
              target: 'IndicatingQuantityReceived'
            },
            {
              target:  'FetchingDestinySkuLocation',
              actions: ['assignBlockedLocationError']
            }
          ],
          onError: {
            target:  'FetchingDestinySkuLocation',
            actions: 'assignError'
          }
        }
      },
      ScanningAvailableLocation: {
        invoke: {
          id:  'ScanningAvailableLocation',
          src: GetLocationByNameMachine(
            'Escanee Ubicacion disponible para confirmar',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            target:  'CheckAvailableLocation',
            actions: ['assignLocationScanned', 'assignDestinySkuLocation']
          }
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      CheckAvailableLocation: {
        invoke: {
          src:    'checkLocationAvailable',
          onDone: [
            {
              cond:   'locationAvailable',
              target: 'GetAvailableQuantityInLocationScanned'
            },
            {
              target:  'ScanningAvailableLocation',
              actions: ['assignBlockedLocationError']
            }
          ],
          onError: {
            target:  'ScanningAvailableLocation',
            actions: ['assignError']
          }
        }
      },
      GetAvailableQuantityInLocationScanned: {
        invoke: {
          src:    'getAvailableQuantityInLocation',
          onDone: [
            {
              target:  'CheckingIfLocationHasItems',
              actions: ['assignAvailableQuantityInLocation']
            }
          ],
          onError: {
            target:  'ScanningAvailableLocation',
            actions: ['assignError']
          }
        }
      },
      CheckingIfLocationHasItems: {
        invoke: {
          src:    'checkIfLocationHasdifferentItems',
          onDone: [
            {
              cond:    'isLocationAvailable',
              target:  'CheckingIfLocationHasCorrectParent',
              actions: ['clearError']
            },
            {
              target:  'ScanningAvailableLocation',
              actions: ['assignLocationNotAvailableError']
            }
          ],
          onError: {
            target:  'ScanningAvailableLocation',
            actions: ['assignError']
          }
        }
      },
      CheckingIfLocationHasCorrectParent: {
        invoke: {
          src:    'checkIfLocationHasCorrectParent',
          onDone: [
            {
              cond:    'isCorrectParent',
              target:  'IndicatingQuantityReceived',
              actions: ['clearError']
            },
            {
              target:  'ScanningAvailableLocation',
              actions: ['assignIsNotParentError', 'clearLocationScanned']
            }
          ],
          onError: {
            target:  'ScanningAvailableLocation',
            actions: ['assignError']
          }
        }
      },
      IndicatingQuantityReceived: {
        invoke: {
          src:  EnterQuantityMachine,
          id:   EnterQuantityMachine.id,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            hint: 'Ingrese cantidad de unidades depositadas',
            min:  1,
            max:  Math.min(
              ctx.availableQuantityInLocationScanned as number,
              ctx.skuUnloadedQuantity!
            )
          }),
          onDone: [
            {
              target:  'SendingToParentMarkItemAsSlotted',
              actions: 'assignQuantityDeposited',
              cond:    'hasAvailableQuantity'
            }
          ]
        }
      },
      SendingToParentMarkItemAsSlotted: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'SlottingItemSlotted',
              payload: {
                slottingItemId:  ctx.slottingItem!.id,
                taskId:          ctx.task.id,
                locationId:      ctx.locationScanned!.id,
                quantitySlotted: ctx.quantityDeposited
              }
            };
          },
          onDone: [
            {
              cond:   'isQuantityDepositedTotalSkuQuantity',
              target: 'FetchingSkuCount'
            },
            {
              cond:   'theLocationIsNotFull',
              target: 'WarningQuantityDepositedIsNotTotalSkuQuantity'
            },
            {
              target:  'FetchingDestinySkuLocation',
              actions: 'assignNewQuantityToSlot'
            }
          ],
          onError: {
            target:  'ScanningRollContainer',
            actions: ['assignError']
          }
        }
      },
      WarningQuantityDepositedIsNotTotalSkuQuantity: {
        on: {
          SCAN_AVAILABLE: {
            target:  'ScanningAvailableLocation',
            actions: ['assignNewQuantityToSlot']
          },
          SCAN_DESTINY_LOCATION: {
            target:  'ScanningDestinySkuLocation',
            actions: [
              'assignNewQuantityToSlot',
              'updateAvailableQuantityInLocationScanned'
            ]
          }
        }
      },
      SendingToParentConpleteTask: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'RollContainerTaskCompleted',
              payload: {
                taskId:              ctx.task.id,
                slottingContainerId: ctx.slottingContainerId
              }
            };
          },
          onDone: {
            target: 'Finishing'
          },
          onError: {
            target:  'ScanningRollContainer',
            actions: ['assignError']
          }
        }
      },

      Finishing: {
        invoke: {
          id:  'Finishing',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee Ubicacion de destino para confirmar',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'La ubicación escaneada es invalida.',
                ctx.containerLocationName
              )
            ),
          onDone: {
            target: 'Done'
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   slottingRollContainerGuards,
    actions:  slottingRollContainerActions,
    services: slottingRollContainerServices
  }
);

import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByCheckDigitFn } from '../machine';

export function getMovingToPreSorterLocationByCheckDigit(
  defaultErrorMessage: string,
  movingToPreSorterProcessId: number
): GetLocationByCheckDigitFn {
  return fetchResult(defaultErrorMessage, checkDigit =>
    api.get<Location>(
      `moving-to-pre-sorter-process/${movingToPreSorterProcessId}/validate-staging-check-digit`,
      {
        params: {
          checkDigit
        }
      }
    )
  );
}

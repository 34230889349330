import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusProcessingStatus extends ReservationItemStatus {
  public displayName = 'Procesando';

  public internalName = ReservationItemStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

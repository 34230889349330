import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { GetSellerProductByCode } from '../../layer-4/seller-product/get-seller-product-by-code/component';
import { FeedingToSorterTaskMachine } from './machine';

export const FeedingToSorterTask = withActorRef(FeedingToSorterTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Inducir contenedor</Ink.Text>
        </Ink.Header>

        {current.hasTag('loading') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Cargando... </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanFeedingToSorterLocation') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Trasladese hasta ubicacion de induccion:</Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanFeedingToSorterContainer') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Identifique contenedor a inducir:</Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanSellerProductToProcess') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Bulto' +
                ' ' +
                (current.context.processedFeedingToSorterItems + 1) +
                ' de ' +
                current.context.totalFeedingToSorterItems}
            </Ink.Chalk>
            <Ink.Text bold>
              Escanee CB del bulto e induzca en la cinta:
            </Ink.Text>
          </>
        )}

        {current.matches('ConfirmingFeedingToSorterScreen') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Proceso de induccion finalizado</Ink.Text>
          </Ink.Box>
        )}

        {/* Below separator */}

        {current.matches('ScanFeedingToSorterLocation') && (
          <GetLocationByName />
        )}

        {current.matches('ScanFeedingToSorterContainer') && (
          <GetContainerByLpn />
        )}

        {current.matches('ScanSellerProductToProcess') && (
          <GetSellerProductByCode />
        )}

        {current.matches('ConfirmingFeedingToSorterScreen') && (
          <>
            <Ink.Box
              flexDirection='row'
              justifyContent='space-between'
              paddingLeft={1}
            >
              <Ink.Button
                style={{ width: '15%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Volver'
              />
            </Ink.Box>
          </>
        )}

        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      </Ink.Box>
    );
  }
);

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanLabelMachine } from './machine';

export const ScanLabel: FC = withActorRef(ScanLabelMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const onSubmitLabel = (label: string) => {
    if (label === '') {
      return;
    }
    send({
      type: 'LABEL_SCANNED',
      data: { label }
    });
  };

  const changeLabel = (label: string) => {
    send({
      type: 'CHANGE',
      data: { label }
    });
  };

  return (
    <>
      <Ink.Text bold>{current.context.hint}</Ink.Text>

      <Ink.TextInput
        onChange={changeLabel}
        value={current.context.label}
        onSubmit={onSubmitLabel}
      />

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});

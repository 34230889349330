import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpnMachine } from './EnterLpnMachine';

export const EnterLpn: FC = withActorRef(EnterLpnMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const setLpn = useCallback(
    (input: string) => {
      send({
        type: 'lpnScanned',
        data: { lpn: input }
      });
    },
    [send]
  );

  const submitLpn = useCallback(() => {
    send('submitLpn');
  }, [send]);

  return (
    <>
      <Ink.Text bold color='highlightColor'>
        {current.context.hint}
      </Ink.Text>

      <Ink.TextInput
        value={current.context.lpn ? current.context.lpn : ''}
        onChange={setLpn}
        onSubmit={submitLpn}
      />

      {current.context.error && (
        <Ink.Text color='red'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});

import { RestockingItemStatuses, TaskStatuses } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { PalletRestockingTaskContext } from './machine';

type PalletRestockingTaskServices = MachineOptions<
  PalletRestockingTaskContext,
  any
>['services'];

export const palletRestockingTaskServices: PalletRestockingTaskServices = {
  getRestockingItem: async (ctx, _evt) => {
    const { data } = await api.get(`/restocking-item/${ctx.restockingItemId}/`);
    return {
      restockingItem: data
    };
  },
  getSuggestedOrigingLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/restocking-item/${ctx.restockingItemId}/get-suggested-location`,
      {
        params: {
          originLocationId: ctx.originLocation?.id
        }
      }
    );
    return {
      location: data
    };
  },
  getAnySuggestedOriginLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/restocking-item/${ctx.restockingItemId}/get-any-suggested-location`,
      {
        params: {
          originLocationId: ctx.originLocation?.id
        }
      }
    );
    return {
      location: data
    };
  },
  moveContainer: async ctx => {
    const { data: container } = await api.post(
      '/container/place-in-new-location',
      {
        containerId:  ctx.container!.id,
        locationName: ctx.location!.name,
        taskId:       ctx.task?.id
      }
    );

    return { container };
  },
  completeRestockingTask: async (ctx, _evt) => {
    const { data } = await api.post(`/task/${ctx.task.id}/update-restocking`, {
      restockingItemId:  ctx.restockingItem.id,
      restockedQuantity: ctx.restockedQuantity,
      originLocationId:  ctx.originLocation!.id,
      status:            TaskStatuses.Completed,
      restockItemStatus: RestockingItemStatuses.Completed,
      containerId:       ctx.container?.id || ctx.originLocation?.containers[0]
    });
    return {
      task: data
    };
  },
  cancelRestockingTask: async (ctx, _evt) => {
    const { data } = await api.post(`/task/${ctx.task.id}/update-restocking`, {
      restockingItemId:  ctx.restockingItem.id,
      restockedQuantity: 0,
      originLocationId:  ctx.originLocation?.id,
      status:            TaskStatuses.Cancelled,
      restockItemStatus: RestockingItemStatuses.Cancelled
    });
    return {
      task: data
    };
  },
  validateOriginLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/restocking-item/${ctx.restockingItem.id}/validate-origin-location/${
        ctx.originLocation!.id
      }`
    );
    return {
      location: data
    };
  },
  getContainerInOrigingLocation: async (ctx, _evt) => {
    const restockingItemId = ctx.restockingItem.id || ctx.restockingItemId;
    const params = new URLSearchParams();
    params.append('restockingItemId', restockingItemId.toString());
    const { data } = await api.get(
      `/container/location/${ctx.originLocation?.id}/with-inventory-items`,
      {
        params
      }
    );
    return {
      container: data
    };
  },
  updateRestockingItem: async (ctx, _evt) => {
    const { data } = await api.patch(
      `/restocking-item/${
        ctx.restockingItem.id
      }/update-restocking-item/location/${ctx.originLocation!.id}`
    );
    return data;
  },
  checkIfCanMoveToLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/location/${ctx.location!.id}/validate-pick-tower`
    );
    return {
      task: data
    };
  },
  checkLocationAvailable: async (ctx, _event) => {
    const { data } = await api.get(
      `location/${ctx.location!.id}/check-available`
    );

    return data;
  }
};

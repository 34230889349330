import Ink from '@ink';
import { TaskTypes } from '@wms/domain/.';
import React, { FC, useCallback } from 'react';
import { AuthenticationPayload } from '../../api/auth';

interface MenuProps {
  send: (any: string, any1: any) => void;
  user: AuthenticationPayload['user'] | null;
}
const package_json = require('./../../../package.json');

const menuItems = [
  // Acceso directo a 'Administrar Impresora'
  {
    key:         'printer',
    displayName: 'ADMINISTRAR IMPRESORA',
    event:       'taskSelected',
    payload:     { task: { type: TaskTypes.ManagePrinter } }
  },
  { key: 'back', displayName: 'ATRAS', event: 'Back', payload: {} }
] as const;

export const ConfigurationMenu: FC<MenuProps> = ({ send, user }) => {
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) send(item.event, item.payload);
  }, []);

  const capitalize = (word: string) => {
    return word.length ? word[0].toUpperCase() + word.slice(1) : '';
  };

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opcion:</Ink.Text>
      </Ink.Header>

      <Ink.Box paddingLeft={3} padding={3} flexDirection='column'>
        <Ink.Box>
          <Ink.Text>Usuario:</Ink.Text>
          {user ? (
            <Ink.Text color='greenBright'>
              {user.username} - {user.employeeCode}
            </Ink.Text>
          ) : null}
        </Ink.Box>
        {(user?.userTaskRoles || []).length ? (
          <>
            <Ink.Text>Roles del usuario:</Ink.Text>
            {user?.userTaskRoles.map((role: any, i) => (
              <Ink.Box paddingLeft={1} key={i}>
                <Ink.Text color='greenBright'>
                  {capitalize(role.taskRole.name)}
                </Ink.Text>
              </Ink.Box>
            ))}
          </>
        ) : null}
      </Ink.Box>
      <Ink.Box paddingLeft={3} padding={3}>
        <Ink.Text>v.</Ink.Text>
        <Ink.Text color='greenBright'>{package_json.version} </Ink.Text>
      </Ink.Box>
      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item => (
          <Ink.Button
            key={item.key}
            id={item.key}
            label={item.displayName}
            onClick={handleClick}
          />
        ))}
      </Ink.Box>
    </Ink.Box>
  );
};

ConfigurationMenu.displayName = 'Menu';

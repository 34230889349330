import { api } from '../../../../api/utils/axios-instance';

export const ProductDetailServices = {
  fetchProduct: async ctx => {
    const { data } = await api.get(`product/sku/${ctx.product.sku}/detail`);
    return {
      inventoryItems: data
    };
  }
};

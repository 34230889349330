import { Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { AlertData } from '../../../../../types/alerData';
import { Maybe } from '../../../../../utils/maybe';
import { handleErrorPopUp } from '../../../../../utils/pop-up-layer-4';
import { getLocationByNameActions } from './actions';
import { getLocationByNameGuards } from './guards';
import { getLocationByNameServices } from './services';

export interface GetLocationByNameContext {
  readonly hint: string;
  locationName: string;
  location: Location | null;
  error: string | null;
  level: string;
  readonly locationSuggestionName?: string;
  readonly locationSuggestionRequired?: boolean;
  readonly locationSuggestionHint?: string;
}

export type GetLocationByNameFn = (name: string) => Promise<Maybe<Location>>;

export const GetLocationByNameMachineId = 'GetLocationByName';

export const GetLocationByNameMachine = (
  hint: string,
  fetchFn: GetLocationByNameFn,
  locationSuggestion?: { name: string; hint: string; required: boolean }
) =>
  createMachine(
    {
      id:                         GetLocationByNameMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetLocationByNameContext
      },
      context: {
        hint,
        locationName:               '',
        locationSuggestionName:     locationSuggestion?.name,
        locationSuggestionHint:     locationSuggestion?.hint,
        locationSuggestionRequired: locationSuggestion?.required,
        level:                      '',
        location:                   null,
        error:                      null
      },
      initial: 'EnteringLocationName',
      states:  {
        EnteringLocationName: {
          entry: 'clearLocationName',
          on:    {
            UpdateLocationName: {
              actions: ['updateLocationName', 'clearError', 'clearParentError']
            },
            SubmitLocationName: [
              {
                cond:    'isLocationMaskedAndInLevel',
                actions: ['clearError', 'unmaskLocation'],
                target:  'EnteringLevel'
              },
              {
                cond:    'isLocationMasked',
                actions: ['clearError', 'unmaskLocation'],
                target:  'FetchingLocation'
              },
              {
                cond:    'isLocationInLevel',
                actions: 'clearError',
                target:  'EnteringLevel'
              },
              {
                cond:    'isValidLocationName',
                actions: 'clearError',
                target:  'FetchingLocation'
              },
              {
                actions: ['assignError', 'clearLocationName']
              }
            ]
          }
        },
        EnteringLevel: {
          on: {
            UpdateLevel: {
              actions: ['updateLevel', 'clearError']
            },
            SubmitLevel: [
              {
                cond:    'isValidLocationNameWithLevel',
                actions: ['clearError', 'addLevelToLocationName'],
                target:  'FetchingLocation'
              },
              {
                actions: ['assignError', 'clearLevel']
              }
            ],
            backToScanLocation: {
              target:  'EnteringLocationName',
              actions: ['clearLevel']
            }
          }
        },
        FetchingLocation: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchLocation',
            onDone: [
              {
                cond:    'isSuccess',
                actions: ['assignLocation', 'clearLevel'],
                target:  'Finished'
              },
              {
                actions: ['clearParentError', 'assignError', 'clearLevel'],
                target:  'HandleErrors'
              }
            ]
          }
        },
        HandleErrors: {
          always: [
            {
              actions: ctx =>
                handleErrorPopUp({
                  errorMessage: ctx.error || ''
                } as AlertData),
              target: 'EnteringLocationName'
            }
          ]
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.location
        }
      }
    },
    {
      guards:   getLocationByNameGuards,
      actions:  getLocationByNameActions,
      services: getLocationByNameServices(fetchFn)
    }
  );

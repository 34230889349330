import Ink from '@ink';
import {
  HDtypes,
  LabelOrderType,
  OrderType,
  OrderTypes,
  SStypes
} from '@wms/domain';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { EnterQuantityMachine } from '../../capa-4/enter-quantity/EnterQuantityMachine';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { Print } from '../../layer-4/printer/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { MidiPickingWavePrinting } from './components/printing';
import { MidiPickingWaveMachine } from './machine';

export const MidiPickingWaveTask = withActorRef(MidiPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getDisplayName = (orderType: OrderType): string => {
      const orderTypeName = orderType?.name as OrderTypes;
      if (HDtypes.includes(orderTypeName)) {
        return 'HD';
      } else if (SStypes.includes(orderTypeName)) {
        return 'AB TIE';
      } else {
        return 'N/A';
      }
    };

    return (
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Picking Ola N° {current.context.pickingWaveId || 'N/A'} - PickTower
            Nivel {current.context.level}
          </Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column'>
          {/* Marking all the 'Loading' states here */}
          {![
            'Initializing',
            'FetchingSuggestedItem',
            'FetchSplittedPickItem',
            'MarkingAsPicked',
            'MarkingAsSkipped',
            'CheckAvailableOpportunisticCount',
            'VerifyingCounting',
            'MarkingAsInSorter',
            'PrePrinting',
            'CompletingPickingWave',
            'Done'
          ].includes(current.value.toString()) ? (
            <>
              {!current.matches('ConfirmingScreenPicking') && (
                <IntercalatedText
                  texts={['Item', '/', '-']}
                  values={[
                    (current.context.pickedItems || 0) +
                      (current.context.pendingItemsToLabel! > 0 ? 0 : 1),
                    current.context.totalItems?.toString() || '-',
                    getDisplayName(current.context?.orderType!)
                  ]}
                />
              )}

              {current.matches('ScanLocation') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <HighlightedInfo
                    attribute='SKU'
                    value={current.context?.currentPickItem?.sku}
                  />
                  <Ink.Text color='greenBright' bold>
                    {current.context.currentPickItem.product.name}
                  </Ink.Text>
                  <Ink.Text>Trasládese a la siguiente ubicación:</Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {current.context.currentPickItem.pickingLocation.name ||
                      'N/A'}
                  </Ink.Chalk>
                </>
              )}
              {current.matches('ScanItem') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={current.context.selectedLocation!.name}
                  />
                  <HighlightedInfo
                    attribute='Escanee SKU del producto'
                    value={current.context.currentPickItem!.sku}
                  />
                  <Ink.Chalk bold color='greenBright'>
                    {current.context.currentPickItem!.product!.name}
                  </Ink.Chalk>
                </>
              )}

              {(current.matches('SelectingQuantity') ||
                current.matches('PrintingInternalSupply')) && (
                <>
                  <Ink.Text> </Ink.Text>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={current.context.selectedLocation!.name}
                  />
                  <Ink.Text>
                    Tome
                    <Ink.Box paddingLeft={1} paddingRight={1}>
                      <Ink.Chalk greenBright bold>
                        {current.context.currentPickItem.quantity}
                      </Ink.Chalk>
                    </Ink.Box>
                    unidad/es de
                  </Ink.Text>
                  <HighlightedInfo
                    attribute='SKU'
                    value={current.context.currentPickItem.sku}
                  />
                  <Ink.Chalk greenBright bold>
                    {current.context.currentPickItem.product.name}
                  </Ink.Chalk>
                </>
              )}

              {current.matches('OpportunisticCounting') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text bold>
                    Confirme cantidad restante en ubicación
                  </Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {`${current.context.currentPickItem.sku} - ${current.context.currentPickItem.product.name}`}
                  </Ink.Chalk>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={
                      current.context.currentPickItem.pickingLocation.name ||
                      'N/A'
                    }
                  />
                </>
              )}

              {current.hasTag('printing') && (
                <Ink.Box>
                  <Print />
                </Ink.Box>
              )}

              {current.matches('DepositingInSorter') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={current.context.selectedLocation!.name}
                  />
                  <Ink.Text>
                    Etiqueta{' '}
                    {current.context.pendingItemsToSort > 0
                      ? current.context.pendingItemsToSort
                      : 1}{' '}
                    de {current.context.totalItemsToSort}
                  </Ink.Text>
                  <HighlightedInfo
                    attribute='Escanee SKU del producto'
                    value={current.context.currentPickItem!.sku}
                  />
                  <Ink.Chalk bold color='greenBright'>
                    {current.context.currentPickItem!.product!.name}
                  </Ink.Chalk>
                </>
              )}

              {current.matches('ProductInfoMismatch') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>
                    La cantidad tomada y la requerida no coinciden
                  </Ink.Text>
                </>
              )}

              {current.matches('AwaitingRestocking') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>
                    El item actual está esperando ser restockeado
                  </Ink.Text>
                </>
              )}

              {current.matches('ConfirmingReturningItem') && (
                <>
                  <Ink.Text> </Ink.Text>
                  <Ink.Text>
                    El item actual está siendo devuelto a su ubicación
                  </Ink.Text>
                  <HighlightedInfo
                    attribute='SKU'
                    value={current.context.currentPickItem.product.sku}
                    bold
                  />
                  <Ink.Text bold color='greenBright'>
                    {current.context.currentPickItem.product.name}
                  </Ink.Text>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={
                      current.context.currentPickItem?.pickingLocation?.name ||
                      'N/A'
                    }
                  />
                  <IntercalatedText
                    texts={['Deje', 'unidades']}
                    values={['1']}
                  />
                  <HighlightedInfo
                    attribute='Etiqueta'
                    value={current.context.selectedLabel!}
                  />
                </>
              )}

              {!current.matches('ConfirmingScreenPicking') && (
                <Ink.Text> </Ink.Text>
              )}
            </>
          ) : (
            <Ink.Text>Cargando...</Ink.Text>
          )}

          {/* Below separator */}
          {current.matches('ScanLocation') && <GetLocationByName />}

          {current.matches('ScanItem') && <GetProductBySKU />}

          {current.matches('ConfirmingReturningItem') && (
            <>
              <Ink.Button
                onClick={() => {
                  send('confirm');
                }}
                label='Confirmado, dejé el item en la ubicación'
              />
            </>
          )}

          {(current.matches('PrintingHomeDelivery') ||
            current.matches('PrintingInternalSupply')) && (
            <MidiPickingWavePrinting actorRef={actorRef} />
          )}

          {current.matches('SelectingQuantity') && (
            <EnterQuantity id={`${EnterQuantityMachine.id}-1`} />
          )}

          {current.matches('OpportunisticCounting') && (
            <EnterQuantity id={`${EnterQuantityMachine.id}-2`} />
          )}

          {current.matches('DepositingInSorter') && (
            <>
              <ScanLabel />
              {current.context.typeOfLabel !== LabelOrderType.Ss && (
                <Ink.Button
                  onClick={() => {
                    send('moveToPrePrinting');
                  }}
                  label='Volver a la pantalla de impresion de etiquetas'
                />
              )}
            </>
          )}

          {current.matches('ProductInfoMismatch') && (
            <>
              <Ink.Text bold>¿Continuar o reportar incidencia?</Ink.Text>
              <Ink.Button
                onClick={() => {
                  send('continue');
                }}
                label='Continuar'
              />
              <Ink.Button
                onClick={() => {
                  send('report');
                }}
                label='Reportar'
              />
            </>
          )}

          {current.matches('AwaitingRestocking') && (
            <>
              <Ink.Text bold>¿Que deseas hacer?</Ink.Text>
              <Ink.Button
                onClick={() => {
                  send('refetch');
                }}
                label='Consultar disponibilidad del producto'
              />
              <Ink.Button
                onClick={() => {
                  send('skip');
                }}
                label='Ir al siguiente item'
              />
            </>
          )}

          {current.matches('MissingItemErrorScreen') && (
            <>
              {current.context.error && (
                <ErrorMessage error={current.context.error!!} />
              )}
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('return');
                  }}
                  label='Volver'
                />
              </Ink.Box>
            </>
          )}

          {current.matches('ConfirmingScreenPicking') ? (
            <>
              <Ink.Box flexDirection='column'>
                <Ink.Box paddingBottom={2}>
                  <Ink.Text bold color='greenBright'>
                    Picking Completado
                  </Ink.Text>
                </Ink.Box>
                {current.context.missingItems?.length !== 0 && (
                  <>
                    <Ink.Text>Los siguientes items estan faltantes:</Ink.Text>
                    {current.context.missingItems!.map(item => (
                      <>
                        <Ink.Text key={item.id}>
                          {item.inventoryItem?.sku} -{' '}
                          {item.inventoryItem?.product?.name} - Cantidad:{' '}
                          {item.quantity}
                        </Ink.Text>
                      </>
                    ))}
                  </>
                )}
                {current.context.missingItems?.length === 0 && (
                  <Ink.Text>Todos los items han sido tomados.</Ink.Text>
                )}
                <Ink.Box flexDirection='row' justifyContent='space-between'>
                  <Ink.Button
                    style={{ width: '60%' }}
                    onClick={() => {
                      send('confirm');
                    }}
                    label='Confirmar y salir'
                  />
                </Ink.Box>
              </Ink.Box>
            </>
          ) : null}
          {current.context.error &&
            !current.matches('MissingItemErrorScreen') && (
              <ErrorMessage bold error={current.context.error!!} />
            )}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

import Ink from '@ink';
import React, { FC } from 'react';
import { AutoAssignTask, setTaskProps } from './AutoAssignTask';

export function autoAssignTaskRouter() {
  return ['auto-assign-task'];
}
export const AutoAssignTaskRouter: FC<setTaskProps> = ({
  taskId,
  setTask,
  needAutoAssign,
  backToMenu
}) => {
  return (
    <>
      <Ink.Box paddingBottom={1}>
        <Ink.Header>
          <Ink.Text bold>Seleccionando Tarea</Ink.Text>
        </Ink.Header>
      </Ink.Box>

      <AutoAssignTask
        taskId={taskId}
        needAutoAssign={needAutoAssign}
        setTask={setTask}
        backToMenu={backToMenu}
      />
    </>
  );
};

AutoAssignTaskRouter.displayName = 'AutoAssignTaskRouter';

import { MachineOptions } from 'xstate';
import { PalletRestockingTaskContext } from './machine';

type PalletRestockingTaskGuards = MachineOptions<
  PalletRestockingTaskContext,
  any
>['guards'];

export const palletRestockingTaskGuards: PalletRestockingTaskGuards = {
  isOriginLocationRequired: ctx => {
    return ctx.flagOriginLocation;
  },
  canMoveToLocation: (_ctx, evt) => {
    return evt.data.task;
  },
  isNotFoundException: (_ctx, evt) => {
    return evt.data.response.status === 404;
  },
  restockingRuleNotMetFlag: ctx => {
    return ctx.restockingRuleNotMetFlag;
  },
  locationAvailable: (_ctx, event) => event.data
};

import { ReservationPendingItemStatuses } from '../ReservationPendingItem';
import { ReservationPendingItemStatus } from './ReservationPendingItemStatus';

export class ReservationPendingItemPendingStatus extends ReservationPendingItemStatus {
  public displayName = 'Pendiente';

  public internalName = ReservationPendingItemStatuses.Pending;

  public statusColor = '#Ffffff';
}

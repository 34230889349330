import { PendingDispatchingPickItemStatuses } from '../PendingDispatchingPickItem';
import { PendingDispatchingPickItemStatus } from './PendingDispatchingPickItemStatus';

export class PendingDispatchingPickItemPickedStatus extends PendingDispatchingPickItemStatus {
  public displayName = 'Levantado';

  public internalName = PendingDispatchingPickItemStatuses.Picked;

  public statusColor = '#64b5f6';
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { NoveltyUnloadingContext } from './machine';

type NoveltyUnloadingActions = MachineOptions<
  NoveltyUnloadingContext,
  any
>['actions'];

export const noveltyUnloadingMachineActions: NoveltyUnloadingActions = {
  assignStagingLocation: assign({
    stagingLocation: (_, event) => event.data.stagingLocation
  }),
  assignProductScanned: assign({
    productScanned: (_, event) => event.data
  }),
  assignTotalProductsToUnload: assign({
    totalProductsToUnload: (_, event) => event.data.totalProductsToUnload
  }),
  assignProductsUnloaded: assign({
    productsUnloaded: (_, event) => event.data.productsUnloaded
  }),
  assignNoveltyProductType: assign({
    noveltyProductType: (_, event) => event.data
  }),
  assignNoveltyContainer: assign({
    noveltyContainer: (_, event) => event.data.noveltyContainer
  }),
  assignNoveltyContainerLPNToCreate: assign({
    noveltyContainerLpnToCreate: (_, event) => event.data.lpn
  }),
  assignTargetLocation: assign({
    targetLocation: (_, event) => event.data.targetLocation
  }),
  assignError: assign({
    error: (_, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearCtx: assign({
    productScanned:              (_, __) => null,
    noveltyProductType:          (_, __) => null,
    noveltyContainer:            (_, __) => null,
    noveltyContainerLpnToCreate: (_, __) => null,
    targetLocation:              (_, __) => null,
    error:                       (_, __) => null
  }),
  assignErrorIsNotInNovelty: assign({
    error: (_, __) =>
      'El producto escaneado no se encuentra en la recepcion o ya fue descargado'
  }),
  clearError: assign({
    error: (_, __) => null
  })
};

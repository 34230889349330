import { OrderType, OrderTypes, PickItem } from '@wms/domain';
import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveActions = MachineOptions<
  MidiPickingWaveContext,
  any
>['actions'];

export const midiPickingWaveActions: MidiPickingWaveActions = {
  assignSuggestedItem: assign({
    currentPickItem:  (_ctx, event) => event.data.pickItem,
    selectedLocation: (_ctx, _event) => null,
    selectedProduct:  (_ctx, _event) => null,
    selectedQuantity: (_ctx, _event) => null,
    selectedLabel:    (_ctx, _event) => null,
    reportedIncident: (_ctx, _event) => null,
    error:            (_ctx, _event) => null
  }),
  assignOrderType: assign({
    orderType: (_ctx, event) => event.data.pickItem.orderItem.order.orderType
  }),
  assignPickItem: assign({
    currentPickItem: (_ctx, event) => event.data.pickItem
  }),
  assignLocation: assign({
    selectedLocation: (_ctx, event) => event.data
  }),
  assignProduct: assign({
    selectedProduct: (_ctx, event) => event.data
  }),
  assignQuantity: assign({
    selectedQuantity: (_ctx, event) => event.data.quantity
  }),
  assignCountQuantity: assign({
    countQuantity: (_ctx, event) => event.data.quantity
  }),
  assignLabel: assign({
    selectedLabel: (_ctx, event) => event.data.label
  }),
  assignMissingItems: assign({
    missingItems: (_ctx, event) => event.data.missingItems
  }),
  assignPendingItems: assign({
    pendingItems:        (_ctx, event) => event.data.pendingItems,
    totalItems:          (_ctx, event) => event.data.totalItems,
    pickedItems:         (_ctx, event) => event.data.pickedItems,
    pendingItemsToLabel: (_ctx, event) => event.data.pendingItemsToLabel
  }),
  assignInvalidProductError: assign({
    error: (_context, event) => event.data.errorView
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      );
    }
  }),
  assignPendingItemsToSort: assign({
    pendingItemsToSort: (_ctx, event) => event.data.count,
    totalItemsToSort:   (_ctx, event) => event.data.total
  }),
  clearPickItem: assign({
    currentPickItem: (_ctx, _evt) => ({} as PickItem)
  }),
  setFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => true
  }),
  disableFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => false
  }),
  assignCourierType: assign({
    labelCourier: (ctx, _event) => {
      const orderTypeName = ctx.currentPickItem?.orderItem.order.orderType
        .name as OrderTypes;

      return OrderType.getCourier(orderTypeName);
    }
  }),
  assignUserId: assign({
    userId: (_ctx, event) => event.data?.data?.response?.data?.userId
  }),
  assignTypeHD: assign({
    typeOfLabel: _ctx => 'hd'
  }),
  assignTypeSS: assign({
    typeOfLabel: _ctx => 'ss'
  }),
  assignUpdatedContextError: assign({
    error: (_ctx, _event) => {
      return 'La ubicación y los pickItems asociados fueron actualizados,por favor dirijase a la nueva ubicacion';
    }
  }),
  assignLabelsToPaste: assign({
    labelsToPaste: (_ctx, evt) => evt.data
  }),
  removeLabelsToPaste: assign({
    labelsToPaste: (ctx, event) =>
      ctx.labelsToPaste?.filter(
        labelToPaste => labelToPaste !== event.data.label
      ) || []
  })
};

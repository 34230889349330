import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { NoSortableSlottingTaskContext } from './machine';

type NoSortableSlottingTaskServices = MachineOptions<
  NoSortableSlottingTaskContext,
  any
>['services'];

export const noSortableSlottingTaskServices: NoSortableSlottingTaskServices = {
  fetchContainersToNoSortableSlotting: async (ctx, _event) => {
    const { data } = await api.get(
      `no-sortable-slotting-process/${ctx.noSortableSlottingProcessId}/progress`
    );

    return {
      totalContainers:   data.total,
      slottedContainers: data.slotted
    };
  },

  fetchSuggestedContainerToNoSortableSlotting: async (ctx, _event) => {
    const { data } = await api.get(
      `no-sortable-slotting-process/${ctx.noSortableSlottingProcessId}/suggested-container`
    );

    return {
      suggestedContainerToNoSortableSlotting: data
    };
  },

  fetchSuggestedLocationToStore: async (ctx, _event) => {
    const { data } = await api.get(
      `no-sortable-slotting-process/${ctx.containerToNoSortableSlotting?.id}/suggested-location`
    );

    return {
      suggestedLocationToStore: data
    };
  }
};

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { MovingToPreSorterContainer } from './MovingToPreSorterContainer';
import { Receipt } from './Receipt';
import {
  MovingToPreSorterProcessStatus,
  MovingToPreSorterProcessStatusCancelledStatus,
  MovingToPreSorterProcessStatusCompletedStatus,
  MovingToPreSorterProcessStatusDraftStatus,
  MovingToPreSorterProcessStatusPausedStatus,
  MovingToPreSorterProcessStatusPendingStatus,
  MovingToPreSorterProcessStatusProcessingStatus
} from './moving-to-preSorter-process-status';

export const MovingToPreSorterProcessStatuses = {
  Draft:      'draft',
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class MovingToPreSorterProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [MovingToPreSorterProcessStatuses.Draft]:
      MovingToPreSorterProcessStatusDraftStatus,
    [MovingToPreSorterProcessStatuses.Pending]:
      MovingToPreSorterProcessStatusPendingStatus,
    [MovingToPreSorterProcessStatuses.Processing]:
      MovingToPreSorterProcessStatusProcessingStatus,
    [MovingToPreSorterProcessStatuses.Completed]:
      MovingToPreSorterProcessStatusCompletedStatus,
    [MovingToPreSorterProcessStatuses.Cancelled]:
      MovingToPreSorterProcessStatusCancelledStatus,
    [MovingToPreSorterProcessStatuses.Paused]:
      MovingToPreSorterProcessStatusPausedStatus
  })
  status: MovingToPreSorterProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => MovingToPreSorterContainer, 'movingToPreSorterProcessId')
  movingToPreSorterContainers: MovingToPreSorterContainer[];
}

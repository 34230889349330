import { InventoryAdjustItemStatuses } from '../InventoryAdjustItem';
import { InventoryAdjustItemStatus } from './InventoryAdjustItemStatus';

export class InventoryAdjustItemCancelledStatus extends InventoryAdjustItemStatus {
  public displayName = 'Cancelado';

  public internalName = InventoryAdjustItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#b90c0c';
}

import { PTWLocationItemStatuses } from '../PTWLocationItem';
import { PTWLocationItemStatus } from './PTWLocationItemStatus';

export class PTWLocationItemDraftStatus extends PTWLocationItemStatus {
  public displayName = 'Borrador';

  public internalName = PTWLocationItemStatuses.Draft;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

import { MachineOptions } from 'xstate';
import { NoveltyUnloadingContext } from './machine';

type NoveltyUnloadingGuards = MachineOptions<
  NoveltyUnloadingContext,
  any
>['guards'];

export const noveltyUnloadingMachineGuards: NoveltyUnloadingGuards = {
  hasPendingPackageToUnload: (_ctx, evt) => {
    return evt.data.pendingNoveltyUnloadingPackagesToUnload > 0;
  },

  hasNoveltySKUToUnload: (_ctx, evt) => {
    return evt.data.totalProductsToUnload !== evt.data.productsUnloaded;
  },

  isSKUInNovelty: (_ctx, evt) => {
    return evt.data;
  },
  hasNoveltyContainerToUnload: (_ctx, evt) => {
    return evt.data.noveltyContainer;
  }
};

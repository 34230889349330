import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusRejectedStatus extends ReservationItemStatus {
  public displayName = 'Rechazada';

  public internalName = ReservationItemStatuses.Rejected;

  public statusColor = '#f3d6e1';

  public textColor = '#b8006d';
}

import { MachineOptions } from 'xstate';
import { StagingAuditingContext } from './machine';

type StagingAuditingGuardsType = MachineOptions<
  StagingAuditingContext,
  any
>['guards'];

export const StagingAuditingGuards: StagingAuditingGuardsType = {
  hasContainersToAudit: (ctx, evt) => {
    return evt.data.containersToAudit !== 0;
  },
  hasMismatchedContainers: (ctx, evt) => {
    return evt.data.containersMismatched !== 0;
  }
};

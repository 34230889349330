import { NoveltyMoveContainerToBufferItemStatuses } from '../NoveltyMoveContainerToBufferItem';
import { NoveltyMoveContainerToBufferItemStatus } from './NoveltyMoveContainerToBufferItemStatus';

export class NoveltyMoveContainerToBufferItemStatusMovingStatus extends NoveltyMoveContainerToBufferItemStatus {
  public displayName = 'En movimiento';

  public internalName = NoveltyMoveContainerToBufferItemStatuses.Moving;

  public statusColor = '#Ffffff';
}

import {
  Container,
  MopSorterExitItem,
  Package,
  SortingItem
} from '@wms/domain';
import { createMachine } from 'xstate';

import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { containerDetailActions } from './actions';
import { containerDetailGuards } from './guards';
import { containerDetailServices } from './services';

export type Item = SortingItem | Package | MopSorterExitItem;

export interface ContainerDetailContext {
  container: Container | null;
  items: string[] | null;
  error: string | null;
  receiptId: number | null;
  destination: string | null;
  orderIds: number[] | null;
}

export const DefaultContainerDetailContext: ContainerDetailContext = {
  container:   null,
  items:       null,
  error:       null,
  receiptId:   null,
  destination: null,
  orderIds:     null
};

export const ContainerDetailMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGED2A7ALgQwJbrACcARMHXAGwDoBlAY23QBlsAjMCgYggzCvwBuqANZ80WPARJk81eoxbsKCQagaZcGANoAGALq69iUAAdUsXBozGQAD0QAWAEwAaEAE9ETgOw6qDnQA2JwBWHR8ADgiATh1vAF94t3FyKVJyOQZmNg5uXn50IVEqFMkidNlaLMUOFUK1bCt0Qy0ARiMkEDMLJpt7BBCAZgiqQICQ6IcHCKdnVui3TwQA7yonQYdB7xDImLjE5IxU8plKKoUcriJCVEIqEwpGgDNbgFsSo7LpDPPspTqhOpNM19IYbN1LMC+ohWq1AlRooEIttFjDvKsHENWqFdrEEkkQKV8CcfvI-rk6BQwNhCABRQg3QhgzoQ3qdfpOeZUVoBQI6aLeBzY1r8hyohARVpUMI6WXhbxRPGtA6Ez7E76VABiZDoAAsKpQ8gQCkUxGq0qdqNrMHqDcpVEDtKD9ODzJDrOyYa0IiEqL5BkFBojppsIuKVlQdFMfLDsU5ZiqiRaftbbZbONdbvdHpgXoR3kmSVqdfrLQCGk0Wi6WW62aB+jMRt5vUiYmMIqHxU5kVRG9idgq9vjDhJ1XaqHbOIQ4FlmaZa1DPQhYYjuc3JUH252PDCplRBiFWiFgujFfsVehUBA4DZCxrKK6eov64gALSBcXvxPmotnMk1ChH3ddBoQQJwdEGNY9gcbxZmFUVxSDBxe37WVcXPEdjnvK0SztIC6zsRBomiX0sW9NsQ2GcMdF9HwdAiQZBkCOMEwJO9xzwmsnw9F9lwPSCfWiQZWk3Siwx3ZZ1m5ZEZXQ-FEiAA */
    id:     'ContainerDetail',
    schema: {
      context: {} as ContainerDetailContext
    },
    context: DefaultContainerDetailContext,
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: _ctx =>
            GetContainerByLpnMachine(
              'Escanear contenedor a consultar',
              getAnyContainerByLpn(
                'Ocurrió un error, por favor intente de nuevo'
              )
            ),
          onDone: [
            {
              actions: 'assignContainer',
              target:  'FetchDetail'
            }
          ]
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      FetchDetail: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchItems',
          onDone: {
            actions: ['assignItems', 'assignFlowIds'],
            target:  'Detail'
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError', 'restartValues']
          }
        }
      },
      Detail: {
        on: {
          rescan: {
            target:  'ScanningContainer',
            actions: 'restartValues'
          }
        }
      }
    }
  },
  {
    actions:  containerDetailActions,
    services: containerDetailServices,
    guards:   containerDetailGuards
  }
);

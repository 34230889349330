import {
  InventoryItem,
  MopSorterExitItem,
  Package,
  Product,
  SortingItem
} from '@wms/domain';
import { createMachine } from 'xstate';

import { AlertData } from '../../../../types/alerData';
import { PopUpMachine } from '../../layer-4/pop-up/machine';
import { getAnyProductBySKUToValidate } from '../../layer-4/product/get-product-by-sku/fetchers/get-any-product-by-sku-to-validate';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { ProductDetailActions } from './actions';
import { ProductDetailGuards } from './guards';
import { ProductDetailServices } from './services';

export type Item = SortingItem | Package | MopSorterExitItem;

export interface ProductDetailContext {
  product: Product | null;
  inventoryItems: InventoryItem[];
  error: string | null;
  alertData: AlertData;
}

export const DefaultProductDetailContext: ProductDetailContext = {
  product:        null,
  inventoryItems: [],
  error:          null,
  alertData:      {
    errorTitle:     'Error',
    errorMessage:   'Ocurrió un error, por favor intente de nuevo',
    positiveTarget: null,
    negativeTarget: null,
    target:         null
  }
};

export const ProductDetailMachine = createMachine(
  {
    id:     'ProductDetail',
    schema: {
      context: {} as ProductDetailContext
    },
    context: DefaultProductDetailContext,
    initial: 'ScanningSKU',
    states:  {
      ErrorRouter: {
        always: [
          {
            cond:   'goToScanningSKU',
            target: 'ScanningSKU'
          }
        ]
      },
      HandleErrors: {
        invoke: {
          id:   PopUpMachine.id,
          src:  PopUpMachine,
          data: {
            alertData: ctx => {
              return ctx.alertData;
            }
          },
          onDone: {
            target:  'ErrorRouter',
            actions: ['clearError', 'restartValues']
          }
        }
      },
      ScanningSKU: {
        tags:   ['sku'],
        invoke: {
          id:  GetProductBySKUMachineId,
          src: GetProductBySKUMachine(
            'Escanee SKU',
            getAnyProductBySKUToValidate(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: ['assignProduct', 'clearError'],
            target:  'FetchDetail'
          }
        }
      },
      FetchDetail: {
        invoke: {
          src:    'fetchProduct',
          onDone: {
            actions: 'assignInventoryItems',
            target:  'Detail'
          },
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },
      Detail: {
        on: {
          rescan: {
            target:  'ScanningSKU',
            actions: 'restartValues'
          }
        }
      }
    }
  },
  {
    actions:  ProductDetailActions,
    services: ProductDetailServices,
    guards:   ProductDetailGuards
  }
);

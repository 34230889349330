import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpnOrLocationLabelMachine } from './EnterLpnOrLocationLabelMachine';

export const EnterLpnOrLocationLabel: FC = withActorRef(
  EnterLpnOrLocationLabelMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const setLpnOrLocationLabel = useCallback(
    (input: string) => {
      send({
        type: 'lpnOrLocationLabelScanned',
        data: { lpnOrLocationLabel: input }
      });
    },
    [send]
  );

  const submitLpnOrLocationLabel = useCallback(() => {
    send('submitLpnOrLocationLabel');
  }, [send]);

  return (
    <>
      <Ink.Text bold color='highlightColor'>
        {current.context.hint}
      </Ink.Text>

      <Ink.TextInput
        value={
          current.context.lpnOrLocationLabel
            ? current.context.lpnOrLocationLabel
            : ''
        }
        onChange={setLpnOrLocationLabel}
        onSubmit={submitLpnOrLocationLabel}
      />

      {current.context.error && (
        <Ink.Text color='red'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});

import { PTWLocationItemStatuses } from '../PTWLocationItem';
import { PTWLocationItemStatus } from './PTWLocationItemStatus';

export class PTWLocationItemMovingStatus extends PTWLocationItemStatus {
  public displayName = 'Moviendo';

  public internalName = PTWLocationItemStatuses.Moving;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { TaskRoleAdmin } from '@wms/domain';
import { useSelector } from '@xstate/react';
import { useCallback } from 'react';
import { useRootMachine } from '../shared/Machine';
import { TaskProcessType } from '../tasks/taskIndex';
import { MenuItem } from './types';

export function useCanAccess(): (menuItem: MenuItem) => boolean {
  const rootMachine = useRootMachine();
  const userTaskRoles = useSelector(
    rootMachine.interpreter,
    emitted => emitted.context.session?.user?.userTaskRoles
  );

  return useCallback(
    menuItem => {
      const taskRoles = Array.isArray(userTaskRoles)
        ? userTaskRoles.map(userTaskRole => userTaskRole.taskRole.name)
        : [];

      const isAdmin = taskRoles.some(taskRole =>
        Object.keys(TaskRoleAdmin).includes(taskRole)
      );

      if (isAdmin) {
        return true;
      }

      let taskRoleCheck: boolean = true;
      let taskTypeCheck: boolean = true;

      if (menuItem.taskRoles && Array.isArray(menuItem.taskRoles)) {
        taskRoleCheck = taskRoles.some(taskRole =>
          menuItem.taskRoles!.includes(taskRole)
        );
      }

      if (menuItem.checkTaskTypeAccess) {
        const processType = TaskProcessType[menuItem.key];

        if (
          processType &&
          'validBucketTypes' in processType &&
          Array.isArray(processType.validBucketTypes)
        ) {
          const taskTypes = Array.isArray(userTaskRoles)
            ? userTaskRoles.reduce(
                (res, userTaskRole) => [
                  ...res,
                  ...(userTaskRole.taskRole?.taskRoleTaskTypes || []).map(
                    taskRoleTaskType => taskRoleTaskType.taskType.internalName
                  )
                ],
                []
              )
            : [];

          taskTypeCheck = processType.validBucketTypes.some(validBucketType =>
            taskTypes.includes(validBucketType)
          );
        } else {
          taskTypeCheck = true;
        }
      }

      return taskRoleCheck && taskTypeCheck;
    },
    [userTaskRoles]
  );
}

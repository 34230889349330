import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { DispatchHDContext } from './machine';

type DispatchHDServices = MachineOptions<DispatchHDContext, any>['services'];

export const dispatchHDServices: DispatchHDServices = {
  fetchDispatchingWaveDetails: async (ctx, _evt) => {
    const { data } = await api.get(`dispatching-wave/${ctx.dispatchingWaveId}`);
    return {
      dispatchingWave: data
    };
  },
  fetchSuggestedDispatchingItem: async (ctx, _evt) => {
    const [nextItem, count] = await Promise.all([
      api.get(
        `dispatching-wave/${ctx.dispatchingWaveId}/next-dispatching-item`
      ),
      api.get(`dispatching-wave/${ctx.dispatchingWaveId}/pending-items-count`)
    ]);
    return {
      dispatchingItem: nextItem.data,
      counts:          count.data
    };
  },
  fetchPartiallyDispatchedReservation: async (ctx, _evt) => {
    const { data } = await api.get(
      `dispatching-wave/${ctx.dispatchingWaveId}/has-partially-dispatched-reservation`
    );
    return {
      hasPartiallyDispatchedReservation: data.hasPartiallyDispatchedReservation,
      itemsToLoad:                       data.itemsToLoad,
      itemsToUnload:                     data.itemsToUnload,
      labelsToLoad:                      data.labelsToLoad,
      labelsToUnload:                    data.labelsToUnload,
      partiallyDispatchedReservations:   data.partiallyDispatchedReservations
    };
  },
  createPartiallyDispatchIncident: async (ctx, _evt) => {
    await api.post('dispatching-wave/create-partially-dispatched-incident', {
      orderId: ctx.orderId,
      userId:  ctx.task.userId
    });
  },
  fetchSuggestedDispatchingItemToUnload: async (ctx, _evt) => {
    const { data } = await api.get(
      `dispatching-wave/${ctx.dispatchingWaveId}/next-dispatching-item-to-unload`
    );
    return {
      dispatchingItem: data
    };
  },
  checkIfReservationIsUnrouted: async (ctx, _evt) => {
    const { data } = await api.get(
      `dispatching-item/reservation/${ctx.scannedLabel}/is-unrouted`,
      {}
    );
    return data;
  }
};

import { Entity, Field, PrimaryKey } from '@skyframe/core';

@Entity()
export class ArbaCOTCodeProvince {
  @PrimaryKey()
  id: number;

  @Field({ name: 'province_code' })
  provinceCode: string;

  @Field({ name: 'province' })
  province: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'locality' })
  locality: string;

  @Field({ name: 'locality_code' })
  localityCode: string;

  @Field({ name: 'zip_code' })
  zipCode: string;
}

import { Container, Location, mismatchTypes } from '@wms/domain';
import { createMachine } from 'xstate';

import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';
import { EnterQuantityMachine } from '../../capa-4/enter-quantity/EnterQuantityMachine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';

import { getExistingProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-existing-product-by-sku';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { inventoryAdjustmentActions } from './actions';
import { inventoryAdjustmentGuards } from './guards';
import { inventoryAdjustmentServices } from './services';

export interface InventoryAdjustmentContext {
  error: string;
  warning: string;
  mismatchItems: Array<{
    sku: string;
    physicalQuantity: number;
    logicalQuantity: number;
    name: string;
    mismatchType: mismatchTypes;
    containerLpn: string | null;
    originalContainerLpn: string | null;
  }>;
  lpn: string;
  container: Container;
  itemsToAdjust: Array<{ sku: string; quantity: number }> | null;
  location: Location | null;
  currentItem: { sku: string; quantity: number } | null;
  ordersWithReservedItemsInLocation: number[];
}

export const DefaultInventoryAdjustmentContext = {
  error:                             '',
  warning:                           '',
  itemsToAdjust:                     [],
  mismatchItems:                     [],
  currentSku:                        null,
  location:                          null,
  ordersWithReservedItemsInLocation: [],
  lpn:                               ''
};

export const InventoryAdjustmentMachine = createMachine(
  {
    id:                         'InventoryAdjustment',
    predictableActionArguments: true,
    schema:                     {
      context: {} as InventoryAdjustmentContext
    },
    initial: 'ScanLocation',
    states:  {
      ScanLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: () =>
            GetLocationByNameMachine(
              'Escanee ubicacion a controlar',
              getAnyLocationByName(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: [
            {
              cond:    'invalidType',
              target:  'ScanLocation',
              actions: 'invalidTypeError'
            },
            {
              actions: ['setLocation', 'clearError'],
              target:  'CheckReservedItemsInLocation'
            }
          ]
        }
      },
      CheckReservedItemsInLocation: {
        invoke: {
          src:    'orderIdsWithReservedItemsInLocation',
          onDone: [
            {
              cond:    'areReservedItemsInLocation',
              target:  'ScanItem',
              actions: ['reservedItemInLocationError']
            },
            {
              cond:   'canCreateContainer',
              target: 'WantToCreateContainer'
            },
            {
              target: 'ScanItem'
            }
          ],
          onError: 'ScanLocation'
        }
      },
      WantToCreateContainer: {
        on: {
          SCAN_CONTAINER: 'ScanContainer',
          SCAN_ITEM:      'ScanItem'
        }
      },
      ScanContainer: {
        invoke: {
          id:     EnterLpnMachine.id,
          src:    EnterLpnMachine,
          data:   DefaultEnterLpnContext,
          onDone: {
            actions: ['assignLpn', 'clearError'],
            target:  'GetContainer'
          }
        }
      },
      GetContainer: {
        invoke: {
          src:    'getContainer',
          onDone: [
            {
              cond:    'ValidLpn',
              actions: 'setContainer',
              target:  'ScanItem'
            },
            {
              actions: 'assignInvalidLpnError',
              target:  'ScanContainer'
            }
          ],
          onError: 'ScanContainer'
        }
      },
      ScanItem: {
        invoke: {
          id:  GetProductBySKUMachineId,
          src: () =>
            GetProductBySKUMachine(
              'Confirme escaneando SKU del producto',
              getExistingProductBySKU(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: [
            {
              actions: ['setCurrentItem', 'assignToItemsToAdjust'],
              target:  'SelectingQuantity'
            }
          ]
        },
        on: {
          FINISH_CONTROL: 'VerifyStock'
        }
      },
      SelectingQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: {
            min:  1,
            hint: 'Ingrese cantidad'
          },
          onDone: [
            {
              actions: 'setQuantity',
              target:  'ScanItem'
            }
          ]
        },
        exit: 'clearError'
      },
      VerifyStock: {
        invoke: {
          src:    'verifyStock',
          onDone: {
            actions: 'setMismatchItems',
            target:  'DetailOfVerification'
          }
        }
      },
      DetailOfVerification: {
        on: {
          CONFIRM_INCIDENT: 'MismatchIventoryControl',
          CONFIRM:          'Done'
        }
      },
      MismatchIventoryControl: {
        invoke: {
          src:    'mismatchInventoryAdjustment',
          onDone: {
            target:  'ConfirmControl',
            actions: ['clearContext', 'clearWarning']
          },
          onError: {
            target:  'ScanLocation',
            actions: ['assignError', 'clearContext', 'clearWarning']
          }
        }
      },
      ConfirmControl: {
        on: {
          INVENTORY_CONTROL: 'ScanLocation',
          CONFIRM:           'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   inventoryAdjustmentGuards,
    actions:  inventoryAdjustmentActions,
    services: inventoryAdjustmentServices
  }
);

import {
  ConsolidatingContainersToStagingContainer,
  Container,
  Location,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';

import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-requested-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { consolidatingContainersToStagingActions } from './actions';
import { consolidatingContainersToStagingServices } from './services';

export interface ConsolidatingContainersToStagingContext {
  task: Task;
  consolidatingToStagingContainerId: number;
  slotPosition: number;
  locationName: string;

  consolidatingContainersToStagingContainer: ConsolidatingContainersToStagingContainer | null;
  containerToScan: Container | null;
  destinyLocation: Location | null;

  error: string | null;
}

export const DefaultConsolidatingContainersToStagingContext: ConsolidatingContainersToStagingContext =
  {
    task:                                      {} as Task,
    consolidatingToStagingContainerId:         -1,
    slotPosition:                              -1,
    locationName:                              '',
    consolidatingContainersToStagingContainer: null,
    containerToScan:                           null,
    destinyLocation:                           null,
    error:                                     null
  };

export const ConsolidatingContainersToStagingMachine = createMachine(
  {
    id:                         'consolidatingContainerstoStaging',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ConsolidatingContainersToStagingContext
    },
    initial: 'FetchingConsolidatingContainersToStaging',
    states:  {
      FetchingConsolidatingContainersToStaging: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchConsolidatingContainersToStaging',
          onDone: {
            target:  'ScanningContainer',
            actions: ['assignConsolidatingContainer']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningContainer: {
        tags:   ['saveable'],
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanear contenedor',
              getRequestedContainerByLpn(
                'Ocurrion un error, por favor intente de nuevo',
                'El contenedor escaneado es invalido',
                ctx.containerToScan!.lpn
              )
            ),
          onDone: {
            target:  'FetchingDestinyLocation',
            actions: ['clearError']
          }
        }
      },
      FetchingDestinyLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchDestinyLocation',
          onDone: {
            target:  'ScanningDestinyLocation',
            actions: ['assignDestinyLocation']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningDestinyLocation: {
        tags:   ['saveable'],
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Ingrese dígitos de control',
              getRequestedCheckDigit(
                'Ocurrió un error, intente más tarde.',
                'Ingrese el dígito de control requerido',
                ctx.destinyLocation!.checkDigit
              )
            ),
          onDone: {
            target:  'ConsolidationContainerConsolidated',
            actions: ['clearError']
          }
        }
      },
      ConsolidationContainerConsolidated: {
        tags:   ['loading'],
        invoke: {
          src:     'ConsolidationContainerConsolidated',
          onDone:  'Done',
          onError: {
            target:  'ScanningDestinyLocation',
            actions: ['assignError']
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    actions:  consolidatingContainersToStagingActions,
    services: consolidatingContainersToStagingServices
  }
);

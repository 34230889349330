import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MovePendingDispatchingItemsMachine } from './machine';

export const MovePendingDispatchingItemsTask = withActorRef(
  MovePendingDispatchingItemsMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const continueTask = useCallback(() => send({ type: 'CONFIRM' }), [send]);

  return (
    <>
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Ola de traslado Nº {current.context.movePendingDispatchingItemsWaveId}{' '}
          - Canal {current.context.stagingLocation?.name || 'N/A'}
        </Ink.Header>
      </Ink.Box>

      <Ink.Box flexDirection='column'>
        <>
          {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}

          {current.matches('ScanningControlDigit') && (
            <>
              <HighlightedInfo
                attribute='Ingrese digito de control del canal'
                value={current.context.stagingLocation?.name || ' '}
              />
              <Ink.Text />
              <ScanControlDigit />
              {current.context.error && (
                <ErrorMessage error={current.context.error} />
              )}
            </>
          )}

          {current.matches('ScanningContainer') && (
            <>
              <HighlightedInfo
                attribute='Ingrese contenedor'
                value={
                  current.context.suggestedNextPendingDispatchingItemToMove!
                    .container.lpn || ' '
                }
              />
              <Ink.Text />
              <GetContainerByLpn />
            </>
          )}

          {current.matches('ScanningDestinyLocation') && (
            <>
              <HighlightedInfo
                attribute='Ingrese ubicación de destino'
                value={current.context.suggestedDestinyLocationName || ' '}
              />
              <Ink.Text />
              <GetLocationByName />
            </>
          )}

          {current.matches('ConfirmingScreen') && (
            <>
              <Ink.Text bold>
                Se ha terminado correctamente la Ola de Traslado
              </Ink.Text>
              <Ink.Button
                label='Finalizar Proceso'
                onClick={continueTask}
                style={{ width: '40%' }}
              />
            </>
          )}
        </>
      </Ink.Box>
    </>
  );
});

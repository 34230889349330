import { PendingDispatchingPickingProcessStatuses } from '../PendingDispatchingPickingProcess';
import { PendingDispatchingPickingProcessStatus } from './PendingDispatchingPickingProcessStatus';

export class PendingDispatchingPickingProcessPausedStatus extends PendingDispatchingPickingProcessStatus {
  public displayName = 'Pausado';

  public internalName = PendingDispatchingPickingProcessStatuses.Paused;

  public statusColor = '#e6f4ff';

  public textColor = '#ffa300';
}

import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusConsolidatedStatus extends OrderItemStatus {
  public displayName = 'Consolidado';

  public internalName = OrderItemStatuses.Consolidated;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

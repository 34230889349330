import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MissingMiniMopItemsContext } from './machine';

type MissingMiniMopItemsActions = MachineOptions<
  MissingMiniMopItemsContext,
  any
>['actions'];

export const missingMiniMopItemsActions: MissingMiniMopItemsActions = {
  assignContainerType: assign({
    containerType: (_, event) => event.data.containerType
  }),
  assignContainerCreated: assign({
    containerCreated: (_, event) => event.data.container
  }),
  assignPickItemCount: assign({
    pickItemsTotalCount:  (_, event) => event.data.pickItemsTotalCount,
    pickItemsPickedCount: (_, event) => event.data.pickItemsPickedCount
  }),
  assignNextPickItem: assign({
    nextPickItem: (_, event) => event.data.nextPickItem
  }),
  assignQuantityPicked: assign({
    quantityPicked: (_, event) => event.data.quantity
  }),
  assignPackingStationLocation: assign({
    packingStationLocation: (_, event) => event.data.packingStationLocation
  }),
  assignDestinyContainer: assign({
    destinyContainer: (_, event) => event.data.destinyContainer
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearError: assign({
    error: (_, __) => null
  })
};

import {
  Container,
  SellerProduct,
  Task,
  UnloadingContainer
} from '@wms/domain';
import { createMachine } from 'xstate';

import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getUnloadableContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-unloadable-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getUnloadingLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-unloading-location-by-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { getAnySellerProductByCode } from '../../layer-4/seller-product/get-seller-product-by-code/fetchers/get-any-seller-product-by-code';
import {
  GetSellerProductByCodeMachine,
  GetSellerProductByCodeMachineId
} from '../../layer-4/seller-product/get-seller-product-by-code/machine';
import { unloadingTaskActions } from './actions';
import { unloadingTaskGuards } from './guards';
import { unloadingTaskServices } from './services';

export interface UnloadingTaskContext {
  task: Task;
  unloadingProcessId: number;
  locationId: number;
  totalContainersToCreate: number;
  pendingContainersToCreate: number;
  currentContainer: UnloadingContainer;
  containerLpn: string;
  totalUnloadingItems: number;
  unloadedUnloadingItems: number;
  suggestedUnloadingItemCode: string | null;
  sellerProductToUnload: SellerProduct | null;
  suggestedContainerToUnload: UnloadingContainer | null;
  containerToUnload: Container | null;
  error: string;
}

export const DefaultUnloadingTaskContext: UnloadingTaskContext = {
  task:                       {} as Task,
  unloadingProcessId:         -1,
  locationId:                 -1,
  totalContainersToCreate:    -1,
  pendingContainersToCreate:  -1,
  currentContainer:           {} as UnloadingContainer,
  containerLpn:               '',
  totalUnloadingItems:        -1,
  unloadedUnloadingItems:     -1,
  suggestedUnloadingItemCode: null,
  sellerProductToUnload:      null,
  suggestedContainerToUnload: null,
  containerToUnload:          null,
  error:                      ''
};

export const UnloadingTaskMachine = createMachine(
  {
    id:                         'UnloadingTask',
    predictableActionArguments: true,
    schema:                     {
      context: {} as UnloadingTaskContext
    },
    initial: 'EnteringCheckDigitScreen',
    states:  {
      EnteringCheckDigitScreen: {
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Ingrese dígitos de control',
              getUnloadingLocationByCheckDigit(
                'No se encontro una Location con ese codigo de verificación',
                ctx.unloadingProcessId
              )
            ),
          onDone: [
            {
              actions: 'assignLocationId',
              target:  'FetchingPendingContainersToCreate'
            }
          ]
        }
      },

      FetchingPendingContainersToCreate: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchPendingContainersToCreate',
          onDone: [
            {
              cond:    'hasPendingContainersToCreate',
              actions: ['assignContainersToCreate'],
              target:  'EnteringLpn'
            },
            {
              target: 'FetchingUnloadingItemsToUnload'
            }
          ]
        }
      },

      EnteringLpn: {
        invoke: {
          id:     EnterLpnMachine.id,
          src:    EnterLpnMachine,
          data:   DefaultEnterLpnContext,
          onDone: [
            {
              actions: 'assignContainerLpn',
              target:  'CreateContainer'
            }
          ]
        }
      },

      CreateContainer: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'UnloadingCreateContainer',
              payload: {
                unloadingProcessId: ctx.unloadingProcessId,
                containerLpn:       ctx.containerLpn,
                locationId:         ctx.locationId,
                sortable:           ctx.currentContainer?.sortable,
                pure:               ctx.currentContainer?.pure,
                expectedDate:       ctx.currentContainer?.expectedDate
              }
            };
          },
          onDone: {
            target: 'FetchingPendingContainersToCreate'
          }
        }
      },

      FetchingUnloadingItemsToUnload: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchUnloadingItemsToUnload',
          onDone: [
            {
              cond:    'hasUnloadingItemsToUnload',
              actions: ['assignUnloadingItems'],
              target:  'FetchingSuggestedUnloadingItemToUnload'
            },
            {
              target: 'ConfirmingUnloadingScreen'
            }
          ]
        }
      },

      FetchingSuggestedUnloadingItemToUnload: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedUnloadingItemToUnload',
          onDone: {
            actions: 'assignSuggestedUnloadingItemToUnload',
            target:  'ScanUnloadingItem'
          }
        }
      },

      ScanUnloadingItem: {
        invoke: {
          id:  GetSellerProductByCodeMachineId,
          src: GetSellerProductByCodeMachine(
            'Escanee código de bulto a recibir',
            getAnySellerProductByCode(
              'No se encontro un SellerProduct con ese codigo'
            )
          ),
          onDone: [
            {
              actions: ['assignSellerProductToUnload'],
              target:  'FetchingSuggestedContainerToUnload'
            }
          ]
        }
      },

      FetchingSuggestedContainerToUnload: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedContainerToUnload',
          onDone: {
            actions: ['assignSuggestedContainerToUnload'],
            target:  'ScanContainer'
          }
        }
      },

      ScanContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee etiqueta de contenedor para confirmar movimiento',
              getUnloadableContainerByLpn(
                'No se encontro un Contenedor con ese lpn',
                ctx.sellerProductToUnload?.id as number,
                ctx.unloadingProcessId
              )
            ),
          onDone: {
            actions: ['assignContainerToUnload'],
            target:  'CompletingUnloadingItem'
          }
        }
      },

      CompletingUnloadingItem: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'UnloadingUnloadItem',
              payload: {
                sellerProductId: ctx.sellerProductToUnload?.id,
                containerId:     ctx.containerToUnload?.id
              }
            };
          },
          onDone: {
            actions: 'clearError',
            target:  'FetchingUnloadingItemsToUnload'
          },
          onError: {
            actions: 'assignError',
            target:  'ContainerErrorScreen'
          }
        }
      },

      ContainerErrorScreen: {
        on: {
          back: {
            actions: 'clearError',
            target:  'ScanContainer'
          }
        }
      },

      ConfirmingUnloadingScreen: {
        on: {
          confirm: {
            target: 'CompletingUnloading'
          }
        }
      },

      CompletingUnloading: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'UnloadingConfirm',
            payload: {
              processId: ctx.unloadingProcessId
            }
          }),
          onDone: {
            target: 'Done'
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   unloadingTaskGuards,
    actions:  unloadingTaskActions,
    services: unloadingTaskServices
  }
);

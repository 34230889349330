import { MachineOptions } from 'xstate';
import {
  GetLocationByCheckDigitContext,
  GetLocationByCheckDigitFn
} from './machine';

type GetLocationByCheckDigitServices = MachineOptions<
  GetLocationByCheckDigitContext,
  any
>['services'];

export const getLocationByCheckDigitServices: (
  fetchFn: GetLocationByCheckDigitFn
) => GetLocationByCheckDigitServices = fetchFn => ({
  fetchLocation: (context, _event) => fetchFn(context.locationCheckDigit.trim())
});

import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getItemByLabelActions } from './actions';
import { getItemByLabelGuards } from './guards';
import { getItemByLabelServices } from './services';

export interface Item {
  label: string;
}
export interface GetItemByLabelContext {
  readonly hint: string;
  itemLabel: string;
  item: Item | null;
  error: string | null;
  canScanBuckets: boolean;
  scalateErrorsToParent: boolean;
}

export type GetItemByLabelFn = (lpn: string) => Promise<Maybe<Item>>;

export const GetItemByLabelMachineId = 'GetItemByLabel';

export const GetItemByLabelMachine = (
  hint: string,
  fetchFn: GetItemByLabelFn,
  options: {
    canScanBuckets: boolean;
  } = { canScanBuckets: false },
  scalateErrorsToParent: boolean = false
) =>
  createMachine(
    {
      id:                         GetItemByLabelMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetItemByLabelContext
      },
      context: {
        hint,
        itemLabel:      '',
        item:           null,
        error:          null,
        canScanBuckets: options.canScanBuckets,
        scalateErrorsToParent
      },
      initial: 'EnteringItemLabel',
      states:  {
        EnteringItemLabel: {
          entry: 'clearItemLabel',
          on:    {
            UpdateItemLabel: {
              actions: ['updateItemLabel', 'clearError', 'clearParentError']
            },
            SubmitItemLabel: [
              {
                cond:    'isValidItemLabel',
                target:  'FetchingItem',
                actions: ['clearError']
              },
              {
                cond:    'scalateErrorsToParent',
                actions: 'throwError',
                target:  'Finished'
              },
              {
                actions: ['clearItemLabel', 'assignInvalidLabelError']
              }
            ]
          }
        },
        FetchingItem: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchItem',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignItem',
                target:  'Finished'
              },
              {
                cond:    'scalateErrorsToParent',
                target:  'Finished',
                actions: 'throwError'
              },
              {
                actions: ['assignError'],
                target:  'EnteringItemLabel'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.item
        }
      }
    },
    {
      guards:   getItemByLabelGuards,
      actions:  getItemByLabelActions,
      services: getItemByLabelServices(fetchFn)
    }
  );

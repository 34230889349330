import { Entity, ForeignKey, Knows, NeededBy, Needs } from '@skyframe/core';
import { Appointment } from './Appointment';
import { BaseLocation, LocationStatuses } from './BaseLocation';
import { CategoryLocation } from './CategoryLocation';
import { ClassificationContainer } from './ClassificationContainer';
import { ConsolidateContainersWave } from './ConsolidateContainersWave';
import { Container } from './Container';
import { DispatchingWave } from './DispatchingWave';
import { ImpureSortableCrossdockClassificationContainer } from './ImpureSortableCrossdockClassificationContainer';
import { Incident } from './Incident';
import { InventoryAdjustItem } from './InventoryAdjustItem';
import { InventoryCountingItem } from './InventoryCountingItem';
import { InventoryItem } from './InventoryItem';
import { LabelingDesk } from './LabelingDesk';
import { LocationAssignment } from './LocationAssignment';
import { LocationLocationAttribute } from './LocationLocationAttribute';
import { LocationProductAttribute } from './LocationProductAttribute';
import { LocationType } from './LocationType';
import { LocationZone } from './LocationZone';
import { NoveltyMoveContainerToBufferContainer } from './NoveltyMoveContainerToBufferContainer';
import { NoveltyUnloadingContainerV2 } from './NoveltyUnloadingContainerV2';
import { Order } from './Order';
import { Package } from './Package';
import { PackingWave } from './PackingWave';
import { PickItem } from './PickItem';
import { PickingProcess } from './PickingProcess';
import { PickingWave } from './PickingWave';
import { PickingZoneLocation } from './PickingZoneLocation';
import { PreStorageLocationMapping } from './PreStorageLocationMapping';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { ProductRestockingLevelAlert } from './ProductRestockingLevelAlert';
import { Receipt } from './Receipt';
import { RestockingItem } from './RestockingItem';
import { Route } from './Route';
import { SorterExitBranch } from './SorterExitBranch';
import { SorterWMSLocation } from './SorterWMSLocation';
import { StagingAuditingWave } from './StagingAuditingWave';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import { TaskRoleLocation } from './TaskRoleLocation';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';

export const LocationTypeZoneName = {
  ABA: 'ABA',
  ALM: 'ALM',
  AV:  'AV',
  GRA: 'GRA',
  STK: 'STK',
  STG: 'STG',
  PA:  'PA',
  RE:  'RE',
  LY:  'LY',
  INS: 'INS',
  STI: 'STI'
};

export const LocationMatchStorageWithPicking = {
  ABA: 'ALM',
  ALM: 'ABA',
  AV:  'STK',
  STK: 'AV',
  GRA: 'STG',
  STG: 'GRA',
  INS: 'STI',
  STI: 'INS'
};

export enum SorterExitLocations {
  'SAL-S-01' = 'SAL-S-01',
  'SAL-S-02' = 'SAL-S-02',
  'SAL-S-03' = 'SAL-S-03',
  'SAL-S-04' = 'SAL-S-04',
  'SAL-S-05' = 'SAL-S-05',
  'SAL-S-06' = 'SAL-S-06',
  'SAL-S-07' = 'SAL-S-07',
  'SAL-S-08' = 'SAL-S-08',
  'SAL-S-09' = 'SAL-S-09',
  'SAL-S-10' = 'SAL-S-10',
  'SAL-S-11' = 'SAL-S-11',
  'SAL-S-12' = 'SAL-S-12',
  'SAL-S-13' = 'SAL-S-13',
  'SAL-S-14' = 'SAL-S-14',
  'SAL-S-15' = 'SAL-S-15',
  'SAL-S-16' = 'SAL-S-16',
  'SAL-S-17' = 'SAL-S-17',
  'SAL-S-18' = 'SAL-S-18',
  'SAL-S-19' = 'SAL-S-19',
  'SAL-S-20' = 'SAL-S-20',
  'SAL-S-21' = 'SAL-S-21',
  'SAL-S-22' = 'SAL-S-22',
  'SAL-S-23' = 'SAL-S-23',
  'SAL-S-24' = 'SAL-S-24',
  'SAL-S-25' = 'SAL-S-25',
  'SAL-S-26' = 'SAL-S-26',
  'SAL-S-27' = 'SAL-S-27',
  'SAL-S-28' = 'SAL-S-28'
}

export enum UnusableLocations {
  Lost = 'Desaparecidos'
}

export const FT100LocationName = 'FT-100';

export const CrossdockingLocationTypes = {
  Sorter:  'sorter',
  Storage: 'storage'
};

export const LocationEntityFilter = {
  Location: 'Ubicación',
  Aisle:    'Pasillo'
};

export const LocationInventoryFulfillmentStatus = {
  All:          'all',
  WithStock:    'with-stock',
  WithoutStock: 'without-stock'
};

export const LocationUnvalidStatuses = [
  LocationStatuses.Unavailable,
  LocationStatuses.Blocked
];

export const LocationPickingStorageType = {
  All:     'all',
  Picking: 'picking',
  Storage: 'storage'
};

export const BaldaSize = {
  SMALL: 64000000, // mm3
  BIG:   80000000
} as const;

@Entity()
export class Location extends BaseLocation {
  checkDigit: never;

  status: never;

  @Knows(() => Location, 'parentId')
  parent: Location;

  @Knows(() => Location, 'parentId')
  children: Location[];

  @Knows(() => Container, 'locationId')
  containers: Container[];

  @Knows(() => ZoneClassifier, 'zoneClassifierId')
  zoneClassifier: ZoneClassifier;

  @Needs(() => LocationType, 'locationTypeId')
  locationType: LocationType;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Knows(() => InventoryItem, 'locationId')
  inventoryItems: InventoryItem[];

  /* Dock location only */
  @Knows(() => Appointment, 'stagingId')
  appointment: Appointment[];

  /* Section only */
  @Knows(() => Warehouse, 'crossDockId')
  crossDockWarehouse: Warehouse;

  /* Section only */
  @Knows(() => Warehouse, 'anomalySectionId')
  anomalySectionWarehouse: Warehouse;

  /* Section only */
  @Knows(() => CategoryLocation, 'sectionId')
  rubric: CategoryLocation;

  /* Aisle only */
  @Knows(() => CategoryLocation, 'aisleId')
  families: CategoryLocation[];

  @Knows(() => Route, 'routeId')
  route: Route;

  @Knows(() => Incident, 'locationId')
  incidents: Incident[];

  @NeededBy(() => LabelingDesk, 'locationId')
  labelingDesk: LabelingDesk;

  @Knows(() => ProductRestockingLevel, 'locationId')
  productRestockingLevels: ProductRestockingLevel[];

  @NeededBy(() => ProductRestockingLevelAlert, 'locationId')
  productRestockingLevelAlerts: ProductRestockingLevelAlert[];

  @NeededBy(() => LocationProductAttribute, 'locationId')
  locationProductAttributes: LocationProductAttribute[];

  @NeededBy(() => LocationLocationAttribute, 'locationId')
  locationLocationAttributes: LocationLocationAttribute[];

  @NeededBy(() => SorterWMSLocation, 'locationId')
  sorterWMSLocation: SorterWMSLocation;

  @NeededBy(() => ClassificationContainer, 'sorterExitLocationId')
  classificationContainer: ClassificationContainer;

  @NeededBy(() => NoveltyMoveContainerToBufferContainer, 'originLocationId')
  originNoveltyMoveContainerToBufferContainers: NoveltyMoveContainerToBufferContainer[];

  @NeededBy(() => NoveltyMoveContainerToBufferContainer, 'targetSectorId')
  targetNoveltyMoveContainerToBufferContainers: NoveltyMoveContainerToBufferContainer[];

  @NeededBy(() => PackingWave, 'locationId')
  packingWaves: PackingWave[];

  @NeededBy(() => TaskRoleLocation, 'locationId')
  taskRoleLocation: TaskRoleLocation[];

  @NeededBy(() => ConsolidateContainersWave, 'originLocationId')
  originConsolidateContainersWave: ConsolidateContainersWave[];

  @NeededBy(() => ConsolidateContainersWave, 'targetLocationId')
  targetConsolidateContainersWave: ConsolidateContainersWave[];

  @Knows(() => NoveltyUnloadingContainerV2, 'targetSectorId')
  noveltyUnloadingContainersV2: NoveltyUnloadingContainerV2[];

  @ForeignKey(() => LocationZone, { name: 'location_zone_id' })
  locationZoneId: number;

  @Knows(() => LocationZone, 'locationZoneId')
  locationZone: LocationZone;

  @Knows(() => InventoryCountingItem, 'locationId')
  inventoryCountingItem: InventoryCountingItem;

  @NeededBy(() => LocationAssignment, 'locationId')
  locationAssignments: LocationAssignment[];

  @Knows(() => Receipt, 'unloadingLocationId')
  unloadingLocationReceipts: Receipt[];

  @Knows(() => Order, 'dispatchLocationId')
  dispatchLocationOrders: Order[];

  @Knows(() => PickingProcess, 'consolidationLocationId')
  pickingProcessesToConsolidate: PickingProcess[];

  @Knows(() => PickingWave, 'consolidationLocationId')
  pickingWavesToConsolidate: PickingWave[];

  @Knows(() => PickingZoneLocation, 'locationId')
  pickingZones: PickingZoneLocation[];

  @Knows(() => PreStorageLocationMapping, 'preStorageLocationId')
  storageLocationMappings: PreStorageLocationMapping[];

  @Knows(() => PreStorageLocationMapping, 'storageLocationId')
  preStorageLocationMappings: PreStorageLocationMapping[];

  @Knows(() => Package, 'locationId')
  packages: Package[];

  @NeededBy(() => StagingAuditingWave, 'stagingId')
  stagingAuditingWaves: StagingAuditingWave[];

  @NeededBy(
    () => ImpureSortableCrossdockClassificationContainer,
    'sorterExitLocationId'
  )
  impureSortableCrossdockClassificationContainers: ImpureSortableCrossdockClassificationContainer[];

  @NeededBy(() => DispatchingWave, 'stagingId')
  dispatchingWave: DispatchingWave;

  @Knows(() => StandardUnloadingContainer, 'targetSectorId')
  standardUnloadingContainer: StandardUnloadingContainer;

  @Knows(() => RestockingItem, 'originLocationId')
  originRestockingItems: RestockingItem[];

  @NeededBy(() => RestockingItem, 'targetLocationId')
  targetRestockingItems: RestockingItem[];

  @Knows(() => PickItem, 'pickingLocationId')
  pickItems: PickItem[];

  @Knows(() => SorterExitBranch, 'locationId')
  sorterExitBranch: SorterExitBranch;

  @Knows(() => Order, 'orderRampId')
  orders: Order[];

  @NeededBy(() => InventoryAdjustItem, 'locationId')
  inventoryAdjustItems: InventoryAdjustItem[];

  public static isValidCheckDigit(value: string): boolean {
    // RegEx que valida que el digito de control sea un numero de 4 digitos y nada mas

    const regexCheckDigit = /^\d{4}$/;

    return regexCheckDigit.test(value);
  }

  public static isPickTowerLocation(value: string): boolean {
    // RegEx que valida que la location sea del Pick Tower

    const regexCheckDigit = /^PR/;

    return regexCheckDigit.test(value);
  }

  public static isMidiRackLocation(value: string): boolean {
    // RegEx que valida que la location sea del Midi Rack

    const regexCheckDigit = /^MD/;

    return regexCheckDigit.test(value);
  }

  public static isMezzanineLocation(value: string): boolean {
    // RegEx que valida que la location sea del Mezzanine

    const regexCheckDigit = /^MZ/;

    return regexCheckDigit.test(value);
  }
}

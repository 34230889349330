import { MovePendingDispatchingItemsProcessStatuses } from '../MovePendingDispatchingItemsProcess';
import { MovePendingDispatchingItemsProcessStatus } from './MovePendingDispatchingItemsProcessStatus';

export class MovePendingDispatchingItemsProcessPendingStatus extends MovePendingDispatchingItemsProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MovePendingDispatchingItemsProcessStatuses.Pending;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

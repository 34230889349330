import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { FeedingToSorterContainer } from './FeedingToSorterContainer';
import { Receipt } from './Receipt';
import {
  FeedingToSorterProcessStatus,
  FeedingToSorterProcessStatusCancelledStatus,
  FeedingToSorterProcessStatusCompletedStatus,
  FeedingToSorterProcessStatusPausedStatus,
  FeedingToSorterProcessStatusPendingStatus,
  FeedingToSorterProcessStatusProcessingStatus
} from './feeding-to-sorter-process-status';

export const FeedingToSorterProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class FeedingToSorterProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [FeedingToSorterProcessStatuses.Pending]:
      FeedingToSorterProcessStatusPendingStatus,
    [FeedingToSorterProcessStatuses.Processing]:
      FeedingToSorterProcessStatusProcessingStatus,
    [FeedingToSorterProcessStatuses.Completed]:
      FeedingToSorterProcessStatusCompletedStatus,
    [FeedingToSorterProcessStatuses.Cancelled]:
      FeedingToSorterProcessStatusCancelledStatus,
    [FeedingToSorterProcessStatuses.Paused]:
      FeedingToSorterProcessStatusPausedStatus
  })
  status: FeedingToSorterProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => FeedingToSorterContainer, 'feedingToSorterProcessId')
  feedingToSorterContainers: FeedingToSorterContainer[];
}

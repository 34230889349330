import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { ReportAnomalyContext } from './machine';

type ReportAnomalyActions = MachineOptions<
  ReportAnomalyContext,
  any
>['actions'];

export const reportAnomalyActions: ReportAnomalyActions = {
  assignLocation: assign({
    location: (_ctx, event) => event.data
  }),
  assignProduct: assign({
    product: (_ctx, event) => event.data
  }),
  assignProductQuantity: assign({
    productQuantity: (_ctx, event) => event.data.quantity
  }),
  assignCreatedContainer: assign({
    anomalyContainer: (_ctx, event) => event.data.container
  }),
  assignExistingContainer: assign({
    anomalyContainer: (_ctx, event) => event.data
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  assingScanningLocationAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningLocation'
      };
    }
  }),
  assingScanningSKUAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningSKU'
      };
    }
  }),
  assingScanningAnomalyContainerCreatedAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningAnomalyContainerCreated'
      };
    }
  }),
  assingScanningDestinationLocationAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningDestinationLocation'
      };
    }
  }),
  assingConfirmingTransferAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ConfirmingTransfer'
      };
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  assignContainerType: assign({
    containerType: (_ctx, event) => event.data.containerType
  }),
  assignDestinationLocation: assign({
    destinationLocation: (_ctx, event) => event.data
  }),
  assignContainerTypes: assign({
    availableContainerTypes: (_ctx, event) => event.data.containerTypes
  }),
  assignAnomalyItem: assign({
    anomalyItem: (_ctx, event) => event.data
  }),
  assignTargetLocation: assign({
    targetLocation: (_ctx, event) => event.data.targetLocation
  }),
  addPendingContainerToMoveId: assign({
    pendingContainersToMoveLpns: (ctx, _event) => {
      const pendingContainersLpns = ctx.pendingContainersToMoveLpns;
      const anomalyContainer = ctx.anomalyContainer;
      if (
        pendingContainersLpns &&
        !pendingContainersLpns?.includes(anomalyContainer!.lpn)
      ) {
        pendingContainersLpns.push(anomalyContainer!.lpn);
        return pendingContainersLpns;
      }
      return pendingContainersLpns;
    }
  }),
  assignIsMovingContainer: assign({
    isMovingContainer: (_ctx, _event) => {
      return true;
    }
  }),
  unassignIsMovingContainer: assign({
    isMovingContainer: (_ctx, _event) => {
      return false;
    }
  }),
  assignContainerError: assign({
    error: (ctx, _event) =>
      `El contenedor ${
        ctx.anomalyContainer!.lpn
      } no pertenece a la lista de contenedores `
  }),
  removeActualContainerFromPendingContainers: assign({
    pendingContainersToMoveLpns: (ctx, _event) => {
      const pendingContainersLpns = ctx.pendingContainersToMoveLpns;
      const anomalyContainer = ctx.anomalyContainer;
      if (pendingContainersLpns.includes(anomalyContainer!.lpn)) {
        return pendingContainersLpns.filter(
          lpn => lpn !== anomalyContainer!.lpn
        );
      }
      return pendingContainersLpns;
    }
  })
};

import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusPartiallyDispatchedStatus extends OrderItemStatus {
  public displayName = 'Parcialmente despachado';

  public internalName = OrderItemStatuses.PartiallyDispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Receipt } from './Receipt';
import { Reservation } from './Reservation';

@Entity()
export class ReservationReceipt {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

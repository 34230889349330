import Ink from '@ink';
import { ReceiptTypes, SectionNames, Task, TaskTypes } from '@wms/domain';
import { useActor } from '@xstate/react';
import React, { FC, useCallback, useEffect } from 'react';
import { useFindTasks } from '../../../../api/Task';
import { getReservationWarehouseFromTask } from '../../../../utils/reservation-warehouse-from-task';
import { withActorRef } from '../../../shared/Machine';
import { Paginator } from '../../../shared/Paginator';
import { SelectList } from '../../../shared/SelectList';
import { TaskProcessType } from '../../taskIndex';
import { TaskSearcherMachine } from './machine';

function getEntityId(task: Task): number {
  const payload = task as any;
  return (
    payload?.payload?.pickingWaveId ||
    payload?.payload?.packingWaveId ||
    payload?.payload?.consolidateContainersProcessId ||
    payload?.payload?.context?.pickingWaveId ||
    payload?.payload?.container?.lpn ||
    payload?.receiptId ||
    payload?.payload?.context?.stockTransfer?.id ||
    payload?.payload?.stockTransfer?.id ||
    payload?.pickingWaveId ||
    payload?.packingWaveId ||
    payload?.consolidateContainersProcessId ||
    payload?.consolidateContainersWaveId ||
    payload?.consolidatingToStagingContainerId ||
    payload?.payload?.consolidatingToStagingContainerId ||
    payload?.payload?.stagingAuditingWaveId ||
    payload?.stagingAuditingWaveId ||
    payload?.payload?.dispachingWaveId ||
    payload?.dispachingWaveId ||
    payload?.orderId ||
    payload?.payload?.id ||
    payload?.id ||
    payload?.payload?.standardUnloadingProcessId ||
    payload?.standardUnloadingProcessId ||
    payload?.slottingItemId ||
    payload?.payload?.slottingItemId
  );
}

const getMezzanineLevel = containerLocationName => {
  switch (containerLocationName) {
    case SectionNames.MezzanineLevel1:
      return 'Mezzanine nivel 1';
    case SectionNames.MezzanineLevel2:
      return 'Mezzanine nivel 2';
    case SectionNames.MezzanineLevel3:
      return 'Mezzanine nivel 3';
    case SectionNames.MezzanineLevel0:
      return 'Mezzanine nivel 0';
    default:
      return 'N/A';
  }
};

function getOperationName(task: Task): string | null {
  const originalPayload = task?.payload as any;
  const payload = originalPayload?.context?.task?.payload || originalPayload;
  if (task.type === TaskTypes.MaxiPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - Selectivos Maxi`;
  } else if (task.type === TaskTypes.MidiPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - PickTower Nivel ${payload?.level ?? payload.context?.level ?? 'X'}`;
  } else if (task.type === TaskTypes.MiniPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    } - HD - Mezzanine Nivel ${
      payload?.level ?? payload.context?.level ?? 'X'
    }`;
  } else if (task.type == TaskTypes.MaxiConsolidateContainersTask) {
    return `Traslado #${
      payload.consolidateContainersWaveId ||
      payload.context.consolidateContainersWaveId
    } - Selectivos Maxi`;
  } else if (task.type === TaskTypes.BigPickingWave) {
    return `Ola N°${payload.pickingWaveId || payload.context.pickingWaveId} - ${
      payload.deliveryType || payload.context.deliveryType
    } - Autoestibas Big`;
  } else if (task.type === TaskTypes.MiniMopPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    }  - AB TIE - Mezzanine Nivel ${
      payload?.level ?? payload.context?.level ?? 'X'
    }`;
  } else if (task.type === TaskTypes.MiniPtwPickingWave) {
    return `Ola N°${
      payload.pickingWaveId || payload.context.pickingWaveId
    }  - HD PTW - Mezzanine Nivel ${
      payload?.level ?? payload.context?.level ?? 'X'
    }`;
  } else if (task.type === TaskTypes.ConsolidatingContainersToStagingType) {
    return `${payload.locationName || payload.context.locationName} | LPN ${
      payload.lpn || payload.context.lpn || ''
    } | ${payload.stagingName || payload.context.stagingName}`;
  } else if (task.type === TaskTypes.StagingAuditingTask) {
    return `AUDITORIA N°${
      payload.stagingAuditingWaveId || payload.context.stagingAuditingWaveId
    } - ${payload.stageName || payload.context.stageName}${
      getReservationWarehouseFromTask(task)
        ? ` - SUC-${getReservationWarehouseFromTask(task)?.wmsWhCode}`
        : ''
    }`;
  } else if (
    task.type === TaskTypes.DispatchingHDTask ||
    task.type === TaskTypes.DispatchingSSTask
  ) {
    const orderType = task.type === TaskTypes.DispatchingHDTask ? 'HD' : 'SS';
    const staging =
      payload.stagingName || payload.context?.stagingName || 'CL-N/A';
    const destination =
      payload?.destination || payload?.context?.destination || '';
    return `${orderType} #${getEntityId(task)} | ${staging} ${destination}`;
  } else if (task.type === TaskTypes.StandardUnloading) {
    const receiptType = payload?.receiptTypeName;
    const documentNumber = payload?.documentNumber;
    let displayType = 'OC/Doc N°: ';
    if (receiptType === ReceiptTypes.SupplierWms) {
      displayType = 'Proveedor: ';
    }
    if (receiptType === ReceiptTypes.TransferWmsFravega) {
      displayType = 'Transferencia: ';
    }
    return `${displayType}${documentNumber}`;
  } else if (task.type === TaskTypes.Slotting) {
    return `OE - ${task.receiptId}: ${
      payload.containerLpn || payload.context.containerLpn
    }`;
  } else if (task.type === TaskTypes.SlottingRollContainer) {
    return `Guardado en Estantería - ${
      payload.containerLpn || payload.context.containerLpn
    } en ubicación ${getMezzanineLevel(
      payload.containerLocationName || payload.context.containerLocationName
    )}`;
  } else if (task.type === TaskTypes.MoveToBuffer) {
    return `Roll Container ${
      payload.containerLpn || payload.context.containerLpn
    } - Canal ${
      payload.containerLocationName || payload.context.containerLocationName
    } - OE ${task.receiptId}`;
  } else if (task.type === TaskTypes.ResolveMismatchingStagingAuditingTask) {
    return `Arreglo de descuadre Orden #${
      payload.orderId || payload.context.orderId
    }`;
  } else if (task.type === TaskTypes.missingItemsMiniMop) {
    return `Pickear Faltantes Mini-Mop # ${
      payload.mopAuditingPickingWaveId ||
      payload.context.mopAuditingPickingWaveId
    }`;
  } else if (task.type === TaskTypes.PendingDispatchingPickingTask) {
    return `Ola de Picking FT-100 #${
      payload.pickingWaveId || payload.context.pickingWaveId
    }`;
  } else if (task.type === TaskTypes.NoveltyUnloading) {
    return `Novedad: ${payload.documentNumber}`;
  } else if (task.type === TaskTypes.PalletRestockingTask) {
    return `${payload.product?.sku ?? ''} #${
      payload.originLocation?.name ?? ''
    } #${payload.location?.name ?? ''} `;
  } else if (task.type === TaskTypes.InventoryAdjustWave) {
    return payload.taskName || payload.context.taskName;
  } else if (task.type === TaskTypes.NoveltyMoveContainerToBuffer) {
    return `Traslado de contenedor: ${
      payload.noveltyContainerMoveToBufferContainerLpn ||
      payload.context.noveltyContainerMoveToBufferContainerLpn
    }`;
  }
  return null;
}

export const TaskSearcher: FC = withActorRef(
  TaskSearcherMachine,
  TaskSearcherMachine.id
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);
  const taskProcess = TaskProcessType[current.context.menuItemKey!];

  const { page, request, hasPrev, hasNext, requestPrev, requestNext } =
    useFindTasks(taskProcess.validBucketTypes, 5);

  useEffect(() => {
    request();
  }, []);

  const onSelect = useCallback(
    (task: Task) => send({ type: 'taskSelected', data: { task } }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione Tarea de {taskProcess.label}</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingTop={2} padding={2}>
        <Ink.Text inline bold>
          Seleccione <Ink.Chalk greenBright>{taskProcess.entity}</Ink.Chalk>:
        </Ink.Text>

        {page ? (
          page.rows.length > 0 ? (
            <>
              <SelectList<Task>
                items={page.rows}
                trackBy={item => item.id}
                getDisplayText={item =>
                  getOperationName(item)
                    ? (getOperationName(item) as string)
                    : `${taskProcess?.entity} #${getEntityId(item)}`
                }
                onSelect={onSelect}
              />

              <Paginator
                hasPrev={hasPrev}
                hasNext={hasNext}
                onPrev={requestPrev}
                onNext={requestNext}
              />
            </>
          ) : (
            <Ink.Text color='redBright'>
              No hay tareas disponibles para {taskProcess.entity}
            </Ink.Text>
          )
        ) : (
          <Ink.Text>Cargando...</Ink.Text>
        )}
      </Ink.Box>
    </Ink.Box>
  );
});

TaskSearcher.displayName = 'TaskSearcher';

import { UnloadingProcessStatuses } from '../UnloadingProcess';
import { UnloadingProcessStatus } from './UnloadingProcessStatus';

export class UnloadingProcessStatusPausedStatus extends UnloadingProcessStatus {
  public displayName = 'Pausado';

  public internalName = UnloadingProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

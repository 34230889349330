import { NoveltyUnloadingPackageStatuses } from '../NoveltyUnloadingPackage';
import { NoveltyUnloadingPackageStatus } from './NoveltyUnloadingPackageStatus';

export class NoveltyUnloadingPackageStatusProcessingStatus extends NoveltyUnloadingPackageStatus {
  public displayName = 'Procesando';

  public internalName = NoveltyUnloadingPackageStatuses.Processing;

  public statusColor = '#Ffffff';
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { SlottingRollContainerContext } from './machine';

type SlottingRollContainerServices = MachineOptions<
  SlottingRollContainerContext,
  any
>['services'];

export const slottingRollContainerServices: SlottingRollContainerServices = {
  fetchSkuCount: async (ctx, _event) => {
    const { data } = await api.get('slotting-container/items-count', {
      params: {
        slottingContainerId: ctx.slottingContainerId!
      }
    });

    return {
      itemsTotal:   data.itemsTotal,
      itemsSlotted: data.itemsSlotted
    };
  },
  fetchItem: async (ctx, _event) => {
    const { data } = await api.get('slotting-container/item', {
      params: {
        slottingContainerId: ctx.slottingContainerId!
      }
    });

    return {
      product:      data.product,
      slottingItem: data.slottingItem
    };
  },
  fetchSkuUnloadedQuantity: async (ctx, _event) => {
    const { data } = await api.get('slotting-item/unloaded-quantity', {
      params: {
        inventoryItemId: ctx.slottingItem!.inventoryItemId
      }
    });

    return {
      unloadedQuantity: data
    };
  },
  fetchDestinySkuLocation: async (ctx, _event) => {
    const { data } = await api.get(
      `product/${ctx.productAssigned!.id}/section-name`,
      {
        params: {
          sectionName: ctx.containerLocationName
        }
      }
    );

    return {
      destinyLocationAndQuantity: data
    };
  },
  checkIfLocationHasdifferentItems: async (ctx, _event) => {
    const { data } = await api.get('location/has-different-sku', {
      params: {
        locationId: ctx.locationScanned!.id,
        sku:        ctx.productAssigned!.sku
      }
    });

    return data;
  },
  checkIfLocationHasCorrectParent: async (ctx, _event) => {
    const { data } = await api.get(
      `location/${ctx.locationScanned!.id}/get-mezzanine-level`,
      {}
    );
    return data;
  },
  getAvailableQuantityInLocation: async (ctx, _event) => {
    const { data } = await api.get(
      `location/${ctx.locationScanned!.id}/available-quantity`,
      {
        params: {
          productId: ctx.productAssigned!.id
        }
      }
    );

    return data;
  },
  checkLocationAvailable: async (ctx, _event) => {
    const { data } = await api.get(
      `location/${ctx.locationScanned!.id}/check-available`
    );

    return data;
  }
};

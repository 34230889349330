import { ReceiptBatchStatuses } from '../ReceiptBatch';
import { ReceiptBatchStatus } from './ReceiptBatchStatus';

export class ReceiptBatchPendingStatus extends ReceiptBatchStatus {
  public displayName = 'Pendiente';

  public internalName = ReceiptBatchStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

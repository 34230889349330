import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  OwnedBy,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditOrderItem } from './AuditOrderItem';
import { DispatchLabel } from './DispatchLabel';
import { FulfillmentOrder } from './FulfillmentOrder';
import { Incident } from './Incident';
import { Order } from './Order';
import { OrderItemType } from './OrderItemType';
import { PackagingLevel } from './PackagingLevel';
import { PackingItem } from './PackingItem';
import { PickItem } from './PickItem';
import { Product } from './Product';
import { ReservationItem } from './ReservationItem';
import { SellerProduct } from './SellerProduct';
import { TransferOrderItem } from './TransferOrderItem';
import {
  OrderItemStatus,
  OrderItemStatusAuditedStatus,
  OrderItemStatusAuditingOmittedStatus,
  OrderItemStatusAuditingStatus,
  OrderItemStatusCancelledStatus,
  OrderItemStatusConsolidatedStatus,
  OrderItemStatusConsolidatingStatus,
  OrderItemStatusDamagedStatus,
  OrderItemStatusDispatchedStatus,
  OrderItemStatusDispatchingStatus,
  OrderItemStatusDocMissingStatus,
  OrderItemStatusMissingStatus,
  OrderItemStatusNotDispatchedStatus,
  OrderItemStatusOnHoldStatus,
  OrderItemStatusPartiallyDispatchedStatus,
  OrderItemStatusPendingToHoldStatus,
  OrderItemStatusPreparedStatus,
  OrderItemStatusPreparingStatus,
  OrderItemStatusReturnedStatus,
  OrderItemStatusStockedStatus
} from './order-item-status';

export const OrderItemStatuses = {
  Audited:             'audited',
  AuditingOmitted:     'auditing-omitted',
  Stocked:             'stocked',
  Preparing:           'preparing',
  Prepared:            'prepared',
  Missing:             'missing',
  Damaged:             'damaged',
  DocMissing:          'doc-missing',
  PendingToHold:       'pending-to-hold',
  OnHold:              'on-hold',
  Returned:            'returned',
  Dispatched:          'dispatched',
  PartiallyDispatched: 'partially-dispatched',
  NotDispatched:       'not-dispatched',
  Dispatching:         'dispatching',
  Consolidating:       'consolidating',
  Consolidated:        'consolidated',
  Auditing:            'auditing',
  Cancelled:           'cancelled'
};

export const OrderItemPendingStatuses = [OrderItemStatuses.Stocked] as const;

export const OrderItemDispatchedStatuses = [
  OrderItemStatuses.Dispatched,
  OrderItemStatuses.PartiallyDispatched
] as const;

@Entity()
export class OrderItem {
  @PrimaryKey()
  id: number;

  @Status({
    [OrderItemStatuses.Stocked]:             OrderItemStatusStockedStatus,
    [OrderItemStatuses.Preparing]:           OrderItemStatusPreparingStatus,
    [OrderItemStatuses.Prepared]:            OrderItemStatusPreparedStatus,
    [OrderItemStatuses.Missing]:             OrderItemStatusMissingStatus,
    [OrderItemStatuses.Damaged]:             OrderItemStatusDamagedStatus,
    [OrderItemStatuses.DocMissing]:          OrderItemStatusDocMissingStatus,
    [OrderItemStatuses.PendingToHold]:       OrderItemStatusPendingToHoldStatus,
    [OrderItemStatuses.OnHold]:              OrderItemStatusOnHoldStatus,
    [OrderItemStatuses.Returned]:            OrderItemStatusReturnedStatus,
    [OrderItemStatuses.Dispatched]:          OrderItemStatusDispatchedStatus,
    [OrderItemStatuses.Audited]:             OrderItemStatusAuditedStatus,
    [OrderItemStatuses.AuditingOmitted]:     OrderItemStatusAuditingOmittedStatus,
    [OrderItemStatuses.PartiallyDispatched]:
      OrderItemStatusPartiallyDispatchedStatus,
    [OrderItemStatuses.NotDispatched]: OrderItemStatusNotDispatchedStatus,
    [OrderItemStatuses.Dispatching]:   OrderItemStatusDispatchingStatus,
    [OrderItemStatuses.Consolidating]: OrderItemStatusConsolidatingStatus,
    [OrderItemStatuses.Consolidated]:  OrderItemStatusConsolidatedStatus,
    [OrderItemStatuses.Auditing]:      OrderItemStatusAuditingStatus,
    [OrderItemStatuses.Cancelled]:     OrderItemStatusCancelledStatus
  })
  status: OrderItemStatus;

  @Field({ name: 'should_return' })
  shouldReturn: boolean;

  @Field({ name: 'delivery_order' })
  deliveryOrder: number;

  @Field()
  volume: number;

  @Field()
  weight: number;

  @Field()
  quantity: number;

  // SOLO SE USA PARA PRODUCT ITEM
  public get getExpectedQuantity() {
    return this.expectedQuantity;
  }

  public set setExpectedQuantity(quantity: number) {
    this.expectedQuantity = quantity;
  }

  @Field({ name: 'expected_quantity' })
  expectedQuantity: number;

  @Field()
  sku: string;

  @Field({ name: 'wms_order_item_id' })
  wmsOrderItemId: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'dispatched_quantity' })
  dispatchedQuantity: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => FulfillmentOrder, { name: 'group_id' })
  groupId: number;

  @ForeignKey(() => OrderItemType, { name: 'order_item_type_id' })
  orderItemTypeId: number;

  @Knows(() => OrderItemType, 'orderItemTypeId')
  orderItemType: OrderItemType;

  @Knows(() => FulfillmentOrder, 'groupId')
  group: FulfillmentOrder;

  @OwnedBy(() => Order, 'orderId')
  order: Order;

  @NeededBy(() => PickItem, 'orderItemId')
  pickItems: PickItem[];

  @NeededBy(() => AuditOrderItem, 'orderItemId')
  auditItems: AuditOrderItem[];

  @NeededBy(() => PackingItem, 'orderItemId')
  packingItems: PackingItem[];

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => SellerProduct, { name: 'seller_product_id' })
  sellerProductId: number;

  @ForeignKey(() => ReservationItem, { name: 'reservation_item_id' })
  reservationItemId: number;

  @Needs(() => ReservationItem, 'reservationItemId')
  reservationItem: ReservationItem;

  @Knows(() => Product, 'productId')
  product: Product;

  @Knows(() => SellerProduct, 'sellerProductId')
  sellerProduct: SellerProduct;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  // @TODO: Change to Needs
  @Knows(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @ForeignKey(() => TransferOrderItem, { name: 'transfer_order_item_id' })
  transferOrderItemId: number;

  @Knows(() => TransferOrderItem, 'transferOrderItemId')
  transferOrderItem: TransferOrderItem;

  @Knows(() => DispatchLabel, 'orderItemId')
  dispatchLabels: DispatchLabel[];

  @Knows(() => Incident, 'orderItemId')
  incidents: Incident[];

  public static readonly REGULAR_STATUSES = [
    OrderItemStatuses.Stocked,
    OrderItemStatuses.Preparing,
    OrderItemStatuses.Prepared,
    OrderItemStatuses.Consolidating,
    OrderItemStatuses.Consolidated,
    OrderItemStatuses.Audited,
    OrderItemStatuses.Dispatching,
    OrderItemStatuses.Dispatched
  ];

  public static readonly ANOMALY_STATUSES = [
    OrderItemStatuses.Missing,
    OrderItemStatuses.Damaged,
    OrderItemStatuses.DocMissing
  ];

  public static readonly RETURNING_STATUSES = [
    OrderItemStatuses.PendingToHold,
    OrderItemStatuses.OnHold
  ];

  public isRegular(): boolean {
    return OrderItem.REGULAR_STATUSES.concat([
      OrderItemStatuses.NotDispatched,
      OrderItemStatuses.PartiallyDispatched
    ]).includes(this.status.internalName);
  }

  public isAnomaly(): boolean {
    return OrderItem.ANOMALY_STATUSES.includes(this.status.internalName);
  }
}

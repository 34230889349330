import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { DispatchingItem } from './DispatchingItem';
import { DispatchingProcess } from './DispatchingProcess';
import { Location } from './Location';
import { DispatchingWaveCancelledStatus } from './dispatching-wave-status/DispatchingWaveCancelledStatus';
import { DispatchingWaveCompletedStatus } from './dispatching-wave-status/DispatchingWaveCompletedStatus';
import { DispatchingWavePausedStatus } from './dispatching-wave-status/DispatchingWavePausedStatus';
import { DispatchingWavePendingStatus } from './dispatching-wave-status/DispatchingWavePendingStatus';
import { DispatchingWaveProcessingStatus } from './dispatching-wave-status/DispatchingWaveProcessingStatus';
import { DispatchingWaveStatus } from './dispatching-wave-status/DispatchingWaveStatus';

export const DispatchingWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Paused:     'paused',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

export const DispatchingWaveType = {
  HD: 'HD',
  SS: 'SS'
};

@Entity()
export class DispatchingWave {
  @PrimaryKey()
  id: number;

  @Status({
    [DispatchingWaveStatuses.Pending]:    DispatchingWavePendingStatus,
    [DispatchingWaveStatuses.Processing]: DispatchingWaveProcessingStatus,
    [DispatchingWaveStatuses.Completed]:  DispatchingWaveCompletedStatus,
    [DispatchingWaveStatuses.Paused]:     DispatchingWavePausedStatus,
    [DispatchingWaveStatuses.Cancelled]:  DispatchingWaveCancelledStatus
  })
  status: DispatchingWaveStatus;

  @ForeignKey(() => Location, { name: 'staging_id' })
  stagingId: number;

  @Field({ name: 'type' })
  type: string;

  @ForeignKey(() => DispatchingProcess, { name: 'dispatching_process_id' })
  dispatchingProcessId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Location, 'stagingId')
  staging: Location;

  @Needs(() => DispatchingProcess, 'dispatchingProcessId')
  dispatchingProcess: DispatchingProcess;

  @NeededBy(() => DispatchingItem, 'dispatchingWaveId')
  dispatchingItems: DispatchingItem[];
}

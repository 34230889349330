import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusDeliveredStatus extends ReservationItemStatus {
  public displayName = 'Entregada';

  public internalName = ReservationItemStatuses.Delivered;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}

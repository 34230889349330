import { MachineOptions, assign } from 'xstate';
import { AlertData } from '../../../../types/alerData';
import { handleSuccessPopUp } from '../../../../utils/pop-up-layer-4';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MiniMopPickingWaveContext } from './machine';

type MiniMopPickingWaveActionsType = MachineOptions<
  MiniMopPickingWaveContext,
  any
>['actions'];

export const miniMopPickingWaveActions: MiniMopPickingWaveActionsType = {
  assignPickingCartTypes: assign({
    pickingCartTypes: (_ctx, event) => event.data.pickingCartTypes
  }),
  assignSelectedPickingCartType: assign({
    pickingCartType: (_ctx, event) => event.data.pickingCartType
  }),
  assignPickingCart: assign({
    pickingCart: (_ctx, event) => event.data
  }),
  assignNextBranchOfficeToPrepare: assign({
    branchOfficeToPrepare: (_ctx, event) => event.data.branchOffice,
    orderIdToPrepare:      (_ctx, event) => event.data.orderId
  }),
  assignBranchOfficesQuantity: assign({
    branchOfficeTotal: (_ctx, event) => event.data.branchOfficeQuantity
  }),
  assignContainerLpn: assign({
    containerLpn: (_ctx, event) => event.data.lpn
  }),
  assignNextPickItem: assign({
    currentPickItem: (_ctx, event) => event.data.pickItem
  }),
  assignMissingItems: assign({
    missingItems: (_ctx, event) => event.data.missingItems
  }),
  assignPickItemLocation: assign({
    pickItemLocation: (_ctx, event) => event.data.location
  }),
  assignPickingCartPosition: assign({
    pickingCartPosition: (_ctx, event) => event.data.quantity
  }),
  assignPickedQuantity: assign({
    pickedQuantity: (_ctx, event) => event.data.quantity
  }),
  assignCurrentMopPickingContainerToClose: assign({
    currentMopPickingContainerToClose: (_ctx, event) =>
      event.data.mopPickingContainer
  }),
  assignBranchOfficeCreated: assign({
    branchOfficeCreated: (ctx, _event) => ctx.branchOfficeCreated + 1
  }),
  assignPickItemDestinyLocation: assign({
    pickItemDestinyContainerLocation: (_ctx, event) =>
      event.data.pickItemDestinyLocation
  }),
  assignPositionError: assign({
    positionError: (__, ___) => 'La posicion ya se encuentra ocupada'
  }),
  clearPositionError: assign({
    positionError: (__, ___) => null
  }),
  assignLpnError: assign({
    lpnError: (__, ___) => 'El contenedor escaneado ya existe'
  }),
  clearLpnError: assign({
    lpnError: (__, ___) => null
  }),
  assignItemLocationScanError: assign({
    error: (__, ___) => 'La ubicación escaneada no es correcta'
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      );
    }
  }),
  clearError: assign({
    error: (__, ___) => null
  }),
  setFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => true
  }),
  disableFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => false
  }),
  assignUserId: assign({
    userId: (_ctx, event) => event.data?.data?.response?.data?.userId
  }),
  assignCountQuantity: assign({
    countQuantity: (_ctx, event) => event.data.quantity
  }),
  assignUpdatedContextError: assign({
    error: (_ctx, _event) => {
      return 'La ubicación y los pickItems asociados fueron actualizados,por favor dirijase a la nueva ubicacion';
    }
  }),
  showSuccessInducedContainer: ctx => {
    handleSuccessPopUp({
      successMessage: `Ya puede inducir la cubeta ${
        ctx.currentMopPickingContainerToClose!.container.lpn
      } al sorter. Recuerde cerrarla.`
    } as AlertData);
  }
};

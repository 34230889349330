import { MachineOptions } from 'xstate';
import { ResolveMismatchedAuditingWaveContext } from './machine';

type ResolveMismatchedAuditingWaveGuardsType = MachineOptions<
  ResolveMismatchedAuditingWaveContext,
  any
>['guards'];

export const ResolveMismatchedAuditingWaveGuards: ResolveMismatchedAuditingWaveGuardsType =
  {};

import { Entity, Field, Knows, NeededBy, PrimaryKey } from '@skyframe/core';
import { Courier } from './Courier';
import { Order } from './Order';
import { LabelCouriers, LabelCouriersType } from './Printer';

export enum OrderTypes {
  WMSHD = 'wms-hd',
  WMSHDANDREANI = 'wms-hd-andreani',
  WMSHDCDS = 'wms-hd-cds',
  WMSHDWP = 'wms-hd-wp',
  WMSHDDM = 'wms-hd-dm',
  WMSHDLBO = 'wms-hd-lbo',
  /**
   * @deprecated Use WMSHDME (wms-hd-me) instead
   */
  WMSHDMELI = 'wms-hd-meli',
  WMSHDME = 'wms-hd-me',

  WMSHDFVG = 'wms-hd-fvg',
  WMSHDSellers = 'wms-hd-sellers',
  WMSSPUSellers = 'wms-spu-sellers',
  WMSSS = 'wms-ss',
  WMSSPU = 'wms-ss-spu',

  WMSTransfer = 'wms-transfer',

  // OLD TYPES
  Sellers = 'hd-sellers',
  WMS = 'wms',
  Transfer = 'transferencia',
  StoreSupplySellers = 'ss-sellers',
  WMSRetailHD = 'wms-retail-hd',
  WMSRetailSS = 'wms-retail-ss'
}

export const HDOOLLTypes = [
  OrderTypes.WMSHD,
  OrderTypes.WMSHDANDREANI,
  OrderTypes.WMSHDWP,
  OrderTypes.WMSHDFVG,
  OrderTypes.WMSHDCDS,
  OrderTypes.WMSHDMELI,
  OrderTypes.WMSHDME,
  OrderTypes.WMSHDDM,
  OrderTypes.WMSHDDM,
  OrderTypes.WMSHDLBO
];

export const HDtypes = [OrderTypes.WMSHD, ...HDOOLLTypes];

export const SStypes = [OrderTypes.WMSSS, OrderTypes.WMSSPU];

@Entity()
export class OrderType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Order, 'orderTypeId')
  orders: Order[];

  @Knows(() => Courier, 'orderTypeId')
  couriers: Courier[];

  public getCourier(): LabelCouriersType {
    return OrderType.getCourier(this.name);
  }

  public static getCourier(name: string): LabelCouriersType {
    switch (name) {
      case OrderTypes.WMSHDANDREANI:
        return LabelCouriers.ANDREANI;
      case OrderTypes.WMSHDCDS:
        return LabelCouriers.CRUZDELSUR;
      case OrderTypes.WMSHDWP:
        return LabelCouriers.WEBPACK;
      case OrderTypes.WMSHDDM:
        return LabelCouriers.DM;
      case OrderTypes.WMSHDFVG:
        return LabelCouriers.FRAVEGA;
      case OrderTypes.WMSHDLBO:
        return LabelCouriers.LBO;
      case OrderTypes.WMSHDMELI:
      case OrderTypes.WMSHDME:
        return LabelCouriers.ME;
      default:
        return LabelCouriers.FRAVEGA; // Default label
    }
  }

  public static getCourierDisplayName(
    name: string,
    defaultLabel?: string
  ): string {
    switch (name) {
      case OrderTypes.WMSHDANDREANI:
        return 'Andreani';
      case OrderTypes.WMSHDCDS:
        return 'Cruz del Sur';
      case OrderTypes.WMSHDWP:
        return 'Webpack';
      case OrderTypes.WMSHDDM:
        return 'DM';
      case OrderTypes.WMSHDFVG:
        return 'Fravega';
      case OrderTypes.WMSHDLBO:
        return 'LBO';
      case OrderTypes.WMSHDMELI:
      case OrderTypes.WMSHDME:
        return 'Mercado Envíos';
      default:
        return defaultLabel || '--'; // Default label
    }
  }
}

import { FeedingToSorterProcessStatuses } from '../FeedingToSorterProcess';
import { FeedingToSorterProcessStatus } from './FeedingToSorterProcessStatus';

export class FeedingToSorterProcessStatusPendingStatus extends FeedingToSorterProcessStatus {
  public displayName = 'Pendiente';

  public internalName = FeedingToSorterProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

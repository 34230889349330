import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusConsolidatedStatus extends OrderStatus {
  public displayName = 'Consolidado';

  public internalName = OrderStatuses.Consolidated;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

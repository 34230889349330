import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { NoveltyMoveContainerToBufferContext } from './machine';

type NoveltyMoveContainerToBufferServices = MachineOptions<
  NoveltyMoveContainerToBufferContext,
  any
>['services'];

export const noveltyMoveContainerToBufferServices: NoveltyMoveContainerToBufferServices =
  {
    fetchMoveContainerToBufferContainer: async (ctx, _event) => {
      const { data } = await api.get(
        `/novelty-move-container-to-buffer-container/${ctx.noveltyContainerMoveToBufferContainerLpn}`
      );
      return {
        noveltyMoveContainerToBufferContainer: data
      };
    },
    fetchTargetLocation: async (ctx, _event) => {
      const { data } = await api.get(
        `/location/${ctx.noveltyMoveContainerToBufferContainer!.targetSectorId}`
      );
      return {
        targetLocation: data
      };
    },
    markNoveltyContainerToBufferContainerAsMoved: async (ctx, _event) => {
      await api.post(
        `/novelty-move-container-to-buffer-container/${
          ctx.noveltyMoveContainerToBufferContainer!.id
        }/moved`
      );
    },
    fetchProcessesToSend: async (ctx, _event) => {
      const { data } = await api.get(
        `/novelty-move-container-to-buffer-container/${
          ctx.noveltyMoveContainerToBufferContainer!.id
        }/processes-to-send`
      );
      return {
        processesIds: data
      };
    }
  };

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../../modules/shared/utils/getErrorView';
import { DispatchSSContext } from './machine';

type DispatchSSActions = MachineOptions<DispatchSSContext, any>['actions'];

export const dispatchSSActions: DispatchSSActions = {
  assignDispatchingWaveDetails: assign({
    dispatchingWave: (_ctx, event) => event.data.dispatchingWave
  }),
  assignSuggestedDispatchingItem: assign({
    suggestedDispatchingItem: (_ctx, event) => event.data.dispatchingItem
  }),
  assignItemCounts: assign({
    totalItems:  (_ctx, evt) => evt.data.counts.totalItems,
    pickedItems: (_ctx, evt) => evt.data.counts.pickedItems
  }),
  assignScannedLabel: assign({
    scannedLabel: (_ctx, evt) => evt.data.label
  }),
  assignTouchedEndOfLoading: assign({
    touchedEndOfLoading: (_ctx, _evt) => true
  }),
  markAsNonReturningPoint: assign({
    nonReturnPoint: (_ctx, _evt) => true
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearError: assign({
    error: (_, __) => null
  }),
  assignIsPartiallyDispatch: assign({
    isPartiallyDispatch: (_ctx, evt) => evt.data.isPartiallyDispatch
  })
};

import { InventoryAdjustItemStatuses } from '../InventoryAdjustItem';
import { InventoryAdjustItemStatus } from './InventoryAdjustItemStatus';

export class InventoryAdjustItemAdjustedStatus extends InventoryAdjustItemStatus {
  public displayName = 'Ajustado';

  public internalName = InventoryAdjustItemStatuses.Adjusted;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}

import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getFeedingToSorterContainerByLpn(
  defaultErrorMessage: string,
  feedingToSorterContainerId: number
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn =>
    api.get<Container>(
      `feeding-to-sorter-container/${feedingToSorterContainerId}/container/${lpn}`
    )
  );
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MaxiPickingWaveMachine } from './machine';

export const MaxiPickingWaveTask = withActorRef(MaxiPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    const onClickContainerType = containerType => {
      send({ type: 'select', data: { containerType } });
    };

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Picking Ola N°{current.context.pickingWaveId} - Selectivos Maxi
        </Ink.Header>
        {/* Header */}
        <Ink.Box>
          {current.matches('ScanningPalletTruck') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Escanear transpaleta</Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('SelectingContainerType') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                Tome contenedor N°{' '}
                {current.context.createdContainersQuantity + 1}
              </Ink.Text>
              <Ink.Text>Se surgiere contenedor tipo</Ink.Text>
              <Ink.Text bold>
                <Ink.Chalk color='greenBright'>
                  {current.context.containerSuggestedType?.name || 'N/A'}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningContainer') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Confirme nuevo contenedor</Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ConfirmingPickItemLocation') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text>
                {current.context.currentPickItem?.sku} -{' '}
                {current.context.currentPickItem?.product.name}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Trasládese hasta ubicación:</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.inventoryItem.location.name}
              </Ink.Text>

              <Ink.Button
                label={
                  current.context.palletTruckType === 'simpĺe'
                    ? 'Forzar cierre del contenedor ' +
                      current.context.containerlpn
                    : 'Forzar cierre de los contenedores'
                }
                key={current.context.containerlpn}
                onClick={() => send('forceClosedContainer')}
              />
            </Ink.Box>
          )}

          {current.matches('ScanningPickItemSKU') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text>
                Cantidad a pickear:{' '}
                <Ink.Chalk bold color='greenBright'>
                  {`${
                    current.context.currentPickItemTotalQuantity || 'N/A'
                  } unidades`}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text>
                Restantes:
                <Ink.Chalk bold color='greenBright'>
                  {`${
                    current.context.currentPickItem?.quantity || 'N/A'
                  } unidades`}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Escanee SKU del producto:</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.sku} -{' '}
                {current.context.currentPickItem?.product.name}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningPickItemContainer') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Escanee etiqueta del contenedor de destino:</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningOutputLocation') && (
            <Ink.Box flexDirection='column'>
              {current.context.containerWasForcedToClose ? null : (
                <Ink.Text bold>Picking completado</Ink.Text>
              )}
              {current.context.palletTruckType === 'double' ? (
                <Ink.Text>Los contenedores han sido cerrados</Ink.Text>
              ) : (
                <Ink.Text>El contenedor ha sido cerrado</Ink.Text>
              )}
              <Ink.Text>Trasládese hasta ubicación de cabecera:</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.outputLocation?.name}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.hasTag('unloading') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text> </Ink.Text>
              <Ink.Text>
                Confirme descarga de contenedor{' '}
                {current.context.containersLeftToUnload.length === 2
                  ? '#2'
                  : '#1'}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ConfirmingScreen') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Descarga completada</Ink.Text>
              <Ink.Text>
                {' '}
                ¿Quieres terminar la tarea de Picking #
                {current.context.pickingWaveId}?
              </Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ConfirmingDeleteContainers') && (
            <Ink.Box flexDirection='column' justifyContent='center'>
              <Ink.Text bold>
                <Ink.Chalk color='redBright'>Atención</Ink.Chalk>
              </Ink.Text>
              <Ink.Text>Se eliminará el siguiente contenedor vacío:</Ink.Text>
              <Ink.Text bold>
                <Ink.Chalk color='greenBright'>
                  {current.context.emptyContainers[
                    current.context.emptyContainers.length - 1
                  ]?.lpn || 'N/A'}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Button
                style={{ width: '100%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Si'
              />
              <Ink.Button
                style={{ width: '100%' }}
                onClick={() => {
                  send('cancel');
                }}
                label='No'
              />
            </Ink.Box>
          )}
        </Ink.Box>

        {/* Body */}
        <>
          {current.hasTag('loading') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Cargando... </Ink.Text>
            </Ink.Box>
          )}

          {current.hasTag('location') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName />
            </Ink.Box>
          )}

          {current.hasTag('container') && (
            <Ink.Box flexDirection='column'>
              <ScanContainer />
            </Ink.Box>
          )}

          {current.hasTag('lpn') && (
            <Ink.Box flexDirection='column'>
              <EnterLpn />
            </Ink.Box>
          )}

          {current.matches('SelectingContainerType') && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                label={current.context.containerSuggestedType?.name || 'N/A'}
                onClick={() =>
                  onClickContainerType(
                    current.context.containerType
                      ? current.context.containerSuggestedType
                      : current.context.containerSuggestedType
                  )
                }
              />
              <Ink.Button
                label='Tomar otro tipo de contenedor'
                onClick={() => null}
              />
            </Ink.Box>
          )}

          {current.matches('ScanningPickItemSKU') && (
            <Ink.Box flexDirection='column'>
              <ScanSKU />
            </Ink.Box>
          )}

          {current.matches('ConfirmingScreen') && (
            <Ink.Box flexDirection='row' justifyContent='space-between'>
              <Ink.Button
                style={{ width: '40%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Si'
              />
              <Ink.Button
                style={{ width: '40%' }}
                onClick={() => {
                  send('cancel');
                }}
                label='No'
              />
            </Ink.Box>
          )}

          {current.context.error && (
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
          )}
        </>
      </Ink.Box>
    );
  }
);

import { MopAuditingPickingWaveStatuses } from '../MopAuditingPickingWave';
import { MopAuditingPickingWaveStatus } from './MopAuditingPickingWaveStatus';

export class MopAuditingPickingWaveStatusCompletedStatus extends MopAuditingPickingWaveStatus {
  public displayName = 'Completado';

  public internalName = MopAuditingPickingWaveStatuses.Completed;

  public statusColor = '#Ffffff';
}

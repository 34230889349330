import { SlottingProcessStatuses } from '../SlottingProcess';
import { SlottingProcessStatus } from './SlottingProcessStatus';

export class SlottingProcessStatusCompletedStatus extends SlottingProcessStatus {
  public displayName = 'Completado';

  public internalName = SlottingProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

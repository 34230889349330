import {
  DomainModels,
  InventoryItemExceptionTypes,
  PackingLocationItemStatuses,
  PickItemStatuses
} from '@wms/domain';
import { MachineOptions } from 'xstate';
import { getErrorType } from '../../../shared/utils/getErrorView';
import { MiniPickingWaveContext } from './machine';

type MiniPickingWaveGuards = MachineOptions<
  MiniPickingWaveContext,
  any
>['guards'];

export const miniPickingWaveGuards: MiniPickingWaveGuards = {
  hasQuantityToPick: (ctx, _event) => {
    return ctx.pickedQuantity !== ctx.currentPickItem?.quantity;
  },
  itemIsInLocation: (_ctx, evt) => {
    const pickItem = evt.data as DomainModels.PickItem;
    return pickItem.pickingLocationId === pickItem.inventoryItem.locationId;
  },
  pendingItemsRemaining: (_ctx, event) => {
    return event.data.pendingItems > 0;
  },
  bucketIsEmpty: (_ctx, evt) => {
    return (
      evt.data.totalItems === evt.data.missingItems.length ||
      evt.data.bucketIsEmpty
    );
  },
  isInventoryInsufficientQuantityException: (_ctx, evt) => {
    return (
      getErrorType(evt.data?.data) ===
      InventoryItemExceptionTypes.InsufficientQuantity
    );
  },
  isOpportunisticCountAvailable: (_ctx, evt) => {
    return evt.data.isAvailable;
  },
  isEmptyContainer: (_ctx, evt) => {
    if (evt.data.container && evt.data.container.inventoryItems) {
      return evt.data.container.inventoryItems.length === 0;
    }
    return true;
  },
  isPickItemCancelled: (_ctx, event) => {
    return event.data.status === PickItemStatuses.Cancelled;
  },
  isMovingPickingContainer: (_ctx, evt) => {
    return (
      evt.data.pickingContainer?.status !== PackingLocationItemStatuses.Draft
    );
  }
};

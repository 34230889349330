import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { OrderItem } from './OrderItem';
import { ReceiptItem } from './ReceiptItem';
import { ReservationItem } from './ReservationItem';
import { Warehouse } from './Warehouse';

export const MAX_SORTABLE_MEASURES = [800, 600, 600];
export const MAX_SORTABLE_WEIGHT = 25;

@Entity()
export class SellerProduct {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field()
  sorter: boolean;

  @Field()
  pure: boolean;

  @Field()
  code: string;

  @ForeignKey(() => Warehouse, { name: 'target_warehouse_id' })
  targetWarehouseId: number;

  @Needs(() => Warehouse, 'targetWarehouseId')
  warehouse: Warehouse;

  @Field({ name: 'expedition_date' })
  expeditionDate: Date;

  @Knows(() => ReceiptItem, 'sellerProductId')
  receiptItem: ReceiptItem;

  @Knows(() => OrderItem, 'sellerProductId')
  orderItem: OrderItem;

  @Knows(() => InventoryItem, 'sellerProductId')
  inventoryItem: InventoryItem;

  @Knows(() => ReservationItem, 'sellerProductId')
  reservationItem: ReservationItem;
}

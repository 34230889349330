import { InventoryAdjustWaveStatuses } from '../InventoryAdjustWave';
import { InventoryAdjustWaveStatus } from './InventoryAdjustWaveStatus';

export class InventoryAdjustWaveProcessingStatus extends InventoryAdjustWaveStatus {
  public displayName = 'Procesando';

  public internalName = InventoryAdjustWaveStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}

import { LocationStatuses } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { ManualRestockingContext } from './machine';

type ManualRestockingGuardsType = MachineOptions<
  ManualRestockingContext,
  any
>['guards'];

export const manualRestockingGuards: ManualRestockingGuardsType = {
  locationAttributeIsPicking: (_ctx, evt) => {
    return !!evt.data && evt.data.locationAttribute === 'picking';
  },
  pendingRestockingItem: (_ctx, evt) => {
    return evt.data.restockingItem;
  },
  locationHasContainer: (_ctx, evt) => {
    return (
      evt.data.containers.length > 0 &&
      evt.data.containers[0].inventoryItems.length > 0
    );
  },
  locationHasItems: (_ctx, evt) => {
    return evt.data.inventoryItems.length > 0;
  },
  existRestockingItem: (ctx, _evt) => {
    return !!ctx.restockingItem;
  },
  targetLocationSameAsOrigin: (ctx, evt) => {
    return evt.data.id == ctx.originLocation!.id;
  },
  validRestockingLevel: (_ctx, evt) => {
    return evt.data.validation;
  },
  targetLocationUnavailable: (_ctx, evt) => {
    return evt.data.status === LocationStatuses.Unavailable;
  },
  targetLocationBlocked: (_ctx, evt) => {
    return evt.data.status === LocationStatuses.Blocked;
  },
  canMoveToLocation: (_ctx, evt) => {
    return evt.data.task;
  },
  hasTask: ctx => {
    return !!ctx.externalTask;
  },
  isNull: (_ctx, evt) => {
    return !evt.data;
  }
};

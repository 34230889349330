import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { PackingAuditingDesignatorContext } from './machine';

type PackingAuditingDesignatorServices = MachineOptions<
  PackingAuditingDesignatorContext,
  any
>['services'];

export const packingAuditingDesignatorServices: PackingAuditingDesignatorServices =
  {
    checkTaskTypeByContainer: async (ctx, _event) => {
      const { data } = await api.get(
        `container/${ctx.container?.id}/task-type`
      );
      return data;
    },
    fetchPackingWaveTask: async context => {
      const { data: task } = await api.get('packing-wave/assign-mini-task', {
        params: {
          packingLocationId: context.location?.id,
          containerId:       context.container?.id
        }
      });

      return task;
    },
    markItemAsMoved: async (ctx, _event) => {
      const { data } = await api.post('auditing-wave/launch-task', {
        locationId:  ctx.location?.id,
        containerId: ctx.container?.id
      });
      return data;
    }
  };

import { MachineOptions, assign } from 'xstate';
import { MovingToPreSorterTaskContext } from './machine';

type MovingToPreSorterTaskActions = MachineOptions<
  MovingToPreSorterTaskContext,
  any
>['actions'];

export const movingToPreSorterTaskActions: MovingToPreSorterTaskActions = {
  assignContainers: assign({
    totalContainers: (_ctx, event) => event.data.totalContainers,
    movedContainers: (_ctx, event) => event.data.movedContainers
  }),

  assignSuggestedContainerToMoveToPreSorter: assign({
    suggestedContainerToMoveToPreSorter: (_ctx, event) =>
      event.data.suggestedContainerToMoveToPreSorter
  }),

  assignContainerToMoveToPreSorter: assign({
    containerToMoveToPreSorter: (_ctx, event) => event.data
  }),

  assignLocationToUnload: assign({
    locationToUnload: (_ctx, event) => event.data
  })
};

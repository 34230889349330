import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { PtwPackageInductionMachine } from './machine';

export const PtwPackageInductionComponent = withActorRef(
  PtwPackageInductionMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>Enviar bulto PTW a rampa</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
        {current.matches('ScanLabel') && (
          <>
            <GetItemByLabel />
          </>
        )}

        {current.matches('PackingItemsIsNotPending') && (
          <>
            <Ink.Text bold>
              El bulto NO está listo para inducir. Valide con la supervisión
            </Ink.Text>
            <Ink.Box flexDirection='column' paddingX={1} paddingTop={1}>
              <Ink.Button
                label='Reintentar operación'
                onClick={() => send('BackToScanLabel')}
              />
            </Ink.Box>
          </>
        )}

        {current.matches('FeedPackageToSorterExit') && (
          <>
            <Ink.Text bold>Ya puede inducir en el sorter</Ink.Text>
            <Ink.Box flexDirection='column' paddingX={1} paddingTop={1}>
              <Ink.Button
                label='Inducción Realizada'
                onClick={() => send('BackToScanLabel')}
              />
            </Ink.Box>
          </>
        )}
      </Ink.Box>
      {current.context.error && (
        <Ink.Text>
          <Ink.Chalk color='redBright'>{current.context.error}</Ink.Chalk>
        </Ink.Text>
      )}
    </Ink.Box>
  );
});

import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  Owns,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Provider } from './Provider';
import { PurchaseOrderItem } from './PurchaseOrderItem';
import { Receipt } from './Receipt';
import { User } from './User';
import { Warehouse } from './Warehouse';
import {
  PurchaseOrderStatusCancelledStatus,
  PurchaseOrderStatusClosedStatus,
  PurchaseOrderStatusOpenStatus
} from './purchase-order-status';
import { PurchaseOrderStatus } from './purchase-order-status/PurchaseOrderStatus';

export const PurchaseOrderStatuses = {
  Open:      'open',
  Closed:    'closed',
  Cancelled: 'cancelled'
};

export enum OperationTypes {
  Alta = '01',
  Modificacion = '02',
  Baja = '03'
}

@Entity()
export class PurchaseOrder {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @ForeignKey(() => Provider, { name: 'provider_id' })
  providerId: number;

  @Field({ name: 'provider_code_sap' })
  providerCodeSap: string;

  @Field({ name: 'provider_code_nodum' })
  providerCodeNodum: number;

  @Field({ name: 'provider_name' })
  providerName: string;

  @Field({ name: 'transaction_number' })
  transactionNumber: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'document_number' })
  documentNumber: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'warehouse_id' })
  warehouseId: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'purchase_date' })
  purchaseDate: Date;

  @Field({ name: 'sap_order_code' })
  sapCode: string;

  @Field({ name: 'currency' })
  currency: string;

  @Field({ name: 'total_price' })
  totalPrice: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'estimated_arrive_date' })
  estimatedArriveDate: Date;

  @Owns(() => PurchaseOrderItem, 'purchaseOrderId')
  purchaseOrderItems: PurchaseOrderItem[];

  @Knows(() => Receipt, 'purchaseOrderId')
  receipts: Receipt[];

  @Knows(() => Provider, 'providerId')
  provider: Provider;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Status({
    [PurchaseOrderStatuses.Open]:      PurchaseOrderStatusOpenStatus,
    [PurchaseOrderStatuses.Cancelled]: PurchaseOrderStatusCancelledStatus,
    [PurchaseOrderStatuses.Closed]:    PurchaseOrderStatusClosedStatus
  })
  status: PurchaseOrderStatus;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;
}

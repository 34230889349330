import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { Location } from './Location';
import { NoveltyMoveContainerToBufferContainer } from './NoveltyMoveContainerToBufferContainer';
import { NoveltyUnloadingItemV2 } from './NoveltyUnloadingItemV2';
import {
  NoveltyUnloadingContainerV2Status,
  NoveltyUnloadingContainerV2StatusMovingStatus,
  NoveltyUnloadingContainerV2StatusOpenStatus
} from './novlety-unloading-container-v2-status';

export const NoveltyUnloadingContainerV2Statuses = {
  Open:   'open',
  Moving: 'moving'
};

@Entity()
export class NoveltyUnloadingContainerV2 {
  @PrimaryKey()
  id: number;

  @IsNotEmpty()
  @Field({ name: 'type' })
  type: string;

  @ForeignKey(() => Location, { name: 'target_sector_id' })
  targetSectorId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'targetSectorId')
  targetSector: Location;

  @Needs(() => Container, 'containerId')
  container: Container;

  @NeededBy(
    () => NoveltyMoveContainerToBufferContainer,
    'noveltyUnloadingContainerV2Id'
  )
  noveltyMoveContainerToBufferContainers: NoveltyMoveContainerToBufferContainer[];

  @Knows(() => NoveltyUnloadingItemV2, 'noveltyUnloadingContainerV2Id')
  noveltyUnloadingItemsV2: NoveltyUnloadingItemV2[];

  @Status({
    [NoveltyUnloadingContainerV2Statuses.Open]:
      NoveltyUnloadingContainerV2StatusOpenStatus,
    [NoveltyUnloadingContainerV2Statuses.Moving]:
      NoveltyUnloadingContainerV2StatusMovingStatus
  })
  status: NoveltyUnloadingContainerV2Status;
}

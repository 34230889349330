import { InventoryAdjustItemStatuses } from '../InventoryAdjustItem';
import { InventoryAdjustItemStatus } from './InventoryAdjustItemStatus';

export class InventoryAdjustItemControlledStatus extends InventoryAdjustItemStatus {
  public displayName = 'Controlado';

  public internalName = InventoryAdjustItemStatuses.Controlled;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

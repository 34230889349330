import { MachineOptions, assign } from 'xstate';
import { AlertData } from '../../../../../types/alerData';
import { handleErrorPopUp } from '../../../../../utils/pop-up-layer-4';
import { GetProductBySKUContext } from './machine';

type GetProductBySKUActions = MachineOptions<
  GetProductBySKUContext,
  any
>['actions'];

export const getProductBySKUActions: GetProductBySKUActions = {
  updateProductSKU: assign({
    productSKU: (_context, event) => event.productSKU
  }),
  assignProduct: assign({
    product: (context, event) => event.data.data
  }),
  assignError: assign({
    error: (context, event) => event.data.error
  }),
  clearError: assign({
    error: (_context, _event) => null
  }),
  clearProductSKU: assign({
    productSKU: (_context, _event) => ''
  }),
  invalidSKUError: assign({
    error: (_context, _event) => 'El SKU ingresado no es válido'
  }),
  notRequiredSKUError: assign({
    error: (_context, _event) => 'El SKU ingresado no es requerido'
  }),
  handleErrorInPopUp: (ctx, evt) => {
    handleErrorPopUp({
      errorMessage:
        evt?.data?.error ||
        ctx.error ||
        'Ha ocurrido un error al buscar el producto'
    } as AlertData);
  }
};

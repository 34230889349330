import { ReceiptBatchStatuses } from '../ReceiptBatch';
import { ReceiptBatchStatus } from './ReceiptBatchStatus';

export class ReceiptBatchProcessingStatus extends ReceiptBatchStatus {
  public displayName = 'Procesando';

  public internalName = ReceiptBatchStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#e77c03';
}

import {
  Container,
  ContainerType,
  Location,
  PickItem,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { missingMiniMopItemsActions } from './actions';
import { missingMiniMopItemsGuards } from './guards';
import { missingMiniMopItemsServices } from './services';

export interface MissingMiniMopItemsContext {
  task: Task;
  mopAuditingPickingWaveId: number;
  auditingWaveId: number;
  pickingWaveId: number;
  packingStationId: number;

  containerType: ContainerType | null;
  containerCreated: Container | null;

  pickItemsTotalCount: number | null;
  pickItemsPickedCount: number | null;
  nextPickItem: PickItem | null;
  quantityPicked: number | null;

  packingStationLocation: Location | null;
  destinyContainer: Container | null;

  error: string | null;
}

export const DefaultMissingMiniMopItemsContext: MissingMiniMopItemsContext = {
  task:                     {} as Task,
  mopAuditingPickingWaveId: -1,
  auditingWaveId:           -1,
  pickingWaveId:            -1,
  packingStationId:         -1,
  containerType:            null,
  containerCreated:         null,
  pickItemsTotalCount:      null,
  pickItemsPickedCount:     null,
  nextPickItem:             null,
  quantityPicked:           null,
  packingStationLocation:   null,
  destinyContainer:         null,
  error:                    null
};

export const MissingMiniMopItemsMachine = createMachine(
  {
    id:                         'MissingMiniMopItems',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MissingMiniMopItemsContext
    },
    initial: 'FetchingContainerType',
    states:  {
      FetchingContainerType: {
        invoke: {
          src:    'fetchContainerType',
          onDone: {
            target:  'CreatingContainer',
            actions: 'assignContainerType'
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      CreatingContainer: {
        invoke: {
          id:   CreateContainerMachine.id,
          src:  CreateContainerMachine,
          data: ctx => ({
            ...createContainerMachineInitialContext,
            containerType:            ctx.containerType!,
            forceContainerType:       true,
            mopAuditingPickingWaveId: ctx.mopAuditingPickingWaveId
          }),
          onDone: {
            target:  'SendingToParentCreatePickingContainer',
            actions: ['assignContainerCreated', 'clearError']
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      SendingToParentCreatePickingContainer: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniMopCreatePickingContainer',
              payload: {
                pickingWaveId: ctx.pickingWaveId,
                containerId:   ctx.containerCreated!.id,
                warehouseId:   ctx.containerCreated!.location!.warehouseId
              }
            };
          },
          onDone: 'FetchingPickItemCount'
        }
      },
      FetchingPickItemCount: {
        invoke: {
          src:    'fetchPickItemCount',
          onDone: {
            target:  'FetchingNextPickItem',
            actions: 'assignPickItemCount'
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      FetchingNextPickItem: {
        invoke: {
          src:    'fetchNextPickItem',
          onDone: [
            {
              cond:    'hasNextPickItem',
              target:  'ScanningPickingLocation',
              actions: 'assignNextPickItem'
            },
            {
              target: 'FetchingPackingStation'
            }
          ],
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      ScanningPickingLocation: {
        invoke: {
          id:  'ScanningPickingLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion de picking',
              getRequestedLocationByName(
                'Ocurrio un error, por favor intente nuevamente',
                'La ubicacion no es valida',
                ctx.nextPickItem!.inventoryItem!.location!.name
              )
            ),
          onDone:  'EnteringPickingQuantity',
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      EnteringPickingQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            hint: 'Ingrese la cantidad a pickear',
            max:  ctx.nextPickItem!.quantity
          }),
          onDone: {
            target:  'SendingToParentMiniMopMarkItemAsPicked',
            actions: 'assignQuantityPicked'
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      SendingToParentMiniMopMarkItemAsPicked: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => ({
            task:    ctx.task,
            type:    'MiniMopMarkAuditingItemAsPicked',
            payload: {
              pickItemId:     ctx.nextPickItem!.id,
              pickedQuantity: ctx.quantityPicked!,
              containerId:    ctx.containerCreated!.id
            }
          }),
          onDone: 'FetchingPickItemCount'
        }
      },
      FetchingPackingStation: {
        invoke: {
          src:    'fetchPackingStation',
          onDone: {
            target:  'FetchingDestinyContainer',
            actions: 'assignPackingStationLocation'
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      FetchingDestinyContainer: {
        invoke: {
          src:    'fetchDestinyContainer',
          onDone: {
            target:  'ScanningDestinyLocation',
            actions: 'assignDestinyContainer'
          },
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      ScanningDestinyLocation: {
        invoke: {
          id:  'ScanningDestinyLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicacion de destino',
              getRequestedLocationByName(
                'Ocurrio un error, por favor intente nuevamente',
                'La ubicacion no es valida',
                ctx.packingStationLocation!.name
              )
            ),
          onDone:  'ScanningDestinyContainer',
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      ScanningDestinyContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee cubeta de destino',
              getRequestedContainerByLpn(
                'Ocurrio un error, por favor intente nuevamente',
                'La cubeta no es valida',
                ctx.destinyContainer!.lpn
              )
            ),
          onDone:  'SendingToParentCompleteMopAuditingPickingWave',
          onError: {
            target:  'CreatingContainer',
            actions: 'assignError'
          }
        }
      },
      SendingToParentCompleteMopAuditingPickingWave: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => ({
            task:    ctx.task,
            type:    'MiniMopCompleteAuditingPickingWave',
            payload: {
              pickingWaveId:       ctx.pickingWaveId,
              destinyContainerLpn: ctx.destinyContainer!.lpn,
              locationId:          ctx.packingStationLocation!.id,
              taskId:              ctx.task.id,
              originContainerLpn:  ctx.containerCreated!.lpn
            }
          }),
          onDone: 'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    services: missingMiniMopItemsServices,
    guards:   missingMiniMopItemsGuards,
    actions:  missingMiniMopItemsActions
  }
);

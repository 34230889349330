import { NoveltyUnloadingItemStatuses } from '../NoveltyUnloadingItem';
import { NoveltyUnloadingItemStatus } from './NoveltyUnloadingItemStatus';

export class NoveltyUnloadingItemStatusUnloadedStatus extends NoveltyUnloadingItemStatus {
  public displayName = 'Descargado';

  public internalName = NoveltyUnloadingItemStatuses.Unloaded;

  public statusColor = '#Ffffff';
}

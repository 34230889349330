import { DispatchingWaveStatuses } from '../DispatchingWave';
import { DispatchingWaveStatus } from './DispatchingWaveStatus';

export class DispatchingWavePendingStatus extends DispatchingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = DispatchingWaveStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import {
  LocationAttributeNames,
  LocationTypeNames,
  locationAttributeOperationType
} from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { InventoryAdjustmentContext } from './machine';

type InventoryAdjustmentGuards = MachineOptions<
  InventoryAdjustmentContext,
  any
>['guards'];

export const inventoryAdjustmentGuards: InventoryAdjustmentGuards = {
  invalidType: (_ctx, evt) =>
    [
      LocationTypeNames.Dock,
      LocationTypeNames.Section,
      LocationTypeNames.PickingCart
    ].includes(evt.data.locationType.name),
  areReservedItemsInLocation: (_ctx, evt) => {
    return evt.data.ordersWithReservedItemsInLocation.length > 0;
  },
  canCreateContainer: (ctx, _evt) => {
    const isStorage = ctx.location!.locationLocationAttributes.some(
      ({ locationAttribute }) =>
        locationAttribute.name === LocationAttributeNames.OPERATIONTYPE &&
        [
          locationAttributeOperationType.STORAGE,
          locationAttributeOperationType.STORAGE_MINI
        ].includes(locationAttribute.value.value)
    );

    return isStorage && !ctx.location?.name.startsWith('MZ1');
  },
  ValidLpn: (_ctx, evt) => evt.data
};

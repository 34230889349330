import Ink from '@ink';
import React, { FC } from 'react';

export const FatalError: FC<{
  error: string | null;
  onRetry: () => void;
}> = ({ onRetry, error }) => {
  return (
    <>
      <Ink.Text>
        <Ink.Chalk redBright>{error || ''}</Ink.Chalk>
      </Ink.Text>
      <Ink.Button key={1} id='1' label='Reintentar' onClick={onRetry} />
    </>
  );
};

FatalError.displayName = 'FatalError';

import { MachineOptions } from 'xstate';
import { NoSortableSlottingTaskContext } from './machine';

type NoSortableSlottingTaskGuards = MachineOptions<
  NoSortableSlottingTaskContext,
  any
>['guards'];

export const noSortableSlottingTaskGuards: NoSortableSlottingTaskGuards = {
  hasPendingContainersToNoSortableSlotting: (_ctx, event) => {
    return event.data.slottedContainers < event.data.totalContainers;
  }
};

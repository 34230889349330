import { Container } from '@wms/domain/.';
import {
  MachineOptions,
  assign
  // assign
} from 'xstate';
import { InventoryAdjustmentContext } from './machine';

type InventoryAdjustmentActions = MachineOptions<
  InventoryAdjustmentContext,
  any
>['actions'];

export const inventoryAdjustmentActions: InventoryAdjustmentActions = {
  assignToItemsToAdjust: assign({
    itemsToAdjust: (ctx, _evt) => {
      const existingItem = ctx.itemsToAdjust!.find(
        item => item.sku === ctx.currentItem?.sku
      );
      if (existingItem) {
        return ctx.itemsToAdjust;
      }
      return [...(ctx.itemsToAdjust || []), ctx.currentItem!];
    }
  }),
  clearContext: assign({
    itemsToAdjust: _ctx => [],
    location:      _ctx => null,
    mismatchItems: _ctx => [],
    currentItem:   _ctx => null,
    container:     _ctx => ({} as Container)
  }),
  clearError: assign({
    error: ''
  }),
  clearWarning: assign({
    warning: _ctx => ''
  }),
  setLocation: assign({
    location: (_ctx, evt) => evt.data
  }),
  invalidTypeError: assign({
    error: (_ctx, _evt) =>
      'El tipo de ubicación escaneada no es valida para el control.'
  }),
  reservedItemInLocationError: assign({
    // error: (_, evt) =>
    //   `La ubicacion posee items reservados, por favor complete las siguientes ordenes para poder liberar la ubicacion. Orden/es: ${evt.data.ordersWithReservedItemsInLocation}`
    warning: (_, evt) =>
      `⚠️ La ubicación posee items reservados, un reporte de faltante negativo en ciertas situaciones podría afectar a las olas de picking de las siguientes orden/es: ${evt.data.ordersWithReservedItemsInLocation}`
  }),
  setQuantity: assign({
    itemsToAdjust: (ctx, evt) => {
      const currentItem = ctx.itemsToAdjust!.find(
        item => item.sku === ctx.currentItem?.sku
      );
      currentItem!.quantity += evt.data.quantity;
      return ctx.itemsToAdjust;
    }
  }),
  setCurrentItem: assign({
    currentItem: (ctx, evt) => {
      let quantity = 0;
      const currentItem = ctx.itemsToAdjust!.find(
        item => item.sku === evt.data.sku
      );
      if (currentItem) {
        quantity = currentItem.quantity;
      }
      return { sku: evt.data.sku, quantity };
    }
  }),
  setMismatchItems: assign({
    mismatchItems: (_ctx, evt) => evt.data.mismatchItems
  }),
  assignLpn: assign({
    lpn: (_ctx, evt) => evt.data.lpn
  }),
  assignInvalidLpnError: assign({
    error:
      'El contenedor ya se encuentra declarado en el sistema, revise por Backoffice la situacion'
  }),
  setContainer: assign({
    container: (ctx, _evt) => ({ lpn: ctx.lpn } as Container)
  })
};

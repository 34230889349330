import Ink from '@ink';
import React, { FC, Fragment } from 'react';

export interface IIntercalatedText {
  texts: string[];
  values: Array<string | number>;
  valuesColor?: 'redBright' | 'greenBright' | 'none';
  bold?: boolean;
}

const intercalate = (
  text1: string[],
  text2: Array<string | number>,
  config: {
    bold: boolean;
    valuesColor: 'redBright' | 'greenBright' | undefined;
  }
) => {
  return text1.reduce((acc, value, index) => {
    if (index > 0) {
      acc.push(<Ink.Chalk key={`separator-${index}`}>&nbsp;</Ink.Chalk>);
    }
    if (value) {
      acc.push(
        <Fragment key={`text1-${index}`}>
          <Ink.Chalk>{value}</Ink.Chalk> &nbsp;
        </Fragment>
      );
    }
    acc.push(
      <Fragment key={`text2-${index}`}>
        <Ink.Chalk bold={config.bold} color={config.valuesColor}>
          {text2[index] !== undefined ? '' + text2[index] : ''}
        </Ink.Chalk>
      </Fragment>
    );
    return acc;
  }, [] as JSX.Element[]);
};

export const IntercalatedText: FC<IIntercalatedText> = ({
  texts,
  values,
  bold = true,
  valuesColor = 'greenBright'
}) => {
  const validatedValuesColor = valuesColor === 'none' ? undefined : valuesColor;
  return (
    <Ink.Text>
      {intercalate(texts, values, { bold, valuesColor: validatedValuesColor })}
    </Ink.Text>
  );
};

IntercalatedText.displayName = 'IntercalatedText';

import { SlottingItemStatuses } from '../SlottingItem';
import { SlottingItemStatus } from './SlottingItemStatus';

export class SlottingItemStatusCancelledStatus extends SlottingItemStatus {
  public displayName = 'Cancelado';

  public internalName = SlottingItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Order } from './Order';
import {
  ReservationOrderStatus,
  ReservationOrderStatusPartiallyDispatchedStatus
} from './reservation-order-status';
import { ReservationOrderStatusCancelledStatus } from './reservation-order-status/ReservationOrderStatusCancelledStatus';
import { ReservationOrderStatusDeliveredStatus } from './reservation-order-status/ReservationOrderStatusDeliveredStatus';
import { ReservationOrderStatusDispatchedStatus } from './reservation-order-status/ReservationOrderStatusDispatchedStatus';
import { ReservationOrderStatusNotDispatchedStatus } from './reservation-order-status/ReservationOrderStatusNotDispatchedStatus';
import { ReservationOrderStatusProcessingStatus } from './reservation-order-status/ReservationOrderStatusProcessingStatus';
import { ReservationOrderStatusRejectedStatus } from './reservation-order-status/ReservationOrderStatusRejectedStatus';
import { ReservationOrderStatusRoutedStatus } from './reservation-order-status/ReservationOrderStatusRoutedStatus';
import { ReservationIdentifier } from './reservationIdentifier';

export const ReservationOrderStatuses = {
  Routed:              'routed',
  Processing:          'processing',
  Dispatched:          'dispatched',
  Delivered:           'delivered',
  Rejected:            'rejected',
  Cancelled:           'cancelled',
  PartiallyDispatched: 'partially-dispatched',
  NotDispatched:       'not-dispatched'
};

@Entity()
export class ReservationOrder {
  @PrimaryKey()
  id: number;

  @Status({
    [ReservationOrderStatuses.Routed]:     ReservationOrderStatusRoutedStatus,
    [ReservationOrderStatuses.Processing]:
      ReservationOrderStatusProcessingStatus,
    [ReservationOrderStatuses.Dispatched]:
      ReservationOrderStatusDispatchedStatus,
    [ReservationOrderStatuses.Delivered]:           ReservationOrderStatusDeliveredStatus,
    [ReservationOrderStatuses.Rejected]:            ReservationOrderStatusRejectedStatus,
    [ReservationOrderStatuses.Cancelled]:           ReservationOrderStatusCancelledStatus,
    [ReservationOrderStatuses.PartiallyDispatched]:
      ReservationOrderStatusPartiallyDispatchedStatus,
    [ReservationOrderStatuses.NotDispatched]:
      ReservationOrderStatusNotDispatchedStatus
  })
  status: ReservationOrderStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'wms_reservation_order_number' })
  wmsReservationOrderNumber: number;

  @Field({ name: 'labeled_items_count' })
  labeledItemsCount: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => ReservationIdentifier, {
    name: 'reservation_identifier_id'
  })
  reservationIdentifierId: number;

  @Needs(() => Order, 'orderId')
  order: Order;

  @Needs(() => ReservationIdentifier, 'reservationIdentifierId')
  reservationIdentifier: ReservationIdentifier;

  public getWmsReservationOrderNumber() {
    return this.wmsReservationOrderNumber.toString().padStart(11, '0');
  }
}

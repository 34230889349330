import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { DispatchingItem } from './DispatchingItem';
import { MovePendingDispatchingItemsWave } from './MovePendingDispatchingItemsWave';
import { ReservationPendingContainer } from './ReservationPendingContainer';
import {
  MovePendingDispatchingItemCancelledStatus,
  MovePendingDispatchingItemMovedStatus,
  MovePendingDispatchingItemPendingStatus,
  MovePendingDispatchingItemProcessingStatus
} from './move-pending-dispatching-item-status';
import { MovePendingDispatchingItemStatus } from './move-pending-dispatching-item-status/MovePendingDispatchingItemStatus';

export const MovePendingDispatchingItemStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Moved:      'moved',
  Cancelled:  'cancelled'
};

@Entity()
export class MovePendingDispatchingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [MovePendingDispatchingItemStatuses.Pending]:
      MovePendingDispatchingItemPendingStatus,
    [MovePendingDispatchingItemStatuses.Processing]:
      MovePendingDispatchingItemProcessingStatus,
    [MovePendingDispatchingItemStatuses.Moved]:
      MovePendingDispatchingItemMovedStatus,
    [MovePendingDispatchingItemStatuses.Cancelled]:
      MovePendingDispatchingItemCancelledStatus
  })
  status: MovePendingDispatchingItemStatus;

  @ForeignKey(() => MovePendingDispatchingItemsWave, {
    name: 'move_pending_dispatching_items_wave_id'
  })
  movePendingDispatchingItemsWaveId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => DispatchingItem, { name: 'dispatching_item_id' })
  dispatchingItemId: number;

  @ForeignKey(() => ReservationPendingContainer, {
    name: 'reservation_pending_container_id'
  })
  reservationPendingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(
    () => MovePendingDispatchingItemsWave,
    'movePendingDispatchingItemsWaveId'
  )
  movePendingDispatchingItemsWave: MovePendingDispatchingItemsWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => DispatchingItem, 'dispatchingItemId')
  dispatchingItem: DispatchingItem;

  @Knows(() => ReservationPendingContainer, 'reservationPendingContainerId')
  reservationPendingContainer: ReservationPendingContainer;
}

import { FeedingToSorterProcessStatuses } from '../FeedingToSorterProcess';
import { FeedingToSorterProcessStatus } from './FeedingToSorterProcessStatus';

export class FeedingToSorterProcessStatusPausedStatus extends FeedingToSorterProcessStatus {
  public displayName = 'Pausado';

  public internalName = FeedingToSorterProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

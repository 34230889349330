import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { Receipt } from './Receipt';
import { SlottingContainer } from './SlottingContainer';
import {
  SlottingProcessStatus,
  SlottingProcessStatusCancelledStatus,
  SlottingProcessStatusCompletedStatus,
  SlottingProcessStatusPausedStatus,
  SlottingProcessStatusPendingStatus,
  SlottingProcessStatusProcessingStatus
} from './slotting-process-status';

export const SlottingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class SlottingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [SlottingProcessStatuses.Pending]:    SlottingProcessStatusPendingStatus,
    [SlottingProcessStatuses.Processing]: SlottingProcessStatusProcessingStatus,
    [SlottingProcessStatuses.Completed]:  SlottingProcessStatusCompletedStatus,
    [SlottingProcessStatuses.Cancelled]:  SlottingProcessStatusCancelledStatus,
    [SlottingProcessStatuses.Paused]:     SlottingProcessStatusPausedStatus
  })
  status: SlottingProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => SlottingContainer, 'slottingProcessId')
  slottingContainers: SlottingContainer[];
}

import {
  Location,
  NoveltyMoveContainerToBufferContainer,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';

import { SendToManyParentsMachine } from '../../core/SendToManyParentsMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { noveltyMoveContainerToBufferActions } from './actions';
import { noveltyMoveContainerToBufferServices } from './services';

export interface NoveltyMoveContainerToBufferContext {
  task: Task | null;
  processesIds: number[];
  noveltyContainerMoveToBufferContainerLpn: string;
  targetLocation: Location | null;
  noveltyMoveContainerToBufferContainer: NoveltyMoveContainerToBufferContainer | null;
  error: string | null;
}

export const DeafultNoveltyMoveContainerToBufferContext = {
  task:                                     null,
  processesIds:                             [],
  noveltyContainerMoveToBufferContainerLpn: '',
  targetLocation:                           null,
  noveltyMoveContainerToBufferContainer:    null,
  error:                                    null
};

export const NoveltyMoveContainerToBufferMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QFkCWA7VyD2A3MAKtgMrYBOALmGQKIAeqFAdMQMYCG6m6UACu6wDW7GAGII2dGCYZc2QdLSYc+IqUrV6jFhy4Y+A4TASzsHCqkkBtAAwBdW3cSgADtliNL6ZyDqIATADMNkwAnABsABwA7AAsAKzx4YExoQCMADQgAJ4BNrFMNkGxsdHRgf6xofH5AL61WUpYeIQk5FS0DMxsnNxQADJm7BaS4pLSpgpMTSqt6h1a3bp9g+ZeJuhya9b2jj5uHiPeSL6ISQWRoTbhafGh95fRkVm5CP75hcU2l7GBoZFpIr1RoYZqqNoaTraADCkgAZqgyABbfSzJFgdAUUTIADyADUaMgaAA5AgAfWhOOJADEAJIAJSJABE9icDp5JD4-AhQlUmIE4rzIjFwr9vi8Av5okxBfdwvF-PFYmlIvFosCQDMWmp2poukxYegEcjUS10ZjRNCAILE6E0fqs1zuDnHUDc8I2aWXa7RfLvSJVaIShAAphVe7RfyReWBWKeyIarXg+Z67TU0GwAAWpvw5qxEikTFgFGGilBsx1kMWTHTmCzObAecdIHZRy5iDSf38YWu-l5aVKUdi4WD-luTEigSnioVsSCAfCifL2ohC31tdQ9Z4aIxWOoZHITBcABthnDyEjpsvk7qocwN1uoDvMRstsMvI5m62vO2edF4kwSSKrE0ZFDcArBp2BTRBEALJNGoqBGk6oaug2AQHAPhJnMt6LPszptic3IALQjjkiDEQB9zUTRtGBEuygrimd46L0+j8EIIhgPhhw-kRiBzpB0b8lOwSetRSEJg0mrXjhVb6j0eg8Ks76cmyBF8W6iApCEArhmkyEwYJ5FvEUTCVBG8SBFZfY2P8DFgnJa4wvCiIotuZq7jxLq-uU3aitcdn+OEY6JKEo7vEwaThDFIWBCF0RRCBDkVquqb3hm2YebmXnqbxalaQgBkRGGvbvBEdkRJB6QiVOCrxMh1Q2AKKVMbh66ZZA3mEYVtyeoBTzvGkfYekEo6doBk5TiFDUWUk9T1EAA */
    id:                         'NoveltyMoveContainerToBuffer',
    predictableActionArguments: true,
    schema:                     {
      context: {} as NoveltyMoveContainerToBufferContext
    },
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor de novedades',
              getRequestedContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.',
                'El contenedor escaneado es invalido',
                ctx.noveltyContainerMoveToBufferContainerLpn
              )
            ),
          onDone: {
            target: 'FetchingMoveContainerToBufferContainer'
          }
        }
      },

      FetchingMoveContainerToBufferContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchMoveContainerToBufferContainer',
          onDone: {
            target:  'FetchingTargetLocation',
            actions: 'assignMoveContainerToBufferContainer'
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },

      FetchingTargetLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchTargetLocation',
          onDone: {
            target:  'ScanningTargetLocation',
            actions: 'assignTargetLocation'
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },

      ScanningTargetLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Confirme escaneando ubicación',
              getRequestedLocationByName(
                'Ocurrió un error, por favor reintente más tarde.',
                'La ubicación escaneada es invalida',
                ctx.targetLocation!.name
              )
            ),
          onDone: {
            target:  'ConfirmingMovement',
            actions: 'assignLocation'
          }
        }
      },

      ConfirmingMovement: {
        on: {
          CONFIRMED: 'MarkingNoveltyContainerToBufferContainerAsMoved'
        }
      },

      MarkingNoveltyContainerToBufferContainerAsMoved: {
        invoke: {
          src:    'markNoveltyContainerToBufferContainerAsMoved',
          onDone: {
            target: 'FetchingProccesesToSend'
          }
        }
      },

      FetchingProccesesToSend: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchProcessesToSend',
          onDone: {
            target:  'SendingToParentMovingNoveltyItemsToBuffer',
            actions: 'assignProcessesIds'
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },

      SendingToParentMovingNoveltyItemsToBuffer: {
        invoke: {
          id:   SendToManyParentsMachine.id,
          src:  SendToManyParentsMachine,
          data: ctx => {
            return {
              type:         'NoveltyMoveItemsToBuffer',
              processesIds: ctx.processesIds,
              payload:      {
                taskId:                                  ctx.task!.id,
                noveltyMoveContainerToBufferContainerId:
                  ctx.noveltyMoveContainerToBufferContainer!.id,
                bufferLocationId: ctx.targetLocation!.id
              }
            };
          },
          onDone:  'Done',
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    actions:  noveltyMoveContainerToBufferActions,
    services: noveltyMoveContainerToBufferServices
  }
);

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../../modules/shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { DispatchSSMachine } from './machine';

export const DispatchSSTask = withActorRef(DispatchSSMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const shouldRenderStagingLocationInfo =
      !!current.context.stagingName &&
      !current.hasTag('loading') &&
      !current.matches('ScanningCheckDigit') &&
      !current.matches('ConfirmingDispatch');

    const shouldRenderSeparatorNewLine =
      !current.hasTag('loading') && !current.matches('ConfirmingDispatch');

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        {/* Cargando */}
        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Header>
                Despacho N°{current.context.dispatchingWaveId} - Cargando...
              </Ink.Header>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : (
            <Ink.Header>
              Despacho SS N°{current.context.dispatchingWaveId} - OS N°{' '}
              {current.context.dispatchingWave?.dispatchingProcess
                ?.orderBatchId || 'X'}{' '}
              - {current.context.dispatchingWave?.staging?.name || ''}
            </Ink.Header>
          )}
        </>

        {/* Header */}
        <>
          {current.matches('ScanningCheckDigit') && (
            <Ink.Box flexDirection='column'>
              <IntercalatedText
                texts={['Trasladese a muelle', 'canal']}
                values={[
                  `${
                    current.context.dispatchingWave?.staging.parent.name || ''
                  },`,
                  current.context.dispatchingWave?.staging.name || ''
                ]}
              />
            </Ink.Box>
          )}

          {shouldRenderStagingLocationInfo ? (
            <HighlightedInfo
              attribute='Canal'
              value={current.context.stagingName!}
            />
          ) : null}

          {shouldRenderSeparatorNewLine ? <Ink.Text> </Ink.Text> : null}

          {current.matches('ScanningDispatchingItem') && (
            <Ink.Box flexDirection='column'>
              <Ink.Chalk highlightColor bold>
                Escanee contenedor
              </Ink.Chalk>
              <Ink.Text>
                {current.context.pickedItems} de {current.context.totalItems}{' '}
                contenedores despachados
              </Ink.Text>
              <HighlightedInfo
                attribute='Contenedor sugerido'
                value={current.context.suggestedDispatchingItem?.label ?? ''}
              />
            </Ink.Box>
          )}

          {current.matches('ConfirmingDispatch') && (
            <Ink.Box flexDirection='column'>
              <IntercalatedText
                texts={['Carga en canal', 'completada.']}
                values={[current.context.dispatchingWave?.staging.name || '']}
              />
              <Ink.Text>
                {current.context.pickedItems} de {current.context.totalItems}{' '}
                contenedores despachados.
              </Ink.Text>
            </Ink.Box>
          )}
        </>

        {/* Body */}
        <>
          {current.matches('ScanningCheckDigit') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByCheckDigit />
            </Ink.Box>
          )}

          {current.matches('ScanningDispatchingItem') && (
            <Ink.Box flexDirection='column'>
              <GetItemByLabel />
            </Ink.Box>
          )}

          {current.matches('ConfirmingDispatch') && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                onClick={() => {
                  send('confirm');
                }}
                label='Finalizar proceso de despacho'
              />
            </Ink.Box>
          )}
          {current.context.error ? (
            <ErrorMessage error={current.context.error} />
          ) : null}
        </>
      </Ink.Box>
    );
  }
);

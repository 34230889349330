import { ContainerStatuses } from '../Container';
import { ContainerStatus } from './ContainerStatus';

export class ContainerStatusDeletedStatus extends ContainerStatus {
  public displayName = 'Eliminado';

  public internalName = ContainerStatuses.Deleted;

  public statusColor = '#Ffffff';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanItem } from '../../capa-4/scan-item/ScanItem';
import { ScanLocation } from '../../capa-4/scan-location/ScanLocation';
import { FatalError } from '../../core/FatalError';
import { InventoryCountingTaskMachine } from './InventoryCountingTaskMachine';

export const InventoryCountingTask: FC = withActorRef(
  InventoryCountingTaskMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const menuItems = [
    {
      key:         'assign-task',
      displayName: 'Continuar controlando.',
      event:       'continue'
    },
    {
      key:         'back',
      displayName: 'Volver al menu.',
      event:       'finishTask'
    }
  ];
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(menuItem => menuItem.key === id);
    if (item) send(item.event);
  }, []);

  return (
    <Ink.Box flexDirection='column' paddingLeft={1}>
      <Ink.Box>
        <Ink.Header>
          <Ink.Text bold>CONTEO INVENTARIO</Ink.Text>
        </Ink.Header>
      </Ink.Box>

      <Ink.Box>
        <Ink.Text bold>Conteo de inventario</Ink.Text>
      </Ink.Box>

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}

      {current.matches('ScanLocation') ? <ScanLocation /> : null}
      {current.matches('ScanContainer') ? <ScanContainer /> : null}
      {current.matches('ScanItem') ? <ScanItem /> : null}

      {current.matches('ConfirmingTask') ? (
        <>
          <Ink.Box flexDirection='column'>
            {menuItems.map(item => (
              <Ink.Button
                key={item.key}
                id={item.key}
                label={item.displayName}
                onClick={handleClick}
              />
            ))}
          </Ink.Box>
        </>
      ) : null}

      {current.matches('Initializing') ? (
        <Ink.Text>Cargando...</Ink.Text>
      ) : null}
      {current.matches('FatalError') ? (
        <FatalError
          error={current.context.error}
          onRetry={() => send('RETRY')}
        />
      ) : null}
    </Ink.Box>
  );
});

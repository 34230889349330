import Ink from '@ink';
import {
  TaskRoleInbound,
  TaskRoleInventory,
  TaskRoleOutbound
} from '@wms/domain';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  disconnect: () => void;
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:         'assign-task',
    displayName: 'Buscar Tarea',
    event:       'awaitingTask',
    payload:     {}
  },
  {
    key:         'inbound',
    displayName: 'Recepciones',
    event:       'NavigateToInboundMenu',
    payload:     {},
    taskRoles:   Object.values(TaskRoleInbound)
  },
  {
    key:         'outbound',
    displayName: 'Preparaciones',
    event:       'NavigateToOutboundMenu',
    payload:     {},
    taskRoles:   Object.values(TaskRoleOutbound)
  },
  {
    key:         'logistics',
    displayName: 'Logisticas',
    event:       'NavigateToLogisticsMenu',
    payload:     {},
    taskRoles:   Object.values(TaskRoleInventory)
  },
  {
    key:         'inventory-management',
    displayName: 'Utilidades',
    event:       'NavigateToInventoryManagementMenu',
    payload:     {}
  },
  {
    key:         'crossdocking',
    displayName: 'Crossdocking',
    event:       'NavigateToCrossdockingMenu',
    payload:     {},
    taskRoles:   []
  },
  {
    key:         'configuration',
    displayName: 'Configuraciones',
    event:       'NavigateToConfigurationMenu',
    payload:     {}
  },
  {
    key:         'disconnect',
    displayName: 'Desconectarse',
    event:       'NavigateToInboundMenu',
    payload:     {}
  }
] as const;

export const Menu: FC<MenuProps> = ({ disconnect, send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const idToTransitionMap = {
      'assign-task':          'findTask',
      inbound:                'NavigateToInboundMenu',
      outbound:               'NavigateToOutboundMenu',
      'inventory-management': 'NavigateToInventoryManagementMenu',
      logistics:              'NavigateToLogisticsMenu',
      crossdocking:           'NavigateToCrossdockingMenu',
      configuration:          'NavigateToConfigurationMenu'
    };
    if (id in idToTransitionMap) {
      send(idToTransitionMap[id], {});
    } else {
      disconnect();
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

Menu.displayName = 'Menu';

import { NoSortableSlottingContainerStatuses } from '../NoSortableSlottingContainer';
import { NoSortableSlottingContainerStatus } from './NoSortableSlottingContainerStatus';

export class NoSortableSlottingContainerPendingStatus extends NoSortableSlottingContainerStatus {
  public displayName = 'Pendiente';

  public internalName = NoSortableSlottingContainerStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderBatch } from './OrderBatch';
import { PTWLocationItem } from './PTWLocationItem';
import {
  PTWLocationProcessCompletedStatus,
  PTWLocationProcessPendingStatus,
  PTWLocationProcessProcessingStatus,
  PTWLocationProcessStatus
} from './ptw-location-process-status';

export const PTWLocationProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class PTWLocationProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [PTWLocationProcessStatuses.Pending]:    PTWLocationProcessPendingStatus,
    [PTWLocationProcessStatuses.Processing]: PTWLocationProcessProcessingStatus,
    [PTWLocationProcessStatuses.Completed]:  PTWLocationProcessCompletedStatus
  })
  status: PTWLocationProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => PTWLocationItem, 'PTWLocationProcessId')
  PTWLocationItems: PTWLocationItem[];
}

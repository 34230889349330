import { StandardUnloadingProcessStatuses } from '../StandardUnloadingProcess';
import { StandardUnloadingProcessStatus } from './StandardUnloadingProcessStatus';

export class StandardUnloadingProcessStatusPendingStatus extends StandardUnloadingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = StandardUnloadingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { PendingDispatchingPickingWaveStatuses } from '../PendingDispatchingPickingWave';
import { PendingDispatchingPickingWaveStatus } from './PendingDispatchingPickingWaveStatus';

export class PendingDispatchingPickingWavePendingStatus extends PendingDispatchingPickingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = PendingDispatchingPickingWaveStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import Ink from '@ink';
import {
  OrderType,
  OrderTypes,
  SStypes,
  StagingAuditingContainer
} from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React, { useMemo } from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { ResolveItem, ResolveMismatchedAuditingWaveMachine } from './machine';

export const ResolveMismatchedAuditingWaveComponent = withActorRef(
  ResolveMismatchedAuditingWaveMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);

  const stagingAuditingContainersByContainerIdMap = useMemo(() => {
    const mapToReturn = new Map<number, StagingAuditingContainer>();
    if (!current.context.stagingAuditingItems) {
      return mapToReturn;
    }
    current.context.stagingAuditingItems!.forEach(item => {
      if (item.stagingAuditingContainer) {
        mapToReturn.set(
          item.stagingAuditingContainerId,
          item.stagingAuditingContainer
        );
      }
      if (item.scannedStagingAuditingContainer) {
        mapToReturn.set(
          item.scannedStagingAuditingContainerId,
          item.scannedStagingAuditingContainer
        );
      }
    });
    return mapToReturn;
  }, [current.context.stagingAuditingItems!]);

  const stagingAuditingItemsByContainerIdMap = useMemo(() => {
    const mapToReturn = new Map<number, ResolveItem[]>();
    if (!current.context.stagingAuditingItems) {
      return mapToReturn;
    }
    current.context.stagingAuditingItems!.forEach(item => {
      if (item.stagingAuditingContainer) {
        const containerId = item.stagingAuditingContainerId;
        const stagingAuditingItemsForContainer =
          mapToReturn.get(containerId) ?? [];
        stagingAuditingItemsForContainer.push(item);
        mapToReturn.set(containerId, stagingAuditingItemsForContainer);
      }
      if (item.scannedStagingAuditingContainer) {
        const containerId = item.scannedStagingAuditingContainerId;
        const stagingAuditingItemsForContainer =
          mapToReturn.get(containerId) ?? [];
        stagingAuditingItemsForContainer.push(item);
        mapToReturn.set(containerId, stagingAuditingItemsForContainer);
      }
    });
    return mapToReturn;
  }, [current.context.stagingAuditingItems!]);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        Arreglo de descuadre Orden #{current.context.orderId}
      </Ink.Header>

      {current.matches('ShowingWaveDetails') && (
        <>
          <Ink.Text bold>Comenzar arreglo de descuadre</Ink.Text>
          {Array.from(stagingAuditingItemsByContainerIdMap.entries()).map(
            ([containerId, itemsOfContainer]) => (
              <AuditingItemList
                stagingAuditingItems={itemsOfContainer}
                stagingAuditingContainer={
                  stagingAuditingContainersByContainerIdMap.get(containerId)!
                }
                orderType={current.context.orderType}
                key={containerId}
              />
            )
          )}
        </>
      )}

      {current.hasTag('loading') ? <Ink.Text> Cargando...</Ink.Text> : null}
    </Ink.Box>
  );
});

function AuditingItemList({
  stagingAuditingItems,
  stagingAuditingContainer,
  orderType
}: {
  stagingAuditingItems: ResolveItem[];
  stagingAuditingContainer: StagingAuditingContainer;
  orderType: OrderType;
}) {
  return (
    <Ink.Box flexDirection='column' paddingX={2} marginTop={4}>
      <HighlightedInfo
        attribute='Contenedor'
        value={stagingAuditingContainer.container.lpn}
      />
      <HighlightedInfo
        attribute='Ubicación'
        value={stagingAuditingContainer.container.location.name}
      />
      {stagingAuditingItems.map(item => (
        <AuditingItemCard item={item} key={item.id} orderType={orderType} />
      ))}
    </Ink.Box>
  );
}

function AuditingItemCard({
  item,
  orderType
}: {
  item: ResolveItem;
  orderType: OrderType;
}) {
  const isNegativeMismatch = useMemo(() => {
    if (item.expectedQuantity > item.auditedQuantity) {
      return true;
    }
    return false;
  }, [item]);

  const isSS = useMemo(
    () => SStypes.includes(orderType.name as OrderTypes),
    [orderType]
  );

  const packageTypeText = useMemo(() => {
    if (!item.data) {
      return 'N/A';
    }
    if (item.data.packageItems === 1) {
      return 'Unitario';
    }

    if (item.data.packageItems > 1) {
      return 'Multi unidad';
    }

    return 'N/A'; // FALLBACK
  }, [item.data]);

  const cubetaText = useMemo(() => {
    if (!item.data) {
      return 'N/A';
    }
    if (item.data.mopSorterExitItem) {
      return item.data.mopSorterExitItem;
    }

    return 'N/A'; // FALLBACK
  }, [item.data]);

  return (
    <Ink.Box flexDirection='column' paddingX={6} marginTop={2}>
      <HighlightedInfo
        attribute='Contenedor escaneado'
        value={item.scannedStagingAuditingContainer?.container?.lpn || '-'}
      />
      <HighlightedInfo attribute='Etiqueta' value={item.label} />
      <HighlightedInfo
        attribute={isSS ? 'Cubeta' : 'Tipo de Paquete'}
        value={isSS ? cubetaText : packageTypeText}
      />

      <HighlightedInfo attribute='SKU' value={item.inventoryItem?.sku || '-'} />
      <HighlightedInfo
        attribute='Producto'
        value={item.inventoryItem?.product.name || '-'}
      />
      <HighlightedInfo
        attribute='Cantidad esperada'
        value={item.expectedQuantity}
        color={isNegativeMismatch ? 'redBright' : 'greenBright'}
      />
      <HighlightedInfo
        attribute='Cantidad auditada'
        value={item.auditedQuantity}
        color={isNegativeMismatch ? 'redBright' : 'greenBright'}
      />
    </Ink.Box>
  );
}

import { MovingToPreSorterProcessStatuses } from '../MovingToPreSorterProcess';
import { MovingToPreSorterProcessStatus } from './MovingToPreSorterProcessStatus';

export class MovingToPreSorterProcessStatusCancelledStatus extends MovingToPreSorterProcessStatus {
  public displayName = 'Cancelado';

  public internalName = MovingToPreSorterProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { MovePendingDispatchingItemsWaveStatuses } from '../MovePendingDispatchingItemsWave';
import { MovePendingDispatchingItemsWaveStatus } from './MovePendingDispatchingItemsWaveStatus';

export class MovePendingDispatchingItemsWavePausedStatus extends MovePendingDispatchingItemsWaveStatus {
  public displayName = 'Pausado';

  public internalName = MovePendingDispatchingItemsWaveStatuses.Paused;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { InventoryItem, Location, RestockingItem } from '@wms/domain';
import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { PalletRestockingTaskContext } from './machine';

type PalletRestockingTaskActions = MachineOptions<
  PalletRestockingTaskContext,
  any
>['actions'];

export const palletRestockingTaskActions: PalletRestockingTaskActions = {
  setRestockingItem: assign({
    restockingItem: (_ctx, evt) => evt.data.restockingItem as RestockingItem,
    location:       (_ctx, evt) => evt.data.restockingItem.targetLocation as Location,
    originLocation: (_ctx, evt) =>
      evt.data.restockingItem.originLocation as Location,
    flagOriginLocation: (_ctx, evt) =>
      !!evt.data.restockingItem.originLocation as boolean
  }),
  setOriginLocation: assign({
    originLocation: (_ctx, evt) => evt.data.location
  }),
  setOriginContainer: assign({ container: (_ctx, evt) => evt.data.container }),
  setTask:            assign({
    task: (ctx, _evt) => ctx.task
  }),
  setRestockingRuleNotMetFlag: assign({
    restockingRuleNotMetFlag: (_ctx, _evt) => true
  }),
  addToRestockedItems: assign({
    restockedQuantity: (_ctx, event) =>
      (event.data.container.inventoryItems as InventoryItem[]).reduce(
        (acc, curr) => acc + curr.quantity,
        0
      )
  }),
  assignOriginLocation: assign({
    originLocation: (_ctx, event) => event.data
  }),
  assignOriginContainer: assign({
    container: (_ctx, event) => event.data
  }),
  assignLocation: assign({
    location: (_ctx, event) => event.data
  }),
  assignError: assign({
    error: (_ctx, evt) => getErrorView(evt.data || '')
  }),
  clearError: assign({
    error: () => null
  }),
  assignMaxNumberOfContainersExceededError: assign({
    error: (_ctx, _event) =>
      'Los dos espacios asignados para contenedor en esta ubicacion se encuentran ocupados, por favor, libere uno para realizar esta operación.'
  })
};

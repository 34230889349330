import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusNotDeliveredStatus extends ReservationStatus {
  public displayName = 'No entregada';

  public internalName = ReservationStatuses.NotDelivered;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { PickItem } from './PickItem';

import { WMSTransportOrder } from './WMSTransportOrder';
import {
  SortingItemStatus,
  SortingItemStatusAnomalyStatus,
  SortingItemStatusDeliveredStatus,
  SortingItemStatusDraftStatus,
  SortingItemStatusSortingStatus
} from './sorting-item-status';

export const SortingItemStatuses = {
  Draft:     'draft',
  Sorting:   'sorting',
  Anomaly:   'anomaly',
  Delivered: 'delivered'
};

@Entity()
export class SortingItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sku' })
  sku: string;

  @Status({
    [SortingItemStatuses.Draft]:     SortingItemStatusDraftStatus,
    [SortingItemStatuses.Sorting]:   SortingItemStatusSortingStatus,
    [SortingItemStatuses.Anomaly]:   SortingItemStatusAnomalyStatus,
    [SortingItemStatuses.Delivered]: SortingItemStatusDeliveredStatus
  })
  status: SortingItemStatus;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => PickItem, { name: 'pick_item_id' })
  pickItemId: number;

  @Field({ name: 'label' })
  label: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => PickItem, 'pickItemId')
  pickItem: PickItem;

  @Knows(() => WMSTransportOrder, 'sortingItemId')
  wmsTransportOrder: WMSTransportOrder;
}

import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { NoSortableSlottingProcess } from './NoSortableSlottingProcess';
import { UnloadingContainer } from './UnloadingContainer';
import {
  NoSortableSlottingContainerPendingStatus,
  NoSortableSlottingContainerSlottedStatus,
  NoSortableSlottingContainerStatus
} from './no-sortable-slotting-container-status';

export const NoSortableSlottingContainerStatuses = {
  Pending: 'pending',
  Slotted: 'slotted'
};

@Entity()
export class NoSortableSlottingContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [NoSortableSlottingContainerStatuses.Pending]:
      NoSortableSlottingContainerPendingStatus,
    [NoSortableSlottingContainerStatuses.Slotted]:
      NoSortableSlottingContainerSlottedStatus
  })
  status: NoSortableSlottingContainerStatus;

  @ForeignKey(() => NoSortableSlottingProcess, {
    name: 'no_sortable_slotting_process_id'
  })
  noSortableSlottingProcessId: number;

  @ForeignKey(() => UnloadingContainer, { name: 'unloading_container_id' })
  unloadingContainerId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => NoSortableSlottingProcess, 'noSortableSlottingProcessId')
  noSortableSlottingProcess: NoSortableSlottingProcess;

  @Needs(() => UnloadingContainer, 'unloadingContainerId')
  unloadingContainer: UnloadingContainer;

  @Needs(() => Container, 'containerId')
  container: Container;
}

import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getFeedingToSorterLocationByName(
  defaultErrorMessage: string,
  feedingToSorterContainerId: number
): GetLocationByNameFn {
  return fetchResult(defaultErrorMessage, name =>
    api.get<Location>(
      `feeding-to-sorter-container/${feedingToSorterContainerId}/location`,
      {
        params: { name }
      }
    )
  );
}

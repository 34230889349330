import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Order } from './Order';

import { PendingDispatchingPickItem } from './PendingDispatchingPickItem';
import { PendingDispatchingPickingProcess } from './PendingDispatchingPickingProcess';
import {
  PendingDispatchingPickingWaveCancelledStatus,
  PendingDispatchingPickingWaveCompletedStatus,
  PendingDispatchingPickingWavePausedStatus,
  PendingDispatchingPickingWavePendingStatus,
  PendingDispatchingPickingWaveProcessingStatus
} from './pending-dispatching-picking-wave-status';
import { PendingDispatchingPickingWaveStatus } from './pending-dispatching-picking-wave-status/PendingDispatchingPickingWaveStatus';

export const PendingDispatchingPickingWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class PendingDispatchingPickingWave {
  @PrimaryKey()
  id: number;

  @Status({
    [PendingDispatchingPickingWaveStatuses.Pending]:
      PendingDispatchingPickingWavePendingStatus,
    [PendingDispatchingPickingWaveStatuses.Processing]:
      PendingDispatchingPickingWaveProcessingStatus,
    [PendingDispatchingPickingWaveStatuses.Completed]:
      PendingDispatchingPickingWaveCompletedStatus,
    [PendingDispatchingPickingWaveStatuses.Cancelled]:
      PendingDispatchingPickingWaveCancelledStatus,
    [PendingDispatchingPickingWaveStatuses.Paused]:
      PendingDispatchingPickingWavePausedStatus
  })
  status: PendingDispatchingPickingWaveStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => PendingDispatchingPickingProcess, {
    name: 'pending_dispatching_picking_process_id'
  })
  pendingDispatchingPickingProcessId: number;

  @Needs(() => Order, 'orderId')
  order: Order;

  @Needs(
    () => PendingDispatchingPickingProcess,
    'pendingDispatchingPickingProcessId'
  )
  pendingDispatchingPickingProcess: PendingDispatchingPickingProcess;

  @NeededBy(() => PendingDispatchingPickItem, 'pendingDispatchingPickingWaveId')
  pendingDispatchingPickItems: PendingDispatchingPickItem[];
}

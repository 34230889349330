import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByCheckDigitFn } from '../machine';

export function getUnloadingLocationByCheckDigit(
  defaultErrorMessage: string,
  unloadingProcessId: number
): GetLocationByCheckDigitFn {
  return fetchResult(defaultErrorMessage, checkDigit =>
    api.get<Location>('unloading-process/location/get-by-check-digit', {
      params: {
        unloadingProcessId,
        checkDigit
      }
    })
  );
}

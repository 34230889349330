import { PutToWallOrderStatuses } from '../PutToWallOrder';
import { PutToWallOrderStatus } from './PutToWallOrderStatus';

export class PutToWallOrderStatusPreparingStatus extends PutToWallOrderStatus {
  public displayName = 'Preparando';

  public internalName = PutToWallOrderStatuses.Preparing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

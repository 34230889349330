import { MachineOptions } from 'xstate';
import { PickPendingDispatchingTaskContext } from './machine';

type PickPendingDispatchingTaskGuards = MachineOptions<
  PickPendingDispatchingTaskContext,
  any
>['guards'];

export const pickPendingDispatchingTaskGuards: PickPendingDispatchingTaskGuards =
  {
    pendingItemsRemaining: (_ctx, event) => {
      return event.data.pendingItems > 0;
    }
  };

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { MovePendingDispatchingItemsWave } from './MovePendingDispatchingItemsWave';
import { OrderBatch } from './OrderBatch';
import {
  MovePendingDispatchingItemsProcessCompletedStatus,
  MovePendingDispatchingItemsProcessPendingStatus,
  MovePendingDispatchingItemsProcessProcessingStatus
} from './move-pending-dispatching-items-process-status';
import { MovePendingDispatchingItemsProcessStatus } from './move-pending-dispatching-items-process-status/MovePendingDispatchingItemsProcessStatus';

export const MovePendingDispatchingItemsProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class MovePendingDispatchingItemsProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [MovePendingDispatchingItemsProcessStatuses.Pending]:
      MovePendingDispatchingItemsProcessPendingStatus,
    [MovePendingDispatchingItemsProcessStatuses.Processing]:
      MovePendingDispatchingItemsProcessProcessingStatus,
    [MovePendingDispatchingItemsProcessStatuses.Completed]:
      MovePendingDispatchingItemsProcessCompletedStatus
  })
  status: MovePendingDispatchingItemsProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(
    () => MovePendingDispatchingItemsWave,
    'movePendingDispatchingItemsProcessId'
  )
  movePendingDispatchingItemsWaves: MovePendingDispatchingItemsWave[];
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { SlottingItemContext } from './machine';

type SlottingItemActionsType = MachineOptions<
  SlottingItemContext,
  any
>['actions'];

export const slottingItemActions: SlottingItemActionsType = {
  assignStagingLocation: assign({
    stagingLocation: (_ctx, event) => event.data.stagingLocation
  }),
  assignSuggestedSlottingItem: assign({
    suggestedSlottingItem: (_ctx, event) => event.data.suggestedSlottingItem
  }),
  assignContainerType: assign({
    containerType: (_ctx, event) =>
      event.data.suggestedSlottingItem.slottingContainer.container.containerType
  }),
  assignDestinyLocation: assign({
    suggestedDestinyLocation: (_ctx, event) => event.data.destinyLocation
  }),
  assignContainer: assign({
    container: (_ctx, event) =>
      event.data.suggestedSlottingItem.slottingContainer.container
  }),
  assignScannedLocation: assign({
    scannedLocation: (_ctx, event) => event.data
  }),
  assignNotValidLocationError: assign((_ctx, event) => {
    let errorMessage =
      'La ubicación escaneada no es valida o la misma se encuentra ocupada. Revise la ubicación sugerida.';
    if (event.data.isBlocked) {
      errorMessage =
        'No es posible realizar el movimiento ya que la ubicación escaneada se encuentra bloqueada';
    }
    return {
      error:           errorMessage,
      scannedLocation: null
    };
  }),
  clearCtx: assign({
    suggestedSlottingItem:    (_ctx, _event) => null,
    containerType:            (_ctx, _event) => null,
    suggestedDestinyLocation: (_ctx, _event) => null
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  assignError: assign({
    error: (_ctx, event) => getErrorView(event.data?.data || event.data)
  })
};

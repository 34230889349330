import { createMachine } from 'xstate';
import { AlertData } from '../../../../types/alerData';

export interface PopUpContext {
  alertData: AlertData | null;
}

export const DefaultPopUpContext: PopUpContext = {
  alertData: null
};
export const PopUpMachineId = 'PopUpMachineId';

export const PopUpMachine = createMachine(
  {
    id:     PopUpMachineId,
    schema: {
      context: {} as PopUpContext
    },
    initial: 'Initializing',
    states:  {
      Initializing: {
        on: {
          closePopUp: 'Finally'
        }
      },
      Finally: {
        type: 'final'
      }
    }
  },
  {}
);

import { RestockingItemStatuses } from '../RestockingItem';
import { RestockingItemStatus } from './RestockingItemStatus';

export class RestockingItemStatusCompletedStatus extends RestockingItemStatus {
  public displayName = 'Completado';

  public internalName = RestockingItemStatuses.Completed;

  public statusColor = '#64b5f6';

  public textColor = '#373636';
}

import { ReceiptStatus } from './ReceiptStatus';

export class ReceiptStatusPreConfirmedStatus extends ReceiptStatus {
  public displayName = 'Pre-Confirmado';

  public internalName = 'pre-confirmed';

  public statusColor = '#f3ecfe';

  public textColor = '#8240f2';
}

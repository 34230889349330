import { Container } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { sendParent } from 'xstate/lib/actions';

export interface EnterLpnOrLocationLabelContext {
  lpnOrLocationLabel: string;
  invalidLpns: string[];
  invalidLocationLabels: string[];
  invalidMessage: string;
  hint: string;
  error: string | null;
}

export const DefaultEnterLpnOrLocationLabelContext: EnterLpnOrLocationLabelContext =
  {
    lpnOrLocationLabel:    '',
    invalidLpns:           [],
    invalidLocationLabels: [],
    invalidMessage:        'Etiqueta de contenedor o de ubicación inválida',
    hint:                  'Escanee etiqueta de contenedor o de ubicación:',
    error:                 null
  };

export const EnterLpnOrLocationLabelMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFEB2AXMAnAMgB1QFkBDAYwAsBLVMAOjA22qn1QGIAbAgZVONRoQA2gAYAuolB4A9rErpK01JJAAPRAEYATAHZaAZg0AWABwaAbCZ0BOE0etaArABoQAT00b9tR2Y0jHc30jfR0dYIBfCNc0TFwCEgpqOgY45lY2WABXACMAW3lWUQkkEBk5BSUVdQRzc1pTIy0TSyMbLQ1rI1cPBHtvNsHHfRMtc07HKJjGeKIyKhp6GfSCTNyC9CKNEqlZeUVlUpqnevNAhyNtS3OXd0RdEwaRIJEx-Qe2qOiQVGkIOBUsWwrESCzAKnK+yqR00j0azVa7U6+nMPXu9REfgC+kcGh05metimICBs1BySWaVQLAIEL2lUOoBqth8Jkctku9lMjh0WjRCAeTyC-h0uM6Og0xNJIPmFK4qG463kmAgdIqB2qiEcuNoIkMIlMXW1YX5uj0GkC+vMOhEzx0RnMXwiQA */
  createMachine<EnterLpnOrLocationLabelContext>(
    {
      id:                         'EnterLpnOrLocationLabelMachine',
      initial:                    'enteringLpnOrLocationLabel',
      predictableActionArguments: true,
      states:                     {
        enteringLpnOrLocationLabel: {
          on: {
            lpnOrLocationLabelScanned: {
              actions: [
                assign({
                  lpnOrLocationLabel: (_ctx, event) =>
                    event.data.lpnOrLocationLabel
                }),
                'clearParentError',
                'clearError'
              ]
            },
            submitLpnOrLocationLabel: [
              {
                cond:   'lpnIsValid',
                target: 'lpnOrLocationLabelSubmitted'
              },
              {
                cond:   'locationLabelIsValid',
                target: 'lpnOrLocationLabelSubmitted'
              },
              {
                target:  'enteringLpnOrLocationLabel',
                actions: 'showErrorAndCleanInput'
              }
            ]
          }
        },
        lpnOrLocationLabelSubmitted: {
          type: 'final',
          data: (ctx, _event) => ({
            lpnOrLocationLabel: ctx.lpnOrLocationLabel
          })
        }
      }
    },
    {
      guards: {
        lpnIsValid: (ctx, _event) => {
          return !(
            ctx.invalidLpns.includes(ctx.lpnOrLocationLabel as string) ||
            !Container.isValidLpn(ctx.lpnOrLocationLabel)
          );
        },
        locationLabelIsValid: (ctx, _event) => {
          return !ctx.invalidLocationLabels.includes(
            ctx.lpnOrLocationLabel as string
          );
        }
      },
      actions: {
        showErrorAndCleanInput: assign({
          error:              (ctx, _event) => ctx.invalidMessage,
          lpnOrLocationLabel: (_, __) => ''
        }),
        clearParentError: sendParent('clearError'),
        clearError:       assign({ error: null })
      }
    }
  );

import { NoveltyUnloadingContainerV2Statuses } from '../NoveltyUnloadingContainerV2';
import { NoveltyUnloadingContainerV2Status } from './NoveltyUnloadingContainerV2Status';

export class NoveltyUnloadingContainerV2StatusOpenStatus extends NoveltyUnloadingContainerV2Status {
  public displayName = 'Abierto';

  public internalName = NoveltyUnloadingContainerV2Statuses.Open;

  public statusColor = '#Ffffff';
}

import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import {
  CustomFetchResultError,
  fetchResult
} from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getRequestedLocationByName(
  defaultErrorMessage: string,
  nameMismatchErrorMessage: string,
  requestedLocationName: string
): GetLocationByNameFn {
  return fetchResult(defaultErrorMessage, name => {
    if (name === requestedLocationName) {
      return api.get<Location>('location/name', {
        params: { name }
      });
    } else {
      throw new CustomFetchResultError(nameMismatchErrorMessage);
    }
  });
}

import { FeedingToSorterProcessStatuses } from '../FeedingToSorterProcess';
import { FeedingToSorterProcessStatus } from './FeedingToSorterProcessStatus';

export class FeedingToSorterProcessStatusCancelledStatus extends FeedingToSorterProcessStatus {
  public displayName = 'Cancelado';

  public internalName = FeedingToSorterProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

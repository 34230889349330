import { InventoryAdjustItemStatuses } from '../InventoryAdjustItem';
import { InventoryAdjustItemStatus } from './InventoryAdjustItemStatus';

export class InventoryAdjustItemPendingStatus extends InventoryAdjustItemStatus {
  public displayName = 'Pendiente';

  public internalName = InventoryAdjustItemStatuses.Pending;

  public statusColor = '#f6f6f6';

  public textColor = '#808080';
}

import { TaskStatuses } from '@wms/domain/.';
import { api } from '../../../../api/utils/axios-instance';

export const manualRestockingServices = {
  fetchLocationAttribute: async ctx => {
    const { data } = await api.get('location-attribute/from-location', {
      params: {
        locationId: ctx.targetLocation.id,
        name:       'operationType'
      }
    });
    return {
      locationAttribute: data
    };
  },
  fetchRestockingItem: async ctx => {
    const { data } = await api.get('restocking-item/pending', {
      params: {
        targetLocationId:  ctx.targetLocation.id,
        originLocationId:  ctx.originLocation.id,
        restockedQuantity: ctx.restockedQuantity,
        productId:         ctx.productId,
        containerId:       ctx.container.id
      }
    });
    return {
      restockingItem: data
    };
  },
  createRestockingItem: async ctx => {
    const { data } = await api.post('restocking-item/create-manual', {
      originLocationId:  ctx.originLocation.id,
      targetLocationId:  ctx.targetLocation.id,
      productId:         ctx.productId,
      restockedQuantity: ctx.restockedQuantity,
      containerId:       ctx.container.id
    });
    return {
      restockingItem: data
    };
  },
  moveContainer: async ctx => {
    const { data } = await api.post('container/place-in-new-location', {
      containerId:  ctx.container.id,
      locationName: ctx.targetLocation.name,
      taskId:       null
    });
    return {
      container: data
    };
  },
  checkRestockingLevel: async ctx => {
    const { data } = await api.get('location/min-restocking-level', {
      params: {
        locationId:         ctx.targetLocation.id,
        productId:          ctx.productId,
        restockingQuantity: ctx.restockedQuantity
      }
    });
    return {
      validation: data
    };
  },
  completeRestockingTask: async (ctx, _evt) => {
    const { data } = await api.post(
      `/restocking-item/${ctx.restockingItem.id}/update-restocking`,
      {
        restockedQuantity: ctx.restockedQuantity,
        status:            TaskStatuses.Completed
      }
    );
    return {
      task: data
    };
  },
  checkIfCanMoveToLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `/location/${ctx.targetLocation.id}/validate-pick-tower`
    );
    return {
      task: data
    };
  },
  checkContainerHasStandardUnloadingContainer: async ctx => {
    const { data } = await api.get(`task/container/${ctx.container.id}`);
    return data;
  }
};

import { NoveltyUnloadingPackageStatuses } from '../NoveltyUnloadingPackage';
import { NoveltyUnloadingPackageStatus } from './NoveltyUnloadingPackageStatus';

export class NoveltyUnloadingPackageStatusCompletedStatus extends NoveltyUnloadingPackageStatus {
  public displayName = 'Completado';

  public internalName = NoveltyUnloadingPackageStatuses.Completed;

  public statusColor = '#Ffffff';
}

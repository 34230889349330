import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusAuditedStatus extends OrderStatus {
  public displayName = 'Auditado';

  public internalName = OrderStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

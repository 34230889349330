import { NoSortableSlottingProcessStatuses } from '../NoSortableSlottingProcess';
import { NoSortableSlottingProcessStatus } from './NoSortableSlottingProcessStatus';

export class NoSortableSlottingProcessStatusCompletedStatus extends NoSortableSlottingProcessStatus {
  public displayName = 'Completado';

  public internalName = NoSortableSlottingProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

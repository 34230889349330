import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { InventoryAdjustWaveMachine } from './machine';

export const InventoryAdjustWaveComponent = withActorRef(
  InventoryAdjustWaveMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box paddingX={1}>
      <Ink.Header>
        <Ink.Text>
          Ola de Ajuste de Inventario: {current.context.taskName}
        </Ink.Text>
      </Ink.Header>
      {current.matches('ScanLocation') && (
        <Ink.Box flexDirection='column'>
          <GetLocationByName />
        </Ink.Box>
      )}
      {current.matches('ScanSku') && (
        <Ink.Box flexDirection='column'>
          {current.context.actualAdjustItem?.product?.sku && (
            <HighlightedInfo
              attribute='Producto a Controlar'
              value={current.context.actualAdjustItem?.product?.sku || ''}
            />
          )}
          <ScanSKU />
          <Ink.Button
            label='Posición vacía'
            onClick={() => send('EMPTY_LOCATION')}
          />
        </Ink.Box>
      )}
      {current.matches('SelectingQuantity') && (
        <Ink.Box padding={1} flexDirection='column'>
          <EnterQuantity />
        </Ink.Box>
      )}
      {current.matches('DetailOfVerification') ? (
        <>
          <Ink.Box padding={1} flexDirection='column'>
            {current.context.mismatchItems.length > 0 ? (
              <Ink.Box padding={1} flexDirection='column'>
                <Ink.Text>Usted indicó que la ubicación</Ink.Text>
                <Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {current.context.actualAdjustItem?.location?.name || ''}
                  </Ink.Chalk>
                  &nbsp;
                  {current.context.reportedQuantity === 0
                    ? 'no contiene: '
                    : 'contiene: '}
                </Ink.Text>
                <Ink.Box padding={1} flexDirection='column'>
                  <Ink.Box flexDirection='row'>
                    <HighlightedInfo
                      attribute='SKU'
                      value={
                        (current.context.productScanned?.sku
                          ? current.context.productScanned?.sku
                          : current.context.actualAdjustItem?.product
                              ?.sku) as string
                      }
                    />
                  </Ink.Box>
                  <Ink.Box>
                    <Ink.Text>
                      <Ink.Chalk greenBright bold>
                        {
                          (current.context.productScanned?.name
                            ? current.context.productScanned?.name
                            : current.context.actualAdjustItem?.product
                                ?.name) as string
                        }
                      </Ink.Chalk>
                    </Ink.Text>
                  </Ink.Box>
                  <Ink.Box>
                    <Ink.Text>
                      <HighlightedInfo
                        attribute='Esperado'
                        value={
                          current.context.mismatchItems[0]
                            .logicalQuantity as number
                        }
                      />
                      &nbsp;
                    </Ink.Text>

                    <HighlightedInfo
                      attribute='Declarado'
                      value={current.context.reportedQuantity as number}
                    />
                  </Ink.Box>
                </Ink.Box>
                La misma no coincide con lo declarado en el sistema.
                <Ink.Text bold>
                  Desea confirmar la creación de la incidencia?
                </Ink.Text>
                <Ink.Button
                  key={3}
                  id='confirm-incident'
                  label='Confirmar Incidencia'
                  onClick={() => send('CONFIRM_INCIDENT')}
                />
              </Ink.Box>
            ) : (
              <Ink.Box padding={1} flexDirection='column'>
                <Ink.Text bold>Se realizo correctamente el control.</Ink.Text>
                {current.context.productScanned?.sku && (
                  <HighlightedInfo
                    attribute='SKU'
                    value={current.context.productScanned?.sku as string}
                  />
                )}
                <HighlightedInfo
                  attribute='En la ubicación'
                  value={
                    current.context.actualAdjustItem?.location?.name as string
                  }
                />
                <Ink.Button
                  id='confirm'
                  label='Siguiente Producto'
                  onClick={() => send('CONFIRM')}
                />
              </Ink.Box>
            )}
            {(current.context.productScanned?.sku ||
              current.context.actualAdjustItem?.product) && (
              <Ink.Button
                label='Modificar Cantidad Ingresada'
                onClick={() => send('SELECT_QUANTITY')}
              />
            )}
          </Ink.Box>
        </>
      ) : null}

      {current.matches('ConfirmIncident') && (
        <Ink.Box flexDirection='column'>
          <Ink.Text>Incidencia Confirmada.</Ink.Text>
          <Ink.Button
            label='Siguiente Producto'
            onClick={() => send('NEXT_ITEM')}
          />
        </Ink.Box>
      )}
      {current.matches('ConfirmAdjustWaveCompleted') && (
        <Ink.Box flexDirection='column'>
          <Ink.Text>Ola de ajuste de inventario completada.</Ink.Text>
          <Ink.Button label='Confirmar' onClick={() => send('CONFIRM')} />
        </Ink.Box>
      )}
      {current.hasTag('loading') && (
        <Ink.Box>
          <Ink.Text>Cargando...</Ink.Text>
        </Ink.Box>
      )}
      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </Ink.Box>
  );
});

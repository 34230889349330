import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { PrintPutToWallLabelsContext } from './machine';

type PrintPutToWallLabelsActions = MachineOptions<
  PrintPutToWallLabelsContext,
  any
>['actions'];

export const printPutToWallLabelsActions: PrintPutToWallLabelsActions = {
  assignReservationId: assign({
    reservationId: (_, event) => event.data.input,
    error:         () => ''
  }),
  clearReservationInput: assign({
    reservationId: (_, _event) => ''
  }),
  assignError: assign({
    error: (_, event) => getErrorView(event.data || event.data.data)
  }),
  assignEmptyError: assign({
    error: (_, __) => 'El campo no puede estar vacio'
  })
};

import Ink from '@ink';
import { Task } from '@wms/domain';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAutoAssignTask } from '../../api/Task';
import { RouterPaths } from '../shared/Router';
import { getErrorView } from '../shared/utils/getErrorView';
import {
  isCantAuditError,
  isSerializationError
} from '../shared/utils/isTaskError';

export interface setTaskProps {
  setTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  needAutoAssign: boolean;
  taskId?: number;
  backToMenu: any;
  machine?: any;
}

export function autoAssignRoute(): RouterPaths {
  return [{ name: 'auto-assign-task', data: null }];
}

const delay = Number.parseInt(process.env.DELAY_REFRESH as string) || 5;

export const AutoAssignTask: FC<setTaskProps> = ({
  taskId,
  setTask,
  needAutoAssign,
  backToMenu
}) => {
  const [offset, setOffset] = useState<number>(0);
  const [prioritizedTasks, prioritizedTasksStatus, selectTask] =
    useAutoAssignTask(offset, needAutoAssign, taskId);
  const [error, setError] = useState<string | null>();

  const incrementOffset = () => {
    setOffset(offsetMod => offsetMod + 1);
  };

  const resetOffset = () => {
    setOffset(0);
  };

  useEffect(() => {
    selectTask();
  }, []);

  useEffect(() => {
    const notFound =
      !prioritizedTasksStatus.loading && prioritizedTasks?.count === 0;
    const errorView = notFound
      ? 'No se encontro tarea asignable'
      : getErrorView(prioritizedTasksStatus.error);

    let autoRefresh: NodeJS.Timeout;
    const isSerializationErrorValue = isSerializationError(
      prioritizedTasksStatus.error
    );
    if (isSerializationErrorValue) {
      selectTask();
    }

    const isCantAuditErrorValue = isCantAuditError(
      prioritizedTasksStatus.error
    );
    if (isCantAuditErrorValue) {
      incrementOffset();
      selectTask();
    }

    if (errorView && !(isSerializationErrorValue || isCantAuditErrorValue)) {
      setError(errorView);
      resetOffset();
      autoRefresh = setTimeout(selectTask, Number(delay) * 1000);
    } else if (!prioritizedTasksStatus.loading && prioritizedTasks?.count > 0) {
      resetOffset();
      setTask(prioritizedTasks.rows[0]);
    }

    return () => {
      clearTimeout(autoRefresh);
    };
  }, [prioritizedTasksStatus, prioritizedTasks]);

  const retryAssign = useCallback(() => selectTask(), [selectTask]);

  return (
    <>
      {!!error && (
        <>
          <Ink.Box paddingX={1}>
            <Ink.Text color='redBright'>{error}</Ink.Text>
          </Ink.Box>
          <Ink.Box flexDirection='column' paddingX={1} paddingTop={1}>
            <Ink.Button
              label='VOLVER A BUSCAR'
              onClick={retryAssign}
              disabled={!error || !!prioritizedTasks?.count}
            />

            <Ink.Button label='ATRAS' onClick={backToMenu} />
          </Ink.Box>
        </>
      )}
    </>
  );
};
AutoAssignTask.displayName = 'AutoAssignTask';

import { PackingItemStatuses } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { PtwPackageInductionContext } from './machine';

type PtwPackageInductionGuardsType = MachineOptions<
  PtwPackageInductionContext,
  any
>['guards'];

export const ptwPackageInductionGuards: PtwPackageInductionGuardsType = {
  checkIfTheyAreAllPendingPackingItems: (ctx, _event) => {
    return (
      ctx.packingItems &&
      ctx.packingItems.every(
        packingItem =>
          packingItem.status.internalName === PackingItemStatuses.Pending
      )
    );
  }
};

import { TaskTypes } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { PackingAuditingDesignatorContext } from './machine';

type PackingAuditingDesignatorGuards = MachineOptions<
  PackingAuditingDesignatorContext,
  any
>['guards'];

export const packingAuditingDesignatorGuards: PackingAuditingDesignatorGuards =
  {
    isMiniMopAuditing: (ctx, _evt) => {
      return ctx.taskType === TaskTypes.MiniMopAuditing;
    }
  };

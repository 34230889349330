import { DispatchingItem, DispatchingWave, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { GoToOptionsEvent, MenuItemProps } from '../../core/GenericOptions';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getPendingDispatchingWaveItemByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-pendingDispatchingItem-by-label';
import { GetItemByLabelMachine } from '../../layer-4/label/get-item-by-label/machine';
import { getRequestedCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-requested-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { dispatchHDActions } from './actions';
import { dispatchHDGuards } from './guards';
import { dispatchHDServices } from './services';

export interface DispatchHDContext {
  task: Task;
  dispatchingWaveId: number;
  stagingId: number;
  stagingName: string | null;
  error: string | null;
  nonReturnPoint: boolean;
  orderId: number;
  dispatchingWave: DispatchingWave | null;

  suggestedDispatchingItem: DispatchingItem | null;
  scannedLabel: string | null;

  pickedItems: number;
  totalItems: number;

  itemsToLoad: number;
  itemsToUnload: number;
  labelsToLoad: string[];
  labelsToUnload: string[];
  partiallyDispatchedReservations: number;

  touchedEndOfLoading: boolean;
}

export const DefaultDispatchHDContext: DispatchHDContext = {
  task:              {} as Task,
  dispatchingWaveId: -1,
  stagingId:         -1,
  stagingName:       null,
  error:             null,
  nonReturnPoint:    false,

  dispatchingWave: null,

  suggestedDispatchingItem: null,
  scannedLabel:             null,

  pickedItems:                     0,
  totalItems:                      0,
  orderId:                         0,
  itemsToLoad:                     0,
  itemsToUnload:                   0,
  labelsToLoad:                    [],
  labelsToUnload:                  [],
  partiallyDispatchedReservations: 0,
  touchedEndOfLoading:             false
};

export const DispatchHDMachine = createMachine(
  {
    id:                         'DispatchHD',
    predictableActionArguments: true,
    schema:                     {
      context: {} as DispatchHDContext
    },
    initial: 'Initializing',
    states:  {
      Initializing: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchDispatchingWaveDetails',
          onDone: {
            target:  'ScanningCheckDigit',
            actions: 'assignDispatchingWaveDetails'
          },
          onError: {
            actions: 'assignError'
          }
        }
      },

      ScanningCheckDigit: {
        tags:   ['saveable'],
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Confirme Ingresando Digito de Control del Canal',
              getRequestedCheckDigit(
                'Ocurrió un error, intente más tarde.',
                'Ingrese el dígito de control requerido',
                ctx.dispatchingWave!.staging.checkDigit
              )
            ),
          onDone: {
            target:  'FetchingSuggestedDispatchingItem',
            actions: 'clearError'
          }
        }
      },

      FetchingSuggestedDispatchingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedDispatchingItem',
          onDone: [
            {
              cond:    'doesNotHaveSuggestedDispatchingItem',
              actions: ['assignItemCounts'],
              target:  'ConfirmingDispatch'
            },
            {
              target:  'ScanningDispatchingItem',
              actions: ['assignSuggestedDispatchingItem', 'assignItemCounts']
            }
          ],
          onError: {
            actions: 'assignError'
          }
        }
      },
      ScanningDispatchingItem: {
        tags:   ['saveable'],
        invoke: {
          id:  'ScanningDispatchingItem',
          src: ctx =>
            GetItemByLabelMachine(
              'Escanee etiqueta de pedido de cliente',
              getPendingDispatchingWaveItemByLabel(
                'Ocurrió un error, por favor reintente más tarde.',
                ctx.dispatchingWaveId
              )
            ),
          onDone: {
            actions: ['assignScannedLabel', 'clearError'],
            target:  'CheckingIfReservationIsUnrouted'
          },
          onError: {
            actions: 'clearError',
            target:  'ScanningDispatchingItem'
          }
        }
      },
      CheckingIfReservationIsUnrouted: {
        tags:   ['loading'],
        invoke: {
          src:     'checkIfReservationIsUnrouted',
          onDone:  'MarkingItemAsDispatched',
          onError: {
            actions: 'assignError',
            target:  'MarkingItemAsCancelled'
          }
        }
      },
      MarkingItemAsCancelled: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingItemUnloaded',
              payload: {
                label: ctx.scannedLabel
              }
            };
          },
          onDone: {
            target: 'ScanningDispatchingItem'
          },
          onError: {
            actions: 'assignError',
            target:  'ScanningDispatchingItem'
          }
        }
      },
      MarkingItemAsDispatched: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingItemDispatched',
              payload: {
                label: ctx.scannedLabel
              }
            };
          },
          onDone: {
            target: 'FetchingSuggestedDispatchingItem'
          },
          onError: {
            actions: 'assignError',
            target:  'FetchingSuggestedDispatchingItem'
          }
        }
      },
      ConfirmingDispatch: {
        tags: ['saveable'],
        on:   {
          confirm: 'MarkingWaveAsCompleted'
        },
        exit:  'clearError',
        entry: 'markAsNonReturningPoint'
      },
      MarkingWaveAsCompleted: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'DispatchingWaveCompleted',
              payload: {
                dispatchingWaveId:   ctx.dispatchingWaveId,
                touchedEndOfLoading: ctx.touchedEndOfLoading
              }
            };
          },
          onDone: {
            target: 'PartiallyDispatching'
          },
          onError: {
            actions: 'assignError'
          }
        }
      },

      PartiallyDispatching: {
        initial: 'FetchingPartiallyDispatchedReservation',
        states:  {
          FetchingPartiallyDispatchedReservation: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchPartiallyDispatchedReservation',
              onDone: [
                {
                  cond:    'hasPartiallyDispatchedReservation',
                  actions: 'assignPartiallyDispatchedReservation',
                  target:  'CreatePartiallyDispatchIncident'
                },
                {
                  target: 'FinishedPartiallyDispatch'
                }
              ],
              onError: {
                actions: 'assignError'
              }
            }
          },
          CreatePartiallyDispatchIncident: {
            invoke: {
              src:    'createPartiallyDispatchIncident',
              onDone: {
                target: 'AskingToUnloadOrLoad'
              }
            }
          },
          AskingToUnloadOrLoad: {
            on: {
              unload: 'FinishedPartiallyDispatch'
            }
          },
          // UnloadingPartiallyDispatchedReservations: {
          //   initial: 'FetchingSuggestedDispatchingItemToUnload',
          //   states:  {
          //     FetchingSuggestedDispatchingItemToUnload: {
          //       tags:   ['loading'],
          //       invoke: {
          //         src:    'fetchSuggestedDispatchingItemToUnload',
          //         onDone: [
          //           {
          //             cond:   'doesNotHaveSuggestedDispatchingItem',
          //             target: 'FinishedUnloading'
          //           },
          //           {
          //             target:  'ScanningDispatchingItemToUnload',
          //             actions: ['assignSuggestedDispatchingItem']
          //           }
          //         ],
          //         onError: {
          //           actions: 'assignError'
          //         }
          //       }
          //     },
          //     ScanningDispatchingItemToUnload: {
          //       tags:   ['label'],
          //       invoke: {
          //         id:  'ScanningDispatchingItemToUnload',
          //         src: ctx =>
          //           GetItemByLabelMachine(
          //             'Escanee etiqueta de pedido de cliente',
          //             getDispachedDispatchingWaveItemByLabel(
          //               'Ocurrió un error, por favor reintente más tarde.',
          //               ctx.dispatchingWaveId
          //             )
          //           ),
          //         onDone: {
          //           actions: 'assignScannedLabel',
          //           target:  'MarkingItemAsUnloaded'
          //         }
          //       }
          //     },
          //     MarkingItemAsUnloaded: {
          //       tags:   ['loading'],
          //       invoke: {
          //         id:   SendToParentMachine.id,
          //         src:  SendToParentMachine,
          //         data: ctx => {
          //           return {
          //             task:    ctx.task,
          //             type:    'DispatchingItemUnloaded',
          //             payload: {
          //               label: ctx.scannedLabel
          //             }
          //           };
          //         },
          //         onDone: {
          //           target: 'FetchingSuggestedDispatchingItemToUnload'
          //         },
          //         onError: {
          //           target:  'ScanningDispatchingItemToUnload',
          //           actions: 'assignError'
          //         }
          //       }
          //     },
          //     FinishedUnloading: {
          //       type: 'final'
          //     }
          //   },
          //   onDone: {
          //     target: 'FetchingPartiallyDispatchedReservation'
          //   }
          // },
          FinishedPartiallyDispatch: {
            type: 'final'
          }
        },
        onDone: 'Done'
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      partiallyDispatch: {
        actions: 'assignTouchedEndOfLoading',
        target:  'MarkingWaveAsCompleted'
      }
    }
  },
  {
    guards:  dispatchHDGuards,
    actions: {
      ...dispatchHDActions,
      triggerMenuScreen: (ctx, { triggerMenuScreen }: GoToOptionsEvent) => {
        const options: MenuItemProps[] = [];
        // if (!ctx.nonReturnPoint) {
        //   options.push({
        //     label:   'Fin de Carga',
        //     onClick: () => send('partiallyDispatch')
        //   });
        // }
        triggerMenuScreen(options);
      }
    },
    services: dispatchHDServices
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { Location } from './Location';
import { Product } from './Product';
import { Task } from './Task';
import {
  RestockingItemStatus,
  RestockingItemStatusCancelledStatus,
  RestockingItemStatusCompletedStatus,
  RestockingItemStatusPendingStatus
} from './restocking-item-status';
import { RestockingItemStatusProcessingStatus } from './restocking-item-status/RestockingItemStatusProcessingStatus';

export const RestockingItemStatuses = {
  Pending:    'pending',
  Completed:  'completed',
  Processing: 'processing',
  Cancelled:  'cancelled'
};

export const RestockingItemTypes = {
  Container: 'container',
  Bulk:      'bulk'
} as const;

export const RestockingItemActiveStatuses = [
  RestockingItemStatuses.Pending,
  RestockingItemStatuses.Processing
];

@Entity()
export class RestockingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [RestockingItemStatuses.Pending]:    RestockingItemStatusPendingStatus,
    [RestockingItemStatuses.Completed]:  RestockingItemStatusCompletedStatus,
    [RestockingItemStatuses.Cancelled]:  RestockingItemStatusCancelledStatus,
    [RestockingItemStatuses.Processing]: RestockingItemStatusProcessingStatus
  })
  status: RestockingItemStatus;

  @Field({ name: 'type' })
  type: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'restocked_quantity' })
  restockedQuantity: number;

  @ForeignKey(() => Location, { name: 'origin_location_id' })
  originLocationId: number;

  @Knows(() => Location, 'originLocationId')
  originLocation: Location;

  @ForeignKey(() => Location, { name: 'target_location_id' })
  targetLocationId: number;

  @Needs(() => Location, 'targetLocationId')
  targetLocation: Location;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Needs(() => Product, 'productId')
  product: Product;

  @ForeignKey(() => Task, { name: 'task_id' })
  taskId: number;

  @Knows(() => Task, 'taskId')
  task: Task;

  @ForeignKey(() => RestockingItem, { name: 'on_finish_launch_item_id' })
  onFinishLaunchItemId: number;

  @Knows(() => RestockingItem, 'onFinishLaunchItemId')
  onFinishLaunchItem: RestockingItem;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Knows(() => Container, 'containerId')
  container: Container;
}

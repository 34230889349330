import { NoSortableSlottingProcessStatuses } from '../NoSortableSlottingProcess';
import { NoSortableSlottingProcessStatus } from './NoSortableSlottingProcessStatus';

export class NoSortableSlottingProcessStatusProcessingStatus extends NoSortableSlottingProcessStatus {
  public displayName = 'Procesando';

  public internalName = NoSortableSlottingProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { LocationDetailContext } from './machine';

type locationDetailServicesType = MachineOptions<
  LocationDetailContext,
  any
>['services'];

export const locationDetailServices: locationDetailServicesType = {
  getLocationDetails: async ctx => {
    const { data } = await api.get(`location/${ctx.location!.id}/details`);
    return {
      allowMultiSku:               data.allowMultiSku,
      operationTypes:              data.operationTypes,
      items:                       data.items,
      containerCount:              data.containerCount,
      lastInventoryItemInLocation: data.lastInventoryItemInLocation,
      location:                    data.location,
      neverHadItemsInlocation:     data.neverHadItemsInlocation
    };
  }
};

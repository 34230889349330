import {
  Entity,
  Field,
  ForeignKey,
  IsAlphanumeric,
  IsNotEmpty,
  IsNumberString,
  Knows,
  PrimaryKey
} from '@skyframe/core';
import { OrderType } from './OrderType';
import { Vehicle } from './Vehicle';

export enum CourierNameTypes {
  Fravega = 'FRAVEGA'
}

export enum CourierCourierCenterCodeTypes {
  Fravega = '1'
}

@Entity()
export class Courier {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  name: string;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @IsAlphanumeric({
    message: 'No se permiten caracteres especiales'
  })
  @IsNumberString({
    message: 'Ingrese solo números'
  })
  @Field({ name: 'dni_cuit_cuil' })
  dniCuitCuil: string;

  @Field({ name: 'courier_center_code' })
  courierCenterCode: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Vehicle, 'courierId')
  vehicles: Vehicle[];

  @ForeignKey(() => OrderType, { name: 'order_type_id' })
  orderTypeId: number;

  @Knows(() => OrderType, 'orderTypeId')
  orderType: OrderType;
}

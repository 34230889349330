import { PTWLocationItemStatuses } from '../PTWLocationItem';
import { PTWLocationItemStatus } from './PTWLocationItemStatus';

export class PTWLocationItemDeliveredStatus extends PTWLocationItemStatus {
  public displayName = 'Entregado';

  public internalName = PTWLocationItemStatuses.Delivered;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { Container } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { GetContainerByLpnContext } from './machine';

type GetContainerByLpnGuards = MachineOptions<
  GetContainerByLpnContext,
  any
>['guards'];

export const getContainerByLpnGuards: GetContainerByLpnGuards = {
  isValidContainerLpn: (context, _event) =>
    Container.isValidLpn(context.containerLpn),
  isSuccess: (_context, event) => event.data.type === 'success'
};

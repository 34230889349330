import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanLocationMachine } from './ScanLocationMachine';

export const ScanLocation: FC = withActorRef(ScanLocationMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    const onSubmitLocation = name => {
      send({
        type: 'LOCATION_SCANNED',
        data: { name }
      });
    };

    const changeLocation = locationName => {
      send({
        type: 'CHANGE',
        data: { name: unmaskLocationLabel(locationName) }
      });
    };

    const onSubmitSetLevelLocation = position => {
      send({
        type: 'LOCATION_SCANNED',
        data: { position }
      });
    };

    const changeLevelLocation = position => {
      send({ type: 'CHANGE', data: { position } });
    };

    const currentLocationName =
      current.context.location?.name && current.context.location.name;

    return (
      <>
        {current.matches('AwaitingLocationScan') ? (
          <>
            <Ink.Chalk highlightColor bold>
              {current.context.hint
                ? current.context.hint
                : 'Escanee ubicación:'}
            </Ink.Chalk>

            {current.context.suggestedLocation && (
              <Ink.Text>
                UBI. SUGERIDA:&nbsp;
                <Ink.Chalk greenBright bold>
                  {current.context.suggestedLocation?.name}
                </Ink.Chalk>
              </Ink.Text>
            )}

            {current.context.requiredLocation && (
              <Ink.Text>
                UBI. REQUERIDA:{' '}
                <Ink.Chalk greenBright bold>
                  {current.context.requiredLocation?.name}
                </Ink.Chalk>
              </Ink.Text>
            )}

            <Ink.TextInput
              onChange={changeLocation}
              value={current.context.location?.name}
              onSubmit={onSubmitLocation}
            />
          </>
        ) : null}

        {current.matches('DeclaringLocationPosition') ? (
          <>
            <Ink.Text>
              Ubicación:{' '}
              {currentLocationName.slice(0, 11) +
                current.context.position +
                currentLocationName.slice(
                  currentLocationName.length - 3,
                  currentLocationName.length
                )}
            </Ink.Text>

            <Ink.Text>Indique la altura a colocar </Ink.Text>
            <Ink.TextInput
              onChange={changeLevelLocation}
              value={current.context.position}
              onSubmit={onSubmitSetLevelLocation}
            />
          </>
        ) : null}

        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}
      </>
    );
  }
);

function unmaskLocationLabel(locationName: string): string {
  const match = locationName.match(
    /^UB(?<warehouse>[A-Z]{2})(?<aisle>[0-9]{3})(?<column>[0-9]{3})(?:(?<level>(?:[0-9]{2}|XX))(?<compartment>[0-9]{2}))?$/
  );
  if (!(match && match.groups)) return locationName;

  return [
    match.groups.warehouse,
    match.groups.aisle,
    match.groups.column,
    ...(match.groups.level && match.groups.compartment
      ? [match.groups.level, match.groups.compartment]
      : [])
  ].join('-');
}

ScanLocation.displayName = 'ScanLocation';

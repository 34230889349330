import { ReservationOrderStatuses } from '../ReservationOrder';
import { ReservationOrderStatus } from './ReservationOrderStatus';

export class ReservationOrderStatusNotDispatchedStatus extends ReservationOrderStatus {
  public displayName = 'No despachado';

  public internalName = ReservationOrderStatuses.NotDispatched;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}

import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getNoSortableSlottingContainerByLpn(
  defaultErrorMessage: string,
  noSortableSlottingProcessId: number,
  currentContainerLpnToVerify?: string
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn => {
    return api.get<Container>(
      `no-sortable-slotting-process/${noSortableSlottingProcessId}/container/${lpn}`,
      {
        params: { currentContainerLpnToVerify }
      }
    );
  });
}

import { StagingAuditingContainerStatuses } from '../StagingAuditingContainer';
import { StagingAuditingContainerStatus } from './StagingAuditingContainerStatus';

export class StagingAuditingContainerAuditedStatus extends StagingAuditingContainerStatus {
  public displayName = 'Auditado';

  public internalName = StagingAuditingContainerStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

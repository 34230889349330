import { MovePendingDispatchingItemStatuses } from '../MovePendingDispatchingItem';
import { MovePendingDispatchingItemStatus } from './MovePendingDispatchingItemStatus';

export class MovePendingDispatchingItemProcessingStatus extends MovePendingDispatchingItemStatus {
  public displayName = 'Procesando';

  public internalName = MovePendingDispatchingItemStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { DispatchingItemStatuses } from '@wms/domain';
import qs from 'qs';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetItemByLabelFn, Item } from '../machine';

export function getPendingDispatchingWaveItemByLabel(
  defaultErrorMessage: string,
  dispatchingWaveId: number
): GetItemByLabelFn {
  // Item MUST have a label. Ex: {id: 1, label: string}.
  // You MUST NOT return only the label on the endpoint.

  const queryParams = {
    status: [
      DispatchingItemStatuses.Pending,
      DispatchingItemStatuses.Cancelled
    ],
    notStatusMessage: 'Esa etiqueta ya fue escaneada.'
  };
  return fetchResult(defaultErrorMessage, label =>
    api.get<Item>(
      `dispatching-wave/${dispatchingWaveId}/label/${label}/not-in-status`,
      {
        params: {
          qs: qs.stringify(queryParams)
        }
      }
    )
  );
}

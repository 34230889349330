import { MovingToSorterExitItemStatuses } from '../MovingToSorterExitItem';
import { MovingToSorterExitItemStatus } from './MovingToSorterExitItemStatus';

export class MovingToSorterExitItemStatusPendingStatus extends MovingToSorterExitItemStatus {
  public displayName = 'Pendiente';

  public internalName = MovingToSorterExitItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

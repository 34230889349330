import { Container } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetContainerByLpnFn } from '../machine';

export function getUnloadableContainerByLpn(
  defaultErrorMessage: string,
  sellerProductId: number,
  unloadingProcessId: number
): GetContainerByLpnFn {
  return fetchResult(defaultErrorMessage, lpn =>
    api.get<Container>(
      `unloading-process/get-unloadable-container-by-lpn/${lpn}`,
      {
        params: {
          unloadingProcessId,
          sellerProductId
        }
      }
    )
  );
}

import { MachineOptions } from 'xstate';
import { MissingMiniMopItemsContext } from './machine';

type MissingMiniMopItemsGuards = MachineOptions<
  MissingMiniMopItemsContext,
  any
>['guards'];

export const missingMiniMopItemsGuards: MissingMiniMopItemsGuards = {
  hasNextPickItem: (_ctx, event) => event.data.nextPickItem !== ''
};

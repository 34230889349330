import { Container, Location, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getMovingToPreSorterContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-move-to-pre-sorter-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getMovingToPreSorterLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-moving-to-pre-sorter-location-by-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { getCrossdockingSorterLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-crossdocking-sorter-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { movingToPreSorterTaskActions } from './actions';
import { movingToPreSorterTaskGuards } from './guards';
import { movingToPreSorterTaskServices } from './services';

export interface MovingToPreSorterTaskContext {
  task: Task;
  movingToPreSorterProcessId: number;
  totalContainers: number;
  movedContainers: number;
  suggestedContainerToMoveToPreSorter: Container | null;
  containerToMoveToPreSorter: Container | null;
  locationToUnload: Location | null;
}

export const DefaultMovingToPreSorterTaskContext: MovingToPreSorterTaskContext =
  {
    task:                                {} as Task,
    movingToPreSorterProcessId:          -1,
    totalContainers:                     -1,
    movedContainers:                     -1,
    suggestedContainerToMoveToPreSorter: {} as Container,
    containerToMoveToPreSorter:          {} as Container,
    locationToUnload:                    {} as Location
  };

export const MovingToPreSorterTaskMachine = createMachine(
  {
    id:                         'MovingToPreSorterTask',
    predictableActionArguments: true,
    schema:                     {
      context: {} as MovingToPreSorterTaskContext
    },
    initial: 'EnteringCheckDigitScreen',
    states:  {
      EnteringCheckDigitScreen: {
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Ingrese dígitos de control',
              getMovingToPreSorterLocationByCheckDigit(
                'No se encontro una Location con ese codigo de verificación',
                ctx.movingToPreSorterProcessId
              )
            ),
          onDone: [
            {
              target: 'FetchingContainersToMoveToPreSorter'
            }
          ]
        }
      },

      FetchingContainersToMoveToPreSorter: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchContainersToMoveToPreSorter',
          onDone: [
            {
              cond:    'hasPendingContainersToMoveToPreSorter',
              actions: ['assignContainers'],
              target:  'FetchingSuggestedContainerToMoveToPreSorter'
            },
            {
              target: 'ConfirmingMovingToPreSorterScreen'
            }
          ]
        }
      },

      FetchingSuggestedContainerToMoveToPreSorter: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedContainerToMoveToPreSorter',
          onDone: {
            actions: 'assignSuggestedContainerToMoveToPreSorter',
            target:  'ScanContainerToMoveToPreSorter'
          }
        }
      },

      ScanContainerToMoveToPreSorter: {
        invoke: {
          id:  'ScanContainerToMoveToPreSorter',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getMovingToPreSorterContainerByLpn(
                'No se encontro un contenedor a trasladar con ese LPN asociado a este proceso',
                ctx.movingToPreSorterProcessId
              )
            ),
          onDone: [
            {
              actions: ['assignContainerToMoveToPreSorter'],
              target:  'ScanLocationToUnload'
            }
          ]
        }
      },

      ScanLocationToUnload: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Confirme escaneando ubicación',
            getCrossdockingSorterLocationByName(
              'No se encontro una ubicación de CrossDock con ese nombre'
            )
          ),
          onDone: [
            {
              actions: ['assignLocationToUnload'],
              target:  'ScanContainerToConfirmMoveToPreSorter'
            }
          ]
        }
      },

      ScanContainerToConfirmMoveToPreSorter: {
        invoke: {
          id:  'ScanContainerToConfirmMoveToPreSorter',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getMovingToPreSorterContainerByLpn(
                'Contenedor no existente o no es el que se escaneo en primera instancia',
                ctx.movingToPreSorterProcessId,
                ctx.containerToMoveToPreSorter?.lpn
              )
            ),
          onDone: [
            {
              target: 'CompletingMovingContainerToPreSorter'
            }
          ]
        }
      },

      CompletingMovingContainerToPreSorter: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'MovingToPreSorterContainerMoved',
            payload: {
              processId:                    ctx.movingToPreSorterProcessId,
              containerToMoveToPreSorterId: ctx.containerToMoveToPreSorter?.id,
              locationId:                   ctx.locationToUnload?.id
            }
          }),
          onDone: {
            target: 'FetchingContainersToMoveToPreSorter'
          }
        }
      },

      ConfirmingMovingToPreSorterScreen: {
        on: {
          confirm: {
            target: 'Done'
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   movingToPreSorterTaskGuards,
    actions:  movingToPreSorterTaskActions,
    services: movingToPreSorterTaskServices
  }
);

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Appointment } from './Appointment';
import { Receipt } from './Receipt';
import { ReceiptBatchType } from './ReceiptBatchType';
import { User } from './User';
import { Warehouse } from './Warehouse';
import { ReceiptBatchPendingStatus } from './receipt-batch-status';
import { ReceiptBatchConfirmedStatus } from './receipt-batch-status/ReceiptBatchConfirmedStatusStatus';
import { ReceiptBatchPreConfirmedStatus } from './receipt-batch-status/ReceiptBatchPreConfirmedStatusStatus';
import { ReceiptBatchProcessingStatus } from './receipt-batch-status/ReceiptBatchProcessingStatusStatus';
import { ReceiptBatchStatus } from './receipt-batch-status/ReceiptBatchStatus';
import { ReceiptBatchStoredStatus } from './receipt-batch-status/ReceiptBatchStoredStatusStatus';
import { ReceiptBatchStoringStatus } from './receipt-batch-status/ReceiptBatchStoringStatusStatus';

export const ReceiptBatchStatuses = {
  Pending:      'pending',
  Processing:   'processing',
  PreConfirmed: 'pre-confirmed',
  Confirmed:    'confirmed',
  Storing:      'storing',
  Stored:       'stored'
};

@Entity()
export class ReceiptBatch {
  @PrimaryKey()
  id: number;

  @Field()
  key: string;

  @Field()
  description: string;

  @Status({
    [ReceiptBatchStatuses.Pending]:      ReceiptBatchPendingStatus,
    [ReceiptBatchStatuses.Processing]:   ReceiptBatchProcessingStatus,
    [ReceiptBatchStatuses.PreConfirmed]: ReceiptBatchPreConfirmedStatus,
    [ReceiptBatchStatuses.Confirmed]:    ReceiptBatchConfirmedStatus,
    [ReceiptBatchStatuses.Storing]:      ReceiptBatchStoringStatus,
    [ReceiptBatchStatuses.Stored]:       ReceiptBatchStoredStatus
  })
  status: ReceiptBatchStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Receipt, 'receiptBatchId')
  receipts: Receipt[];

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Knows(() => User, 'userId')
  user: User;

  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @Needs(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @ForeignKey(() => ReceiptBatchType, { name: 'receipt_batch_type_id' })
  receiptBatchTypeId: number;

  @Needs(() => ReceiptBatchType, 'receiptBatchTypeId')
  receiptBatchType: ReceiptBatchType;
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MovingToPreSorterTaskContext } from './machine';

type MovingToPreSorterTaskServices = MachineOptions<
  MovingToPreSorterTaskContext,
  any
>['services'];

export const movingToPreSorterTaskServices: MovingToPreSorterTaskServices = {
  fetchContainersToMoveToPreSorter: async (ctx, _event) => {
    const { data } = await api.get(
      `moving-to-pre-sorter-process/${ctx.movingToPreSorterProcessId}/progress`
    );

    return {
      totalContainers: data.total,
      movedContainers: data.moved
    };
  },

  fetchSuggestedContainerToMoveToPreSorter: async (ctx, _event) => {
    const { data } = await api.get(
      `moving-to-pre-sorter-process/${ctx.movingToPreSorterProcessId}/suggested-container`
    );

    return {
      suggestedContainerToMoveToPreSorter: data
    };
  }
};

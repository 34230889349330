import { MoveToBufferItemStatuses } from '../MoveToBufferItem';
import { MoveToBufferItemStatus } from './MoveToBufferItemStatus';

export class MoveToBufferItemStatusMovedStatus extends MoveToBufferItemStatus {
  public displayName = 'Movido';

  public internalName = MoveToBufferItemStatuses.Moved;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

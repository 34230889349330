import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MoveBulkMachine } from './machine';

export const MoveBulk = withActorRef(MoveBulkMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      {/* Header */}
      <>
        <Ink.Header>Traslado de bulto</Ink.Header>
      </>

      {/* Body */}
      <>
        {current.hasTag('loading') && (
          <Ink.Box justifyContent='center' flexDirection='column' paddingX={1}>
            <Ink.Text>Cargando...</Ink.Text>
          </Ink.Box>
        )}

        {current.matches('ScanningLabel') && (
          <Ink.Box justifyContent='center' flexDirection='column' paddingX={1}>
            <Ink.Text>
              Escanee el bulto para trasladarlo a otra ubicación
            </Ink.Text>
            <Ink.Text> </Ink.Text>
            <GetItemByLabel />
          </Ink.Box>
        )}

        {current.matches('ScanningLocation') && (
          <Ink.Box justifyContent='center' flexDirection='column' paddingX={1}>
            <HighlightedInfo attribute='Bulto' value={current.context.label!} />
            <Ink.Text> </Ink.Text>
            <HighlightedInfo
              attribute='Se debe encontrar en'
              value={current.context.items![0].inventoryItem.location.name}
            />
            <GetLocationByName />
          </Ink.Box>
        )}

        {current.matches('ConfirmingScreen') && (
          <Ink.Box justifyContent='center' flexDirection='column' paddingX={1}>
            <Ink.Text>Traslado realizado</Ink.Text>
            <Ink.Text> </Ink.Text>
            <IntercalatedText
              texts={['El bulto', 'fue']}
              values={[current.context.label!]}
            />
            <IntercalatedText
              texts={['trasladadado desde la ubicacion']}
              values={[current.context.items![0].inventoryItem.location.name]}
            />
            <IntercalatedText
              texts={['a la ubicación', 'con éxito.']}
              values={[current.context.newLocation!.name]}
            />

            <Ink.Button label='Confirmar' onClick={() => send('confirm')} />
          </Ink.Box>
        )}

        {current.context.error && (
          <ErrorMessage bold error={current.context.error!!} />
        )}
      </>
    </Ink.Box>
  );
});

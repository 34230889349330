import { Container } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { escalate, sendParent } from 'xstate/lib/actions';

export interface EnterLpnContext {
  lpn: string;
  invalidLpns: string[];
  invalidLpnMessage: string;
  hint: string;
  containerTypeName?: string;
  error: string | null;
  delegateErrorHandlingToParent: boolean;
}

export const DefaultEnterLpnContext: EnterLpnContext = {
  lpn:                           '',
  invalidLpns:                   [],
  invalidLpnMessage:             'Etiqueta inválida',
  hint:                          'Escanee etiqueta de contenedor:',
  containerTypeName:             undefined,
  error:                         null,
  delegateErrorHandlingToParent: false
};

export const EnterLpnMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFEB2AXMAnAMgB1QFkBDAYwAsBLVMAOjA22qn1QGIAbAgZVONRoQA2gAYAuolB4A9rErpK01JJAAPRAEYATAHZaAZg0AWABwaAbCZ0BOE0etaArABoQAT00b9tR2Y0jHc30jfR0dYIBfCNc0TFwCEgpqOgY45lY2WABXACMAW3lWUQkkEBk5BSUVdQRzc1pTIy0TSyMbLQ1rI1cPBHtvNsHHfRMtc07HKJjGeKIyKhp6GfSCTNyC9CKNEqlZeUVlUpqnevNAhyNtS3OXd0RdEwaRIJEx-Qe2qOiQVGkIOBUsWwrESCzAKnK+yqR00j0azVa7U6+nMPXu9REfgC+kcGh05metimICBs1BySWaVQLAIEL2lUOoBqth8Jkctku9lMjh0WjRCAeTyC-h0uM6Og0xNJIPmFK4qG463kmAgdIqB2qiEcuNoIkMIlMXW1YX5uj0GkC+vMOhEzx0RnMXwiQA */
  createMachine<EnterLpnContext>(
    {
      id:                         'EnterLpnMachine',
      initial:                    'enteringLpn',
      predictableActionArguments: true,
      states:                     {
        enteringLpn: {
          on: {
            lpnScanned: {
              actions: [
                assign({
                  lpn: (_ctx, event) => event.data.lpn.trim()
                }),
                'clearParentError',
                'clearError'
              ]
            },
            submitLpn: [
              {
                cond:    'delegateErrorHandlingToParent',
                target:  'error',
                actions: ['throwError', 'clearParentError']
              },
              {
                cond:    'lpnIsInvalid',
                target:  'enteringLpn',
                actions: ['showErrorAndCleanInput', 'clearParentError']
              },
              {
                target:  'lpnSubmitted',
                actions: 'clearParentError'
              }
            ]
          }
        },
        lpnSubmitted: {
          type: 'final',
          data: (ctx, _event) => ({ lpn: ctx.lpn })
        },
        error: {
          type: 'final'
        }
      }
    },
    {
      guards: {
        lpnIsInvalid: (ctx, _event) => {
          return (
            ctx.invalidLpns.includes(ctx.lpn as string) ||
            !Container.isValidLpn(ctx.lpn, ctx.containerTypeName)
          );
        },
        delegateErrorHandlingToParent: (ctx, _event) => {
          return (
            ctx.delegateErrorHandlingToParent &&
            (ctx.invalidLpns.includes(ctx.lpn as string) ||
              !Container.isValidLpn(ctx.lpn, ctx.containerTypeName))
          );
        }
      },
      actions: {
        showErrorAndCleanInput: assign({
          error: (ctx, _event) => ctx.invalidLpnMessage,
          lpn:   (_, __) => ''
        }),
        clearParentError: sendParent('clearError'),
        clearError:       assign({ error: null }),
        throwError:       escalate((ctx, _) => ({
          data: { message: ctx.invalidLpnMessage }
        }))
      }
    }
  );

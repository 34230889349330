import { StandardUnloadingItemStatuses } from '../StandardUnloadingItem';
import { StandardUnloadingItemStatus } from './StandardUnloadingItemStatus';

export class StandardUnloadingItemStatusUnloadedStatus extends StandardUnloadingItemStatus {
  public displayName = 'Descargado';

  public internalName = StandardUnloadingItemStatuses.Unloaded;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

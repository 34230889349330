import { Container, Product } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { ScanLabelContext } from './machine';

type ScanLabelGuards = MachineOptions<ScanLabelContext, any>['guards'];

export const prefixLabelType = {
  ss: 'P',
  hd: 'F',
  mz: 'M'
};

export const scanLabelGuards: ScanLabelGuards = {
  hasRequiredLabelAndIsIncorrect: (ctx, _event) => {
    return (
      (ctx.requiredLabel !== null && ctx.requiredLabel !== ctx.label) ||
      ctx.invalidLabels.includes(ctx.label) ||
      (ctx.validLabels?.length > 0 && !ctx.validLabels?.includes(ctx.label))
    );
  },
  isLabelInvalid: (ctx, _event) => {
    const invalidLabel = !Product.isValidLabel(ctx.label);
    const invalidContainer = !Container.isValidLpn(ctx.label); // Caso classificationItem y stagingAuditingItem, puede recibir cubetas o cubeta con etiqueta de container temporal

    return ctx.allowContainers
      ? invalidLabel && invalidContainer
      : invalidLabel;
  },
  isLabelEmpty: (ctx, _event) => {
    return !!ctx.label === false || ctx.label.trim() === '';
  },
  isLabelInvalidType: (ctx, _event) => {
    return (
      !!ctx.typeOfLabel &&
      (!!ctx.label === false ||
        (!!ctx.typeOfLabel &&
          ctx.label[0] !== prefixLabelType[ctx.typeOfLabel]))
    );
  }
};

import { InventoryAdjustWaveStatuses } from '../InventoryAdjustWave';
import { InventoryAdjustWaveStatus } from './InventoryAdjustWaveStatus';

export class InventoryAdjustWaveCompletedStatus extends InventoryAdjustWaveStatus {
  public displayName = 'Completada';

  public internalName = InventoryAdjustWaveStatuses.Completed;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

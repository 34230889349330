import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { ConsolidatingContainersToStagingContext } from './machine';

type ConsolidatingContainersToStagingServices = MachineOptions<
  ConsolidatingContainersToStagingContext,
  any
>['services'];

export const consolidatingContainersToStagingServices: ConsolidatingContainersToStagingServices =
  {
    fetchConsolidatingContainersToStaging: async (ctx, _evt) => {
      const { data } = await api.get(
        '/consolidating-containers-to-staging-container/container',
        {
          params: {
            consolidatingContainersToStagingContainerId:
              ctx.consolidatingToStagingContainerId!,
            slotPosition: ctx.slotPosition!
          }
        }
      );
      return {
        consolidatingContainersToStagingcontainer: data
      };
    },
    fetchDestinyLocation: async (ctx, _evt) => {
      const { data } = await api.get(
        '/consolidating-containers-to-staging-container/:id/staging',
        {
          params: {
            id: ctx.consolidatingContainersToStagingContainer!.id
          }
        }
      );
      return {
        destinyLocation: data
      };
    },
    ConsolidationContainerConsolidated: async (ctx, _evt) => {
      await api.get(
        'consolidating-containers-to-staging-process/consolidating-containers-to-staging-dispatch-process-event',
        {
          params: {
            consolidatingContainersToStagingProcessId:
              ctx.consolidatingContainersToStagingContainer
                ?.consolidatingContainersToStagingProcessId,
            event:   'ConsolidationContainerConsolidated',
            payload: {
              locationId:                                  ctx.destinyLocation!.id,
              consolidatingContainersToStagingContainerId:
                ctx.consolidatingContainersToStagingContainer!.id,
              taskId: ctx.task.id
            }
          }
        }
      );
    }
  };

import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusPendingStatus extends ReservationItemStatus {
  public displayName = 'Pendiente';

  public internalName = ReservationItemStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

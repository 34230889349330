import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { Receipt } from './Receipt';
import { UnloadingContainer } from './UnloadingContainer';
import { UnloadingItem } from './UnloadingItem';
import {
  UnloadingProcessStatus,
  UnloadingProcessStatusCancelledStatus,
  UnloadingProcessStatusCompletedStatus,
  UnloadingProcessStatusPausedStatus,
  UnloadingProcessStatusPendingStatus,
  UnloadingProcessStatusProcessingStatus
} from './unloading-process-status';

export const UnloadingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class UnloadingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [UnloadingProcessStatuses.Pending]:    UnloadingProcessStatusPendingStatus,
    [UnloadingProcessStatuses.Processing]:
      UnloadingProcessStatusProcessingStatus,
    [UnloadingProcessStatuses.Completed]: UnloadingProcessStatusCompletedStatus,
    [UnloadingProcessStatuses.Cancelled]: UnloadingProcessStatusCancelledStatus,
    [UnloadingProcessStatuses.Paused]:    UnloadingProcessStatusPausedStatus
  })
  status: UnloadingProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => UnloadingItem, 'unloadingProcessId')
  unloadingItems: UnloadingItem[];

  @NeededBy(() => UnloadingContainer, 'unloadingProcessId')
  unloadingContainers: UnloadingContainer[];
}

import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { ReceiptItem } from './ReceiptItem';

@Entity()
export class TransferItem {
  @PrimaryKey()
  id: number;

  @Field({
    name: 'line_number'
  })
  lineNumber: number;

  @Field()
  quantity: number;

  @Field({ name: 'serial_number' })
  serialNumber: string;

  @Field({ name: 'bill_number' })
  billNumber: string;

  @Field()
  comment: string;

  @ForeignKey(() => ReceiptItem, {
    name: 'receipt_item_id'
  })
  receiptItemId: number;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import { NoveltyUnloadingPackageStatuses } from '../NoveltyUnloadingPackage';
import { NoveltyUnloadingPackageStatus } from './NoveltyUnloadingPackageStatus';

export class NoveltyUnloadingPackageStatusPendingStatus extends NoveltyUnloadingPackageStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyUnloadingPackageStatuses.Pending;

  public statusColor = '#Ffffff';
}

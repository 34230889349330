import Ink from '@ink';
import { NoveltyUnloadingContainerTypes } from '@wms/domain';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { NoveltyUnloadingMachine } from './machine';

export const NoveltyUnloadingTask = withActorRef(NoveltyUnloadingMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);
    return (
      <>
        <Ink.Box flexDirection='column' paddingX={1}>
          <Ink.Header>
            Novedad Nº {current.context.noveltyUnloadingProcessId} - Canal{' '}
            {current.context.stagingLocation?.name || 'N/A'}
          </Ink.Header>
        </Ink.Box>

        <Ink.Box flexDirection='column'>
          <>
            {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}

            {current.matches('ScanningControlDigit') && (
              <>
                <HighlightedInfo
                  attribute='Ingrese digito de control del canal'
                  value={current.context.stagingLocation?.name || ' '}
                />
                <Ink.Text />
                <ScanControlDigit />
                {current.context.error && (
                  <ErrorMessage error={current.context.error} />
                )}
              </>
            )}

            {current.matches('ScanningSKU') && (
              <>
                <Ink.Text>
                  SKU {current.context.productsUnloaded + 1} /{' '}
                  {current.context.totalProductsToUnload}
                </Ink.Text>
                <Ink.Text bold>Escanee etiqueta de SKU</Ink.Text>
                <GetProductBySKU id='ScanningSKU' />
                {current.context.error && (
                  <ErrorMessage error={current.context.error} />
                )}
              </>
            )}

            {current.matches('SelectingIfIsAptOrNotApt') && (
              <>
                <Ink.Text>
                  SKU {current.context.productsUnloaded + 1} /{' '}
                  {current.context.totalProductsToUnload}
                </Ink.Text>

                <HighlightedInfo
                  attribute='SKU'
                  value={
                    current.context.productScanned!.name +
                    '-' +
                    current.context.productScanned!.sku
                  }
                />
                <Ink.Text>Indique tratamiento del producto </Ink.Text>
                <Ink.Text> </Ink.Text>

                <Ink.Text>Seleccione si es APTO o NO APTO</Ink.Text>

                <Ink.Button
                  label='APTO'
                  onClick={() =>
                    send({
                      type: 'SELECTED',
                      data: NoveltyUnloadingContainerTypes.Apt
                    })
                  }
                />

                <Ink.Button
                  label='NO APTO'
                  onClick={() =>
                    send({
                      type: 'SELECTED',
                      data: NoveltyUnloadingContainerTypes.NotApt
                    })
                  }
                />
              </>
            )}

            {current.matches('ScanningNoveltyContainerToCreate') && (
              <>
                <Ink.Text>
                  SKU {current.context.productsUnloaded + 1} /{' '}
                  {current.context.totalProductsToUnload}
                </Ink.Text>

                <Ink.Text>
                  {' '}
                  Crear contenedor{' '}
                  {current.context.noveltyProductType ===
                  NoveltyUnloadingContainerTypes.Apt
                    ? 'APTO'
                    : 'NO APTO'}
                </Ink.Text>

                <EnterLpn />
                {current.context.error && (
                  <ErrorMessage error={current.context.error} />
                )}
              </>
            )}

            {current.matches('ConfirmingSKUInContainer') && (
              <>
                <Ink.Text>
                  SKU {current.context.productsUnloaded + 1} /{' '}
                  {current.context.totalProductsToUnload}
                </Ink.Text>

                <Ink.Text> Confirme movimiento</Ink.Text>

                <Ink.Text> </Ink.Text>
                <Ink.Text> Escanee SKU para mover a contenedor </Ink.Text>
                <HighlightedInfo
                  attribute={
                    current.context.noveltyProductType ===
                    NoveltyUnloadingContainerTypes.Apt
                      ? 'APTO'
                      : 'NO APTO'
                  }
                  value={
                    current.context.noveltyContainer?.container.lpn ||
                    current.context.noveltyContainerLpnToCreate!
                  }
                />

                <GetProductBySKU id='ConfirmingSKUInContainer' />
              </>
            )}

            {current.matches('ScanningNoveltyContainer') && (
              <>
                <Ink.Text>
                  SKU {current.context.productsUnloaded + 1} /{' '}
                  {current.context.totalProductsToUnload}
                </Ink.Text>

                <Ink.Text> Confirme contenedor de destino</Ink.Text>

                <Ink.Text> </Ink.Text>

                <HighlightedInfo
                  attribute={
                    current.context.noveltyProductType ==
                    NoveltyUnloadingContainerTypes.Apt
                      ? 'APTO'
                      : 'NO APTO'
                  }
                  value={current.context.noveltyContainer!.container.lpn}
                />

                <GetContainerByLpn />
              </>
            )}

            {current.matches('ConfirmingScreen') && (
              <>
                <Ink.Text bold>
                  Se ha terminado correctamente la descarga de novedades
                </Ink.Text>
                <Ink.Button
                  label='Finalizar Proceso'
                  onClick={() =>
                    send({
                      type: 'CONFIRMED'
                    })
                  }
                />
              </>
            )}
          </>
        </Ink.Box>
      </>
    );
  }
);

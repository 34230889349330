import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MovingToPreSorterTaskMachine } from './machine';

export const MovingToPreSorterTask = withActorRef(MovingToPreSorterTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            ORDEN DE ENTRADA #{current.context.task.receiptId}
          </Ink.Text>
        </Ink.Header>

        {current.hasTag('loading') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Cargando... </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('EnteringCheckDigitScreen') && (
          <>
            <Ink.Text bold>Ingrese Dígito de control del Muelle:</Ink.Text>
          </>
        )}

        {current.matches('ScanContainerToMoveToPreSorter') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Contenedor' +
                ' ' +
                (current.context.movedContainers + 1) +
                ' de ' +
                current.context.totalContainers}
            </Ink.Chalk>
            <Ink.Text bold>Cargue contenedor:</Ink.Text>
            <Ink.Chalk greenBright bold>
              {'Sugerido:' +
                ' ' +
                current.context.suggestedContainerToMoveToPreSorter?.lpn}
            </Ink.Chalk>
          </>
        )}

        {current.matches('ScanLocationToUnload') && (
          <>
            <Ink.Text bold>Trasladese hasta ubicación de inducción:</Ink.Text>
          </>
        )}

        {current.matches('ScanContainerToConfirmMoveToPreSorter') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Contenedor' +
                ' ' +
                (current.context.movedContainers + 1) +
                ' de ' +
                current.context.totalContainers}
            </Ink.Chalk>
            <Ink.Text bold>Descargue contenedor:</Ink.Text>
          </>
        )}

        {/* Below separator */}

        {current.matches('EnteringCheckDigitScreen') && (
          <GetLocationByCheckDigit />
        )}

        {current.matches('ScanContainerToMoveToPreSorter') && (
          <GetContainerByLpn id='ScanContainerToMoveToPreSorter' />
        )}

        {current.matches('ScanContainerToConfirmMoveToPreSorter') && (
          <GetContainerByLpn id='ScanContainerToConfirmMoveToPreSorter' />
        )}

        {current.matches('ScanLocationToUnload') && <GetLocationByName />}

        {current.matches('ConfirmingMovingToPreSorterScreen') && (
          <>
            <Ink.Text bold>
              Todos los contenedores han sido trasladados
            </Ink.Text>
            <Ink.Box
              flexDirection='row'
              justifyContent='space-between'
              paddingLeft={1}
            >
              <Ink.Button
                style={{ width: '15%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Volver'
              />
            </Ink.Box>
          </>
        )}
      </Ink.Box>
    );
  }
);

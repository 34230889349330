import { MachineOptions } from 'xstate';
import { InventoryAdjustWaveContext } from './machine';

type InventoryAdjustWaveGuards = MachineOptions<
  InventoryAdjustWaveContext,
  any
>['guards'];

export const inventoryAdjustWaveGuards: InventoryAdjustWaveGuards = {
  hasNextInventoryAdjustItem: (_ctx, evt) => {
    return !!evt.data.actualAdjustItem;
  }
};

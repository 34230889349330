import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { InventoryAdjustWaveContext } from './machine';

type InventoryAdjustWaveServices = MachineOptions<
  InventoryAdjustWaveContext,
  any
>['services'];

export const inventoryAdjustWaveServices: InventoryAdjustWaveServices = {
  fetchNextInventoryAdjustItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `inventory-adjust-wave/${ctx.inventoryAdjustWaveId}/next-inventory-adjust-item`
    );
    return {
      actualAdjustItem: data
    };
  },
  processAdjustItem: async (ctx, _evt) => {
    await api.post(
      `inventory-adjust-item/${ctx.actualAdjustItem?.id}/process-adjust-item`,
      {
        reportedQuantity: ctx.reportedQuantity as number,
        expectedQuantity: ctx.mismatchItems[0]?.logicalQuantity as number
      }
    );
    return;
  },
  completeInventoryAdjustWave: async (ctx, _evt) => {
    await api.post(
      `inventory-adjust-wave/${ctx.inventoryAdjustWaveId}/complete`
    );
    return;
  },
  mismatchInventoryAdjustment: async ctx => {
    const data = await api.post('inventory-item/mismatch-inventory-control', {
      locationId:    ctx.actualAdjustItem?.locationId,
      mismatchItems: ctx.mismatchItems
    });
    return data;
  },
  verifyStock: async ctx => {
    const sku = ctx.productScanned?.sku
      ? ctx.productScanned?.sku
      : ctx.actualAdjustItem?.product?.sku;
    const { data: mismatItems } = await api.post('inventory-item/control', {
      locationId:    ctx.actualAdjustItem?.locationId,
      itemsToAdjust: [
        {
          sku:      sku as string,
          quantity: ctx.reportedQuantity
        }
      ]
    });
    return mismatItems;
  }
};

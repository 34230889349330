import { ImpureSortableCrossdockClassificationItemStatuses } from '../ImpureSortableCrossdockClassificationItem';
import { ImpureSortableCrossdockClassificationItemStatus } from './ImpureSortableCrossdockClassificationItemStatus';

export class ImpureSortableCrossdockClassificationItemStatusClassifiedStatus extends ImpureSortableCrossdockClassificationItemStatus {
  public displayName = 'Clasificado';

  public internalName =
    ImpureSortableCrossdockClassificationItemStatuses.Classified;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

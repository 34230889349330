import { PutToWallOrderStatuses } from '../PutToWallOrder';
import { PutToWallOrderStatus } from './PutToWallOrderStatus';

export class PutToWallOrderStatusPreparedStatus extends PutToWallOrderStatus {
  public displayName = 'Preparada';

  public internalName = PutToWallOrderStatuses.Prepared;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

import { OrderTypes } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { ContainerMergeContext } from './machine';

type ContainerMergeGuardsType = MachineOptions<
  ContainerMergeContext,
  any
>['guards'];

export const ContainerMergeGuards: ContainerMergeGuardsType = {
  hasItemsInOriginContainer: (_ctx, evt) => {
    return evt.data.hasPendingItemsToMove;
  },
  orderTypeIsSS: (ctx, _evt) => {
    return ctx.orderType === OrderTypes.WMSSS;
  }
};

import { SellerProduct } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetSellerProductByCodeFn } from '../machine';

export function getFeedingToSorterSellerProductByCode(
  defaultErrorMessage: string,
  feedingToSorterContainerId: number
): GetSellerProductByCodeFn {
  return fetchResult(defaultErrorMessage, code =>
    api.get<SellerProduct>(
      `feeding-to-sorter-container/${feedingToSorterContainerId}/seller-product/${code}`
    )
  );
}

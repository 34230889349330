import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchPreparedStatus extends OrderBatchStatus {
  public displayName = 'Preparado';

  public internalName = OrderBatchStatuses.Prepared;

  public statusColor = '#e4f6f0';

  public textColor = '#e77c03';
}

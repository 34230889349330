import { MachineOptions, assign } from 'xstate';
import { PackingAuditingDesignatorContext } from './machine';

type PackingAuditingDesignatorActions = MachineOptions<
  PackingAuditingDesignatorContext,
  any
>['actions'];

export const packingAuditingDesignatorActions: PackingAuditingDesignatorActions =
  {
    assignScannedContainer: assign({
      container: (_ctx, event) => event.data
    }),
    assignScannedLocation: assign({
      location: (_ctx, event) => event.data
    }),
    assignTaskType: assign({
      taskType: (_ctx, event) => event.data
    }),
    assignErrorMessage: assign({
      errorMessage: (_ctx, event) => event.data.response.data.errorView
    }),
    clearError: assign({
      errorMessage: (_ctx, _event) => ''
    })
  };

import { MoveToBufferProcessStatuses } from '../MoveToBufferProcess';
import { MoveToBufferProcessStatus } from './MoveToBufferProcessStatus';

export class MoveToBufferProcessStatusProcessingStatus extends MoveToBufferProcessStatus {
  public displayName = 'Procesando';

  public internalName = MoveToBufferProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

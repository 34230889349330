import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { TaskRole } from './TaskRole';
import { User } from './User';

@Entity()
export class UserTaskRole {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => TaskRole, { name: 'task_role_id' })
  taskRoleId: number;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Field({ name: 'priority' })
  priority: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => User, 'userId')
  user: User;

  @Needs(() => TaskRole, 'taskRoleId')
  taskRole: TaskRole;
}

import { MachineOptions } from 'xstate';
import { GetLocationByNameContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetLocationByNameContext,
  any
>['guards'];

export const getLocationByNameGuards: GetLocationByNameGuards = {
  isValidLocationName: (context, _event) => {
    // En caso de cambiar alguna logica de acá, reemplazarlo en 'isValidLocationNameWithLevel tambien.
    const requiredLocation = context.locationSuggestionRequired;
    const locationNameToCompare = context.locationSuggestionName;
    if (requiredLocation && locationNameToCompare) {
      return context.locationName.trim() === locationNameToCompare;
    }
    return !!context.locationName;
  },
  isSuccess:         (_context, event) => event.data.type === 'success',
  isLocationInLevel: (context, _event) => {
    /* 
    Formato: <2 caracteres>-<3 digitos>-<3 digitos>-XX-<2 digitos>
    Ejemplo: 
    - MD-001-001-XX-01 // true
    - MD-002-022-02-01 // false

    Formato PR: <2 caracteres>X-<3 digitos>-<3 digitos>
    Ejemplo: 
    - PRX-001-001 // true
    - PR1-002-022// false
    */

    const locationInLevelRegex = /^[A-Z]{2}-\d{3}-\d{3}-XX-\d{2}$/;
    const locationInPrLevelRegex = /^PR[Xx]-\d{3}-\d{3}/;

    return (
      locationInLevelRegex.test(context.locationName) ||
      locationInPrLevelRegex.test(context.locationName)
    );
  },
  isLocationMasked: (ctx, _evt) => {
    /* 
    Formato: <UB><2 caracteres>-<10 digitos>
    Ejemplo: 
    - UBLA0070100104 // true
    - LA-007-010-01-04 // false
    */
    const locationMaskedRegex = /^UB[A-Z]{2}\d{10}$/;

    return locationMaskedRegex.test(ctx.locationName);
  },
  isLocationMaskedAndInLevel: (ctx, _evt) => {
    /* 
    Formato: <UB><2 caracteres><6 digitos>XX<2 digitos>
    Ejemplo: 
    - UBLA007010XX04 // true
    - UBLA0070100104 // false
    - LA-007-010-01-04 // false
    */
    const locationMaskedAndInLevelRegex = /^UB[A-Z]{2}\d{6}XX\d{2}$/;

    return locationMaskedAndInLevelRegex.test(ctx.locationName);
  },
  isValidLocationNameWithLevel: (context, _event) => {
    const location = context.locationName;
    const level = context.level || '1';
    let newLocation: string;
    if (location.includes('PR')) {
      newLocation = location.replace('X', level.toString().padStart(1, '0'));
    } else {
      newLocation = location.replace('XX', level.toString().padStart(2, '0'));
    }

    const requiredLocation = context.locationSuggestionRequired;
    const locationNameToCompare = context.locationSuggestionName;
    if (requiredLocation && locationNameToCompare) {
      return newLocation === locationNameToCompare;
    }
    return !!newLocation;
  }
};

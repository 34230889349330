import {
  Container,
  InventoryCountingPayload,
  InventoryItem,
  InventoryItemStatuses,
  Location,
  ProductTypeNames,
  Task
} from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';
import { api } from '../../../../api/utils/axios-instance';
import {
  DefaultScanContainerContext,
  ScanContainerMachine
} from '../../capa-4/scan-container/ScanContainerMachine';
import {
  DefaultScanItemContext,
  ScanItemMachine
} from '../../capa-4/scan-item/ScanItemMachine';
import {
  DefaultScanLocationContext,
  ScanLocationMachine
} from '../../capa-4/scan-location/ScanLocationMachine';
import { GoToHelpEvent } from '../../core/GenericOptions';

export interface InventoryCountingTaskContext {
  task: Task | null;
  payload: InventoryCountingPayload;

  title: string;
  hint: string;
  error: string;

  location: Location | null;
  item: InventoryItem | null;
  container: Container | null;
  status: string;
}

export const InventoryCountingTaskInitialContext = {
  task:    null,
  payload: null,

  title: '',
  hint:  '',
  error: '',

  location:  null,
  item:      null,
  container: null,
  status:    InventoryItemStatuses.Detained
};

const HELP = `Control de inventario
Escanee una ubicacion, un sku, selecione un lote e ingrese la cantidad de unidades que se encuentran en dicho lote. `;

export const InventoryCountingTaskMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgEEAbCgAgGEB7fAF3QLACdZqBZegN0iJQAB3qxcTXIyEgAHogAsAJgDsAOiUBmAIwAOAKy6AbLpUAGM5oUqANCACeiJdrNr9Ly0bMBObQrMq2kYAvqF2+PQQcDJoWHiERGoEErjoFLgAXmAyouKS0khyiLpqgSqaRkb63gqmPvqado4IeppqFpr67kq1OkpmSmEgsThsiaj8YAzMrITsOWIpBaDyLfpGasaqHtp6Ftr6TYh62mre3jpdRipKNSqGQyPxxAt5UvgyqwC0Bxq6SkZNFYzN1vGZrEcWmYSucdJVnBdvLpoaFQkA */
  createMachine<InventoryCountingTaskContext>(
    {
      id:      'InventoryCountingTaskMachine',
      initial: 'Initialize',
      states:  {
        Initialize: {
          always: [
            {
              cond:   'pickingLocation',
              target: 'ScanLocation'
            },
            { target: 'GetContainer' }
          ]
        },
        ScanLocation: {
          invoke: {
            id:   ScanLocationMachine.id,
            src:  ScanLocationMachine,
            data: ctx => ({
              ...DefaultScanLocationContext,
              requiredLocation: {
                name: ctx.payload.locationName
              },
              validLocations: [{ name: ctx.payload.locationName }]
            }),
            onDone: {
              actions: 'assignLocation',
              target:  'ScanItem'
            }
          }
        },
        GetContainer: {
          invoke: {
            src:    'findContainerByLpn',
            onDone: {
              actions: assign({
                container: (ctx, evt) => evt.data.container
              }),
              target: 'ScanContainer'
            },
            onError: {
              actions: 'assignError',
              target:  'FatalError'
            }
          }
        },
        ScanContainer: {
          invoke: {
            id:   ScanContainerMachine.id,
            src:  ScanContainerMachine,
            data: ctx => ({
              ...DefaultScanContainerContext,
              requestedContainer: ctx.container,
              validContainers:    [ctx.container]
            }),
            onDone: {
              actions: 'assignContainer',
              target:  'ScanItem'
            }
          }
        },
        ScanItem: {
          invoke: {
            id:   ScanItemMachine.id,
            src:  ScanItemMachine,
            data: ctx => ({
              ...DefaultScanItemContext,
              container:     ctx.container,
              location:      ctx.location,
              requestedItem: {
                sku: ctx.payload.sku,
                lot: {
                  lotNumber: ctx.payload.lotNumber
                },
                product: {
                  name: ctx.payload.productName
                }
              },
              skipQuantityCheck:       true,
              status:                  [InventoryItemStatuses.Detained],
              inventoryCountingItemId: ctx.payload.inventoryCountingItemId,
              usePiecesOnly:           true,
              returnBulk:              true
            }),
            onDone: {
              actions: assign({
                item: (ctx, evt) => ({
                  ...evt.data.items.find(
                    i => i.status === InventoryItemStatuses.Detained
                  ),
                  quantity:
                    evt.data.items[0].product.productType.name ===
                    ProductTypeNames.Granel
                      ? evt.data.scannedQty * 1000
                      : evt.data.scannedQty
                })
              }),
              target: 'UpdatingSAPStock'
            }
          }
        },
        UpdatingSAPStock: {
          exit:   ['deleteError'],
          invoke: {
            src:     'updateSAPStock',
            onDone:  'CompleteTask',
            onError: {
              actions: 'assignError',
              target:  'ScanItem'
            }
          }
        },

        CompleteTask: {
          exit:   ['deleteError'],
          invoke: {
            src:     'completeTask',
            onDone:  'InventoryControlled',
            onError: {
              actions: 'assignError',
              target:  'FatalError'
            }
          }
        },
        InventoryControlled: {
          type: 'final',
          data: ctx => ctx
        },
        FatalError: {
          on: {
            RETRY: [
              {
                target: 'Initialize'
              }
            ]
          }
        }
      },
      on: {
        goToHelp: {
          actions: 'triggerHelpScreen'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        pickingLocation: ctx => !ctx.payload.containerLpn
      },
      actions: {
        ...UtilityActions,
        triggerHelpScreen: (ctx, { triggerHelpScreen }: GoToHelpEvent) =>
          triggerHelpScreen(HELP)
      },
      services: {
        ...API,
        updateSAPStock: ctx => {
          return api.patch(
            `/inventory-counting-item/${ctx.payload.inventoryCountingItemId}/quantity-v0`,
            { quantity: ctx.item?.quantity, inventoryItemId: ctx.item?.id }
          );
        }
      }
    }
  );

import { StandardUnloadingContainerStatuses } from '../StandardUnloadingContainer';
import { StandardUnloadingContainerStatus } from './StandardUnloadingContainerStatus';

export class StandardUnloadingContainerStatusOpenStatus extends StandardUnloadingContainerStatus {
  public displayName = 'Abierto';

  public internalName = StandardUnloadingContainerStatuses.Open;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

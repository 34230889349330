import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { NoveltyMoveContainerToBufferMachine } from './machine';

export const NoveltyMoveContainerToBufferTask = withActorRef(
  NoveltyMoveContainerToBufferMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);
  return (
    <>
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Traslado de novedades - Contenedor{' '}
          {current.context.noveltyContainerMoveToBufferContainerLpn || 'N/A'}
        </Ink.Header>
      </Ink.Box>

      <Ink.Box flexDirection='column'>
        <>
          {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}

          {current.matches('ScanningContainer') && (
            <>
              <HighlightedInfo
                attribute='Ingrese contenedor'
                value={
                  current.context.noveltyContainerMoveToBufferContainerLpn ||
                  ' '
                }
              />
              <Ink.Text />
              <GetContainerByLpn />
              {current.context.error && (
                <ErrorMessage error={current.context.error} />
              )}
            </>
          )}

          {current.matches('ScanningTargetLocation') && (
            <>
              <HighlightedInfo
                attribute='Ubi. de destino'
                value={current.context.targetLocation?.name || ' '}
              />

              <Ink.Text />
              <GetLocationByName />
            </>
          )}

          {current.matches('ConfirmingMovement') && (
            <>
              <Ink.Text bold> Traslado completado</Ink.Text>

              <Ink.Button
                label='CONFIRMAR TRASLADO'
                onClick={() =>
                  send({
                    type: 'CONFIRMED'
                  })
                }
              />
            </>
          )}
        </>
      </Ink.Box>
    </>
  );
});

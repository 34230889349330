import { Container, Location, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getNoSortableSlottingContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-no-sortable-slotting-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getNoSortableSlottingLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-no-sortable-slotting-location-by-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { getCrossdockingStorageLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-crossdocking-storage-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { noSortableSlottingTaskActions } from './actions';
import { noSortableSlottingTaskGuards } from './guards';
import { noSortableSlottingTaskServices } from './services';

export interface NoSortableSlottingTaskContext {
  task: Task;
  noSortableSlottingProcessId: number;
  totalContainers: number;
  slottedContainers: number;
  suggestedContainerToNoSortableSlotting: Container | null;
  containerToNoSortableSlotting: Container | null;
  suggestedLocationToStore: Location | null;
  locationToStore: Location | null;
}

export const DefaultNoSortableSlottingTaskContext: NoSortableSlottingTaskContext =
  {
    task:                                   {} as Task,
    noSortableSlottingProcessId:            -1,
    totalContainers:                        -1,
    slottedContainers:                      -1,
    suggestedContainerToNoSortableSlotting: {} as Container,
    containerToNoSortableSlotting:          {} as Container,
    suggestedLocationToStore:               {} as Location,
    locationToStore:                        {} as Location
  };

export const NoSortableSlottingTaskMachine = createMachine(
  {
    id:                         'NoSortableSlottingTask',
    predictableActionArguments: true,
    schema:                     {
      context: {} as NoSortableSlottingTaskContext
    },
    initial: 'EnteringCheckDigitScreen',
    states:  {
      EnteringCheckDigitScreen: {
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: ctx =>
            GetLocationByCheckDigitMachine(
              'Ingrese dígitos de control',
              getNoSortableSlottingLocationByCheckDigit(
                'No se encontro una Location con ese codigo de verificación',
                ctx.noSortableSlottingProcessId
              )
            ),
          onDone: [
            {
              target: 'FetchingContainersToNoSortableSlotting'
            }
          ]
        }
      },

      FetchingContainersToNoSortableSlotting: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchContainersToNoSortableSlotting',
          onDone: [
            {
              cond:    'hasPendingContainersToNoSortableSlotting',
              actions: ['assignContainers'],
              target:  'FetchingSuggestedContainerToNoSortableSlotting'
            },
            {
              target: 'ConfirmingNoSortableSlottingScreen'
            }
          ]
        }
      },

      FetchingSuggestedContainerToNoSortableSlotting: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedContainerToNoSortableSlotting',
          onDone: {
            actions: 'assignSuggestedContainerToNoSortableSlotting',
            target:  'ScanContainerToNoSortableSlotting'
          }
        }
      },

      ScanContainerToNoSortableSlotting: {
        invoke: {
          id:  'ScanContainerToNoSortableSlotting',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getNoSortableSlottingContainerByLpn(
                'No se encontro un contenedor a trasladar con ese LPN asociado a este proceso',
                ctx.noSortableSlottingProcessId
              )
            ),
          onDone: [
            {
              actions: ['assignContainerToNoSortableSlotting'],
              target:  'FetchingSuggestedLocationToStore'
            }
          ]
        }
      },

      FetchingSuggestedLocationToStore: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchSuggestedLocationToStore',
          onDone: {
            actions: 'assignSuggestedLocationToStore',
            target:  'ScanLocationToStore'
          }
        }
      },

      ScanLocationToStore: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Confirme escaneando ubicación',
            getCrossdockingStorageLocationByName(
              'No se encontro una ubicación de Almacenamiento con ese nombre'
            )
          ),
          onDone: [
            {
              actions: ['assignLocationToStore'],
              target:  'ScanContainerToConfirmNoSortableSlotting'
            }
          ]
        }
      },

      ScanContainerToConfirmNoSortableSlotting: {
        invoke: {
          id:  'ScanContainerToConfirmNoSortableSlotting',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getNoSortableSlottingContainerByLpn(
                'Contenedor no existente o no es el que se escaneo en primera instancia',
                ctx.noSortableSlottingProcessId,
                ctx.containerToNoSortableSlotting?.lpn
              )
            ),
          onDone: [
            {
              target: 'CompletingContainerToNoSortableSlotting'
            }
          ]
        }
      },

      CompletingContainerToNoSortableSlotting: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'NoSortableSlottingContainerSlotted',
            payload: {
              processId:                     ctx.noSortableSlottingProcessId,
              noSortableSlottingContainerId:
                ctx.containerToNoSortableSlotting?.id,
              locationId: ctx.locationToStore?.id
            }
          }),
          onDone: {
            target: 'FetchingContainersToNoSortableSlotting'
          }
        }
      },

      ConfirmingNoSortableSlottingScreen: {
        on: {
          confirm: {
            target: 'Done'
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   noSortableSlottingTaskGuards,
    actions:  noSortableSlottingTaskActions,
    services: noSortableSlottingTaskServices
  }
);

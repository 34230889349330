import { UnloadingItemStatuses } from '../UnloadingItem';
import { UnloadingItemStatus } from './UnloadingItemStatus';

export class UnloadingItemStatusUnloadedStatus extends UnloadingItemStatus {
  public displayName = 'Descargado';

  public internalName = UnloadingItemStatuses.Unloaded;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { PendingDispatchingPickingProcessStatuses } from '../PendingDispatchingPickingProcess';
import { PendingDispatchingPickingProcessStatus } from './PendingDispatchingPickingProcessStatus';

export class PendingDispatchingPickingProcessPendingStatus extends PendingDispatchingPickingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = PendingDispatchingPickingProcessStatuses.Pending;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

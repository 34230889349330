import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';

import { Container } from './Container';
import { Location } from './Location';
import { NoveltyMoveContainerToBufferItem } from './NoveltyMoveContainerToBufferItem';
import { NoveltyUnloadingContainer } from './NoveltyUnloadingContainer';
import { NoveltyUnloadingContainerV2 } from './NoveltyUnloadingContainerV2';
import {
  NoveltyMoveContainerToBufferContainerStatus,
  NoveltyMoveContainerToBufferContainerStatusMovedStatus,
  NoveltyMoveContainerToBufferContainerStatusPendingStatus
} from './novelty-move-container-to-buffer-container-status';

export const NoveltyMoveContainerToBufferContainerStatuses = {
  Pending: 'pending',
  Moved:   'moved'
};

@Entity()
export class NoveltyMoveContainerToBufferContainer {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => Location, { name: 'origin_location_id' })
  originLocationId: number;

  @ForeignKey(() => Location, { name: 'target_sector_id' })
  targetSectorId: number;

  @ForeignKey(() => NoveltyUnloadingContainer, {
    name: 'novelty_unloading_container_v2_id'
  })
  noveltyUnloadingContainerV2Id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Needs(() => Location, 'originLocationId')
  originLocation: Location;

  @Needs(() => Location, 'targetSectorId')
  targetSector: Location;

  @Needs(() => NoveltyUnloadingContainerV2, 'noveltyUnloadingContainerV2Id')
  noveltyUnloadingContainerV2: NoveltyUnloadingContainerV2;

  @Status({
    [NoveltyMoveContainerToBufferContainerStatuses.Pending]:
      NoveltyMoveContainerToBufferContainerStatusPendingStatus,
    [NoveltyMoveContainerToBufferContainerStatuses.Moved]:
      NoveltyMoveContainerToBufferContainerStatusMovedStatus
  })
  status: NoveltyMoveContainerToBufferContainerStatus;

  @NeededBy(
    () => NoveltyMoveContainerToBufferItem,
    'noveltyMoveContainerToBufferContainerId'
  )
  noveltyMoveContainerToBufferItems: NoveltyMoveContainerToBufferItem[];
}

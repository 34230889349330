import { PTWLocationProcessStatuses } from '../PTWLocationProcess';
import { PTWLocationProcessStatus } from './PTWLocationProcessStatus';

export class PTWLocationProcessCompletedStatus extends PTWLocationProcessStatus {
  public displayName = 'Completado';

  public internalName = PTWLocationProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

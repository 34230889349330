import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { DispatchSSContext } from './machine';

type DispatchSSServices = MachineOptions<DispatchSSContext, any>['services'];

export const dispatchSSServices: DispatchSSServices = {
  fetchDispatchingWaveDetails: async (ctx, _evt) => {
    const { data } = await api.get(`dispatching-wave/${ctx.dispatchingWaveId}`);
    return {
      dispatchingWave: data
    };
  },
  fetchSuggestedDispatchingItem: async (ctx, _evt) => {
    const [nextItem, count] = await Promise.all([
      api.get(
        `dispatching-wave/${ctx.dispatchingWaveId}/next-dispatching-item`
      ),
      api.get(`dispatching-wave/${ctx.dispatchingWaveId}/pending-items-count`)
    ]);
    return {
      dispatchingItem: nextItem.data,
      counts:          count.data
    };
  },
  checkPartialDispatch: async (ctx, _evt) => {
    const { data } = await api.get(
      `dispatching-wave/${ctx.dispatchingWaveId}/staging/${ctx.stagingId}/check-partial-dispatch-ss`
    );
    return {
      isPartiallyDispatch: data.isPartiallyDispatch
    };
  },
  createPartiallyDispatchIncident: async (ctx, _evt) => {
    await api.post('dispatching-wave/create-partially-dispatched-incident', {
      orderId: ctx.task.orderId,
      userId:  ctx.task.userId
    });
  }
};

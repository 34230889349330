import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { PalletRestockingTaskMachine } from './machine';

export const PalletRestockingTask = withActorRef(PalletRestockingTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const boldStyle = { fontWeight: 'bold' };

    return (
      <>
        <Ink.Box flexDirection='column' paddingTop={1}>
          <Ink.Box>
            <Ink.Header>
              <Ink.Text bold>
                <>RESTOCKEANDO A PALLET COMPLETO #{current.context.task.id}</>
              </Ink.Text>
            </Ink.Header>
          </Ink.Box>
          <Ink.Box flexDirection='column'>
            {current.context.product?.sku && (
              <HighlightedInfo
                attribute='SKU'
                value={current.context.product?.sku}
              />
            )}
            {current.context.product?.name && (
              <HighlightedInfo
                attribute='Descripción'
                value={current.context.product?.name}
              />
            )}
            {current.context.originLocation?.name && (
              <HighlightedInfo
                attribute='Ubicación de origen'
                value={current.context.originLocation.name}
              />
            )}
            {current.context.location?.name && (
              <HighlightedInfo
                attribute='Ubicación de destino'
                value={current.context.location.name}
              />
            )}
          </Ink.Box>
          {current.matches('NoRestockingLocationSatisfiesRule') && (
            <Ink.Box flexDirection='column'>
              <div style={{ color: 'red' }}>
                <span style={boldStyle}>NO </span>se encontró ubicación de
                almacenamiento disponible para reabastecimiento a{' '}
                <span style={boldStyle}>PALLET COMPLETO</span>. Si considera
                esto un error por favor revise la{' '}
                <span style={boldStyle}>REGLA DE REABASTECIMIENTO</span> para
                este producto. Se intentará sugerir una ubicación con pallet por
                debajo de la regla.{' '}
                <span style={boldStyle}>PUEDE CONTINUAR LA TAREA</span>
              </div>

              <Ink.Button
                label='Continuar'
                onClick={() => {
                  send('CONTINUE');
                }}
              />
            </Ink.Box>
          )}
          {current.matches('ScanOriginLocationRequired') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName id='ScanOriginLocationRequired' />
            </Ink.Box>
          )}

          {current.matches('ScanOriginLocation') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName id='ScanOriginLocation' />
            </Ink.Box>
          )}

          {current.matches('ScanOriginContainer') && (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
            </Ink.Box>
          )}

          {current.matches('ScanTargetLocation') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName id='ScanTargetLocation' />
            </Ink.Box>
          )}

          {current.hasTag('loading') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Moviendo Contenedor... </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('RestockComplete') && (
            <>
              <Ink.Box flexDirection='column'>
                <IntercalatedText
                  texts={[
                    'La ubicacion ',
                    ' se ha restockeado correctamente con el contenedor '
                  ]}
                  values={[
                    `${current.context.location?.name}`,
                    current.context.container?.lpn || ''
                  ]}
                />
              </Ink.Box>
              <Ink.Button
                label='Confirmar'
                onClick={() => {
                  send('CONFIRM');
                }}
              />
            </>
          )}

          {current.matches('Error') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
              <Ink.Button
                label='Cancelar Restocking'
                onClick={() => {
                  send('CANCEL');
                }}
              />
              <Ink.Button
                label='Recalcular Ubicacion'
                onClick={() => {
                  send('RECALCULATE');
                }}
              />
            </Ink.Box>
          ) : (
            current.context.error && (
              <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            )
          )}
        </Ink.Box>
      </>
    );
  }
);

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { FetchTranportOrderByLabelContext } from './machine';

type CloseTransportOrderServices = MachineOptions<
  FetchTranportOrderByLabelContext,
  any
>['services'];

export const closeTransportOrderServices: CloseTransportOrderServices = {
  closingTransportOrder: context => {
    return api.post('sorter/tote-manual-notify', {
      tote_erpwms:               context.transportOrder!.id,
      tote_barcode:              context.transportOrder!.barcode.toString(),
      tote_destination_executed: context.transportOrder!.validFinalLocations[0]
    });
  }
};

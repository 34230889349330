import Ink, { ButtonProps } from '@ink';
import { User } from '@wms/domain/.';
import React, { FC, useCallback, useMemo } from 'react';
import { AnyInterpreter, AnyState } from 'xstate';
import { MenuItem } from '../../menu/types';
import { useCanAccess } from '../../menu/useCanAccess';
import { useRootMachine } from '../../shared/Machine';

function getLastChildInterpreter(
  interpreter: AnyInterpreter,
  condition: (interpreter: AnyInterpreter) => boolean,
  lastFound: AnyInterpreter | null
): AnyInterpreter | null {
  if (interpreter.children) {
    const [child] = Array.from(interpreter.children.values()) as [
      AnyInterpreter | void
    ];

    return child
      ? condition(child)
        ? getLastChildInterpreter(child, condition, child)
        : getLastChildInterpreter(child, condition, lastFound)
      : lastFound;
  } else {
    return lastFound;
  }
}

function hasHelpTransition(interpreter: AnyInterpreter): boolean {
  return !!interpreter?.machine?.definition?.on?.['goToHelp'];
}

function getLastChildInterpreterWithHelp(
  interpreter: AnyInterpreter
): AnyInterpreter | null {
  return getLastChildInterpreter(interpreter, hasHelpTransition, null);
}

function hasOptionsTransition(interpreter: AnyInterpreter): boolean {
  return !!interpreter?.machine?.definition?.on?.['goToOptions'];
}

function getLastChildInterpreterWithOptions(
  interpreter: AnyInterpreter
): AnyInterpreter | null {
  return getLastChildInterpreter(interpreter, hasOptionsTransition, null);
}

export type TriggerHelpScreen = (helpText: string) => void;

export interface GoToHelpEvent {
  type: 'goToHelp';
  triggerHelpScreen: TriggerHelpScreen;
}

export interface MenuItemProps extends ButtonProps {}

export type TriggerMenuScreen = (
  menuItems: Array<MenuItem | MenuItemProps>
) => void;

export interface GoToOptionsEvent {
  type: 'goToOptions';
  send: AnyInterpreter['send'];
  state: AnyState;
  triggerMenuScreen: TriggerMenuScreen;
  user?: User;
}

export const GenericOptions: FC = () => {
  const canAccess = useCanAccess();
  const rootRef = useRootMachine();
  const helpTaskRef = getLastChildInterpreterWithHelp(rootRef.interpreter);
  const optionsTaskRef = getLastChildInterpreterWithOptions(
    rootRef.interpreter
  );

  const goBack = useCallback(
    () => rootRef.send('quitMenuScreen'),
    [rootRef.send]
  );

  /* Help Screen */

  const triggerHelpScreen = useCallback<TriggerHelpScreen>(
    helpText => rootRef.send('triggerHelpScreen', { data: helpText }),
    [rootRef.send]
  );

  const goToHelp = useCallback(() => {
    if (helpTaskRef) {
      const payload = { triggerHelpScreen } as Omit<GoToHelpEvent, 'type'>;
      helpTaskRef.send('goToHelp', payload);
    }
  }, [helpTaskRef, triggerHelpScreen]);

  /* Menu Screen */

  const goToMenu = useCallback(() => rootRef.send('backToMenu'), [rootRef]);

  const defaultMenuItems = useMemo<MenuItemProps[]>(
    () => [
      {
        label:   'Volver al menú',
        id:      'back-to-menu',
        onClick: goToMenu
      },
      {
        label:   'Atrás',
        id:      'back',
        onClick: goBack
      }
    ],
    [goToMenu, goBack]
  );

  const triggerMenuScreen = useCallback<TriggerMenuScreen>(
    menuItems =>
      rootRef.send('triggerMenuScreen', {
        data: [
          ...menuItems.filter(menuItem => canAccess(menuItem as MenuItem)),
          ...defaultMenuItems
        ]
      }),
    [rootRef.send, defaultMenuItems]
  );

  const goToOptions = useCallback(() => {
    if (optionsTaskRef && !optionsTaskRef.getSnapshot().hasTag('loading')) {
      // Si queremos comunicar con el parent task:
      // currentTaskRef.parent.send('...');

      const sendAndCloseMenu = (() => {
        let goBackCalled = false;

        return (...args: Parameters<AnyInterpreter['send']>) => {
          optionsTaskRef.send(...args);

          if (!goBackCalled) {
            goBack();
            goBackCalled = true;
          }
        };
      })();

      const payload = {
        send:  sendAndCloseMenu,
        state: optionsTaskRef.state,
        triggerMenuScreen
      } as Omit<GoToOptionsEvent, 'type'>;

      optionsTaskRef.send('goToOptions', payload);
    } else {
      rootRef.send('triggerMenuScreen', { data: defaultMenuItems });
    }
  }, [optionsTaskRef, goBack, triggerMenuScreen, rootRef, defaultMenuItems]);

  return (
    <>
      {rootRef.state.hasTag('withOptions') ? (
        <Ink.Box flexDirection='row' paddingX={2}>
          {helpTaskRef ? (
            <Ink.Box paddingRight={2}>
              <Ink.Button id='help' label='AYUDA' onClick={goToHelp} />
            </Ink.Box>
          ) : null}

          <Ink.Button id='options' label='OPCIONES' onClick={goToOptions} />
        </Ink.Box>
      ) : null}

      {rootRef.state.matches('inTaskSearcher') ? (
        <Ink.Box flexDirection='row' paddingX={2}>
          <Ink.Button id='back' label='VOLVER' onClick={goToMenu} />
        </Ink.Box>
      ) : null}
    </>
  );
};

GenericOptions.displayName = 'GenericOptions';

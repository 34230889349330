import { ReservationPendingItemStatuses } from '../ReservationPendingItem';
import { ReservationPendingItemStatus } from './ReservationPendingItemStatus';

export class ReservationPendingItemCompletedStatus extends ReservationPendingItemStatus {
  public displayName = 'Completado';

  public internalName = ReservationPendingItemStatuses.Completed;

  public statusColor = '#Ffffff';
}

import { PendingDispatchingPickingWaveStatuses } from '../PendingDispatchingPickingWave';
import { PendingDispatchingPickingWaveStatus } from './PendingDispatchingPickingWaveStatus';

export class PendingDispatchingPickingWaveCancelledStatus extends PendingDispatchingPickingWaveStatus {
  public displayName = 'Cancelado';

  public internalName = PendingDispatchingPickingWaveStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

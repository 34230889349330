import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { CloseNoveltyContainerMachine } from './machine';

export const CloseNoveltyContainerJob = withActorRef(
  CloseNoveltyContainerMachine
)(({ actorRef }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [current, send] = useActor(actorRef);

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      {/* Cargando */}
      <>
        {current.hasTag('loading') ? (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Cargando...</Ink.Text>
          </Ink.Box>
        ) : (
          <Ink.Header>Cierre de Contenedores Novedades</Ink.Header>
        )}
      </>
      <>
        {current.matches('ScanningContainer') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Escanee contenedor de novedades a cerrar</Ink.Text>
            <GetContainerByLpn />
            {current.context.error && (
              <ErrorMessage bold error={current.context.error} />
            )}
          </Ink.Box>
        )}

        {current.matches('ShowingContainerInfo') && (
          <Ink.Box flexDirection='column'>
            <HighlightedInfo
              attribute='Contenedor a cerrar'
              value={current.context.containerScanned!.lpn}
            />
            <Ink.Text> </Ink.Text>
            <Ink.Text bold> Items en el contenedor: </Ink.Text>
            <Ink.Box flexDirection='column'>
              {current.context.noveltyUnloadingContainer!.noveltyUnloadingItemsV2.map(
                (item, index) => (
                  <Ink.Text key={index}>
                    - SKU {item.sku} | Recepcion Nº{' '}
                    {item.noveltyUnloadingProcess.receiptId}
                  </Ink.Text>
                )
              )}
            </Ink.Box>

            <Ink.Button
              label='CERRAR CONTENEDOR'
              onClick={() => send('CloseNoveltyContainer')}
            />
          </Ink.Box>
        )}

        {current.matches('ContainerClosed') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold color='greenBright'>
              Contenedor {current.context.containerScanned!.lpn} cerrado
              satisfactoriamente
            </Ink.Text>
            <Ink.Button
              label='Cerrar otro contenedor'
              onClick={() => send('reset')}
            />
          </Ink.Box>
        )}
      </>
    </Ink.Box>
  );
});

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Reservation, TargetOrderTypes } from './Reservation';
import { ReservationItem } from './ReservationItem';
import { ReservationOrder } from './ReservationOrder';

@Entity()
export class ReservationIdentifier {
  @PrimaryKey()
  id: number;

  @Field({ name: 'external_reference_id' })
  externalReferenceId: string;

  @Field({ name: 'delivery_code' })
  deliveryCode: string;

  @Field({ name: 'target_order_type' })
  targetOrderType: TargetOrderTypes;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ReservationOrder, 'reservationIdentifierId')
  reservationOrder: ReservationOrder;

  @Knows(() => ReservationItem, 'reservationIdentifierId')
  reservationItems: ReservationItem[];
}

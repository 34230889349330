import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Location } from './Location';
import { Warehouse } from './Warehouse';

@Entity()
export class SorterExitBranch {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Knows(() => Location, 'locationId')
  location: Location;
}

import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';

import { NoveltyMoveContainerToBufferContainer } from './NoveltyMoveContainerToBufferContainer';
import { NoveltyMoveContainerToBufferProcess } from './NoveltyMoveContainerToBufferProcess';
import {
  NoveltyMoveContainerToBufferItemStatus,
  NoveltyMoveContainerToBufferItemStatusMovedStatus,
  NoveltyMoveContainerToBufferItemStatusMovingStatus
} from './novelty-move-container-to-buffer-item-status';
import { NoveltyUnloadingItemV2 } from './';

export const NoveltyMoveContainerToBufferItemStatuses = {
  Moving: 'moving',
  Moved:  'moved'
};

@Entity()
export class NoveltyMoveContainerToBufferItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => NoveltyMoveContainerToBufferContainer, {
    name: 'novelty_move_container_to_buffer_container_id'
  })
  noveltyMoveContainerToBufferContainerId: number;

  @ForeignKey(() => NoveltyMoveContainerToBufferProcess, {
    name: 'novelty_move_container_to_buffer_process_id'
  })
  noveltyMoveContainerToBufferProcessId: number;

  @ForeignKey(() => NoveltyUnloadingItemV2, {
    name: 'novelty_unloading_item_v2_id'
  })
  noveltyUnloadingItemV2Id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(
    () => NoveltyMoveContainerToBufferContainer,
    'noveltyMoveContainerToBufferContainerId'
  )
  noveltyMoveContainerToBufferContainer: NoveltyMoveContainerToBufferContainer;

  @Needs(
    () => NoveltyMoveContainerToBufferProcess,
    'noveltyMoveContainerToBufferProcessId'
  )
  noveltyMoveContainerToBufferProcess: NoveltyMoveContainerToBufferProcess;

  @Needs(() => NoveltyUnloadingItemV2, 'noveltyUnloadingItemV2Id')
  noveltyUnloadingItemV2: NoveltyUnloadingItemV2;

  @Status({
    [NoveltyMoveContainerToBufferItemStatuses.Moving]:
      NoveltyMoveContainerToBufferItemStatusMovingStatus,
    [NoveltyMoveContainerToBufferItemStatuses.Moved]:
      NoveltyMoveContainerToBufferItemStatusMovedStatus
  })
  status: NoveltyMoveContainerToBufferItemStatus;
}

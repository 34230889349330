import { MovePendingDispatchingItemStatuses } from '../MovePendingDispatchingItem';
import { MovePendingDispatchingItemStatus } from './MovePendingDispatchingItemStatus';

export class MovePendingDispatchingItemCancelledStatus extends MovePendingDispatchingItemStatus {
  public displayName = 'Cancelado';

  public internalName = MovePendingDispatchingItemStatuses.Cancelled;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { FeedingToSorterItem } from './FeedingToSorterItem';
import { FeedingToSorterProcess } from './FeedingToSorterProcess';
import { MovingToPreSorterContainer } from './MovingToPreSorterContainer';
import {
  FeedingToSorterContainerStatus,
  FeedingToSorterContainerStatusCancelledStatus,
  FeedingToSorterContainerStatusCompletedStatus,
  FeedingToSorterContainerStatusPendingStatus,
  FeedingToSorterContainerStatusProcessingStatus
} from './feeding-to-sorter-container-status';

export const FeedingToSorterContainerStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class FeedingToSorterContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [FeedingToSorterContainerStatuses.Pending]:
      FeedingToSorterContainerStatusPendingStatus,
    [FeedingToSorterContainerStatuses.Processing]:
      FeedingToSorterContainerStatusProcessingStatus,
    [FeedingToSorterContainerStatuses.Completed]:
      FeedingToSorterContainerStatusCompletedStatus,
    [FeedingToSorterContainerStatuses.Cancelled]:
      FeedingToSorterContainerStatusCancelledStatus
  })
  status: FeedingToSorterContainerStatus;

  @ForeignKey(() => FeedingToSorterProcess, {
    name: 'feeding_to_sorter_process_id'
  })
  feedingToSorterProcessId: number;

  @ForeignKey(() => MovingToPreSorterContainer, {
    name: 'moving_to_pre_sorter_container_id'
  })
  movingToPreSorterContainerId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => FeedingToSorterProcess, 'feedingToSorterProcessId')
  feedingToSorterProcess: FeedingToSorterProcess;

  @Needs(() => MovingToPreSorterContainer, 'movingToPreSorterContainerId')
  movingToPreSorterContainer: MovingToPreSorterContainer;

  @Needs(() => Container, 'containerId')
  container: Container;

  @NeededBy(() => FeedingToSorterItem, 'feedingToSorterContainerId')
  feedingToSorterItems: FeedingToSorterItem[];
}

import { PendingDispatchingPickingWaveStatuses } from '../PendingDispatchingPickingWave';
import { PendingDispatchingPickingWaveStatus } from './PendingDispatchingPickingWaveStatus';

export class PendingDispatchingPickingWaveProcessingStatus extends PendingDispatchingPickingWaveStatus {
  public displayName = 'Procesando';

  public internalName = PendingDispatchingPickingWaveStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { DispatchingProcessStatuses } from '../DispatchingProcess';
import { DispatchingProcessStatus } from './DispatchingProcessStatus';

export class DispatchingProcessProcessingStatus extends DispatchingProcessStatus {
  public displayName = 'Procesando';

  public internalName = DispatchingProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

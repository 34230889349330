import { Location, SellerProduct } from '@wms/domain';
import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getLocationByCheckDigitActions } from './actions';
import { getLocationByCheckDigitGuards } from './guards';
import { getLocationByCheckDigitServices } from './services';

export interface GetLocationByCheckDigitContext {
  readonly hint: string;
  locationCheckDigit: string;
  location: SellerProduct | null;
  error: string | null;
  readonly locationSuggestionCheckDigit?: string;
  readonly locationSuggestionRequired?: boolean;
  readonly locationSuggestionHint?: string;
}

export type GetLocationByCheckDigitFn = (
  checkDigit: string
) => Promise<Maybe<Location>>;

export const GetLocationByCheckDigitMachineId = 'GetLocationByCheckDigit';

export const GetLocationByCheckDigitMachine = (
  hint: string,
  fetchFn: GetLocationByCheckDigitFn,
  locationSuggestion?: { checkDigit: string; hint: string; required: boolean }
) =>
  createMachine(
    {
      id:                         GetLocationByCheckDigitMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetLocationByCheckDigitContext
      },
      context: {
        hint,
        locationCheckDigit:           '',
        location:                     null,
        error:                        null,
        locationSuggestionCheckDigit: locationSuggestion?.checkDigit,
        locationSuggestionHint:       locationSuggestion?.hint,
        locationSuggestionRequired:   locationSuggestion?.required
      },
      initial: 'EnteringLocationCheckDigit',
      states:  {
        EnteringLocationCheckDigit: {
          on: {
            UpdateLocationCheckDigit: {
              actions: ['updateLocationCheckDigit', 'clearError']
            },
            SubmitLocationCheckDigit: {
              cond:   'isValidLocationCheckDigit',
              target: 'FetchingLocation'
            }
          }
        },
        FetchingLocation: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchLocation',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignLocation',
                target:  'Finished'
              },
              {
                actions: ['assignError', 'clearCheckDigit'],
                target:  'EnteringLocationCheckDigit'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.location
        }
      }
    },
    {
      guards:   getLocationByCheckDigitGuards,
      actions:  getLocationByCheckDigitActions,
      services: getLocationByCheckDigitServices(fetchFn)
    }
  );

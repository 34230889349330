import { NoveltyUnloadingProcessStatuses } from '../NoveltyUnloadingProcess';
import { NoveltyUnloadingProcessStatus } from './NoveltyUnloadingProcessStatus';

export class NoveltyUnloadingProcessStatusCompletedStatus extends NoveltyUnloadingProcessStatus {
  public displayName = 'Completado';

  public internalName = NoveltyUnloadingProcessStatuses.Completed;

  public statusColor = '#Ffffff';
}

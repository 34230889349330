import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { HighlightedInfo } from '../../../../shared/HighlightedInfo';
import { withActorRef } from '../../../../shared/Machine';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from './machine';

export const GetLocationByName = withActorRef(
  GetLocationByNameMachine,
  GetLocationByNameMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateLocationName = useCallback(
    (locationName: string) =>
      send({ type: 'UpdateLocationName', locationName }),
    [send]
  );

  const submitLocationName = useCallback(
    () => send({ type: 'SubmitLocationName' }),
    [send]
  );

  const updateLevel = useCallback(
    (level: string) => send({ type: 'UpdateLevel', level }),
    [send]
  );

  const submitLevel = useCallback(() => send({ type: 'SubmitLevel' }), [send]);

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          {current.context.locationSuggestionName ? (
            <HighlightedInfo
              attribute={current.context.locationSuggestionHint || ''}
              value={current.context.locationSuggestionName || ''}
            />
          ) : null}

          {current.matches('EnteringLocationName') && (
            <Ink.TextInput
              value={current.context.locationName}
              onChange={updateLocationName}
              onSubmit={submitLocationName}
            />
          )}

          {current.matches('EnteringLevel') && (
            <>
              <Ink.Text>Ahora, indique el nivel en altura</Ink.Text>
              <Ink.TextInput
                value={current.context.level}
                onChange={updateLevel}
                onSubmit={submitLevel}
                type='number'
              />
              <Ink.Button
                style={{ width: '70%' }}
                label='Volver a escanear ubicación'
                onClick={() => send({ type: 'backToScanLocation' })}
                id='backToScanLocation'
                key='backToScanLocation'
              />
            </>
          )}

          {current.context.error ? (
            <Ink.Text color='redBright' bold>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});

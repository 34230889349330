import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../../modules/shared/ErrorMessage';
import { HighlightedInfo } from '../../../../modules/shared/HighlightedInfo';
import { withActorRef } from '../../../../modules/shared/Machine';
import { getReservationWarehouseFromTask } from '../../../../utils/reservation-warehouse-from-task';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { LogisticOperator } from '../../core/order-logistic-operator-info';
import { WarehouseInfo } from '../../core/order-warehouse-info';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { StagingAuditingMachine } from './machine';

export const StagingAuditingComponent = withActorRef(StagingAuditingMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const warehouse = getReservationWarehouseFromTask(
      current.context.task as any
    );
    const orderType = (current.context.task as any).payload?.order?.orderType;

    const shouldRenderCurrentStagingLocation =
      !current.matches('ScanningStagingLocation') &&
      current.context.stagingLocation?.name;

    const shouldRenderCurrentAuditingContainer =
      current.context.auditingContainer?.lpn;

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            AUDITORIA N {current.context.stagingAuditingWaveId} -{' '}
            {current.context.stageName}
          </Ink.Text>
        </Ink.Header>

        {shouldRenderCurrentStagingLocation ? (
          <HighlightedInfo
            attribute='Canal'
            value={current.context.stagingLocation!.name}
          />
        ) : null}

        {shouldRenderCurrentAuditingContainer ? (
          <HighlightedInfo
            attribute='Contenedor'
            value={current.context.auditingContainer!.lpn}
          />
        ) : null}

        {warehouse ? (
          <WarehouseInfo warehouse={warehouse} order={current.context.order} />
        ) : orderType ? (
          <LogisticOperator orderType={orderType} />
        ) : null}

        {current.hasTag('loading') ? <Ink.Text>Cargando...</Ink.Text> : null}

        {current.matches('ScanningStagingLocation') ? (
          <>
            <HighlightedInfo
              attribute='Trasladese a canal'
              value={current.context.stagingLocation!.name}
            />
            <ScanControlDigit />
            {current.context.error ? (
              <ErrorMessage error={current.context.error!} />
            ) : null}
          </>
        ) : null}

        {current.matches('AuditingContainers') ? (
          <>
            <Ink.Chalk highlightColor bold>
              Escanee contenedor
            </Ink.Chalk>
            <Ink.Text>
              {' '}
              {current.context.containersAudited} /{' '}
              {current.context.totalContainers} auditados
            </Ink.Text>
            <GetContainerByLpn id='AuditingContainers' />

            {current.context.error ? (
              <ErrorMessage error={current.context.error!} />
            ) : null}
          </>
        ) : null}

        {current.matches('ScanningLabel') ? (
          <>
            <Ink.Text>Escanee bulto</Ink.Text>
            <ScanLabel />
            {current.context.error ? (
              <ErrorMessage error={current.context.error!} />
            ) : null}

            <Ink.Button
              label='CERRAR CONTENEDOR'
              onClick={() => send('CLOSE_CONTAINER')}
            />
          </>
        ) : null}

        {current.matches('Finishing') ? (
          <>
            <IntercalatedText
              texts={['Auditoría en canal', 'completada.']}
              values={[current.context.stageName]}
            />
            <Ink.Text> </Ink.Text>
            <Ink.Text>¿Deseas continuar?</Ink.Text>

            <Ink.Button
              label='FINALIZAR PROCESO DE AUDITORIA'
              onClick={() => send('FINISHING')}
            />
            {current.context.error ? (
              <ErrorMessage error={current.context.error!} />
            ) : null}
          </>
        ) : null}
        {current.matches('FinishingWithMismatchedContainers') ? (
          <>
            <HighlightedInfo
              attribute='Auditoría en canal'
              value={current.context.stageName}
            />
            <Ink.Text>Completada con descuadre o incompleta</Ink.Text>

            {current.context.containersMismatched ? (
              <>
                <Ink.Text> </Ink.Text>
                <HighlightedInfo
                  attribute='Total de Contenedores con descuadre'
                  value={current.context.containersMismatched!}
                />
              </>
            ) : null}
            <Ink.Text bold>
              ¿Finalizar proceso de auditoría con descuadre/pendientes?
            </Ink.Text>

            <Ink.Button
              label='FINALIZAR PROCESO CON DESCUADRE'
              onClick={() => send('FINISHING')}
            />
            {current.context.error ? (
              <ErrorMessage error={current.context.error!} />
            ) : null}
          </>
        ) : null}
      </Ink.Box>
    );
  }
);

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { PopUp } from '../../layer-4/pop-up/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { UnloadingV3Machine } from './machine';

export const UnloadingV3Task = withActorRef(UnloadingV3Machine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <>
        <Ink.Box flexDirection='column' paddingX={1}>
          <Ink.Header>
            Recepcion Orden Nº {current.context.receiptId} en canal{' '}
            {current.context.stagingName}
          </Ink.Header>
          {/* Header */}
          <Ink.Box flexDirection='column'>
            <>
              {current.hasTag('loading') && (
                <Ink.Box flexDirection='column'>
                  <Ink.Text>Cargando... </Ink.Text>
                </Ink.Box>
              )}
              {current.matches('ScanningControlDigit') && (
                <>
                  <HighlightedInfo
                    attribute='Ingrese digito de control del canal'
                    value={current.context.stagingName}
                  />
                  <Ink.Text />
                  <ScanControlDigit />
                </>
              )}
              {current.matches('ScanningSKU') && (
                <>
                  <HighlightedInfo
                    attribute='Usando contenedor'
                    value={
                      (current.context.standardUnloadingContainerScanned
                        ?.container.lpn || '') as string
                    }
                  />
                  <Ink.Text> </Ink.Text>
                  <GetProductBySKU />
                  {current.context.error ? (
                    <ErrorMessage error={current.context.error} />
                  ) : null}
                </>
              )}
              {current.matches('SelectingContainerType') && (
                <>
                  <Ink.Text>Seleccione tipo de contenedor a utilizar</Ink.Text>

                  {current.context.containerTypes?.map(
                    (containerType, index) => (
                      <Ink.Button
                        key={index}
                        label={containerType.name}
                        onClick={() =>
                          send({
                            type: 'CONTAINER_TYPE_SELECTED',
                            data: containerType
                          })
                        }
                      />
                    )
                  )}
                </>
              )}
              {current.matches('ScanContainer') && (
                <>
                  <Ink.Text />
                  <HighlightedInfo
                    attribute='Escanee contenedor, TIPO'
                    value={
                      (current.context.containerType?.name || '') as string
                    }
                  />
                  <Ink.Text />
                  <EnterLpn />
                  {current.context.error && (
                    <ErrorMessage error={current.context.error} />
                  )}

                  <Ink.Box flexDirection='column'>
                    {current.context.containerTypes.map(
                      (containerType, index) => (
                        <Ink.Button
                          key={index}
                          label={'CAMBIAR CONTENEDOR A ' + containerType.name}
                          onClick={() =>
                            send({
                              type: 'CHANGE_CONTAINER_TYPE',
                              data: containerType
                            })
                          }
                        />
                      )
                    )}
                  </Ink.Box>
                </>
              )}
              {current.matches('DetailOfReceipt') && (
                <>
                  {current.context.detailOfItems.map((item, index) => (
                    <Ink.Text key={index}>
                      {item.sku}: &nbsp;{' '}
                      <Ink.Chalk color='greenBright'>
                        {'Cantidad esperada ' + item.expectedQuantity}
                      </Ink.Chalk>
                      &nbsp; - &nbsp;
                      <Ink.Text>
                        {'Cantidad descargada ' + item.actualQuantity}
                      </Ink.Text>
                    </Ink.Text>
                  ))}
                  <Ink.Button
                    key='scan-container-button'
                    label='Escanear Contenedor'
                    onClick={() =>
                      send({
                        type: 'CONTINUE'
                      })
                    }
                  />
                </>
              )}
              {current.matches('IndicatingQuantityReceived') && (
                <>
                  <Ink.Text color='greenBright' bold>
                    {current.context.SKUScanned!.sku} -{' '}
                    {current.context.SKUScanned!.name}
                  </Ink.Text>
                  <Ink.Text />
                  <Ink.Text>
                    Indique cant. a almacenar en el contenedor:
                  </Ink.Text>
                  <Ink.Text bold color='greenBright'>
                    {current.context.standardUnloadingContainerScanned
                      ?.container.lpn || ''}
                  </Ink.Text>
                  {current.context.quantityLeft ? (
                    <HighlightedInfo
                      attribute='Quedan por descargar'
                      value={current.context.quantityLeft}
                    />
                  ) : null}
                  <EnterQuantity />
                </>
              )}
              {current.matches('ConfirmingLabels') && (
                <>
                  {/* <Ink.Text>
                    SKU {current.context.standardUnloadingItemsLoadedCount} de{' '}
                    {current.context.standardUnloadingItemsTotalCount}
                  </Ink.Text> */}
                  <Ink.Text color='greenBright' bold>
                    {current.context.SKUScanned!.sku} -{' '}
                    {current.context.SKUScanned!.name}
                  </Ink.Text>
                  <Ink.Text />
                  <Ink.Text>
                    {' '}
                    Pegue las {current.context.receivedQuantity} etiquetas de
                    SKU y confirme accion para continuar
                  </Ink.Text>
                  <Ink.Button
                    label='OK'
                    onClick={() => send({ type: 'LABELS_CONFIRMED' })}
                  />
                </>
              )}
              {current.matches('WarningAllItemsWasNotReceived') && (
                <>
                  <Ink.Text color='redBright' bold>
                    {' '}
                    ¡ATENCIÓN!{' '}
                  </Ink.Text>

                  <Ink.Text>Todavía le quedan ítems a recepcionar:</Ink.Text>
                  <Ink.Text> </Ink.Text>
                  {current.context.detailOfItems!.map(item => (
                    <>
                      {item.missingQuantity && (
                        <>
                          <Ink.Text key={item.sku}>
                            {item.sku}: &nbsp;{' '}
                            <Ink.Chalk color='redBright'>
                              {'Cantidad descargada ' + item.actualQuantity}
                            </Ink.Chalk>{' '}
                            &nbsp;de {item.expectedQuantity}
                          </Ink.Text>
                          <Ink.Text> </Ink.Text>
                        </>
                      )}
                    </>
                  ))}
                  <Ink.Button
                    label='FINALIZAR PRE-CIERRE'
                    onClick={() => send({ type: 'FINISHING' })}
                  />
                  <Ink.Button
                    label='SEGUIR DESCARGANDO'
                    onClick={() => send({ type: 'UNLOADING' })}
                  />
                </>
              )}
              {current.matches('Finishing') && (
                <>
                  <Ink.Text> ¿Confirmar pre-cierre?</Ink.Text>
                  <Ink.Button
                    label='CONFIRMAR'
                    onClick={() => send({ type: 'CONFIRMING' })}
                  />
                </>
              )}
              {current.matches('HandleErrors') && <PopUp />}
            </>
          </Ink.Box>
        </Ink.Box>
      </>
    );
  }
);

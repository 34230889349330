import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { PickListProduct } from './PickListProduct';
import { PickingWave } from './PickingWave';
import { PutToWallOrder } from './PutToWallOrder';

@Entity()
export class PickList {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PutToWallOrder, { name: 'ptw_order_id' })
  ptwOrderId: number;

  @Needs(() => PutToWallOrder, 'ptwOrderId')
  ptwOrder: PutToWallOrder;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @Field({ name: 'is_picked' })
  isPicked: boolean;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => PickListProduct, 'pickListId')
  pickListProducts: PickListProduct[];
}

import { ContainerTypes, DomainModels, PickItemStatuses } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { MiniPtwPickingWaveContext } from './machine';

type MiniPtwPickingWaveGuards = MachineOptions<
  MiniPtwPickingWaveContext,
  any
>['guards'];

export const miniPtwPickingWaveGuards: MiniPtwPickingWaveGuards = {
  hasQuantityToPick: (ctx, _event) => {
    return ctx.pickedQuantity !== ctx.currentPickItem?.quantity;
  },
  pendingItemsRemaining: (_ctx, event) => {
    return event.data.pendingItems > 0;
  },
  itemIsInLocation: (_ctx, evt) => {
    const pickItem = evt.data as DomainModels.PickItem;
    return pickItem.pickingLocationId === pickItem.inventoryItem.locationId;
  },
  isSuggestedProduct: (ctx, evt) => {
    return evt.data.product.sku === ctx.currentPickItem?.sku;
  },
  isInventoryInsufficientQuantityException: (_ctx, evt) => {
    return (
      evt.data?.data?.response?.data?.type ===
      'InventoryInsufficientQuantityException'
    );
  },
  canAutoAssignBucketContainerType: (ctx, evt) => {
    return evt.data.containerTypes.some(
      containerType => containerType.name === ContainerTypes.CUBETA
    );
  },
  isOpportunisticCountAvailable: (_ctx, evt) => {
    return evt.data.isAvailable;
  },
  isPickItemCancelled: (_ctx, event) => {
    return event.data.status === PickItemStatuses.Cancelled;
  }
};

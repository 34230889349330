import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MoveToBufferMachine } from './machine';

export const MoveToBufferTask = withActorRef(MoveToBufferMachine)(
  ({ actorRef }) => {
    const [current] = useActor(actorRef);
    return (
      <>
        <Ink.Box flexDirection='column' paddingX={1}>
          <Ink.Header>
            Tarea De Traslado Nº {current.context.moveToBufferItemId} - Canal{' '}
            {current.context.containerLocationName || 'N/A'}
          </Ink.Header>
        </Ink.Box>

        <Ink.Box flexDirection='column'>
          <>
            {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}

            {current.matches('ScanningControlDigit') && (
              <>
                <HighlightedInfo
                  attribute='Ingrese digito de control del canal'
                  value={current.context.stagingLocation?.name || ' '}
                />
                <Ink.Text />
                <ScanControlDigit />
                {current.context.error && (
                  <ErrorMessage error={current.context.error} />
                )}
              </>
            )}

            {current.matches('ScanningContainer') && (
              <>
                <HighlightedInfo
                  attribute='Ingrese contenedor'
                  value={
                    current.context.standardUnloadingContainer!.container.lpn ||
                    ' '
                  }
                />
                <Ink.Text />
                <GetContainerByLpn />
              </>
            )}

            {current.matches('ScanningDestinyLocation') && (
              <>
                <HighlightedInfo
                  attribute='Destino de RollContainer'
                  value={'Nivel ' + current.context.destinyLocationLevel ?? ' '}
                />
                <HighlightedInfo
                  attribute='Ingrese ubicación de destino'
                  value={current.context.destinyLocation!.name || ' '}
                />
                <Ink.Text />
                <GetLocationByName />
              </>
            )}
          </>
        </Ink.Box>
      </>
    );
  }
);

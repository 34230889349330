import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  Owns,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Appointment } from './Appointment';
import { AuditOrder } from './AuditOrder';
import { Comment } from './Comment';
import { CustomerOrder } from './CustomerOrder';
import { Document } from './Document';
import { FulfillmentOrder } from './FulfillmentOrder';
import { Incident } from './Incident';
import { InventoryAssignationStrategy } from './InventoryAssignationStrategy';
import { Location } from './Location';
import { MopPickingContainer } from './MopPickingContainer';
import { MovePendingDispatchingItemsWave } from './MovePendingDispatchingItemsWave';
import { OrderBatch } from './OrderBatch';
import { OrderCustomerOrder } from './OrderCustomerOrder';
import { OrderItem } from './OrderItem';
import { OrderType } from './OrderType';
import { PendingDispatchingPickingWave } from './PendingDispatchingPickingWave';
import { PickingProcess } from './PickingProcess';
import { Process } from './Process';
import { ReservationOrder } from './ReservationOrder';
import { Route } from './Route';
import { StagingAuditingWave } from './StagingAuditingWave';
import { Task } from './Task';
import { TransferOrder } from './TransferOrder';
import { Warehouse } from './Warehouse';
import {
  OrderStatus,
  OrderStatusAuditedStatus,
  OrderStatusAuditingMismatchStatus,
  OrderStatusAuditingOmittedStatus,
  OrderStatusAuditingStatus,
  OrderStatusCancelledStatus,
  OrderStatusConsolidatedStatus,
  OrderStatusConsolidatingStatus,
  OrderStatusDeletedStatus,
  OrderStatusDispatchedStatus,
  OrderStatusDispatchingStatus,
  OrderStatusNotDispatchedStatus,
  OrderStatusNotPreparedStatus,
  OrderStatusPartiallyDispatchedStatus,
  OrderStatusPartiallyShippedStatus,
  OrderStatusPreparedStatus,
  OrderStatusPreparingStatus,
  OrderStatusShippedStatus
} from './order-status';

export const OrderStatuses = {
  NotPrepared:         'not-prepared',
  Preparing:           'preparing',
  Prepared:            'prepared',
  Auditing:            'auditing',
  Audited:             'audited',
  AuditingOmitted:     'auditing-omitted',
  AuditingMismatch:    'auditing-mismatch',
  Dispatching:         'dispatching',
  Dispatched:          'dispatched',
  Cancelled:           'cancelled',
  PartiallyDispatched: 'partially-dispatched',
  NotDispatched:       'not-dispatched',
  Consolidating:       'consolidating',
  Consolidated:        'consolidated',
  Shipped:             'shipped',
  PartiallyShipped:    'partially-shipped',
  Deleted:             'deleted'
};

export const ReadyOrderStatuses = [
  OrderStatuses.Preparing,
  OrderStatuses.Prepared,
  OrderStatuses.Consolidating,
  OrderStatuses.Consolidated,
  OrderStatuses.Auditing,
  OrderStatuses.Audited,
  OrderStatuses.AuditingOmitted,
  OrderStatuses.AuditingMismatch,
  OrderStatuses.Dispatching,
  OrderStatuses.PartiallyDispatched
];

export const ProcessOrderStatuses = [
  OrderStatuses.Audited,
  OrderStatuses.Dispatching,
  OrderStatuses.Auditing,
  OrderStatuses.Preparing,
  OrderStatuses.Prepared,
  OrderStatuses.Cancelled
];

export const PickingMethods = {
  BatchPickingMultiOrder: 'batch-picking-multi-order',
  Normal:                 'normal'
};

export const OrderPendingStatuses = [OrderStatuses.NotPrepared] as const;

export const OrderDispatchedStatuses = [
  OrderStatuses.Dispatched,
  OrderStatuses.PartiallyDispatched,
  OrderStatuses.Shipped
] as const;

export const TranslatePickingMethods = {
  [PickingMethods.BatchPickingMultiOrder]: 'Batch Picking',
  [PickingMethods.Normal]:                 'Normal'
};

export const LabelOrderType = {
  Hd: 'hd',
  Ss: 'ss'
};

@Entity()
export class Order {
  @PrimaryKey()
  id: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'must_audit' })
  mustAudit: boolean;

  @Field({ name: 'lote_arba' })
  loteArba: string;

  @Field({ name: 'cot_date' })
  cotDate: Date;

  @Field({ name: 'branch_offices_to_go' })
  branchOfficesToGo: string;

  @Field({ name: 'impression_count' })
  impressionCount: number = 0;

  @Status({
    [OrderStatuses.NotPrepared]:         OrderStatusNotPreparedStatus,
    [OrderStatuses.Preparing]:           OrderStatusPreparingStatus,
    [OrderStatuses.Prepared]:            OrderStatusPreparedStatus,
    [OrderStatuses.Auditing]:            OrderStatusAuditingStatus,
    [OrderStatuses.Audited]:             OrderStatusAuditedStatus,
    [OrderStatuses.AuditingOmitted]:     OrderStatusAuditingOmittedStatus,
    [OrderStatuses.AuditingMismatch]:    OrderStatusAuditingMismatchStatus,
    [OrderStatuses.Dispatching]:         OrderStatusDispatchingStatus,
    [OrderStatuses.Dispatched]:          OrderStatusDispatchedStatus,
    [OrderStatuses.Cancelled]:           OrderStatusCancelledStatus,
    [OrderStatuses.PartiallyDispatched]: OrderStatusPartiallyDispatchedStatus,
    [OrderStatuses.NotDispatched]:       OrderStatusNotDispatchedStatus,
    [OrderStatuses.Consolidating]:       OrderStatusConsolidatingStatus,
    [OrderStatuses.Consolidated]:        OrderStatusConsolidatedStatus,
    [OrderStatuses.Shipped]:             OrderStatusShippedStatus,
    [OrderStatuses.Deleted]:             OrderStatusDeletedStatus,
    [OrderStatuses.PartiallyShipped]:    OrderStatusPartiallyShippedStatus
  })
  status: OrderStatus;

  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @ForeignKey(() => Route, { name: 'route_id' })
  routeId: number;

  @ForeignKey(() => OrderType, { name: 'order_type_id' })
  orderTypeId: number;

  @ForeignKey(() => InventoryAssignationStrategy, {
    name: 'wanted_assignation_strategy_id'
  })
  wantedAssigationStrategyId: number;

  @Knows(() => InventoryAssignationStrategy, 'wantedAssigationStrategyId')
  wantedAssigationStrategy: InventoryAssignationStrategy;

  @Knows(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @Knows(() => Route, 'routeId')
  route: Route;

  @Needs(() => OrderType, 'orderTypeId')
  orderType: OrderType;

  @Owns(() => OrderItem, 'orderId')
  orderItems: OrderItem[];

  @Knows(() => Task, 'orderId')
  task: Task[];

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Knows(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => AuditOrder, 'orderId')
  auditories: AuditOrder[];

  @NeededBy(() => OrderCustomerOrder, 'orderId')
  orderCustomerOrders: OrderCustomerOrder[];

  @NeededBy(() => ReservationOrder, 'orderId')
  reservationOrders: ReservationOrder[];

  @Knows(() => StagingAuditingWave, 'orderId')
  stagingAuditingWaves: StagingAuditingWave[];

  @Knows(() => Comment, 'orderId')
  comment: Comment[];

  @Knows(() => Document, 'orderId')
  documents: Document[];

  @Knows(() => PickingProcess, 'orderId')
  pickingProcesses: PickingProcess[];

  @ForeignKey(() => CustomerOrder, { name: 'customer_order_id' })
  customerOrderId: number;

  @Knows(() => CustomerOrder, 'customerOrderId')
  customerOrder?: CustomerOrder;

  @Field({ name: 'estimated_date' })
  estimatedDate: Date;

  public static readonly REGULAR_STATUSES = [
    OrderStatuses.NotPrepared,
    OrderStatuses.Preparing,
    OrderStatuses.Prepared,
    OrderStatuses.Auditing,
    OrderStatuses.Audited,
    OrderStatuses.Dispatched
  ];

  public static readonly PICKING_METHODS = [
    PickingMethods.BatchPickingMultiOrder,
    PickingMethods.Normal
  ];

  public static readonly ANOMALY_STATUSES = [OrderStatuses.Cancelled];

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @ForeignKey(() => TransferOrder, { name: 'transfer_order_id' })
  transferOrderId: number;

  @ForeignKey(() => Location, { name: 'dispatch_location_id' })
  dispatchLocationId: number;

  @Knows(() => TransferOrder, 'transferOrderId')
  transferOrder: TransferOrder;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Knows(() => Location, 'dispatchLocationId')
  dispatchLocation: Location;

  @Knows(() => FulfillmentOrder, 'orderId')
  customerOrderReservation: FulfillmentOrder;

  @NeededBy(() => MovePendingDispatchingItemsWave, 'orderId')
  movePendingDispatchingItemsWaves: MovePendingDispatchingItemsWave[];

  @NeededBy(() => PendingDispatchingPickingWave, 'orderId')
  pendingDispatchingPickingWaves: PendingDispatchingPickingWave[];

  @Knows(() => MopPickingContainer, 'orderId')
  mopPickingContainers: MopPickingContainer[];

  @Knows(() => Incident, 'orderId')
  incidents: Incident[];

  @ForeignKey(() => Location, { name: 'order_ramp_id' })
  orderRampId: number;

  @Knows(() => Location, 'orderRampId')
  orderRamp: Location;
}

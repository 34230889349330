import Ink from '@ink';
import {
  InventoryItem,
  InventoryItemStatuses,
  operationTypesDisplayName
} from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { LocationDetailMachine } from './machine';

export const LocationDetailComponent = withActorRef(LocationDetailMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getStatusEmoji = (inventoryItem: InventoryItem) => {
      const status = inventoryItem.status as unknown as string;
      if (status == InventoryItemStatuses.Available) {
        return '✅';
      } else if (status == InventoryItemStatuses.Detained) {
        return '🕑';
      } else if (status == InventoryItemStatuses.Reserved) {
        return '❌';
      }
      return '';
    };
    const getOperationType = (operationTypes: string[]) => {
      if (operationTypes.length === 0) {
        return '';
      }
      const types = operationTypes.map(ot => operationTypesDisplayName[ot]);
      return types.toString();
    };
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Detalle de la Ubicación</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.hasTag('loading') && <Ink.Text>Loading...</Ink.Text>}
          {current.matches('ScanningLocation') && <GetLocationByName />}

          {current.matches('LocationDetails') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                <HighlightedInfo
                  attribute='UBICACIÓN'
                  value={current.context.location?.name || '-'}
                />
              </Ink.Text>
              <Ink.Text>
                <Ink.Text bold>Permite MultiSku: </Ink.Text>{' '}
                {current.context.allowMultiSku ? 'SI' : 'NO'}
              </Ink.Text>
              <Ink.Text>
                <Ink.Text bold>Tipo de Ubicación: </Ink.Text>{' '}
                {getOperationType(current.context.operationTypes)}
              </Ink.Text>
              <Ink.Text>
                <Ink.Text bold>Estado de la ubicación: </Ink.Text>{' '}
                {current.context.locationStatus}
              </Ink.Text>
              {(current.context.items?.length as number) > 0 && (
                <Ink.Text bold>SKUs: &nbsp; </Ink.Text>
              )}
              {current.context.items?.map((item, index) => (
                <Ink.Text key={item.sku} bold color='orangeBright'>
                  <Ink.Box
                    flexDirection='column'
                    key={index}
                    marginBottom={1.5}
                  >
                    <Ink.Text>
                      {item.sku} - {item.name}
                    </Ink.Text>
                    {item.itemsInContainersByStatus.map((inventoryItem, i) => (
                      <Ink.Box key={i} paddingLeft={5}>
                        <Ink.Text>
                          {inventoryItem.quantity}u. en&nbsp;
                          {inventoryItem.container
                            ? inventoryItem.container?.lpn
                            : 'la ubicación'}{' '}
                          | {getStatusEmoji(inventoryItem)}
                        </Ink.Text>
                      </Ink.Box>
                    ))}
                  </Ink.Box>
                </Ink.Text>
              ))}
              {current.context.lastInventoryItemInLocation && (
                <Ink.Text bold>
                  Último SKU en ubicación: &nbsp;
                  <Ink.Text color='orangeBright'>
                    {current.context.lastInventoryItemInLocation.sku} -{' '}
                    {current.context.lastInventoryItemInLocation.product.name}
                  </Ink.Text>
                </Ink.Text>
              )}
              {current.context.neverHadItemsInlocation && (
                <Ink.Text bold color='orangeBright'>
                  En esta ubicación nunca se ha colocado un producto.
                </Ink.Text>
              )}
              <Ink.Box paddingTop={4}> </Ink.Box>

              <Ink.Text bold> Estados del producto </Ink.Text>
              <Ink.Text> ✅: Disponible </Ink.Text>
              <Ink.Text> 🕑: Retenido </Ink.Text>
              <Ink.Text> ❌: Reservado </Ink.Text>
              <Ink.Button
                label='Escanear otra ubicación'
                onClick={() => send('reset')}
              />
            </Ink.Box>
          )}
        </Ink.Box>
        {current.context.error && (
          <ErrorMessage error={current.context.error!} bold />
        )}
      </Ink.Box>
    );
  }
);

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { LabelDetailContext } from './machine';

type LabelDetailActionsType = MachineOptions<
  LabelDetailContext,
  any
>['actions'];

export const labelDetailActions: LabelDetailActionsType = {
  assignEntity: assign({
    entity: (_ctx, event) => {
      return event.data.entityType;
    },
    label: (_ctx, event) => {
      return event.data.label;
    }
  }),
  assignItem: assign({
    items: (_ctx, event) => {
      return event.data.items;
    }
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return (
        getErrorView(event.data ?? event.data.data) ||
        'Ha ocurrido un error al buscar el detalle de la Etiqueta'
      );
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => {
      return null;
    }
  }),
  restartValues: assign({
    entity: null,
    label:  null,
    items:  null
  }),
  assignEmptyTypeError: assign({
    error: (_ctx, _event) => {
      return 'No se encontró la etiqueta en el sistema';
    }
  })
};

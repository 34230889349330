import { Container } from '@wms/domain';
import { InventoryItemStatuses, LocationTypeNames } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { BulkMovementContext } from './machine';

type BulkMovementGuardsType = MachineOptions<
  BulkMovementContext,
  any
>['guards'];

export const bulkMovementGuards: BulkMovementGuardsType = {
  isValidLpn: (_ctx, evt) => {
    return Container.isValidLpn(evt.data.lpnOrLocationLabel);
  },

  originLocationHasMoreThanOneContainer: (_ctx, evt) => {
    return evt.data;
  },

  destinyLocationHasMoreThanOneContainer: (_ctx, evt) => {
    return evt.data;
  },

  invalidAnomalyContainerLocation: (ctx, evt) => {
    return (
      ctx.itemStatus === InventoryItemStatuses.Anomaly &&
      evt.data.destinyContainer?.location.locationType.name !==
        LocationTypeNames.Anomaly
    );
  },

  invalidAnomalyLocation: (ctx, evt) => {
    return (
      ctx.itemStatus === InventoryItemStatuses.Anomaly &&
      evt.data.destinyLocation?.locationType.name !== LocationTypeNames.Anomaly
    );
  },

  isDestinyAvailable: (ctx, evt) => {
    return evt.data >= (ctx.quantity as number);
  },

  isMezzanine: (ctx, _evt) => {
    const locationName = (ctx.destinyLocation?.name ||
      ctx.destinyContainer?.location.name) as string;
    return locationName.startsWith('MZ') && locationName.charAt(2) != '0';
  },

  goToScanningDestinyLabel: (ctx, _evt) => {
    return ctx.alertData.target === 'ScanningDestinyLabel';
  },

  goToScanningOriginLabel: (ctx, _evt) => {
    return ctx.alertData.target === 'ScanningOriginLabel';
  },
  goToScanningSku: (ctx, _evt) => {
    return ctx.alertData.target === 'ScanningSku';
  },
  goToCheckingIfProductIsInOriginContainer: (ctx, _evt) => {
    return ctx.alertData.target === 'CheckingIfProductIsInOriginContainer';
  },
  gotToEnteringQuantity: (ctx, _evt) => {
    return ctx.alertData.target === 'EnteringQuantity';
  }
};

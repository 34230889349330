import { MoveToBufferProcessStatuses } from '../MoveToBufferProcess';
import { MoveToBufferProcessStatus } from './MoveToBufferProcessStatus';

export class MoveToBufferProcessStatusCancelledStatus extends MoveToBufferProcessStatus {
  public displayName = 'Cancelado';

  public internalName = MoveToBufferProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

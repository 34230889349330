import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ClassificationItem } from './ClassificationItem';
import { Comment } from './Comment';
import { Container } from './Container';
import { DispatchingItem } from './DispatchingItem';
import { FeedingToSorterItem } from './FeedingToSorterItem';
import { FulfillmentOrder } from './FulfillmentOrder';
import { ImpureSortableCrossdockClassificationItem } from './ImpureSortableCrossdockClassificationItem';
import { Incident } from './Incident';
import { InventoryCount } from './InventoryCount';
import { InventoryCountingItem } from './InventoryCountingItem';
import { Location } from './Location';
import { Lot } from './Lot';
import { MovingToSorterExitItem } from './MovingToSorterExitItem';
import { PackingItem } from './PackingItem';
import { PickItem } from './PickItem';
import { Product } from './Product';
import { PutAwayItem } from './PutAwayItem';
import { ReceiptItem } from './ReceiptItem';
import { SellerProduct } from './SellerProduct';
import { SlottingItem } from './SlottingItem';
import { SortingItem } from './SortingItem';
import { StagingAuditingItem } from './StagingAuditingItem';
import { StandardUnloadingItem } from './StandardUnloadingItem';
import { StockTransferLogic } from './StockTransferLogic';
import { TransferOrderItem } from './TransferOrderItem';
import { UnloadingItem } from './UnloadingItem';
import {
  InventoryItemPhysicalStatus,
  InventoryItemPhysicalStatusAvailableStatus,
  InventoryItemPhysicalStatusNotInWareHouseStatus
} from './inventory-item-physical-status';
import {
  InventoryItemQualityStatus,
  InventoryItemQualityStatusDamagedStatus,
  InventoryItemQualityStatusDetainedStatus,
  InventoryItemQualityStatusDocMissingStatus,
  InventoryItemQualityStatusMissingStatus,
  InventoryItemQualityStatusNormalStatus
} from './inventory-item-quality-status';
import {
  InventoryItemStatus,
  InventoryItemStatusAnomalyStatus,
  InventoryItemStatusAvailableStatus,
  InventoryItemStatusDetainedStatus,
  InventoryItemStatusNotInWareHouseStatus,
  InventoryItemStatusReceivingStatus,
  InventoryItemStatusReservedStatus
} from './inventory-item-status';

export const InventoryItemStatuses = {
  Available:      'available',
  NotInWareHouse: 'not-in-warehouse',
  Anomaly:        'anomaly',
  Detained:       'detained',
  Reserved:       'reserved',
  Receiving:      'receiving',
  Dispatched:     'dispatched'
};

export const InventoryItemLogicStatuses = {
  Available:  'available',
  InTransit:  'in-transit',
  Reserved:   'reserved',
  InAnalysis: 'in-analysis'
};

export const InventoryItemQualityStatuses = {
  Normal:     'normal',
  Detained:   'detained',
  Damaged:    'damaged',
  Missing:    'missing',
  DocMissing: 'doc-missing'
};

export const InventoryItemPhysicalStatuses = {
  Available:      'available',
  NotInWareHouse: 'not-in-warehouse'
};

export const InventoryItemExceptionTypes = {
  InsufficientQuantity: 'InventoryInsufficientQuantityException',
  ItemInStaging:        'ItemInStagingException'
};

export const InventoryItemIncidentStatuses = [
  InventoryItemStatuses.Anomaly,
  InventoryItemStatuses.Detained
];

export enum AnomalyTypes {
  MISSING = 'mark-as-missing',
  DAMAGED = 'mark-as-damaged'
}

export const InventoryItemUnavailableStatuses = [
  InventoryItemStatuses.NotInWareHouse,
  InventoryItemStatuses.Reserved,
  InventoryItemStatuses.Anomaly,
  InventoryItemStatuses.Detained
];

export enum AlphaQualityStatus {
  QualityPending = '03'
}

export enum AlphaStatusReason {
  StockRemoval = '10'
}

export enum AlphaMovementType {
  Alta = '01'
}

@Entity()
export class InventoryItem {
  @PrimaryKey()
  id: number;

  @Field()
  sku: string;

  @Field()
  quantity: number;

  @Field()
  frac: boolean;

  @Field({ name: 'location_date' })
  locationDate: Date;

  @Field({ name: 'logic_warehouse' })
  logicWarehouse: string;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @ForeignKey(() => FulfillmentOrder, { name: 'group_id' })
  groupId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => SellerProduct, { name: 'seller_product_id' })
  sellerProductId: number;

  @ForeignKey(() => Lot, { name: 'lot_id' })
  lotId: number;

  @ForeignKey(() => StockTransferLogic, { name: 'stock_transfer_logic_id' })
  stockTransferLogicId: number;

  @Knows(() => StockTransferLogic, 'stockTransferLogicId')
  stockTransferLogic: StockTransferLogic;

  @Knows(() => SellerProduct, 'sellerProductId')
  sellerProduct: SellerProduct;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'lot_number' })
  lotNumber: string;

  @Field({ name: 'received_date' })
  receivedDate: Date;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Knows(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Knows(() => PickItem, 'inventoryItemId')
  pickItem: PickItem;

  @Knows(() => Product, 'productId')
  product: Product;

  @Knows(() => Comment, 'inventoryItemId')
  comment: Comment;

  @Knows(() => ClassificationItem, 'inventoryItemId')
  classificationItem: ClassificationItem;

  @Knows(() => FulfillmentOrder, 'groupId')
  group: FulfillmentOrder;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Field({ name: 'expiration_date' })
  expirationDate: Date;

  @NeededBy(() => InventoryCount, 'inventoryItemId')
  inventoryCount: InventoryCount;

  @Knows(() => PackingItem, 'inventoryItemId')
  packingItem: PackingItem;

  @Knows(() => PutAwayItem, 'inventoryItemId')
  putAwayItem: PutAwayItem;

  @Knows(() => StandardUnloadingItem, 'inventoryItemId')
  standardUnloadingItem: StandardUnloadingItem;

  @Knows(() => Lot, 'lotId')
  lot: Lot;

  @Status({
    [InventoryItemStatuses.Available]:      InventoryItemStatusAvailableStatus,
    [InventoryItemStatuses.NotInWareHouse]:
      InventoryItemStatusNotInWareHouseStatus,
    [InventoryItemStatuses.Anomaly]:   InventoryItemStatusAnomalyStatus,
    [InventoryItemStatuses.Detained]:  InventoryItemStatusDetainedStatus,
    [InventoryItemStatuses.Reserved]:  InventoryItemStatusReservedStatus,
    [InventoryItemStatuses.Receiving]: InventoryItemStatusReceivingStatus
  })
  status: InventoryItemStatus;

  @Status(
    {
      [InventoryItemQualityStatuses.Normal]:
        InventoryItemQualityStatusNormalStatus,
      [InventoryItemQualityStatuses.Detained]:
        InventoryItemQualityStatusDetainedStatus,
      [InventoryItemQualityStatuses.Damaged]:
        InventoryItemQualityStatusDamagedStatus,
      [InventoryItemQualityStatuses.Missing]:
        InventoryItemQualityStatusMissingStatus,
      [InventoryItemQualityStatuses.DocMissing]:
        InventoryItemQualityStatusDocMissingStatus
    },
    { name: 'quality_status' }
  )
  qualityStatus: InventoryItemQualityStatus;

  @Status(
    {
      [InventoryItemPhysicalStatuses.Available]:
        InventoryItemPhysicalStatusAvailableStatus,
      [InventoryItemPhysicalStatuses.NotInWareHouse]:
        InventoryItemPhysicalStatusNotInWareHouseStatus
    },
    { name: 'physical_status' }
  )
  physicalStatus: InventoryItemPhysicalStatus;

  public static readonly ANOMALY_STATUSES = [InventoryItemStatuses.Anomaly];

  public static readonly CHANGE_QUALITY_STATUSES = [
    InventoryItemStatuses.Available
  ];

  @Knows(() => TransferOrderItem, 'inventoryItemId')
  transferOrderItems: TransferOrderItem[];

  @Knows(() => Incident, 'inventoryItemId')
  incidents: Incident[];

  public static mapStatusName(internalName: string): string {
    switch (internalName) {
      case InventoryItemStatuses.Anomaly:
        return 'Con Incidencias';
      case InventoryItemStatuses.Available:
        return 'Disponible';
      case InventoryItemStatuses.Detained:
        return 'Retenido';
      case InventoryItemStatuses.NotInWareHouse:
        return 'No disponible';
      case InventoryItemStatuses.Reserved:
        return 'Reservado';
    }

    return '';
  }

  public static mapPhysStatusName(internalName: string): string {
    switch (internalName) {
      case InventoryItemPhysicalStatuses.Available:
        return 'Disponible';
      case InventoryItemPhysicalStatuses.NotInWareHouse:
        return 'No disponible';
    }

    return '';
  }

  public static mapQualityStatusName(internalName: string): string {
    switch (internalName) {
      case InventoryItemQualityStatuses.Normal:
        return 'Normal';
      case InventoryItemQualityStatuses.Damaged:
        return 'Dañado';
      case InventoryItemQualityStatuses.Detained:
        return 'Retenido';
      case InventoryItemQualityStatuses.Missing:
        return 'Faltante';
      case InventoryItemQualityStatuses.DocMissing:
        return 'Falta Documentación';
    }

    return '';
  }

  @NeededBy(() => InventoryCountingItem, 'inventoryItemId')
  inventoryCountingItem: InventoryCountingItem;

  @NeededBy(() => SortingItem, 'inventoryItemId')
  SortingItem: SortingItem[];

  @Field({ name: 'wrong_location' })
  wrongLocation: boolean;

  @Knows(() => UnloadingItem, 'inventoryItemId')
  unloadingItems: UnloadingItem[];

  @NeededBy(() => FeedingToSorterItem, 'inventoryItemId')
  feedingToSorterItem: FeedingToSorterItem;

  @NeededBy(() => MovingToSorterExitItem, 'inventoryItemId')
  movingToSorterExitItem: MovingToSorterExitItem;

  @Knows(() => StagingAuditingItem, 'inventoryItemId')
  stagingAuditingItem: StagingAuditingItem;

  @NeededBy(() => ImpureSortableCrossdockClassificationItem, 'inventoryItemId')
  impureSortableCrossdockClassificationItem: ImpureSortableCrossdockClassificationItem;

  @Knows(() => DispatchingItem, 'inventoryItemId')
  dispatchingItem: DispatchingItem;

  @Knows(() => SlottingItem, 'inventoryItemId')
  slottingItem: SlottingItem;
}

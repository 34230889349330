import { TransportOrderStatuses } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetItemByLabelFn, Item } from '../machine';

export function getTransportOrderByLabel(
  defaultErrorMessage: string
): GetItemByLabelFn {
  // Item MUST have a label. Ex: {id: 1, label: string}.
  // You MUST NOT return only the label on the endpoint.
  const params = new URLSearchParams();
  params.set('status', TransportOrderStatuses.Processing);
  return fetchResult(defaultErrorMessage, label =>
    api.get<Item>(`transport-order/get-by-label/${label}`, {
      params
    })
  );
}

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { TaskRoleLocation } from './TaskRoleLocation';
import { TaskRoleTaskType } from './TaskRoleTaskType';
import { User } from './User';
import { UserTaskRole } from './UserTaskRole';
import { Warehouse } from './Warehouse';
import { WorkloadList } from './WorkloadList';

export const TaskRoleDeveloper = {
  Developer: 'Developer'
};

export const TaskRoleManager = {
  'Gerencia Administrativa': 'Gerencia Administrativa'
};

export const TaskRoleShift = {
  'Jefe Turno M': 'Jefe Turno M',
  'Jefe Turno T': 'Jefe Turno T',
  'Jefe Turno N': 'Jefe Turno N'
};
export const TaskRoleAdmin = {
  Admin: 'Admin',
  ...TaskRoleShift,
  ...TaskRoleManager,
  ...TaskRoleDeveloper
};

export const TaskRoleReservation = {
  'Lider Reservas':    'Lider Reservas',
  'Analista Reservas': 'Analista Reservas'
};

export const TaskRolePicking = {
  'Lider Preparación Mezzanine': 'Lider Preparación Mezzanine',
  'Picker MZ0':                  'Picker MZ0',
  'Picker MZ1':                  'Picker MZ1',
  'Picker MZ3':                  'Picker MZ3',
  'Lider Preparación Picktower': 'Lider Preparación Picktower',
  'Picker PR0':                  'Picker PR0',
  'Picker PR1':                  'Picker PR1',
  'Picker PR2':                  'Picker PR2',
  'Picker PR3':                  'Picker PR3'
};

export const TaskRoleDispaching = {
  'Jefe Tráfico':             'Jefe Tráfico',
  'Lider Tráfico':            'Lider Tráfico',
  'Operador Tráfico':         'Operador Tráfico',
  'Clasificador en la rampa': 'Clasificador en la rampa',
  'Lider despacho':           'Lider despacho',
  'Operario despacho':        'Operario despacho'
};

export const TaskRoleAuditing = {
  'Lider Auditoría':    'Líder Auditoría',
  'Analista Auditoría': 'Analista Auditoría',
  Auditor:              'Auditor',
  'Auditor MZ2':        'Auditor MZ2'
};

export const TaskRoleSlotting = {
  'Lider Slotting':    'Lider Slotting',
  'Operador Slotting': 'Operador Slotting'
};

export const TaskRoleSupport = {
  'Lider Soporte Logístico':    'Lider Soporte Logístico',
  'Analista Soporte Logístico': 'Analista Soporte Logístico'
};

export const TaskRoleClark = {
  'Lider Clark Nave2':      'Lider Clark Nave2',
  'Almacenaje Clark Nave2': 'Almacenaje Clark Nave2',
  'Reposición Clark Nave2': 'Reposición Clark Nave2'
};

export const TaskRoleOnFoot = {
  'Repositor a pie Nave2 MZ0': 'Repositor a pie Nave2 MZ0',
  'Repositor a pie Nave2 MZ1': 'Repositor a pie Nave2 MZ1',
  'Repositor a pie Nave2 MZ3': 'Repositor a pie Nave2 MZ3'
};

export const TaskRoleNovelty = {
  'Lider Novedades':    'Lider Novedades',
  'Operador Novedades': 'Operador Novedades'
};

export const TaskRoleInventory = {
  'Lider Inventario':    'Lider Inventario',
  'Operador Inventario': 'Operador Inventario',
  ...TaskRoleOnFoot,
  ...TaskRoleClark,
  ...TaskRoleNovelty
};

export const TaskRoleForklift = {
  'Lider Clampista Nave2':    'Lider Clampista Nave2',
  'Operador Clampista Nave2': 'Operador Clampista Nave2',
  'Repositor a pie Nave2':    'Repositor a pie Nave2'
};

export const TaskRoleOutbound = {
  ...TaskRoleAuditing,
  ...TaskRolePicking,
  ...TaskRoleDispaching,
  ...TaskRoleReservation
};

export const TaskRoleInbound = {
  'Lider recepción MINI-MIDI Nave2':    'Lider recepción MINI-MIDI Nave2',
  'Operador recepción MINI-MIDI Nave2': 'Operador recepción MINI-MIDI Nave2',
  'Lider recepción XD':                 'Lider recepción XD',
  'Operador recepción XD':              'Operador recepción XD',
  'Lider Recepción XD':                 'Lider Recepción XD',
  'Operador Recepción XD':              'Operador Recepción XD',
  ...TaskRoleClark,
  ...TaskRoleForklift,
  ...TaskRoleNovelty,
  ...TaskRoleSlotting
};

export const TaskRoleNames = {
  ...TaskRoleAdmin,
  ...TaskRoleDeveloper,
  ...TaskRoleManager,
  ...TaskRoleShift,
  ...TaskRoleOutbound,
  ...TaskRoleSupport,
  ...TaskRoleInventory,
  ...TaskRoleInbound,
  ...TaskRoleOnFoot
};

@Entity()
export class TaskRole {
  @PrimaryKey()
  id: number;

  @Field({ name: 'name' })
  name: string;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  // JSONB field defined in the backend.
  roles: any;

  @Field({ name: 'identifier_name' })
  identifierName: string;

  @Field({ name: 'icon_url' })
  iconUrl: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => User, 'authorId')
  author: User;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @NeededBy(() => UserTaskRole, 'taskRoleId')
  usersTaskRole: UserTaskRole[];

  @NeededBy(() => TaskRoleTaskType, 'taskRoleId')
  taskRoleTaskTypes: TaskRoleTaskType[];

  @NeededBy(() => TaskRoleLocation, 'taskRoleId')
  taskRoleLocations: TaskRoleLocation[];

  @NeededBy(() => WorkloadList, 'taskRoleId')
  workloadLists: WorkloadList[];
}

import { MachineOptions, assign } from 'xstate';
import { GetLocationByCheckDigitContext } from './machine';

type GetLocationByCheckDigitActions = MachineOptions<
  GetLocationByCheckDigitContext,
  any
>['actions'];

export const getLocationByCheckDigitActions: GetLocationByCheckDigitActions = {
  updateLocationCheckDigit: assign({
    locationCheckDigit: (_context, event) => event.locationCheckDigit
  }),
  assignLocation: assign({
    location: (_context, event) => event.data.data
  }),
  assignError: assign({
    error: (_context, event) => event.data.error
  }),
  clearError: assign({
    error: (_context, _event) => null
  }),
  clearCheckDigit: assign({
    locationCheckDigit: (_context, _event) => ''
  })
};

import { SellerProduct } from '@wms/domain';
import { createMachine } from 'xstate';
import { Maybe } from '../../../../../utils/maybe';
import { getSellerProductByCodeActions } from './actions';
import { getSellerProductByCodeGuards } from './guards';
import { getSellerProductByCodeServices } from './services';

export interface GetSellerProductByCodeContext {
  readonly hint: string;
  sellerProductCode: string;
  sellerProduct: SellerProduct | null;
  error: string | null;
}

export type GetSellerProductByCodeFn = (
  code: string
) => Promise<Maybe<SellerProduct>>;

export const GetSellerProductByCodeMachineId = 'GetSellerProductByCode';

export const GetSellerProductByCodeMachine = (
  hint: string,
  fetchFn: GetSellerProductByCodeFn
) =>
  createMachine(
    {
      id:                         GetSellerProductByCodeMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as GetSellerProductByCodeContext
      },
      context: {
        hint,
        sellerProductCode: '',
        sellerProduct:     null,
        error:             null
      },
      initial: 'EnteringSellerProductCode',
      states:  {
        EnteringSellerProductCode: {
          on: {
            UpdateSellerProductCode: {
              actions: [
                'updateSellerProductCode',
                'clearError',
                'clearParentError'
              ]
            },
            SubmitSellerProductCode: {
              cond:   'isValidSellerProductCode',
              target: 'FetchingSellerProduct'
            }
          }
        },
        FetchingSellerProduct: {
          tags:   ['loading'],
          invoke: {
            src:    'fetchSellerProduct',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignSellerProduct',
                target:  'Finished'
              },
              {
                actions: 'assignError',
                target:  'EnteringSellerProductCode'
              }
            ]
          }
        },
        Finished: {
          type: 'final',
          data: (context, _event) => context.sellerProduct
        }
      }
    },
    {
      guards:   getSellerProductByCodeGuards,
      actions:  getSellerProductByCodeActions,
      services: getSellerProductByCodeServices(fetchFn)
    }
  );

import { NoveltyUnloadingProcessStatuses } from '../NoveltyUnloadingProcess';
import { NoveltyUnloadingProcessStatus } from './NoveltyUnloadingProcessStatus';

export class NoveltyUnloadingProcessStatusProcessingStatus extends NoveltyUnloadingProcessStatus {
  public displayName = 'Procesando';

  public internalName = NoveltyUnloadingProcessStatuses.Processing;

  public statusColor = '#Ffffff';
}

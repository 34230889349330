import { FeedingToSorterProcessStatuses } from '../FeedingToSorterProcess';
import { FeedingToSorterProcessStatus } from './FeedingToSorterProcessStatus';

export class FeedingToSorterProcessStatusProcessingStatus extends FeedingToSorterProcessStatus {
  public displayName = 'Procesando';

  public internalName = FeedingToSorterProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

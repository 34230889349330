import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Location } from './Location';
import { Order } from './Order';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import { StagingAuditingProcess } from './StagingAuditingProcess';
import { StagingAuditingWaveCancelledStatus } from './staging-auditing-wave-status/StagingAuditingWaveCancelledStatus';
import { StagingAuditingWaveCompletedStatus } from './staging-auditing-wave-status/StagingAuditingWaveCompletedStatus';
import { StagingAuditingWavePausedStatus } from './staging-auditing-wave-status/StagingAuditingWavePausedStatus';
import { StagingAuditingWavePendingStatus } from './staging-auditing-wave-status/StagingAuditingWavePendingStatus';
import { StagingAuditingWaveProcessingStatus } from './staging-auditing-wave-status/StagingAuditingWaveProcessingStatus';
import { StagingAuditingWaveQuantityMismatchedStatus } from './staging-auditing-wave-status/StagingAuditingWaveQuantityMismatchedStatus';
import { StagingAuditingWaveStatus } from './staging-auditing-wave-status/StagingAuditingWaveStatus';

export const StagingAuditingWaveStatuses = {
  Pending:            'pending',
  Processing:         'processing',
  Paused:             'paused',
  Completed:          'completed',
  Cancelled:          'cancelled',
  QuantityMismatched: 'quantity-mismatched'
};

@Entity()
export class StagingAuditingWave {
  @PrimaryKey()
  id: number;

  @Status({
    [StagingAuditingWaveStatuses.Pending]:    StagingAuditingWavePendingStatus,
    [StagingAuditingWaveStatuses.Processing]:
      StagingAuditingWaveProcessingStatus,
    [StagingAuditingWaveStatuses.Paused]:             StagingAuditingWavePausedStatus,
    [StagingAuditingWaveStatuses.Completed]:          StagingAuditingWaveCompletedStatus,
    [StagingAuditingWaveStatuses.Cancelled]:          StagingAuditingWaveCancelledStatus,
    [StagingAuditingWaveStatuses.QuantityMismatched]:
      StagingAuditingWaveQuantityMismatchedStatus
  })
  status: StagingAuditingWaveStatus;

  @ForeignKey(() => Location, { name: 'staging_id' })
  stagingId: number;

  @Field({ name: 'min_percentage' })
  minPercentage: number;

  @ForeignKey(() => StagingAuditingProcess, {
    name: 'staging_auditing_process_id'
  })
  stagingAuditingProcessId: number;

  @ForeignKey(() => Order, { name: 'order_id' })
  orderId: number;

  @Knows(() => Order, 'orderId')
  order: Order;

  @ForeignKey(() => StagingAuditingWave, { name: 'next_wave_id' })
  nextWaveId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => StagingAuditingProcess, 'stagingAuditingProcessId')
  stagingAuditingProcess: StagingAuditingProcess;

  @Knows(() => StagingAuditingContainer, 'stagingAuditingWaveId')
  stagingAuditingContainers: StagingAuditingContainer[];

  @Knows(() => StagingAuditingWave, 'nextWaveId')
  nextWave: StagingAuditingWave;

  @Needs(() => Location, 'stagingId')
  staging: Location;
}

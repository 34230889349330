import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanContainer } from '../../capa-4/scan-container/ScanContainer';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { MiniMopPickingWaveMachine } from './machine';

export const MiniMopPickingWaveTask = withActorRef(MiniMopPickingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getDisplayName = (pickingCartType: string) => {
      switch (pickingCartType) {
        case 'tote-picking-cart-9':
          return 'Carro de Picking 9 Cubetas';
        default:
          return 'N/A';
      }
    };
    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            Ola Nº{current.context.pickingWaveId || 'N/A'} - AB TIE - Mezzanine
            Nivel {current.context.level}
          </Ink.Text>
        </Ink.Header>
        {/* Header */}
        <Ink.Box flexDirection='column'>
          {current.matches('SelectingPickingCartType') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Tomar carro de picking</Ink.Text>
              {current.context.pickingCartTypes?.map(type => (
                <Ink.Button
                  label={getDisplayName(type)}
                  onClick={() =>
                    send({
                      type: 'pickingCartTypeSelected',
                      data: { pickingCartType: type }
                    })
                  }
                  key={type}
                />
              ))}
            </Ink.Box>
          ) : null}

          {current.matches('ScanningPickingCart') ? (
            <>
              <HighlightedInfo
                attribute='Confirme'
                value={getDisplayName(current.context.pickingCartType!)}
              />
              <GetLocationByName id='ScanningPickingCart' />
            </>
          ) : null}

          {current.matches('ScanningContainer') ? (
            <>
              <Ink.Text color='greenBright' bold>
                Creación de cubetas
              </Ink.Text>
              <HighlightedInfo
                attribute='Picking Cart'
                value={current.context.pickingCart?.name || 'N/A'}
              />
              <IntercalatedText
                texts={['Debes crear', 'cubeta/s']}
                values={[current.context.branchOfficeTotal!.toString()]}
              />
              <IntercalatedText
                texts={['Cubetas creadas', '/']}
                values={[
                  current.context.branchOfficeCreated.toString(),
                  current.context.branchOfficeTotal!.toString()
                ]}
              />
            </>
          ) : null}

          {current.matches('OpportunisticCounting') && (
            <>
              <Ink.Text bold>Confirme cantidad restante en ubicación</Ink.Text>
              <Ink.Chalk greenBright bold>
                {`${current.context.currentPickItem!.sku} - ${
                  current.context.currentPickItem!.product.name
                }`}
              </Ink.Chalk>
              <HighlightedInfo
                attribute='Ubicación'
                value={
                  current.context.currentPickItem!.pickingLocation.name || 'N/A'
                }
              />
            </>
          )}

          {current.matches('SelectingPickingCartPosition') ? (
            <>
              <Ink.Text bold>Indique la posicion dentro del carro</Ink.Text>
              <HighlightedInfo
                attribute='Cubeta'
                value={current.context.containerLpn || 'N/A'}
              />
              <HighlightedInfo
                attribute='Picking Cart'
                value={current.context.pickingCart?.name || 'N/A'}
              />
              <IntercalatedText
                texts={['Cubeta', '/']}
                values={[
                  current.context.branchOfficeCreated.toString(),
                  current.context.branchOfficeTotal!.toString()
                ]}
              />
              <EnterQuantity />
              <Ink.Text>
                <Ink.Chalk bold color='redBright'>
                  {current.context.positionError || ''}
                </Ink.Chalk>
              </Ink.Text>
            </>
          ) : null}

          {current.matches('AwaitingRestocking') && (
            <>
              <Ink.Text> </Ink.Text>
              <Ink.Text>El item actual está esperando ser restockeado</Ink.Text>
            </>
          )}

          {current.matches('ScanningItemLocation') ? (
            <>
              <Ink.Text>
                SKU:&nbsp;
                <Ink.Chalk bold>
                  {current.context.currentPickItem!.product!.sku}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Chalk>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
              <Ink.Text bold>Trasladese Hasta la ubicacion</Ink.Text>
              <Ink.Text>
                <Ink.Chalk bold color='greenBright'>
                  {current.context.currentPickItem!.pickingLocation.name}
                </Ink.Chalk>
              </Ink.Text>
              <GetLocationByName id='ScanningItemLocation' />
            </>
          ) : null}

          {current.matches('ScanningPickItem') ? (
            <>
              <HighlightedInfo
                attribute='Ubicación'
                value={current.context.currentPickItem!.pickingLocation.name}
              />
              <HighlightedInfo
                attribute='Escanee SKU del producto'
                value={current.context.currentPickItem!.sku}
              />
              <Ink.Chalk bold color='greenBright'>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
            </>
          ) : null}

          {current.matches('WarningPickingCartToSorter') ? (
            <>
              <Ink.Text>
                Lleve su carrito de picking al sorter y confirme para dejar las
                cubetas
              </Ink.Text>
              <HighlightedInfo
                attribute='Picking Cart'
                value={current.context.pickingCart?.name || 'N/A'}
              />
              <Ink.Button
                label='Comenzar a inducir cubetas'
                onClick={() => send('CONFIRM')}
              />
            </>
          ) : null}

          {current.matches('ScanningMiniMopContainer') ? (
            <>
              <Ink.Text bold>Dejando cubetas en el sorter </Ink.Text>
              <HighlightedInfo
                attribute='Escanee cubeta'
                value={
                  current.context.currentMopPickingContainerToClose!.container
                    .lpn
                }
              />
              <GetContainerByLpn id='ScanningMiniMopContainer' />
            </>
          ) : null}

          {current.matches('ScanningPickItemDestinyLocation') ? (
            <>
              <HighlightedInfo
                attribute='Ubicación'
                value={current.context.currentPickItem!.pickingLocation.name}
              />
              <HighlightedInfo
                attribute='SKU'
                value={current.context.currentPickItem!.sku}
              />
              <Ink.Chalk bold color='greenBright'>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
              <IntercalatedText
                texts={['Cubeta de destino:', '- Posición:']}
                values={[
                  current.context.pickItemDestinyContainerLocation!.container
                    .lpn,
                  current.context.pickItemDestinyContainerLocation!
                    .pickingCartPosition
                ]}
              />
            </>
          ) : null}

          {current.matches('ScanningItemQuantity') ? (
            <>
              <HighlightedInfo
                attribute='Ubicación'
                value={current.context.currentPickItem!.pickingLocation.name}
              />
              <IntercalatedText
                texts={['Deposite', 'unidad/es de']}
                values={[current.context.currentPickItem!.quantity]}
              />
              <HighlightedInfo
                attribute='SKU'
                value={current.context.currentPickItem!.sku}
              />
              <Ink.Chalk bold color='greenBright'>
                {current.context.currentPickItem!.product!.name}
              </Ink.Chalk>
              <IntercalatedText
                texts={['En cubeta:', '- Posición:']}
                values={[
                  current.context.pickItemDestinyContainerLocation!.container
                    .lpn,
                  current.context.pickItemDestinyContainerLocation!
                    .pickingCartPosition
                ]}
              />
            </>
          ) : null}

          {current.matches('ConfirmingScreen') ? (
            <>
              <Ink.Box flexDirection='column'>
                <Ink.Box paddingBottom={2}>
                  <Ink.Text bold color='greenBright'>
                    Picking Completado
                  </Ink.Text>
                </Ink.Box>
                {current.context.missingItems?.length !== 0 && (
                  <>
                    <Ink.Text>Los siguientes items estan faltantes:</Ink.Text>
                    {current.context.missingItems!.map(item => (
                      <>
                        <Ink.Text key={item.id}>
                          {item.inventoryItem?.sku} -{' '}
                          {item.inventoryItem?.product?.name} - Cantidad:{' '}
                          {item.quantity}
                        </Ink.Text>
                      </>
                    ))}
                  </>
                )}
                {current.context.missingItems?.length === 0 && (
                  <Ink.Text>Todos los items han sido tomados.</Ink.Text>
                )}
                <Ink.Box flexDirection='row' justifyContent='space-between'>
                  <Ink.Button
                    style={{ width: '50%' }}
                    onClick={() => {
                      send('confirm');
                    }}
                    label='Confirmar y salir'
                  />
                </Ink.Box>
              </Ink.Box>
            </>
          ) : null}
          {current.matches('AwaitingRestocking') && (
            <>
              <Ink.Text bold>¿Que deseas hacer?</Ink.Text>
              <Ink.Button
                onClick={() => {
                  send('refetch');
                }}
                label='Consultar disponibilidad del producto'
              />
              <Ink.Button
                onClick={() => {
                  send('skip');
                }}
                label='Ir al siguiente item'
              />
            </>
          )}

          {current.matches('MissingItemErrorScreen') && (
            <>
              {current.context.error && (
                <ErrorMessage error={current.context.error!!} />
              )}
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '40%' }}
                  onClick={() => {
                    send('return');
                  }}
                  label='Volver'
                />
              </Ink.Box>
            </>
          )}

          {current.matches({
            PrematurelyCloseContainer: 'ScanningOldContainer'
          }) && (
            <>
              <Ink.Box flexDirection='column'>
                <Ink.Box marginBottom={2} flexDirection='column'>
                  <Ink.Chalk bold color='orangeBright'>
                    Cerrando cubeta prematuramente por espacio insuficiente
                  </Ink.Chalk>
                  <Ink.Text bold>
                    Acerquese a la zona del sorter con el contendor, escaneelo y
                    depositelo sobre la cinta.
                  </Ink.Text>
                  <Ink.Text>
                    Recuerde dejar el item que estaba pickeando en la ubicación
                    original
                  </Ink.Text>
                  <HighlightedInfo
                    attribute='Ubicación'
                    value={
                      current.context.currentPickItem?.pickingLocation.name ||
                      'N/A'
                    }
                  />
                </Ink.Box>
                <HighlightedInfo
                  attribute='LPN'
                  value={
                    current.context.pickItemDestinyContainerLocation!.container!
                      .lpn
                  }
                />
              </Ink.Box>
            </>
          )}

          {current.matches({
            PrematurelyCloseContainer: 'EnteringNewContainer'
          }) ? (
            <>
              <Ink.Text color='greenBright' bold>
                Creacion de cubeta nueva
              </Ink.Text>
              <Ink.Text>
                Ahora busque una nueva cubeta y vuelva a la ubicación del
                picking donde dejó el item con ella.
              </Ink.Text>
              <HighlightedInfo
                attribute='Ubicación'
                value={
                  current.context.currentPickItem?.pickingLocation.name || 'N/A'
                }
              />
              <HighlightedInfo
                attribute='Ponga la cubeta en la posicion'
                value={
                  current.context.pickItemDestinyContainerLocation!
                    .pickingCartPosition
                }
              />
            </>
          ) : null}

          {current.matches({
            PrematurelyCloseContainer: 'ConfirmCloseEmptyContainer'
          }) ? (
            <>
              <Ink.Text color='greenBright' bold>
                Creacion de cubeta nueva
              </Ink.Text>
              <Ink.Text>
                La cubeta que está queriendo cerrar está vacía. No se puede
                cerrar prematuramente una cubeta sin haber pickeado ningún item
                con la cubeta.
              </Ink.Text>
              <Ink.Text>
                Si la cubeta tenía items de antemano, contacte a soporte.
              </Ink.Text>
            </>
          ) : null}

          {current.matches('ConfirmEmptyContainerInLocation') ? (
            <>
              <Ink.Box flexDirection='column'>
                <Ink.Box marginBottom={2} flexDirection='column'>
                  <Ink.Chalk bold color='greenBright'>
                    Cubeta cerrada sin items
                  </Ink.Chalk>
                  <Ink.Text bold>
                    Se ha reportado faltante de todos los items de esta cubeta.
                  </Ink.Text>
                  <Ink.Text bold>
                    Como no has pickeado ningun ítem con esta cubeta, deja el
                    contenedor donde lo habias agarrado.
                  </Ink.Text>
                </Ink.Box>
                <HighlightedInfo
                  attribute='LPN'
                  value={
                    current.context.currentMopPickingContainerToClose!.container
                      .lpn
                  }
                />
              </Ink.Box>
            </>
          ) : null}
        </Ink.Box>

        {/* body */}

        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : null}

          {current.hasTag('containerCreate') ? (
            <Ink.Box flexDirection='column'>
              <EnterLpn />
            </Ink.Box>
          ) : null}

          {current.hasTag('sku') ? (
            <Ink.Box flexDirection='column'>
              <ScanSKU />
            </Ink.Box>
          ) : null}

          {current.hasTag('quantity') ? (
            <Ink.Box flexDirection='column'>
              <EnterQuantity />
            </Ink.Box>
          ) : null}

          {current.matches('ScanningItemQuantity') && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                label='Espacio insuficiente en cubeta'
                onClick={() => send('closeContainer')}
              />{' '}
            </Ink.Box>
          )}

          {current.matches({
            PrematurelyCloseContainer: 'ScanningOldContainer'
          }) && (
            <Ink.Box flexDirection='column'>
              <ScanContainer />
              <Ink.Button
                label='Cancelar cierre'
                onClick={() => send('cancelCloseContainer')}
              />{' '}
            </Ink.Box>
          )}

          {current.matches({
            PrematurelyCloseContainer: 'ConfirmCloseEmptyContainer'
          }) && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                label='Cancelar cierre de cubeta'
                onClick={() => send('confirm')}
              />{' '}
            </Ink.Box>
          )}

          {current.hasTag('containerGet') ? (
            <Ink.Box flexDirection='column'>
              <GetContainerByLpn />
            </Ink.Box>
          ) : null}

          {current.matches('ConfirmEmptyContainerInLocation') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                style={{ width: '100%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Ya dejé el contenedor en su lugar'
              />{' '}
            </Ink.Box>
          ) : null}
        </>
        {current.context.error &&
          !current.matches('MissingItemErrorScreen') && (
            <ErrorMessage bold error={current.context.error!!} />
          )}
      </Ink.Box>
    );
  }
);

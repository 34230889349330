import { StandardUnloadingContainerStatuses } from '../StandardUnloadingContainer';
import { StandardUnloadingContainerStatus } from './StandardUnloadingContainerStatus';

export class StandardUnloadingContainerStatusCancelledStatus extends StandardUnloadingContainerStatus {
  public displayName = 'Cancelado';

  public internalName = StandardUnloadingContainerStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { MachineOptions, assign } from 'xstate';
import { ConsolidationClasificationContext } from './machine';

type ConsolidationClassificationActionsType = MachineOptions<
  ConsolidationClasificationContext,
  any
>['actions'];

export const consolidationClasificationActions: ConsolidationClassificationActionsType =
  {
    assignLabel: assign({
      label: (_ctx, event) => event.data.label
    }),
    assignClassificationItem: assign({
      classificationItem:          (_ctx, event) => event.data.classificationItem,
      classificationItemLocation:  (_ctx, event) => event.data.location,
      classificationItemWarehouse: (_ctx, event) => event.data.warehouse,
      classificationItemRoute:     (_ctx, event) => event.data.route
    }),
    assignClassificationContainer: assign({
      classificationContainer: (_ctx, event) =>
        event.data.classificationContainer
    }),
    clearCtx: assign({
      label:                       (_, __) => null,
      classificationItem:          (_, __) => null,
      classificationContainer:     (_, __) => null,
      classificationItemLocation:  (_, __) => null,
      classificationItemWarehouse: (_, __) => null,
      classificationItemRoute:     (_, __) => null,
      newContainerLabel:           (_, __) => null,
      suggestedSlotPosition:       (_, __) => null,
      selectedContainerTypeName:   (_, __) => null,
      selectedContainerType:       (_, __) => null,
      error:                       (_, __) => null,
      isLastItem:                  (_, __) => false
    }),
    assignError: assign({
      error: (_ctx, event) => event.data.response.data.errorView
    }),
    assignSlotPosition: assign({
      suggestedSlotPosition: (_ctx, event) => event.data.slotPosition
    }),
    assignSelectedContainerTypeName: assign({
      selectedContainerTypeName: (_ctx, event) => event.data.containerTypeName
    }),
    assignSelectedContainerType: assign({
      selectedContainerType: (_ctx, event) => event.data.containerType
    }),
    assignNewContainerLabel: assign({
      newContainerLabel: (_ctx, event) => event.data.lpn
    }),
    clearMessages: assign({
      error:   (_, __) => null,
      success: (_, __) => null
    }),
    assignContainerToClose: assign({
      containerToClose: (_ctx, event) => event.data
    }),
    assignDeliveryType: assign({
      deliveryType: (_ctx, event) => event.data.deliveryType
    }),
    assignIsLastItem: assign({
      isLastItem: (_ctx, event) => event.data.isLastItem
    }),
    assignInvalidLabel: assign({
      error: _ctx => 'La etiqueta es igual al del bulto a depositar.'
    }),
    assignContainersToClose: assign({
      classificationContainersToClose: (_ctx, event) =>
        event.data.containersToClose
    })
  };

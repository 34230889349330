import { Package, PackingItem, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { fetchPackingItemsFromPackageByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-packing-items-from-label';
import {
  GetItemByLabelMachine,
  GetItemByLabelMachineId
} from '../../layer-4/label/get-item-by-label/machine';
import { ptwPackageInductionActions } from './actions';
import { ptwPackageInductionGuards } from './guards';
import { ptwPackageInductionServices } from './services';

export interface PtwPackageInductionContext {
  packageLabel: string;
  entity: string | null;
  packingItems: PackingItem[];
  error: string | null;
  hint: string | '';
  task: Task | null;
  package: Package | null;
}

export const DefaultPtwPackageInductionContext: PtwPackageInductionContext = {
  packageLabel: '',
  entity:       null,
  packingItems: [],
  error:        null,
  hint:         'Confirme escaneando bulto:',
  task:         null,
  package:      null
};

export const PtwPackageInductionMachine = createMachine(
  {
    id:     'PtwPackageInduction',
    schema: {
      context: {} as PtwPackageInductionContext
    },
    context: DefaultPtwPackageInductionContext,
    initial: 'ScanLabel',
    states:  {
      ScanLabel: {
        invoke: {
          id:  GetItemByLabelMachineId,
          src: _ctx =>
            GetItemByLabelMachine(
              'Escanee etiqueta',
              fetchPackingItemsFromPackageByLabel(
                'Por favor, ingrese una etiqueta valida'
              )
            ),
          onDone: [
            {
              cond:    'checkIfTheyAreAllPendingPackingItems',
              actions: ['assignPackageData', 'clearError'],
              target:  'GetTaskByPackageLabel'
            },
            {
              target:  'FeedPackageToSorterExit',
              actions: ['clearError']
            }
          ],
          onError: {
            target:  'ThereAreNonPendingPackingItems',
            actions: ['assignError', 'clearContextData']
          }
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      ThereAreNonPendingPackingItems: {
        on: {
          BackToScanLabel: {
            target:  'ScanLabel',
            actions: 'clearContextData'
          }
        }
      },
      GetTaskByPackageLabel: {
        invoke: {
          src:    'fetchTaskByPackageLabel',
          onDone: {
            target:  'MiniPtwNewPackageInExitTrack',
            actions: ['assignTask']
          },
          onError: {
            actions: 'assignError',
            target:  'ThereAreNonPendingPackingItems'
          }
        }
      },
      MiniPtwNewPackageInExitTrack: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniPtwNewPackageInExitTrack',
              payload: {
                packingItems: ctx.packingItems,
                package:      ctx.package
              }
            };
          },
          onDone: [
            {
              target: 'MiniPtwCheckIfHasPendingPackingItems'
            }
          ],
          onError: {
            target:  'ScanLabel',
            actions: ['assignError', 'clearContextData']
          }
        }
      },
      MiniPtwCheckIfHasPendingPackingItems: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MiniPtwCheckIfHasPendingPackingItems',
              payload: {
                packingItems: ctx.packingItems,
                package:      ctx.package
              }
            };
          },
          onDone: [
            {
              target: 'FeedPackageToSorterExit'
            }
          ],
          onError: {
            target:  'ScanLabel',
            actions: ['assignError', 'clearContextData']
          }
        }
      },
      FeedPackageToSorterExit: {
        on: {
          BackToScanLabel: {
            target:  'ScanLabel',
            actions: 'clearContextData'
          }
        }
      },
      Final: {
        type: 'final'
      }
    }
  },
  {
    actions:  ptwPackageInductionActions,
    services: ptwPackageInductionServices,
    guards:   ptwPackageInductionGuards
  }
);

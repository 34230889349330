import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { WorkloadList } from './WorkloadList';

@Entity()
export class WorkBoard {
  @PrimaryKey()
  id: number;

  @Field({ name: 'title' })
  title: string;

  @Field({ name: 'description' })
  description: string;

  @Field({ name: 'icon' })
  icon: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => WorkloadList, 'boardId')
  workloadLists: WorkloadList[];
}

import { PendingDispatchingPickItemStatuses } from '../PendingDispatchingPickItem';
import { PendingDispatchingPickItemStatus } from './PendingDispatchingPickItemStatus';

export class PendingDispatchingPickItemCancelledStatus extends PendingDispatchingPickItemStatus {
  public displayName = 'Cancelado';

  public internalName = PendingDispatchingPickItemStatuses.Cancelled;

  public statusColor = '#b90c0c';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { PickList } from './PickList';
import { PutToWallWave } from './PutToWallWave';
import { Reservation } from './Reservation';
import {
  PutToWallOrderStatus,
  PutToWallOrderStatusCancelledStatus,
  PutToWallOrderStatusNotPreparedStatus,
  PutToWallOrderStatusPreparedStatus,
  PutToWallOrderStatusPreparingStatus
} from './put-to-wall-order-status';

export const PutToWallOrderStatuses = {
  NotPrepared: 'not-prepared',
  Preparing:   'preparing',
  Prepared:    'prepared',
  Cancelled:   'cancelled'
};

@Entity()
export class PutToWallOrder {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PutToWallWave, { name: 'ptw_wave_id' })
  ptwWaveId: number;

  @Knows(() => PutToWallWave, 'ptwWaveId')
  putToWallWave: PutToWallWave;

  @Field()
  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @NeededBy(() => PickList, 'ptwOrderId')
  pickLists: PickList[];

  @Status({
    [PutToWallOrderStatuses.NotPrepared]: PutToWallOrderStatusNotPreparedStatus,
    [PutToWallOrderStatuses.Preparing]:   PutToWallOrderStatusPreparingStatus,
    [PutToWallOrderStatuses.Prepared]:    PutToWallOrderStatusPreparedStatus,
    [PutToWallOrderStatuses.Cancelled]:   PutToWallOrderStatusCancelledStatus
  })
  status: PutToWallOrderStatus;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  PrimaryKey
} from '@skyframe/core';
import { LocationProductAttribute } from './LocationProductAttribute';
import { Product } from './Product';

export const ProductAttributeVolume = {
  MINI: 'mini',
  MIDI: 'midi',
  MAXI: 'maxi',
  BIG:  'big'
} as const;

export const ProductAttributeMhe = {
  CLAMP:      'clamp',
  PALLETJACK: 'palleteJack',
  FORKLIFT:   'forklift'
} as const;

export const ProductAttributeRotation = {
  AP: 'A+',
  A:  'A',
  B:  'B',
  C:  'C',
  CM: 'C-'
} as const;

export const ProductAttributePalletizationRelation = {
  ONE_TO_ONE:            '1:1',
  ONE_TO_ONE_AND_A_HALF: '1:1.5',
  ONE_TO_TWO:            '1:2'
};

export const ProductAttributeType = {
  VOLUME:                'volume',
  MHE:                   'mhe',
  MAXCLAMPCAPACITY:      'clampMaxQtyByMovement',
  ROTATION:              'rotation',
  SUGGESTEDCONTAINER:    'suggestedContainer',
  FRAGILITY:             'fragility',
  PALLETIZATIONRELATION: 'palletizationRelation',
  SELFSTACKING:          'selfStacking',
  SUGGESTEDPACKTYPE:     'suggestedPackType',
  PRICE:                 'price',
  RESTOCKINGQUANTITY:    'restockingQuantity'
} as const;

export type productAttributeType =
  (typeof ProductAttributeType)[keyof typeof ProductAttributeType];

export type productAttributeVolume =
  (typeof ProductAttributeVolume)[keyof typeof ProductAttributeVolume];

export type productAttributeMhe =
  (typeof ProductAttributeMhe)[keyof typeof ProductAttributeMhe];

export type productAttributeRotation =
  (typeof ProductAttributeRotation)[keyof typeof ProductAttributeRotation];

export type productAttributePalletizationRelation =
  (typeof ProductAttributePalletizationRelation)[keyof typeof ProductAttributePalletizationRelation];

export type productAttributeValues =
  | productAttributeVolume
  | productAttributeMhe
  | productAttributeRotation
  | productAttributePalletizationRelation;
@Entity()
export class ProductAttribute {
  @PrimaryKey()
  id: number;

  @Field()
  type: string;

  @Field()
  name: string;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Product, 'productId')
  product: Product;

  @NeededBy(() => LocationProductAttribute, 'productAttributeId')
  locationProductAttributes: LocationProductAttribute[];
}

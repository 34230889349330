import Ink from '@ink';
import { LabelOrderType } from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { Print } from '../../layer-4/printer/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { MiniPackingWaveMachine } from './machine';

const otherPackTypeReasons = [
  {
    id:    1,
    label: 'El producto es demasiado grande para el packaging sugerido'
  },
  {
    id:    2,
    label: 'El producto es demasiado pequeño para el packaging sugerido'
  },
  {
    id:    3,
    label: 'No hay stock de packaging sugerido'
  },
  {
    id:    99,
    label: 'Otras razones'
  }
];

export const MiniPackingWaveTask = withActorRef(MiniPackingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Header>Ola de Packing #{current.context.packingWaveId}</Ink.Header>

        {current.hasTag('loading') && (
          <Ink.Box>
            <Ink.Text>Cargando...</Ink.Text>
          </Ink.Box>
        )}

        {current.hasTag('printing') && (
          <Ink.Box>
            <Print />
          </Ink.Box>
        )}

        {(current.matches('ScanningSKU') ||
          current.matches('ConfirmingSKU')) && (
          <>
            <HighlightedInfo
              attribute='Ubicación'
              value={current.context.container?.location.name || ''}
            />
            <HighlightedInfo
              attribute='Cubeta'
              value={current.context.container?.lpn || ''}
            />

            <GetProductBySKU />
          </>
        )}

        {current.matches('SelectingPackageType') && (
          <Ink.Box flexDirection='column'>
            <HighlightedInfo
              attribute='Ubicación'
              value={current.context.container?.location.name || ''}
            />
            <HighlightedInfo
              attribute='Cubeta'
              value={current.context.container?.lpn || ''}
            />

            <HighlightedInfo
              attribute='Elija packaging para el producto'
              value={current.context.currentPackingItem!.orderItem.sku}
            />
            <Ink.Text color='greenBright' bold>
              {current.context.currentPackingItem?.orderItem.product.name}
            </Ink.Text>
            <HighlightedInfo
              attribute='Tomar'
              value={current.context.suggestedPackageType!.name}
            />
            <Ink.Button
              id={current.context.suggestedPackageType?.id.toString()}
              label={current.context.suggestedPackageType?.name}
              onClick={() => {
                send({
                  type: 'select',
                  data: { packType: current.context.suggestedPackageType }
                });
              }}
            />
            <Ink.Button
              id='other'
              label='Tomar otro packaging'
              onClick={() => {
                send({
                  type: 'other'
                });
              }}
            />
          </Ink.Box>
        )}

        {current.matches('SelectingOtherPackageType') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Elija packaging</Ink.Text>
            <Ink.Text>
              Se sugiere {current.context.suggestedPackageType?.name}
            </Ink.Text>
            {current.context.otherPackageTypes?.map(packType => (
              <Ink.Button
                key={packType.id}
                label={packType.name}
                onClick={() => {
                  send({
                    type: 'select',
                    data: { packType }
                  });
                }}
              />
            ))}
          </Ink.Box>
        )}

        {current.matches('SelectingReason') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>¿Por que no utilizó el packaging sugerido?</Ink.Text>
            {otherPackTypeReasons.map(reason => (
              <Ink.Button
                key={reason.id}
                label={reason.label}
                onClick={() => {
                  send({
                    type: 'select',
                    data: { reason }
                  });
                }}
              />
            ))}
          </Ink.Box>
        )}

        {current.matches('ScanningLabel') && (
          <>
            <HighlightedInfo
              attribute='Ubicación'
              value={current.context.container?.location.name || ''}
            />
            <HighlightedInfo
              attribute='Cubeta'
              value={current.context.container?.lpn || ''}
            />

            <HighlightedInfo
              attribute='Producto'
              value={current.context.currentPackingItem!.orderItem.sku}
            />
            <Ink.Text color='greenBright' bold>
              {current.context.currentPackingItem?.orderItem.product.name}
            </Ink.Text>
            <ScanLabel />
            {current.context.typeOfLabel !== LabelOrderType.Ss && (
              <Ink.Button
                onClick={() => {
                  send('moveToPrintingLabels');
                }}
                label='Volver a la pantalla de impresion de etiquetas'
              />
            )}
          </>
        )}

        {current.matches({ PrintingLabels: 'ConfirmMercadoEnvios' }) && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Está preparando una órden Mercado Envíos</Ink.Text>
            <IntercalatedText
              texts={['Pegue la etiqueta de', 'en el bulto y confirme']}
              values={['Mercado Envios']}
            />
            <Ink.Button
              label='Etiqueta pegada'
              onClick={() => {
                send('confirm');
              }}
            />
          </Ink.Box>
        )}

        {current.matches({ PrintingLabels: 'ConfirmClientMercadoEnvios' }) && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Está preparando una órden Mercado Envíos</Ink.Text>
            <IntercalatedText
              texts={['Ahora, pegue la etiqueta de', 'en el bulto y confirme']}
              values={['cliente']}
            />
            <Ink.Button
              label='Etiqueta de cliente pegada'
              onClick={() => {
                send('confirm');
              }}
            />
          </Ink.Box>
        )}

        {current.context.error && (
          <ErrorMessage error={current.context.error} bold />
        )}
      </Ink.Box>
    );
  }
);

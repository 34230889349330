import { SlottingProcessStatuses } from '../SlottingProcess';
import { SlottingProcessStatus } from './SlottingProcessStatus';

export class SlottingProcessStatusPausedStatus extends SlottingProcessStatus {
  public displayName = 'Pausado';

  public internalName = SlottingProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { UnloadingProcessStatuses } from '../UnloadingProcess';
import { UnloadingProcessStatus } from './UnloadingProcessStatus';

export class UnloadingProcessStatusCancelledStatus extends UnloadingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = UnloadingProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { Task } from '@wms/domain';
import qs from 'qs';
import { Page } from '../types/page';
import { UseListApi, useListApi, useManualApi } from './utils';

export function useAutoAssignTask(
  offset: number = 0,
  needAutoAssign: boolean = false,
  id?: number
): [Page<Task>, ReactUseApi.State, ReactUseApi.RequestFn] {
  const url = id || needAutoAssign ? 'task/pick' : 'task/assignable-tasks';

  return useManualApi<Page<Task>>({
    method: 'GET',
    url:    url,
    params: { offset, ...(id ? { id } : {}) }
  });
}

export function useFindTasks(
  types: string[],
  perPage: number
): UseListApi<Task> {
  return useListApi<Task>(
    {
      method: 'GET',
      url:    'task/assignable-tasks',
      params: {
        qs: qs.stringify({
          type:   types,
          status: 'pending'
        })
      }
    },
    perPage
  );
}

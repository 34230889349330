import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { NoveltyMoveContainerToBufferItem } from './NoveltyMoveContainerToBufferItem';
import { Receipt } from './Receipt';
import {
  NoveltyMoveContainerToBufferProcessStatus,
  NoveltyMoveContainerToBufferProcessStatusCompletedStatus,
  NoveltyMoveContainerToBufferProcessStatusPendingStatus,
  NoveltyMoveContainerToBufferProcessStatusProcessingStatus
} from './novelty-move-container-to-buffer-process-status';

export const NoveltyMoveContainerToBufferProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class NoveltyMoveContainerToBufferProcess {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Status({
    [NoveltyMoveContainerToBufferProcessStatuses.Pending]:
      NoveltyMoveContainerToBufferProcessStatusPendingStatus,
    [NoveltyMoveContainerToBufferProcessStatuses.Processing]:
      NoveltyMoveContainerToBufferProcessStatusProcessingStatus,
    [NoveltyMoveContainerToBufferProcessStatuses.Completed]:
      NoveltyMoveContainerToBufferProcessStatusCompletedStatus
  })
  status: NoveltyMoveContainerToBufferProcessStatus;

  @NeededBy(
    () => NoveltyMoveContainerToBufferItem,
    'noveltyMoveContainerToBufferProcessId'
  )
  noveltyMoveContainerToBufferItems: NoveltyMoveContainerToBufferItem[];
}

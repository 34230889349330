import Ink from '@ink';
import { JobTypes, Task } from '@wms/domain/.';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:         'pallet-restock-inventory',
    displayName: 'RESTOCKEAR PALLET',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'pallet-restock-inventory' }
  },
  {
    key:         'manual-restocking',
    displayName: 'TRASLADO DE CONTENEDORES',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.ManualRestocking } as Task
    },
    checkTaskTypeAccess: true
  },
  {
    key:         'bulk-movement',
    displayName: 'TRASLADO DE PRODUCTO A GRANEL',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.BulkMovement } as Task
    },
    checkTaskTypeAccess: true
  },
  {
    key:                 'inventory-adjust-wave',
    displayName:         'OLA DE CONTROL DE INVENTARIO',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'inventory-adjust-wave' },
    checkTaskTypeAccess: true
  },
  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const LogisticsMenu: FC<MenuProps> = ({ send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(({ key }) => key === id);

    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

LogisticsMenu.displayName = 'Menu';

import { createMachine } from 'xstate';

import { printPutToWallLabelsActions } from './actions';
import { printingPutToWallLabelsGuards as printPutToWallLabelsGuards } from './guards';
import { printPutToWallLabelsServices } from './services';

export interface PrintPutToWallLabelsContext {
  error: string | null;
  reservationId: string;
}

export const DefaultBaseContext: PrintPutToWallLabelsContext = {
  error:         null,
  reservationId: ''
};

export const PrintPutToWallLabelsMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCECGswDoBiYAuAxgBYAqATqgHawAOA9mXgPJkRhnICeAMqgEZgANgGIIdSlgCWlAG50A1llyFSFavUYs2HHvyEBtAAwBdRKHqxJeSeLMgAHogCsAJkOYXANgAsAZgCMTt7eABzehv4AnAA0IJyIALSRAOyYycmGTpGhhtn+Lv6eAL5FsWgYmADKRHQA7tJQ5FS0DMys7ACSlABmdMIAwoJ0GE3qrVrsRqZIIBZWNpR2jghOni5p-lE+-obJXiEusfEIIe6R5+fJ3i43m-4hJWXoWIPDDaMtmu1kouJSsgoXkNLJRGmpPm1tFM7HNrLYZssAphfCEDgcnCFkvcXCEnMkjogXL51pEsp4IoYKWEnI8QOUgW9QR8NJD2L8JJhpHJFJhXiCwc0WRMyPp-NNzG84YsEYhPOTMDtid5Nj4UpFDnFCTsPL5fIZgr5IoZDYYXLT6ZhmeNvnzIMIyHB8NCZrCFktEH4QgrdplsqifMaCQhUi5IlFMuGMckwpkSqUQJQ6Gx4DN6TDJW6ZQgEoVMJ5NlirvkQv4sX4gznMZgguE-Oro1Fkubnjh8MQrV9tFxeAJBOnLFL3QhvKlPL5grjPCElYUQhWdu59bXDS4GylmxVqnV3uChd8ur1+-N4aBlur-B5Tb6XNdcYUK040oanKXS-ro+TPDT4xa+TvBda2hHoOWbjpgYakjkqrTiOQYjuBFyRNOuxEiWvgblgHasmQtoQMBmanog+TeJghjkvcE4hAGoZBrqF6GLipI3J6OzpHGRRAA */
    id:                         'PrintPutToWallLabelsMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as PrintPutToWallLabelsContext
    },
    initial: 'AwaitingReservationInput',
    states:  {
      AwaitingReservationInput: {
        on: {
          CHANGE: {
            actions: ['assignReservationId', 'clearErrorMessage']
          },
          SUBMIT_INPUT: [
            {
              cond:    'isReservationInputEmpty',
              actions: ['clearReservationInput', 'assignEmptyError']
            },
            {
              target: 'PrintingReservationLabel'
            }
          ]
        }
      },
      PrintingReservationLabel: {
        invoke: {
          src:    'printReservationLabel',
          onDone: {
            target: 'ReservationLabelPrinted'
          },
          onError: {
            target:  'AwaitingReservationInput',
            actions: 'assignError'
          }
        }
      },
      ReservationLabelPrinted: {
        on: {
          reset: 'AwaitingReservationInput'
        }
      }
    }
  },
  {
    guards:   printPutToWallLabelsGuards,
    actions:  printPutToWallLabelsActions,
    services: printPutToWallLabelsServices
  }
);

import { MovingToSorterExitItemStatuses } from '../MovingToSorterExitItem';
import { MovingToSorterExitItemStatus } from './MovingToSorterExitItemStatus';

export class MovingToSorterExitItemStatusMovingStatus extends MovingToSorterExitItemStatus {
  public displayName = 'Moviendo';

  public internalName = MovingToSorterExitItemStatuses.Moving;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

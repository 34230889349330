import { MovingToPreSorterContainerStatuses } from '../MovingToPreSorterContainer';
import { MovingToPreSorterContainerStatus } from './MovingToPreSorterContainerStatus';

export class MovingToPreSorterContainerCancelledStatus extends MovingToPreSorterContainerStatus {
  public displayName = 'Cancelado';

  public internalName = MovingToPreSorterContainerStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

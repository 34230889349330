import { actions, createMachine } from 'xstate';
import { api } from '../../../api/utils/axios-instance';

export interface SendToParentContext {
  processesIds: number[];
  type: string;
  payload: Record<string, any>;
}

export const SendToManyParentsMachine = createMachine<SendToParentContext>(
  {
    id:                         'SendToManyParentsMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as SendToParentContext
    },
    initial: 'SendingEvent',
    states:  {
      SendingEvent: {
        invoke: {
          src:     'dispatchEvent',
          onDone:  'EventSent',
          onError: {
            actions: actions.escalate((_ctx, ev) => ev)
          }
        }
      },
      EventSent: {
        type: 'final'
      }
    }
  },
  {
    services: {
      dispatchEvent: async (context, _event) => {
        //        await api.post<void>(`/process/${process?.id}/event`, {

        await api.post<void>('/task/dispatch-many-events', {
          type:         context.type,
          processesIds: context.processesIds,
          payload:      context.payload
        });
      }
    }
  }
);

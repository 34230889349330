import { MachineOptions, assign } from 'xstate';
import { NoveltyMoveContainerToBufferContext } from './machine';

type NoveltyMoveContainerToBufferActions = MachineOptions<
  NoveltyMoveContainerToBufferContext,
  any
>['actions'];

export const noveltyMoveContainerToBufferActions: NoveltyMoveContainerToBufferActions =
  {
    assignTargetLocation: assign({
      targetLocation: (_ctx, event) => event.data.targetLocation
    }),
    assignError: assign({
      error: (_ctx, event) =>
        event?.data?.data?.errorView ||
        'Ha ocurrido un error, por favor intente nuevamente.'
    }),
    assignMoveContainerToBufferContainer: assign({
      noveltyMoveContainerToBufferContainer: (_ctx, event) =>
        event.data.noveltyMoveContainerToBufferContainer
    }),
    assignProcessesIds: assign({
      processesIds: (_ctx, event) => event.data.processesIds
    })
  };

import { Container, Location } from '@wms/domain';
import { createMachine } from 'xstate';

import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getPackingLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-packing-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { packingAuditingDesignatorActions } from './actions';
import { packingAuditingDesignatorGuards } from './guards';
import { packingAuditingDesignatorServices } from './services';

export interface PackingAuditingDesignatorContext {
  location: Location | null;
  taskType: string | null;
  container: Container | null;
  errorMessage: string | null;
}

export const DefaultPackingAuditingDesignatorContext: PackingAuditingDesignatorContext =
  {
    location:     null,
    taskType:     null,
    container:    null,
    errorMessage: null
  };

export const PackingAuditingDesignatorMachine = createMachine(
  {
    id:                         'PackingAuditingDesignator',
    predictableActionArguments: true,
    schema:                     {
      context: {} as PackingAuditingDesignatorContext
    },
    context: DefaultPackingAuditingDesignatorContext,
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: _ctx =>
            GetContainerByLpnMachine(
              'Escanee la etiqueta de la cubeta',
              getAnyContainerByLpn(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: {
            target:  'CheckTaskFromContainer',
            actions: ['assignScannedContainer', 'clearError']
          }
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      CheckTaskFromContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'checkTaskTypeByContainer',
          onDone: {
            target:  'ScanningLocation',
            actions: ['assignTaskType', 'clearError']
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignErrorMessage'
          }
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee la ubicación',
            getPackingLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: [
            {
              cond:    'isMiniMopAuditing',
              target:  'MarkingItemAsMoved',
              actions: ['assignScannedLocation', 'clearError']
            },
            {
              target:  'FetchingTask',
              actions: ['assignScannedLocation', 'clearError']
            }
          ]
        },
        on: {
          clearError: {
            actions: 'clearError'
          },
          RESET: {
            target: 'ScanningContainer'
          }
        }
      },
      MarkingItemAsMoved: {
        tags:   ['loading'],
        invoke: {
          src:     'markItemAsMoved',
          onDone:  'TaskAssigned',
          onError: {
            target:  'ScanningLocation',
            actions: 'assignErrorMessage'
          }
        }
      },
      FetchingTask: {
        invoke: {
          src:     'fetchPackingWaveTask',
          onDone:  'TaskAssigned',
          onError: {
            actions: 'assignErrorMessage',
            target:  'ShowingFetchTaskError'
          }
        }
      },
      ShowingFetchTaskError: {
        on: {
          back: {
            actions: 'clearError',
            target:  'ScanningContainer'
          }
        }
      },
      TaskAssigned: {
        type: 'final',
        data: (_context, event) => event.data
      }
    }
  },
  {
    guards:   packingAuditingDesignatorGuards,
    actions:  packingAuditingDesignatorActions,
    services: packingAuditingDesignatorServices
  }
);

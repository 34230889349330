import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchDispatchedStatus extends OrderBatchStatus {
  public displayName = 'Despachado';

  public internalName = OrderBatchStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}

import { MovingToSorterExitProcessStatuses } from '../MovingToSorterExitProcess';
import { MovingToSorterExitProcessStatus } from './MovingToSorterExitProcessStatus';

export class MovingToSorterExitProcessStatusCompletedStatus extends MovingToSorterExitProcessStatus {
  public displayName = 'Completado';

  public internalName = MovingToSorterExitProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

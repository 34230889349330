import { FT100LocationName } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { PickPendingDispatchingTaskContext } from './machine';

type PickPendingDispatchingTaskServices = MachineOptions<
  PickPendingDispatchingTaskContext,
  any
>['services'];

export const pickPendingDispatchingTaskServices: PickPendingDispatchingTaskServices =
  {
    getPendingDispachingLocation: async (_ctx, _event) => {
      const { data } = await api.get('location/name', {
        params: {
          name: FT100LocationName
        }
      });

      return data;
    },
    fetchPendingItemsCount: async (ctx, _evt) => {
      const { data } = await api.get(
        `pending-dispatching-picking-wave/${ctx.pickingWaveId}/pending-pending-dispatching-pick-item`
      );

      return {
        pendingItems: data.pendingItems,
        totalItems:   data.totalItems
      };
    },
    getNextPendingDispachingpickItem: async (ctx, _evt) => {
      const { data } = await api.get(
        `pending-dispatching-picking-wave/${ctx.pickingWaveId}/next-pending-dispatching-pick-item`
      );

      return data;
    },
    getStagingLocation: async (ctx, _evt) => {
      const { data } = await api.get(
        `pending-dispatching-picking-wave/${ctx.pickingWaveId}/get-staging-location`
      );

      return data;
    }
  };

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { ReceiptItem } from './ReceiptItem';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import {
  StandardUnloadingItemStatus,
  StandardUnloadingItemStatusCancelledStatus,
  StandardUnloadingItemStatusPendingStatus,
  StandardUnloadingItemStatusUnloadedStatus
} from './standard-unloading-item-status';

export const StandardUnloadingItemStatuses = {
  Pending:   'pending',
  Unloaded:  'unloaded',
  Cancelled: 'cancelled'
};

@Entity()
export class StandardUnloadingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [StandardUnloadingItemStatuses.Pending]:
      StandardUnloadingItemStatusPendingStatus,
    [StandardUnloadingItemStatuses.Unloaded]:
      StandardUnloadingItemStatusUnloadedStatus,
    [StandardUnloadingItemStatuses.Cancelled]:
      StandardUnloadingItemStatusCancelledStatus
  })
  status: StandardUnloadingItemStatus;

  @ForeignKey(() => StandardUnloadingContainer, {
    name: 'standard_unloading_container_id'
  })
  standardUnloadingContainerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @Field({ name: 'unloaded_quantity' })
  unloadedQuantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => StandardUnloadingContainer, 'standardUnloadingContainerId')
  standardUnloadingContainer: StandardUnloadingContainer;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;
}

import { RestockingItemStatuses } from '../RestockingItem';
import { RestockingItemStatus } from './RestockingItemStatus';

export class RestockingItemStatusProcessingStatus extends RestockingItemStatus {
  public displayName = 'Procesando';

  public internalName = RestockingItemStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

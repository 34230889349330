import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { AuditOrderItem } from './AuditOrderItem';
import { AuditingWave } from './AuditingWave';
import { ClassificationContainer } from './ClassificationContainer';
import { ClassificationItem } from './ClassificationItem';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { ConsolidatingContainersToStagingContainer } from './ConsolidatingContainersToStagingContainer';
import { ContainerType, ContainerTypes } from './ContainerType';
import { DispatchingItem } from './DispatchingItem';
import { FeedingToSorterContainer } from './FeedingToSorterContainer';
import { ImpureSortableCrossdockClassificationContainer } from './ImpureSortableCrossdockClassificationContainer';
import { Incident } from './Incident';
import { InventoryCountingItem } from './InventoryCountingItem';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { MopPickingContainer } from './MopPickingContainer';
import { MopSorterExitItem } from './MopSorterExitItem';
import { MovePendingDispatchingItem } from './MovePendingDispatchingItem';
import { MoveToBufferItem } from './MoveToBufferItem';
import { MovingToPreSorterContainer } from './MovingToPreSorterContainer';
import { NoSortableSlottingContainer } from './NoSortableSlottingContainer';
import { NoveltyMoveContainerToBufferContainer } from './NoveltyMoveContainerToBufferContainer';
import { NoveltyUnloadingContainer } from './NoveltyUnloadingContainer';
import { NoveltyUnloadingContainerV2 } from './NoveltyUnloadingContainerV2';
import { PTWLocationItem } from './PTWLocationItem';
import { PackingLocationItem } from './PackingLocationItem';
import { PickingContainerGroupContainer } from './PickingContainerGroupContainer';
import { Product } from './Product';
import { PutAwayItem } from './PutAwayItem';
import { Receipt } from './Receipt';
import { ReservationPendingContainer } from './ReservationPendingContainer';
import { RestockingItem } from './RestockingItem';
import { SlottingContainer } from './SlottingContainer';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import { StagingAuditingItem } from './StagingAuditingItem';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import { UnloadingContainer } from './UnloadingContainer';
import {
  ContainerStatus,
  ContainerStatusAvailableStatus,
  ContainerStatusDeletedStatus,
  ContainerStatusProcessStatus
} from './container-status';
import { ReceiptStatusConfirmedStatus } from './receipt-status';

export const LogicContainerName = {
  // Granel:    'GRANEL',
  // AltoValor: 'ALTOVALOR',
  // GouGou:    'GOUGOU'
  Lost: 'Lost'
};

export const ContainerStatuses = {
  Process:   'process',
  Available: 'available',
  Deleted:   'deleted'
};
@Entity()
export class Container {
  @ApiField()
  @PrimaryKey()
  id: number;

  @ApiField()
  @Status({
    [ContainerStatuses.Process]:   ContainerStatusProcessStatus,
    [ContainerStatuses.Available]: ContainerStatusAvailableStatus,
    [ContainerStatuses.Deleted]:   ContainerStatusDeletedStatus
  })
  status: ContainerStatus;

  @ApiField()
  @Field()
  lpn: string;

  @ApiField()
  @Field({ name: 'location_date' })
  locationDate: Date;

  @ApiField()
  @Field({ name: 'is_anomaly' })
  isAnomaly: boolean;

  @ApiField()
  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ApiField()
  @ForeignKey(() => ContainerType, { name: 'container_type_id' })
  containerTypeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Knows(() => Incident, 'containerId')
  incidents: Incident[];

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Knows(() => InventoryItem, 'containerId')
  inventoryItems: InventoryItem[];

  @NeededBy(() => ClassificationContainer, 'containerId')
  classificationContainer: ClassificationContainer;

  @Needs(() => ContainerType, 'containerTypeId')
  containerType: ContainerType;

  @Knows(() => PutAwayItem, 'containerId')
  putAwayItems: PutAwayItem[];

  @Field()
  weight: number;

  @Field({ name: 'weight_in_kg' })
  weightInKg: number;

  children: Location[];

  @NeededBy(() => AuditOrderItem, 'containerId')
  auditItems: AuditOrderItem[];

  @NeededBy(() => PackingLocationItem, 'containerId')
  packingLocationItem: PackingLocationItem;

  @NeededBy(() => NoveltyUnloadingContainerV2, 'containerId')
  noveltyUnloadingContainerV2: NoveltyUnloadingContainerV2;

  @NeededBy(() => UnloadingContainer, 'containerId')
  unloadingContainer: UnloadingContainer;

  @NeededBy(() => PickingContainerGroupContainer, 'containerId')
  pickingContainerGroupContainer: PickingContainerGroupContainer;

  @NeededBy(() => ConsolidateContainersItem, 'containerId')
  consolidateContainersItems: ConsolidateContainersItem[];

  @NeededBy(() => NoveltyMoveContainerToBufferContainer, 'containerId')
  noveltyMoveContainerToBufferContainers: NoveltyMoveContainerToBufferContainer[];

  @NeededBy(() => MopPickingContainer, 'containerId')
  mopPickingContainers: MopPickingContainer[];

  @Knows(() => AuditingWave, 'targetContainerId')
  auditingWaves: AuditingWave[];

  @NeededBy(() => MopSorterExitItem, 'containerId')
  mopSorterExitItems: MopSorterExitItem[];

  @NeededBy(() => ReservationPendingContainer, 'containerId')
  reservationPendingContainer: ReservationPendingContainer;

  @NeededBy(() => ConsolidatingContainersToStagingContainer, 'containerId')
  consolidatingContainersToStagingContainer: ConsolidatingContainersToStagingContainer;

  @Knows(() => ClassificationItem, 'containerId')
  classificationItem: ClassificationItem;

  @NeededBy(() => MovingToPreSorterContainer, 'containerId')
  movingToPreSorterContainer: MovingToPreSorterContainer;

  @NeededBy(() => FeedingToSorterContainer, 'containerId')
  feedingToSorterContainer: FeedingToSorterContainer;

  @NeededBy(() => NoSortableSlottingContainer, 'containerId')
  noSortableSlottingContainer: NoSortableSlottingContainer;

  @Knows(() => StagingAuditingContainer, 'containerId')
  stagingAuditingContainer: StagingAuditingContainer;

  @Knows(() => StagingAuditingItem, 'containerId')
  stagingAuditingItems: StagingAuditingItem[];

  @Knows(() => InventoryCountingItem, 'containerId')
  inventoryCountingItem: InventoryCountingItem;

  @NeededBy(() => ImpureSortableCrossdockClassificationContainer, 'containerId')
  impureSortableCrossdockClassificationContainer: ImpureSortableCrossdockClassificationContainer;

  @Knows(() => DispatchingItem, 'containerId')
  dispatchingItem: DispatchingItem;

  @NeededBy(() => StandardUnloadingContainer, 'containerId')
  standardUnloadingContainer: StandardUnloadingContainer;

  @NeededBy(() => MoveToBufferItem, 'containerId')
  moveToBufferItem: MoveToBufferItem;

  @NeededBy(() => PTWLocationItem, 'containerId')
  PTWLocationItem: PTWLocationItem;

  @Knows(() => SlottingContainer, 'containerId')
  slottingContainer: SlottingContainer[];

  @NeededBy(() => MovePendingDispatchingItem, 'containerId')
  movePendingDispatchingItem: MovePendingDispatchingItem;

  @NeededBy(() => NoveltyUnloadingContainer, 'containerId')
  noveltyUnloadingContainer: NoveltyUnloadingContainer;

  @Knows(() => RestockingItem, 'containerId')
  restockingItem: RestockingItem;

  public static isValidLpn(lpn: string, containerTypeName?: string): boolean {
    if (!!lpn === false || lpn.trim().length <= 0) {
      return false;
    }

    if (containerTypeName === ContainerTypes.CUBETA) {
      const regexLpn = /^\d{6}$/;
      return regexLpn.test(lpn.trim());
    } else if (
      containerTypeName === ContainerTypes.ROLLCONTAINER ||
      containerTypeName === ContainerTypes.PALLETARLOG
    ) {
      const regexLpn = /^\d{18}$/;
      return regexLpn.test(lpn.trim());
    } else if (containerTypeName === ContainerTypes.CAJADECARTON) {
      const regexLpn = /^MZ\d{16}$/;
      return regexLpn.test(lpn.trim());
    } else {
      // RegEx que valida que el lpn sea un numero de 6 o 18 digitos o una caja de carton y nada mas
      const regexLpn = /^\d{6}(\d{12})?$/;
      const regexPacking = /^MZ\d{16}$/;
      return regexLpn.test(lpn.trim()) || regexPacking.test(lpn.trim());
    }
  }
}

export function cantPutManyInSameContainer(
  receipt: Receipt,
  container: Container,
  product: Product
): boolean {
  const sameItemAndDisabledMultiItemsXContainer =
    !receipt.manySkuAllowedInContainer &&
    container.inventoryItems.some(ii => ii.productId !== product.id);
  return (
    receipt &&
    !(receipt.status instanceof ReceiptStatusConfirmedStatus) &&
    sameItemAndDisabledMultiItemsXContainer
  );
}

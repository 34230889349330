import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ImpureSortableCrossdockClassificationItem } from './ImpureSortableCrossdockClassificationItem';
import { InventoryItem } from './InventoryItem';
import { ReceiptItem } from './ReceiptItem';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  MovingToSorterExitItemStatus,
  MovingToSorterExitItemStatusDeliveredStatus,
  MovingToSorterExitItemStatusMovingStatus,
  MovingToSorterExitItemStatusPendingStatus
} from './moving-to-sorter-exit-item-status';

export const MovingToSorterExitItemStatuses = {
  Pending:   'pending',
  Moving:    'moving',
  Delivered: 'delivered'
};

@Entity()
export class MovingToSorterExitItem {
  @PrimaryKey()
  id: number;

  @Status({
    [MovingToSorterExitItemStatuses.Pending]:
      MovingToSorterExitItemStatusPendingStatus,
    [MovingToSorterExitItemStatuses.Moving]:
      MovingToSorterExitItemStatusMovingStatus,
    [MovingToSorterExitItemStatuses.Delivered]:
      MovingToSorterExitItemStatusDeliveredStatus
  })
  status: MovingToSorterExitItemStatus;

  @ForeignKey(() => ReceiptItem, {
    name: 'receipt_item_id'
  })
  receiptItemId: number;

  @ForeignKey(() => InventoryItem, {
    name: 'inventory_item_id'
  })
  inventoryItemId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => WMSTransportOrder, 'movingToSorterExitItemId')
  wmsTransportOrder: WMSTransportOrder;

  @NeededBy(
    () => ImpureSortableCrossdockClassificationItem,
    'movingToSorterExitItemId'
  )
  impureSortableCrossdockClassificationItems: ImpureSortableCrossdockClassificationItem;
}

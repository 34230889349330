import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { ReceiptItem } from './ReceiptItem';

export enum ReceiptItemTypes {
  Unidentified = 'unidentified',
  Sellers = 'hd-sellers',
  StorePickup = 'spu-sellers',
  Returned = 'returned',
  Failed = 'failed',
  WMS = 'wms'
}

export type TypeReceiptItem =
  (typeof ReceiptItemTypes)[keyof typeof ReceiptItemTypes];

@Entity()
export class ReceiptItemType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => ReceiptItem, 'receiptItemTypeId')
  receiptItems: ReceiptItem[];
}

import { MachineOptions } from 'xstate';
import { SlottingItemContext } from './machine';

type SlottingItemGuardsType = MachineOptions<
  SlottingItemContext,
  any
>['guards'];

export const slottingItemGuards: SlottingItemGuardsType = {
  IsValidLocation: (_ctx, evt) => {
    return evt.data.isValid;
  },
  IsSuggestedLocation: (ctx, evt) => {
    return ctx.suggestedDestinyLocation!.name == evt.data.name;
  }
};

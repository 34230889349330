import { NoSortableSlottingProcessStatuses } from '../NoSortableSlottingProcess';
import { NoSortableSlottingProcessStatus } from './NoSortableSlottingProcessStatus';

export class NoSortableSlottingProcessStatusPausedStatus extends NoSortableSlottingProcessStatus {
  public displayName = 'Pausado';

  public internalName = NoSortableSlottingProcessStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

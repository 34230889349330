import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { useCallback } from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage/ErrorMessage';
import { withActorRef } from '../../../shared/Machine';
import { Print } from '../../layer-4/printer/component';
import { ManagePrinterMachine } from './machine';

export const ManagePrinterTask = withActorRef(ManagePrinterMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const changePrinterCode = useCallback(
      (input: string) => {
        send({
          type: 'change',
          data: { printerCode: input }
        });
      },
      [send]
    );

    const connect = () => {
      const pc = current.context.printerCode.trim();
      if (pc !== '' && pc !== null && pc !== undefined) {
        send('connect');
      }
    };

    return (
      <Ink.Box paddingX={1}>
        <Ink.Header>
          <Ink.Text>Administrando conexión a impresora</Ink.Text>
        </Ink.Header>
        {current.hasTag('loading') && (
          <Ink.Box>
            <Ink.Text>Cargando...</Ink.Text>
          </Ink.Box>
        )}
        {current.hasTag('connect') && (
          <Ink.Box>
            <Ink.Text color='greenBright' bold>
              Conectando...
            </Ink.Text>
            <Ink.Text> </Ink.Text>
          </Ink.Box>
        )}
        {current.hasTag('disconnect') && (
          <Ink.Box>
            <Ink.Text color='greenBright' bold>
              Desconectando...
            </Ink.Text>
            <Ink.Text> </Ink.Text>
          </Ink.Box>
        )}
        {current.matches('HasPrinter') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>Estas conectado a una impresora portátil</Ink.Text>
            <Ink.Text> </Ink.Text>
            <Ink.Text>
              Impresora: &nbsp;
              <Ink.Chalk bold color='greenBright'>
                {`${current.context.printer?.printerCode}`}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Text>
              Modelo: &nbsp;
              <Ink.Chalk bold color='greenBright'>
                {`${current.context.printer?.model}`}
              </Ink.Chalk>
            </Ink.Text>
            <Ink.Button
              label='Imprimir etiqueta de prueba'
              onClick={() => send('printTestLabel')}
              key='disconnect'
              id='disconnect'
            />
            <Ink.Button
              label='Desconectarme'
              onClick={() => send('disconnect')}
              key='disconnect'
              id='disconnect'
            />
          </Ink.Box>
        )}
        {current.matches('NoPrinter') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text bold>
              No estás conectado a ninguna impresora portátil
            </Ink.Text>
            <Ink.Text> </Ink.Text>
            <Ink.Text bold>
              Escanee la etiqueta de la impresora a la que desea conectarse
            </Ink.Text>
            <Ink.TextInput
              value={current.context.printerCode}
              onChange={changePrinterCode}
              onSubmit={connect}
            />
            {current.context.error && (
              <ErrorMessage error={current.context.error} />
            )}
            <Ink.Button
              label='Conectarme'
              onClick={connect}
              key='connect'
              id='connect'
            />
          </Ink.Box>
        )}
        {current.hasTag('print') && <Print />}
      </Ink.Box>
    );
  }
);

import { Entity, Field, ForeignKey, Knows, PrimaryKey } from '@skyframe/core';
import { InventoryCountingItem } from './InventoryCountingItem';
import { InventoryItem } from './InventoryItem';
import { PackagingLevel } from './PackagingLevel';
import { PutAwayItem } from './PutAwayItem';

@Entity()
export class Lot {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PackagingLevel, { name: 'packaging_level_id' })
  packagingLevelId: number;

  @Field({ name: 'lot_number' })
  lotNumber: string;

  @Field({ name: 'weight_in_kg' })
  weightInKg: number;

  @Field({ name: 'expiration_date' })
  expirationDate: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @Knows(() => PutAwayItem, 'lotId')
  putAwayItem: PutAwayItem;

  @Knows(() => InventoryItem, 'lotId')
  inventoryItem: InventoryItem;

  @Knows(() => InventoryCountingItem, 'lotId')
  inventoryCountingItem: InventoryCountingItem;
}

import { Entity, Field, Knows, NeededBy, PrimaryKey } from '@skyframe/core';
import { Category } from './Category';
import { Product } from './Product';
import { ProductTypeLocationZone } from './ProductTypeLocationZone';

export const ProductTypeNames = {
  Granel:       'Granel',
  Palletizable: 'Palletizable'
};

@Entity()
export class ProductType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  description: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_property_name' })
  sapPropertyName: string;

  @NeededBy(() => ProductTypeLocationZone, 'productTypeId')
  productTypeLocationZones: ProductTypeLocationZone[];

  @NeededBy(() => Product, 'productTypeId')
  products: Product[];

  @Knows(() => Category, 'parentPTId')
  children: Category[];
}

import { createMachine } from 'xstate';

import { scanLabelActions } from './actions';
import { scanLabelGuards } from './guards';
import { scanLabelServices } from './services';

export interface ScanLabelContext {
  hint: string;
  label: string;
  requiredLabel: string | null;
  error: string;
  invalidLabels: string[];
  validLabels: string[];
  allowContainers: boolean;
  typeOfLabel: 'ss' | 'hd' | 'mz' | null;
}

export const DefaultScanLabelContext: ScanLabelContext = {
  hint:            'Escanee la etiqueta',
  label:           '',
  error:           '',
  requiredLabel:   null,
  invalidLabels:   [],
  validLabels:     [],
  allowContainers: false,
  typeOfLabel:     null
};

export const ScanLabelMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUDGBDAdgGXQIzABsA6AQQHd0BLAFysyjSwGIBhACVIDkBxAUQDaABgC6iUAAcA9rFpUpmcSAAeiAKwBGYgBYN2oQDYATGoA0IAJ6IAHFrUBfe+aY58RMpTkMXzbKQBCfNgA+sis3Fx8ACLCYkgg0rJ0CkqqCEZCQsRGAMzaOQDsZpaIGrbEAJxqORomjk4gmFIQcEouuASESolyKfFpALQa5lYIAwaOzhiunR7UdN7T3TK9iv2I2gUjpdZGOtW1Dg3tbiQdRC6YkMtJ8mugaTkVWkYVBtZF2whlL0I1dfUgA */
  createMachine(
    {
      id:                         'ScanLabel',
      predictableActionArguments: true,
      schema:                     {
        context: {} as ScanLabelContext
      },
      initial: 'AwaitingScan',
      states:  {
        AwaitingScan: {
          on: {
            CHANGE: {
              actions: ['assignLabel', 'clearParentError']
            },
            LABEL_SCANNED: [
              {
                cond:    'hasRequiredLabelAndIsIncorrect',
                actions: ['clearLabel', 'assignRequiredError']
              },
              {
                cond:    'isLabelEmpty',
                actions: ['clearLabel', 'assignEmptyError']
              },
              {
                cond:    'isLabelInvalid',
                actions: ['clearLabel', 'assignInvalidError']
              },
              {
                cond:    'isLabelInvalidType',
                actions: ['clearLabel', 'assignInvalidTypeError']
              },
              {
                target: 'LabelScanned'
              }
            ],
            LABEL_EMPTY: {
              target:  'AwaitingScan',
              actions: 'assignError'
            }
          }
        },
        LabelScanned: {
          type: 'final',
          data: ctx => ({
            label: ctx.label.trim()
          })
        }
      }
    },
    {
      guards:   scanLabelGuards,
      actions:  scanLabelActions,
      services: scanLabelServices
    }
  );

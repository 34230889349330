import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ClassificationItem } from './ClassificationItem';
import { ClassificationProcess } from './ClassificationProcess';
import { ConsolidatingContainersToStagingContainer } from './ConsolidatingContainersToStagingContainer';
import { Container } from './Container';
import { Location } from './Location';
import { Route } from './Route';
import { Warehouse } from './Warehouse';
import {
  ClassificationContainerClosedStatus,
  ClassificationContainerMovedStatus,
  ClassificationContainerOpenStatus,
  ClassificationContainerStatus
} from './classification-container-status';

export const ClassificationContainerStatuses = {
  Open:   'open',
  Closed: 'closed',
  Moved:  'moved'
};

@Entity()
export class ClassificationContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [ClassificationContainerStatuses.Open]:   ClassificationContainerOpenStatus,
    [ClassificationContainerStatuses.Closed]:
      ClassificationContainerClosedStatus,
    [ClassificationContainerStatuses.Moved]: ClassificationContainerMovedStatus
  })
  status: ClassificationContainerStatus;

  @Field({ name: 'slot_position' })
  slotPosition: number;

  @ForeignKey(() => ClassificationProcess, {
    name: 'classification_process_id'
  })
  classificationProcessId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => Warehouse, { name: 'target_warehouse_id' })
  targetWarehouseId: number;

  @ForeignKey(() => Route, { name: 'route_id' })
  routeId: number;

  @ForeignKey(() => Location, { name: 'sorter_exit_location_id' })
  sorterExitLocationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ClassificationProcess, 'classificationProcessId')
  classificationProcess: ClassificationProcess;

  @Needs(() => Location, 'sorterExitLocationId')
  sorterExitLocation: Location;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Knows(() => Warehouse, 'targetWarehouseId')
  targetWarehouse: Warehouse;

  @Knows(() => ClassificationItem, 'classificationContainerId')
  classificationItems: ClassificationItem[];

  @NeededBy(
    () => ConsolidatingContainersToStagingContainer,
    'classificationContainerId'
  )
  consolidatingContainersToStagingContainer: ConsolidatingContainersToStagingContainer;

  @Knows(() => Route, 'routeId')
  route: Route;
}

import { NoveltyMoveContainerToBufferProcessStatuses } from '../NoveltyMoveContainerToBufferProcess';
import { NoveltyMoveContainerToBufferProcessStatus } from './NoveltyMoveContainerToBufferProcessStatus';

export class NoveltyMoveContainerToBufferProcessStatusPendingStatus extends NoveltyMoveContainerToBufferProcessStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyMoveContainerToBufferProcessStatuses.Pending;

  public statusColor = '#Ffffff';
}

export type ForegroundColor =
  | 'redBright'
  | 'greenBright'
  | 'highlightColor'
  | 'orangeBright';

export const pallet: Record<ForegroundColor, string> = {
  redBright:      '#dc2626',
  greenBright:    '#16a34a',
  highlightColor: '#9061F9',
  orangeBright:   '#f58f00'
};

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';

import { NoveltyUnloadingItemV2 } from './NoveltyUnloadingItemV2';
import { NoveltyUnloadingPackage } from './NoveltyUnloadingPackage';
import { Receipt } from './Receipt';
import { ReceiptItem } from './ReceiptItem';
import { Reservation } from './Reservation';

export const NoveltyTypes = {
  failure:  'failure', // no llego el cliente
  returned: 'returned' // devolvio el cliente
};

@Entity()
export class Novelty {
  @PrimaryKey()
  id: number;

  @Field({ name: 'label' })
  label: string;

  @Field({ name: 'novelty_type' })
  noveltyType: string;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @NeededBy(() => NoveltyUnloadingPackage, 'noveltyId')
  noveltyUnloadingPackage: NoveltyUnloadingPackage;

  @Knows(() => ReceiptItem, 'noveltyId')
  receiptItems: ReceiptItem[];

  @NeededBy(() => NoveltyUnloadingItemV2, 'noveltyId')
  noveltyUnloadingItemsV2: NoveltyUnloadingItemV2[];
}

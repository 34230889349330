import { HDtypes, OrderTypes, SStypes } from '@wms/domain';
import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveServices = MachineOptions<
  MidiPickingWaveContext,
  any
>['services'];

export const midiPickingWaveServices: MidiPickingWaveServices = {
  fetchPendingItemsCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/pending-pick-items-splitted`
    );
    const { data: pendingItemsToLabel } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/midi-pending-items-sort`
    );

    return {
      pendingItems:        data.pendingItems,
      totalItems:          data.totalItems,
      pickedItems:         data.pickedItems,
      count:               data.pendingItems,
      pendingItemsToLabel: pendingItemsToLabel.count,
      pickItem:            pendingItemsToLabel.pickItem
    };
  },
  fetchSuggestedItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `/picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    return { pickItem: data };
  },
  fetchSplittedPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem.id}/splitted-item`
    );
    return { pickItem: data };
  },
  fetchPendingItemsToSortCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem.id}/pending-inventory-items-to-sort`
    );
    return {
      hasNext: data.hasNext,
      count:   data.count,
      total:   data.total
    };
  },
  getTypeDelivery: async (ctx, _evt) => {
    await Promise.resolve();
    const orderTypeName = ctx.currentPickItem?.orderItem.order.orderType
      .name as OrderTypes;

    if (SStypes.includes(orderTypeName)) {
      return { type: 'AB' };
    } else if (HDtypes.includes(orderTypeName)) {
      return { type: 'HD' };
    } else {
      return { type: 'HD' }; // Default fallback
    }
  },
  fetchMissingItems: async (ctx, _evt) => {
    const { data: missingItems } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/missing-items`
    );
    return {
      missingItems
    };
  },
  markItemAsMissing: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/mark-as-missing`,
      {
        params: {
          userId: ctx.userId
        }
      }
    );
    return data;
  },
  checkAvailableOpportunisticCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/opportunistic-count`
    );
    return data;
  },
  validateOpportunisticCounting: async (ctx, _evt) => {
    await api.post(`pick-item/${ctx.currentPickItem?.id}/opportunistic-count`, {
      count:      ctx.countQuantity,
      locationId: ctx.currentPickItem.pickingLocationId
    });
  },
  checkIfPickItemIsCancelled: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem.id}/status`
    );
    return data;
  }
};

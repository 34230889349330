import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { InventoryAdjustmentMachine } from './machine';

export const InventoryAdjustmentTask = withActorRef(InventoryAdjustmentMachine)(
  ({ actorRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingLeft={1}>
        <Ink.Header>
          <Ink.Text bold>AJUSTE DE INVENTARIO</Ink.Text>
        </Ink.Header>

        {current.matches('Initializing') && <Ink.Text>Cargando...</Ink.Text>}

        {current.context.warning && (
          <Ink.Text color='orangeBright'>{current.context.warning}</Ink.Text>
        )}

        {current.matches('ScanLocation') && (
          <Ink.Text>
            <>
              <GetLocationByName />
            </>
          </Ink.Text>
        )}

        {current.matches('WantToCreateContainer') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Desea declarar Nro de contenedor?</Ink.Text>
            <Ink.Button
              key={1}
              id='si'
              label='Si'
              onClick={() => send('SCAN_CONTAINER')}
            />
            <Ink.Button
              key={2}
              id='no'
              label='No'
              onClick={() => send('SCAN_ITEM')}
            />
          </Ink.Box>
        )}

        {current.matches('ScanContainer') && <EnterLpn />}

        {current.matches('ScanItem') && (
          <Ink.Box flexDirection='column'>
            <GetProductBySKU />
            <Ink.Button
              key={3}
              id='finish-control'
              label={
                (current.context.itemsToAdjust || []).length > 0
                  ? 'Finalizar control'
                  : 'Posición vacía'
              }
              onClick={() => send('FINISH_CONTROL')}
            />
          </Ink.Box>
        )}

        {current.matches('SelectingQuantity') && <EnterQuantity />}

        {current.matches('VerifyStock') ? (
          <> Verificando inventario ...</>
        ) : null}
        {current.context.error && (
          <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
        )}

        {current.matches('DetailOfVerification') ? (
          <>
            {current.context.mismatchItems.length > 0 ? (
              <Ink.Box padding={1} flexDirection='column'>
                <Ink.Text>Usted indicó que la ubicación</Ink.Text>
                <Ink.Text>
                  <Ink.Chalk greenBright bold>
                    {current.context.location?.name || ''}
                  </Ink.Chalk>
                  &nbsp; contiene:
                </Ink.Text>
                {current.context.mismatchItems.map(item => {
                  return (
                    <>
                      <Ink.Box padding={2} flexDirection='column'>
                        <Ink.Box flexDirection='row'>
                          <HighlightedInfo attribute='SKU' value={item.sku} />
                        </Ink.Box>
                        <Ink.Box>
                          <Ink.Text>
                            <Ink.Chalk greenBright bold>
                              {item.name}
                            </Ink.Chalk>
                          </Ink.Text>
                        </Ink.Box>
                        <Ink.Box>
                          <HighlightedInfo
                            attribute='Cant. esperada'
                            value={item.logicalQuantity}
                          />
                        </Ink.Box>
                        <Ink.Box>
                          <HighlightedInfo
                            attribute='Cant. declarado'
                            value={item.physicalQuantity}
                          />
                        </Ink.Box>
                        {current.context.container?.lpn && (
                          <>
                            <Ink.Box marginTop={2} flexDirection='row'>
                              <Ink.Text>CONTENEDOR:</Ink.Text>
                            </Ink.Box>
                            <Ink.Box flexDirection='row'>
                              <HighlightedInfo
                                attribute='Cont. esperado'
                                value={
                                  item.originalContainerLpn || 'Sin Contenedor'
                                }
                              />
                            </Ink.Box>
                            <Ink.Box flexDirection='row'>
                              <HighlightedInfo
                                attribute='Cont. declarado'
                                value={item.containerLpn || 'Sin Contenedor'}
                              />
                            </Ink.Box>
                          </>
                        )}
                      </Ink.Box>
                    </>
                  );
                })}
                La misma no coincide con lo declarado en el sistema.
                <Ink.Text bold>
                  Desea confirmar la creación de la incidencia?
                </Ink.Text>
                <Ink.Button
                  key={3}
                  id='confirm-incident'
                  label='Confirmar Incidencia'
                  onClick={() => send('CONFIRM_INCIDENT')}
                />
              </Ink.Box>
            ) : (
              <Ink.Box padding={1} flexDirection='column'>
                <Ink.Text bold>Se verifico correctamente el control.</Ink.Text>
                <Ink.Button
                  key={4}
                  id='confirm'
                  label='Confirmar'
                  onClick={() => send('CONFIRM')}
                />
              </Ink.Box>
            )}
          </>
        ) : null}
        {current.matches('ConfirmControl') ? (
          <>
            <Ink.Text>Incidencia Creada</Ink.Text>
            <Ink.Button
              key={5}
              id='back'
              label='Volver al menu'
              onClick={() => send('CONFIRM')}
            />
            <Ink.Button
              key={6}
              id='back'
              label='Iniciar nuevo Control de Inventario'
              onClick={() => send('INVENTORY_CONTROL')}
            />
          </>
        ) : null}
      </Ink.Box>
    );
  }
);

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { FeedingToSorterTaskContext } from './machine';

type FeedingToSorterTaskActions = MachineOptions<
  FeedingToSorterTaskContext,
  any
>['actions'];

export const feedingToSorterTaskActions: FeedingToSorterTaskActions = {
  assignFeedingToSorterLocation: assign({
    feedingToSorterLocation: (_ctx, event) => event.data
  }),

  assignContainerToProcess: assign({
    containerToProcess: (_ctx, event) => event.data
  }),

  assignFeedingToSorterItems: assign({
    totalFeedingToSorterItems: (_ctx, event) =>
      event.data.totalFeedingToSorterItems,
    processedFeedingToSorterItems: (_ctx, event) =>
      event.data.processedFeedingToSorterItems
  }),

  assignSellerProductToProcess: assign({
    sellerProductToProcess: (_ctx, event) => event.data
  }),

  clearError: assign({
    error: (_ctx, _event) => ''
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      );
    }
  })
};

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { PutToWallOrder } from './PutToWallOrder';
import { PutToWallStation } from './PutToWallStation';
import { PutToWallWaveStatus } from './put-to-wall-wave-status';
import { PutToWallWaveStatusPendingStatus } from './put-to-wall-wave-status/PutToWallWaveStatusPendingStatus';

export const PutToWallWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class PutToWallWave {
  @PrimaryKey()
  id: number;

  @Status({
    [PutToWallWaveStatuses.Pending]:    PutToWallWaveStatusPendingStatus,
    [PutToWallWaveStatuses.Processing]: PutToWallWaveStatusPendingStatus,
    [PutToWallWaveStatuses.Completed]:  PutToWallWaveStatusPendingStatus,
    [PutToWallWaveStatuses.Cancelled]:  PutToWallWaveStatusPendingStatus
  })
  status: PutToWallWaveStatus;

  @ForeignKey(() => PutToWallStation, { name: 'ptw_station_id' })
  ptwStationId: number;

  @Knows(() => PutToWallOrder, 'ptwWaveId')
  putToWallOrders: PutToWallOrder[];

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PutToWallStation, 'ptwStationId')
  putToWallStation: PutToWallStation;
}

import { createMachine } from 'xstate';
import { Maybe } from '../../../../utils/maybe';
import { printActions } from './actions';
import { printGuards } from './guards';
import { printServices } from './services';

export interface PrintMachineContext {
  error: string | null;
  labels: string[] | null;
}

export type PrintFn = () => Promise<Maybe<any>>;

export const PrintMachineId = 'PrintingMachine';

export const PrintMachine = (printFn: PrintFn) =>
  createMachine(
    {
      id:                         PrintMachineId,
      predictableActionArguments: true,
      schema:                     {
        context: {} as PrintMachineContext
      },
      context: {
        error:  null,
        labels: []
      },
      initial: 'Printing',
      states:  {
        Printing: {
          tags:   ['loading'],
          invoke: {
            src:    'printLabel',
            onDone: [
              {
                cond:    'isSuccess',
                actions: 'assignLabels',
                target:  'ConfirmingPrinting'
              },
              {
                actions: 'assignError',
                target:  'Error'
              }
            ]
          }
        },
        ConfirmingPrinting: {
          on: {
            CONFIRM_PRINTING: 'Finished',
            REPRINT:          'Printing'
          }
        },
        Error: {
          on: {
            ignore: 'Finished',
            retry:  'Printing'
          },
          exit: 'clearError'
        },
        Finished: {
          type: 'final',
          data: (ctx, _evt) => ctx.labels
        }
      }
    },
    {
      guards:   printGuards,
      actions:  printActions,
      services: printServices(printFn)
    }
  );

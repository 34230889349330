import { ReceiptBatchStatuses } from '../ReceiptBatch';
import { ReceiptBatchStatus } from './ReceiptBatchStatus';

export class ReceiptBatchStoredStatus extends ReceiptBatchStatus {
  public displayName = 'Almacenado';

  public internalName = ReceiptBatchStatuses.Stored;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

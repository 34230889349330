import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { UnloadingV3Context } from './machine';

type UnloadingV3Services = MachineOptions<UnloadingV3Context, any>['services'];

export const unloadingV3Services: UnloadingV3Services = {
  fetchStagingLocation: async (ctx, _event) => {
    const { data } = await api.get('/location/name', {
      params: {
        name: ctx.stagingName
      }
    });
    return {
      stagingLocation: data
    };
  },
  validateSkuInCurrentContainer: async (ctx, _event) => {
    const { data } = await api.get(
      `/standard-unloading-container/${
        ctx.standardUnloadingContainerScanned!.id
      }/can-undload-sku`,
      {
        params: {
          receiptId: ctx.standardUnloadingProcess!.receiptId,
          productId: ctx.SKUScanned!.id
        }
      }
    );
    return {
      canUnloadedSkuInCurrentContainer: data
    };
  },
  checkIfSkuScannedWasUnloaded: async (ctx, _event) => {
    const { data } = await api.get('/standard-unloading-item/item-scanned', {
      params: {
        receiptId:                  ctx.standardUnloadingProcess!.receiptId,
        productId:                  ctx.SKUScanned!.id,
        standardUnloadingProcessId: ctx.standardUnloadingProcessId
      }
    });
    return {
      quantityLeft: data
    };
  },
  fetchStandardUnloadingProcess: async (ctx, _event) => {
    const { data } = await api.get(
      `/standard-unloading-process/${ctx.standardUnloadingProcessId}`,
      {}
    );
    return {
      standardUnloadingProcess: data
    };
  },
  fetchContainerTypes: async (_ctx, _event) => {
    const qs = require('qs');

    const queryString = qs.stringify({ name: _ctx.allowedContainers });

    const { data } = await api.get('container-type', {
      params: { qs: queryString }
    });
    return {
      containerTypes: data?.rows || []
    };
  },
  fetchStandardUnloadingContainerScanned: async (ctx, _event) => {
    const { data } = await api.get('/standard-unloading-container/container', {
      params: {
        containerLpn: ctx.containerLabel
      }
    });
    return {
      standardUnloadingContainer: data
    };
  },

  validateContainerCapacityRule: async (ctx, _event) => {
    const { data } = await api.get(
      `/product/${ctx.SKUScanned!.id}/validate-container-rule`,
      {
        params: {
          containerTypeId:              ctx.containerType?.id,
          quantity:                     ctx.receivedQuantity,
          standardUnloadingContainerId:
            ctx.standardUnloadingContainerScanned!.id
        }
      }
    );

    return {
      suggestedContainerType: data.containerType,
      qtyPerLevel:            data.qtyPerLevel,
      amountOfLevels:         data.amountOfLevels,
      targetLocation:         data.targetLocation
    };
  },
  checkIfHasMorePendingItemsToUnload: async (ctx, _event) => {
    const { data } = await api.get(
      `/standard-unloading-process/${ctx.standardUnloadingProcessId}/pending-items`
    );

    return {
      detailOfItems: data
    };
  }
};

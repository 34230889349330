import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { NoveltyUnloadingContainer } from './NoveltyUnloadingContainer';
import { NoveltyUnloadingPackage } from './NoveltyUnloadingPackage';
import { ReceiptItem } from './ReceiptItem';
import {
  NoveltyUnloadingItemStatus,
  NoveltyUnloadingItemStatusPendingStatus,
  NoveltyUnloadingItemStatusUnloadedStatus
} from './novelty-unloading-item-status';

export const NoveltyUnloadingItemStatuses = {
  Pending:  'pending',
  Unloaded: 'unloaded'
};

@Entity()
export class NoveltyUnloadingItem {
  @PrimaryKey()
  id: number;

  @Field({ name: 'sku' })
  sku: string;

  @Field({ name: 'serial_number' })
  serialNumber: string;

  @ForeignKey(() => NoveltyUnloadingPackage, {
    name: 'novelty_unloading_package_id'
  })
  noveltyUnloadingPackageId: number;

  @ForeignKey(() => NoveltyUnloadingContainer, {
    name: 'novelty_unloading_container_id'
  })
  noveltyUnloadingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => NoveltyUnloadingPackage, 'noveltyUnloadingPackageId')
  noveltyUnloadingPackage: NoveltyUnloadingPackage;

  @Knows(() => NoveltyUnloadingContainer, 'noveltyUnloadingContainerId')
  noveltyUnloadingContainer: NoveltyUnloadingContainer;

  @Status({
    [NoveltyUnloadingItemStatuses.Pending]:
      NoveltyUnloadingItemStatusPendingStatus,
    [NoveltyUnloadingItemStatuses.Unloaded]:
      NoveltyUnloadingItemStatusUnloadedStatus
  })
  status: NoveltyUnloadingItemStatus;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;
}

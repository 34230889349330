import { ReservationPendingContainerStatuses } from '../ReservationPendingContainer';
import { ReservationPendingContainerStatus } from './ReservationPendingContainerStatus';

export class ReservationPendingContainerStatusMovedStatus extends ReservationPendingContainerStatus {
  public displayName = 'Movido';

  public internalName = ReservationPendingContainerStatuses.Moved;

  public statusColor = '#Ffffff';
}

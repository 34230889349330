import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { DispatchingWave } from './DispatchingWave';
import { OrderBatch } from './OrderBatch';
import { DispatchingProcessCompletedStatus } from './dispatching-process-status/DispatchingProcessCompletedStatus';
import { DispatchingProcessPendingStatus } from './dispatching-process-status/DispatchingProcessPendingStatus';
import { DispatchingProcessProcessingStatus } from './dispatching-process-status/DispatchingProcessProcessingStatus';
import { DispatchingProcessStatus } from './dispatching-process-status/DispatchingProcessStatus';

export const DispatchingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class DispatchingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [DispatchingProcessStatuses.Pending]:    DispatchingProcessPendingStatus,
    [DispatchingProcessStatuses.Processing]: DispatchingProcessProcessingStatus,
    [DispatchingProcessStatuses.Completed]:  DispatchingProcessCompletedStatus
  })
  status: DispatchingProcessStatus;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(() => DispatchingWave, 'dispatchingProcessId')
  dispatchingWaves: DispatchingWave[];
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { NoveltyUnloadingItem } from './NoveltyUnloadingItem';
import { NoveltyUnloadingProcess } from './NoveltyUnloadingProcess';
import {
  NoveltyUnloadingContainerStatus,
  NoveltyUnloadingContainerStatusCompletedStatus,
  NoveltyUnloadingContainerStatusPendingStatus,
  NoveltyUnloadingContainerStatusProcessingStatus
} from './novelty-unloading-container-status';

export const NoveltyUnloadingContainerStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

export const NoveltyUnloadingContainerTypes = {
  Apt:    'apt',
  NotApt: 'not-apt'
} as const;

@Entity()
export class NoveltyUnloadingContainer {
  @PrimaryKey()
  id: number;

  @Field({ name: 'type' })
  type: string;

  @ForeignKey(() => NoveltyUnloadingProcess, {
    name: 'novelty_unloading_process_id'
  })
  noveltyUnloadingProcessId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => NoveltyUnloadingProcess, 'noveltyUnloadingProcessId')
  noveltyUnloadingProcess: NoveltyUnloadingProcess;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Status({
    [NoveltyUnloadingContainerStatuses.Pending]:
      NoveltyUnloadingContainerStatusPendingStatus,
    [NoveltyUnloadingContainerStatuses.Processing]:
      NoveltyUnloadingContainerStatusProcessingStatus,
    [NoveltyUnloadingContainerStatuses.Completed]:
      NoveltyUnloadingContainerStatusCompletedStatus
  })
  status: NoveltyUnloadingContainerStatus;

  @Knows(() => NoveltyUnloadingItem, 'noveltyUnloadingContainerId')
  noveltyUnloadingItems: NoveltyUnloadingItem[];
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MoveToBufferContext } from './machine';

type MoveToBufferServices = MachineOptions<
  MoveToBufferContext,
  any
>['services'];

export const moveToBufferServices: MoveToBufferServices = {
  initialize: async (ctx, _event) => {
    const { data } = await api.get('/move-to-buffer-item/initialize', {
      params: {
        moveToBufferItemId: ctx.moveToBufferItemId
      }
    });
    return {
      standardUnloadingContainer: data.standardUnloadingContainer,
      destinyLocation:            data.destinyLocation,
      destinyLocationLevel:       data.destinyLocationLevel,
      stagingLocation:            data.stagingLocation
    };
  }
};

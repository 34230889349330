import Ink from '@ink';
import { SStypes } from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { LogisticOperator } from '../../core/order-logistic-operator-info';
import { WarehouseInfo } from '../../core/order-warehouse-info';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { LabelDetailMachine } from './machine';

export const LabelDetailComponent = withActorRef(LabelDetailMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const getSkus = items => {
      return items.map(item => {
        const quantityLabel =
          item.inventoryItem?.quantity > 1 ? 'UNIDADES' : 'UNIDAD';
        return {
          sku:      item.inventoryItem?.product.sku || '--',
          quantity: item.inventoryItem?.quantity || '--',
          quantityLabel,
          name:     item.inventoryItem?.product.name || '--'
        };
      });
    };

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Detalle de Etiqueta</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.matches('ScanLabel') && <GetItemByLabel />}

          {current.matches('Detail') && (
            <Ink.Box flexDirection='column' padding={2}>
              <HighlightedInfo
                attribute='Bulto'
                value={current.context.label || ''}
              />
              <Ink.Text>SKU: &nbsp; </Ink.Text>
              {getSkus(current.context.items).map(
                ({ sku, quantity, quantityLabel, name }, index) => (
                  <Ink.Text key={index}>
                    <Ink.Chalk color='greenBright' bold>
                      {`${sku} - ${name} - ${quantity} ${quantityLabel}`}
                    </Ink.Chalk>
                  </Ink.Text>
                )
              )}
              <Ink.Text>Ultima Ubicacion conocida: &nbsp; </Ink.Text>
              <Ink.Text>
                <Ink.Chalk color='greenBright' bold>
                  {(current.context.items &&
                    current.context.items[0].inventoryItem?.location?.name) ||
                    '--'}
                </Ink.Chalk>
              </Ink.Text>

              <Ink.Text>Rampa esperada: &nbsp; </Ink.Text>
              <Ink.Text>
                <Ink.Chalk color='greenBright' bold>
                  {(current.context.items &&
                    current.context.items[0]?.destionation?.name) ||
                    '-'}
                </Ink.Chalk>
              </Ink.Text>

              <Ink.Text>Canal de despacho: &nbsp; </Ink.Text>
              <Ink.Text>
                <Ink.Chalk color='greenBright' bold>
                  {current.context.items &&
                    current.context.items[0]?.staging?.name}
                </Ink.Chalk>
              </Ink.Text>
              {current.context.items &&
                current.context.items.length &&
                (SStypes.includes(current.context.items[0]?.orderType.name) ? (
                  <WarehouseInfo
                    warehouse={current.context.items[0]?.warehouse}
                    bold={false}
                  />
                ) : (
                  <LogisticOperator
                    orderType={current.context.items[0]?.orderType}
                  />
                ))}

              <HighlightedInfo
                attribute='Órden de Salida'
                value={
                  current.context.items?.length
                    ? current.context.items[0]?.order.id
                    : '-'
                }
              />

              <Ink.Button
                label='Escanear otra etiqueta'
                onClick={() => send('rescan')}
              />
            </Ink.Box>
          )}
        </Ink.Box>
        {current.context.error && (
          <ErrorMessage error={current.context.error!} bold />
        )}
      </Ink.Box>
    );
  }
);

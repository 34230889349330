import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { ContainerDetailContext } from './machine';

type containerDetailServicesType = MachineOptions<
  ContainerDetailContext,
  any
>['services'];

export const containerDetailServices: containerDetailServicesType = {
  fetchItems: async ctx => {
    const { data } = await api.get(`container/${ctx.container!.id}/items`);
    return {
      items:       data.items,
      orderIds:     data.orderIds,
      receiptId:   data.receiptId,
      destination: data.destination
    };
  }
};

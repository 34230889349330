import { api } from '../../../../api/utils/axios-instance';

export const reportAnomalyServices = {
  fetchingValidLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `location/${ctx.location.id}/is-valid-anomaly`,
      {}
    );

    return {
      locationData: data
    };
  },
  validatingInventoryItemIsInLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `location/${ctx.location.id}/has-sku-in-location`,
      {
        params: {
          sku: ctx.product.sku
        }
      }
    );
    return data;
  },
  fetchingContainerTypes: async (_ctx, _evt) => {
    const { data } = await api.get('container-type');

    return { containerTypes: data.rows };
  },
  validatingContainerIsAnomaly: async (ctx, _evt) => {
    const { data } = await api.get(
      `/container/${ctx.anomalyContainer.id}/is-anomaly`
    );

    return { anomalyContainer: data };
  },
  validatingIsAnomalyLocation: async (ctx, _evt) => {
    const { data } = await api.get(
      `location/${ctx.location.id}/is-valid-anomaly`
    );

    return data;
  },
  fetchingTargetLocation: async (_ctx, _evt) => {
    const { data } = await api.get('location/anomaly');
    return { targetLocation: data };
  },
  markItemAsAnomaly: async (ctx, _event) => {
    const { data } = await api.get('inventory-item/mark-item-as-anomaly', {
      params: {
        sku:         ctx.anomalyItem.sku,
        locationId:  ctx.location.id,
        containerId: ctx.anomalyContainer.id,
        quantity:    ctx.productQuantity
      }
    });

    return data;
  },
  moveItemAndContainerToAnomalyLocation: async (ctx, _event) => {
    const { data } = await api.get(
      `container/${ctx.anomalyContainer.id}/move-to-anomaly`,
      {
        params: {
          locationId: ctx.targetLocation.id
        }
      }
    );
    return data;
  }
};

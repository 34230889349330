import { MachineOptions } from 'xstate';
import { SlottingRollContainerContext } from './machine';

type SlottingRollContainerGuards = MachineOptions<
  SlottingRollContainerContext,
  any
>['guards'];

export const slottingRollContainerGuards: SlottingRollContainerGuards = {
  hasItemsToScan: (_ctx, event) =>
    event.data.itemsTotal !== event.data.itemsSlotted,
  isLocationAvailable:                 (_ctx, event) => event.data,
  isQuantityDepositedTotalSkuQuantity: (ctx, _event) =>
    ctx.skuUnloadedQuantity === ctx.quantityDeposited,
  hasQuantitySlotted: (ctx, _event) => ctx.quantityDeposited > 0,
  isCorrectParent:    (ctx, event) =>
    event.data.name === ctx.containerLocationName,
  hasAvailableQuantity: (ctx, event) =>
    (ctx.availableQuantityInLocationScanned as number) >= event.data.quantity,
  theLocationIsNotFull: (ctx, _event) =>
    ctx.quantityDeposited < (ctx.availableQuantityInLocationScanned as number),
  locationAvailable: (_ctx, event) => event.data
};

import { MovePendingDispatchingItemsProcessStatuses } from '../MovePendingDispatchingItemsProcess';
import { MovePendingDispatchingItemsProcessStatus } from './MovePendingDispatchingItemsProcessStatus';

export class MovePendingDispatchingItemsProcessCompletedStatus extends MovePendingDispatchingItemsProcessStatus {
  public displayName = 'Completado';

  public internalName = MovePendingDispatchingItemsProcessStatuses.Completed;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { Container } from '@wms/domain';
import { createMachine } from 'xstate';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface ScanContainerContext {
  container: Container | null;
  suggestedContainer: Container | null;
  requestedContainer: Container | null;
  validContainers: Container[];
  hint: string | null;
  lpn: string | null;
  error: string | null;
}

export const DefaultScanContainerContext = {
  lpn:                '',
  error:              '',
  container:          null,
  suggestedContainer: null,
  requestedContainer: null,
  validContainers:    [],
  hint:               null
};

export const ScanContainerMachine = createMachine<ScanContainerContext>(
  {
    id:      'ScanContainerMachine',
    initial: 'AwaitingContainerScan',
    states:  {
      AwaitingContainerScan: {
        on: {
          ContainerScanned: [
            {
              cond:    'invalidLPN',
              actions: 'errorInvalidContainer',
              target:  'AwaitingContainerScan'
            },
            {
              cond:    'requestedContainerInvalid',
              actions: 'errorInvalidContainer',
              target:  'AwaitingContainerScan'
            },
            {
              actions: 'assignLPN',
              target:  'FetchingContainer'
            }
          ]
        }
      },
      FetchingContainer: {
        invoke: {
          src:    'findContainerByLpn',
          onDone: {
            actions: 'assignContainer',
            target:  'ContainerFound'
          },
          onError: {
            actions: 'assignError',
            target:  'AwaitingContainerScan'
          }
        }
      },
      ContainerFound: {
        type: 'final',
        data: ctx => ({ container: ctx.container })
      }
    }
  },
  {
    guards: {
      ...UtilityGuards,
      invalidLPN: (ctx, event) => {
        const canValidate =
          !!ctx.requestedContainer ||
          !!ctx.suggestedContainer ||
          Array.isArray(ctx.validContainers);
        const compareBy = ctx.requestedContainer
          ? 'requestedContainer'
          : 'suggestedContainer';

        const containerToCompare = ctx[compareBy];
        const isInvalidLPN = containerToCompare
          ? containerToCompare.lpn !== event.data.lpn
          : true;

        const validContainers = ctx.validContainers ? ctx.validContainers : [];

        const isNotValidContainer =
          validContainers.length > 0
            ? ctx.validContainers.every(
                container => container.lpn !== event.data.lpn
              )
            : true;
        return canValidate && isNotValidContainer && isInvalidLPN;
      }
    },
    actions: {
      ...UtilityActions
    },
    services: { ...API }
  }
);

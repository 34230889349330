import { StagingAuditingItemStatuses } from '../StagingAuditingItem';
import { StagingAuditingItemStatus } from './StagingAuditingItemStatus';

export class StagingAuditingItemQuantityMismatchedStatus extends StagingAuditingItemStatus {
  public displayName = 'Auditado con descuadres';

  public internalName = StagingAuditingItemStatuses.QuantityMismatched;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

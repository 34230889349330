import { UnloadingProcessStatuses } from '../UnloadingProcess';
import { UnloadingProcessStatus } from './UnloadingProcessStatus';

export class UnloadingProcessStatusProcessingStatus extends UnloadingProcessStatus {
  public displayName = 'Procesando';

  public internalName = UnloadingProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC } from 'react';
import { withActorRef } from '../../../shared/Machine';
import { ScanSKUMachine } from './ScanSKUMachine';

export const ScanSKU: FC = withActorRef(ScanSKUMachine)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const changeIdentifier = newIdentifier => {
    send({ type: 'ChangedIdentifier', data: newIdentifier });
  };

  function onSubmitIdentifier() {
    send({
      type: 'SubmittedIdentifier',
      data: {}
    });
    changeIdentifier('');
  }

  return (
    <>
      {current.matches('AwaitingIdentifierScan') ? (
        <>
          <Ink.Chalk highlightColor bold>
            {current.context.hint}
          </Ink.Chalk>
          <Ink.TextInput
            onChange={changeIdentifier}
            value={current.context.identifier || ''}
            onSubmit={onSubmitIdentifier}
          />
        </>
      ) : null}

      {current.context.error && (
        <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
      )}
    </>
  );
});

ScanSKU.displayName = 'ScanLocation';

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { DispatchingItem } from './DispatchingItem';
import { Incident } from './Incident';
import { InventoryItem } from './InventoryItem';
import { StagingAuditingContainer } from './StagingAuditingContainer';
import {
  StagingAuditingItemDraftStatus,
  StagingAuditingItemPositiveMismatchedStatus
} from './staging-auditing-item-status';
import { StagingAuditingItemAuditedStatus } from './staging-auditing-item-status/StagingAuditingItemAuditedStatus';
import { StagingAuditingItemAuditingStatus } from './staging-auditing-item-status/StagingAuditingItemAuditingStatus';
import { StagingAuditingItemCancelledStatus } from './staging-auditing-item-status/StagingAuditingItemCancelledStatus';
import { StagingAuditingItemPendingStatus } from './staging-auditing-item-status/StagingAuditingItemPendingStatus';
import { StagingAuditingItemQuantityMismatchedStatus } from './staging-auditing-item-status/StagingAuditingItemQuantityMismatchedStatus';
import { StagingAuditingItemStatus } from './staging-auditing-item-status/StagingAuditingItemStatus';

export const StagingAuditingItemStatuses = {
  Pending:            'pending',
  Auditing:           'auditing',
  Audited:            'audited',
  Cancelled:          'cancelled',
  Draft:              'draft',
  QuantityMismatched: 'quantity-mismatched',
  PositiveMismatched: 'positive-mismatched'
};

export const SortableBulkEntities = {
  SortingItem:       'sorting-item',
  Package:           'package',
  MopSorterExitItem: 'mop-sorter-exit-item'
} as const;

export type SortableBulkEntity =
  (typeof SortableBulkEntities)[keyof typeof SortableBulkEntities];

export interface ReprintStagingAuditingItem {
  label: string;
  sku: string;
  description: string;
  route: string;
  container: string;
  type: SortableBulkEntity;
}

@Entity()
export class StagingAuditingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [StagingAuditingItemStatuses.Pending]:            StagingAuditingItemPendingStatus,
    [StagingAuditingItemStatuses.Auditing]:           StagingAuditingItemAuditingStatus,
    [StagingAuditingItemStatuses.Audited]:            StagingAuditingItemAuditedStatus,
    [StagingAuditingItemStatuses.Cancelled]:          StagingAuditingItemCancelledStatus,
    [StagingAuditingItemStatuses.Draft]:              StagingAuditingItemDraftStatus,
    [StagingAuditingItemStatuses.QuantityMismatched]:
      StagingAuditingItemQuantityMismatchedStatus,
    [StagingAuditingItemStatuses.PositiveMismatched]:
      StagingAuditingItemPositiveMismatchedStatus
  })
  status: StagingAuditingItemStatus;

  @ForeignKey(() => StagingAuditingContainer, {
    name: 'staging_auditing_container_id'
  })
  stagingAuditingContainerId: number;

  @ForeignKey(() => StagingAuditingContainer, {
    name: 'scanned_staging_auditing_item_id'
  })
  scannedStagingAuditingContainerId: number;

  @Field({ name: 'label' })
  label: string;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @Field({ name: 'expected_quantity' })
  expectedQuantity: number;

  @Field({ name: 'audited_quantity' })
  auditedQuantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => StagingAuditingContainer, 'stagingAuditingContainerId')
  stagingAuditingContainer: StagingAuditingContainer;

  @Knows(() => StagingAuditingContainer, 'scannedStagingAuditingContainerId')
  scannedStagingAuditingContainer: StagingAuditingContainer;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => DispatchingItem, 'stagingAuditingItemId')
  dispatchingItem: DispatchingItem;

  @Knows(() => Incident, 'stagingAuditingItemId')
  incidents: Incident[];
}

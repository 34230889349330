import { NoveltyUnloadingProcessStatuses } from '../NoveltyUnloadingProcess';
import { NoveltyUnloadingProcessStatus } from './NoveltyUnloadingProcessStatus';

export class NoveltyUnloadingProcessStatusCancelledStatus extends NoveltyUnloadingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = NoveltyUnloadingProcessStatuses.Cancelled;

  public statusColor = '#Ffffff';
}

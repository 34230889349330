import { ReservationPendingContainerStatuses } from '../ReservationPendingContainer';
import { ReservationPendingContainerStatus } from './ReservationPendingContainerStatus';

export class ReservationPendingContainerStatusPendingStatus extends ReservationPendingContainerStatus {
  public displayName = 'Pendiente';

  public internalName = ReservationPendingContainerStatuses.Pending;

  public statusColor = '#Ffffff';
}

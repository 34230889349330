import { PendingDispatchingPickingProcessStatuses } from '../PendingDispatchingPickingProcess';
import { PendingDispatchingPickingProcessStatus } from './PendingDispatchingPickingProcessStatus';

export class PendingDispatchingPickingProcessCompletedStatus extends PendingDispatchingPickingProcessStatus {
  public displayName = 'Completado';

  public internalName = PendingDispatchingPickingProcessStatuses.Completed;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusNotDispatchedStatus extends OrderStatus {
  public displayName = 'No despachado';

  public internalName = OrderStatuses.NotDispatched;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

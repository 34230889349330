import { ImpureSortableCrossdockClassificationProcessStatuses } from '../ImpureSortableCrossdockClassificationProcess';
import { ImpureSortableCrossdockClassificationProcessStatus } from './ImpureSortableCrossdockClassificationProcessStatus';

export class ImpureSortableCrossdockClassificationProcessStatusCancelledStatus extends ImpureSortableCrossdockClassificationProcessStatus {
  public displayName = 'Cancelado';

  public internalName =
    ImpureSortableCrossdockClassificationProcessStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { Container, Location, Product } from '@wms/domain';
import { createMachine } from 'xstate';
import { AlertData } from '../../../../types/alerData';

import {
  DefaultEnterLpnOrLocationLabelContext,
  EnterLpnOrLocationLabelMachine
} from '../../capa-4/enter-lpn-or-location-label/EnterLpnOrLocationLabelMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';

import { getAnyContainerInLocationByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-in-location-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { PopUpMachine } from '../../layer-4/pop-up/machine';
import { getAnyProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-any-product-by-sku';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { bulkMovementActions } from './actions';
import { bulkMovementGuards } from './guards';
import { bulkMovementServices } from './services';

export interface BulkMovementContext {
  originLabel: string | number | null;
  originContainer: Container | null;
  originLocation: Location | null;

  productToMove: Product | null;
  quantity: number | null;
  itemStatus: string | null;

  destinyLabel: string | number | null;
  destinyContainer: Container | null;
  destinyLocation: Location | null;
  error: string | null;
  alertData: AlertData;
}

export const DefaultBulkMovementContext: BulkMovementContext = {
  originLabel:     null,
  originContainer: {} as Container,
  originLocation:  {} as Location,

  productToMove: {} as Product,
  quantity:      null,
  itemStatus:    null,

  destinyLabel:     null,
  destinyContainer: {} as Container,
  destinyLocation:  {} as Location,

  error:     '',
  alertData: {
    errorTitle:     'Error',
    errorMessage:   'Ocurrió un error, por favor intente de nuevo',
    positiveTarget: null,
    negativeTarget: null,
    target:         null
  }
};

export const BulkMovementMachine = createMachine(
  {
    id:     'BulkMovement',
    schema: {
      context: {} as BulkMovementContext
    },
    context: DefaultBulkMovementContext,
    initial: 'ScanningOriginEntity',
    states:  {
      ErrorRouter: {
        always: [
          {
            cond:   'goToScanningDestinyLabel',
            target: 'ScanningDestinyEntity.ScanningDestinyLabel'
          },
          {
            cond:   'goToScanningSku',
            target: 'ScanningSku'
          },
          {
            cond:   'goToScanningOriginLabel',
            target: 'ScanningOriginEntity.ScanningOriginLabel'
          },
          {
            cond:   'goToCheckingIfProductIsInOriginContainer',
            target: 'CheckingIfProductIsInOriginContainer'
          },
          {
            cond:   'gotToEnteringQuantity',
            target: 'EnteringQuantity'
          }
        ]
      },
      HandleErrors: {
        invoke: {
          id:   PopUpMachine.id,
          src:  PopUpMachine,
          data: {
            alertData: ctx => {
              return ctx.alertData;
            }
          },
          onDone: {
            target:  'ErrorRouter',
            actions: ['clearError']
          }
        }
      },
      ScanningOriginEntity: {
        initial: 'ScanningOriginLabel',
        onDone:  'ScanningSku',
        states:  {
          ScanningOriginLabel: {
            invoke: {
              id:   EnterLpnOrLocationLabelMachine.id,
              src:  EnterLpnOrLocationLabelMachine,
              data: _ctx => ({
                ...DefaultEnterLpnOrLocationLabelContext,
                hint: 'Escanee ubicación o contenedor de origen'
              }),
              onDone: [
                {
                  cond:    'isValidLpn',
                  actions: 'assignOriginLabel',
                  target:  'FetchingOriginContainer'
                },
                {
                  actions: 'assignOriginLabel',
                  target:  'FetchingOriginLocation'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningOriginLocationAlertData'
                ],
                target: 'ScanningOriginLabel'
              }
            }
          },

          FetchingOriginContainer: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchOriginContainer',
              onDone: {
                target:  'Done',
                actions: ['assignOriginContainer', 'clearError']
              },
              onError: {
                actions: [
                  'assignError',
                  'assingScanningOriginLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          FetchingOriginLocation: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchOriginLocation',
              onDone: {
                actions: 'assignOriginLocation',
                target:  'CheckingIfOriginLocationHasMoreThanOneContainer'
              },
              onError: {
                actions: [
                  'assignError',
                  'assingScanningOriginLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          CheckingIfOriginLocationHasMoreThanOneContainer: {
            tags:   ['loading'],
            invoke: {
              src:    'checkIfOriginLocationHasMoreThanOneContainer',
              onDone: [
                {
                  cond:   'originLocationHasMoreThanOneContainer',
                  target: 'ScanningOriginContainer'
                },
                {
                  target: 'Done'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningOriginLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          ScanningOriginContainer: {
            invoke: {
              id:  'ScanningOriginContainer',
              src: ctx =>
                GetContainerByLpnMachine(
                  'Escanear contenedor',
                  getAnyContainerInLocationByLpn(
                    'Ocurrió un error, por favor intente de nuevo.',
                    ctx.originLocation?.id!
                  )
                ),
              onDone: {
                target:  'Done',
                actions: 'assignOriginContainerValidatingLocation'
              },
              onError: {
                actions: [
                  'assignError',
                  'assingScanningOriginLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          Done: {
            type: 'final'
          }
        }
      },

      ScanningSku: {
        invoke: {
          id:  GetProductBySKUMachineId,
          src: () =>
            GetProductBySKUMachine(
              'Escanear SKU del producto',
              getAnyProductBySKU(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: {
            actions: ['assignProductToMove', 'clearError'],
            target:  'CheckingIfProductIsInOriginContainer'
          },
          onError: {
            actions: ['assignError', 'assingScanningSKUAlertData'],
            target:  'HandleErrors'
          }
        }
      },

      CheckingIfProductIsInOriginContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfProductIsInOriginContainer',
          onDone: {
            target:  'EnteringQuantity',
            actions: 'clearError'
          },
          onError: {
            actions: ['assignError', 'assingScanningSKUAlertData'],
            target:  'HandleErrors'
          }
        }
      },

      EnteringQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: () => ({
            ...DefaultEnterQuantityContext,
            max: 9999,
            min: 1
          }),
          onDone: [
            {
              target:  'ValidatingInventoryItemQuantity',
              actions: ['assignQuantity', 'clearError']
            }
          ]
        }
      },

      ValidatingInventoryItemQuantity: {
        tags:   ['loading'],
        invoke: {
          src:    'validateInventoryItemQuantity',
          onDone: {
            actions: 'asignItemStatus',
            target:  'ScanningDestinyEntity'
          },
          onError: {
            actions: ['assignError', 'assingQuantityAlertData'],
            target:  'HandleErrors'
          }
        }
      },

      ScanningDestinyEntity: {
        initial: 'ScanningDestinyLabel',
        onDone:  [
          {
            cond:   'isMezzanine',
            target: 'CheckingDestinyAvailability'
          },
          {
            target: 'MovingProductToLocation'
          }
        ],
        states: {
          ScanningDestinyLabel: {
            invoke: {
              id:   EnterLpnOrLocationLabelMachine.id,
              src:  EnterLpnOrLocationLabelMachine,
              data: _ctx => ({
                ...DefaultEnterLpnOrLocationLabelContext,
                hint: 'Escanee ubicación o contenedor de destino'
              }),
              onDone: [
                {
                  cond:    'isValidLpn',
                  actions: 'assignDestinyLabel',
                  target:  'FetchingDestinyContainer'
                },
                {
                  actions: 'assignDestinyLabel',
                  target:  'FetchingDestinyLocation'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningDestinyLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          FetchingDestinyContainer: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchDestinyContainer',
              onDone: [
                {
                  cond:    'invalidAnomalyContainerLocation',
                  actions: 'assignInvalidAnomalyContainerLocationError',
                  target:  'ScanningDestinyLabel'
                },
                {
                  target:  'Done',
                  actions: 'assignDestinyContainer'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningDestinyLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          FetchingDestinyLocation: {
            tags:   ['loading'],
            invoke: {
              src:    'fetchDestinyLocation',
              onDone: [
                {
                  cond:    'invalidAnomalyLocation',
                  actions: 'assignInvalidAnomalyLocationError',
                  target:  'ScanningDestinyLabel'
                },
                {
                  target:  'CheckingIfDestinyLocationHasMoreThanOneContainer',
                  actions: 'assignDestinyLocation'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningDestinyLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          CheckingIfDestinyLocationHasMoreThanOneContainer: {
            tags:   ['loading'],
            invoke: {
              src:    'checkIfDestinyLocationHasMoreThanOneContainer',
              onDone: [
                {
                  cond:   'destinyLocationHasMoreThanOneContainer',
                  target: 'ScanningDestinyContainer'
                },
                {
                  target: 'Done'
                }
              ],
              onError: {
                actions: [
                  'assignError',
                  'assingScanningDestinyLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          ScanningDestinyContainer: {
            invoke: {
              id:  'ScanningDestinyContainer',
              src: ctx =>
                GetContainerByLpnMachine(
                  'Escanear contenedor',
                  getAnyContainerInLocationByLpn(
                    'Ocurrió un error, por favor intente de nuevo.',
                    ctx.destinyLocation?.id!
                  )
                ),
              onDone: {
                target:  'Done',
                actions: 'assignDestinyContainerValidatingLocation'
              },
              onError: {
                actions: [
                  'assignError',
                  'assingScanningDestinyLocationAlertData'
                ],
                target: '#BulkMovement.HandleErrors'
              }
            }
          },

          Done: {
            type: 'final'
          }
        }
      },
      CheckingDestinyAvailability: {
        tags:   ['loading'],
        invoke: {
          src:    'checkDestinyAvailabledQuantity',
          onDone: [
            {
              cond:    'isDestinyAvailable',
              target:  'MovingProductToLocation',
              actions: 'clearError'
            },
            {
              target:  'GoBackTo',
              actions: 'assignDestinyNotAvailableError'
            }
          ],
          onError: {
            actions: [
              'assignError',
              'assingScanningDestinyLocationAlertData',
              'clearDestiny'
            ],
            target: 'HandleErrors'
          }
        }
      },
      GoBackTo: {
        on: {
          ENTER_QUANTITY: {
            target:  'EnteringQuantity',
            actions: ['clearDestiny', 'clearError']
          },
          ENTER_DESTINY: {
            target:  'ScanningDestinyEntity',
            actions: ['clearDestiny', 'clearError']
          }
        }
      },
      MovingProductToLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'moveProductToLocation',
          onDone: {
            target: 'CompletingMoving'
          },
          onError: {
            actions: ['assignError', 'assingScanningDestinyLocationAlertData'],
            target:  'HandleErrors'
          }
        }
      },

      CompletingMoving: {
        on: {
          CONFIRM: {
            target: 'Finishing'
          }
        }
      },

      Finishing: {
        type: 'final'
      }
    }
  },
  {
    actions:  bulkMovementActions,
    services: bulkMovementServices,
    guards:   bulkMovementGuards
  }
);

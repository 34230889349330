import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchAuditedStatus extends OrderBatchStatus {
  public displayName = 'Auditado';

  public internalName = OrderBatchStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#e77c03';
}

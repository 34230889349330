import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Container } from './Container';
import { ContainerCapacityRule } from './ContainerCapacityRule';

export enum ContainerTypes {
  // Deprecated
  /**
   * @deprecated Use any of the other {@link ContainerTypes.PALLET*} instead.
   */
  PALLET = 'PALLET',
  /**
   * @deprecated Use any of the {@link ContainerTypes.CAJA*} instead.
   */
  BALDA = 'BALDA',

  // Ampliacion
  PALLETARLOG = 'PALLET ARLOG',
  PALLETALLADIO = 'PALLET ALLADIO',
  PALLETCOLCHONES = 'PALLET COLCHONES',
  ROLLCONTAINER = 'ROLL CONTAINER',
  CAJACHICA = 'CAJA CHICA',
  CAJAMEDIANA = 'CAJA MEDIANA',
  CAJAGRANDE = 'CAJA GRANDE',
  CAJADECARTON = 'CAJA DE CARTON',
  CUBETA = 'CUBETA'
}

@Entity()
export class ContainerType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  length: number;

  @Field()
  width: number;

  @Field()
  height: number;

  @Field()
  tare: number;

  @Field({ name: 'mini_allowed' })
  miniAllowed: boolean;

  @Field({ name: 'midi_allowed' })
  midiAllowed: boolean;

  @Field({ name: 'maxi_allowed' })
  maxiAllowed: boolean;

  @Field({ name: 'big_allowed' })
  bigAllowed: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Container, 'containerTypeId')
  containers: Container[];

  @NeededBy(() => ContainerCapacityRule, 'containerTypeId')
  containerRules: ContainerCapacityRule[];
}

import { Location, StandardUnloadingContainer, Task } from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { moveToBufferActions } from './actions';
import { moveToBufferServices } from './services';

export interface MoveToBufferContext {
  task: Task;
  containerLocationName: string;
  moveToBufferItemId: number;
  stagingLocation: Location | null;
  standardUnloadingContainer: StandardUnloadingContainer | null;
  destinyLocation: Location | null;
  destinyLocationLevel: number | null;
  error: string | null;
}

export const DefaultMoveToBufferContext: MoveToBufferContext = {
  task:                       {} as Task,
  containerLocationName:      '',
  moveToBufferItemId:         -1,
  stagingLocation:            null,
  standardUnloadingContainer: null,
  destinyLocation:            null,
  destinyLocationLevel:       null,
  error:                      null
};

export const MoveToBufferMachine = createMachine(
  {
    id:      'moveToBuffer',
    context: {} as MoveToBufferContext,
    initial: 'Initializing',
    states:  {
      Initializing: {
        invoke: {
          src:    'initialize',
          onDone: {
            actions: [
              'assignStagingLocation',
              'assignContainer',
              'assignDestinyLocation'
            ],
            target: 'ScanningControlDigit'
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: ['assignError']
          }
        }
      },
      ScanningControlDigit: {
        invoke: {
          src:  ScanControlDigitMachine,
          id:   ScanControlDigitMachine.id,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanee digito de control',
            requiredLocation: ctx.stagingLocation!
          }),
          onDone: {
            target: 'ScanningContainer'
          }
        }
      },
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee etiqueta de contenedor',
              getRequestedContainerByLpn(
                'Ocurrio un error, intenta nuevamente',
                'El contenedor escaneado no es valido',
                ctx.standardUnloadingContainer!.container.lpn
              )
            ),
          onDone: {
            target:  'ScanningDestinyLocation',
            actions: ['clearError']
          },
          onError: {
            target:  'ScanningContainer',
            actions: ['assignError']
          }
        }
      },
      ScanningDestinyLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee ubicación de destino',
              getRequestedLocationByName(
                'Ocurrio un error, intenta nuevamente',
                'La ubicación escaneada no es valida',
                ctx.destinyLocation!.name
              )
            ),
          onDone: {
            target: 'SendingToParentMoveToBufferItemMoved'
          }
        }
      },
      SendingToParentMoveToBufferItemMoved: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'MoveToBufferItemMoved',
              payload: {
                moveToBufferItemId:           ctx.moveToBufferItemId,
                standardUnloadingContainerId:
                  ctx.standardUnloadingContainer!.id,
                locationId: ctx.destinyLocation!.id
              }
            };
          },
          onDone: {
            target: 'Done'
          },
          onError: {
            target:  'ScanningDestinyLocation',
            actions: ['assignError']
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    services: moveToBufferServices,
    actions:  moveToBufferActions
  }
);

import { api } from '../../../../../../api/utils/axios-instance';
import {
  CustomFetchResultError,
  fetchResult
} from '../../../../../../utils/fetch-result';
import { GetItemByLabelFn } from '../machine';

export function fetchPackingItemsFromPackageByLabel(
  defaultErrorMessage: string
): GetItemByLabelFn {
  return fetchResult(defaultErrorMessage, label => {
    if (label) {
      return api.get('package/package-label', {
        params: { label }
      });
    } else {
      throw new CustomFetchResultError(defaultErrorMessage);
    }
  });
}

import { MovingToSorterExitProcessStatuses } from '../MovingToSorterExitProcess';
import { MovingToSorterExitProcessStatus } from './MovingToSorterExitProcessStatus';

export class MovingToSorterExitProcessStatusProcessingStatus extends MovingToSorterExitProcessStatus {
  public displayName = 'Procesando';

  public internalName = MovingToSorterExitProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { UnloadingProcessStatuses } from '../UnloadingProcess';
import { UnloadingProcessStatus } from './UnloadingProcessStatus';

export class UnloadingProcessStatusCompletedStatus extends UnloadingProcessStatus {
  public displayName = 'Completado';

  public internalName = UnloadingProcessStatuses.Completed;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

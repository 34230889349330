import { MachineOptions } from 'xstate';
import { DispatchSSContext } from './machine';

type DispatchSSGuards = MachineOptions<DispatchSSContext, any>['guards'];

export const dispatchSSGuards: DispatchSSGuards = {
  doesNotHaveSuggestedDispatchingItem: (_ctx, evt) => {
    return !evt.data.dispatchingItem;
  },
  isPartiallyDispatch: (_ctx, evt) => {
    return evt.data.isPartiallyDispatch;
  }
};

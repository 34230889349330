import Ink from '@ink';
import React from 'react';
import { mapLogisticOperator } from '../../../utils/handle-logistic-operator';
import { HighlightedInfo } from '../../shared/HighlightedInfo';

export const LogisticOperator = (ctx: any) => (
  <Ink.Box flexDirection='column'>
    {ctx.orderType ? (
      <HighlightedInfo
        attribute='Transportista'
        value={`${mapLogisticOperator(ctx.orderType.name)}`}
      />
    ) : null}
  </Ink.Box>
);

import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusShippedStatus extends OrderStatus {
  public displayName = 'Expedido';

  public internalName = OrderStatuses.Shipped;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}

import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { LocationLocationAttribute } from './LocationLocationAttribute';

export const locationAttributePalletTruckType = {
  SIMPLE: 'simplepalletjack',
  DOUBLE: 'doublepalletjack'
} as const;

export const locationAttributeOperationType = {
  PICKING:      'picking',
  STORAGE:      'storage',
  STORAGE_MINI: 'storage-mini',
  REVIEW:       'review',
  REPAIR:       'repair'
} as const;

export const operationTypesDisplayName = {
  [locationAttributeOperationType.PICKING]:      'Picking',
  [locationAttributeOperationType.STORAGE]:      'Almacenamiento',
  [locationAttributeOperationType.STORAGE_MINI]: 'Almacenamiento Mini',
  [locationAttributeOperationType.REVIEW]:       'Revisar',
  [locationAttributeOperationType.REPAIR]:       'Reparar'
} as const;

export const LocationAttributeNames = {
  PALLETTRUCKTYPE:   'pallet-truck-type',
  OPERATIONTYPE:     'operationType',
  ROTATION:          'rotation',
  CATEGORY:          'category',
  SUBCATEGORY:       'subcategory',
  OVERFLOW:          'overflow',
  MHE:               'mhe',
  SORTERENTRY:       'sorterEntry',
  SORTEREXIT:        'sorterExit',
  SORTERPTWEXIT:     'sorterPtwExit',
  SORTERPACKINGEXIT: 'sorterPackingExit',
  SORTERNOREAD:      'sorterNoRead',
  SORTEROVERFLOW:    'sorterOverflow',
  SORTERPTWENTRY:    'sorterPtwEntry',
  SIZETYPE:          'sizeType',
  ALLOWMULTISKU:     'allowMultiSku',
  VOLUME:            'volume'
} as const;

export const RotationAttributeNameList = {
  A:    ['A-', 'A', 'A+'],
  B:    ['B-', 'B', 'B+'],
  C:    ['C-', 'C', 'C+'],
  'A-': ['A-', 'A', 'A+'],
  'A+': ['A-', 'A', 'A+'],
  'B-': ['B-', 'B', 'B+'],
  'B+': ['B-', 'B', 'B+'],
  'C-': ['C-', 'C', 'C+'],
  'C+': ['C-', 'C', 'C+']
};

export type LocationAttributeName =
  (typeof LocationAttributeNames)[keyof typeof LocationAttributeNames];

export type LocationAttributePalletTruckType =
  (typeof locationAttributePalletTruckType)[keyof typeof locationAttributePalletTruckType];

export type LocationAttributeValues = LocationAttributePalletTruckType;

@Entity()
export class LocationAttribute {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field()
  value: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => LocationLocationAttribute, 'locationAttributeId')
  locationLocationAttributes: LocationLocationAttribute[];
}

import { MovePendingDispatchingItemsProcessStatuses } from '../MovePendingDispatchingItemsProcess';
import { MovePendingDispatchingItemsProcessStatus } from './MovePendingDispatchingItemsProcessStatus';

export class MovePendingDispatchingItemsProcessProcessingStatus extends MovePendingDispatchingItemsProcessStatus {
  public displayName = 'Procesando';

  public internalName = MovePendingDispatchingItemsProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

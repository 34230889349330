import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusPartiallyShippedStatus extends OrderStatus {
  public displayName = 'Parcialmente Expedido';

  public internalName = OrderStatuses.PartiallyShipped;

  public statusColor = '#fbf9da';

  public textColor = '#ffbf4d';
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { ContainerDetailContext } from './machine';

type LabelDetailActionsType = MachineOptions<
  ContainerDetailContext,
  any
>['actions'];

export const containerDetailActions: LabelDetailActionsType = {
  assignContainer: assign({
    container: (_, event) => event.data
  }),
  assignItems: assign({
    items: (_ctx, event) => {
      return event.data.items;
    }
  }),
  assignFlowIds: assign({
    orderIds:     (_ctx, event) => event.data.orderIds,
    receiptId:   (_ctx, event) => event.data.receiptId,
    destination: (_ctx, event) => event.data.destination
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return (
        getErrorView(event.data ?? event.data.data) ||
        'Ha ocurrido un error al buscar el detalle de la Etiqueta'
      );
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  restartValues: assign({
    container: null,
    items:     null,
    orderIds:   null,
    receiptId: null,
    error:     null
  })
};

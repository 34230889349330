import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanControlDigit } from '../../capa-4/scan-control-digit/ScanControlDigit';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { BigPickingWaveMachine } from './machine';

export const BigPickingWaveTask = withActorRef(BigPickingWaveMachine)(
  ({ actorRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Picking Ola Nº{current.context.pickingWaveId} -{' '}
          {current.context.deliveryType} - Autoestibas Big
        </Ink.Header>
        {/* Header */}
        <Ink.Box>
          {current.matches('ScanningClamp') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Escanear Clamp</Ink.Text>
            </Ink.Box>
          )}
          {current.matches('ScanningControlDigit') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text>
                {current.context.currentPickItem?.sku} -{' '}
                {current.context.currentPickItem?.product.name}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Trasládese hasta ubicación:</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.inventoryItem.location.name}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningSKU') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text>
                Total a pickear:{' '}
                <Ink.Chalk bold color='greenBright'>
                  {`${
                    current.context.currentPickItem?.orderItem.quantity || 'N/A'
                  } unidades`}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text>
                Restantes:
                <Ink.Chalk bold color='greenBright'>
                  {`${
                    current.context.currentPickItem?.quantity || 'N/A'
                  } unidades`}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>
                Cantidad a pickear:{' '}
                <Ink.Chalk bold color='greenBright'>
                  {`${current.context.suggestedPickQuantity || 'N/A'} unidades`}
                </Ink.Chalk>
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Chalk highlightColor bold>
                Escanee SKU del producto:
              </Ink.Chalk>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.sku} -{' '}
                {current.context.currentPickItem?.product.name}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('EnteringQuantity') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>
                Tome hasta {current.context.suggestedPickQuantity} unidades con
                el clamp.
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ScanningStagingLocation') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Picking completado </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Trasládese hasta ubicación:</Ink.Text>
              <Ink.Text color='greenBright' bold>
                {
                  current.context.currentPickItem?.orderItem.order.appointment
                    .staging.name
                }
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('UnloadingScanningSKU') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text> Deposite SKU en el staging </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Chalk highlightColor bold>
                Escanee SKU del producto:
              </Ink.Chalk>
              <Ink.Text color='greenBright' bold>
                {current.context.currentPickItem?.sku} -{' '}
                {current.context.currentPickItem?.product.name}
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches('UnloadingEnteringQuantity') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>
                SKU {current.context.pickItemsIndex} de{' '}
                {current.context.pickItemsQuantity}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>Ingrese cantidad de unidades depositadas</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ConfirmingScreen') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold>Deposito completada</Ink.Text>
              <Ink.Text>
                {' '}
                ¿Quieres terminar la tarea de Picking #
                {current.context.pickingWaveId}?
              </Ink.Text>
              <Ink.Text> </Ink.Text>
            </Ink.Box>
          )}
        </Ink.Box>

        {/* Body */}
        <>
          {current.hasTag('loading') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Cargando... </Ink.Text>
            </Ink.Box>
          )}

          {current.hasTag('controldigit') && (
            <Ink.Box flexDirection='column'>
              <ScanControlDigit />
            </Ink.Box>
          )}

          {current.hasTag('sku') && (
            <Ink.Box flexDirection='column'>
              <ScanSKU />
            </Ink.Box>
          )}

          {current.hasTag('location') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByName />
            </Ink.Box>
          )}

          {current.hasTag('quantity') && (
            <Ink.Box flexDirection='column'>
              <EnterQuantity />
            </Ink.Box>
          )}

          {current.matches('ConfirmingScreen') && (
            <Ink.Box flexDirection='row' justifyContent='space-between'>
              <Ink.Button
                style={{ width: '40%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Si'
              />
              <Ink.Button
                style={{ width: '40%' }}
                onClick={() => {
                  send('cancel');
                }}
                label='No'
              />
            </Ink.Box>
          )}
        </>
      </Ink.Box>
    );
  }
);

import {
  ContainerType,
  ContainerTypes,
  Location,
  Product,
  StandardUnloadingContainer,
  StandardUnloadingItem,
  StandardUnloadingProcess,
  Task
} from '@wms/domain';
import { createMachine } from 'xstate';

import { AlertData } from '../../../../types/alerData';

import {
  DefaultEnterLpnContext,
  EnterLpnMachine
} from '../../capa-4/enter-lpn/EnterLpnMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanControlDigitContext,
  ScanControlDigitMachine
} from '../../capa-4/scan-control-digit/scan-control-digit.machine';

import { GoToOptionsEvent } from '../../core/GenericOptions';
import { SendToParentMachine } from '../../core/SendToParentMachine';

import { PopUpMachine } from '../../layer-4/pop-up/machine';
import { getAnyProductBySKUToValidate } from '../../layer-4/product/get-product-by-sku/fetchers/get-any-product-by-sku-to-validate';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { unloadingV3Actions } from './actions';
import { unloadingV3Guards } from './guards';
import { unloadingV3Services } from './services';

export interface UnloadingV3Context {
  task: Task;
  standardUnloadingProcessId: number;

  standardUnloadingProcess: StandardUnloadingProcess | null;
  stagingName: string;
  receiptId: number;

  stagingLocation: Location | null;

  SKUScanned: Product | null;
  standardUnloadingItemScanned: StandardUnloadingItem | null;

  standardUnloadingContainerScanned: StandardUnloadingContainer | null;

  containerLabel: string | null;

  containerType: ContainerType | null;

  receivedQuantity: number | null;
  quantityLeft: number | null;

  containerTypes: ContainerType[];
  allowedContainers: string[];

  detailOfItems: Array<{
    id: number;
    sku: string;
    expectedQuantity: number;
    actualQuantity: number;
    missingQuantity: boolean;
  }>;

  alertData: AlertData;

  error: string | null;
}

export const DefaultUnloadingV3Context: UnloadingV3Context = {
  task:                       {} as Task,
  standardUnloadingProcessId: -1,
  standardUnloadingProcess:   null,
  stagingName:                '',
  receiptId:                  -1,

  stagingLocation: null,

  SKUScanned:                   null,
  standardUnloadingItemScanned: null,

  standardUnloadingContainerScanned: null,

  containerLabel: null,

  containerType: null,

  receivedQuantity: null,
  quantityLeft:     null,

  containerTypes:    [],
  allowedContainers: [ContainerTypes.ROLLCONTAINER, ContainerTypes.PALLETARLOG],
  detailOfItems:     [],
  error:             null,
  alertData:         {
    errorTitle:     'Error',
    errorMessage:   'Ocurrió un error, por favor intente de nuevo',
    positiveTarget: null,
    negativeTarget: null,
    target:         null
  }
};

export const UnloadingV3Machine = createMachine(
  {
    id:                         'unloadingV3',
    predictableActionArguments: true,
    schema:                     {
      context: {} as UnloadingV3Context
    },
    initial: 'Intializing',
    states:  {
      ErrorRouter: {
        always: [
          {
            cond:   'goToScanningSKU',
            target: 'ScanningSKU'
          },
          {
            cond:   'goToScanContainer',
            target: 'ScanContainer'
          }
        ]
      },
      HandleErrors: {
        invoke: {
          id:   PopUpMachine.id,
          src:  PopUpMachine,
          data: {
            alertData: ctx => {
              return ctx.alertData;
            }
          },
          onDone: {
            target:  'ErrorRouter',
            actions: ['clearError', 'restartValues']
          }
        }
      },

      Intializing: {
        invoke: {
          src:    'fetchStagingLocation',
          onDone: {
            target:  'FetchingStandardUnloadingProcess',
            actions: 'assignStagingLocation'
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: 'assignError'
          }
        }
      },
      FetchingStandardUnloadingProcess: {
        invoke: {
          src:    'fetchStandardUnloadingProcess',
          onDone: [
            {
              cond:    'hasContainerTypes',
              target:  'ScanningControlDigit',
              actions: 'assignStandardUnloadingProcess'
            },
            {
              target:  'FetchingContainerTypes',
              actions: 'assignStandardUnloadingProcess'
            }
          ]
        }
      },
      FetchingContainerTypes: {
        invoke: {
          src:    'fetchContainerTypes',
          onDone: {
            target:  'ScanningControlDigit',
            actions: 'assignContainerTypes'
          }
        }
      },
      ScanningControlDigit: {
        invoke: {
          src:  ScanControlDigitMachine,
          id:   ScanControlDigitMachine.id,
          data: ctx => ({
            ...DefaultScanControlDigitContext,
            hint:             'Escanee digito de control',
            requiredLocation: ctx.stagingLocation!
          }),
          onDone: {
            target:  'SelectingContainerType',
            actions: ['clearError']
          }
        }
      },
      SelectingContainerType: {
        on: {
          CONTAINER_TYPE_SELECTED: {
            target:  'CheckingIfHasMorePendingItemsToUnload',
            actions: ['assignContainerType']
          }
        }
      },

      ScanContainer: {
        invoke: {
          src:  EnterLpnMachine,
          id:   EnterLpnMachine.id,
          data: ctx => ({
            ...DefaultEnterLpnContext,
            hint:              'Escanee etiqueta de contenedor',
            containerTypeName: ctx.containerType?.name
          }),
          onDone: {
            target:  'SendingToParentCreatedContainer',
            actions: ['assingContainerLabel', 'clearError']
          },
          onError: {
            target:  'ScanContainer',
            actions: 'assignError'
          }
        },
        on: {
          CHANGE_CONTAINER_TYPE: {
            actions: ['assignContainerType']
          }
        }
      },
      SendingToParentCreatedContainer: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StandardUnloadingCreateContainer',
              payload: {
                containerTypeId: ctx.containerType?.id,
                lpn:             ctx.containerLabel,
                locationId:      ctx.stagingLocation?.id
              }
            };
          },
          onDone:  { target: 'FetchingstandardUnloadingContainerScanned' },
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingValidationContainerAlertData']
          }
        }
      },

      ScanningSKU: {
        invoke: {
          id:  GetProductBySKUMachineId,
          src: GetProductBySKUMachine(
            'Escanee SKU de producto a recibir',
            getAnyProductBySKUToValidate(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            target:  'CheckingIfSkuScannedWasUnloaded',
            actions: ['assignSKUScanned', 'clearError']
          },
          onError: {
            target:  'ScanningControlDigit',
            actions: 'assignError'
          }
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      DetailOfReceipt: {
        on: {
          CONTINUE: {
            target: 'ScanContainer'
          }
        }
      },

      ValidateSkuInCurrentContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'validateSkuInCurrentContainer',
          onDone: [
            {
              cond:   'canUnloadedSkuInCurrentContainer',
              target: 'IndicatingQuantityReceived'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingValidationContainerAlertData']
          }
        }
      },
      CheckingIfSkuScannedWasUnloaded: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfSkuScannedWasUnloaded',
          onDone: [
            {
              target:  'ValidateSkuInCurrentContainer',
              actions: 'assignQuantityLeft'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },
      FetchingstandardUnloadingContainerScanned: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStandardUnloadingContainerScanned',
          onDone: {
            target:  'ScanningSKU',
            actions: ['assignStandardUnloadingContainerScanned']
          },
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },
      IndicatingQuantityReceived: {
        invoke: {
          src:  EnterQuantityMachine,
          id:   EnterQuantityMachine.id,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            min: 1,
            max: ctx.quantityLeft
          }),
          onDone: [
            {
              target:  'ValidateContainerCapacityRule',
              actions: ['assignReceivedQuantity']
            },
            {
              target:  'ConfirmingLabels',
              actions: ['assignReceivedQuantity']
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },
      ValidateContainerCapacityRule: {
        invoke: {
          src:    'validateContainerCapacityRule',
          onDone: [
            {
              target: 'ConfirmingLabels'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingValidationContainerAlertData']
          }
        }
      },
      ConfirmingLabels: {
        on: {
          LABELS_CONFIRMED: {
            target: 'sendingToParentUnloadedItem'
          }
        }
      },
      sendingToParentUnloadedItem: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task:    ctx.task,
              type:    'StandardUnloadingUnloadItem',
              payload: {
                unloadingContainerId: ctx.standardUnloadingContainerScanned?.id,
                productId:            ctx.SKUScanned!.id,
                unloadedQuantity:     ctx.receivedQuantity!
              }
            };
          },
          onDone: [
            {
              target: 'CheckingIfHasMorePendingItemsToUnload'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },

      CheckingIfHasMorePendingItemsToUnload: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfHasMorePendingItemsToUnload',
          onDone: [
            {
              cond:    'hasPendingItemsToUnloaded',
              target:  'ScanContainer',
              actions: ['assingDetailOfItems', 'clearContainersCtx']
            },
            {
              target: 'Finishing'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },

      CheckingIfAllQuantityWasReceived: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfHasMorePendingItemsToUnload',
          onDone: [
            {
              cond:    'hasPendingItemsToUnloaded',
              target:  'WarningAllItemsWasNotReceived',
              actions: ['assingDetailOfItems']
            },
            {
              target: 'Finishing'
            }
          ],
          onError: {
            target:  'HandleErrors',
            actions: ['assignError', 'assingFethProductAlertData']
          }
        }
      },
      WarningAllItemsWasNotReceived: {
        on: {
          FINISHING: {
            target: 'SendingToParentCompleteTask'
          },
          UNLOADING: {
            target: 'CheckingIfHasMorePendingItemsToUnload'
          }
        }
      },
      Finishing: {
        on: {
          CONFIRMING: 'SendingToParentCompleteTask'
        }
      },
      SendingToParentCompleteTask: {
        tags:   ['loading'],
        invoke: {
          src:  SendToParentMachine,
          id:   SendToParentMachine.id,
          data: ctx => {
            return {
              task: ctx.task,
              type: 'StandardUnloadingCompleteTask'
            };
          },
          onDone: {
            target: 'Done'
          }
        }
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      PARTIALLY_UNLOADING:   'CheckingIfAllQuantityWasReceived',
      GO_TO_DetailOfReceipt: 'DetailOfReceipt',
      GO_TO_SCAN_CONTAINER:  'ScanContainer'
    }
  },
  {
    actions: {
      ...unloadingV3Actions,
      triggerMenuScreen: (
        ctx,
        { send, state, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        triggerMenuScreen([
          ...(state.matches('ScanningSKU') || state.matches('ScanContainer')
            ? [
                {
                  label:   'Forzar Pre-Cierre',
                  onClick: () => send('PARTIALLY_UNLOADING')
                }
              ]
            : []),
          ...(state.matches('ScanContainer')
            ? [
                {
                  label:   'Detalle de la Recepcion',
                  onClick: () => send('GO_TO_DetailOfReceipt')
                }
              ]
            : []),

          ...(state.matches('ScanningSKU') ||
          state.matches('IndicatingQuantityReceived')
            ? [
                {
                  label:   'Escanear contenedor',
                  onClick: () => send('GO_TO_SCAN_CONTAINER')
                }
              ]
            : [])
        ]);
      }
    },
    guards:   unloadingV3Guards,
    services: unloadingV3Services
  }
);

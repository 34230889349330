import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { CustomerOrder } from './CustomerOrder';
import { FulfillmentOrder } from './FulfillmentOrder';
import { Novelty } from './Novelty';
import { PutToWallOrder } from './PutToWallOrder';
import { ReservationItem } from './ReservationItem';
import { ReservationProduct } from './ReservationProduct';
import { ReservationReceipt } from './ReservationReceipt';
import { ReservationType } from './ReservationType';
import { Warehouse } from './Warehouse';
import {
  ReservationStatus,
  ReservationStatusAnnulledStatus,
  ReservationStatusCancelledStatus,
  ReservationStatusDeliveredStatus,
  ReservationStatusDispatchedStatus,
  ReservationStatusNotDeliveredStatus,
  ReservationStatusNotDispatchedStatus,
  ReservationStatusPartiallyDispatchedStatus,
  ReservationStatusProcessingStatus,
  ReservationStatusRejectedStatus,
  ReservationStatusRoutedStatus,
  ReservationStatusUnRoutedStatus
} from './reservation-status/';

import { ReservationIdentifier } from './reservationIdentifier';

export const SelectedModality = {
  HD:     'F',
  SS:     'V',
  MANUAL: 'M',
  DIST:   'D'
} as const;

export const RejectionReasonReservation = {
  Entregado:                'entregado',
  ClienteAusente:           'cliente ausente',
  DireccionIncorrecta:      'direccion incorrecta',
  EntregaParcial:           'entrega parcial',
  ZonaPeligrosa:            'zona peligrosa',
  ZonaIntransitable:        'zona instransitable',
  TransbordoDeMercaderia:   'trasbordo de mercaderia',
  TransporteNoVisita:       'transporte no realiza visita',
  Siniestro:                'siniestro de mercaderia',
  ProductoNoDespachado:     'producto no despachado',
  RechazoPorAnulacion:      'rechazo por anulacion',
  ClienteAnulara:           'cliente anulara',
  ClienteRepactaFecha:      'cliente repacta fecha',
  RechazoPorDetalle:        'rechazo por detalle',
  ProductoYaEntregado:      'producto ya entregado',
  ProductoNoCorresponde:    'producto no corresponde',
  ArtNoIngresaAlDomicilio:  'art. no ingresa al domicilio',
  AlphaInforma:             'informado en alpha',
  AlphaInformaRetiroCambio: 'informado en alpha retiro o cambio',
  NoInformado:              'wmo no informo'
} as const;

export const RejectTypes = {
  Success:  '01',
  Rejected: '00'
};

export const TargetOrderType = {
  PRO: 'PRO',
  RES: 'RES',
  ENO: 'ENO',
  RET: 'RET',
  RCB: 'RCB'
};
export type TargetOrderTypes =
  (typeof TargetOrderType)[keyof typeof TargetOrderType];

export type SelectedReservationRejectionReasonTypes =
  (typeof RejectionReasonReservation)[keyof typeof RejectionReasonReservation];

export type SelectedModalityTypes =
  (typeof SelectedModality)[keyof typeof SelectedModality];

export const ReservationStatuses = {
  Unrouted:            'un-routed',
  Routed:              'routed',
  Processing:          'processing',
  Dispatched:          'dispatched',
  Delivered:           'delivered',
  Rejected:            'rejected',
  Cancelled:           'cancelled', // TODO: Eliminar, redundante con el estado Rejected
  NotDelivered:        'not-delivered',
  PartiallyDispatched: 'partially-dispatched', // TODO: Eliminar, no sucede en HD
  NotDispatched:       'not-dispatched',
  Annulled:            'annulled'
};

export const ReservationPendingStatuses = [
  ReservationStatuses.Unrouted,
  ReservationStatuses.Routed,
  ReservationStatuses.Cancelled
] as const;

export const ReservationDispatchedStatuses = [
  ReservationStatuses.Dispatched,
  ReservationStatuses.Delivered,
  ReservationStatuses.PartiallyDispatched
] as const;

export interface ReservationCustomer {
  fullName: string;
  document: number;
  documentType: string;
  contactNumber: string;
  contactNumber2: string;
  socialReason: string;
  contactName: string;
  address: {
    zipCode: string;
    street: string;
    streetNumber: number;
    town: string;
    floor: number;
    apartmentNumber: string;
    references: string;
    province: string;
  };
}
export interface Bill {
  number: number;
  import: number;
  sellingPoint: string;
}

@Entity()
export class Reservation {
  @PrimaryKey()
  id: number;

  @Status({
    [ReservationStatuses.Unrouted]:            ReservationStatusUnRoutedStatus,
    [ReservationStatuses.Routed]:              ReservationStatusRoutedStatus,
    [ReservationStatuses.Rejected]:            ReservationStatusRejectedStatus,
    [ReservationStatuses.Dispatched]:          ReservationStatusDispatchedStatus,
    [ReservationStatuses.Delivered]:           ReservationStatusDeliveredStatus,
    [ReservationStatuses.Processing]:          ReservationStatusProcessingStatus,
    [ReservationStatuses.Cancelled]:           ReservationStatusCancelledStatus,
    [ReservationStatuses.NotDelivered]:        ReservationStatusNotDeliveredStatus,
    [ReservationStatuses.PartiallyDispatched]:
      ReservationStatusPartiallyDispatchedStatus,
    [ReservationStatuses.NotDispatched]: ReservationStatusNotDispatchedStatus,
    [ReservationStatuses.Annulled]:      ReservationStatusAnnulledStatus
  })
  status: ReservationStatus;

  @Field({ name: 'reserved_at' })
  reservedAt: Date;

  @Field({ name: 'deliver_before' })
  deliverBefore: Date;

  @Field({ name: 'external_reference_id' })
  externalReferenceId: string;

  bill: Bill;

  customer: ReservationCustomer;

  @NeededBy(() => ReservationIdentifier, 'reservationId')
  reservationIdentifiers: ReservationIdentifier[];

  @Field()
  modality: SelectedModalityTypes;

  @Field({ name: 'delivery_code' })
  deliveryCode: string;

  @NeededBy(() => ReservationProduct, 'reservationId')
  reservationProducts: ReservationProduct[];

  @NeededBy(() => ReservationItem, 'reservationId')
  reservationItems: ReservationItem[];

  @NeededBy(() => PutToWallOrder, 'reservationId')
  putToWallOrder: PutToWallOrder;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Knows(() => Warehouse, 'warehouseToId')
  warehouseTo: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'target_warehouse_id' })
  targetWarehouseId: number;

  @Knows(() => Warehouse, 'targetWarehouseId')
  targetWarehouse: Warehouse;

  @ForeignKey(() => Warehouse, { name: 'warehouse_to_id' })
  warehouseToId: number;

  @Knows(() => FulfillmentOrder, 'reservationId')
  fulfillmentOrder: FulfillmentOrder;

  @NeededBy(() => CustomerOrder, 'reservationId')
  customerOrder: CustomerOrder;

  @Field({ name: 'rejection_details' })
  rejectionDetails: SelectedReservationRejectionReasonTypes;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => ReservationType, { name: 'reservation_type_id' })
  reservationTypeId: number;

  @Needs(() => ReservationType, 'reservationTypeId')
  reservationType: ReservationType;

  @NeededBy(() => Novelty, 'reservationId')
  novelties: Novelty[];

  @Field({ name: 'delivery_type' })
  deliveryType: number;

  @NeededBy(() => ReservationReceipt, 'reservationId')
  reservationReceipt: ReservationReceipt[];
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MoveToBufferContext } from './machine';

type MoveToBufferActions = MachineOptions<MoveToBufferContext, any>['actions'];

export const moveToBufferActions: MoveToBufferActions = {
  assignStagingLocation: assign({
    stagingLocation: (_, event) => event.data.stagingLocation
  }),
  assignContainer: assign({
    standardUnloadingContainer: (_, event) =>
      event.data.standardUnloadingContainer
  }),
  assignDestinyLocation: assign({
    destinyLocation:      (_, event) => event.data.destinyLocation,
    destinyLocationLevel: (_, event) => event.data.destinyLocationLevel
  }),
  assignError: assign({
    error: (_, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearError: assign({
    error: (_, __) => null
  })
};

import { ButtonProps } from '@wms/client-core';
import React, {
  CSSProperties,
  FC,
  MouseEventHandler,
  useCallback
} from 'react';

const DEFAULT_STYLE = {};

const MANDATORY_STYLE: CSSProperties = {
  marginTop:     '0.5rem',
  marginBottom:  '0.5rem',
  paddingTop:    '1rem',
  paddingBottom: '1rem'
};

export const Button: FC<ButtonProps> = ({
  style = DEFAULT_STYLE,
  id,
  label,
  onClick,
  disabled
}) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => onClick(id),
    [onClick]
  );

  return (
    <button
      id={id}
      style={{ ...MANDATORY_STYLE, ...style }}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

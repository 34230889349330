import { PackingItemStatuses } from '../PackingItem';
import { PackingItemStatus } from './PackingItemStatus';

export class PackingItemStatusMissingStatus extends PackingItemStatus {
  public displayName = 'Perdido';

  public internalName = PackingItemStatuses.Missing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

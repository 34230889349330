import { ContainerStatuses } from '../Container';
import { ContainerStatus } from './ContainerStatus';

export class ContainerStatusAvailableStatus extends ContainerStatus {
  public displayName = 'Disponible';

  public internalName = ContainerStatuses.Available;

  public statusColor = '#Ffffff';
}

import { MachineOptions, assign } from 'xstate';
import { NoSortableSlottingTaskContext } from './machine';

type NoSortableSlottingTaskActions = MachineOptions<
  NoSortableSlottingTaskContext,
  any
>['actions'];

export const noSortableSlottingTaskActions: NoSortableSlottingTaskActions = {
  assignContainers: assign({
    totalContainers:   (_ctx, event) => event.data.totalContainers,
    slottedContainers: (_ctx, event) => event.data.slottedContainers
  }),

  assignSuggestedContainerToNoSortableSlotting: assign({
    suggestedContainerToNoSortableSlotting: (_ctx, event) =>
      event.data.suggestedContainerToNoSortableSlotting
  }),

  assignContainerToNoSortableSlotting: assign({
    containerToNoSortableSlotting: (_ctx, event) => event.data
  }),

  assignSuggestedLocationToStore: assign({
    suggestedLocationToStore: (_ctx, event) =>
      event.data.suggestedLocationToStore
  }),

  assignLocationToStore: assign({
    locationToStore: (_ctx, event) => event.data
  })
};

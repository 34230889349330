import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { PrintPutToWallLabelsMachine } from './machine';

export const PrintPutToWallLabels = withActorRef(PrintPutToWallLabelsMachine)(
  ({ actorRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [current, send] = useActor(actorRef);

    const onSubmitInput = (input: string) => {
      if (input === '') {
        return;
      }
      send({
        type: 'SUBMIT_INPUT',
        data: { input }
      });
    };

    const changeInput = (input: string) => {
      send({
        type: 'CHANGE',
        data: { input }
      });
    };

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        {/* Cargando */}
        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Header>
                Impresion de Etiquetas PutToWall - Cargando...
              </Ink.Header>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : (
            <Ink.Header>Impresion de Etiquetas PutToWall</Ink.Header>
          )}
        </>

        {/* Header */}
        <>
          {current.matches('AwaitingReservationInput') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Escanee el identificador de la reserva</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ReservationLabelPrinted') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold color='greenBright'>
                Impresion realizada correctamente
              </Ink.Text>
            </Ink.Box>
          )}
        </>

        {/* Body */}
        <>
          {current.matches('FetchTransportOrderByLabel') && (
            <Ink.Box flexDirection='column'>
              <GetItemByLabel id='FetchTransportOrderByLabel' />
            </Ink.Box>
          )}

          {current.matches('AwaitingReservationInput') && (
            <>
              <Ink.TextInput
                onChange={changeInput}
                value={current.context.reservationId}
                onSubmit={onSubmitInput}
              />
            </>
          )}

          {current.matches('PrintingReservationLabel') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Imprimiendo...</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ReservationLabelPrinted') && (
            <Ink.Box flexDirection='column'>
              <Ink.Box flexDirection='column'>
                <HighlightedInfo
                  attribute='Etiqueta impresa correctamente para la reserva'
                  value={current.context.reservationId}
                />
              </Ink.Box>

              <Ink.Button
                label='Imprimir otra etiqueta'
                onClick={() => send('reset')}
              />
            </Ink.Box>
          )}

          {current.context.error && (
            <ErrorMessage bold error={current.context.error} />
          )}
        </>
      </Ink.Box>
    );
  }
);

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { StagingAuditingContext } from './machine';

type StagingAuditingActionsType = MachineOptions<
  StagingAuditingContext,
  any
>['actions'];

export const stagingAuditingActions: StagingAuditingActionsType = {
  assignStagingLocation: assign({
    stagingLocation: (_ctx, event) => event.data.stagingLocation
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(event.data.data) ||
      event.data.response.data.errorView ||
      event.data.response.data.message
  }),
  assignContainersToAudit: assign({
    containersToAudit: (_ctx, event) => event.data.containersToAudit
  }),
  assignAuditingContainer: assign({
    auditingContainer: (_ctx, event) => event.data
  }),
  assingTotalContainers: assign({
    totalContainers: (_ctx, event) => event.data.totalContainers
  }),
  assignItemLabel: assign({
    itemLabel: (_ctx, event) => event.data.label
  }),
  assignContainersAudited: assign({
    containersAudited: (_ctx, event) => event.data.containersAudited
  }),
  assignContainersMismatched: assign({
    containersMismatched: (_ctx, event) => event.data.containersMismatched
  }),
  clearItemLabel: assign({
    itemLabel: null
  }),
  clearError: assign({
    error: null
  }),
  clearAuditingContainer: assign({
    auditingContainer:        null,
    stagingAuditingContainer: null
  }),
  assignStagingAuditingContainer: assign({
    stagingAuditingContainer: (_ctx, event) =>
      event.data.stagingAuditingContainer
  })
};

import { PendingDispatchingPickItemStatuses } from '../PendingDispatchingPickItem';
import { PendingDispatchingPickItemStatus } from './PendingDispatchingPickItemStatus';

export class PendingDispatchingPickItemIdleStatus extends PendingDispatchingPickItemStatus {
  public displayName = 'Almacenado';

  public internalName = PendingDispatchingPickItemStatuses.Idle;

  public statusColor = '#e2f1f8';
}

import { LocationStatuses } from '../BaseLocation';
import { LocationStatus } from './LocationStatus';

export class LocationStatusDamagedStatus extends LocationStatus {
  public displayName = 'Dañada';

  public internalName = LocationStatuses.Damaged;

  public statusColor = '#ba000d';
}

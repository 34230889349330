import { ImpureSortableCrossdockClassificationContainerStatuses } from '../ImpureSortableCrossdockClassificationContainer';
import { ImpureSortableCrossdockClassificationContainerStatus } from './ImpureSortableCrossdockClassificationContainerStatus';

export class ImpureSortableCrossdockClassificationContainerStatusOpenStatus extends ImpureSortableCrossdockClassificationContainerStatus {
  public displayName = 'Abierto';

  public internalName =
    ImpureSortableCrossdockClassificationContainerStatuses.Open;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

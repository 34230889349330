import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { PendingDispatchingPickingWave } from './PendingDispatchingPickingWave';
import { ReservationPendingContainer } from './ReservationPendingContainer';
import {
  PendingDispatchingPickItemCancelledStatus,
  PendingDispatchingPickItemIdleStatus,
  PendingDispatchingPickItemMissingStatus,
  PendingDispatchingPickItemPickedStatus,
  PendingDispatchingPickItemStatus
} from './pending-dispatching-pick-item-status';

export const PendingDispatchingPickItemStatuses = {
  Idle:      'idle',
  Picked:    'picked',
  Missing:   'missing',
  Cancelled: 'cancelled'
};
@Entity()
export class PendingDispatchingPickItem {
  @PrimaryKey()
  id: number;

  @Status({
    [PendingDispatchingPickItemStatuses.Idle]:
      PendingDispatchingPickItemIdleStatus,
    [PendingDispatchingPickItemStatuses.Picked]:
      PendingDispatchingPickItemPickedStatus,
    [PendingDispatchingPickItemStatuses.Missing]:
      PendingDispatchingPickItemMissingStatus,
    [PendingDispatchingPickItemStatuses.Cancelled]:
      PendingDispatchingPickItemCancelledStatus
  })
  status: PendingDispatchingPickItemStatus;

  @ForeignKey(() => PendingDispatchingPickingWave, {
    name: 'pending_dispatching_picking_wave_id'
  })
  pendingDispatchingPickingWaveId: number;

  @ForeignKey(() => ReservationPendingContainer, {
    name: 'reservation_pending_container_id'
  })
  reservationPendingContainerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ReservationPendingContainer, 'reservationPendingContainerId')
  reservationPendingContainer: ReservationPendingContainer;

  @Needs(() => PendingDispatchingPickingWave, 'pendingDispatchingPickingWaveId')
  pendingDispatchingPickingWave: PendingDispatchingPickingWave;
}

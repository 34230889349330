import { PutToWallWaveStatuses } from '../PutToWallWave';
import { PutToWallWaveStatus } from './PutToWallWaveStatus';

export class PutToWallWaveStatusCompletedStatus extends PutToWallWaveStatus {
  public displayName = 'Completed';

  public internalName = PutToWallWaveStatuses.Completed;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

import { MachineOptions } from 'xstate';
import { FetchTranportOrderByLabelContext } from './machine';

type CloseTransportOrderGuards = MachineOptions<
  FetchTranportOrderByLabelContext,
  any
>['guards'];

export const closeTransportOrderGuards: CloseTransportOrderGuards = {
  checkIfStatus0: (_ctx, evt) => {
    return evt.data.data.code === 0;
  }
};

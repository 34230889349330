import { SlottingProcessStatuses } from '../SlottingProcess';
import { SlottingProcessStatus } from './SlottingProcessStatus';

export class SlottingProcessStatusPendingStatus extends SlottingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = SlottingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

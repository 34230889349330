import { SlottingContainerStatuses } from '../SlottingContainer';
import { SlottingContainerStatus } from './SlottingContainerStatus';

export class SlottingContainerStatusPendingStatus extends SlottingContainerStatus {
  public displayName = 'Pendiente';

  public internalName = SlottingContainerStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MachineOptions } from 'xstate';
import { ReportAnomalyContext } from './machine';

type ReportAnomalyGuards = MachineOptions<ReportAnomalyContext, any>['guards'];

export const reportAnomalyGuards: ReportAnomalyGuards = {
  isValidProductQuantity: (context, _event) => {
    return !!context.productQuantity;
  },
  isValidContainer: (context, event) => {
    return context.anomalyContainer?.lpn === event.data;
  },
  checkIfPendingContainersToMove: (context, _event) => {
    if (context.pendingContainersToMoveLpns.length) {
      return context.pendingContainersToMoveLpns.length > 0;
    }
    return false;
  },
  isScannedMovingContainer: (context, _event) => {
    return context.isMovingContainer;
  },

  isContainerInPendingListToMove: (context, _event) => {
    const pendingContainersLpns = context.pendingContainersToMoveLpns;
    const anomalyContainer = context.anomalyContainer!.lpn;
    return (
      context.isMovingContainer &&
      (pendingContainersLpns.includes(anomalyContainer) || false)
    );
  },
  goToScanningLocation: context => {
    return context.alertData.target === 'ScanningLocation';
  },
  goToScanningSKU: context => {
    return context.alertData.target === 'ScanningSKU';
  },
  goToScanningAnomalyContainerCreated: context => {
    return context.alertData.target === 'ScanningAnomalyContainerCreated';
  },
  goToConfirmingTransfer: context => {
    return context.alertData.target === 'ConfirmingTransfer';
  },
  goToScanningDestinationLocation: context => {
    return context.alertData.target === 'ScanningDestinationLocation';
  }
};

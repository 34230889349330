import { AuditingItemStatuses } from '../AuditingItem';
import { AuditingItemStatus } from './AuditingItemStatus';

export class AuditingItemAuditedStatus extends AuditingItemStatus {
  public displayName = 'Auditado';

  public internalName = AuditingItemStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { InventoryAdjustmentContext } from './machine';

type InventoryAdjustmentServices = MachineOptions<
  InventoryAdjustmentContext,
  any
>['services'];

export const inventoryAdjustmentServices: InventoryAdjustmentServices = {
  verifyStock: async ctx => {
    const { data: mismatItems } = await api.post('inventory-item/control', {
      locationId:    ctx.location?.id,
      containerLpn:  ctx.container?.lpn,
      itemsToAdjust: ctx.itemsToAdjust
    });

    return mismatItems;
  },
  mismatchInventoryAdjustment: async ctx => {
    const data = await api.post('inventory-item/mismatch-inventory-control', {
      locationId:    ctx.location?.id,
      containerLpn:  ctx.container?.lpn,
      mismatchItems: ctx.mismatchItems
    });

    return data;
  },
  orderIdsWithReservedItemsInLocation: async (ctx, _event) => {
    const { data } = await api.get(
      `location/${ctx.location?.id}/orders-with-reserved-items`
    );
    return {
      ordersWithReservedItemsInLocation: data
    };
  },
  getContainer: async (ctx, _evt) => {
    const { data } = await api.get(`container/verify-lpn/${ctx.lpn}`);

    return data;
  }
};

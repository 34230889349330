import { MoveToBufferItemStatuses } from '../MoveToBufferItem';
import { MoveToBufferItemStatus } from './MoveToBufferItemStatus';

export class MoveToBufferItemStatusCancelledStatus extends MoveToBufferItemStatus {
  public displayName = 'Cancelado';

  public internalName = MoveToBufferItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MoveBulksContext } from './machine';

type MoveBulksActionsType = MachineOptions<MoveBulksContext, any>['actions'];

export const moveBulksActions: MoveBulksActionsType = {
  assignEntity: assign({
    entity: (_ctx, event) => event.data.entityType,
    label:  (_ctx, event) => event.data.label
  }),
  assignNewLocation: assign({
    newLocation: (_ctx, event) => event.data
  }),
  assignError: assign({
    error: (_ctx, event) => getErrorView(event.data || event.data.data)
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  assignItem: assign({
    items: (_ctx, event) => event.data.items
  })
};

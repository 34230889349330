import { UserTaskRole } from '@wms/domain';
import { useManualApi } from './utils';

export interface User {
  recordIdOrUsername: string;
  password: string;
}
export interface AuthenticationPayload {
  token: string;
  user: {
    id: number;
    username: string;
    recordId: string;
    email: string;
    userTaskRoles: UserTaskRole[];
    employeeCode: number;
  };
}
export function useLogin(
  user: User,
  options?: ReactUseApi.Options
): [AuthenticationPayload, ReactUseApi.State, ReactUseApi.RequestFn] {
  return useManualApi(
    {
      method: 'POST',
      url:    process.env.BACKEND_HOST_LOGIN || 'http://localhost:8080/auth/login',
      data:   { ...user }
    },
    options
  );
}

export function useHHLogin(
  user: User,
  options?: ReactUseApi.Options
): [AuthenticationPayload, ReactUseApi.State, ReactUseApi.RequestFn] {
  console.log('useLogin', user);
  return useManualApi(
    {
      method: 'POST',
      url:
        process.env.BACKEND_HOST_LOGIN ||
        'http://localhost:8080/api/v1/user-auth/login',
      data: { ...user }
    },
    options
  );
}

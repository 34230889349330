import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { MoveToBufferItem } from './MoveToBufferItem';
import { Receipt } from './Receipt';
import {
  MoveToBufferProcessStatus,
  MoveToBufferProcessStatusCancelledStatus,
  MoveToBufferProcessStatusCompletedStatus,
  MoveToBufferProcessStatusPausedStatus,
  MoveToBufferProcessStatusPendingStatus,
  MoveToBufferProcessStatusProcessingStatus
} from './move-to-buffer-process-status';

export const MoveToBufferProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class MoveToBufferProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [MoveToBufferProcessStatuses.Pending]:
      MoveToBufferProcessStatusPendingStatus,
    [MoveToBufferProcessStatuses.Processing]:
      MoveToBufferProcessStatusProcessingStatus,
    [MoveToBufferProcessStatuses.Completed]:
      MoveToBufferProcessStatusCompletedStatus,
    [MoveToBufferProcessStatuses.Cancelled]:
      MoveToBufferProcessStatusCancelledStatus,
    [MoveToBufferProcessStatuses.Paused]: MoveToBufferProcessStatusPausedStatus
  })
  status: MoveToBufferProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => MoveToBufferItem, 'moveToBufferProcessId')
  moveToBufferItems: MoveToBufferItem[];
}

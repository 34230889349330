import { MopAuditingPickingWaveStatuses } from '../MopAuditingPickingWave';
import { MopAuditingPickingWaveStatus } from './MopAuditingPickingWaveStatus';

export class MopAuditingPickingWaveStatusCancelledStatus extends MopAuditingPickingWaveStatus {
  public displayName = 'Cancelado';

  public internalName = MopAuditingPickingWaveStatuses.Cancelled;

  public statusColor = '#Ffffff';
}

import { ReservationStatuses } from '../Reservation';
import { ReservationStatus } from './ReservationStatus';

export class ReservationStatusPartiallyDispatchedStatus extends ReservationStatus {
  public displayName = 'Parcialmente despachado';

  public internalName = ReservationStatuses.PartiallyDispatched;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

import { Entity } from '@skyframe/core';
import { BaseLocation } from './BaseLocation';
import { CategoryLocation } from './CategoryLocation';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { LocationType } from './LocationType';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';

export interface SectionMapData {
  aisles: Array<{
    id: number;
    name: string;
    locations: Array<{
      id: number;
      name: string;
      column: number;
      level: number;
      inventoryItemCount: number;
    }>;
  }>;
}

export const SectionNames = {
  Racks:           'Nave2_Racks',
  RacksMini:       'Nave2_Racks_MN',
  PickTowerLevel0: 'Nave2_PickTower_Nivel0',
  PickTowerLevel1: 'Nave2_PickTower_Nivel1',
  PickTowerLevel2: 'Nave2_PickTower_Nivel2',
  PickTowerLevel3: 'Nave2_PickTower_Nivel3',
  MezzanineLevel0: 'Nave2_Mezzanine_Nivel0',
  MezzanineLevel1: 'Nave2_Mezzanine_Nivel1',
  MezzanineLevel2: 'Nave2_Mezzanine_Nivel2',
  MezzanineLevel3: 'Nave2_Mezzanine_Nivel3'
} as const;

export const SectionGroupsNames = {
  [SectionNames.Racks]:           SectionGroups.MD,
  [SectionNames.RacksMini]:       SectionGroups.MN,
  [SectionNames.PickTowerLevel0]: SectionGroups.PR,
  [SectionNames.PickTowerLevel1]: SectionGroups.PR,
  [SectionNames.PickTowerLevel2]: SectionGroups.PR,
  [SectionNames.PickTowerLevel3]: SectionGroups.PR,
  [SectionNames.MezzanineLevel0]: SectionGroups.MZ0,
  [SectionNames.MezzanineLevel1]: SectionGroups.MZ,
  [SectionNames.MezzanineLevel2]: SectionGroups.MZ,
  [SectionNames.MezzanineLevel3]: SectionGroups.MZ
} as const;

export const enum SectionGroups {
  MD = 'MD',
  MN = 'MN',
  PR = 'PR',
  MZ0 = 'MZ0',
  MZ = 'MZ'
}

export const ReadableSectionNames = {
  [SectionNames.MezzanineLevel0]: 'Mezzanine nivel 0',
  [SectionNames.MezzanineLevel1]: 'Mezzanine nivel 1',
  [SectionNames.MezzanineLevel2]: 'Mezzanine nivel 2',
  [SectionNames.MezzanineLevel3]: 'Mezzanine nivel 3',
  [SectionNames.Racks]:           'Racks',
  [SectionNames.RacksMini]:       'Racks Mini',
  [SectionNames.PickTowerLevel0]: 'Pick Tower 0',
  [SectionNames.PickTowerLevel1]: 'Pick Tower 1',
  [SectionNames.PickTowerLevel2]: 'Pick Tower 2',
  [SectionNames.PickTowerLevel3]: 'Pick Tower 3'
} as const;

export const PickTowerSections = [
  SectionNames.PickTowerLevel0,
  SectionNames.PickTowerLevel1,
  SectionNames.PickTowerLevel2,
  SectionNames.PickTowerLevel3
] as const;

export const MezzanineSections = [
  SectionNames.MezzanineLevel0,
  SectionNames.MezzanineLevel1,
  SectionNames.MezzanineLevel2,
  SectionNames.MezzanineLevel3
] as const;

@Entity()
export class Section extends BaseLocation {
  rubric: CategoryLocation;

  families: never;

  checkDigit: never;

  status: never;

  side: never;

  column: never;

  level: never;

  parent: never;

  placementAllowed: never;

  routeId: never;

  /* Aisles */
  children: Location[];

  appointment: never;

  containers: Container[];

  inventoryItems: InventoryItem[];

  locationType: LocationType;

  warehouse: Warehouse;

  zoneClassifier: ZoneClassifier;

  crossDockWarehouse: Warehouse;

  route: never;
}

import { FeedingToSorterContainerStatuses } from '../FeedingToSorterContainer';
import { FeedingToSorterContainerStatus } from './FeedingToSorterContainerStatus';

export class FeedingToSorterContainerStatusProcessingStatus extends FeedingToSorterContainerStatus {
  public displayName = 'Procesando';

  public internalName = FeedingToSorterContainerStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

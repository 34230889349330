import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MiniMopAuditingWaveContext } from './machine';

type MiniMopAuditingWaveActions = MachineOptions<
  MiniMopAuditingWaveContext,
  any
>['actions'];

export const miniMopAuditingWaveActions: MiniMopAuditingWaveActions = {
  assignLpn: assign({
    interiorContainerLpn: (_, event) => event.data.label
  }),
  assignClosedLpn: assign({
    closedContainerLpn: (_, event) => event.data.lpn
  }),
  assignSuggestedContainerType: assign({
    interiorContainerSuggestedContainerType: (_, event) =>
      event.data.containerType
  }),
  assignAuditingItems: assign({
    auditingItems: (_, event) => event.data.auditingItems || event.data
  }),
  assignCurrentProduct: assign({
    currentProduct: (_, event) => event.data
  }),
  assignMismatchedItem: assign({
    mismatchItems: (ctx, event) => {
      let mmItems = ctx.mismatchItems;
      const item =
        mmItems.length > 0
          ? mmItems!.find(
              mmi => mmi.sku === (event.data.sku || ctx.currentProduct?.sku)
            )
          : undefined;
      if (item) {
        const index = mmItems.findIndex(mmi => mmi.sku === item.sku);
        mmItems[index].auditedQuantity++;
      } else {
        mmItems = [
          ...mmItems,
          {
            sku:             event.data.sku || ctx.currentProduct?.sku,
            name:            event.data.name || ctx.currentProduct?.name,
            auditedQuantity: 1
          }
        ];
      }
      return mmItems;
    }
  }),
  postAuditedCleanup: assign({
    currentProduct: (_, __) => null,
    auditingItems:  (_, __) => null
  }),
  assignProductAlreadyAuditedError: assign({
    error: (_, __) =>
      'El producto escaneado ya se encuentra auditado en su totalidad.'
  }),
  assignPendingProductsToAuditError: assign({
    error: (_, __) => 'Aún hay productos pendientes de escanear.'
  }),
  assignError: assign({
    error: (_, event) =>
      getErrorView(event.data?.data ?? event.data, 'Hubo un error al auditar')
  }),
  clearError: assign({
    error: (_, __) => null
  }),
  assignRefreshAuditingItemsError: assign({
    error: (_, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Hubo un error al actualizar los items auditados'
      )
  }),
  assignMissingItemsConfirm: assign({
    missingItemsConfirm: (_, __) => true
  }),
  assignMismatchItemsConfirm: assign({
    mismatchItemsConfirm: (_, __) => true
  }),
  clearMissingItemsConfirm: assign({
    missingItemsConfirm: (_, __) => false
  }),
  clearMismatchItemsConfirm: assign({
    mismatchItemsConfirm: (_, __) => false
  }),
  assignMissingAuditingItems: assign({
    missingAuditingItems: (ctx, _event) =>
      ctx.auditingItems!.filter(ai => ai.auditedQuantity < ai.expectedQuantity)
  }),
  clearMissingAuditingItem: assign({
    missingAuditingItems: (_, __) => []
  }),
  clearMismatchedItem: assign({
    mismatchItems: (_, __) => []
  }),
  clearCurrentProduct: assign({
    currentProduct: (_, __) => null
  }),
  assignItemToReportMismatch: assign({
    itemToReportMismatch: (ctx, event) =>
      event.data.sku ? event.data : ctx.currentProduct
  }),
  assignPreexistingContainer: assign({
    interiorExistingLpn: (_, event) => event.data.container.lpn
  })
};

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { NoveltyUnloadingContext } from './machine';

type NoveltyUnloadingServices = MachineOptions<
  NoveltyUnloadingContext,
  any
>['services'];

export const noveltyUnloadingMachineServices: NoveltyUnloadingServices = {
  fetchStagingLocation: async (ctx, _event) => {
    const { data } = await api.get(
      `/novelty-unloading-process/fetch-staging-location/${ctx.noveltyUnloadingProcessId}`
    );
    return {
      stagingLocation: data.stagingLocation
    };
  },

  fetchNoveltySKUCount: async (ctx, _event) => {
    const { data } = await api.get(
      `novelty-unloading-process/${ctx.noveltyUnloadingProcessId}/get-novelty-items-count`
    );

    return {
      totalProductsToUnload: data.totalProductsToUnload,
      productsUnloaded:      data.productsUnloaded
    };
  },

  checkIfSKUInNovelty: async (ctx, _event) => {
    const { data } = await api.get(
      `/product/sku/${ctx.productScanned!.sku}/is-in-novelty`,
      {
        params: {
          noveltyUnloadingProcessId: ctx.noveltyUnloadingProcessId.toString()
        }
      }
    );
    return data;
  },

  fetchNoveltyContainer: async (ctx, _event) => {
    const { data } = await api.get(
      '/novelty-unloading-container-v2/get-target-container-by-product',
      {
        params: {
          productId:            ctx.productScanned!.id.toString(),
          noveltyContainerType: ctx.noveltyProductType
        }
      }
    );
    return {
      noveltyContainer: data.targetNoveltyContainer,
      targetLocation:   data.targetLocation
    };
  }
};

import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWavePendingStatus extends StagingAuditingWaveStatus {
  public displayName = 'Pendiente';

  public internalName = StagingAuditingWaveStatuses.Pending;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

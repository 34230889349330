import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  Owns,
  PrimaryKey
} from '@skyframe/core';
import { Receipt } from './Receipt';
import { ReceiptRejectionReportItem } from './ReceiptRejectionReportItem';
import { User } from './User';

@Entity()
export class ReceiptRejectionReport {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field()
  comment: string;

  @ForeignKey(() => User, { name: 'reporter_user_id' })
  reporterUserId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Owns(() => ReceiptRejectionReportItem, 'receiptRejectionReportId')
  receiptRejectionReportItems: ReceiptRejectionReportItem[];

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Needs(() => User, 'reporterUserId')
  reporterUser: User;
}

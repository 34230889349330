import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { CloseNoveltyContainerContext } from './machine';

type CloseNoveltyContainerActions = MachineOptions<
  CloseNoveltyContainerContext,
  any
>['actions'];

export const closeNoveltyContainerActions: CloseNoveltyContainerActions = {
  assignContainer: assign({
    containerScanned: (_ctx, evt) => evt.data
  }),
  assignNoveltyUnloadingContainer: assign({
    noveltyUnloadingContainer: (_ctx, evt) => evt.data.noveltyUnloadingContainer
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return (
        getErrorView(event.data ?? event.data.data) ||
        'Ha ocurrido un error al buscar el detalle de la Etiqueta'
      );
    }
  }),
  clearError: assign({
    error: (_, __) => null
  })
};

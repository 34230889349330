import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWaveQuantityMismatchedStatus extends StagingAuditingWaveStatus {
  public displayName = 'Descuadre';

  public internalName = StagingAuditingWaveStatuses.QuantityMismatched;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

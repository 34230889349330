import { DispatchingItemStatuses } from '../DispatchingItem';
import { DispatchingItemStatus } from './DispatchingItemStatus';

export class DispatchingItemCancelledStatus extends DispatchingItemStatus {
  public displayName = 'Cancelado';

  public internalName = DispatchingItemStatuses.Cancelled;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

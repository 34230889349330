import { PendingDispatchingPickingProcessStatuses } from '../PendingDispatchingPickingProcess';
import { PendingDispatchingPickingProcessStatus } from './PendingDispatchingPickingProcessStatus';

export class PendingDispatchingPickingProcessCancelledStatus extends PendingDispatchingPickingProcessStatus {
  public displayName = 'Cancelado';

  public internalName = PendingDispatchingPickingProcessStatuses.Cancelled;

  public statusColor = '#e6f4ff';

  public textColor = '#b90c0c';
}

import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusAnnulledStatus extends ReservationItemStatus {
  public displayName = 'Anulado';

  public internalName = ReservationItemStatuses.Annulled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  PrimaryKey
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Category } from './Category';
import { Category2RejectionReason } from './Category2RejectionReason';
import { CategoryOpportunisticCount } from './CategoryOpportunisticCount';
import { Product } from './Product';

export const Category2Types = {
  Heading: 0,
  Family:  1
};

export type Category2TypeTypes =
  (typeof Category2Types)[keyof typeof Category2Types];

@Entity()
export class Category2 {
  @ApiField()
  @PrimaryKey()
  id: number;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'name' })
  name: string;

  @Field({ name: 'allow_all' })
  allowAll: boolean;

  @Field({ name: 'type' })
  type: Category2TypeTypes;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'product_match' })
  productMatch: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_category2_code' })
  sapCategory2Code: string;

  @ForeignKey(() => Category, { name: 'parent_cat_id' })
  parentCatId: number;

  @Knows(() => Category, 'parentCatId')
  parent: Category;

  @Knows(() => Product, 'category2Id')
  products: Product[];

  @Knows(() => Category2RejectionReason, 'category2Id')
  category2RejectionReasons: Category2RejectionReason[];

  @Knows(() => CategoryOpportunisticCount, 'category2Id')
  categoryOpportunisticCounts: CategoryOpportunisticCount[];
}

import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchDeletedStatus extends OrderBatchStatus {
  public displayName = 'Eliminada';

  public internalName = OrderBatchStatuses.Deleted;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

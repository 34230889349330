import { TextInputProps } from '@wms/client-core';
import React, {
  ChangeEventHandler,
  FC,
  FormEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { Box } from './Box';
import { Button } from './Button';

const buttonStyle = { margin: 1, marginLeft: 4 };

export const TextInput: FC<TextInputProps> = ({
  focus,
  mask,
  value,
  onChange,
  onSubmit,
  placeholder,
  type
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => onChange(event.target.value),
    [onChange]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLInputElement> | void) => {
      if (onSubmit) {
        if (event) {
          onSubmit(event.currentTarget.value);
        } else if (inputRef.current) {
          onSubmit(inputRef.current.value);
        }
      }
    },
    [onSubmit]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );
  const handleClick = useCallback(() => handleSubmit(), [handleSubmit]);
  useEffect(() => {
    inputRef?.current?.focus();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [focus]);
  function handleClickOutside(event) {
    // @Description : If the user touch the body we need to autofocus the input
    if (
      event.target.nodeName === 'DIV' ||
      event.target.nodeName === 'BUTTON' ||
      event.target.nodeName === 'HTML'
    ) {
      event.preventDefault();
      inputRef?.current?.focus();
    }
  }
  return (
    <Box width='100%'>
      <input
        autoFocus
        id='INPUT-TEXT'
        ref={inputRef}
        style={{ margin: '1px' }}
        autoCapitalize='none'
        type={type ? type : mask === '*' ? 'password' : 'text'}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit}
        placeholder={placeholder}
      />
      {onSubmit ? (
        <Button
          id='BUTTON-OK'
          style={buttonStyle}
          label='OK'
          onClick={handleClick}
        />
      ) : null}
    </Box>
  );
};

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderItem } from './OrderItem';
import { Product } from './Product';
import { Reservation } from './Reservation';
import { ReservationItemLabel } from './ReservationItemLabel';
import { ReservationPendingItem } from './ReservationPendingItem';
import { SellerProduct } from './SellerProduct';
import {
  ReservationItemStatus,
  ReservationItemStatusAnnulledStatus,
  ReservationItemStatusCancelledStatus,
  ReservationItemStatusDeliveredStatus,
  ReservationItemStatusDispatchedStatus,
  ReservationItemStatusNotDispatchedStatus,
  ReservationItemStatusPartiallyDispatchedStatus,
  ReservationItemStatusProcessingStatus,
  ReservationItemStatusRejectedStatus
} from './reservation-item-status';
import { ReservationItemStatusPendingStatus } from './reservation-item-status/ReservationItemStatusPendingStatus';
import { ReservationIdentifier } from './reservationIdentifier';

export const ReservationItemStatuses = {
  Pending:             'pending',
  Processing:          'processing',
  Dispatched:          'dispatched',
  PartiallyDispatched: 'partially-dispatched',
  NotDispatched:       'not-dispatched',
  Delivered:           'delivered',
  Rejected:            'rejected',
  Cancelled:           'cancelled',
  Annulled:            'annulled'
};

export const ValidStatusesToCreateOrderItems = [
  ReservationItemStatuses.Pending,
  ReservationItemStatuses.PartiallyDispatched
] as const;

@Entity()
export class ReservationItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => Reservation, { name: 'reservation_id' })
  reservationId: number;

  @Status({
    [ReservationItemStatuses.Pending]:             ReservationItemStatusPendingStatus,
    [ReservationItemStatuses.Processing]:          ReservationItemStatusProcessingStatus,
    [ReservationItemStatuses.Dispatched]:          ReservationItemStatusDispatchedStatus,
    [ReservationItemStatuses.Delivered]:           ReservationItemStatusDeliveredStatus,
    [ReservationItemStatuses.Rejected]:            ReservationItemStatusRejectedStatus,
    [ReservationItemStatuses.Cancelled]:           ReservationItemStatusCancelledStatus,
    [ReservationItemStatuses.PartiallyDispatched]:
      ReservationItemStatusPartiallyDispatchedStatus,
    [ReservationItemStatuses.NotDispatched]:
      ReservationItemStatusNotDispatchedStatus,
    [ReservationItemStatuses.Annulled]: ReservationItemStatusAnnulledStatus
  })
  status: ReservationItemStatus;

  @ForeignKey(() => SellerProduct, { name: 'seller_product_id' })
  sellerProductId: number;

  @ForeignKey(() => ReservationIdentifier, {
    name: 'reservation_identifier_id'
  })
  reservationIdentifierId: number;

  @Knows(() => Product, 'productId')
  product: Product;

  @Knows(() => SellerProduct, 'sellerProductId')
  sellerProduct: SellerProduct;

  @Knows(() => ReservationIdentifier, 'reservationIdentifierId')
  reservationIdentifier: ReservationIdentifier;

  @Needs(() => Reservation, 'reservationId')
  reservation: Reservation;

  @NeededBy(() => OrderItem, 'reservationItemId')
  orderItem: OrderItem;

  @NeededBy(() => ReservationItemLabel, 'reservationItemId')
  reservationItemLabel: ReservationItemLabel;

  @NeededBy(() => ReservationPendingItem, 'reservationItemId')
  reservationPendingItems: ReservationPendingItem[];

  @Field()
  quantity: number;

  @Field({ name: 'line_number' })
  lineNumber: number;

  @Field({ name: 'delivered_quantity' })
  deliveredQuantity: number;

  @Field({ name: 'active' })
  active: boolean;

  @Field({ name: 'label_initial_value' })
  labelInitialValue: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

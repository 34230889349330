import { StockTableStatuses } from '../StockTable';
import { StockItemStatus } from './StockItemStatus';

export class StockItemStatusDetainedStatus extends StockItemStatus {
  public displayName = 'Retenido';

  public internalName = StockTableStatuses.Detained;

  public statusColor = '#ffd3d3';
}

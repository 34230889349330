import { MachineOptions, assign } from 'xstate';
import { UnloadingTaskContext } from './machine';

type UnloadingTaskActions = MachineOptions<
  UnloadingTaskContext,
  any
>['actions'];

export const unloadingTaskActions: UnloadingTaskActions = {
  assignLocationId: assign({
    locationId: (_ctx, event) => event.data.id
  }),

  assignContainersToCreate: assign({
    pendingContainersToCreate: (_ctx, event) =>
      event.data.pendingContainersToCreate.length,
    currentContainer:        (_ctx, event) => event.data.pendingContainersToCreate[0],
    totalContainersToCreate: (_ctx, event) => event.data.totalContainersToCreate
  }),

  assignContainerLpn: assign({
    containerLpn: (_ctx, event) => event.data.lpn
  }),

  assignError: assign({
    error: (_ctx, event) => event.data.data.response.data.errorView
  }),

  clearError: assign({
    error: (_ctx, _event) => ''
  }),

  assignUnloadingItems: assign({
    totalUnloadingItems:    (_ctx, event) => event.data.totalUnloadingItems,
    unloadedUnloadingItems: (_ctx, event) => event.data.unloadedUnloadingItems
  }),

  assignSuggestedUnloadingItemToUnload: assign({
    suggestedUnloadingItemCode: (_ctx, event) =>
      event.data.suggestedUnloadingItemCode
  }),

  assignSellerProductToUnload: assign({
    sellerProductToUnload: (_ctx, event) => event.data
  }),

  assignSuggestedContainerToUnload: assign({
    suggestedContainerToUnload: (_ctx, event) =>
      event.data.suggestedContainerToUnload
  }),

  assignContainerToUnload: assign({
    containerToUnload: (_ctx, event) => event.data
  })
};

import { NoveltyUnloadingContainerStatuses } from '../NoveltyUnloadingContainer';
import { NoveltyUnloadingContainerStatus } from './NoveltyUnloadingContainerStatus';

export class NoveltyUnloadingContainerStatusProcessingStatus extends NoveltyUnloadingContainerStatus {
  public displayName = 'Procesando';

  public internalName = NoveltyUnloadingContainerStatuses.Processing;

  public statusColor = '#Ffffff';
}

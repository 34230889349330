import { MovingToPreSorterProcessStatuses } from '../MovingToPreSorterProcess';
import { MovingToPreSorterProcessStatus } from './MovingToPreSorterProcessStatus';

export class MovingToPreSorterProcessStatusDraftStatus extends MovingToPreSorterProcessStatus {
  public displayName = 'Borrador';

  public internalName = MovingToPreSorterProcessStatuses.Draft;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

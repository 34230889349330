import Ink from '@ink';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:                 'unloading',
    displayName:         'DESCARGAR MERCADERIA',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'unloading' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'moving-to-pre-sorter',
    displayName:         'TRASLADAR A ZONA DE INDUCCIÓN',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'moving-to-pre-sorter' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'feeding-to-sorter',
    displayName:         'INDUCIR CONTENEDOR',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'feeding-to-sorter' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'no-sortable-slotting',
    displayName:         'ALMACENAR CONTENEDORES NO SORTEABLES',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'no-sortable-slotting' },
    checkTaskTypeAccess: true
  },

  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const CrossdockingMenu: FC<MenuProps> = ({ send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) send(item.event, item.payload);
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

CrossdockingMenu.displayName = 'Menu';

import { MachineOptions } from 'xstate';
import { MovePendingDispatchingItemsContext } from './machine';

type MovePendingDispatchingItemsGuards = MachineOptions<
  MovePendingDispatchingItemsContext,
  any
>['guards'];

export const movePendingDispatchingItemsMachineGuards: MovePendingDispatchingItemsGuards =
  {
    hasPendingDispatchingItemsToMove: (_ctx, evt) => {
      return evt.data.pendingPendingDispatchingItemsToMove > 0;
    }
  };

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { SlottingContainer } from './SlottingContainer';
import {
  SlottingItemStatus,
  SlottingItemStatusCancelledStatus,
  SlottingItemStatusPendingStatus,
  SlottingItemStatusSlottedStatus
} from './slotting-item-status';

export const SlottingItemStatuses = {
  Pending:   'pending',
  Slotted:   'slotted',
  Cancelled: 'cancelled'
};

@Entity()
export class SlottingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [SlottingItemStatuses.Pending]:   SlottingItemStatusPendingStatus,
    [SlottingItemStatuses.Slotted]:   SlottingItemStatusSlottedStatus,
    [SlottingItemStatuses.Cancelled]: SlottingItemStatusCancelledStatus
  })
  status: SlottingItemStatus;

  @Field({ name: 'slotted_quantity' })
  slottedQuantity: number;

  @ForeignKey(() => SlottingContainer, {
    name: 'slotting_container_id'
  })
  slottingContainerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => SlottingContainer, 'slottingContainerId')
  slottingContainer: SlottingContainer;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;
}

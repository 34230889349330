import { SlottingProcessStatuses } from '../SlottingProcess';
import { SlottingProcessStatus } from './SlottingProcessStatus';

export class SlottingProcessStatusProcessingStatus extends SlottingProcessStatus {
  public displayName = 'Procesando';

  public internalName = SlottingProcessStatuses.Processing;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { ReservationPendingContainerStatuses } from '../ReservationPendingContainer';
import { ReservationPendingContainerStatus } from './ReservationPendingContainerStatus';

export class ReservationPendingContainerStatusProcessingStatus extends ReservationPendingContainerStatus {
  public displayName = 'Procesando';

  public internalName = ReservationPendingContainerStatuses.Processing;

  public statusColor = '#Ffffff';
}

import { PutToWallWaveStatuses } from '../PutToWallWave';
import { PutToWallWaveStatus } from './PutToWallWaveStatus';

export class PutToWallWaveStatusCancelledStatus extends PutToWallWaveStatus {
  public displayName = 'Cancelado';

  public internalName = PutToWallWaveStatuses.Cancelled;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

import { MoveToBufferProcessStatuses } from '../MoveToBufferProcess';
import { MoveToBufferProcessStatus } from './MoveToBufferProcessStatus';

export class MoveToBufferProcessStatusPendingStatus extends MoveToBufferProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MoveToBufferProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

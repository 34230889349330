import Ink from '@ink';
import { JobTypes, Task, TaskTypes } from '@wms/domain';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:         'inventory-adjustment',
    displayName: 'AJUSTE DE INVENTARIO',
    event:       'taskSelected',
    payload:     {
      task: { type: TaskTypes.InventoryAdjustment }
    },
    checkTaskTypeAccess: true
  },
  {
    key:         'report-anomaly',
    displayName: 'MARCAR COMO ANOMALÍA',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.ReportAnomaly } as Task
    },
    checkTaskTypeAccess: true
  },
  // {
  //   key:         'restocking-utility',
  //   displayName: 'Restockear Item',
  //   event:       'taskSelected',
  //   payload:     {
  //     task: { type: TaskTypes.RestockingUtility } as Task
  //   },
  //   checkTaskTypeAccess: true
  // },
  // {
  //   key:         'manage-printer',
  //   displayName: 'ADMINISTRAR IMPRESORA',
  //   event:       'taskSelected',
  //   payload:     { task: { type: TaskTypes.ManagePrinter } }
  // },
  // {
  //   key:         'test-printer',
  //   displayName: 'PROBAR IMPRESORA',
  //   event:       'taskSelected',
  //   payload:     { task: { type: TaskTypes.TestPrinter } }
  // },
  {
    key:         'label-detail',
    displayName: 'CONSULTA DE BULTO',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.LabelDetail } as Task
    }
  },
  {
    key:         'product-detail',
    displayName: 'CONSULTA DE PRODUCTO',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.ProductDetail } as Task
    }
  },
  {
    key:         'container-detail',
    displayName: 'CONSULTA DE CONTENEDOR',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.ContainerDetail } as Task
    }
  },
  {
    key:         'location-detail',
    displayName: 'CONSULTA DE UBICACIÓN',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.LocationDetail } as Task
    }
  },
  {
    key:         'move-bulk',
    displayName: 'TRASLADO DE BULTO',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.MoveBulk } as Task
    },
    checkTaskTypeAccess: true
  },
  {
    key:         'close-transport-order',
    displayName: 'CIERRE DE ORDEN DE TRANSPORTE',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.CloseTransportOrder } as Task
    },
    checkTaskTypeAccess: true
  },
  {
    key:         'print-ptw-labels',
    displayName: 'IMPRESIÓN DE ETIQUETAS PUT TO WALL',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.PrintPutToWallLabels } as Task
    },
    checkTaskTypeAccess: true
  },

  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const InventoryManagementMenu: FC<MenuProps> = ({ send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(({ key }) => key === id);

    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

InventoryManagementMenu.displayName = 'Menu';

import { NoSortableSlottingContainerStatuses } from '../NoSortableSlottingContainer';
import { NoSortableSlottingContainerStatus } from './NoSortableSlottingContainerStatus';

export class NoSortableSlottingContainerSlottedStatus extends NoSortableSlottingContainerStatus {
  public displayName = 'Almacenado';

  public internalName = NoSortableSlottingContainerStatuses.Slotted;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import {
  CustomFetchResultError,
  fetchResult
} from '../../../../../../utils/fetch-result';
import { GetLocationByCheckDigitFn } from '../machine';

export function getRequestedCheckDigit(
  defaultErrorMessage: string,
  nameMismatchErrorMessage: string,
  requestedCheckDigit: string
): GetLocationByCheckDigitFn {
  return fetchResult(defaultErrorMessage, checkDigit => {
    if (checkDigit === requestedCheckDigit) {
      return api.get<Location>('location/check-digit', {
        params: { checkDigit }
      });
    } else {
      throw new CustomFetchResultError(nameMismatchErrorMessage);
    }
  });
}

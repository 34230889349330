import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { Task } from './Task';

export const taskAttributeName = {
  locations: 'locations'
} as const;

@Entity()
export class TaskAttribute {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @ForeignKey(() => Task, { name: 'task_id' })
  taskId: number;

  @Field()
  value: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Task, 'taskId')
  task: Task;
}

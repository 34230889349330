import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  PrimaryKey
} from '@skyframe/core';

import { Category } from './Category';
import { Category2 } from './Category2';
import { Warehouse } from './Warehouse';

@Entity()
export class CategoryOpportunisticCount {
  @PrimaryKey()
  id: number;

  @Field({ name: 'disabled' })
  disabled: boolean;

  @IsNotEmpty({
    message:
      'Debe ingresar una cantidad mínima para realizar el conteo oportunístico'
  })
  @Field({ name: 'min_quantity' })
  minQuantity: number;

  @ForeignKey(() => Category, { name: 'category_id' })
  categoryId: number;

  @ForeignKey(() => Category2, { name: 'category_2_id' })
  category2Id: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Category, 'categoryId')
  category: Category;

  @Knows(() => Category2, 'category2Id')
  category2: Category2;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;
}

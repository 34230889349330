import { api } from '../../../../api/utils/axios-instance';

export const containerMergeServices = {
  fetchStagingAuditingContainers: async context => {
    const { data } = await api.get(
      'staging-auditing-container/get-staging-auditing-container-by-container-id',
      {
        params: {
          originContainerId: context.originContainer.id,
          targetContainerId: context.targetContainer.id,
          stagingId:         context.staging.id
        }
      }
    );
    return {
      originStagingAuditingContainer: data.originStagingAuditingContainer,
      targetStagingAuditingContainer: data.targetStagingAuditingContainer,
      orderType:                      data.orderType
    };
  },
  fetchStagingAuditingItem: async context => {
    const { data } = await api.get(
      'staging-auditing-container/get-staging-auditing-item-by-label',
      {
        params: {
          originStagingAuditingContainerId:
            context.originStagingAuditingContainer.id,
          stagingAuditingItemLabel: context.itemToMoveLabel
        }
      }
    );
    return {
      stagingAuditingItem: data.stagingAuditingItem
    };
  },
  moveStagingAuditingItemToTargetStagingAuditingContainer: async context => {
    const { data } = await api.get(
      'staging-auditing-container/move-staging-auditing-item',
      {
        params: {
          targetStagingAuditingContainerId:
            context.targetStagingAuditingContainer.id,
          originStagingAuditingContainerId:
            context.originStagingAuditingContainer.id,
          stagingAuditingItemId: context.stagingAuditingItemToMove.id,
          splittedQuantity:      context.quantityToMove || 1
        }
      }
    );
    return {
      hasPendingItemsToMove: data
    };
  },
  fetchStagingLocations: async _context => {
    const { data } = await api.get('location/get-staging-locations', {});
    return {
      stagingLocations: data
    };
  }
};

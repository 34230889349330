import { MachineOptions } from 'xstate';
import { FeedingToSorterTaskContext } from './machine';

type FeedingToSorterTaskGuards = MachineOptions<
  FeedingToSorterTaskContext,
  any
>['guards'];

export const feedingToSorterTaskGuards: FeedingToSorterTaskGuards = {
  hasPendingFeedingToSorterItemsToProcess: (_ctx, event) => {
    return (
      event.data.processedFeedingToSorterItems <
      event.data.totalFeedingToSorterItems
    );
  }
};

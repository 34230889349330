import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { FeedingToSorterContainer } from './FeedingToSorterContainer';
import { InventoryItem } from './InventoryItem';
import { ReceiptItem } from './ReceiptItem';
import {
  FeedingToSorterItemStatus,
  FeedingToSorterItemStatusCancelledStatus,
  FeedingToSorterItemStatusFedStatus,
  FeedingToSorterItemStatusPendingStatus
} from './feeding-to-sorter-item-status';

export const FeedingToSorterItemStatuses = {
  Pending:   'pending',
  Fed:       'fed',
  Cancelled: 'cancelled'
};

@Entity()
export class FeedingToSorterItem {
  @PrimaryKey()
  id: number;

  @Status({
    [FeedingToSorterItemStatuses.Pending]:
      FeedingToSorterItemStatusPendingStatus,
    [FeedingToSorterItemStatuses.Fed]:       FeedingToSorterItemStatusFedStatus,
    [FeedingToSorterItemStatuses.Cancelled]:
      FeedingToSorterItemStatusCancelledStatus
  })
  status: FeedingToSorterItemStatus;

  @ForeignKey(() => FeedingToSorterContainer, {
    name: 'feeding_to_sorter_container_id'
  })
  feedingToSorterContainerId: number;

  @ForeignKey(() => ReceiptItem, {
    name: 'receipt_item_id'
  })
  receiptItemId: number;

  @ForeignKey(() => InventoryItem, {
    name: 'inventory_item_id'
  })
  inventoryItemId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => FeedingToSorterContainer, 'feedingToSorterContainerId')
  feedingToSorterContainer: FeedingToSorterContainer;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;
}

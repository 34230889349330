import {
  Field,
  ForeignKey,
  IsDefined,
  IsNotEmpty,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Appointment } from './Appointment';
import { CategoryLocation } from './CategoryLocation';
import { Container } from './Container';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { LocationType } from './LocationType';
import { Route } from './Route';
import { Warehouse } from './Warehouse';
import { ZoneClassifier } from './ZoneClassifier';
import {
  LocationStatusAvailableStatus,
  LocationStatusBlockedStatus,
  LocationStatusDamagedStatus,
  LocationStatusUnavailableStatus
} from './location-status';

export enum LocationStatuses {
  Available = 'available',
  Unavailable = 'unavailable',
  Damaged = 'damaged',
  Blocked = 'blocked'
}

export const LocationPlacementTypes = {
  CrossDockPending: 'cross-dock-pending'
};
export type TypeLocationPlacementTypes =
  (typeof LocationPlacementTypes)[keyof typeof LocationPlacementTypes];

export abstract class BaseLocation {
  @PrimaryKey()
  id: number;

  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field()
  name: string;

  /* location only */
  @Field({ name: 'placement_allowed' })
  placementAllowed: TypeLocationPlacementTypes;

  /* Staging Only */
  @Field({ name: 'check_digit' })
  checkDigit: string;

  @Field({ name: 'side' })
  side: number;

  @Field({ name: 'column' })
  column: number;

  @Field({ name: 'level' })
  level: number;

  @Field({ name: 'position' })
  position: number;

  @Field({ name: 'free_spaces_before' })
  freeSpacesBefore: number;

  @Field({ name: 'width' })
  width: number;

  @Field({ name: 'height' })
  height: number;

  @ForeignKey(() => ZoneClassifier, { name: 'zone_classifier_id' })
  zoneClassifierId: number;

  @ForeignKey(() => LocationType, { name: 'location_type_id' })
  locationTypeId: number;

  @IsDefined({
    message: 'Debe seleccionar un almacén'
  })
  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => Location, { name: 'parent_id' })
  parentId: number;

  @ForeignKey(() => Route, { name: 'route_id' })
  routeId: number;

  abstract parent: Location;

  abstract children: Location[];

  abstract containers: Container[];

  abstract zoneClassifier: ZoneClassifier;

  abstract locationType: LocationType;

  abstract warehouse: Warehouse;

  abstract route: Route;

  abstract inventoryItems: InventoryItem[];

  abstract rubric: CategoryLocation;

  abstract families: CategoryLocation[];

  /* Dock and Staging only */
  @Status({
    [LocationStatuses.Available]:   LocationStatusAvailableStatus,
    [LocationStatuses.Unavailable]: LocationStatusUnavailableStatus,
    [LocationStatuses.Blocked]:     LocationStatusBlockedStatus,
    [LocationStatuses.Damaged]:     LocationStatusDamagedStatus
  })
  status: any;

  /* Dock location only */
  abstract appointment: Appointment[];

  /* Section only */
  abstract crossDockWarehouse: Warehouse;
}

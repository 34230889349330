import { MachineOptions, assign } from 'xstate';
import { GetContainerByLpnContext } from './machine';

type GetContainerByLpnActions = MachineOptions<
  GetContainerByLpnContext,
  any
>['actions'];

export const getContainerByLpnActions: GetContainerByLpnActions = {
  updateContainerLpn: assign({
    containerLpn: (_context, event) => event.containerLpn
  }),
  assignContainer: assign({
    container: (_context, event) => event.data.data
  }),
  assignError: assign({
    error: (_context, event) => event.data.error
  }),
  invalidContainerError: assign({
    error: (_context, _event) => 'La etiqueta escaneada es inválida'
  }),
  clearError: assign({
    error: (_context, _event) => null
  }),
  clearContainerLpn: assign({
    containerLpn: (_context, _event) => ''
  })
};

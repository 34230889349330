import Ink from '@ink';
import { AuditingItem } from '@wms/domain';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { Print } from '../../layer-4/printer/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { MiniMopAuditingWaveMachine } from './machine';

export const MiniMopAuditingWaveTask = withActorRef(MiniMopAuditingWaveMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const canCompleteAuditing = () => {
      return (
        current.context.mismatchItems?.length === 0 &&
        current.context.missingAuditingItems?.length === 0 &&
        current.context.auditingItems?.every(
          ai => ai.auditedQuantity === ai.expectedQuantity
        )
      );
    };

    const getStatusEmoji = (auditingItem: AuditingItem) => {
      if (auditingItem.auditedQuantity == auditingItem.expectedQuantity) {
        return '✅';
      }
      return '❌';
    };

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          Ola de Auditoría Nº{current.context.auditingWaveId} - Mini-MOP
        </Ink.Header>
        {/* Header */}
        <Ink.Box flexDirection='column'>
          <>
            {/* Confirming City Zone (Amba) */}
            {current.matches('ConfirmingCityZone') && (
              <Ink.Box flexDirection='column'>
                <Ink.Text>El destino de contenedor es de AMBA</Ink.Text>
                <IntercalatedText
                  texts={['Se debe auditar en']}
                  values={['Cubeta plástica']}
                />
                <Ink.Text>
                  Vacíe la cubeta plástica sobre la mesa para iniciar proceso de
                  auditoría{' '}
                </Ink.Text>
                <Ink.Text />
                <Ink.Button
                  label='Ya vacié la cubeta'
                  onClick={() => {
                    send('confirmCity');
                  }}
                />
              </Ink.Box>
            )}

            {/* CreatingInteriorContainer */}
            {current.matches('CreatingInteriorContainer') && (
              <Ink.Text bold color='greenBright'>
                Creando caja de carton
              </Ink.Text>
            )}

            {current.matches(
              'CreatingInteriorContainer.ScanningInteriorLPN'
            ) && (
              <Ink.Box flexDirection='column'>
                <ScanLabel />
              </Ink.Box>
            )}

            {current.matches('CreatingInteriorContainer.StickingLabel') && (
              <Ink.Text>Pega la etiqueta en el contenedor de despacho</Ink.Text>
            )}
          </>

          {/* AuditingItem */}
          <>
            {current.matches('AuditingItem') && (
              <>
                <Ink.Text bold color='greenBright'>
                  Auditando items
                </Ink.Text>

                <Ink.Box padding={2} flexDirection='column'>
                  <Ink.Box>
                    <Ink.Box width='20%'>
                      <Ink.Text bold>SKU</Ink.Text>
                    </Ink.Box>
                    <Ink.Box width='45%'>
                      <Ink.Text bold>Descripcion</Ink.Text>
                    </Ink.Box>
                    <Ink.Box width='15%'>
                      <Ink.Text bold>Cant.</Ink.Text>
                    </Ink.Box>
                    <Ink.Box width='20%'>
                      <Ink.Text bold>Estado</Ink.Text>
                    </Ink.Box>
                  </Ink.Box>
                  <Ink.Newline />
                  {current.context.auditingItems?.map((auditingItem, index) => (
                    <>
                      <Ink.Box key={index}>
                        <Ink.Box width='20%'>
                          <Ink.Text bold color='highlightColor'>
                            {auditingItem.product.sku}
                          </Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='45%'>
                          <Ink.Text>{auditingItem.product.name}</Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='15%'>
                          <Ink.Text>
                            {auditingItem.auditedQuantity} /{' '}
                            {auditingItem.expectedQuantity}
                          </Ink.Text>
                        </Ink.Box>
                        {/* <Ink.Box width='20%'><Ink.Text>{auditingItem.status === AuditingItemStatuses.Auditing ? 'PENDIENTE' : 'AUDITADO'}</Ink.Text></Ink.Box> */}
                        <Ink.Box width='20%'>
                          <Ink.Text
                            color={
                              auditingItem.auditedQuantity >=
                              auditingItem.expectedQuantity
                                ? 'greenBright'
                                : ''
                            }
                          >
                            {getStatusEmoji(auditingItem)}
                          </Ink.Text>
                        </Ink.Box>
                      </Ink.Box>
                    </>
                  ))}
                </Ink.Box>

                <Ink.Text />

                {/* Mismatched items */}
                {current.context.mismatchItems?.length > 0 ? (
                  <>
                    <Ink.Text bold>Items fuera de Orden</Ink.Text>
                    <Ink.Box padding={2} flexDirection='column'>
                      <Ink.Box>
                        <Ink.Box width='10%'>
                          <Ink.Text>Item</Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='20%'>
                          <Ink.Text>SKU</Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='30%'>
                          <Ink.Text>Descripcion</Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='10%'>
                          <Ink.Text>Cant.</Ink.Text>
                        </Ink.Box>
                        <Ink.Box width='30%'>
                          <Ink.Text>Estado</Ink.Text>
                        </Ink.Box>
                      </Ink.Box>
                      <Ink.Newline />
                      {current.context.mismatchItems?.map(
                        (auditingItem, index) => (
                          <>
                            <Ink.Box key={index}>
                              <Ink.Box width='10%'>
                                <Ink.Text>{index + 1}</Ink.Text>
                              </Ink.Box>
                              <Ink.Box width='20%'>
                                <Ink.Text>{auditingItem.sku}</Ink.Text>
                              </Ink.Box>
                              <Ink.Box width='30%'>
                                <Ink.Text>{auditingItem.name}</Ink.Text>
                              </Ink.Box>
                              <Ink.Box width='10%'>
                                <Ink.Text>
                                  {auditingItem.auditedQuantity}
                                </Ink.Text>
                              </Ink.Box>
                              <Ink.Box width='30%'>
                                <Ink.Text color='redBright'>
                                  A DEVOLVER
                                </Ink.Text>
                              </Ink.Box>
                            </Ink.Box>
                          </>
                        )
                      )}
                    </Ink.Box>
                  </>
                ) : null}
              </>
            )}

            {current.matches('AuditingItem.ScanningContainer') && (
              <>
                <Ink.Text> </Ink.Text>
                <Ink.Text>
                  Escanear etiqueta del contenedor del destino
                </Ink.Text>
                <GetContainerByLpn id='ScanningContainer' />
              </>
            )}

            {current.matches('AuditingItem.ScanningSKU') && (
              <>
                {current.context.mismatchItems.length <= 0 ? (
                  <>
                    <GetProductBySKU />
                  </>
                ) : (
                  <Ink.Text bold>
                    DEVUELVA SOBRANTES Y REINICIE AUDITORIA
                  </Ink.Text>
                )}

                <Ink.Text bold color='redBright'>
                  {current.context.error}
                </Ink.Text>

                {/* Esto se tiene que mostrar solo si se auditaron TODOS los items correctamente, ni mas items ni menos */}
                {canCompleteAuditing() ? (
                  <Ink.Button
                    label='Completar auditoría'
                    onClick={() => {
                      send('close');
                    }}
                    id='complete-auditing'
                    key='complete-auditing'
                  />
                ) : null}

                {/* {!current.context.missingItemsConfirm &&*/}
                {/* !canCompleteAuditing() ? (*/}
                {/*  <Ink.Button*/}
                {/*    label='Reportar faltante'*/}
                {/*    onClick={() => {*/}
                {/*      send('reportMissing');*/}
                {/*    }}*/}
                {/*    id='report-missing-items'*/}
                {/*    key='report-missing-items'*/}
                {/*  />*/}
                {/* ) : null}*/}

                <Ink.Button
                  label='Reiniciar Audítoria'
                  onClick={() => {
                    send('resetAuditingItems');
                  }}
                  id='refresh-audited-items'
                  key='refresh-audited-items'
                />
              </>
            )}

            {current.matches('AuditingItem.ConfirmingDifferenceScreen') && (
              <>
                <Ink.Text bold>
                  Debe devolver items fuera de orden a picking
                </Ink.Text>
                <Ink.Text>Se generó una incidencia.</Ink.Text>
                <Ink.Text>
                  Utilice el boton actualizar para renovar situacion de la
                  auditoria
                </Ink.Text>
                <Ink.Button
                  onClick={() => {
                    send('confirm');
                  }}
                  label='Ok'
                />
              </>
            )}
          </>
          {current.matches('AuditingItem.ConfirmingReportMissingScreen') && (
            <>
              <Ink.Text bold>
                Se generó una incidencia por faltante de items
              </Ink.Text>
              <Ink.Text>
                Utilice el boton actualizar para renovar situacion de la
                auditoria
              </Ink.Text>
              <Ink.Button
                onClick={() => {
                  send('CONFIRM');
                }}
                label='Confirmar'
              />
            </>
          )}

          {current.matches('ConfirmingContainerClose') && (
            <>
              <Ink.Text bold>Cierre de contenedor</Ink.Text>
              <Ink.Text>Haz terminado la auditoría!</Ink.Text>
              <Ink.Text> </Ink.Text>
              <GetContainerByLpn id='ConfirmingContainerClose' />
            </>
          )}
        </Ink.Box>

        {/* Body */}
        <Ink.Box flexDirection='column'>
          {current.hasTag('loading') && <Ink.Text>Cargando... </Ink.Text>}

          {current.hasTag('lpn') && <EnterLpn />}

          {current.matches('CreatingInteriorContainer.StickingLabel') && (
            <>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  onClick={() => {
                    send('confirm');
                  }}
                  label='Etiqueta pegada'
                  id='confirm-stick-label'
                  key='confirm-stick-label'
                />
              </Ink.Box>
            </>
          )}

          {current.matches('ConfirmingContainerClose') && (
            <>
              <Ink.Box flexDirection='row' justifyContent='space-between'>
                <Ink.Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    send('cancel');
                  }}
                  label='Revalidar cierre'
                />
              </Ink.Box>
            </>
          )}

          {current.context.error && (
            <ErrorMessage error={current.context.error!} bold />
          )}

          {current.hasTag('printing') ? <Print /> : null}
        </Ink.Box>
      </Ink.Box>
    );
  }
);

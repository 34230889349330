import {
  Entity,
  Field,
  ForeignKey,
  IsDefined,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Location } from './Location';
import { Order } from './Order';
import { Receipt } from './Receipt';
import { ReceiptBatch } from './ReceiptBatch';
import { StockTransfer } from './StockTransfer';
import { TaskBucket } from './TaskBucket';
import { Vehicle } from './Vehicle';
import { Warehouse } from './Warehouse';

export enum AssociatedOrders {
  OrdenDeEntrada = 'Orden de Entrada',
  OrdenDeSalida = 'Orden de Salida'
}
export enum documentTypes {
  OC = 'Orden de Compra',
  TRI = 'Transferencia IN',
  REENTRY = 'Reingreso'
}
export interface ShippingNotes {
  documentNumber: string;
  documentType: documentTypes;
  externalReferenceIds: string[];
  purchaseOrderId: number;
  provider?: string;
}

@Entity()
export class Appointment {
  @PrimaryKey()
  id: number;

  @Field({ name: 'expected_date' })
  expectedDate: Date;

  @ForeignKey(() => Vehicle, { name: 'vehicle_id' })
  vehicleId: number;

  @ForeignKey(() => Location, { name: 'staging_id' })
  stagingId: number;

  @Field({ name: 'duration' })
  duration: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Receipt, 'appointmentId')
  receipt: Receipt;

  @Knows(() => Vehicle, 'vehicleId')
  vehicle: Vehicle;

  @Knows(() => Location, 'stagingId')
  staging: Location;

  @Knows(() => Order, 'appointmentId')
  order: Order;

  @Knows(() => TaskBucket, 'appointmentId')
  taskbuckets: TaskBucket[];

  @IsDefined({ message: 'El campo es requerido.' })
  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  shippingNotes: ShippingNotes;

  @Knows(() => StockTransfer, 'appointmentId')
  stockTransfer: StockTransfer;

  @NeededBy(() => ReceiptBatch, 'appointmentId')
  receiptBatch: ReceiptBatch;
}

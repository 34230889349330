import { Entity, Field, NeededBy, PrimaryKey } from '@skyframe/core';
import { Receipt } from './Receipt';

export enum ReceiptTypes {
  // seeders amp
  Transferencia = 'Transferencia',
  StorePickup = 'colecta-sellers',
  Proveedor = 'Proveedor',
  // use
  Sellers = 'colecta-sellers',
  WMS = 'wms',
  TransferWmsFravega = 'transferencia-wms',
  SupplierWms = 'proveedor-wms',
  Novelty = 'novedades',
  Return = 'retiro-cambio'
}
export enum AlphaReceiptTypes {
  Retiro = '16',
  Transferencia = '12',
  Reingreso = '08',
  TransferenciaEnAnalisis = '17',
  ServicioTecnico = '11'
}

@Entity()
export class ReceiptType {
  @PrimaryKey()
  id: number;

  @Field()
  name: string;

  @Field({ name: 'display_name' })
  displayName: string;

  @Field({ name: 'default_workflow' })
  defaultWorkflow: string;

  @Field()
  enabled: boolean;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @NeededBy(() => Receipt, 'receiptTypeId')
  receipts: Receipt[];
}

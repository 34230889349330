import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Novelty } from './Novelty';
import { NoveltyUnloadingItem } from './NoveltyUnloadingItem';
import { NoveltyUnloadingProcess } from './NoveltyUnloadingProcess';
import {
  NoveltyUnloadingPackageStatus,
  NoveltyUnloadingPackageStatusCancelledStatus,
  NoveltyUnloadingPackageStatusCompletedStatus,
  NoveltyUnloadingPackageStatusPendingStatus,
  NoveltyUnloadingPackageStatusProcessingStatus
} from './novelty-unloading-package-status';

export const NoveltyUnloadingPackageStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled'
};

@Entity()
export class NoveltyUnloadingPackage {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => NoveltyUnloadingProcess, {
    name: 'novelty_unloading_process_id'
  })
  noveltyUnloadingProcessId: number;

  @ForeignKey(() => Novelty, { name: 'novelty_id' })
  noveltyId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => NoveltyUnloadingProcess, 'noveltyUnloadingProcessId')
  noveltyUnloadingProcess: NoveltyUnloadingProcess;

  @Needs(() => Novelty, 'noveltyId')
  novelty: Novelty;

  @Status({
    [NoveltyUnloadingPackageStatuses.Pending]:
      NoveltyUnloadingPackageStatusPendingStatus,
    [NoveltyUnloadingPackageStatuses.Processing]:
      NoveltyUnloadingPackageStatusProcessingStatus,
    [NoveltyUnloadingPackageStatuses.Completed]:
      NoveltyUnloadingPackageStatusCompletedStatus,
    [NoveltyUnloadingPackageStatuses.Cancelled]:
      NoveltyUnloadingPackageStatusCancelledStatus
  })
  status: NoveltyUnloadingPackageStatus;

  @NeededBy(() => NoveltyUnloadingItem, 'noveltyUnloadingPackageId')
  noveltyUnloadingItems: NoveltyUnloadingItem[];
}

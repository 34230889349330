import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusPartiallyDispatchedStatus extends OrderStatus {
  public displayName = 'Parcialmente despachado';

  public internalName = OrderStatuses.PartiallyDispatched;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MoveToBufferProcessStatuses } from '../MoveToBufferProcess';
import { MoveToBufferProcessStatus } from './MoveToBufferProcessStatus';

export class MoveToBufferProcessStatusCompletedStatus extends MoveToBufferProcessStatus {
  public displayName = 'Completado';

  public internalName = MoveToBufferProcessStatuses.Completed;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React, { FC, useCallback } from 'react';
import { HighlightedInfo } from '../../../../shared/HighlightedInfo';
import { withActorRef } from '../../../../shared/Machine';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from './machine';

export const GetLocationByCheckDigit: FC = withActorRef(
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const updateLocationCheckDigit = useCallback(
    (locationCheckDigit: string) =>
      send({ type: 'UpdateLocationCheckDigit', locationCheckDigit }),
    [send]
  );

  const submitLocationCheckDigit = useCallback(
    () => send({ type: 'SubmitLocationCheckDigit' }),
    [send]
  );

  return (
    <Ink.Box flexDirection='column' paddingY={1}>
      <Ink.Text bold>{current.context.hint}:</Ink.Text>

      {current.hasTag('loading') ? (
        <Ink.Text>Cargando</Ink.Text>
      ) : (
        <>
          {current.context.locationSuggestionCheckDigit &&
          current.context.locationSuggestionCheckDigit ? (
            <HighlightedInfo
              attribute={current.context.locationSuggestionHint || ''}
              value={current.context.locationSuggestionCheckDigit || ''}
            />
          ) : null}

          <Ink.TextInput
            value={current.context.locationCheckDigit}
            onChange={updateLocationCheckDigit}
            onSubmit={submitLocationCheckDigit}
          />

          {current.context.error ? (
            <Ink.Text bold color='redBright'>
              {current.context.error}
            </Ink.Text>
          ) : null}
        </>
      )}
    </Ink.Box>
  );
});

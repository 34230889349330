import { RestockingItemStatuses } from '../RestockingItem';
import { RestockingItemStatus } from './RestockingItemStatus';

export class RestockingItemStatusPendingStatus extends RestockingItemStatus {
  public displayName = 'Pendiente';

  public internalName = RestockingItemStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MiniMopPickingWaveContext } from './machine';

type MiniMOPPickingWaveServices = MachineOptions<
  MiniMopPickingWaveContext,
  any
>['services'];
export const miniMopPickingWaveServices: MiniMOPPickingWaveServices = {
  fetchPickingCartTypes: async (_ctx, _evt) => {
    const { data } = await api.get('location-attribute/get-picking-cart-types');
    return {
      pickingCartTypes: data
    };
  },
  fetchBranchOfficesQuantity: async (ctx, _evt) => {
    const { data } = await api.get(
      `customer-order/${ctx.pickingWaveId}/get-branch-offices`
    );
    return {
      branchOfficeQuantity: data
    };
  },
  fetchDestinyContainer: async (ctx, _evt) => {
    const { data } = await api.get(`container/lpn/${ctx.containerLpn}`);
    return {
      destinyContainer: data
    };
  },
  fetchNextBranchOfficeToPrepare: async (ctx, _evt) => {
    const { data } = await api.get(
      `customer-order/${ctx.pickingWaveId}/get-next-branch-office`
    );
    return {
      branchOffice: data?.[0],
      orderId:      data?.[1]
    };
  },
  fetchVerifyPickingCartPositionAvailable: async (ctx, _evt) => {
    const { data } = await api.get(
      `mop-picking-container/${ctx.pickingWaveId}/verify-picking-cart-position-available`,
      {
        params: {
          pickingCartPosition: ctx.pickingCartPosition
        }
      }
    );
    return {
      pickingCartPositionAvailable: data
    };
  },
  fetchNextPickItem: async (ctx, _evt) => {
    const { data } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/next-pick-item`
    );
    return {
      pickItem: data
    };
  },
  fetchMissingItems: async (ctx, _evt) => {
    const { data: missingItems } = await api.get(
      `picking-wave/${ctx.pickingWaveId}/missing-items`
    );
    return {
      missingItems
    };
  },
  fetchPickItemDestinyContainer: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem!.id}/destiny-container`
    );
    return {
      pickItemDestinyLocation: data
    };
  },
  fetchNextMopPickingContainerToClose: async (ctx, _evt) => {
    const { data } = await api.get(
      `mop-picking-container/${ctx.pickingWaveId}/get-next-mop-picking-container`
    );
    return {
      mopPickingContainer: data
    };
  },
  checkIfContainerExistByLpn: async (ctx, evt) => {
    try {
      await api.get(`/container/lpn/${evt.data.lpn}`);
      return { found: true, lpn: null };
    } catch (e) {
      if (e.request.status === 404) return { found: false, lpn: evt.data.lpn };
      else throw new Error(e);
    }
  },
  checkLocationItemStock: async (ctx, evt) => {
    try {
      const { data } = await api.get(
        `/location/itemStock/${ctx.currentPickItem}`
      );

      if (data > 0) {
        return { location: evt.data.location };
      } else {
        return { location: null };
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  markItemAsMissing: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/mark-as-missing`,
      {
        params: {
          userId: ctx.userId
        }
      }
    );
    return data;
  },
  checkAvailableOpportunisticCount: async (ctx, _evt) => {
    const { data } = await api.get(
      `pick-item/${ctx.currentPickItem?.id}/opportunistic-count`
    );
    return data;
  },
  validateOpportunisticCounting: async (ctx, _evt) => {
    await api.post(`pick-item/${ctx.currentPickItem?.id}/opportunistic-count`, {
      count:      ctx.countQuantity,
      locationId: ctx.currentPickItem!.pickingLocationId
    });
  },
  CheckingIfContainerIsUsed: (ctx, _evt) => {
    return api.get(`container/get-unused-container/${ctx.containerLpn}`);
  },
  checkIfPickItemIsCancelled: async (ctx, _evt) => {
    const { data } = await api.get(
      `/pick-item/${ctx.currentPickItem!.id}/status`
    );
    return data;
  },
  fetchDestinyContainerDetails: async (ctx, _evt) => {
    const [{ data: dataContainer }, { data: dataPickingCont }] =
      await Promise.all([
        api.get(
          `container/${ctx.pickItemDestinyContainerLocation?.containerId}`
        ),
        api.get(
          `mop-picking-container/${ctx.pickItemDestinyContainerLocation?.id}`
        )
      ]);

    return {
      container:           dataContainer,
      mopPickingContainer: dataPickingCont
    };
  }
};

import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { Receipt } from './Receipt';
import { StandardUnloadingContainer } from './StandardUnloadingContainer';
import {
  StandardUnloadingProcessStatus,
  StandardUnloadingProcessStatusCancelledStatus,
  StandardUnloadingProcessStatusCompletedStatus,
  StandardUnloadingProcessStatusPausedStatus,
  StandardUnloadingProcessStatusPendingStatus,
  StandardUnloadingProcessStatusProcessingStatus
} from './standard-unloading-process-status';

export const StandardUnloadingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class StandardUnloadingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [StandardUnloadingProcessStatuses.Pending]:
      StandardUnloadingProcessStatusPendingStatus,
    [StandardUnloadingProcessStatuses.Processing]:
      StandardUnloadingProcessStatusProcessingStatus,
    [StandardUnloadingProcessStatuses.Completed]:
      StandardUnloadingProcessStatusCompletedStatus,
    [StandardUnloadingProcessStatuses.Cancelled]:
      StandardUnloadingProcessStatusCancelledStatus,
    [StandardUnloadingProcessStatuses.Paused]:
      StandardUnloadingProcessStatusPausedStatus
  })
  status: StandardUnloadingProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;

  @NeededBy(() => StandardUnloadingContainer, 'standardUnloadingProcessId')
  standardUnloadingContainers: StandardUnloadingContainer[];
}

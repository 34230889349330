import { NoveltyUnloadingProcessStatuses } from '../NoveltyUnloadingProcess';
import { NoveltyUnloadingProcessStatus } from './NoveltyUnloadingProcessStatus';

export class NoveltyUnloadingProcessStatusPendingStatus extends NoveltyUnloadingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyUnloadingProcessStatuses.Pending;

  public statusColor = '#Ffffff';
}

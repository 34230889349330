import Ink from '@ink';
import { JobTypes, Task } from '@wms/domain';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:                 'pick-wave',
    displayName:         'PICKEAR',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'pick-wave' },
    checkTaskTypeAccess: true
  },
  // {
  //   key:                 'pack-wave',
  //   displayName:         'EMPAQUETAR',
  //   event:               'goToPackingTaskAssigner',
  //   payload:             {},
  //   checkTaskTypeAccess: true
  // },
  // {
  //   key:                 'mini-mop-audit',
  //   displayName:         'AUDITAR MINI-MOP',
  //   event:               'goToAuditingTaskAssigner',
  //   payload:             {},
  //   checkTaskTypeAccess: true
  // },
  {
    key:                 'packing-auditing-designator',
    displayName:         'PUESTO DE EMPAQUE Y AUDITORÍA',
    event:               'goToPackingAuditingDesignator',
    payload:             {},
    checkTaskTypeAccess: true
  },
  {
    key:         'missing-mini-mop-picking',
    displayName: 'PICKEAR FALTANTES MINI-MOP',
    event:       'navigateToTaskSearcher',
    payload:     { menuItemKey: 'missing-mini-mop-picking' }
  },
  {
    key:         'consolidation-clasification',
    displayName: 'CLASIFICAR EN SALIDA DE SORTER',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.Classification } as Task
    },
    checkTaskTypeAccess: true
  },
  {
    key:         'ptw-package-induction',
    displayName: 'ENVIAR BULTO PTW A RAMPA',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.PtwPackageInduction } as Task
    }
  },
  {
    key:                 'move-containers-of-sorter-track-to-staging',
    displayName:         'TRASLADAR DE SALIDA DE RAMPA A STAGING',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'move-containers-of-sorter-track-to-staging' },
    checkTaskTypeAccess: false
  },
  {
    key:                 'staging-auditing',
    displayName:         'AUDITAR EN CANAL',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'staging-auditing' },
    checkTaskTypeAccess: true
  },
  {
    key:         'container-merge',
    displayName: 'FUSIONAR CONTENEDORES',
    event:       'taskSelected',
    payload:     {
      task: { type: JobTypes.MergeContainers } as Task
    }
  },
  {
    key:                 'dispatching-wave',
    displayName:         'DESPACHAR MERCADERÍA',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'dispatching-wave' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'move-pending-dispatching-items',
    displayName:         'TRASLADO A ZONA FT-100',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'move-pending-dispatching-items' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'resolve-mismatched-staging-auditing',
    displayName:         'ARREGLO DE DESCUADRE',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'resolve-mismatched-staging-auditing' },
    checkTaskTypeAccess: true
  },
  // {
  //   key:                 'readjust-products',
  //   displayName:         'COMPLETAR PRODUCTOS FALTANTES',
  //   event:               'navigateToTaskSearcher',
  //   payload:             { menuItemKey: 'readjust-products' },
  //   checkTaskTypeAccess: true
  // },
  // {
  //   key:                 'maxi-consolidate-containers',
  //   displayName:         'TRASLADAR CONTENEDORES A CANAL (MAXI)',
  //   event:               'navigateToTaskSearcher',
  //   payload:             { menuItemKey: 'consolidate-containers' },
  //   checkTaskTypeAccess: true
  // },
  // {
  //   key:         'transfer-to-packing-location',
  //   displayName: 'TRASLADO MANUAL A ESTACIÓN DE PACKING',
  //   event:       'navigateToTaskSearcher',
  //   payload:     { menuItemKey: 'transfer-to-packing-location' }
  // },
  // {
  //   key:         'mini-move-to-sorter-exit',
  //   displayName: 'TRASLADO MANUAL A SALIDA DEL SORTER',
  //   event:       'navigateToTaskSearcher',
  //   payload:     { menuItemKey: 'mini-move-to-sorter-exit' }
  // },

  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const OutboundMenu: FC<MenuProps> = ({ send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

OutboundMenu.displayName = 'Menú';

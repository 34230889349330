import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { FeedingToSorterContainer } from './FeedingToSorterContainer';
import { MovingToPreSorterProcess } from './MovingToPreSorterProcess';
import { UnloadingContainer } from './UnloadingContainer';
import {
  MovingToPreSorterContainerCancelledStatus,
  MovingToPreSorterContainerMovedStatus,
  MovingToPreSorterContainerPendingStatus,
  MovingToPreSorterContainerStatus
} from './moving-to-preSorter-container-status';

export const MovingToPreSorterContainerStatuses = {
  Pending:   'pending',
  Moved:     'moved',
  Cancelled: 'cancelled'
};

@Entity()
export class MovingToPreSorterContainer {
  @PrimaryKey()
  id: number;

  @Status({
    [MovingToPreSorterContainerStatuses.Pending]:
      MovingToPreSorterContainerPendingStatus,
    [MovingToPreSorterContainerStatuses.Moved]:
      MovingToPreSorterContainerMovedStatus,
    [MovingToPreSorterContainerStatuses.Cancelled]:
      MovingToPreSorterContainerCancelledStatus
  })
  status: MovingToPreSorterContainerStatus;

  @ForeignKey(() => MovingToPreSorterProcess, {
    name: 'moving_to_pre_sorter_process_id'
  })
  movingToPreSorterProcessId: number;

  @ForeignKey(() => UnloadingContainer, { name: 'unloading_container_id' })
  unloadingContainerId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => MovingToPreSorterProcess, 'movingToPreSorterProcessId')
  movingToPreSorterProcess: MovingToPreSorterProcess;

  @Needs(() => UnloadingContainer, 'unloadingContainerId')
  unloadingContainer: UnloadingContainer;

  @Needs(() => Container, 'containerId')
  container: Container;

  @NeededBy(() => FeedingToSorterContainer, 'movingToPreSorterContainerId')
  feedingToSorterContainer: FeedingToSorterContainer;
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { PTWLocationProcess } from './PTWLocationProcess';
import { PickingWave } from './PickingWave';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  PTWLocationItemDeliveredStatus,
  PTWLocationItemDraftStatus,
  PTWLocationItemMovingStatus,
  PTWLocationItemStatus
} from './ptw-location-item-status';

export const PTWLocationItemStatuses = {
  Draft:     'draft',
  Moving:    'moving',
  Delivered: 'delivered'
};

@Entity()
export class PTWLocationItem {
  @PrimaryKey()
  id: number;

  @Status({
    [PTWLocationItemStatuses.Draft]:     PTWLocationItemDraftStatus,
    [PTWLocationItemStatuses.Moving]:    PTWLocationItemMovingStatus,
    [PTWLocationItemStatuses.Delivered]: PTWLocationItemDeliveredStatus
  })
  status: PTWLocationItemStatus;

  @ForeignKey(() => PTWLocationProcess, {
    name: 'ptw_location_process_id'
  })
  PTWLocationProcessId: number;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => PTWLocationProcess, 'PTWLocationProcessId')
  PTWLocationProcess: PTWLocationProcess;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Needs(() => Container, 'containerId')
  container: Container;

  @Knows(() => WMSTransportOrder, 'PTWLocationItemId')
  wmsTransportOrder: WMSTransportOrder;
}

import { MachineOptions, assign } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { ScanLabelContext } from './machine';

type ScanLabelActions = MachineOptions<ScanLabelContext, any>['actions'];

export const scanLabelActions: ScanLabelActions = {
  assignLabel: assign({
    label: (_, event) => event.data.label,
    error: () => ''
  }),
  clearLabel: assign({
    label: (_, _event) => ''
  }),
  assignError: assign({
    error: (_, event) => event.data.error
  }),
  assignRequiredError: assign({
    error: (_, __) => 'El Codigo de barras escaneado no es el correcto'
  }),
  assignEmptyError: assign({
    error: (_, __) => 'La etiqueta no puede estar vacia'
  }),
  assignInvalidError: assign({
    error: (_, __) => 'La etiqueta no sigue el formato establecido'
  }),
  assignInvalidTypeError: assign({
    error: (_, __) => 'La etiqueta escaneada no es del tipo correcto'
  }),
  clearParentError: sendParent('clearError')
};

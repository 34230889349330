import { OrderBatchStatuses } from '../OrderBatch';
import { OrderBatchStatus } from './OrderBatchStatus';

export class OrderBatchPartiallyDispatchedStatus extends OrderBatchStatus {
  public displayName = 'Parcialmente despachado';

  public internalName = OrderBatchStatuses.PartiallyDispatched;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

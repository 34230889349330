import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditingWave } from './AuditingWave';
import {
  MopAuditingPickingWaveStatus,
  MopAuditingPickingWaveStatusCancelledStatus,
  MopAuditingPickingWaveStatusCompletedStatus,
  MopAuditingPickingWaveStatusPendingStatus
} from './mop-auditing-picking-wave-status';
import { PickingWave } from './';

export const MopAuditingPickingWaveStatuses = {
  Pending:   'pending',
  Completed: 'completed',
  Cancelled: 'cancelled'
};

@Entity()
export class MopAuditingPickingWave {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => AuditingWave, { name: 'auditing_wave_id' })
  auditingWaveId: number;

  @ForeignKey(() => PickingWave, { name: 'picking_wave_id' })
  pickingWaveId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => AuditingWave, 'auditingWaveId')
  auditingWave: AuditingWave;

  @Needs(() => PickingWave, 'pickingWaveId')
  pickingWave: PickingWave;

  @Status({
    [MopAuditingPickingWaveStatuses.Pending]:
      MopAuditingPickingWaveStatusPendingStatus,
    [MopAuditingPickingWaveStatuses.Completed]:
      MopAuditingPickingWaveStatusCompletedStatus,
    [MopAuditingPickingWaveStatuses.Cancelled]:
      MopAuditingPickingWaveStatusCancelledStatus
  })
  status: MopAuditingPickingWaveStatus;
}

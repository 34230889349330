import Ink from '@ink';
import { SectionNames } from '@wms/domain';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterQuantity } from '../../capa-4/enter-quantity/EnterQuantity';
import { ScanSKU } from '../../capa-4/scan-sku/ScanSKU';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByName } from '../../layer-4/location/get-location-by-name/component';
import { SlottingRollContainerMachine } from './machine';

export const SlottingRollContainerTask = withActorRef(
  SlottingRollContainerMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const getMezzanineLevel = containerLocationName => {
    switch (containerLocationName) {
      case SectionNames.MezzanineLevel1:
        return 'Mezzanine nivel 1';
      case SectionNames.MezzanineLevel2:
        return 'Mezzanine nivel 2';
      case SectionNames.MezzanineLevel3:
        return 'Mezzanine nivel 3';
      case SectionNames.MezzanineLevel0:
        return 'Mezzanine nivel 0';
      default:
        return 'N/A';
    }
  };
  return (
    <>
      <Ink.Box>
        <Ink.Header>
          Guardado en Estant. Nº {current.context.slottingContainerId} -{' '}
          Contenedor {current.context.containerLpn} -{' '}
          {getMezzanineLevel(current.context.containerLocationName)}
        </Ink.Header>
      </Ink.Box>
      <Ink.Box flexDirection='column'>
        <>
          {current.matches('ScanningRollContainerLocation') && (
            <>
              <HighlightedInfo
                attribute='Diríjase al'
                value={getMezzanineLevel(current.context.containerLocationName)}
              />
              <GetLocationByName id='ScanningRollContainerLocation' />
            </>
          )}

          {current.matches('ScanningRollContainer') && (
            <>
              <HighlightedInfo
                attribute='Tome posesión del contenedor'
                value={current.context.containerLpn}
              />
              <GetContainerByLpn id='ScanningRollContainer' />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          )}

          {current.matches('ScanningSku') && (
            <>
              <Ink.Text>
                {' '}
                SKU {current.context.itemsSlotted + 1} /{' '}
                {current.context.itemsTotal}
              </Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.productAssigned!.sku} -{' '}
                {current.context.productAssigned!.name}
              </Ink.Text>
              <ScanSKU />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          )}

          {current.matches('ScanningDestinySkuLocation') && (
            <>
              <Ink.Text>
                {' '}
                SKU {current.context.itemsSlotted + 1} /{' '}
                {current.context.itemsTotal}
              </Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.productAssigned!.sku} -{' '}
                {current.context.productAssigned!.name}
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <HighlightedInfo
                attribute='Trasladese hasta ubicacion'
                value={
                  current.context.skuSelectedDestinyLocationAndQuantity!
                    .location.name
                }
              />
              <GetLocationByName id='ScanningDestinySkuLocation' />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          )}

          {current.matches('ScanningAvailableLocation') && (
            <>
              <Ink.Text>
                {' '}
                SKU {current.context.itemsSlotted + 1} /{' '}
                {current.context.itemsTotal}
              </Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.productAssigned!.sku} -{' '}
                {current.context.productAssigned!.name}
              </Ink.Text>
              <GetLocationByName id='ScanningAvailableLocation' />
              {current.context.error ? (
                <ErrorMessage error={current.context.error} />
              ) : null}
            </>
          )}

          {current.matches('IndicatingQuantityReceived') && (
            <>
              <Ink.Text>
                {' '}
                SKU {current.context.itemsSlotted + 1} /{' '}
                {current.context.itemsTotal}
              </Ink.Text>
              <Ink.Text color='greenBright' bold>
                {current.context.productAssigned!.sku} -{' '}
                {current.context.productAssigned!.name}
              </Ink.Text>

              <HighlightedInfo
                attribute='Cantidad Pendiente a Depositar'
                value={current.context.skuUnloadedQuantity!}
              />

              <HighlightedInfo
                attribute='Cantidad Máxima en esa ubicacion'
                value={
                  current.context.availableQuantityInLocationScanned as number
                }
              />

              <EnterQuantity />
            </>
          )}

          {current.matches('WarningQuantityDepositedIsNotTotalSkuQuantity') && (
            <>
              <Ink.Text bold color='redBright'>
                ¡ATENCION!
              </Ink.Text>
              <Ink.Text> </Ink.Text>
              <Ink.Text>
                La cantidad depositada no es igual a la cantidad disponible para
                depositar en esa ubicación
              </Ink.Text>
              <Ink.Text>
                {' '}
                Cantidad Depositada: {current.context.quantityDeposited}
              </Ink.Text>
              <Ink.Text>
                {' '}
                Cantidad Disponible para Depositar:{' '}
                {Math.min(
                  current.context.availableQuantityInLocationScanned as number,
                  current.context.skuUnloadedQuantity!
                )}
              </Ink.Text>
              <Ink.Text> ¿Que desea Hacer? </Ink.Text>
              <Ink.Button
                onClick={() => send('SCAN_AVAILABLE')}
                label='Escanear otra Ubicación Disponible'
              />
              <Ink.Button
                onClick={() => send('SCAN_DESTINY_LOCATION')}
                label='Escanear la ubicacion del SKU nuevamente'
              />
            </>
          )}
          {current.matches('Finishing') && (
            <>
              <Ink.Text>Todos los SKU han sido almacenados</Ink.Text>
              <Ink.Text> Deje el roll container en Zona de Buffer</Ink.Text>
              <HighlightedInfo
                attribute='Trasladese hasta ubicacion'
                value={getMezzanineLevel(current.context.containerLocationName)}
              />
              <GetLocationByName id='Finishing' />
            </>
          )}
        </>
      </Ink.Box>
    </>
  );
});

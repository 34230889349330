import {
  ConsolidationTaskTypes,
  InboundTaskTypes,
  JobTypes,
  PackingTaskTypes,
  StringTaskType,
  TaskTypes
} from '@wms/domain';
import { FC } from 'react';
import { AnyStateMachine, ContextFrom } from 'xstate';

import { BigPickingWaveTask } from './capa-3/big-picking-wave/component';
import {
  BigPickingWaveMachine,
  DefaultBigPickingWaveContext
} from './capa-3/big-picking-wave/machine';

import { ConsolidatingContainersToStagingTask } from './capa-3/consolidating-containers-to-staging/component';
import {
  ConsolidatingContainersToStagingMachine,
  DefaultConsolidatingContainersToStagingContext
} from './capa-3/consolidating-containers-to-staging/machine';

import { DispatchHDTask } from './capa-3/dispatch-hd/component';
import {
  DefaultDispatchHDContext,
  DispatchHDMachine
} from './capa-3/dispatch-hd/machine';

import { DispatchSSTask } from './capa-3/dispatch-ss/component';
import {
  DefaultDispatchSSContext,
  DispatchSSMachine
} from './capa-3/dispatch-ss/machine';

import {
  DefaultInboundDirectReceptionContext,
  InboundDirectReceptionMachine
} from './capa-3/inbound/inbound-direct-reception/InboundDirectReceptionMachine';
import { InboundDirectReceptionTask } from './capa-3/inbound/inbound-direct-reception/InboundDirectReceptionTask';

import { InventoryAdjustWaveComponent } from './capa-3/inventory-adjust-wave/component';
import {
  DefaultInventoryAdjustWaveContext,
  InventoryAdjustWaveMachine
} from './capa-3/inventory-adjust-wave/machine';
import { InventoryAdjustmentTask } from './capa-3/inventory-adjustment/component';
import {
  DefaultInventoryAdjustmentContext,
  InventoryAdjustmentMachine
} from './capa-3/inventory-adjustment/machine';
import { InventoryCountingTask } from './capa-3/inventory-counting/InventoryCountingTask';
import {
  InventoryCountingTaskInitialContext,
  InventoryCountingTaskMachine
} from './capa-3/inventory-counting/InventoryCountingTaskMachine';
import {
  InventoryCountingTaskInitialContextV1,
  InventoryCountingTaskMachineV1
} from './capa-3/inventory-counting-v1/InventoryCountingTaskMachineV1';
import { InventoryCountingTaskV1 } from './capa-3/inventory-counting-v1/InventoryCountingTaskV1';
import { ManagePrinterTask } from './capa-3/manage-printer/component';
import {
  DefaultManagePrinterContext,
  ManagePrinterMachine
} from './capa-3/manage-printer/machine';
import { MaxiConsolidateContainersTask } from './capa-3/maxi-consolidate-containers/component';
import {
  DefaultMaxiConsolidateContainersContext,
  MaxiConsolidateContainersMachine
} from './capa-3/maxi-consolidate-containers/machine';
import { MaxiPickingWaveTask } from './capa-3/maxi-picking-wave/component';
import {
  DefaultMaxiPickingWaveContext,
  MaxiPickingWaveMachine
} from './capa-3/maxi-picking-wave/machine';
import { MidiPickingWaveTask } from './capa-3/midi-picking-wave/component';
import {
  DefaultMidiPickingWaveContext,
  MidiPickingWaveMachine
} from './capa-3/midi-picking-wave/machine';
import { MiniMopAuditingWaveTask } from './capa-3/mini-mop-auditing-wave/component';
import {
  DefaultMiniMopAuditingWaveContext,
  MiniMopAuditingWaveMachine
} from './capa-3/mini-mop-auditing-wave/machine';
import { MiniMopMoveToSorterExitTask } from './capa-3/mini-mop-move-to-sorter-exit/component';
import {
  DefaultMiniMopMoveToSorterExitContext,
  MiniMopMoveToSorterExitMachine
} from './capa-3/mini-mop-move-to-sorter-exit/machine';
import { MiniMopPickingWaveTask } from './capa-3/mini-mop-picking-wave/component';
import {
  DefaultMiniMopPickingWaveContext,
  MiniMopPickingWaveMachine
} from './capa-3/mini-mop-picking-wave/machine';
import { MiniPackingWaveTask } from './capa-3/mini-packing-wave/component';
import { MiniPackingWaveMachine } from './capa-3/mini-packing-wave/machine';
import { MiniPickingWaveTask } from './capa-3/mini-picking-wave/component';
import {
  DefaultMiniPickingWaveContext,
  MiniPickingWaveMachine
} from './capa-3/mini-picking-wave/machine';
import { MiniPtwPickingWaveTask } from './capa-3/mini-ptw-picking-wave/component';
import {
  DefaultMiniPtwPickingWaveContext,
  MiniPtwPickingWaveMachine
} from './capa-3/mini-ptw-picking-wave/machine';
import { MissingMiniMopItemsTask } from './capa-3/missing-mini-mop-items/component';
import {
  DefaultMissingMiniMopItemsContext,
  MissingMiniMopItemsMachine
} from './capa-3/missing-mini-mop-items/machine';

import { MovePendingDispatchingItemsTask } from './capa-3/move-pending-dispatch-items/component';
import {
  DefaultMovePendingDispatchingItemsContext,
  MovePendingDispatchingItemsMachine
} from './capa-3/move-pending-dispatch-items/machine';
import { MiniMopMoveToAuditingLocationTask } from './capa-3/move-to-auditing-location/component';
import {
  DefaultMoveToAuditingLocationContext,
  MoveToAuditingLocationMachine
} from './capa-3/move-to-auditing-location/machine';
import { MoveToBufferTask } from './capa-3/move-to-buffer/component';
import {
  DefaultMoveToBufferContext,
  MoveToBufferMachine
} from './capa-3/move-to-buffer/machine';
import { MiniMoveToSorterExitTask } from './capa-3/move-to-sorter-exit/component';
import {
  DeafultMiniMoveToSorterExitContext,
  MiniMoveToSorterExitMachine
} from './capa-3/move-to-sorter-exit/machine';
import { NoveltyMoveContainerToBufferTask } from './capa-3/novelty-move-container-to-buffer/component';
import {
  DeafultNoveltyMoveContainerToBufferContext,
  NoveltyMoveContainerToBufferMachine
} from './capa-3/novelty-move-container-to-buffer/machine';
import { NoveltyUnloadingTask } from './capa-3/novelty-unloading/component';
import {
  DefaultNoveltyUnloadingContext,
  NoveltyUnloadingMachine
} from './capa-3/novelty-unloading/machine';

import { ReportAnomaly } from './capa-3/report-anomaly/component';
import {
  DeafultReportAnomalyContext,
  ReportAnomalyMachine
} from './capa-3/report-anomaly/machine';

import { ResolveMismatchedAuditingWaveComponent } from './capa-3/resolve-mismatched-auditing-wave/component';
import {
  DefaultResolveMismatchedAuditingWaveContext,
  ResolveMismatchedAuditingWaveMachine
} from './capa-3/resolve-mismatched-auditing-wave/machine';
import { RestockingUtility } from './capa-3/restock-utility/RestockingUtility';
import {
  DefaultRestockingUtilityContext,
  RestockingUtilityMachine
} from './capa-3/restock-utility/RestockingUtilityMachine';

import { SlottingItemTask } from './capa-3/slotting-item/component';
import {
  DefaultSlottingItemContext,
  SlottingItemMachine
} from './capa-3/slotting-item/machine';
import { SlottingRollContainerTask } from './capa-3/slotting-roll-container/component';
import {
  DefaultSlottingRollContainerContext,
  SlottingRollContainerMachine
} from './capa-3/slotting-roll-container/machine';
import { StagingAuditingComponent } from './capa-3/staging-auditing/component';
import {
  DefaultStagingAuditingContext,
  StagingAuditingMachine
} from './capa-3/staging-auditing/machine';
import { TestPrinterTask } from './capa-3/test-printer/component';
import {
  DefaultTestPrinterContext,
  TestPrinterMachine
} from './capa-3/test-printer/machine';
import { MiniMoveToPackingLocationTask } from './capa-3/transfer-to-packing-location/component';
import {
  DefaultMoveToPackingLocationContext,
  MoveToPackingLocationMachine
} from './capa-3/transfer-to-packing-location/machine';

import { UnloadingV3Task } from './capa-3/unloading-v3/component';
import {
  DefaultUnloadingV3Context,
  UnloadingV3Machine
} from './capa-3/unloading-v3/machine';
import { ChangeItemStatus } from './capa-4/change-item-status/ChangeItemStatus';
import {
  ChangeItemStatusMachine,
  defaultChangeItemStatusMachineContext
} from './capa-4/change-item-status/ChangeItemStatusMachine';

import { BulkMovementComponent } from './jobs/bulk-movement/component';
import {
  BulkMovementMachine,
  DefaultBulkMovementContext
} from './jobs/bulk-movement/machine';
import { CloseNoveltyContainerJob } from './jobs/close-novelty-container/component';
import {
  CloseNoveltyContainerMachine,
  closeNoveltyContainerContext
} from './jobs/close-novelty-container/machine';
import { CloseTransportOrderJob } from './jobs/close-transport-order/component';
import { CloseTransportOrderMachine } from './jobs/close-transport-order/machine';
import { ConsolidationClasificationComponent } from './jobs/consolidation-clasification/component';
import {
  ConsolidationClasificationMachine,
  DefaultConsolidationClasificationContext
} from './jobs/consolidation-clasification/machine';
import { ContainerDetailComponent } from './jobs/container-detail/component';
import {
  ContainerDetailMachine,
  DefaultContainerDetailContext
} from './jobs/container-detail/machine';
import { ContainerMergeComponent } from './jobs/container-merge/component';
import {
  ContainerMergeMachine,
  DefaultContainerMergeContext
} from './jobs/container-merge/machine';
import { LabelDetailComponent } from './jobs/label-detail/component';
import {
  DefaultLabelDetailContext,
  LabelDetailMachine
} from './jobs/label-detail/machine';
import { LocationDetailComponent } from './jobs/location-detail/component';
import {
  DefaultLocationDetailContext,
  LocationDetailMachine
} from './jobs/location-detail/machine';
import { ManualRestockingComponent } from './jobs/manual-restocking/component';
import {
  DefaultManualRestockingContext,
  ManualRestockingMachine
} from './jobs/manual-restocking/machine';
import { MoveBulk } from './jobs/move-bulks/component';
import {
  MoveBulkMachine,
  MoveBulksInitialContext
} from './jobs/move-bulks/machine';
import { PrintPutToWallLabels } from './jobs/print-ptw-labels/component';
import { PrintPutToWallLabelsMachine } from './jobs/print-ptw-labels/machine';
import { ProductDetailComponent } from './jobs/product-detail/component';
import {
  DefaultProductDetailContext,
  ProductDetailMachine
} from './jobs/product-detail/machine';
import { PtwPackageInductionComponent } from './jobs/ptw-package-induction/component';
import {
  DefaultPtwPackageInductionContext,
  PtwPackageInductionMachine
} from './jobs/ptw-package-induction/machine';
import { PalletRestockingTask } from './layer-3/automatic-restocking/component';
import {
  DefaultPalletRestockingTaskContext,
  PalletRestockingTaskMachine
} from './layer-3/automatic-restocking/machine';
import { FeedingToSorterTask } from './layer-3/feeding-to-sorter/component';
import {
  DefaultFeedingToSorterTaskContext,
  FeedingToSorterTaskMachine
} from './layer-3/feeding-to-sorter/machine';
import { MovingToPreSorterTask } from './layer-3/moving-to-pre-sorter/component';
import {
  DefaultMovingToPreSorterTaskContext,
  MovingToPreSorterTaskMachine
} from './layer-3/moving-to-pre-sorter/machine';
import { NoSortableSlottingTask } from './layer-3/no-sortable-slotting/component';
import {
  DefaultNoSortableSlottingTaskContext,
  NoSortableSlottingTaskMachine
} from './layer-3/no-sortable-slotting/machine';
import { PickPendingDispatchingTask } from './layer-3/pick-pending-dispatching/component';
import {
  DefaultPickPendingDispatchingTaskContext,
  PickPendingDispatchingTaskMachine
} from './layer-3/pick-pending-dispatching/machine';
import { UnloadingTask } from './layer-3/unloading/component';
import {
  DefaultUnloadingTaskContext,
  UnloadingTaskMachine
} from './layer-3/unloading/machine';

export interface TaskItem {
  machine: AnyStateMachine;
  initialContext?: ContextFrom<this['machine']>;
  component: FC;
}

export const TaskIndex = {
  /** **** INBOUND *********/

  // // Ingreso de Mercaderia
  // [TaskTypes.ReceiptTaking]: {
  //   machine:        VerifyItemsTaskMachine,
  //   initialContext: DefaultVerifyItemsTaskMachine,
  //   component:      VerifyItemsTask
  // },
  // // Ingreso de Mercaderia
  // [TaskTypes.VerifyAndPalletizeItemsV0]: {
  //   machine:        VerifyItemsTaskMachine,
  //   component:      VerifyItemsTask,
  //   initialContext: DefaultVerifyItemsTaskMachine
  // },

  // Guardado a Pallet completo
  [TaskTypes.Slotting]: {
    machine:        SlottingItemMachine,
    initialContext: DefaultSlottingItemContext,
    component:      SlottingItemTask
  },

  // Guardado En estantería
  [TaskTypes.SlottingRollContainer]: {
    machine:        SlottingRollContainerMachine,
    initialContext: DefaultSlottingRollContainerContext,
    component:      SlottingRollContainerTask
  },
  // Guardado Roll Container a nivel baldas
  [TaskTypes.MoveToBuffer]: {
    machine:        MoveToBufferMachine,
    initialContext: DefaultMoveToBufferContext,
    component:      MoveToBufferTask
  },

  // // Almacenaje
  // [TaskTypes.PutAwayContainersV0]: {
  //   machine:        PutAwayContainersTaskMachine,
  //   initialContext: DefaultPutAwayContainersTaskContext,
  //   component:      PutAwayContainersTask
  // },

  // Recepcion Directa
  [TaskTypes.InboundDirectReceptionV0]: {
    machine:        InboundDirectReceptionMachine,
    initialContext: DefaultInboundDirectReceptionContext,
    component:      InboundDirectReceptionTask
  },
  // // Pre-Almacenaje
  // [TaskTypes.PutAwayContainersToPrestorageV0]: {
  //   machine:        PutAwayContainersTaskMachine,
  //   initialContext: DefaultPutAwayContainersTaskContext,
  //   component:      PutAwayContainersTask
  // },
  // Recepcion V2
  // [TaskTypes.StandardUnloading]: {
  //   machine:        UnloadingV2Machine,
  //   initialContext: DefaultUnloadingV2Context,
  //   component:      UnloadingV2Task
  // },
  // Recepcion V3
  [TaskTypes.StandardUnloading]: {
    machine:        UnloadingV3Machine,
    initialContext: DefaultUnloadingV3Context,
    component:      UnloadingV3Task
  },
  // // Almacenaje desde Pre-Storage
  // [TaskTypes.PutAwayContainersToStorageV0]: {
  //   machine:        PutAwayContainersTaskMachine,
  //   initialContext: DefaultPutAwayContainersTaskContext,
  //   component:      PutAwayContainersTask
  // },

  // [TaskTypes.InboundMoveContainerFromStagingToStorage]: {
  //   machine:        MoveContainerFromStagingToStorageMachine,
  //   initialContext: moveContainerFromStagingToStorageTaskInitialContext,
  //   component:      MoveContainerFromStagingToStorageTask
  // },

  // Restockear ubicación de picking a pallet completo
  [TaskTypes.PalletRestockingTask]: {
    machine:        PalletRestockingTaskMachine,
    initialContext: DefaultPalletRestockingTaskContext,
    component:      PalletRestockingTask
  },

  // Restockear ubicación de picking a pallet completo desde buffer de mezanine
  [TaskTypes.PalletRestockingOnFootTask]: {
    machine:        PalletRestockingTaskMachine,
    initialContext: DefaultPalletRestockingTaskContext,
    component:      PalletRestockingTask
  },

  // // Bajar contenedor a pasillo
  // [TaskTypes.DownloadContainer]: {
  //   machine:        DownloadContainerTaskMachine,
  //   initialContext: DefaultDownloadContainerTaskContext,
  //   component:      DownloadContainerTask
  // },

  // // Subir contenedor desde el pasillo
  // [TaskTypes.UploadContainer]: {
  //   machine:        UploadContainerTaskMachine,
  //   initialContext: DefaultUploadContainerTaskContext,
  //   component:      UploadContainerTask
  // },

  // [TaskTypes.MoveContainerToStorage]: {
  //   machine:        MoveContainerTaskMachine,
  //   initialContext: moveContainerTaskInitialContext,
  //   component:      MoveContainerTask
  // },

  // [TaskTypes.FracStorage]: {
  //   machine:        BulkStorageItemMachine,
  //   initialContext: BulkStorageItemTaskInitialContext,
  //   component:      BulkStorageItem
  // },

  // Sellers Inbound

  [TaskTypes.Unloading]: {
    machine:        UnloadingTaskMachine,
    initialContext: DefaultUnloadingTaskContext,
    component:      UnloadingTask
  },

  [TaskTypes.MovingToPreSorter]: {
    machine:        MovingToPreSorterTaskMachine,
    initialContext: DefaultMovingToPreSorterTaskContext,
    component:      MovingToPreSorterTask
  },

  [TaskTypes.FeedingToSorter]: {
    machine:        FeedingToSorterTaskMachine,
    initialContext: DefaultFeedingToSorterTaskContext,
    component:      FeedingToSorterTask
  },

  [TaskTypes.NoSortableSlotting]: {
    machine:        NoSortableSlottingTaskMachine,
    initialContext: DefaultNoSortableSlottingTaskContext,
    component:      NoSortableSlottingTask
  },

  // NOVELTY INBOUND

  [TaskTypes.NoveltyUnloading]: {
    machine:        NoveltyUnloadingMachine,
    initialContext: DefaultNoveltyUnloadingContext,
    component:      NoveltyUnloadingTask
  },

  [TaskTypes.NoveltyMoveContainerToBuffer]: {
    machine:        NoveltyMoveContainerToBufferMachine,
    initialContext: DeafultNoveltyMoveContainerToBufferContext,
    component:      NoveltyMoveContainerToBufferTask
  },

  /** **** OUTBOUND *********/

  // Sorter

  [TaskTypes.MiniMoveToPackingLocationTask]: {
    machine:        MoveToPackingLocationMachine,
    initialContext: DefaultMoveToPackingLocationContext,
    component:      MiniMoveToPackingLocationTask
  },

  // Pick

  // [TaskTypes.PickOrderV0]: {
  //   machine:        PickItemsTaskMachine,
  //   initialContext: DefaultPickItemsTaskMachineContext,
  //   component:      PickItemsTask
  // },

  // [TaskTypes.PickWaveV0]: {
  //   machine:        PickItemsTaskMachine,
  //   component:      PickItemsTask,
  //   initialContext: DefaultPickItemsTaskMachineContext
  // },

  // [TaskTypes.PickAndSortV0]: {
  //   machine:   PickItemsTaskMachine,
  //   component: PickItemsTask
  // },

  [TaskTypes.MidiPickingWave]: {
    machine:        MidiPickingWaveMachine,
    component:      MidiPickingWaveTask,
    initialContext: DefaultMidiPickingWaveContext
  },
  [TaskTypes.PendingDispatchingPickingTask]: {
    machine:        PickPendingDispatchingTaskMachine,
    component:      PickPendingDispatchingTask,
    initialContext: DefaultPickPendingDispatchingTaskContext
  },

  [TaskTypes.MiniPickingWave]: {
    machine:        MiniPickingWaveMachine,
    component:      MiniPickingWaveTask,
    initialContext: DefaultMiniPickingWaveContext
  },
  [TaskTypes.MiniPtwPickingWave]: {
    machine:        MiniPtwPickingWaveMachine,
    component:      MiniPtwPickingWaveTask,
    initialContext: DefaultMiniPtwPickingWaveContext
  },
  [TaskTypes.MiniMopMoveToSorterExitTask]: {
    component:      MiniMopMoveToSorterExitTask,
    machine:        MiniMopMoveToSorterExitMachine,
    initialContext: DefaultMiniMopMoveToSorterExitContext
  },
  [TaskTypes.MovePendingDispatchingTask]: {
    component:      MovePendingDispatchingItemsTask,
    machine:        MovePendingDispatchingItemsMachine,
    initialContext: DefaultMovePendingDispatchingItemsContext
  },
  [TaskTypes.MiniMopAuditing]: {
    component:      MiniMopAuditingWaveTask,
    machine:        MiniMopAuditingWaveMachine,
    initialContext: DefaultMiniMopAuditingWaveContext
  },
  [TaskTypes.MaxiPickingWave]: {
    machine:        MaxiPickingWaveMachine,
    component:      MaxiPickingWaveTask,
    initialContext: DefaultMaxiPickingWaveContext
  },
  [TaskTypes.BigPickingWave]: {
    machine:        BigPickingWaveMachine,
    component:      BigPickingWaveTask,
    initialContext: DefaultBigPickingWaveContext
  },
  [TaskTypes.MiniMopPickingWave]: {
    machine:        MiniMopPickingWaveMachine,
    component:      MiniMopPickingWaveTask,
    initialContext: DefaultMiniMopPickingWaveContext
  },

  // Pack
  [TaskTypes.MiniPackingTask]: {
    machine:   MiniPackingWaveMachine,
    component: MiniPackingWaveTask
  },

  // // Consolidate
  // [TaskTypes.ConsolidateContainersV0]: {
  //   machine:   PickItemsTaskMachine,
  //   component: PickItemsTask
  // },

  [TaskTypes.ConsolidatingContainersToStagingType]: {
    machine:        ConsolidatingContainersToStagingMachine,
    initialContext: DefaultConsolidatingContainersToStagingContext,
    component:      ConsolidatingContainersToStagingTask
  },

  // [TaskTypes.DispatchContainersV0]: {
  //   machine:   DispatchContainersMachine,
  //   component: DispatchContainers
  // },

  // Staging Auditing
  [TaskTypes.StagingAuditingTask]: {
    machine:        StagingAuditingMachine,
    initialContext: DefaultStagingAuditingContext,
    component:      StagingAuditingComponent
  },

  // Sorter
  [TaskTypes.MiniMoveToSorterExitTask]: {
    machine:        MiniMoveToSorterExitMachine,
    initialContext: DeafultMiniMoveToSorterExitContext,
    component:      MiniMoveToSorterExitTask
  },
  // // Weight Containers
  // [TaskTypes.WeightContainersV0]: {
  //   machine:        WeightContainerTaskMachine,
  //   initialContext: WeightContainerTaskInitialContext,
  //   component:      WeightContainerTask
  // },
  // Consolidate Containers
  [TaskTypes.MaxiConsolidateContainersTask]: {
    machine:        MaxiConsolidateContainersMachine,
    initialContext: DefaultMaxiConsolidateContainersContext,
    component:      MaxiConsolidateContainersTask
  },
  // Stage
  // [TaskTypes.StageContainersV0]: {
  //   machine:   PickItemsTaskMachine,
  //   component: PickItemsTask
  // },

  // // Audit
  // [TaskTypes.AuditContainersV0]: {
  //   machine:   AuditContainersTaskMachine,
  //   component: AuditContainersTask
  // },

  // Missing mini mop items
  [TaskTypes.missingItemsMiniMop]: {
    machine:        MissingMiniMopItemsMachine,
    initialContext: DefaultMissingMiniMopItemsContext,
    component:      MissingMiniMopItemsTask
  },

  [TaskTypes.MiniMopMovingToAuditingLocationTask]: {
    machine:        MoveToAuditingLocationMachine,
    initialContext: DefaultMoveToAuditingLocationContext,
    component:      MiniMopMoveToAuditingLocationTask
  },

  // // Ship
  // [TaskTypes.ShipContainersV0]: {
  //   machine:   PickItemsTaskMachine,
  //   component: PickItemsTask
  // },

  // // Ship but cooler
  // [TaskTypes.DispatchItem]: {
  //   machine:        DispatchItemTaskMachine,
  //   initialContext: DefaultDispatchItemsTaskMachine,
  //   component:      DispatchItemTask
  // },

  // Even cooler Ship
  [TaskTypes.DispatchingHDTask]: {
    machine:        DispatchHDMachine,
    initialContext: DefaultDispatchHDContext,
    component:      DispatchHDTask
  },

  // The coolest Ship
  [TaskTypes.DispatchingSSTask]: {
    machine:        DispatchSSMachine,
    initialContext: DefaultDispatchSSContext,
    component:      DispatchSSTask
  },

  // // Move to grid 3
  // [TaskTypes.MoveContainersToGrid3]: {
  //   machine:        MoveContainersTaskMachine,
  //   initialContext: moveContainersToGrid3TaskInitialContext,
  //   component:      MoveContainersTask
  // },

  /** * Utilities de CAPA 3 */
  // [TaskTypes.CreateContainersV0]: {
  //   machine:        CreateContainersTaskMachine,
  //   initialContext: createContainersTaskInitialContext,
  //   component:      CreateContainersTask
  // },

  // [TaskTypes.CreateContainer]: {
  //   machine:        CreateContainersTaskMachine,
  //   initialContext: CreateContainersTaskMachine,
  //   component:      CreateContainersTask
  // },

  // [TaskTypes.CreateItem]: {
  //   machine:        CreateItemsTaskMachine,
  //   initialContext: createItemsTaskInitialContext,
  //   component:      CreateItemsTask
  // },

  // [TaskTypes.RemoveItemsFromContainer]: {
  //   machine:        RemoveItemsFromContainerTaskMachine,
  //   initialContext: removeItemsFromContainerTaskInitialContext,
  //   component:      RemoveItemsFromContainerTask
  // },

  // [TaskTypes.MarkItemAsAnomaly]: {
  //   machine:        CreateAnomalyMachine,
  //   initialContext: createAnomalyInitialContext,
  //   component:      CreateAnomaly
  // },

  // [TaskTypes.SelectRejectionReason]: {
  //   machine:        SelectRejectionReasonMachine,
  //   initialContext: DefaultSelectRejectionReasonContext,
  //   component:      SelectRejectionReason
  // },

  [TaskTypes.InventoryCounting]: {
    machine:        InventoryCountingTaskMachine,
    initialContext: InventoryCountingTaskInitialContext,
    component:      InventoryCountingTask
  },

  [TaskTypes.InventoryCountingV1]: {
    machine:        InventoryCountingTaskMachineV1,
    initialContext: InventoryCountingTaskInitialContextV1,
    component:      InventoryCountingTaskV1
  },

  [TaskTypes.InventoryAdjustment]: {
    machine:        InventoryAdjustmentMachine,
    initialContext: DefaultInventoryAdjustmentContext,
    component:      InventoryAdjustmentTask
  },

  /** * Utilities de CAPA 4 ****/
  // Gestion de Inventario

  // [TaskTypes.viewContainerDetail]: {
  //   machine:        ViewContainerDetailsMachine,
  //   initialContext: initialContainerDetailsContext,
  //   component:      ViewContainerDetailsTask
  // },

  // [TaskTypes.viewLocationDetail]: {
  //   machine:        ViewLocationDetailsMachine,
  //   initialContext: initialLocationDetailsContext,
  //   component:      ViewLocationDetailsTask
  // },

  [TaskTypes.ManagePrinter]: {
    machine:        ManagePrinterMachine,
    initialContext: DefaultManagePrinterContext,
    component:      ManagePrinterTask
  },

  [TaskTypes.TestPrinter]: {
    machine:        TestPrinterMachine,
    initialContext: DefaultTestPrinterContext,
    component:      TestPrinterTask
  },

  [TaskTypes.ChangeItemStatus]: {
    machine:        ChangeItemStatusMachine,
    initialContext: defaultChangeItemStatusMachineContext,
    component:      ChangeItemStatus
  },

  // [TaskTypes.MoveItemsV0]: {
  //   machine:        MoveItemsTaskMachine,
  //   initialContext: moveItemsTaskInitialContext,
  //   component:      MoveItemsTask
  // },

  // [TaskTypes.MoveItemsV1]: {
  //   machine:        MoveItemsV1TaskMachine,
  //   initialContext: moveItemsV1TaskInitialContext,
  //   component:      MoveItemsV1Task
  // },

  // [TaskTypes.ConsolidateContainersV0]: {
  //   machine:        ConsolidateContainersTaskMachine,
  //   initialContext: DefaultConsolidateContainersTaskTaskMachine,
  //   component:      ConsolidateContainersTask
  // },

  [TaskTypes.ResolveMismatchingStagingAuditingTask]: {
    machine:        ResolveMismatchedAuditingWaveMachine,
    component:      ResolveMismatchedAuditingWaveComponent,
    initialContext: DefaultResolveMismatchedAuditingWaveContext
  },

  [JobTypes.MoveBulk]: {
    machine:        MoveBulkMachine,
    initialContext: MoveBulksInitialContext,
    component:      MoveBulk
  },

  [JobTypes.CloseNoveltyContainer]: {
    machine:        CloseNoveltyContainerMachine,
    initialContext: closeNoveltyContainerContext,
    component:      CloseNoveltyContainerJob
  },

  // // Por ahora
  // [TaskTypes.AddItemsToContainer]: {
  //   machine:        AddItemsToContainerTaskMachine,
  //   component:      AddItemsToContainerTask,
  //   initialContext: addItemsToContainerTaskInitialContext
  // },

  // [TaskTypes.MarkAsAnomalyTask]: {
  //   machine:        MarkAsAnomalyMachine,
  //   component:      MarkAsAnomaly,
  //   initialContext: markAsAnomalyInitialContext
  // },

  // [TaskTypes.FractionateContainer]: {
  //   machine:        FractionateContainerTaskMachine,
  //   component:      FractionateContainerTask,
  //   initialContext: DefaultFractionateContainerTaskContext
  // },

  // [TaskTypes.ItemDetails]: {
  //   machine:        ViewItemDetailsMachine,
  //   component:      ViewItemDetailsTask,
  //   initialContext: initialItemDetailsContext
  // },

  // [TaskTypes.ViewProductDetail]: {
  //   machine:        ViewProductDetailsMachine,
  //   component:      ViewProductDetailsTask,
  //   initialContext: initialProductDetailContext
  // },

  [TaskTypes.InventoryCounting]: {
    machine:        InventoryCountingTaskMachine,
    component:      InventoryCountingTask,
    initialContext: InventoryCountingTaskInitialContext
  },

  [TaskTypes.RestockingUtility]: {
    machine:        RestockingUtilityMachine,
    component:      RestockingUtility,
    initialContext: DefaultRestockingUtilityContext
  },

  [JobTypes.ReportAnomaly]: {
    machine:        ReportAnomalyMachine,
    component:      ReportAnomaly,
    initialContext: DeafultReportAnomalyContext
  },

  [JobTypes.Classification]: {
    machine:        ConsolidationClasificationMachine,
    component:      ConsolidationClasificationComponent,
    initialContext: DefaultConsolidationClasificationContext
  },
  [JobTypes.MergeContainers]: {
    machine:        ContainerMergeMachine,
    component:      ContainerMergeComponent,
    initialContext: DefaultContainerMergeContext
  },
  [JobTypes.CloseTransportOrder]: {
    machine:   CloseTransportOrderMachine,
    component: CloseTransportOrderJob
  },
  [JobTypes.PrintPutToWallLabels]: {
    machine:   PrintPutToWallLabelsMachine,
    component: PrintPutToWallLabels
  },
  [JobTypes.ManualRestocking]: {
    machine:        ManualRestockingMachine,
    component:      ManualRestockingComponent,
    initialContext: DefaultManualRestockingContext
  },
  [JobTypes.BulkMovement]: {
    machine:        BulkMovementMachine,
    component:      BulkMovementComponent,
    initialContext: DefaultBulkMovementContext
  },
  [JobTypes.LabelDetail]: {
    machine:        LabelDetailMachine,
    component:      LabelDetailComponent,
    initialContext: DefaultLabelDetailContext
  },
  [JobTypes.ProductDetail]: {
    machine:        ProductDetailMachine,
    initialContext: DefaultProductDetailContext,
    component:      ProductDetailComponent
  },
  [JobTypes.ContainerDetail]: {
    machine:        ContainerDetailMachine,
    component:      ContainerDetailComponent,
    initialContext: DefaultContainerDetailContext
  },
  [JobTypes.LocationDetail]: {
    machine:        LocationDetailMachine,
    component:      LocationDetailComponent,
    initialContext: DefaultLocationDetailContext
  },
  [JobTypes.PtwPackageInduction]: {
    machine:        PtwPackageInductionMachine,
    component:      PtwPackageInductionComponent,
    initialContext: DefaultPtwPackageInductionContext
  },
  [TaskTypes.InventoryAdjustWave]: {
    machine:        InventoryAdjustWaveMachine,
    component:      InventoryAdjustWaveComponent,
    initialContext: DefaultInventoryAdjustWaveContext
  }
} as Record<StringTaskType, TaskItem>;

export const TaskProcessType = {
  'standard-unloading': {
    entity:           'Orden de Entrada',
    label:            'ingreso de mercaderia',
    validBucketTypes: [InboundTaskTypes.StandardUnloading]
  },
  'direct-reception': {
    entity:           'Línea Directa',
    label:            'linea-directa',
    validBucketTypes: Object.values(InboundTaskTypes)
  },
  'pick-wave': {
    entity:           'Ola de Picking',
    label:            'picking',
    validBucketTypes: [
      // TaskTypes.PickWaveV0,
      TaskTypes.MidiPickingWave,
      TaskTypes.MiniPickingWave,
      TaskTypes.MiniPtwPickingWave,
      TaskTypes.MaxiPickingWave,
      TaskTypes.BigPickingWave,
      TaskTypes.MiniMopPickingWave,
      TaskTypes.PendingDispatchingPickingTask
    ]
  },
  'dispatching-wave': {
    entity:           'Despacho de Mercadería',
    label:            'dispatching',
    validBucketTypes: [TaskTypes.DispatchingHDTask, TaskTypes.DispatchingSSTask]
  },
  'consolidate-containers': {
    entity:           'Traslado',
    label:            'Traslado',
    validBucketTypes: Object.values(ConsolidationTaskTypes)
  },
  'pack-wave': {
    entity:           'Ola de Packing',
    label:            'packing',
    validBucketTypes: Object.values(PackingTaskTypes)
  },
  'transfer-to-packing-location': {
    entity:           'Preparación',
    label:            'transfer',
    validBucketTypes: [TaskTypes.MiniMoveToPackingLocationTask]
  },
  'transfer-to-auditing-location': {
    entity:           'Tarea de traslado a auditoría',
    label:            'transfer',
    validBucketTypes: [TaskTypes.MiniMopMovingToAuditingLocationTask]
  },
  'mini-move-to-sorter-exit': {
    entity:           'Traslado Manual a Salida de Sorter',
    label:            'sorter',
    validBucketTypes: [
      TaskTypes.MiniMoveToSorterExitTask,
      TaskTypes.MiniMopMoveToSorterExitTask
    ]
  },
  'move-pending-dispatching-items': {
    entity:           'Traslado a zona FT-100',
    label:            'Traslado de remanente a FT-100',
    validBucketTypes: [TaskTypes.MovePendingDispatchingTask]
  },
  'move-containers-of-sorter-track-to-staging': {
    entity:           'Tarea de traslado de salida de rampa a zona de staging',
    label:            'Traslado',
    validBucketTypes: [TaskTypes.ConsolidatingContainersToStagingType]
  },
  'staging-auditing': {
    entity:           'Auditoria en salida de canal',
    label:            'Auditoria',
    validBucketTypes: [TaskTypes.StagingAuditingTask]
  },
  'slotting-item': {
    entity:           'Guardado a pallet completo',
    label:            'slotting',
    validBucketTypes: [TaskTypes.Slotting]
  },
  'slotting-roll-container': {
    entity:           'Guardado en estantería',
    label:            'slotting',
    validBucketTypes: [TaskTypes.SlottingRollContainer]
  },
  'move-to-buffer': {
    entity:           'Traslado de roll container a mezzanine',
    label:            'Traslado',
    validBucketTypes: [TaskTypes.MoveToBuffer]
  },
  'missing-mini-mop-picking': {
    entity:           'Pickeo Faltantes Mini-Mop',
    label:            'Picking',
    validBucketTypes: [TaskTypes.missingItemsMiniMop]
  },
  'pallet-restock-inventory': {
    entity:           'Restockear inventario a pallet completo',
    label:            'Restockear inventario a pallet completo',
    validBucketTypes: [
      TaskTypes.PalletRestockingTask,
      TaskTypes.PalletRestockingOnFootTask
    ]
  },
  'resolve-mismatched-staging-auditing': {
    entity:           'Arreglo de Descuadre',
    label:            'Arreglo de descuadre',
    validBucketTypes: [TaskTypes.ResolveMismatchingStagingAuditingTask]
  },
  'inventory-counting': {
    entity:           'Conteo de inventario',
    label:            'Conteo de Inventario',
    validBucketTypes: [
      TaskTypes.InventoryCounting,
      TaskTypes.InventoryCountingV1
    ]
  },
  'inventory-counting-v1': {
    entity:           'Conteo de inventario V1',
    label:            'Conteo de Inventario V1',
    validBucketTypes: [TaskTypes.InventoryCountingV1]
  },
  unloading: {
    entity:           'Orden de Entrada',
    label:            'Ingreso',
    validBucketTypes: [TaskTypes.Unloading]
  },
  'moving-to-pre-sorter': {
    entity:           'Orden de Entrada',
    label:            'traslado',
    validBucketTypes: [TaskTypes.MovingToPreSorter]
  },
  'feeding-to-sorter': {
    entity:           'Contenedor',
    label:            'inducido',
    validBucketTypes: [TaskTypes.FeedingToSorter]
  },
  'no-sortable-slotting': {
    entity:           'Orden de Entrada',
    label:            'almacenamiento',
    validBucketTypes: [TaskTypes.NoSortableSlotting]
  },
  'novelty-unloading': {
    entity:           'Orden de Entrada',
    label:            'ingreso de mercaderia',
    validBucketTypes: [TaskTypes.NoveltyUnloading]
  },
  'novelty-move-container-to-buffer': {
    entity:           'Traslado de contenedores',
    label:            'traslado',
    validBucketTypes: [TaskTypes.NoveltyMoveContainerToBuffer]
  },
  // jobs
  'print-ptw-labels': {
    entity:           'Orden de Put To Wall',
    label:            'Impresion de etiquetas put to wall',
    validBucketTypes: [JobTypes.PrintPutToWallLabels]
  },
  'close-transport-order': {
    entity:           'Orden de Transporte',
    label:            'Cierre de orden de transporte',
    validBucketTypes: [JobTypes.CloseTransportOrder]
  },
  'close-novelty-container': {
    entity:           'Novedades',
    label:            'Cierre de contenedor de novedades',
    validBucketTypes: [JobTypes.CloseNoveltyContainer]
  },
  'bulk-movement': {
    entity:           'Product',
    label:            'Traslado de producto a granel',
    validBucketTypes: [JobTypes.BulkMovement]
  },
  'manual-restocking': {
    entity:           'InventoryItem',
    label:            'Traslado de contenedores',
    validBucketTypes: [JobTypes.ManualRestocking]
  },
  'restocking-utility': {
    entity:           'RestockingItem',
    label:            'Restockear Item',
    validBucketTypes: [TaskTypes.RestockingUtility]
  },
  'inventory-adjustment': {
    entity:           'InventoryItem',
    label:            'Ajuste de inventario',
    validBucketTypes: [TaskTypes.InventoryAdjustment]
  },
  'report-anomaly': {
    entity:           'InventoryItem',
    label:            'Reportar Anomalía',
    validBucketTypes: [JobTypes.ReportAnomaly]
  },
  'inventory-adjust-wave': {
    entity:           'Ola de Control de inventario',
    label:            'Ola de Control de inventario',
    validBucketTypes: [TaskTypes.InventoryAdjustWave]
  }
} as const;

import { SlottingItemStatuses } from '../SlottingItem';
import { SlottingItemStatus } from './SlottingItemStatus';

export class SlottingItemStatusPendingStatus extends SlottingItemStatus {
  public displayName = 'Pendiente';

  public internalName = SlottingItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

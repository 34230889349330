import {
  Entity,
  Field,
  ForeignKey,
  Needs,
  PrimaryKey,
  Status,
  Unique
} from '@skyframe/core';
import { Receipt } from './Receipt';
import {
  MovingToSorterExitProcessStatus,
  MovingToSorterExitProcessStatusCompletedStatus,
  MovingToSorterExitProcessStatusPendingStatus,
  MovingToSorterExitProcessStatusProcessingStatus
} from './moving-to-sorter-exit-process-status';

export const MovingToSorterExitProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed'
};

@Entity()
export class MovingToSorterExitProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [MovingToSorterExitProcessStatuses.Pending]:
      MovingToSorterExitProcessStatusPendingStatus,
    [MovingToSorterExitProcessStatuses.Processing]:
      MovingToSorterExitProcessStatusProcessingStatus,
    [MovingToSorterExitProcessStatuses.Completed]:
      MovingToSorterExitProcessStatusCompletedStatus
  })
  status: MovingToSorterExitProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @Unique()
  @ForeignKey(() => Receipt, { name: 'receipt_id' })
  receiptId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Receipt, 'receiptId')
  receipt: Receipt;
}

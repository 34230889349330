import {
  Entity,
  Field,
  ForeignKey,
  IsNotEmpty,
  Needs,
  PrimaryKey
} from '@skyframe/core';

import { Product } from './Product';
import { Warehouse } from './Warehouse';

@Entity()
export class ProductPickingPosition {
  @PrimaryKey()
  id: number;

  @IsNotEmpty()
  @Field({ name: 'quantity' })
  quantity: number;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Needs(() => Product, 'productId')
  product: Product;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Container } from './Container';
import { InventoryCounting } from './InventoryCounting';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { Lot } from './Lot';
import { Product } from './Product';

@Entity()
export class InventoryCountingItem {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => InventoryCounting, { name: 'inventory_counting_id' })
  inventoryCountingId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => Container, { name: 'container_id' })
  containerId: number;

  @ForeignKey(() => Lot, { name: 'lot_id' })
  lotId: number;

  @Field({ name: 'counted_quantity' })
  countedQuantity: number;

  @Field({ name: 'original_quantity' })
  originalQuantity: number;

  @Field({ name: 'lot_number' })
  lotNumber: string;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => InventoryCounting, 'inventoryCountingId')
  inventoryCounting: InventoryCounting;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Knows(() => Product, 'productId')
  product: Product;

  @Knows(() => Container, 'containerId')
  container: Container;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Knows(() => Lot, 'lotId')
  lot: Lot;
}

import { DispatchingWaveStatuses } from '../DispatchingWave';
import { DispatchingWaveStatus } from './DispatchingWaveStatus';

export class DispatchingWavePausedStatus extends DispatchingWaveStatus {
  public displayName = 'Pausado';

  public internalName = DispatchingWaveStatuses.Paused;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

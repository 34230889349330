import { RestockingItemStatuses } from '../RestockingItem';
import { RestockingItemStatus } from './RestockingItemStatus';

export class RestockingItemStatusCancelledStatus extends RestockingItemStatus {
  public displayName = 'Cancelado';

  public internalName = RestockingItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}

import { api } from '../../../../api/utils/axios-instance';

export const labelDetailServices = {
  fetchItem: async ctx => {
    const { data } = await api.get(`${ctx.entity}/label/${ctx.label}`);
    return {
      items: data
    };
  }
};

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { ContainerDetailMachine } from './machine';

export const ContainerDetailComponent = withActorRef(ContainerDetailMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Detalle de Contenedor</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.hasTag('loading') && <Ink.Text>Loading...</Ink.Text>}
          {current.matches('ScanningContainer') && <GetContainerByLpn />}

          {current.matches('Detail') && (
            <Ink.Box flexDirection='column' padding={2}>
              <HighlightedInfo
                attribute='LPN'
                value={current.context.container?.lpn || ''}
              />
              <HighlightedInfo
                attribute='UBICACIÓN'
                value={current.context.container?.location?.name || '-'}
              />
              {current.context.orderIds && (
                <HighlightedInfo
                  attribute='ÓRDEN DE SALIDA'
                  value={current.context.orderIds.join(' o ')}
                />
              )}

              {current.context.destination && (
                <HighlightedInfo
                  attribute='SUC. DESTINO'
                  value={current.context.destination}
                />
              )}

              {current.context.receiptId && (
                <HighlightedInfo
                  attribute='ÓRDEN DE ENTRADA'
                  value={current.context.receiptId}
                />
              )}

              <Ink.Text> </Ink.Text>
              {(current.context.items?.length as number) > 0 && (
                <Ink.Text bold color='greenBright'>
                  Items: &nbsp;{' '}
                </Ink.Text>
              )}

              {current.context.items?.map((item, index) => (
                <Ink.Box key={index} marginBottom={1.5}>
                  <Ink.Text bold>{item}</Ink.Text>
                </Ink.Box>
              ))}

              <Ink.Button
                label='Escanear otro contenedor'
                onClick={() => send('rescan')}
              />
            </Ink.Box>
          )}
        </Ink.Box>
        {current.context.error && (
          <ErrorMessage error={current.context.error!} bold />
        )}
      </Ink.Box>
    );
  }
);

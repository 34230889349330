import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { PickList } from './PickList';
import { Product } from './Product';

@Entity()
export class PickListProduct {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => PickList, { name: 'pick_list_id' })
  pickListId: number;

  @Needs(() => PickList, 'pickListId')
  pickList: PickList;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @Needs(() => Product, 'productId')
  product: Product;

  @Field()
  quantity: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

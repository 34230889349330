import { DispatchingProcessStatuses } from '../DispatchingProcess';
import { DispatchingProcessStatus } from './DispatchingProcessStatus';

export class DispatchingProcessPendingStatus extends DispatchingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = DispatchingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

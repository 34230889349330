import { NoveltyUnloadingItemV2Statuses } from '../NoveltyUnloadingItemV2';
import { NoveltyUnloadingItemV2Status } from './NoveltyUnloadingItemV2Status';

export class NoveltyUnloadingItemV2StatusPendingStatus extends NoveltyUnloadingItemV2Status {
  public displayName = 'Pendiente';

  public internalName = NoveltyUnloadingItemV2Statuses.Pending;

  public statusColor = '#Ffffff';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { LogisticOperator } from '../../core/order-logistic-operator-info';
import { WarehouseInfo } from '../../core/order-warehouse-info';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { ConsolidatingContainersToStagingMachine } from './machine';

export const ConsolidatingContainersToStagingTask = withActorRef(
  ConsolidatingContainersToStagingMachine
)(({ actorRef }) => {
  const [current] = useActor(actorRef);
  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>
          Traslado de salida de rampa {current.context.locationName} a canal
        </Ink.Text>
      </Ink.Header>
      <Ink.Box flexDirection='column' paddingTop={2} padding={2}>
        {current.context?.consolidatingContainersToStagingContainer
          ?.classificationContainer?.targetWarehouse?.wmsWhCode ? (
          <WarehouseInfo
            warehouse={
              current.context?.consolidatingContainersToStagingContainer
                ?.classificationContainer?.targetWarehouse
            }
            location={{ name: current.context.locationName }}
          />
        ) : current.context?.consolidatingContainersToStagingContainer
            ?.classificationContainer?.route ? (
          <LogisticOperator
            orderType={
              current.context?.consolidatingContainersToStagingContainer
                ?.classificationContainer?.route?.order.orderType
            }
          />
        ) : null}

        {current.hasTag('loading') && <Ink.Text>Cargando...</Ink.Text>}
        {current.matches('ScanningContainer') && (
          <>
            <Ink.Text bold>Escanee contenedor a trasladar</Ink.Text>
            <Ink.Text bold color='greenBright'>
              {current.context.containerToScan!.lpn || ''}
            </Ink.Text>
            <GetContainerByLpn />
            {current.context.error && (
              <Ink.Text>
                <Ink.Chalk color='redBright'>{current.context.error}</Ink.Chalk>
              </Ink.Text>
            )}
          </>
        )}
        {current.matches('ScanningDestinyLocation') && (
          <>
            <HighlightedInfo
              attribute='LPN'
              value={current.context.containerToScan!.lpn || ''}
            />
            <HighlightedInfo
              attribute='Traslade a Ubicación'
              value={current.context.destinyLocation!.name}
            />
            <GetLocationByCheckDigit />
            {current.context.error && (
              <Ink.Text>
                <Ink.Chalk color='redBright'>{current.context.error}</Ink.Chalk>
              </Ink.Text>
            )}
          </>
        )}
      </Ink.Box>
    </Ink.Box>
  );
});

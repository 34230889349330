import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { TaskRole } from './TaskRole';
import { WorkBoard } from './WorkBoard';

@Entity()
export class WorkloadList {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => WorkBoard, { name: 'board_id' })
  boardId: number;

  @Needs(() => WorkBoard, 'boardId')
  board: WorkBoard;

  @ForeignKey(() => TaskRole, { name: 'task_role_id' })
  taskRoleId: number;

  @Needs(() => TaskRole, 'taskRoleId')
  taskRole: TaskRole;

  @Field({ name: 'title' })
  title: string;

  @Field()
  position: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import { ContainerTypes } from '@wms/domain/.';
import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MiniPickingWaveContext } from './machine';

type MiniPickingWaveActionsType = MachineOptions<
  MiniPickingWaveContext,
  any
>['actions'];

export const MiniPickingWaveActions: MiniPickingWaveActionsType = {
  assignSelectedContainerType: assign({
    selectedContainerType: (_ctx, event) => event.containerType
  }),
  assignLpn: assign({
    lpn: (_ctx, event) => event.data.lpn
  }),
  assignContainersType: assign({
    suggestedContainerType: (_ctx, event) => event.data.suggestedContainerType,
    containerTypes:         (_ctx, event) => event.data.containerTypes
  }),
  assignInitialLocation: assign({
    initialLocation: (_ctx, event) => event.data.location
  }),
  autoAssignBucketContainerType: assign({
    selectedContainerType: (_ctx, event) =>
      event.data.containerTypes.find(
        containerType => containerType.name === ContainerTypes.CUBETA
      )
  }),
  assignLocation: assign({
    selectedLocation: (_ctx, event) => event.data.location
  }),
  assignContainer: assign({
    createdContainer: (_ctx, event) => event.data
  }),
  assignCurrentPickItem: assign({
    currentPickItem: (_ctx, event) => event.data
  }),
  assignPendingTotalAndMissingItems: assign({
    totalItems:   (_ctx, event) => event.data.totalItems,
    pendingItems: (_ctx, event) => event.data.pendingItems,
    missingItems: (_ctx, event) => event.data.missingItems
  }),
  assignProduct: assign({
    currentProduct: (_ctx, event) => event.data.product
  }),
  incrementPickedQuantity: assign({
    pickedQuantity: (ctx, _event) => ctx.pickedQuantity + 1
  }),
  resetPickedQuantity: assign({
    pickedQuantity: (_ctx, _event) => 0
  }),
  assignError: assign({
    error: (_ctx, event) => {
      return getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      );
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => null
  }),
  setFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => true
  }),
  disableFlagMissingOption: assign({
    flagMissingOption: (_ctx, _evt) => false
  }),
  assignUserId: assign({
    userId: (_ctx, event) => event.data?.data?.response?.data?.userId
  }),
  assignCountQuantity: assign({
    countQuantity: (_ctx, event) => event.data.quantity
  }),
  assignUpdatedContextError: assign({
    error: (_ctx, _event) => {
      return 'La ubicación y los pickItems asociados fueron actualizados,por favor dirijase a la nueva ubicacion';
    }
  })
};

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { FeedingToSorterTaskContext } from './machine';

type FeedingToSorterTaskServices = MachineOptions<
  FeedingToSorterTaskContext,
  any
>['services'];

export const feedingToSorterTaskServices: FeedingToSorterTaskServices = {
  fetchFeedingToSorterItemsToProcess: async (ctx, _event) => {
    const { data } = await api.get(
      `feeding-to-sorter-container/${ctx.feedingToSorterContainerId}/items-count`
    );
    return {
      processedFeedingToSorterItems: data.processedFeedingToSorterItems,
      totalFeedingToSorterItems:     data.totalFeedingToSorterItems
    };
  }
};

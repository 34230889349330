import { OrderItemStatuses } from '../OrderItem';
import { OrderItemStatus } from './OrderItemStatus';

export class OrderItemStatusAuditedStatus extends OrderItemStatus {
  public displayName = 'Auditado';

  public internalName = OrderItemStatuses.Audited;

  public statusColor = '#e4f6f0';

  public textColor = '#358c42';
}

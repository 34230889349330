import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ImpureSortableCrossdockClassificationContainer } from './ImpureSortableCrossdockClassificationContainer';
import { ImpureSortableCrossdockClassificationProcess } from './ImpureSortableCrossdockClassificationProcess';
import { InventoryItem } from './InventoryItem';
import { MovingToSorterExitItem } from './MovingToSorterExitItem';
import { ReceiptItem } from './ReceiptItem';
import {
  ImpureSortableCrossdockClassificationItemStatus,
  ImpureSortableCrossdockClassificationItemStatusClassifiedStatus,
  ImpureSortableCrossdockClassificationItemStatusPendingStatus
} from './impure-sortable-crossdock-classification-item-status';

export const ImpureSortableCrossdockClassificationItemStatuses = {
  Pending:    'pending',
  Classified: 'classified'
};

@Entity()
export class ImpureSortableCrossdockClassificationItem {
  @PrimaryKey()
  id: number;

  @Status({
    [ImpureSortableCrossdockClassificationItemStatuses.Pending]:
      ImpureSortableCrossdockClassificationItemStatusPendingStatus,
    [ImpureSortableCrossdockClassificationItemStatuses.Classified]:
      ImpureSortableCrossdockClassificationItemStatusClassifiedStatus
  })
  status: ImpureSortableCrossdockClassificationItemStatus;

  @ForeignKey(() => ImpureSortableCrossdockClassificationProcess, {
    name: 'impure_sortable_crossdock_classification_process_id'
  })
  impureSortableCrossdockClassificationProcessId: number;

  @ForeignKey(() => ImpureSortableCrossdockClassificationContainer, {
    name: 'impure_sortable_crossdock_classification_container_id'
  })
  impureSortableCrossdockClassificationContainerId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @ForeignKey(() => MovingToSorterExitItem, {
    name: 'moving_to_sorter_exit_item_id'
  })
  movingToSorterExitItemId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(
    () => ImpureSortableCrossdockClassificationProcess,
    'impureSortableCrossdockClassificationProcessId'
  )
  impureSortableCrossdockClassificationProcess: ImpureSortableCrossdockClassificationProcess;

  @Knows(
    () => ImpureSortableCrossdockClassificationContainer,
    'impureSortableCrossdockClassificationContainerId'
  )
  impureSortableCrossdockClassificationContainer: ImpureSortableCrossdockClassificationContainer;

  @Needs(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Needs(() => MovingToSorterExitItem, 'movingToSorterExitItemId')
  movingToSorterExitItem: MovingToSorterExitItem;
}

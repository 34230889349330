import { MachineOptions } from 'xstate';
import { GetItemByLabelContext, GetItemByLabelFn } from './machine';

type GetItemByLabelServices = MachineOptions<
  GetItemByLabelContext,
  any
>['services'];

export const getItemByLabelServices: (
  fetchFn: GetItemByLabelFn
) => GetItemByLabelServices = fetchFn => ({
  fetchItem: (context, _event) => fetchFn(context.itemLabel.trim())
});

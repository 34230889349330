import {
  Container,
  Location,
  StagingAuditingContainer,
  StagingAuditingItem
} from '@wms/domain';
import { createMachine } from 'xstate';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';
import {
  DefaultScanLabelContext,
  ScanLabelMachine
} from '../../capa-4/scan-label/machine';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyCheckDigit } from '../../layer-4/location/get-location-by-check-digit/fetchers/get-any-location-by-check-digit';
import {
  GetLocationByCheckDigitMachine,
  GetLocationByCheckDigitMachineId
} from '../../layer-4/location/get-location-by-check-digit/machine';
import { containerMergeActions } from './actions';
import { ContainerMergeGuards } from './guards';
import { containerMergeServices } from './services';

export interface ContainerMergeContext {
  stagingLocations: Location[] | null;

  selectedStagingCheckDigit: number | null;
  staging: Location | null;

  originContainer: Container | null;
  targetContainer: Container | null;

  originStagingAuditingContainer: StagingAuditingContainer | null;
  targetStagingAuditingContainer: StagingAuditingContainer | null;
  orderType: string | null;

  itemToMoveLabel: string | null;
  stagingAuditingItemToMove: StagingAuditingItem | null;

  quantityToMove: number | null;

  errorMsg: string | null;
}

export const DefaultContainerMergeContext: ContainerMergeContext = {
  stagingLocations: null,

  selectedStagingCheckDigit: null,
  staging:                   null,

  originContainer: null,
  targetContainer: null,

  originStagingAuditingContainer: null,
  targetStagingAuditingContainer: null,
  orderType:                      null,

  itemToMoveLabel:           null,
  stagingAuditingItemToMove: null,

  quantityToMove: null,

  errorMsg: null
};

export const ContainerMergeMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGED2A7WqA2BLCAhgC64bLYGy4BmuAxsaegHQDKD66u6UAMgQCMw2AMQQMYZtwBuqANaS0mHPkZkKVWgxIY2HLj35DsCGam1MA2gAYAujduJQAB1RUd6JyAAeiAIwALABMzAHWAGxBAQAcAJzRfgDMQbHWAQA0IACeiImRzACs1sXhftYA7BWx4dUAvrWZSlh4hB7klDT0aizIABZgdHLcUACS1AASlO2wml1tGEQE3GAATmISUuiyCsxNKq1M050Wun0DQzxjk7DTsyfoSovLK6Zb5t0ODl6u7kxevghEtEAqEgn5ouUguVyqlrFCMtlEFEQQUgokYrFMeVIrFwfVGhhmqo2hpjt1dv1BsMrlMNHduo8luhVutmZttopCfsGaStOSzlTLhNaZR6fN0E9mS8zPdPn5HEgQD9cB5-rlgaDwZDobD4ZkcoCArFmEEihVkjUoVFEviQHsWjyOnyPHoCJxhgA5MAAdwASjhsIznqzJGYdvbiYdeXMmK73TwvX6A0Gpa9ZLK7F9FcrVYqAX4-OFoswIUVwfECgFweF9bkguFCmiirigtFookCoFbRGDuonTHdLx3iQeCmWWOVkPtJAsy43Cq-nnELFyiDrMlym3MXkUgVawhMSby1DUpCiq36g0QOhUBA4F4e46xYu578MGqEABaGuIr8FZiYoBxTFNCcKJLE3Zcg6JL9vccYGHwgjCN8865qA+aJH4zB+DCRZJAklYFHuv5Vv+wHrri5REei4QFJByjQVGsH8pSFyjMKNx0mS4qSqsKFvp4S4IME+6muUzDkeippwoRAT0USvYPNGcHsG6CGJv62CBgsTJ8dmqEvj4y6Fsw4ERLuBThNisT7skIJUcUGIFoklR+PJ3Iwc+pwrGAjAJj6tzcYcOnPPxC7vkJgQBA2Dlwqu7ZouUiS2TEJpmpZ4QgRElnuYxfZeSwU5+VAE5hWhRkHiZZk4kRVk1KJtHMLFLnRbEZrhLlkb5UFugACJgDm1IPCFUplYZ+ZpKEsRgtEBQwnkRZgvuCRTYBmJROEAQFG1nWKUczqxsgPnFYmgXUFkIxEGAAC2Y0Rehy6JIUfiWaaRqbpi4L7oE1gScBznhLRL1yVej6eT1PTYPOo4jXpr7hYJD0IH4QQhNU1jTeUOGWQU0TrvuuNYWaKOJMkYnWHRoNQV1SnMS6ABi3C4LAvTDHdiMVS9IRghjeSoiupTEQafjxIUwGo3j7bAsCu1PhDzC9RI7MfkkII89NQEU5uQQE0Ev1mtlmKZdCQSXrUQA */
    id:                         'ContainerMerge',
    predictableActionArguments: true,
    schema:                     {
      context: {} as ContainerMergeContext
    },
    context: DefaultContainerMergeContext,
    initial: 'ScanningCheckDigit',
    states:  {
      ScanningCheckDigit: {
        invoke: {
          id:  GetLocationByCheckDigitMachineId,
          src: GetLocationByCheckDigitMachine(
            'Ingrese dígitos de control',
            getAnyCheckDigit('Ocurrió un error, intente más tarde.')
          ),
          onDone: {
            actions: 'assignStaging',
            target:  'SelectingOriginContainer'
          }
        }
      },
      SelectingOriginContainer: {
        invoke: {
          id:  'SelectingOriginContainer',
          src: _ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor de origen',
              getAnyContainerByLpn('Ocurrió un error, intente más tarde.')
            ),
          onDone: {
            actions: 'assignOriginContainer',
            target:  'SelectingTargetContainer'
          }
        }
      },
      SelectingTargetContainer: {
        invoke: {
          id:  'SelectingTargetContainer',
          src: _ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor de destino',
              getAnyContainerByLpn('Ocurrió un error, intente más tarde.')
            ),
          onDone: {
            actions: 'assignTargetContainer',
            target:  'FetchingStagingAuditingContainers'
          }
        }
      },
      FetchingStagingAuditingContainers: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStagingAuditingContainers',
          onDone: {
            actions: [
              'assignOriginStagingAuditingContainer',
              'assignTargetStagingAuditingContainer',
              'assignOrderType',
              'clearErrorMsg'
            ],
            target: 'ScanningItem'
          },
          onError: {
            actions: 'assignErrorMsg',
            target:  'SelectingOriginContainer'
          }
        }
      },
      ScanningItem: {
        invoke: {
          id:   ScanLabelMachine.id,
          src:  ScanLabelMachine,
          data: _ctx => ({
            ...DefaultScanLabelContext,
            hint: 'Escanee bulto:'
          }),
          onDone: {
            target:  'FetchingStagingAuditingItem',
            actions: 'assignItemToMoveLabel'
          }
        }
      },
      FetchingStagingAuditingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchStagingAuditingItem',
          onDone: [
            {
              cond:    'orderTypeIsSS',
              actions: ['assignStagingAuditingItemToMove', 'clearErrorMsg'],
              target:  'ScanningItemQuantity'
            },
            {
              actions: ['assignStagingAuditingItemToMove', 'clearErrorMsg'],
              target:  'ConfirmingTargetContainer'
            }
          ],
          onError: {
            actions: 'assignErrorMsg',
            target:  'ScanningItem'
          }
        }
      },
      ScanningItemQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            hint: 'Ingrese la cantidad:',
            min:  1,
            max:  ctx.stagingAuditingItemToMove?.expectedQuantity
          }),
          onDone: {
            target:  'ConfirmingTargetContainer',
            actions: 'assignQuantityToMove'
          }
        }
      },
      ConfirmingTargetContainer: {
        invoke: {
          id:  'ConfirmingTargetContainer',
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenendor de destino',
              getRequestedContainerByLpn(
                'Ocurrió un error, intente más tarde.',
                'El contenedor escaneado no coincide con el contenedor declarado como destino.',
                ctx.targetContainer?.lpn!
              )
            ),
          onDone: {
            target: 'MoveItemToTargetContainer'
          }
        }
      },
      MoveItemToTargetContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'moveStagingAuditingItemToTargetStagingAuditingContainer',
          onDone: [
            {
              actions: 'clearErrorMsg',
              target:  'FinishingMerge'
            }
          ],
          onError: {
            actions: 'assignErrorMsg',
            target:  'ConfirmingTargetContainer'
          }
        }
      },
      FinishingMerge: {
        on: {
          FINISH: {
            target: 'Done'
          },
          CONTINUE: {
            target:  'SelectingOriginContainer',
            actions: 'clearCtx'
          }
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   ContainerMergeGuards,
    actions:  containerMergeActions,
    services: containerMergeServices
  }
);

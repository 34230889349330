import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { PutToWallWave } from './PutToWallWave';
import { SorterLocation } from './SorterLocation';

export interface StationAvailableBinsInfo {
  station: {
    id: number;
    name: string;
  };
  availableBins: number;
}

@Entity()
export class PutToWallStation {
  @PrimaryKey()
  id: number;

  @Field({ name: 'total_bins' })
  totalBins: number;

  @ForeignKey(() => SorterLocation, { name: 'sorter_location_id' })
  sorterLocationId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => SorterLocation, 'sorterLocationId')
  sorterLocation: SorterLocation;

  @NeededBy(() => PutToWallWave, 'ptwStationId')
  putToWallWaves: PutToWallWave[];
}

import {
  DomainModels,
  InventoryItemExceptionTypes,
  LabelCouriers,
  PickItemStatuses
} from '@wms/domain';
import { MachineOptions } from 'xstate';
import { getErrorCode, getErrorType } from '../../../shared/utils/getErrorView';
import { MidiPickingWaveContext } from './machine';

type MidiPickingWaveGuards = MachineOptions<
  MidiPickingWaveContext,
  any
>['guards'];

export const midiPickingWaveGuards: MidiPickingWaveGuards = {
  hasPendingItems: (_ctx, evt) => {
    return evt.data.count > 0;
  },
  hasPendingItemsToSort: (_ctx, evt) => {
    return evt.data.hasNext;
  },
  isOpportunisticCountAvailable: (_ctx, evt) => {
    return evt.data.isAvailable;
  },
  isQuantityExact: (ctx, evt) => {
    return evt.data.quantity === ctx.currentPickItem?.quantity!;
  },
  isQuantityLess: (ctx, evt) => {
    return (
      evt.data.quantity < ctx.currentPickItem?.quantity! &&
      !(evt.data.quantity < 0)
    );
  },
  userPickedLess: (ctx, _evt) => {
    return ctx.selectedQuantity! < ctx.currentPickItem?.quantity!;
  },
  isInternalSupplyDelivery: (_ctx, evt) => {
    return evt.data.type === 'AB';
  },
  isSuggestedProduct: (ctx, evt) => {
    return evt.data.product.sku === ctx.currentPickItem.sku;
  },
  itemIsInLocation: (_ctx, evt) => {
    const pickItem = evt.data.pickItem as DomainModels.PickItem;
    return pickItem.pickingLocationId === pickItem.inventoryItem.locationId;
  },

  isInventoryInsufficientQuantityException: (_ctx, evt) => {
    return (
      getErrorType(evt.data?.data) ===
      InventoryItemExceptionTypes.InsufficientQuantity
    );
  },
  hasPendingItemsToLabel: (_ctx, evt) => {
    return evt.data.pendingItemsToLabel > 0;
  },
  isMercadoEnvios: (ctx, _evt) => {
    return ctx.labelCourier === LabelCouriers.ME;
  },
  isPickItemCancelled: (_ctx, event) => {
    return event.data.status === PickItemStatuses.Cancelled;
  },
  isImATeapotException: (_ctx, evt) => {
    return getErrorCode(evt.data.data) === 418;
  },
  isImATeapotExceptionAndUserPickedLess: (ctx, evt) => {
    const isTeapot = getErrorCode(evt.data.data) === 418;
    const pickedLess = ctx.selectedQuantity! < ctx.currentPickItem?.quantity!;
    return isTeapot && pickedLess;
  }
};

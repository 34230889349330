import { TransportOrder } from '@wms/domain/.';
import { createMachine } from 'xstate';

import { getTransportOrderByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-transportOrder-by-label';
import { GetItemByLabelMachine } from '../../layer-4/label/get-item-by-label/machine';
import { fetchTransportOrderByLabelActions } from './actions';
import { closeTransportOrderGuards } from './guards';
import { closeTransportOrderServices } from './services';

export interface FetchTranportOrderByLabelContext {
  error: string | null;
  transportOrder: TransportOrder | null;
}

export const DefaultBaseContext: FetchTranportOrderByLabelContext = {
  error:          null,
  transportOrder: null
};

export const CloseTransportOrderMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCECGswDoBiYAuAxgBYAqATqgHawAOA9mXgPJkRhnICeAMqgEZgANgGIIdSlgCWlAG50A1llyFSFavUYs2HHvyEBtAAwBdRKHqxJeSeLMgAHogCsAJkOYXANgAsAZgCMTt7eABzehv4AnAA0IJyIALSRAOyYycmGTpGhhtn+Lv6eAL5FsWgYmADKRHQA7tJQ5FS0DMys7ACSlABmdMIAwoJ0GE3qrVrsRqZIIBZWNpR2jghOni5p-lE+-obJXiEusfEIIe6R5+fJ3i43m-4hJWXoWIPDDaMtmu1kouJSsgoXkNLJRGmpPm1tFM7HNrLYZssAphfCEDgcnCFkvcXCEnMkjogXL51pEsp4IoYKWEnI8QOUgW9QR8NJD2L8JJhpHJFJhXiCwc0WRMyPp-NNzG84YsEYhPOTMDtid5Nj4UpFDnFCTsPL5fIZgr5IoZDYYXLT6ZhmeNvnzIMIyHB8NCZrCFktEH4QgrdplsqifMaCQhUi5IlFMuGMckwpkSqUQJQ6Gx4DN6TDJW6ZQgEoVMJ5NlirvkQv4sX4gznMZgguE-Oro1Fkubnjh8MQrV9tFxeAJBOnLFL3QhvKlPL5grjPCElYUQhWdu59bXDS4GylmxVqnV3uChd8ur1+-N4aBlur-B5Tb6XNdcYUK040oanKXS-ro+TPDT4xa+TvBda2hHoOWbjpgYakjkqrTiOQYjuBFyRNOuxEiWvgblgHasmQtoQMBmanog+TeJghjkvcE4hAGoZBrqF6GLipI3J6OzpHGRRAA */
    id:                         'CloseTransportOrderMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as FetchTranportOrderByLabelContext
    },
    initial: 'FetchTransportOrderByLabel',
    states:  {
      FetchTransportOrderByLabel: {
        invoke: [
          {
            id:  'FetchTransportOrderByLabel',
            src: GetItemByLabelMachine(
              'Escanee etiqueta de bulto',
              getTransportOrderByLabel(
                'Ocurrio un error al obtener la orden de transporte, por favor intente más tarde.'
              ),
              {
                canScanBuckets: true
              }
            ),

            onDone: {
              actions: ['assignTransportOrder'],
              target:  'ShowingTransportOrderInfo'
            }
          }
        ]
      },

      ShowingTransportOrderInfo: {
        on: {
          CloseTransportOrder: {
            target:  'ClosingTransportOrder',
            actions: 'clearErrorMessage'
          }
        }
      },

      ClosingTransportOrder: {
        invoke: [
          {
            id:  'ClosingTransportOrder',
            src: 'closingTransportOrder',

            onDone: [
              {
                target: 'TransportOrderClosed',
                cond:   'checkIfStatus0'
              },
              {
                target:  'ShowingTransportOrderInfo',
                actions: 'assignErrorMsg'
              }
            ]
          }
        ]
      },

      TransportOrderClosed: {
        on: {
          reset: 'FetchTransportOrderByLabel'
        }
      }
    }
  },
  {
    guards:   closeTransportOrderGuards,
    actions:  fetchTransportOrderByLabelActions,
    services: closeTransportOrderServices
  }
);

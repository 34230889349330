import { MachineOptions } from 'xstate';
import { GetLocationByCheckDigitContext } from './machine';

type GetLocationByCheckDigitGuards = MachineOptions<
  GetLocationByCheckDigitContext,
  any
>['guards'];

export const getLocationByCheckDigitGuards: GetLocationByCheckDigitGuards = {
  isValidLocationCheckDigit: (context, _event) => {
    const requiredLocation = context.locationSuggestionRequired;
    const locationNameToCompare = context.locationSuggestionCheckDigit;
    if (requiredLocation && locationNameToCompare) {
      return context.locationCheckDigit === locationNameToCompare;
    }
    return !!context.locationCheckDigit;
  },
  isSuccess: (_context, event) => event.data.type === 'success'
};

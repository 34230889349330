import { ReservationPendingContainerStatuses } from '../ReservationPendingContainer';
import { ReservationPendingContainerStatus } from './ReservationPendingContainerStatus';

export class ReservationPendingContainerStatusCancelledStatus extends ReservationPendingContainerStatus {
  public displayName = 'Cancelado';

  public internalName = ReservationPendingContainerStatuses.Cancelled;

  public statusColor = '#Ffffff';
}

import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusNotDispatchedStatus extends ReservationItemStatus {
  public displayName = 'No despachado';

  public internalName = ReservationItemStatuses.NotDispatched;

  public statusColor = '#f6e8fe';

  public textColor = '#9700f0';
}

import Ink from '@ink';
import { JobTypes, Task } from '@wms/domain/.';
import React, { FC, useCallback } from 'react';
import { MenuItems } from './types';
import { useCanAccess } from './useCanAccess';

interface MenuProps {
  send: (any: string, any1: any) => void;
}

const menuItems: MenuItems = [
  {
    key:                 'standard-unloading',
    displayName:         'RECEPCIONAR MERCADERÍA',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'standard-unloading' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'novelty-unloading',
    displayName:         'RECEPCION DE NOVEDADES',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'novelty-unloading' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'close-novelty-container',
    displayName:         'CERRAR CONTENEDOR DE NOVEDADES',
    event:               'taskSelected',
    payload:             { task: { type: JobTypes.CloseNoveltyContainer } as Task },
    checkTaskTypeAccess: true
  },
  {
    key:                 'novelty-move-container-to-buffer',
    displayName:         'TRASLADO CONTENEDORES DE NOVEDADES A ZONA DE ESPERA',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'novelty-move-container-to-buffer' },
    checkTaskTypeAccess: true
  },
  // // En hito1 no va
  // {
  //   key:                 'direct-reception',
  //   displayName:         'RECEPCIONAR EN LÍNEA CON GUARDADO',
  //   event:               'navigateToTaskSearcher',
  //   payload:             { menuItemKey: 'direct-reception' },
  //   checkTaskTypeAccess: true
  // },
  {
    key:                 'slotting-item',
    displayName:         'ALMACENAR PALLET',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'slotting-item' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'move-to-buffer',
    displayName:         'TRASLADAR ROLL CONTAINER A MEZZANINE',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'move-to-buffer' },
    checkTaskTypeAccess: true
  },
  {
    key:                 'slotting-roll-container',
    displayName:         'ALMACENAR PRODUCTOS EN ESTANTERÍAS',
    event:               'navigateToTaskSearcher',
    payload:             { menuItemKey: 'slotting-roll-container' },
    checkTaskTypeAccess: true
  },
  { key: 'back', displayName: '← ATRÁS', event: 'Back', payload: {} }
] as const;

export const InboundMenu: FC<MenuProps> = ({ send }) => {
  const canAccess = useCanAccess();
  const handleClick = useCallback((id: string) => {
    const item = menuItems.find(i => i.key === id);
    if (item) {
      send(item.event, item.payload);
    }
  }, []);

  return (
    <Ink.Box flexDirection='column'>
      <Ink.Header>
        <Ink.Text>Seleccione una opción:</Ink.Text>
      </Ink.Header>

      <Ink.Box flexDirection='column' paddingLeft={1}>
        {menuItems.map(item =>
          canAccess(item) ? (
            <Ink.Button
              key={item.key}
              id={item.key}
              label={item.displayName}
              onClick={handleClick}
            />
          ) : null
        )}
      </Ink.Box>
    </Ink.Box>
  );
};

InboundMenu.displayName = 'Menu';

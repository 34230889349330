import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MoveBulksContext } from './machine';

type MoveBulksServices = MachineOptions<MoveBulksContext, any>['services'];

export const moveBulkServices: MoveBulksServices = {
  moveBulkToNewLocation: async (ctx, _event) => {
    await api.post('inventory-item/move-label', {
      label:      ctx.label,
      locationId: ctx.newLocation!.id.toString()
    });
  },
  fetchItemDetails: async ctx => {
    const { data } = await api.get(`${ctx.entity}/label/${ctx.label}`);
    return {
      items: data
    };
  }
};

import { OrderStatuses } from '../Order';
import { OrderStatus } from './OrderStatus';

export class OrderStatusAuditingOmittedStatus extends OrderStatus {
  public displayName = 'Auditoría omitida';

  public internalName = OrderStatuses.AuditingOmitted;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

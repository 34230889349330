import { SlottingItemStatuses } from '../SlottingItem';
import { SlottingItemStatus } from './SlottingItemStatus';

export class SlottingItemStatusSlottedStatus extends SlottingItemStatus {
  public displayName = 'Guardado';

  public internalName = SlottingItemStatuses.Slotted;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

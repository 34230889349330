import { MovePendingDispatchingItemsWaveStatuses } from '../MovePendingDispatchingItemsWave';
import { MovePendingDispatchingItemsWaveStatus } from './MovePendingDispatchingItemsWaveStatus';

export class MovePendingDispatchingItemsWaveCompletedStatus extends MovePendingDispatchingItemsWaveStatus {
  public displayName = 'Completado';

  public internalName = MovePendingDispatchingItemsWaveStatuses.Completed;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

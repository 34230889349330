import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { Container } from './Container';
import { Product } from './Product';
import { SorterWMSLocation } from './SorterWMSLocation';
import { WMSTransportOrder } from './WMSTransportOrder';
import {
  TransportOrderStatus,
  TransportOrderStatusCancelledStatus,
  TransportOrderStatusCompletedStatus,
  TransportOrderStatusCompletedWithErrorStatus,
  TransportOrderStatusCompletedWithUtilityStatus,
  TransportOrderStatusFailedToCreateStatus,
  TransportOrderStatusPendingStatus,
  TransportOrderStatusProcessingStatus
} from './transport-order-status';

export const TransportOrderStatuses = {
  Pending:              'pending',
  Processing:           'processing',
  Completed:            'completed',
  Cancelled:            'cancelled',
  FailedToCreate:       'failed-to-create',
  CompletedWithError:   'completed-with-error',
  CompletedWithUtility: 'completed-with-utility'
};

export const TransportOrderType = {
  TransportOrder: 'transport-order',
  PutToWall:      'put-to-wall'
} as const;

export const TransportOrderCompletedStatuses = [
  TransportOrderStatuses.Completed,
  TransportOrderStatuses.CompletedWithError,
  TransportOrderStatuses.CompletedWithUtility
];
@Entity()
export class TransportOrder {
  @PrimaryKey()
  id: number;

  @Status({
    [TransportOrderStatuses.Completed]:      TransportOrderStatusCompletedStatus,
    [TransportOrderStatuses.Cancelled]:      TransportOrderStatusCancelledStatus,
    [TransportOrderStatuses.Pending]:        TransportOrderStatusPendingStatus,
    [TransportOrderStatuses.Processing]:     TransportOrderStatusProcessingStatus,
    [TransportOrderStatuses.FailedToCreate]:
      TransportOrderStatusFailedToCreateStatus,
    [TransportOrderStatuses.CompletedWithError]:
      TransportOrderStatusCompletedWithErrorStatus,
    [TransportOrderStatuses.CompletedWithUtility]:
      TransportOrderStatusCompletedWithUtilityStatus
  })
  status: TransportOrderStatus;

  @Field()
  barcode: string;

  @ForeignKey(() => SorterWMSLocation, {
    name: 'origin_sorter_wms_location_id'
  })
  originSorterWMSLocationId: number;

  @ForeignKey(() => SorterWMSLocation, {
    name: 'requested_sorter_wms_location_id'
  })
  requestedSorterWMSLocationId: number;

  @ForeignKey(() => SorterWMSLocation, { name: 'final_sorter_wms_location_id' })
  finalSorterWMSLocationId: number;

  @Field({ name: 'valid_final_locations' })
  validFinalLocations: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => SorterWMSLocation, 'originSorterWMSLocationId')
  originSorterWMSLocation: SorterWMSLocation;

  @Knows(() => SorterWMSLocation, 'requestedSorterWMSLocationId')
  requestedSorterWMSLocation: SorterWMSLocation;

  @Knows(() => SorterWMSLocation, 'finalSorterWMSLocationId')
  finalSorterWMSLocation: SorterWMSLocation;

  @Knows(() => WMSTransportOrder, 'transportOrderId')
  wmsTransportOrder: WMSTransportOrder;

  public static isValidTransportOrder(barcode: string): boolean {
    const isValidLabel = Product.isValidLabel(barcode);

    const isValidLPN = Container.isValidLpn(barcode);

    return isValidLabel || isValidLPN;
  }
}

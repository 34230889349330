import { NoveltyUnloadingPackageStatuses } from '../NoveltyUnloadingPackage';
import { NoveltyUnloadingPackageStatus } from './NoveltyUnloadingPackageStatus';

export class NoveltyUnloadingPackageStatusCancelledStatus extends NoveltyUnloadingPackageStatus {
  public displayName = 'Cancelado';

  public internalName = NoveltyUnloadingPackageStatuses.Cancelled;

  public statusColor = '#Ffffff';
}

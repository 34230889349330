import {
  Container,
  ContainerType,
  InventoryItem,
  Location,
  Product
} from '@wms/domain/.';
import { assign, createMachine } from 'xstate';
import { AlertData } from '../../../../types/alerData';
import {
  CreateContainerMachine,
  createContainerMachineInitialContext
} from '../../capa-4/create-container/CreateContainerMachine';
import {
  DefaultEnterQuantityContext,
  EnterQuantityMachine
} from '../../capa-4/enter-quantity/EnterQuantityMachine';

import { GoToOptionsEvent } from '../../core/GenericOptions';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import { getRequestedContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-requested-container-by-lpn';
import { GetContainerByLpnMachine } from '../../layer-4/container/get-container-by-lpn/machine';
import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import { getRequestedLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-requested-location-by-name';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { PopUpMachine } from '../../layer-4/pop-up/machine';
import { getAnyProductBySKU } from '../../layer-4/product/get-product-by-sku/fetchers/get-any-product-by-sku';
import {
  GetProductBySKUMachine,
  GetProductBySKUMachineId
} from '../../layer-4/product/get-product-by-sku/machine';
import { reportAnomalyActions } from './actions';
import { reportAnomalyGuards } from './guards';
import { reportAnomalyServices } from './services';

export interface ReportAnomalyContext {
  location: Location | null;
  product: Product | null;
  anomalyItem: InventoryItem | null;
  productQuantity: string;
  containerType: ContainerType | null;
  anomalyContainer: Container | null;
  suggestedLocation: Location | null;
  error: string | null;
  targetLocation: Location | null;
  availableContainerTypes: ContainerType[] | null;
  destinationLocation: Location | null;
  pendingContainersToMoveLpns: string[];
  isMovingContainer: boolean;
  alertData: AlertData;
}

export const DeafultReportAnomalyContext = {
  location:                    null,
  product:                     null,
  anomalyItem:                 null,
  productQuantity:             null,
  containerType:               null,
  anomalyContainer:            null,
  suggestedLocation:           null,
  error:                       null,
  targetLocation:              null,
  availableContainerTypes:     [],
  destinationLocation:         null,
  pendingContainersToMoveLpns: [],
  isMovingContainer:           false,
  alertData:                   {
    errorTitle:     'Error',
    errorMessage:   'Ocurrió un error, por favor intente de nuevo',
    positiveTarget: null,
    negativeTarget: null,
    target:         null
  }
};

export const ReportAnomalyMachine = createMachine(
  {
    id:     'ReportAnomaly',
    schema: {
      context: {} as ReportAnomalyContext
    },
    initial: 'ScanningLocation',
    states:  {
      ErrorRouter: {
        always: [
          {
            cond:   'goToScanningLocation',
            target: 'ScanningLocation'
          },
          {
            cond:   'goToScanningSKU',
            target: 'ScanningSKU'
          },
          {
            cond:   'goToScanningAnomalyContainerCreated',
            target: 'ScanningAnomalyContainerCreated'
          },
          {
            cond:   'goToConfirmingTransfer',
            target: 'ConfirmingTransfer'
          },
          {
            cond:   'goToScanningDestinationLocation',
            target: 'ScanningDestinationLocation'
          }
        ]
      },
      HandleErrors: {
        invoke: {
          id:   PopUpMachine.id,
          src:  PopUpMachine,
          data: {
            error: ctx => {
              return ctx.alerData;
            }
          },
          onDone: {
            target:  'ErrorRouter',
            actions: ['clearError']
          }
        }
      },

      ScanningLocation: {
        invoke: {
          id:  'ScanningLocation',
          src: GetLocationByNameMachine(
            'Ingrese la ubicación donde se encuentra el producto dañado',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: 'assignLocation',
            target:  'FetchingValidLocation'
          }
        }
      },
      FetchingValidLocation: {
        invoke: {
          src:    'fetchingValidLocation',
          onDone: {
            target:  'ScanningSKU',
            actions: 'clearError'
          },
          onError: {
            actions: ['assignError', 'assingScanningLocationAlertData'],
            target:  'HandleErrors'
          }
        }
      },
      ScanningSKU: {
        tags:   ['sku'],
        invoke: {
          id:  GetProductBySKUMachineId,
          src: GetProductBySKUMachine(
            'Escanee SKU del producto dañado',
            getAnyProductBySKU(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: {
            actions: ['assignProduct', 'clearError'],
            target:  'ValidatingSKU'
          }
        }
      },
      ValidatingSKU: {
        invoke: {
          src:    'validatingInventoryItemIsInLocation',
          onDone: {
            actions: 'assignAnomalyItem',
            target:  'EnteringItemsQuantity'
          },
          onError: {
            actions: ['assignError', 'assingScanningSKUAlertData'],
            target:  'HandleErrors'
          }
        }
      },
      EnteringItemsQuantity: {
        invoke: {
          id:   EnterQuantityMachine.id,
          src:  EnterQuantityMachine,
          data: ctx => ({
            ...DefaultEnterQuantityContext,
            min:  1,
            max:  ctx.anomalyItem?.quantity,
            hint: 'Ingrese la cantidad de productos dañados'
          }),
          onDone: [
            {
              actions: ['assignProductQuantity', 'clearError'],
              target:  'FetchingContainerTypes'
            },
            {
              target:  'EnteringItemsQuantity',
              actions: assign({
                error: (_ctx, _event) =>
                  'Se ingreso una cantidad mayor o menor a la permitida'
              })
            }
          ]
        }
      },
      FetchingContainerTypes: {
        invoke: {
          src:    'fetchingContainerTypes',
          onDone: {
            actions: 'assignContainerTypes',
            target:  'PickingContainerType'
          }
        }
      },
      PickingContainerType: {
        on: {
          select: {
            actions: 'assignContainerType',
            target:  'ScanningContainerLpn'
          }
        }
      },
      // Para crear container
      ScanningContainerLpn: {
        invoke: {
          id:   CreateContainerMachine.id,
          src:  CreateContainerMachine,
          data: ctx => ({
            ...createContainerMachineInitialContext,
            forceContainerType: true,
            location:           ctx.location,
            containerType:      ctx.containerType,
            isAnomaly:          true
          }),
          onDone: {
            actions: 'assignCreatedContainer',
            target:  'ConfirmingTransfer'
          }
        }
      },
      // Para escanear un contenedor ya creado
      ScanningAnomalyContainerCreated: {
        invoke: {
          id:  'ScanningAnomalyContainerCreated',
          src: GetContainerByLpnMachine(
            'Escanee contenedor',
            getAnyContainerByLpn('Ocurrio un error, por favor intente de nuevo')
          ),
          onDone: {
            target:  'CheckingIfContainerIsAnomaly',
            actions: ['assignExistingContainer', 'clearError']
          }
        }
      },
      CheckingIfContainerIsAnomaly: {
        invoke: {
          src:    'validatingContainerIsAnomaly',
          onDone: [
            {
              cond:   'isContainerInPendingListToMove',
              target: 'FetchingDestinationLocation'
            },
            {
              cond:    'isScannedMovingContainer',
              actions: 'assignContainerError',
              target:  'ScanningAnomalyContainerCreated'
            },
            {
              target: 'ConfirmingTransfer'
            }
          ],
          onError: {
            actions: [
              'assignError',
              'assingScanningAnomalyContainerCreatedAlertData'
            ],
            target: 'HandleErrors'
          }
        }
      },
      ConfirmingTransfer: {
        on: {
          confirm: {
            target: 'MarkingItemAsAnomaly'
          }
        }
      },
      MarkingItemAsAnomaly: {
        invoke: {
          src:    'markItemAsAnomaly',
          onDone: {
            target: 'ScanningConfirmationContainerLpn'
          },

          onError: {
            actions: ['assignError', 'assingScanningLocationAlertData'],
            target:  'HandleErrors'
          }
        }
      },
      ScanningConfirmationContainerLpn: {
        invoke: {
          id:  'ScanningConfirmationContainerLpn',
          src: ctx =>
            GetContainerByLpnMachine(
              'Escanee contenedor',
              getRequestedContainerByLpn(
                'Ocurrio un error, por favor intente de nuevo',
                'El contenedor escaneado no es válido',
                ctx.anomalyContainer!.lpn
              )
            ),
          onDone: {
            target: 'AskingUserDecision'
          },
          onError: {
            actions: ['assignError', 'assingConfirmingTransferAlertData'],
            target:  'HandleErrors'
          }
        }
      },
      AskingUserDecision: {
        on: {
          completionOfAnomalyMarking: {
            target: 'FetchingDestinationLocation'
          },
          keepMarking: {
            actions: 'addPendingContainerToMoveId',
            target:  'ScanningLocation'
          }
        }
      },
      FetchingDestinationLocation: {
        invoke: {
          src:    'fetchingTargetLocation',
          onDone: {
            actions: 'assignTargetLocation',
            target:  'ScanningDestinationLocation'
          },
          onError: {
            actions: ['assignError', 'assingScanningLocationAlertData'],
            target:  'HandleErrors'
          }
        }
      },
      ScanningDestinationLocation: {
        invoke: {
          id:  'ScanningDestinationLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee ubicación',
              getRequestedLocationByName(
                'Ocurrio un error, por favor intente de nuevo',
                'El contenedor escaneado no es válido',
                ctx.targetLocation!.name
              )
            ),
          onDone: [
            {
              cond:    'checkIfPendingContainersToMove',
              actions: 'removeActualContainerFromPendingContainers',
              target:  'MovingContainerToAnomalyLocation'
            },
            {
              actions: 'assignDestinationLocation',
              target:  'MovingContainerToAnomalyLocation'
            }
          ]
        }
      },
      MovingContainerToAnomalyLocation: {
        invoke: {
          src:    'moveItemAndContainerToAnomalyLocation',
          onDone: [
            {
              cond:    'checkIfPendingContainersToMove',
              target:  'AskToMoveContainersOrContinue',
              actions: [
                'unassignIsMovingContainer',
                'removeActualContainerFromPendingContainers'
              ]
            },
            {
              target: 'ConfirmingScreen'
            }
          ],
          onError: {
            actions: [
              'assignError',
              'assingScanningDestinationLocationAlertData'
            ],
            target: 'HandleErrors'
          }
        }
      },
      AskToMoveContainersOrContinue: {
        on: {
          movePendingContainer: {
            target:  'ScanningAnomalyContainerCreated',
            actions: 'assignIsMovingContainer'
          },
          keepMarking: {
            actions: 'addPendingContainerToMoveId',
            target:  'ScanningLocation'
          }
        }
      },
      ConfirmingScreen: {
        on: {
          finish: {
            target: 'Done'
          }
        }
      },
      Done: {
        type: 'final'
      }
    },
    on: {
      goToOptions: {
        actions: 'triggerMenuScreen'
      },
      pickingCreatedContainer: {
        target: 'ScanningAnomalyContainerCreated'
      }
    }
  },
  {
    actions: {
      ...reportAnomalyActions,
      triggerMenuScreen: (
        ctx,
        { send, state, triggerMenuScreen }: GoToOptionsEvent
      ) => {
        triggerMenuScreen([
          ...(state.matches('ScanningContainerLpn')
            ? [
                {
                  label:   'Elegir contenedor ya creado',
                  onClick: () => send('pickingCreatedContainer')
                }
              ]
            : [])
        ]);
      }
    },
    services: reportAnomalyServices,
    guards:   reportAnomalyGuards
  }
);

import { MachineOptions } from 'xstate';
import { PrintPutToWallLabelsContext } from './machine';

type PrintPutToWallLabelsGuards = MachineOptions<
  PrintPutToWallLabelsContext,
  any
>['guards'];

export const printingPutToWallLabelsGuards: PrintPutToWallLabelsGuards = {
  isReservationInputEmpty: (ctx, _event) => {
    return !!ctx.reservationId === false || ctx.reservationId.trim() === '';
  }
};

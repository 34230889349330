import {
  Entity,
  Field,
  ForeignKey,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { OrderBatch } from './OrderBatch';
import { PendingDispatchingPickingWave } from './PendingDispatchingPickingWave';
import {
  PendingDispatchingPickingProcessCompletedStatus,
  PendingDispatchingPickingProcessPendingStatus,
  PendingDispatchingPickingProcessProcessingStatus
} from './pending-dispatching-picking-process-status';
import { PendingDispatchingPickingProcessCancelledStatus } from './pending-dispatching-picking-process-status/PendingDispatchingPickingProcessCancelledStatus';
import { PendingDispatchingPickingProcessPausedStatus } from './pending-dispatching-picking-process-status/PendingDispatchingPickingProcessPausedStatus';
import { PendingDispatchingPickingProcessStatus } from './pending-dispatching-picking-process-status/PendingDispatchingPickingProcessStatus';

export const PendingDispatchingPickingProcessStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Paused:     'paused',
  Cancelled:  'cancelled'
};

@Entity()
export class PendingDispatchingPickingProcess {
  @PrimaryKey()
  id: number;

  @Status({
    [PendingDispatchingPickingProcessStatuses.Pending]:
      PendingDispatchingPickingProcessPendingStatus,
    [PendingDispatchingPickingProcessStatuses.Processing]:
      PendingDispatchingPickingProcessProcessingStatus,
    [PendingDispatchingPickingProcessStatuses.Completed]:
      PendingDispatchingPickingProcessCompletedStatus,
    [PendingDispatchingPickingProcessStatuses.Paused]:
      PendingDispatchingPickingProcessPausedStatus,
    [PendingDispatchingPickingProcessStatuses.Cancelled]:
      PendingDispatchingPickingProcessCancelledStatus
  })
  status: PendingDispatchingPickingProcessStatus;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => OrderBatch, { name: 'order_batch_id' })
  orderBatchId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @NeededBy(
    () => PendingDispatchingPickingWave,
    'pendingDispatchingPickingProcessId'
  )
  pendingDispatchingPickingWaves: PendingDispatchingPickingWave[];
}

import {
  DomainModels,
  InventoryItemExceptionTypes,
  MopPickingContainerStatuses,
  PickItemStatuses
} from '@wms/domain';
import { MachineOptions } from 'xstate';
import { getErrorType } from '../../../shared/utils/getErrorView';
import { MiniMopPickingWaveContext } from './machine';

type MiniMopPickingWaveGuardsType = MachineOptions<
  MiniMopPickingWaveContext,
  any
>['guards'];

export const miniMopPickingWaveGuards: MiniMopPickingWaveGuardsType = {
  allBranchOfficesHaveBeenPrepared: (_ctx, evt) => {
    return !evt.data.branchOffice;
  },
  hasProcessedAllPickItem: (_ctx, evt) => {
    return !evt.data.pickItem;
  },
  itemIsInLocation: (_ctx, evt) => {
    const pickItem = evt.data.pickItem as DomainModels.PickItem;
    return pickItem.pickingLocationId === pickItem.inventoryItem.locationId;
  },
  pickingCartPositionIsAvailable: (_ctx, evt) => {
    return evt.data.pickingCartPositionAvailable;
  },
  hasPendingMopPickingContainersToCloseAndIsEmpty: (_ctx, evt) => {
    return (
      !!evt.data.mopPickingContainer &&
      evt.data.mopPickingContainer?.container?.inventoryItems?.length === 0
    );
  },
  hasPendingMopPickingContainersToClose: (_ctx, evt) => {
    return !!evt.data.mopPickingContainer;
  },
  notFound: (_ctx, evt) => {
    return !evt.data.found;
  },
  isSuggestedLocation: (ctx, evt) => {
    return evt.data.name === ctx.currentPickItem?.inventoryItem.location.name;
  },
  isInventoryInsufficientQuantityException: (_ctx, evt) => {
    return (
      getErrorType(evt.data?.data) ===
      InventoryItemExceptionTypes.InsufficientQuantity
    );
  },
  isOpportunisticCountAvailable: (_ctx, evt) => {
    return evt.data.isAvailable;
  },
  isEmptyContainer: (_ctx, evt) => {
    if (evt.data.container && evt.data.container.inventoryItems) {
      return evt.data.container.inventoryItems.length === 0;
    }
    return true;
  },
  isPickItemCancelled: (_ctx, event) => {
    return event.data.status === PickItemStatuses.Cancelled;
  },
  isMovingMopPickingContainer: (_ctx, evt) => {
    return (
      evt.data.mopPickingContainer?.status !==
      MopPickingContainerStatuses.Picking
    );
  }
};

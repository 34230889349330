import { NoveltyUnloadingContainerStatuses } from '../NoveltyUnloadingContainer';
import { NoveltyUnloadingContainerStatus } from './NoveltyUnloadingContainerStatus';

export class NoveltyUnloadingContainerStatusCompletedStatus extends NoveltyUnloadingContainerStatus {
  public displayName = 'Completado';

  public internalName = NoveltyUnloadingContainerStatuses.Completed;

  public statusColor = '#Ffffff';
}

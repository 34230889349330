import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryAdjustItem } from './InventoryAdjustItem';
import { Task } from './Task';
import { User } from './User';
import {
  InventoryAdjustWaveCompletedStatus,
  InventoryAdjustWavePausedStatus,
  InventoryAdjustWavePendingStatus,
  InventoryAdjustWaveProcessingStatus,
  InventoryAdjustWaveStatus
} from './inventory-adjust-wave-status';

export const InventoryAdjustWaveStatuses = {
  Pending:    'pending',
  Paused:     'paused',
  Processing: 'processing',
  Completed:  'completed'
};

export interface LocationToAdjust {
  location: any;
  productId?: number;
  quantity: number;
}

@Entity()
export class InventoryAdjustWave {
  @PrimaryKey()
  id: number;

  @Status({
    [InventoryAdjustWaveStatuses.Pending]:    InventoryAdjustWavePendingStatus,
    [InventoryAdjustWaveStatuses.Completed]:  InventoryAdjustWaveCompletedStatus,
    [InventoryAdjustWaveStatuses.Paused]:     InventoryAdjustWavePausedStatus,
    [InventoryAdjustWaveStatuses.Processing]:
      InventoryAdjustWaveProcessingStatus
  })
  status: InventoryAdjustWaveStatus;

  @ForeignKey(() => User, { name: 'author_id' })
  authorId: number;

  @Needs(() => User, 'authorId')
  author: User;

  @ForeignKey(() => Task, { name: 'task_id' })
  taskId: number;

  @Knows(() => Task, 'taskId')
  task: Task;

  @Field({ name: 'task_name' })
  taskName: string;

  @NeededBy(() => InventoryAdjustItem, 'inventoryAdjustWaveId')
  inventoryAdjustItems: InventoryAdjustItem[];

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

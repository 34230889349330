import { Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByCheckDigitFn } from '../machine';

export function getAnyCheckDigit(
  defaultErrorMessage: string
): GetLocationByCheckDigitFn {
  return fetchResult(defaultErrorMessage, checkDigit => {
    return api.get<Location>('location/check-digit', {
      params: { checkDigit }
    });
  });
}

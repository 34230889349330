import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ConsolidateContainersItem } from './ConsolidateContainersItem';
import { Location } from './Location';
import { MopAuditingPickingWave } from './MopAuditingPickingWave';
import { MopPickingContainer } from './MopPickingContainer';
import { PTWLocationItem } from './PTWLocationItem';
import { PackingLocationItem } from './PackingLocationItem';
import { PickItem } from './PickItem';
import { PickList } from './PickList';
import { PickingContainerGroup } from './PickingContainerGroup';
import { PickingProcess } from './PickingProcess';
import { User } from './User';
import {
  PickingWaveStatus,
  PickingWaveStatusCancelledStatus,
  PickingWaveStatusCompletedStatus,
  PickingWaveStatusPausedStatus,
  PickingWaveStatusPendingStatus,
  PickingWaveStatusProcessingStatus
} from './picking-wave-status';

export const PickingWaveStatuses = {
  Pending:    'pending',
  Processing: 'processing',
  Completed:  'completed',
  Cancelled:  'cancelled',
  Paused:     'paused'
};

@Entity()
export class PickingWave {
  @PrimaryKey()
  id: number;

  @Field({ name: 'started_at' })
  startedAt: Date;

  @Field({ name: 'completed_at' })
  completedAt: Date;

  @ForeignKey(() => PickingProcess, { name: 'picking_process_id' })
  pickingProcessId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @Knows(() => User, 'userId')
  picker: User;

  @Field({ name: 'description' })
  description: string;

  @Status({
    pending:    PickingWaveStatusPendingStatus,
    processing: PickingWaveStatusProcessingStatus,
    completed:  PickingWaveStatusCompletedStatus,
    cancelled:  PickingWaveStatusCancelledStatus,
    paused:     PickingWaveStatusPausedStatus
  })
  status: PickingWaveStatus;

  @Needs(() => PickingProcess, 'pickingProcessId')
  pickingProcess: PickingProcess;

  @Knows(() => PickItem, 'pickingWaveId')
  pickItems: PickItem[];

  @Knows(() => Location, 'consolidationLocationId')
  consolidationLocation: Location;

  @ForeignKey(() => Location, { name: 'consolidation_location_id' })
  consolidationLocationId: number;

  @NeededBy(() => PackingLocationItem, 'pickingWaveId')
  packingLocationItems: PackingLocationItem[];

  @NeededBy(() => ConsolidateContainersItem, 'pickingWaveId')
  consolidateContainersItem: ConsolidateContainersItem[];

  @NeededBy(() => PickingContainerGroup, 'pickingWaveId')
  pickingContainerGroups: PickingContainerGroup[];

  @NeededBy(() => MopAuditingPickingWave, 'pickingWaveId')
  mopAuditingPickingWaves: MopAuditingPickingWave[];

  @NeededBy(() => MopPickingContainer, 'pickingWaveId')
  mopPickingContainers: MopPickingContainer[];

  @NeededBy(() => PTWLocationItem, 'pickingWaveId')
  moveToPTWLocationItems: PTWLocationItem[];

  @NeededBy(() => PickList, 'pickingWaveId')
  pickLists: PickList[];
}

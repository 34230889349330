import { StandardUnloadingItemStatuses } from '../StandardUnloadingItem';
import { StandardUnloadingItemStatus } from './StandardUnloadingItemStatus';

export class StandardUnloadingItemStatusCancelledStatus extends StandardUnloadingItemStatus {
  public displayName = 'Cancelado';

  public internalName = StandardUnloadingItemStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

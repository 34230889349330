import { ImpureSortableCrossdockClassificationItemStatuses } from '../ImpureSortableCrossdockClassificationItem';
import { ImpureSortableCrossdockClassificationItemStatus } from './ImpureSortableCrossdockClassificationItemStatus';

export class ImpureSortableCrossdockClassificationItemStatusPendingStatus extends ImpureSortableCrossdockClassificationItemStatus {
  public displayName = 'Pendiente';

  public internalName =
    ImpureSortableCrossdockClassificationItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey
} from '@skyframe/core';
import { Location } from './Location';
import { PackagingLevel } from './PackagingLevel';
import { Product } from './Product';
import { ProductRestockingLevelAlert } from './ProductRestockingLevelAlert';
import { ProductRestockingLevelAlertType } from './ProductRestockingLevelAlertType';

@Entity()
export class ProductRestockingLevel {
  @PrimaryKey()
  id: number;

  @Field({ name: 'restocking_quantity' })
  restockingQuantity: number;

  @Field({ name: 'maximum_capacity' })
  maximumCapacity: number;

  @ForeignKey(() => Product, { name: 'product_id' })
  productId: number;

  @ForeignKey(() => Location, { name: 'location_id' })
  locationId: number;

  @ForeignKey(() => PackagingLevel, { name: 'restocking_packaging_level_id' })
  packagingLevelId: number;

  @ForeignKey(() => ProductRestockingLevelAlertType, { name: 'alert_type_id' })
  productRestockingLevelAlertTypeId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Knows(() => Location, 'locationId')
  location: Location;

  @Needs(() => Product, 'productId')
  product: Product;

  @Needs(() => PackagingLevel, 'packagingLevelId')
  packagingLevel: PackagingLevel;

  @Needs(
    () => ProductRestockingLevelAlertType,
    'productRestockingLevelAlertTypeId'
  )
  productRestockingLevelAlertType: ProductRestockingLevelAlertType;

  @NeededBy(() => ProductRestockingLevelAlert, 'productRestockingLevelId')
  productRestockingLevelAlerts: ProductRestockingLevelAlert[];
}

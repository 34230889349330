import { NoveltyMoveContainerToBufferContainerStatuses } from '../NoveltyMoveContainerToBufferContainer';
import { NoveltyMoveContainerToBufferContainerStatus } from './NoveltyMoveContainerToBufferContainerStatus';

export class NoveltyMoveContainerToBufferContainerStatusPendingStatus extends NoveltyMoveContainerToBufferContainerStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyMoveContainerToBufferContainerStatuses.Pending;

  public statusColor = '#Ffffff';
}

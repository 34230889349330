import { SlottingContainerStatuses } from '../SlottingContainer';
import { SlottingContainerStatus } from './SlottingContainerStatus';

export class SlottingContainerStatusCancelledStatus extends SlottingContainerStatus {
  public displayName = 'Cancelado';

  public internalName = SlottingContainerStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

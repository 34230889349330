import { Product } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetProductBySKUFn } from '../machine';

export function getAnyProductBySKUToValidate(
  defaultErrorMessage: string
): GetProductBySKUFn {
  return fetchResult(defaultErrorMessage, sku =>
    api.get<Product>(`product/sku/${sku}`)
  );
}

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Entity,
  Field,
  ForeignKey,
  IsDefined,
  Knows,
  NeededBy,
  Needs,
  Owns,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Appointment } from './Appointment';
import { Comment } from './Comment';
import { Document } from './Document';
import { FeedingToSorterProcess } from './FeedingToSorterProcess';
import { ImpureSortableCrossdockClassificationProcess } from './ImpureSortableCrossdockClassificationProcess';
import { Location } from './Location';
import { MoveToBufferProcess } from './MoveToBufferProcess';
import { MovingToPreSorterProcess } from './MovingToPreSorterProcess';
import { MovingToSorterExitProcess } from './MovingToSorterExitProcess';
import { NoSortableSlottingProcess } from './NoSortableSlottingProcess';
import { Novelty } from './Novelty';
import { NoveltyMoveContainerToBufferProcess } from './NoveltyMoveContainerToBufferProcess';
import { NoveltyUnloadingProcess } from './NoveltyUnloadingProcess';
import { Process } from './Process';
import { PurchaseOrder } from './PurchaseOrder';
import { ReceiptBatch } from './ReceiptBatch';
import { ReceiptItem } from './ReceiptItem';
import { ReceiptOrigin } from './ReceiptOrigin';
import { ReceiptRejectionReport } from './ReceiptRejectionReport';
import { ReceiptType } from './ReceiptType';
import { ReservationReceipt } from './ReservationReceipt';
import { Route } from './Route';
import { SlottingProcess } from './SlottingProcess';
import { StandardUnloadingProcess } from './StandardUnloadingProcess';
import { StockTransfer } from './StockTransfer';
import { Task } from './Task';
import { TransferOrder } from './TransferOrder';
import { UnloadingProcess } from './UnloadingProcess';
import { Warehouse } from './Warehouse';
import {
  ReceiptStatus,
  ReceiptStatusClosedStatus,
  ReceiptStatusConfirmedStatus,
  ReceiptStatusOpenStatus,
  ReceiptStatusPreConfirmedStatus,
  ReceiptStatusPreSortingStatus,
  ReceiptStatusProcessingStatus,
  ReceiptStatusSortedStatus,
  ReceiptStatusSortingStatus,
  ReceiptStatusStoredStatus,
  ReceiptStatusStoringStatus
} from './receipt-status';

export const ReceiptStatuses = {
  Open:         'open',
  Processing:   'processing',
  PreConfirmed: 'pre-confirmed',
  Confirmed:    'confirmed',
  Closed:       'closed',
  PreSorting:   'pre-sorting',
  Sorting:      'sorting',
  Sorted:       'sorted',
  Storing:      'storing',
  Stored:       'stored'
};

export const ProcessReceiptStatuses = [
  ReceiptStatuses.Processing,
  ReceiptStatuses.Confirmed,
  ReceiptStatuses.PreSorting,
  ReceiptStatuses.Sorting
];

export const UnloadingProcessReceiptStatuses = [
  ReceiptStatuses.Open,
  ReceiptStatuses.Processing,
  ReceiptStatuses.PreConfirmed
];

export const ReceiptMoveStatuses = {
  NotMoved:  'not-moved',
  Moved:     'moved',
  Cancelled: 'cancelled'
};

export const ReceiptPendingStatuses = [ReceiptStatuses.Open] as const;

export const ReceiptStoredStatuses = [ReceiptStatuses.Stored] as const;

export type ReceiptMoveStatusesTypes =
  (typeof ReceiptMoveStatuses)[keyof typeof ReceiptMoveStatuses];

export const ReceiptWorkFlows = {
  ZoneClassifierLowVolume:  'zone-classifier-low-volume',
  ZoneClassifierHighVolume: 'zone-classifier-high-volume',
  CrossDockPush:            'cross-dock-push',
  WMSCedisReceivingProcess: 'ReceivingProcess-v0',
  WMSCedisInboundProcess:   'InboundProcess-v0'
};

export type ReceiptWorkFlowTypes =
  (typeof ReceiptWorkFlows)[keyof typeof ReceiptWorkFlows];

@Entity()
export class Receipt {
  static WORK_FLOW_AVAILABILITY = [
    {
      internalName: ReceiptWorkFlows.ZoneClassifierLowVolume,
      displayName:  'Bajo Volumen (Clasificación en Recepción)',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.ZoneClassifierHighVolume,
      displayName:  'Alto Volumen (Clasificación post Recepción)',
      isAvailable:  false
    },
    {
      internalName: ReceiptWorkFlows.CrossDockPush,
      displayName:  'Cross dock Push',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.WMSCedisReceivingProcess,
      displayName:  'Recepción en Cedis',
      isAvailable:  true
    },
    {
      internalName: ReceiptWorkFlows.WMSCedisInboundProcess,
      displayName:  'Entrada de mercadería en Cedis',
      isAvailable:  true
    }
  ];

  @ApiField()
  @PrimaryKey()
  id: number;

  @Field({ name: 'work_flow' })
  workFlow: ReceiptWorkFlowTypes;

  @ApiField()
  @IsDefined({ message: 'El campo es requerido.' })
  @ForeignKey(() => ReceiptType, { name: 'receipt_type_id' })
  receiptTypeId: number;

  @ApiField()
  @IsDefined({ message: 'El campo es requerido.' })
  @ForeignKey(() => ReceiptOrigin, { name: 'receipt_origin_id' })
  receiptOriginId: number;

  @ApiField()
  @ForeignKey(() => Appointment, { name: 'appointment_id' })
  appointmentId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'sap_code' })
  sapCode: string;

  @Status({
    [ReceiptStatuses.Open]:         ReceiptStatusOpenStatus,
    [ReceiptStatuses.Processing]:   ReceiptStatusProcessingStatus,
    [ReceiptStatuses.PreConfirmed]: ReceiptStatusPreConfirmedStatus,
    [ReceiptStatuses.Confirmed]:    ReceiptStatusConfirmedStatus,
    [ReceiptStatuses.Closed]:       ReceiptStatusClosedStatus,
    [ReceiptStatuses.PreSorting]:   ReceiptStatusPreSortingStatus,
    [ReceiptStatuses.Sorting]:      ReceiptStatusSortingStatus,
    [ReceiptStatuses.Sorted]:       ReceiptStatusSortedStatus,
    [ReceiptStatuses.Storing]:      ReceiptStatusStoringStatus,
    [ReceiptStatuses.Stored]:       ReceiptStatusStoredStatus
  })
  status: ReceiptStatus;

  @Owns(() => ReceiptItem, 'receiptId')
  receiptItems: ReceiptItem[];

  @Needs(() => ReceiptType, 'receiptTypeId')
  receiptType: ReceiptType;

  @Needs(() => ReceiptOrigin, 'receiptOriginId')
  receiptOrigin: ReceiptOrigin;

  @NeededBy(() => ReceiptRejectionReport, 'receiptId')
  receiptRejectionReport: ReceiptRejectionReport;

  @NeededBy(() => NoveltyMoveContainerToBufferProcess, 'receiptId')
  noveltyMoveContainerToBufferProcesses: NoveltyMoveContainerToBufferProcess[];

  @Knows(() => Document, 'receiptId')
  documents: Document[];

  @Knows(() => Appointment, 'appointmentId')
  appointment: Appointment;

  @Knows(() => Task, 'receiptId')
  task: Task[];

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Field({ name: 'delivery_note' })
  deliveryNote: string;

  @Knows(() => Comment, 'receiptId')
  comment: Comment[];

  @ForeignKey(() => PurchaseOrder, { name: 'purchase_order_id' })
  purchaseOrderId?: number;

  @Knows(() => PurchaseOrder, 'purchaseOrderId')
  purchaseOrder?: PurchaseOrder;

  @Field({ name: 'move_status' })
  moveStatus: ReceiptMoveStatusesTypes;

  @ForeignKey(() => Route, { name: 'route_id' })
  routeId: number;

  @Knows(() => Route, 'routeId')
  route: Route;

  @Field({ name: 'estimated_date' })
  estimatedDate: Date;

  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @ForeignKey(() => ReceiptBatch, { name: 'receipt_batch_id' })
  receiptBatchId: number;

  @Knows(() => ReceiptBatch, 'receiptBatchId')
  receiptBatch: ReceiptBatch;

  @ApiField()
  @ForeignKey(() => TransferOrder, { name: 'transfer_order_id' })
  transferOrderId?: number;

  @Knows(() => TransferOrder, 'transferOrderId')
  transferOrder?: TransferOrder;

  @ApiField()
  @ForeignKey(() => StockTransfer, { name: 'stock_transfer_id' })
  stockTransferId?: number;

  @Knows(() => StockTransfer, 'stockTransferId')
  stockTransfer?: StockTransfer;

  @ForeignKey(() => Location, { name: 'unloading_location_id' })
  unloadingLocationId: number;

  @Knows(() => Location, 'unloadingLocationId')
  unloadingLocation: Location;

  @Field({ name: 'many_sku_allowed_in_container' })
  manySkuAllowedInContainer: boolean;

  @NeededBy(() => UnloadingProcess, 'receiptId')
  unloadingProcess: UnloadingProcess;

  @NeededBy(() => MovingToPreSorterProcess, 'receiptId')
  movingToPreSorterProcess: MovingToPreSorterProcess;

  @NeededBy(() => FeedingToSorterProcess, 'receiptId')
  feedingToSorterProcess: FeedingToSorterProcess;

  @NeededBy(() => MovingToSorterExitProcess, 'receiptId')
  movingToSorterExitProcess: MovingToSorterExitProcess;

  @NeededBy(() => NoSortableSlottingProcess, 'receiptId')
  noSortableSlottingProcess: NoSortableSlottingProcess;

  @NeededBy(() => ImpureSortableCrossdockClassificationProcess, 'receiptId')
  impureSortableCrossdockClassificationProcess: ImpureSortableCrossdockClassificationProcess;

  @NeededBy(() => StandardUnloadingProcess, 'receiptId')
  standardUnloadingProcess: StandardUnloadingProcess;

  @NeededBy(() => MoveToBufferProcess, 'receiptId')
  moveToBufferProcess: MoveToBufferProcess;

  @NeededBy(() => SlottingProcess, 'receiptId')
  slottingProcess: SlottingProcess;

  @Field({ name: 'document_number' })
  documentNumber: string;

  @NeededBy(() => NoveltyUnloadingProcess, 'receiptId')
  noveltyUnloadingProcess: NoveltyUnloadingProcess;

  @NeededBy(() => Novelty, 'receiptId')
  novelties: Novelty[];

  @NeededBy(() => ReservationReceipt, 'receiptId')
  reservationReceipt: ReservationReceipt[];
}

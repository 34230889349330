import { MachineOptions, assign } from 'xstate';
import { FetchTranportOrderByLabelContext } from './machine';

type FetchTransportOrderByLabelActions = MachineOptions<
  FetchTranportOrderByLabelContext,
  any
>['actions'];

export const fetchTransportOrderByLabelActions: FetchTransportOrderByLabelActions =
  {
    assignTransportOrder: assign({
      transportOrder: (_ctx, evt) => evt.data
    }),
    assignErrorMsg: assign({
      error: (_ctx, evt) =>
        `Ocurrio un error al cerrar la orden de transporte. Error ${evt.data.data.code}: ${evt.data.data.message}`
    }),
    clearErrorMessage: assign({
      error: (_, __) => null
    })
  };

import { Product } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { escalate } from 'xstate/lib/actions';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface ScanProductIdentifierContext {
  product: Product | null;
  identifier: string | null;
  error: string | null;
  delegateErrorHandlingToParent: boolean;
}

export const DefaultScanProductContext: ScanProductIdentifierContext = {
  product:                       null,
  identifier:                    null,
  error:                         null,
  delegateErrorHandlingToParent: false
};

export const ScanProductIdentifierMachine =
  createMachine<ScanProductIdentifierContext>(
    {
      id:      'ScanProductIdentifierMachine',
      initial: 'AwaitingIdentifierScan',
      states:  {
        AwaitingIdentifierScan: {
          on: {
            ChangedIdentifier: {
              actions: assign({
                identifier: (_, event) => event.data
              })
            },
            SubmittedIdentifier: {
              target: 'FetchingProduct'
            }
          }
        },
        FetchingProduct: {
          invoke: {
            src:    'findProductByIdentifier',
            onDone: {
              actions: [
                'clearError',
                assign({ product: (_ctx, event) => event.data.product })
              ],
              target: 'ProductFound'
            },
            onError: [
              {
                target:  'Error',
                cond:    'delegateErrorHandlingToParent',
                actions: ['throwError', 'clearInput']
              },
              {
                actions: ['assignError', 'clearInput'],
                target:  'AwaitingIdentifierScan'
              }
            ]
          }
        },
        ProductFound: {
          type: 'final',
          data: ctx => ({ product: ctx.product })
        },
        Error: {
          type: 'final'
        }
      }
    },
    {
      guards: {
        ...UtilityGuards,
        delegateErrorHandlingToParent: ctx => ctx.delegateErrorHandlingToParent
      },
      actions: {
        ...UtilityActions,
        clearError: assign({
          error: _ctx => null
        }),
        clearInput: assign({
          identifier: _ctx => ''
        }),
        throwError: escalate((_, event) => ({ data: event.data }))
      },
      services: { ...API }
    }
  );

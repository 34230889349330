import { MachineOptions } from 'xstate';
import { MiniMopAuditingWaveContext } from './machine';

type MiniMopAuditingWaveGuards = MachineOptions<
  MiniMopAuditingWaveContext,
  any
>['guards'];

export const miniMopAuditingWaveGuards: MiniMopAuditingWaveGuards = {
  isCity:                    (_, event) => event.data.isCity,
  checkingIfProductIsInWave: (ctx, event) => {
    const auditingItemsSkus = ctx.auditingItems!.map(ai => ai.product.sku);
    return auditingItemsSkus!.includes(event.data.sku);
  },
  itemHasFullAudited: (ctx, _event) => {
    const auditItem = ctx.auditingItems?.find(
      item => item.product.sku === ctx.currentProduct!.sku
    );

    return auditItem?.auditedQuantity === auditItem?.expectedQuantity;
  }
};

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { CloseNoveltyContainerContext } from './machine';

type CloseNoveltyContainerServices = MachineOptions<
  CloseNoveltyContainerContext,
  any
>['services'];

export const closeNoveltyContainerServices: CloseNoveltyContainerServices = {
  fetchNoveltyUnloadingContainerByLpn: async (ctx, _evt) => {
    const { data } = await api.get(
      `/novelty-unloading-container-v2/get-lpn/${ctx.containerScanned!.lpn}`
    );
    return {
      noveltyUnloadingContainer: data
    };
  },
  closeNoveltyContainer: async (ctx, _evt) => {
    await api.post(
      `/novelty-unloading-container-v2/close-container-dispatch-process-event/${
        ctx.noveltyUnloadingContainer!.id
      }`,
      null,
      {
        params: {
          event:   'NoveltyCreateContainerToBuffer',
          payload: {
            noveltyUnloadingContainerId: ctx.noveltyUnloadingContainer!.id
          }
        }
      }
    );
  }
};

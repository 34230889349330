import Ink from '@ink';
import React, { FC } from 'react';
import { ProductItem } from '../../../shared/utils/ProductItem';

export const ScanSKU: FC<{
  suggestedItem: ProductItem | null;
  barcodeScanned: (barcode: string) => void;
  barcodeScanUpdate: (barcode: string) => void;
  requestedItem: ProductItem | null;
  identifier: string;
}> = ({
  suggestedItem,
  barcodeScanned,
  requestedItem,
  identifier,
  barcodeScanUpdate
}) => {
  return (
    <>
      <Ink.Chalk bold>Escanee etiqueta del Producto:</Ink.Chalk>

      {suggestedItem && !requestedItem ? (
        <>
          <Ink.Text>
            SKU Sugerido: &nbsp;
            <Ink.Chalk greenBright>
              {suggestedItem.sku + ' - ' + suggestedItem.product?.name}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      {requestedItem ? (
        <>
          <Ink.Text>
            SKU Requerido:
            <Ink.Chalk greenBright>
              {requestedItem.sku + ' - ' + requestedItem.product?.name}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      <Ink.TextInput
        value={identifier}
        onChange={barcodeScanUpdate}
        onSubmit={barcodeScanned}
      />
    </>
  );
};

ScanSKU.displayName = 'ScanSku';

import { MovingToPreSorterProcessStatuses } from '../MovingToPreSorterProcess';
import { MovingToPreSorterProcessStatus } from './MovingToPreSorterProcessStatus';

export class MovingToPreSorterProcessStatusPendingStatus extends MovingToPreSorterProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MovingToPreSorterProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

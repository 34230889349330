import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  NeededBy,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { AuditOrderItem } from './AuditOrderItem';
import { Category } from './Category';
import { Container } from './Container';
import { InventoryAdjustWave } from './InventoryAdjustWave';
import { InventoryCount } from './InventoryCount';
import { InventoryItem } from './InventoryItem';
import { Location } from './Location';
import { Lot } from './Lot';
import { Order } from './Order';
import { OrderBatch } from './OrderBatch';
import { OrderItem } from './OrderItem';
import { PickItem } from './PickItem';
import { PickingProcess } from './PickingProcess';
import { Process } from './Process';
import { Product } from './Product';
import { ProductRestockingLevel } from './ProductRestockingLevel';
import { PutAwayItem } from './PutAwayItem';
import { Receipt } from './Receipt';
import { ReceiptItem } from './ReceiptItem';
import { RestockingItem } from './RestockingItem';
import { SlottingAlgorithm } from './SlottingAlgorithm';
import { TaskAttribute } from './TaskAttribute';
import { TaskBucket } from './TaskBucket';
import { TaskType } from './TaskType';
import { User } from './User';
import { ZoneClassifier } from './ZoneClassifier';
import {
  TaskStatus,
  TaskStatusCancelledStatus,
  TaskStatusCompletedStatus,
  TaskStatusPausedStatus,
  TaskStatusPendingStatus
} from './task-status';
import { TaskStatusDraftStatus } from './task-status/TaskStatusDraftStatus';

export const TaskStatuses = {
  Draft:     'draft',
  Pending:   'pending',
  Completed: 'completed',
  Cancelled: 'cancelled',
  Paused:    'paused'
};

// JOBS

export const JobConsolidation = {
  Classification: 'consolidation-clasification'
};
export const JobUtils = {
  MergeContainers:      'merge-containers',
  CloseTransportOrder:  'close-transport-order',
  PrintPutToWallLabels: 'print-ptw-labels'
};
export const JobOutbound = {
  ...JobConsolidation,
  ...JobUtils
};
export const JobRestocking = {
  ManualRestocking: 'manual-restocking'
};
export const JobUtilities = {
  ...JobRestocking,
  BulkMovement:          'bulk-movement',
  MoveBulk:              'move-bulk',
  LabelDetail:           'label-detail',
  ContainerDetail:       'container-detail',
  LocationDetail:        'location-detail',
  ReportAnomaly:         'report-anomaly',
  CloseNoveltyContainer: 'close-novelty-container',
  PtwPackageInduction:   'ptw-package-induction',
  ProductDetail:         'product-detail'
};
export const JobTypes = {
  ...JobOutbound,
  ...JobUtilities
};

export const MonitorTaskTypes = {
  InboundMoveContainerFromStagingToStorage:
    'inbound-move-container-from-staging-to-storage',
  PalletRestockingTask:       'pallet-restocking-task',
  PalletRestockingOnFootTask: 'pallet-restocking-on-foot-task',
  InventoryAdjustWave:        'inventory-adjust-wave',

  // RECEPCIÓN PROVEEDORES/SUCURSALES
  StandardUnloading:     'standard-unloading',
  Slotting:              'slotting',
  MoveToBuffer:          'move-to-buffer',
  SlottingRollContainer: 'slotting-roll-container',

  // NOVEDADES
  NoveltyUnloading:             'novelty-unloading',
  NoveltyMoveContainerToBuffer: 'novelty-move-container-to-buffer',

  // PICKING
  MiniPickingWave:     'mini-picking-wave',
  MiniMopPickingWave:  'mini-mop-picking-wave',
  MiniPtwPickingWave:  'mini-ptw-picking-wave',
  MidiPickingWave:     'midi-picking-wave',
  // PACKING/AUDITORÍA
  MiniMopAuditing:     'mini-mop-auditing',
  StagingAuditingTask: 'staging-auditing',
  MiniPackingTask:     'mini-packing-task',
  missingItemsMiniMop: 'missing-items-mini-mop',

  // CONSOLIDACIÒN
  ConsolidatingContainersToStagingType:  'consolidate-containers-to-staging',
  // DESPACHO
  DispatchingHDTask:                     'dispatching-hd',
  DispatchingSSTask:                     'dispatching-ss',
  MovePendingDispatchingTask:            'move-pending-dispatching-items',
  PendingDispatchingPickingTask:         'pending-dispatching-picking-task',
  // UTILIDADES
  ResolveMismatchingStagingAuditingTask: 'resolve-mismatching-staging-auditing',
  restockInventory:                      'restocking-inventory'
};

/* 
--- || OUTBOUND || ---
*/

// PICKING
export const PickingTaskTypes = {
  MiniPickingWave:    'mini-picking-wave',
  MiniMopPickingWave: 'mini-mop-picking-wave',
  MiniPtwPickingWave: 'mini-ptw-picking-wave',
  MidiPickingWave:    'midi-picking-wave',
  BigPickingWave:     'big-picking-wave',
  MaxiPickingWave:    'maxi-picking-wave'
} as const;

// AUDITORÍA
export const AuditingTaskTypes = {
  MiniMopAuditing:     'mini-mop-auditing',
  StagingAuditingTask: 'staging-auditing',
  missingItemsMiniMop: 'missing-items-mini-mop'
} as const;

// PACKING
export const PackingTaskTypes = {
  MiniPackingTask: 'mini-packing-task'
} as const;

// CONSOLIDACIÒN
export const ConsolidationTaskTypes = {
  MaxiConsolidateContainersTask:        'maxi-consolidate-containers',
  ConsolidatingContainersToStagingType: 'consolidate-containers-to-staging'
} as const;

// DESPACHO
export const DispatchingTaskTypes = {
  DispatchingHDTask:             'dispatching-hd',
  DispatchingSSTask:             'dispatching-ss',
  MovePendingDispatchingTask:    'move-pending-dispatching-items',
  PendingDispatchingPickingTask: 'pending-dispatching-picking-task'
} as const;

// UTILIDADES
export const UtilsTaskTypes = {
  ManagePrinter:                         'manage-printer',
  TestPrinter:                           'test-printer',
  ResolveMismatchingStagingAuditingTask: 'resolve-mismatching-staging-auditing',
  RestockingUtility:                     'restocking-utility'
} as const;

// NO PDA
export const NoPdaTaskTypes = {
  MiniMoveToPackingLocationTask:       'mini-move-to-packing-location',
  MiniPtwMoveToPtwLocationTask:        'mini-ptw-move-to-ptw-location',
  MiniPtwMoveToSorterExitTask:         'mini-ptw-move-to-sorter-exit',
  MiniMoveToSorterExitTask:            'mini-move-to-sorter-exit',
  MidiMoveToSorterExitTask:            'midi-move-to-sorter-exit',
  MiniMopMoveToSorterExitTask:         'mini-mop-move-to-sorter-exit',
  MiniMopMovingToAuditingLocationTask: 'mini-mop-moving-to-auditing-location',
  MiniMopAuditing:                     'mini-mop-auditing',
  MiniPackingTask:                     'mini-packing-task'
} as const;
export const OutboundTaskTypes = {
  ...PickingTaskTypes,
  ...AuditingTaskTypes,
  ...PackingTaskTypes,
  ...ConsolidationTaskTypes,
  ...DispatchingTaskTypes,
  ...UtilsTaskTypes,
  ...NoPdaTaskTypes
} as const;

/* 
--- || INBOUND || ---
*/

// RECEPCIÓN PROVEEDORES/SUCURSALES
export const ProvidersTaskTypes = {
  StandardUnloading:     'standard-unloading',
  Slotting:              'slotting',
  MoveToBuffer:          'move-to-buffer',
  SlottingRollContainer: 'slotting-roll-container'
  // MoveToBufferTask: 'move-to-buffer-task', ??????
} as const;

// RECEPCIÓN DIRECTA BIG
export const DirectLineTaskTypes = {
  InboundDirectReceptionV0: 'inbound-direct-reception-v0'
} as const;

// RECEPCIÓN SELLERS
export const SellersReceptionTaskTypes = {
  Unloading:          'unloading',
  MovingToPreSorter:  'moving-to-pre-sorter',
  FeedingToSorter:    'feeding-to-sorter',
  MovingToSorterExit: 'moving-to-sorter-exit',
  NoSortableSlotting: 'no-sortable-slotting'
} as const;

export const MiscTaskTypes = {
  InboundMoveContainerFromStagingToStorage:
    'inbound-move-container-from-staging-to-storage',
  PalletRestockingTask:       'pallet-restocking-task',
  PalletRestockingOnFootTask: 'pallet-restocking-on-foot-task'
} as const;

export const NoveltyUnloadingTaskTypes = {
  NoveltyUnloading:             'novelty-unloading',
  NoveltyMoveContainerToBuffer: 'novelty-move-container-to-buffer'
} as const;

export const InboundTaskTypes = {
  ...ProvidersTaskTypes,
  ...DirectLineTaskTypes,
  ...SellersReceptionTaskTypes,
  ...MiscTaskTypes,
  ...NoveltyUnloadingTaskTypes
} as const;

/* 
--- || CHECKS || ---
*/

// CHECK
export const ChecksTaskTypes = {
  restockInventory:    'restocking-inventory',
  ReadjustInventory:   'readjust-inventory',
  countInventory:      'count-inventory',
  InventoryAdjustment: 'inventory-adjustment',
  InventoryCountingV1: 'inventory-counting-v1',
  InventoryCounting:   'inventory-counting',
  MoveItemsV1:         'move-items-v1',
  ChangeItemStatus:    'change-item-status',
  InventoryAdjustWave: 'inventory-adjust-wave'
} as const;

export const WmsAmpliTaskTypes = {
  ...OutboundTaskTypes,
  ...InboundTaskTypes,
  ...ChecksTaskTypes,
  ...JobTypes
} as const;

export const TaskTypesMapped = {
  [WmsAmpliTaskTypes.InboundMoveContainerFromStagingToStorage]:
    'Mover Contenedor Entrante de Área de Preparación a Almacenamiento',
  [WmsAmpliTaskTypes.PalletRestockingTask]:        'Tarea de Reposición de Pallet',
  [WmsAmpliTaskTypes.MiniPtwMoveToSorterExitTask]:
    'Mover a la Salida del Sorter (Tarea Mini PTW)',
  [WmsAmpliTaskTypes.NoveltyUnloading]:             'Recepción Novedades',
  [WmsAmpliTaskTypes.NoveltyMoveContainerToBuffer]:
    'Mover Contenedor de Novedades a Zona de Buffer',
  [WmsAmpliTaskTypes.StandardUnloading]:        'Recepción',
  [WmsAmpliTaskTypes.Slotting]:                 'Guardado',
  [WmsAmpliTaskTypes.MoveToBuffer]:             'Traslado a Mezzanine',
  [WmsAmpliTaskTypes.SlottingRollContainer]:    'Guardado en estantería',
  [WmsAmpliTaskTypes.InboundDirectReceptionV0]:
    'Recepción Directa de Entrada (Versión 0)',
  [WmsAmpliTaskTypes.Unloading]:          'Recepción XD',
  [WmsAmpliTaskTypes.MovingToPreSorter]:  'Traslado a inducción',
  [WmsAmpliTaskTypes.FeedingToSorter]:    'Inducción Sorter',
  [WmsAmpliTaskTypes.MovingToSorterExit]: 'Movimiento a Salida del Sorter',
  [WmsAmpliTaskTypes.NoSortableSlotting]:
    'Ubicación en Estantería No Clasificable',
  [WmsAmpliTaskTypes.MiniPickingWave]:                      'Picking Mini',
  [WmsAmpliTaskTypes.MiniMopPickingWave]:                   'Picking Mini-MOP',
  [WmsAmpliTaskTypes.MiniPtwPickingWave]:                   'Picking Mini-PTW',
  [WmsAmpliTaskTypes.MidiPickingWave]:                      'Picking Midi',
  [WmsAmpliTaskTypes.BigPickingWave]:                       'Picking Big',
  [WmsAmpliTaskTypes.MaxiPickingWave]:                      'Picking Maxi',
  [WmsAmpliTaskTypes.MiniMopAuditing]:                      'Auditoría Mini-MOP',
  [WmsAmpliTaskTypes.StagingAuditingTask]:                  'Auditoría de Canal',
  [WmsAmpliTaskTypes.MiniPackingTask]:                      'Packing Mini',
  [WmsAmpliTaskTypes.MaxiConsolidateContainersTask]:        'Consolidación Maxi',
  [WmsAmpliTaskTypes.ConsolidatingContainersToStagingType]:
    'Traslado de rampa a canal',
  [WmsAmpliTaskTypes.DispatchingHDTask]:             'Despacho HD',
  [WmsAmpliTaskTypes.DispatchingSSTask]:             'Despacho Sucursal',
  [WmsAmpliTaskTypes.MovePendingDispatchingTask]:    'Traslado remanente sucursal',
  [WmsAmpliTaskTypes.PendingDispatchingPickingTask]:
    'Picking remanente sucursal',
  [WmsAmpliTaskTypes.ManagePrinter]:                         'Gestionar Impresora',
  [WmsAmpliTaskTypes.TestPrinter]:                           'Probar Impresora',
  [WmsAmpliTaskTypes.ResolveMismatchingStagingAuditingTask]:
    'Resolución de Descuadre',
  [WmsAmpliTaskTypes.RestockingUtility]:   'Utilidad de Reposición',
  [WmsAmpliTaskTypes.restockInventory]:    'Reposición de Inventario',
  [WmsAmpliTaskTypes.ReadjustInventory]:   'Utilidad Ajuste de Inventario',
  [WmsAmpliTaskTypes.countInventory]:      'Utilidad Conteo de Inventario',
  [WmsAmpliTaskTypes.InventoryAdjustment]: 'Utilidad Ajuste de Inventario',
  [WmsAmpliTaskTypes.InventoryCountingV1]:
    'Utilidad Conteo de Inventario (Versión 1)',
  [WmsAmpliTaskTypes.InventoryCounting]:             'Utilidad Conteo de Inventario',
  [WmsAmpliTaskTypes.MoveItemsV1]:                   'Mover Artículos (Versión 1)',
  [WmsAmpliTaskTypes.ChangeItemStatus]:              'Cambiar Estado del Artículo',
  [WmsAmpliTaskTypes.MiniMoveToPackingLocationTask]:
    'Mover a Mesa de Packing Mini',
  [WmsAmpliTaskTypes.MiniPtwMoveToPtwLocationTask]:        'Mover a Estación de PTW',
  [WmsAmpliTaskTypes.MiniMoveToSorterExitTask]:            'Mover a Salida del Sorter',
  [WmsAmpliTaskTypes.MidiMoveToSorterExitTask]:            'Mover a Salida del Sorter',
  [WmsAmpliTaskTypes.MiniMopMoveToSorterExitTask]:         'Mover a Salida del Sorter',
  [WmsAmpliTaskTypes.MiniMopMovingToAuditingLocationTask]:
    'Mover a Mesa Auditoría Mini-MOP',
  [WmsAmpliTaskTypes.InventoryAdjustWave]: 'Ola de Ajuste de Inventario'
};

export const TaskTypes = {
  ...WmsAmpliTaskTypes
} as const;

export const PullTaskTypes = [
  // TaskTypes.MoveContainerToStorage,
  // TaskTypes.ConsolidateContainersV0,
  // TaskTypes.WeightContainersV0,
  // TaskTypes.MoveContainersToGrid3,
  // TaskTypes.FractionateContainer,
  // TaskTypes.DownloadContainer,
  // TaskTypes.UploadContainer,
  // TaskTypes.RestockInventoryV0
];

export type ProductItem =
  | ReceiptItem
  | PutAwayItem
  | InventoryItem
  | PickItem
  | OrderItem;

export const ProductItemName = {
  ReceiptItem:   'ReceiptItem',
  PutAwayItem:   'PutAwayItem',
  InventoryItem: 'InventoryItem',
  PickItem:      'PickItem',
  OrderItem:     'OrderItem'
} as const;

type EmptyPayload = Record<string, never>;

export type WmsAmpliTaskType =
  (typeof WmsAmpliTaskTypes)[keyof typeof WmsAmpliTaskTypes];

export type NoveltyUnloadingTaskType =
  (typeof NoveltyUnloadingTaskTypes)[keyof typeof NoveltyUnloadingTaskTypes];

export type StringTaskType = NoveltyUnloadingTaskType | WmsAmpliTaskType;

export interface CreateContainerReceiptPayload {
  providerName?: string;
  checkDigit: string;
  stagingId?: number;
  stagingName?: string;
  dockId?: number;
  dockName?: string;
  receiptType?: string;
  pendingZone?: ZoneClassifier;
  pendingCategory?: Category;
}

export interface ReceiptTakingPayload {
  receipt: Receipt;
  providerName?: string;
  dockId?: number;
  dockName?: string;
  stagingId?: number;
  stagingName?: string;
  skuCount?: number;
}

export interface PlaceItemsInContainerReceiptPayload {
  receiptItem: ReceiptItem;
  checkDigit: string;
  stagingId: number;
  stagingName: string;
  receiptType?: string;
}

export interface MoveContainerReceiptPayload {
  containerToMove: Container;
  locationId: number;
  locationName: string;
  receiptType?: string;
  dockName?: string;
}

export interface ConfirmReceiptPayload {
  checkDigit: string;
  stagingId: number;
  itemCount: { processedItems: number; totalItems: number };
  receiptType?: string;
}
export interface ConfirmMoveContainerReceiptPayload {
  containerCount: { processedContainers: number; totalContainers: number };
  receiptType?: string;
}

export interface BatchPickingPayload {
  routeId: number;
  routeName: string;
}

export interface CreatePickingContainerPayload {
  pickingProcessId: number;
  pickingProcess: PickingProcess;
}

export interface PickingWavePayload {
  orderId: number;
  predispatchContainerLpn: string;
  level?: number;
  pickingWaveId: number;
  orderBatchId?: number;
  deliveryType?: string;
}

export interface AuditingWavePayload {
  auditingWaveId: number;
}

export interface PackingPayload {
  packingWaveId: number;
  packingLocationId: number;
}

export interface MoveToPackingLocationPayload {
  packingLocationProcessId: number;
}

export interface MovingToAuditingLocationPayload {
  movingToAuditingLocationProcessId: number;
}

export interface ContainersReadyToConsolidatePayload {
  consolidateContainersProcessId?: number;
  consolidateContainersItemId?: number;
  consolidateContainersWaveId?: number;
}

export interface MoveToSorterExitPayload {
  sorterExitProcessId: number;
}

export interface PlaceItemsInContainerOrderPayload {
  pickItem: PickItem;
  pickingWaveId: number;
  dispatchContainerId: number;
  dispatchContainer: Container;
  orderId: number;
  pickItemId: number;
  orderItem?: OrderItem;
  routeId?: number;
  routeName?: string;
}

export interface MoveContainerToStoragePayload {
  container: Container;
  suggestLocation: boolean;
}

export interface MoveContainersToGrid3Payload {
  suggestLocation: boolean;
  orderId: number;
}

export interface MoveContainerFromCrossDockOrderPayload {
  container: Container;
  orderId: number;
}

export interface MissingItemsCheckPayload {
  sku: string;
  warehouseId: number;
}

export interface ConfirmPreparationOrderPayload {
  orderId: number;
}

export interface AuditOrderItemPayload {
  auditOrderItem: AuditOrderItem;
  pickItems: PickItem[];
  orderItem: OrderItem;
  orderItemId: number;
  routeId: number;
  routeName: string;
  destination?: string;
  auditId: number;
  orderId: number;
  anomaliesQuantity?: number;
}

export interface SearchInventoryItemPayload {
  inventoryItemId: number;
  wmsOrderItemId: string;
  lastKnownLocationName: string;
  auditOrderItemId: number;
  orderItemId?: number;
  pickItemId: number;
  destinationName: string;
  orderId: number;
}

export interface ConfirmAuditOrderPayload {
  routeId: number;
  routeName: string;
  auditId: number;
  orderId: number;
}

export interface ReceiptContainerQuantityPayload {
  checkDigit: number;
  receiptId: number;
  locationId: number;
  locationName: string;
}
export interface DispatchItemOrderPayload {
  pickItem: PickItem;
  orderItem: OrderItem;
  container: Container;
  orderId: number;
  destination?: string;
  routeName?: string;
}

export interface ConfirmDispatchOrderPayload {
  totalItems: number;
  processedItems: number;
  orderId: number;
  order: Order;
}

export interface MoveItemLogisticPayload {
  inventoryItem: InventoryItem;
  destination: Location;
}

export interface MissingItemsMiniMopPayload {
  mopAuditingPickingWaveId: number;
  auditingWaveId: number;
  pickingWaveId: number;
  packingStationId: number;
}

export interface InventoryAdjustWavePayload {
  inventoryAdjustWaveId: number;
  taskName: string;
}

export interface SortCrossDockItemLogisticPayload {
  location: Location;
  pickItem: PickItem;
  orderId: number;
  routeId: number;
  container: Container;
}

export interface MoveContainerLogisticPayload {
  container: Container;
  containerId?: number;
  destination: Location;
}

export interface MoveContainerToStagingPayload {
  orderId: number;
  container: Container;
  destination: Location;
}

export interface RestockInventoryPayload {
  productRestockingLevelId: number;
  productRestockingLevel: ProductRestockingLevel;
  sourceInventoryItems: InventoryItem[];
}

export interface RestockingPayload {
  inventoryItem: InventoryItem;
  container: Container;
  lot: Lot;
  product: Product;

  // 3 fields opcionales, solo puede existir 1 (UNO) solo dentro del payload

  // Si es restocking
  location?: Location;

  // Si es reajuste orden grande
  pickingProcessId?: number;

  // Si es reajuste PW
  pickingWaveId?: number;

  destinationContainer?: Container;
}

export interface RestockingPalletPayload {
  restockingItemId: number;
  originLocation: Location;
  product: Product;
  location: Location;
}

export interface UploadDownloadContainerPayload {
  containerId: number;
  containerLpn: string;
  locationName: string;
}

export interface InventoryCountPayload {
  inventoryItem: InventoryItem;
  inventoryCount: InventoryCount;
  inventoryCountId: number;
}

export interface ReplenishmentLogisticPayload {
  container: Container;
  containerId: number;
}

export interface FractioningContainerPayload {
  containerId: number;
  receiptId: number;
  containerLpn: string;
}

export interface ConsolidationPayload {
  consolidatingToStagingContainerId: number;
  slotPosition: number;
  lpn: string;
}

export interface UnloadingPayload {
  unloadingProcessId: number;
}

export interface MovingToPreSorterPayload {
  movingToPreSorterProcessId: number;
}

export interface FeedingToSorterPayload {
  feedingToSorterProcessId: number;
  feedingToSorterContainerId: number;
}

export interface MovingToSorterExitPayload {
  movingToSorterExitProcessId: number;
}
export interface NoSortableSlottingPayload {
  noSortableSlottingProcessId: number;
}

export interface StagingAuditingPayload {
  stagingAuditingWaveId: number;
  stageName: string;
}

export interface MismatchAuditingPayload {
  orderId: number;
  stagingAuditingWaveId: number;
}

export interface DispatchingPayload {
  dispatchingWaveId: number;
  stagingId: number;
  stagingName: string;
  destination?: string;
}

export interface MovePendingPayload {
  movePendingDispatchingItemsWaveId: number;
}

export interface NoveltyUnloadingPayload {
  noveltyUnloadingProcessId: number;
  receiptTypeName: string;
  documentNumber: string;
}

export interface NoveltyMoveContainerToBufferPayload {
  noveltyContainerMoveToBufferContainerLpn: string;
}
export interface StandardUnloadingPayload {
  standardUnloadingProcessId: number;
  receiptTypeName: string;
  documentNumber: string;
}

export interface SlottingPayload {
  slottingItemId: number;
  slottingContainerId: number;
  containerLpn: string;
}
export interface MoveToBufferPayload {
  moveToBufferItemId: number;
  containerLocationId: number;
  containerLocationName: string;
}

export interface SlottingRollContainerPayload {
  slottingContainerId: number;
  containerLpn: string;
  containerLocationName: string;
}

export type ReceiptTaskPayload =
  | CreateContainerReceiptPayload
  | ReceiptContainerQuantityPayload
  | ReceiptTakingPayload
  | PlaceItemsInContainerReceiptPayload
  | MoveContainerReceiptPayload
  | ConfirmReceiptPayload
  | ConfirmMoveContainerReceiptPayload
  | RestockingPayload;

export type OrderTaskPayload =
  | CreatePickingContainerPayload
  | PickingWavePayload
  | AuditingWavePayload
  | PackingPayload
  | MoveToPackingLocationPayload
  | MoveToSorterExitPayload
  | ContainersReadyToConsolidatePayload
  | MoveContainerFromCrossDockOrderPayload
  | ConfirmPreparationOrderPayload
  | AuditOrderItemPayload
  | SearchInventoryItemPayload
  | ConfirmAuditOrderPayload
  | DispatchItemOrderPayload
  | ConfirmDispatchOrderPayload
  | MoveContainerToStagingPayload
  | MovingToAuditingLocationPayload;

export type LogisticTaskPayload =
  | MoveItemLogisticPayload
  | MoveContainerLogisticPayload
  | SortCrossDockItemLogisticPayload
  | ReplenishmentLogisticPayload
  | RestockingPalletPayload
  | UploadDownloadContainerPayload;

export type TaskPayload =
  | ReceiptTaskPayload
  | OrderTaskPayload
  | LogisticTaskPayload
  | RestockInventoryPayload
  | InventoryCountPayload
  | MissingItemsCheckPayload
  | ConsolidationPayload
  | UnloadingPayload
  | MovingToPreSorterPayload
  | FeedingToSorterPayload
  | MovingToSorterExitPayload
  | NoSortableSlottingPayload
  | DispatchContainersV0Payload
  | StagingAuditingPayload
  | DispatchingPayload
  | StandardUnloadingPayload
  | SlottingPayload
  | MoveToBufferPayload
  | SlottingRollContainerPayload
  | MovePendingPayload
  | NoveltyUnloadingPayload
  | NoveltyMoveContainerToBufferPayload
  | MissingItemsMiniMopPayload
  | MissingItemsMiniMopPayload
  | InventoryAdjustWavePayload;

export type DispatchContainersV0Payload = {
  order: Order;
};

export interface AuditContainersV0Payload {
  orderId: number;
  routeId: number;
  auditId: number;
  routeName: string;
  containersToAudit: Container[];
  auditLocationId: number;
  auditLocation: Location;
}

export type MoveContainerV0Payload = {
  currentContainer: Container;
  containersToMove: Container[];
  movedContainers: Container[];
  slottingAlgorithm: SlottingAlgorithm;
  targetLocation: Location;
  hint: string;
  error: string;
};

export type PutAwayContainersV0Payload = {
  receipt: Receipt;
  receiptContainers: Container[];
  putAwayContainers: Container[];
  currentContainer: Container;
  fromLocation: Location;
  dropoutLocation: Location;
  slottingAlgorithm: SlottingAlgorithm;
};

export type MoveItemsV0Payload = {
  fromLocation: Location | null;

  // Any of these 4
  slottingAlgorithm: SlottingAlgorithm | null;
  suggestedLocation: Location | null;
  requiredLocation: Location | null;
  location: Location | null;

  // Any of these 3.
  requestedItem: Container | null;
  suggestedItem: Container | null;
  validItems: Container[];
  item: Container | null;
  validator: null;

  hint: string | null;
  error: string | null;
  quantity: number;
};

export type MoveContainersV0Payload = {
  currentItem: ProductItem | null;
  itemsToMove: ProductItem[];
  movedItems: ProductItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type CreateContainersV0Payload = {
  currentContainer: Container | null;
  containersToCreate: Container[];
  createdContainers: Container[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type CreateContainerV0Payload = {
  currentContainer: Container | null;
  containersToCreate: Container[];
  createdContainers: Container[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type VerifyAndPalletizeItemsV0Payload = {
  receipt: Receipt | null;
  containersToCreate: any[];
  receiptContainers: Container[];
  receiptItems: ReceiptItem[];
  putAwayItems: PutAwayItem[];
  putAwayContainers: Container[];
  currentItem: null;
  currentContainer: Container | null;
  dropoutLocation: Location | null;
  moveContainer: null;
  slottingAlgorithm: SlottingAlgorithm | string | null;
};

export type CreateItemPayload = {
  currentItem: ProductItem | null;
  itemsToCreate: ProductItem[];
  createdItems: ProductItem[];
  targetLocation: Location | null;
  hint: string | null;
  error: string | null;
};

export type InventoryCountingPayload = {
  locationName: string;
  containerLpn: string;
  sku: string;
  lotNumber: string;
  lot: Lot;
  inventoryCountingItemId: number;
  productName: string;
  // Only used for showing tasks
  inventoryCountingId: number;
};

// @TODO
export type ConsolidateContainersV0Payload = EmptyPayload;

// @TODO
export type PickOrderV0Payload = EmptyPayload;

// @TODO
export type PickAndSortV0Payload = EmptyPayload;

export type NewTaskPayloads =
  | PutAwayContainersV0Payload
  | VerifyAndPalletizeItemsV0Payload
  | PickOrderV0Payload
  | PickAndSortV0Payload
  | MoveContainerV0Payload
  | MoveItemsV0Payload
  | CreateContainersV0Payload
  | CreateContainerV0Payload
  | ConsolidateContainersV0Payload
  | CreateItemPayload
  | MoveContainerToStoragePayload
  | MoveContainersToGrid3Payload;

export type RestockingTaskTypePayload = RestockInventoryPayload;

@Entity()
export class Task {
  @PrimaryKey()
  id: number;

  @Status({
    [TaskStatuses.Draft]:     TaskStatusDraftStatus,
    [TaskStatuses.Pending]:   TaskStatusPendingStatus,
    [TaskStatuses.Cancelled]: TaskStatusCancelledStatus,
    [TaskStatuses.Completed]: TaskStatusCompletedStatus,
    [TaskStatuses.Paused]:    TaskStatusPausedStatus
  })
  status: TaskStatus;

  @Field({ name: 'assigned_at' })
  assignedAt: Date;

  payload: TaskPayload;

  @Field()
  type: StringTaskType;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @ForeignKey(() => User, { name: 'user_id' })
  userId: number;

  @ForeignKey(() => User, { name: 'receipt_id' })
  receiptId: number;

  @ForeignKey(() => User, { name: 'order_id' })
  orderId: number;

  @ForeignKey(() => User, { name: 'order_batch_id' })
  orderBatchId: number;

  @ForeignKey(() => TaskBucket, { name: 'task_bucket_id' })
  taskBucketId: number;

  @ForeignKey(() => Process, { name: 'process_instance_id' })
  processInstanceId: number;

  @ForeignKey(() => TaskType, { name: 'task_type_id' })
  taskTypeId: number;

  @Knows(() => User, 'userId')
  user: User;

  @Knows(() => Receipt, 'receiptId')
  receipt: Receipt;

  @Knows(() => Order, 'orderId')
  order: Order;

  @Knows(() => OrderBatch, 'orderBatchId')
  orderBatch: OrderBatch;

  @Needs(() => TaskBucket, 'taskBucketId')
  taskBucket: TaskBucket;

  @Knows(() => Process, 'processInstanceId')
  processInstance: Process;

  @Knows(() => RestockingItem, 'taskId')
  restockingItem: RestockingItem;

  // Knows relation in order to be compatible with existing string types.
  @Knows(() => TaskType, 'taskTypeId')
  taskType: TaskType;

  @NeededBy(() => TaskAttribute, 'taskId')
  taskAttributes: TaskAttribute[];

  @Knows(() => InventoryAdjustWave, 'taskId')
  inventoryAdjustWave: InventoryAdjustWave;

  getProcessType() {
    const process = this.type.split('-')[0];
    return process as 'receipt' | 'order' | 'logistic' | 'restocking' | 'count';
  }
}

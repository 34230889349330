import { MachineOptions, assign } from 'xstate';
import { PickPendingDispatchingTaskContext } from './machine';

type PickPendingDispatchingTaskActions = MachineOptions<
  PickPendingDispatchingTaskContext,
  any
>['actions'];

export const pickPendingDispatchingTaskActions: PickPendingDispatchingTaskActions =
  {
    setPickingDispatchingLocation: assign({
      pickingDispatchingLocation: (_, event) => event.data
    }),
    assignPendingAndTotalItems: assign({
      totalItems:   (_ctx, event) => event.data.totalItems,
      pendingItems: (_ctx, event) => event.data.pendingItems
    }),
    assignCurrentPickItem: assign({
      currentPickItem: (_ctx, event) => event.data
    }),
    assignStagingLocation: assign({
      stagingLocation: (_ctx, event) => event.data
    })
  };

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { StagingAuditingContext } from './machine';

type StagingAuditingGuardsType = MachineOptions<
  StagingAuditingContext,
  any
>['services'];

export const StagingAuditingServices: StagingAuditingGuardsType = {
  fetchStagingLocation: async (ctx, _evt) => {
    const { data } = await api.get('location/find-any-location', {
      params: {
        locationName: ctx.stageName
      }
    });

    return {
      stagingLocation: data
    };
  },
  fetchStagingAuditingContainersCount: async (ctx, _evt) => {
    const { data } = await api.get('staging-auditing-container/count/pending', {
      params: {
        stagingAuditingWaveId: ctx.stagingAuditingWaveId
      }
    });

    return {
      containersToAudit: data.containersToAudit,
      containersAudited: data.containersAudited,
      totalContainers:   data.totalContainers
    };
  },
  fetchMismatchedContainersCount: async (ctx, _evt) => {
    const { data } = await api.get(
      'staging-auditing-container/count/mismatched',
      {
        params: {
          stagingAuditingWaveId: ctx.stagingAuditingWaveId
        }
      }
    );
    return {
      containersMismatched: data
    };
  },
  checkIfHasStagingAuditingContainer: async (ctx, _evt) => {
    const { data } = await api.get('staging-auditing-container/container', {
      params: {
        containerId:           ctx.auditingContainer!.id,
        stagingAuditingWaveId: ctx.stagingAuditingWaveId
      }
    });

    return {
      stagingAuditingContainer: data
    };
  }
};

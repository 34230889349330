import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { MovePendingDispatchingItemsContext } from './machine';

type MovePendingDispatchingItemsActions = MachineOptions<
  MovePendingDispatchingItemsContext,
  any
>['actions'];

export const movePendingDispatchingItemsMachineActions: MovePendingDispatchingItemsActions =
  {
    assignStagingLocation: assign({
      stagingLocation: (_, event) => event.data.stagingLocation
    }),
    assignPendingDispatchingItemsToMove: assign({
      totalPendingDispatchingItemsToMove: (_, event) =>
        event.data.totalPendingDispatchingItemsToMove,
      pendingPendingDispatchingItemsToMove: (_, event) =>
        event.data.pendingPendingDispatchingItemsToMove,
      suggestedNextPendingDispatchingItemToMove: (_, event) =>
        event.data.suggestedNextPendingDispatchingItemToMove
    }),
    assignMovePendingDispatchingItemContainer: assign({
      movePendingDispatchingItemContainer: (_, event) => event.data
    }),
    assignPendingDispatchingItemToMove: assign({
      pendingDispatchingItemToMove: (_, event) =>
        event.data.pendingDispatchingItemToMove
    }),
    assignDestinyLocation: assign({
      destinyLocation: (_, event) => event.data
    }),
    assignError: assign({
      error: (_, event) =>
        getErrorView(
          event.data?.data ?? event.data,
          'Ocurrió un error, por favor reintente más tarde.'
        )
    }),
    clearError: assign({
      error: (_, __) => null
    })
  };

import { Container, NoveltyUnloadingContainerV2 } from '@wms/domain/.';
import { createMachine } from 'xstate';

import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { closeNoveltyContainerActions } from './actions';
import { closeNoveltyContainerGuards } from './guards';
import { closeNoveltyContainerServices } from './services';

export interface CloseNoveltyContainerContext {
  error: string | null;
  containerScanned: Container | null;
  noveltyUnloadingContainer: NoveltyUnloadingContainerV2 | null;
}

export const closeNoveltyContainerContext: CloseNoveltyContainerContext = {
  error:                     null,
  containerScanned:          null,
  noveltyUnloadingContainer: null
};

export const CloseNoveltyContainerMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCECGswDoBiYAuAxgBYAqATqgHawAOA9mXgPJkRhnICeAMqgEZgANgGIIdSlgCWlAG50A1llyFSFavUYs2HHvyEBtAAwBdRKHqxJeSeLMgAHogCsAJkOYXANgAsAZgCMTt7eABzehv4AnAA0IJyIALSRAOyYycmGTpGhhtn+Lv6eAL5FsWgYmADKRHQA7tJQ5FS0DMys7ACSlABmdMIAwoJ0GE3qrVrsRqZIIBZWNpR2jghOni5p-lE+-obJXiEusfEIIe6R5+fJ3i43m-4hJWXoWIPDDaMtmu1kouJSsgoXkNLJRGmpPm1tFM7HNrLYZssAphfCEDgcnCFkvcXCEnMkjogXL51pEsp4IoYKWEnI8QOUgW9QR8NJD2L8JJhpHJFJhXiCwc0WRMyPp-NNzG84YsEYhPOTMDtid5Nj4UpFDnFCTsPL5fIZgr5IoZDYYXLT6ZhmeNvnzIMIyHB8NCZrCFktEH4QgrdplsqifMaCQhUi5IlFMuGMckwpkSqUQJQ6Gx4DN6TDJW6ZQgEoVMJ5NlirvkQv4sX4gznMZgguE-Oro1Fkubnjh8MQrV9tFxeAJBOnLFL3QhvKlPL5grjPCElYUQhWdu59bXDS4GylmxVqnV3uChd8ur1+-N4aBlur-B5Tb6XNdcYUK040oanKXS-ro+TPDT4xa+TvBda2hHoOWbjpgYakjkqrTiOQYjuBFyRNOuxEiWvgblgHasmQtoQMBmanog+TeJghjkvcE4hAGoZBrqF6GLipI3J6OzpHGRRAA */
    id:                         'CloseNoveltyContainerMachine',
    predictableActionArguments: true,
    schema:                     {
      context: {} as CloseNoveltyContainerContext
    },
    initial: 'ScanningContainer',
    states:  {
      ScanningContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: GetContainerByLpnMachine(
            'Escanee contenedor',
            getAnyContainerByLpn('Ocurrio un error, por favor intente de nuevo')
          ),
          onDone: {
            target:  'FetchNoveltyUnloadingContainerByLpn',
            actions: ['assignContainer', 'clearError']
          }
        }
      },
      FetchNoveltyUnloadingContainerByLpn: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchNoveltyUnloadingContainerByLpn',
          onDone: {
            target:  'ShowingContainerInfo',
            actions: 'assignNoveltyUnloadingContainer'
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },
      ShowingContainerInfo: {
        on: {
          CloseNoveltyContainer: {
            target:  'ClosingNoveltyContainer',
            actions: 'clearError'
          }
        }
      },
      ClosingNoveltyContainer: {
        invoke: {
          src:    'closeNoveltyContainer',
          onDone: {
            target:  'ContainerClosed',
            actions: 'clearError'
          },
          onError: {
            target:  'ScanningContainer',
            actions: 'assignError'
          }
        }
      },
      ContainerClosed: {
        on: {
          reset: 'ScanningContainer'
        }
      }
    }
  },
  {
    guards:   closeNoveltyContainerGuards,
    actions:  closeNoveltyContainerActions,
    services: closeNoveltyContainerServices
  }
);

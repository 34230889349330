import { NoSortableSlottingProcessStatuses } from '../NoSortableSlottingProcess';
import { NoSortableSlottingProcessStatus } from './NoSortableSlottingProcessStatus';

export class NoSortableSlottingProcessStatusPendingStatus extends NoSortableSlottingProcessStatus {
  public displayName = 'Pendiente';

  public internalName = NoSortableSlottingProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

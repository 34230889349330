import { MachineOptions, assign } from 'xstate';
import { ResolveMismatchedAuditingWaveContext } from './machine';

type ResolveMismatchedAuditingWaveActionsType = MachineOptions<
  ResolveMismatchedAuditingWaveContext,
  any
>['actions'];

export const ResolveMismatchedAuditingWaveActions: ResolveMismatchedAuditingWaveActionsType =
  {
    assignStagingAuditingItems: assign({
      stagingAuditingItems: (_ctx, event) => event.data.stagingAuditingItems
    }),
    assignOrderType: assign({
      orderType: (_ctx, event) => event.data.orderType
    })
  };

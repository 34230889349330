import { ImpureSortableCrossdockClassificationContainerStatuses } from '../ImpureSortableCrossdockClassificationContainer';
import { ImpureSortableCrossdockClassificationContainerStatus } from './ImpureSortableCrossdockClassificationContainerStatus';

export class ImpureSortableCrossdockClassificationContainerStatusClosedStatus extends ImpureSortableCrossdockClassificationContainerStatus {
  public displayName = 'Cerrado';

  public internalName =
    ImpureSortableCrossdockClassificationContainerStatuses.Closed;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../../modules/shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { ScanLabel } from '../../capa-4/scan-label/component';
import { LogisticOperator } from '../../core/order-logistic-operator-info';
import { WarehouseInfo } from '../../core/order-warehouse-info';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { ConsolidationClasificationMachine } from './machine';

export const ConsolidationClasificationComponent = withActorRef(
  ConsolidationClasificationMachine
)(({ actorRef }) => {
  const [current, send] = useActor(actorRef);

  const shouldRenderLabelInfo =
    !!current.context.label &&
    !current.hasTag('loading') &&
    (current.matches('ScanningChosenContainer') ||
      current.matches('LocatingContainer'));

  const shouldRenderContainerInfo =
    (!!current.context.classificationContainer?.container?.lpn ||
      !!current.context.newContainerLabel) &&
    !current.hasTag('loading') &&
    (current.matches('ScanningChosenContainer') ||
      current.matches('LocatingContainer'));

  return (
    <Ink.Box flexDirection='column' paddingX={1}>
      <Ink.Header>
        <Ink.Text bold>
          Clasificación en Rampa:{' '}
          {current.context.classificationItemLocation?.name || ''}
        </Ink.Text>
      </Ink.Header>
      {current.context.classificationItemWarehouse ? (
        <WarehouseInfo
          warehouse={current.context.classificationItemWarehouse}
          location={current.context.classificationItemLocation}
        />
      ) : current.context.classificationItemRoute ? (
        <LogisticOperator
          orderType={current.context.classificationItemRoute.order.orderType}
        />
      ) : null}

      {shouldRenderLabelInfo ? (
        <HighlightedInfo
          attribute='Bulto'
          value={current.context.classificationItem!.label}
        />
      ) : null}

      {shouldRenderContainerInfo ? (
        <HighlightedInfo
          attribute='Contenedor'
          value={
            current.context!.newContainerLabel ||
            current.context.classificationContainer?.container?.lpn ||
            'asd'
          }
        />
      ) : null}

      {!current.matches('ScanningLabel') ? <Ink.Text> </Ink.Text> : null}

      {current.matches('ScanningLabel') ? (
        <>
          <HighlightedInfo
            attribute='Escanee Bulto'
            value={current.context.classificationItem?.label || ''}
          />
          <ScanLabel />
          <Ink.Text>
            <Ink.Chalk redBright bold>
              {current.context.error || ''}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      {current.matches('Continue') ? (
        <>
          <Ink.Text>
            <Ink.Chalk greenBright bold>
              {current.context.isLastItem
                ? 'Bulto depositado y CONTENEDOR CERRADO AUTOMÁTICAMENTE.'
                : 'Bulto depositado en contenedor correctamente.'}
            </Ink.Chalk>
          </Ink.Text>

          <Ink.Button
            label='CONTINUAR'
            onClick={() => send({ type: 'CONTINUE' })}
          />
        </>
      ) : null}

      {current.matches('ScanningContainer') ? (
        <>
          <GetContainerByLpn id='ScanningContainer' />
          {current.context.error && (
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
          )}
        </>
      ) : null}

      {current.matches('ChoosingContainerType') ? (
        <>
          <Ink.Text> Seleccione tipo de contenedor a crear</Ink.Text>
          <Ink.Button
            onClick={() =>
              send({
                type: 'CONTAINER_TYPE_CHOSEN',
                data: { containerTypeName: 'PALLET ARLOG' }
              })
            }
            label='PALLET'
          />
          <Ink.Button
            onClick={() =>
              send({
                type: 'CONTAINER_TYPE_CHOSEN',
                data: { containerTypeName: 'ROLL CONTAINER' }
              })
            }
            label='ROLL CONTAINER'
          />
        </>
      ) : null}

      {current.matches('ScanningChosenContainer') ? (
        <>
          <Ink.Text>
            {' '}
            El bulto escaneado es el 1ero para el destino indicado
          </Ink.Text>
          <Ink.Text bold>Cree/Escanee PALLET ARLOG</Ink.Text>
          <Ink.Text> </Ink.Text>
          <EnterLpn />
          <Ink.Text>
            <Ink.Chalk redBright bold>
              {current.context.error || ''}
            </Ink.Chalk>
          </Ink.Text>
        </>
      ) : null}

      {current.matches('LocatingContainer') ? (
        <>
          <Ink.Text>Ubique el contenedor en una posición</Ink.Text>
          {/* <HighlightedInfo
            attribute='Ubique contenedor en posicion'
            value={current.context.suggestedSlotPosition!}
          /> */}

          <Ink.Button
            onClick={() => send('CONTAINER_LOCATED')}
            label='Contenedor Ubicado'
          />
        </>
      ) : null}

      {current.matches('DepositingInContainer') ? (
        <>
          <HighlightedInfo
            attribute='Deposite bulto'
            value={current.context.classificationItem!.label}
          />

          <HighlightedInfo
            attribute='En contenedor'
            value={current.context.classificationContainer!.container.lpn}
          />

          <GetContainerByLpn id='DepositingInContainer' />
        </>
      ) : null}

      {current.hasTag('loading') ? <Ink.Text> Cargando...</Ink.Text> : null}
    </Ink.Box>
  );
});

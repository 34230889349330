import { MachineOptions } from 'xstate';
import { ProductDetailContext } from './machine';

type productDetailGuardsType = MachineOptions<
  ProductDetailContext,
  any
>['guards'];

export const ProductDetailGuards: productDetailGuardsType = {
  goToScanningSKU: context => {
    return context.alertData.target === 'ScanningSKU';
  }
};

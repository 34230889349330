import { Location } from '@wms/domain';
import { createMachine } from 'xstate';
import { getSortableBulkAtSorterExitByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-sortable-bulk-at-sorter-exit';
import {
  GetItemByLabelMachine,
  GetItemByLabelMachineId
} from '../../layer-4/label/get-item-by-label/machine';
import { getTargetLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-target-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { moveBulksActions } from './actions';
import { moveBulkServices } from './services';

export interface MoveBulksContext {
  label: string | null;
  entity: string | null;
  items: any[] | null;

  newLocation: Location | null;

  error: string | null;
}

export const MoveBulksInitialContext: MoveBulksContext = {
  label:  null,
  entity: null,
  items:  null,

  newLocation: null,

  error: null
};

export const MoveBulkMachine = createMachine<MoveBulksContext>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QHUCGAnMALA9gV1jABUccAbABQEsBjAawDoAZHVCKgOyjU1wONJlYAYiIB5MUwD6FAJIBhANIBtAAwBdRKAAOOWFQAuVHBy0gAHogBsAdgCMDACwAmG6psAOZ47duAzAA0IACeiB4OPgCcdlaOds5+AKxJMQC+qUE82PiEJOTU9AxoBjRYnFB5ZAAiYAaoVGTC8gASAKJKUgBisgAaappIILr6RiZmlgjOiQ42fpGJnt6+qoEhiH52Ngx+Vnbxdo7zibEe6ZkY2fyVBYzFpeWVNXUNwgBCAIId4lIAsq0AcgBVfpmYaGYymQYTDyORwMKyJVThSKxOweY5WIKhSZJJx+Pw2NyJKKqZy2M4gLJ8XKCG4MeRYMD0B6CADKdQMBGE3R6UnEkhBgzBo0hoAmflUDj2jgWXh87hWWPWtgYctsiQ8hI8HkiqkcFKpOQE+VojAZTLoLPI7NQnJEHy+Yl+AOBGlBenBYyh1j8cJszjsvrmkTijlUmLWCD8zmcDBDrjRiI1iRDBou1ONlFNDEqnSo5nKTTE-26ACUfl1eoKdB6ReNEFMZnNZUsFatsbC-PCbLD5lZIm4A4k07wjdds7n84WHYo+U6-kDq0NaxD6wgYXCEUi7Ci4uirBGO54GIiCUkw5LIkl0hkQBwcBA4GZDVdaab3SNV96EABaQ+IP8GFUYDgMRUl8RiSIR0uGkTUKFg2HKF9YKED9PVFCwwmmVUERhQkXCsZwPHbdYEicZwBz1A9dgJGxoIzcdCjuMouEeWp6jINC62-FwlQQXVtj8dEZRsSJIimZI6NvZDMzpc1mVYtkOQILivzFRBHF9eF+zJBZ5X8Pjoy7FxKMcajA0JeixzfQpJwLLhVK9dSEDsJFtjiA53H0lY7EMmwrHhGFVAWRJkisVRxOHG8gA */
    id:                         'MoveBulks',
    initial:                    'ScanningLabel',
    predictableActionArguments: true,
    states:                     {
      ScanningLabel: {
        invoke: {
          id:  GetItemByLabelMachineId,
          src: _ctx =>
            GetItemByLabelMachine(
              'Escanee etiqueta de bulto',
              getSortableBulkAtSorterExitByLabel(
                'Ocurrió un error, por favor reintente más tarde.'
              ),
              { canScanBuckets: true },
              true
            ),
          onDone: {
            actions: ['assignEntity', 'clearError'],
            target:  'FetchingDetail'
          },
          onError: {
            actions: 'assignError',
            target:  'ScanningLabel'
          }
        }
      },
      FetchingDetail: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchItemDetails',
          onDone: {
            actions: 'assignItem',
            target:  'ScanningLocation'
          },
          onError: {
            actions: 'assignError',
            target:  'ScanningLabel'
          }
        }
      },
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: () =>
            GetLocationByNameMachine(
              'Indique nueva ubicación de destino',
              getTargetLocationByName(
                'Ocurrió un error, por favor reintente más tarde.'
              )
            ),
          onDone: {
            target:  'MovingBulk',
            actions: 'assignNewLocation'
          }
        }
      },
      MovingBulk: {
        tags:   ['loading'],
        invoke: {
          src:    'moveBulkToNewLocation',
          onDone: {
            target: 'ConfirmingScreen'
          }
        }
      },
      ConfirmingScreen: {
        on: {
          confirm: 'Done'
        }
      },
      Done: {
        type: 'final'
      }
    }
  },
  {
    services: moveBulkServices,
    actions:  moveBulksActions
  }
);

import { SellerProduct } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetSellerProductByCodeFn } from '../machine';

export function getAnySellerProductByCode(
  defaultErrorMessage: string
): GetSellerProductByCodeFn {
  return fetchResult(defaultErrorMessage, code =>
    api.get<SellerProduct>(`seller-product/get-by-code/${code}`)
  );
}

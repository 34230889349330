import { StagingAuditingWaveStatuses } from '../StagingAuditingWave';
import { StagingAuditingWaveStatus } from './StagingAuditingWaveStatus';

export class StagingAuditingWaveCancelledStatus extends StagingAuditingWaveStatus {
  public displayName = 'Cancelado';

  public internalName = StagingAuditingWaveStatuses.Cancelled;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import Ink from '@ink';
import { InventoryItem } from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { withActorRef } from '../../../shared/Machine';
import { PopUp } from '../../layer-4/pop-up/component';
import { GetProductBySKU } from '../../layer-4/product/get-product-by-sku/component';
import { ProductDetailMachine } from './machine';

export const ProductDetailComponent = withActorRef(ProductDetailMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>Detalle de Producto</Ink.Text>
        </Ink.Header>

        <Ink.Box flexDirection='column' paddingX={1} justifyContent='center'>
          {current.matches('ScanningSKU') && <GetProductBySKU />}

          {current.matches('Detail') && (
            <Ink.Box flexDirection='column' padding={2}>
              <HighlightedInfo
                attribute='SKU'
                value={current.context.product?.sku || ''}
              />
              <HighlightedInfo
                attribute='Descripción'
                value={current.context.product?.name || ''}
              />
              {current.context.inventoryItems?.map(
                ({ location, quantity, status }, index) => (
                  <Ink.Box flexDirection='column' key={index}>
                    &nbsp;
                    <Ink.Chalk color='greenBright' bold>
                      {location.name}
                    </Ink.Chalk>
                    <Ink.Text>
                      Cantidad:&nbsp;
                      <Ink.Chalk>{quantity}</Ink.Chalk>
                    </Ink.Text>
                    <Ink.Text>
                      Calidad:&nbsp;
                      <Ink.Chalk>
                        {InventoryItem.mapStatusName(
                          status as unknown as string
                        )}
                      </Ink.Chalk>
                    </Ink.Text>
                  </Ink.Box>
                )
              )}
              <Ink.Button
                label='Escanear otro producto'
                onClick={() => send('rescan')}
              />
            </Ink.Box>
          )}
        </Ink.Box>
        {current.matches('HandleErrors') && <PopUp />}
      </Ink.Box>
    );
  }
);

import { Product } from '@wms/domain';
import { assign, createMachine } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { API, UtilityActions, UtilityGuards } from '../../../../api/api';

export interface ScanSKUContext {
  product: Product | null;
  identifier: string | null;
  hint: string;
  error: string | null;
  validIdentifiers: string[];
}

export const DefaultScanSKUContext: ScanSKUContext = {
  product:          null,
  identifier:       null,
  hint:             'Escanee SKU/EAN:',
  error:            null,
  validIdentifiers: []
};

export const ScanSKUMachine = createMachine<ScanSKUContext>(
  {
    id:      'ScanSKUMachine',
    initial: 'AwaitingIdentifierScan',
    states:  {
      AwaitingIdentifierScan: {
        on: {
          ChangedIdentifier: {
            actions: assign({
              identifier: (_, event) => event.data
            })
          },
          SubmittedIdentifier: [
            {
              cond:   'isValidIdentifier',
              target: 'FetchingProduct'
            },
            {
              target:  'AwaitingIdentifierScan',
              actions: ['assignInvalidProduct', 'clearInput']
            }
          ]
        }
      },
      FetchingProduct: {
        invoke: {
          src:    'findProduct',
          onDone: {
            actions: assign({ product: (_ctx, event) => event.data.product }),
            target:  'ProductFound'
          },
          onError: {
            actions: ['assignError', 'clearParentError', 'clearInput'],
            target:  'AwaitingIdentifierScan'
          }
        }
      },
      ProductFound: {
        type: 'final',
        data: ctx => ({ product: ctx.product })
      }
    }
  },
  {
    guards: {
      ...UtilityGuards,
      isValidIdentifier: (ctx, _event) => {
        const validSKU = Product.isValidSKU(ctx.identifier);
        if (!validSKU) {
          return false;
        }
        if (!ctx.validIdentifiers.length) {
          return true;
        }
        return validSKU && ctx.validIdentifiers.includes(ctx.identifier!);
      }
    },
    actions: {
      ...UtilityActions,
      clearParentError: sendParent('clearError'),
      clearInput:       assign({
        identifier: _ctx => ''
      }),
      assignInvalidProduct: assign({
        error: _ctx => 'Producto inválido'
      })
    },
    services: { ...API }
  }
);

import { SlottingContainerStatuses } from '../SlottingContainer';
import { SlottingContainerStatus } from './SlottingContainerStatus';

export class SlottingContainerStatusProcessingStatus extends SlottingContainerStatus {
  public displayName = 'Procesando';

  public internalName = SlottingContainerStatuses.Processing;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

import { Product } from '@wms/domain/.';
import { MachineOptions } from 'xstate';
import { GetProductBySKUContext } from './machine';

type GetLocationByNameGuards = MachineOptions<
  GetProductBySKUContext,
  any
>['guards'];

export const getProductBySKUGuards: GetLocationByNameGuards = {
  isInvalidProductSKU: (context, _event) =>
    !Product.isValidSKU(context.productSKU),
  isNotRequiredProductSKU: (ctx, _event) =>
    !!ctx.requiredSKU?.length && !ctx.requiredSKU.includes(ctx.productSKU),
  isSuccess: (_context, event) => event.data.type === 'success'
};

import { MachineOptions } from 'xstate';
import { api } from '../../../../api/utils/axios-instance';
import { MovePendingDispatchingItemsContext } from './machine';

type MovePendingDispatchingItemsServices = MachineOptions<
  MovePendingDispatchingItemsContext,
  any
>['services'];

export const movePendingDispatchingItemsMachineServices: MovePendingDispatchingItemsServices =
  {
    movePendingDispatchingItemsFetchStagingLocation: async (ctx, _event) => {
      const { data } = await api.get(
        `/move-pending-dispatching-items-wave/initialize/${ctx.movePendingDispatchingItemsWaveId}`
      );
      return {
        stagingLocation: data.stagingLocation
      };
    },

    fetchPendingDispatchingItemsToMove: async (ctx, _event) => {
      const { data } = await api.get(
        `/move-pending-dispatching-item/pending-items-to-move/${ctx.movePendingDispatchingItemsWaveId}`
      );
      return {
        totalPendingDispatchingItemsToMove:
          data.totalPendingDispatchingItemsToMove,
        pendingPendingDispatchingItemsToMove:
          data.pendingPendingDispatchingItemsToMove,
        suggestedNextPendingDispatchingItemToMove:
          data.suggestedNextPendingDispatchingItemToMove
      };
    },

    fetchPendingDispatchingItemToMove: async (ctx, _event) => {
      const { data } = await api.get(
        '/move-pending-dispatching-item/get-item-by-container-lpn',
        {
          params: {
            containerLpn: ctx.movePendingDispatchingItemContainer?.lpn
          }
        }
      );
      return {
        pendingDispatchingItemToMove: data.pendingDispatchingItemToMove
      };
    }
  };

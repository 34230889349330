import { NoveltyUnloadingItemV2Statuses } from '../NoveltyUnloadingItemV2';
import { NoveltyUnloadingItemV2Status } from './NoveltyUnloadingItemV2Status';

export class NoveltyUnloadingItemV2StatusUnloadedStatus extends NoveltyUnloadingItemV2Status {
  public displayName = 'Descargado';

  public internalName = NoveltyUnloadingItemV2Statuses.Unloaded;

  public statusColor = '#';
}

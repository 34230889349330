import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { BulkMovementContext } from './machine';

type BulkMovementActionsType = MachineOptions<
  BulkMovementContext,
  any
>['actions'];

export const bulkMovementActions: BulkMovementActionsType = {
  assignOriginLabel: assign({
    originLabel: (_ctx, event) => event.data.lpnOrLocationLabel
  }),
  assignOriginContainer: assign({
    originContainer: (_ctx, event) => event.data.originContainer
  }),
  assignOriginLocation: assign({
    originLocation: (_ctx, event) => event.data.originLocation
  }),
  assignOriginContainerValidatingLocation: assign({
    originContainer: (_ctx, event) => event.data
  }),

  assignProductToMove: assign({
    productToMove: (_ctx, event) => event.data
  }),

  assignQuantity: assign({
    quantity: (_ctx, event) => event.data.quantity
  }),

  assignDestinyLabel: assign({
    destinyLabel: (_ctx, event) => event.data.lpnOrLocationLabel
  }),
  assignDestinyContainer: assign({
    destinyContainer: (_ctx, event) => event.data.destinyContainer
  }),
  assignDestinyLocation: assign({
    destinyLocation: (_ctx, event) => event.data.destinyLocation
  }),
  assignDestinyContainerValidatingLocation: assign({
    destinyContainer: (_ctx, event) => event.data
  }),
  asignItemStatus: assign({
    itemStatus: (_ctx, event) => event.data.itemsStatus
  }),

  assignError: assign({
    error: (_ctx, event) => getErrorView(event.data || '')
  }),
  clearError: assign({
    error: (_ctx, _event) => ''
  }),
  assignInvalidAnomalyContainerLocationError: assign({
    error: (_ctx, _event) =>
      'El contenedor tiene que estar en una ubicacion de anomalia para trasladar productos anomalos'
  }),
  assignInvalidAnomalyLocationError: assign({
    error: (_ctx, _event) =>
      'La ubicacion tiene que ser de anomalia para trasladar productos anomalos'
  }),
  assignDestinyNotAvailableError: assign({
    error: (ctx, event) =>
      `No es posible realizar el movimiento ya que supera la cantidad máxima de esta ubicación. La cantidad a mover es ${ctx.quantity} y la capacidad disponible es ${event.data}`
  }),
  clearDestiny: assign({
    destinyContainer: (_ctx, _event) => null,
    destinyLocation:  (_ctx, _event) => null
  }),

  assingScanningOriginLocationAlertData: assign({
    alertData: ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningOriginLabel'
      };
    }
  }),

  assingScanningDestinyLocationAlertData: assign({
    alertData: ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningDestinyLabel'
      };
    }
  }),

  assingScanningSKUAlertData: assign({
    alertData: ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningSku'
      };
    }
  }),
  assingQuantityAlertData: assign({
    alertData: ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'EnteringQuantity'
      };
    }
  })
};

import { MachineOptions } from 'xstate';
import {
  GetSellerProductByCodeContext,
  GetSellerProductByCodeFn
} from './machine';

type GetSellerProductByCodeServices = MachineOptions<
  GetSellerProductByCodeContext,
  any
>['services'];

export const getSellerProductByCodeServices: (
  fetchFn: GetSellerProductByCodeFn
) => GetSellerProductByCodeServices = fetchFn => ({
  fetchSellerProduct: (context, _event) =>
    fetchFn(context.sellerProductCode.trim())
});

import { MachineOptions } from 'xstate';
import { LabelDetailContext } from './machine';

type labelDetailGuardsType = MachineOptions<LabelDetailContext, any>['guards'];

export const labelDetailGuards: labelDetailGuardsType = {
  isPackageEntity: (ctx, event) => {
    return event.data.entityType === 'package';
  },
  isSortingItemEntity: (ctx, event) => {
    return event.data.entityType === 'sorting-item';
  },
  isMopSorterExitItemEntity: (ctx, event) => {
    return event.data.entityType === 'mop-sorter-exit-item';
  },
  entityTypeIsNull: (ctx, event) => {
    return !!event.data.entityType === false;
  }
};

import { NoveltyUnloadingContainerStatuses } from '../NoveltyUnloadingContainer';
import { NoveltyUnloadingContainerStatus } from './NoveltyUnloadingContainerStatus';

export class NoveltyUnloadingContainerStatusPendingStatus extends NoveltyUnloadingContainerStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyUnloadingContainerStatuses.Pending;

  public statusColor = '#Ffffff';
}

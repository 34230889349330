import { Container, Location, SellerProduct, Task } from '@wms/domain';
import { createMachine } from 'xstate';

import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getFeedingToSorterContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-feeding-to-sorter-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getFeedingToSorterLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-feeding-to-sorter-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { getFeedingToSorterSellerProductByCode } from '../../layer-4/seller-product/get-seller-product-by-code/fetchers/get-feeding-to-sorter-seller-product-by-code';
import {
  GetSellerProductByCodeMachine,
  GetSellerProductByCodeMachineId
} from '../../layer-4/seller-product/get-seller-product-by-code/machine';
import { feedingToSorterTaskActions } from './actions';
import { feedingToSorterTaskGuards } from './guards';
import { feedingToSorterTaskServices } from './services';

export interface FeedingToSorterTaskContext {
  task: Task;
  feedingToSorterProcessId: number;
  feedingToSorterContainerId: number;
  feedingToSorterLocation: Location;
  containerToProcess: Container;
  totalFeedingToSorterItems: number;
  processedFeedingToSorterItems: number;
  sellerProductToProcess: SellerProduct;
  error: string | null;
}

export const DefaultFeedingToSorterTaskContext: FeedingToSorterTaskContext = {
  task:                          {} as Task,
  feedingToSorterProcessId:      -1,
  feedingToSorterContainerId:    -1,
  feedingToSorterLocation:       {} as Location,
  containerToProcess:            {} as Container,
  totalFeedingToSorterItems:     -1,
  processedFeedingToSorterItems: -1,
  sellerProductToProcess:        {} as SellerProduct,
  error:                         ''
};

export const FeedingToSorterTaskMachine = createMachine(
  {
    id:                         'FeedingToSorterTask',
    predictableActionArguments: true,
    schema:                     {
      context: {} as FeedingToSorterTaskContext
    },
    initial: 'ScanFeedingToSorterLocation',
    states:  {
      ScanFeedingToSorterLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: ctx =>
            GetLocationByNameMachine(
              'Confirme escaneando ubicación',
              getFeedingToSorterLocationByName(
                'No se encontro una Ubicación con ese nombre',
                ctx.feedingToSorterContainerId
              )
            ),
          onDone: [
            {
              actions: 'assignFeedingToSorterLocation',
              target:  'ScanFeedingToSorterContainer'
            }
          ]
        }
      },

      ScanFeedingToSorterContainer: {
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: ctx =>
            GetContainerByLpnMachine(
              'Confirme escaneando contenedor',
              getFeedingToSorterContainerByLpn(
                'No se encontro un contenedor con ese lpn',
                ctx.feedingToSorterContainerId
              )
            ),
          onDone: [
            {
              actions: 'assignContainerToProcess',
              target:  'FetchingFeedingToSorterItemsToProcess'
            }
          ]
        }
      },

      FetchingFeedingToSorterItemsToProcess: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchFeedingToSorterItemsToProcess',
          onDone: [
            {
              cond:    'hasPendingFeedingToSorterItemsToProcess',
              actions: ['assignFeedingToSorterItems'],
              target:  'ScanSellerProductToProcess'
            },
            {
              target: 'CompletingFeedingToSorter'
            }
          ]
        }
      },

      ScanSellerProductToProcess: {
        invoke: {
          id:  GetSellerProductByCodeMachineId,
          src: ctx =>
            GetSellerProductByCodeMachine(
              'Confirme inducción escaneando CB',
              getFeedingToSorterSellerProductByCode(
                'No se encontro un producto seller con ese código en este contenedor',
                ctx.feedingToSorterContainerId
              )
            ),
          onDone: [
            {
              actions: ['assignSellerProductToProcess', 'clearError'],
              target:  'ConfirmFeedingToSorterItemFed'
            }
          ]
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },

      ConfirmFeedingToSorterItemFed: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'FeedingToSorterItemFed',
            payload: {
              sellerProductId: ctx.sellerProductToProcess.id,
              locationId:      ctx.feedingToSorterLocation.id
            }
          }),
          onDone: {
            target: 'FetchingFeedingToSorterItemsToProcess'
          },
          onError: {
            target:  'ScanSellerProductToProcess',
            actions: ['assignError']
          }
        }
      },

      CompletingFeedingToSorter: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: ctx => ({
            task:    ctx.task,
            type:    'FeedingToSorterCompleted',
            payload: {
              feedingToSorterContainerId: ctx.feedingToSorterContainerId
            }
          }),
          onDone: {
            target: 'ConfirmingFeedingToSorterScreen'
          }
        }
      },

      ConfirmingFeedingToSorterScreen: {
        on: {
          confirm: {
            target: 'Done'
          }
        }
      },

      Done: {
        type: 'final'
      }
    }
  },
  {
    guards:   feedingToSorterTaskGuards,
    actions:  feedingToSorterTaskActions,
    services: feedingToSorterTaskServices
  }
);

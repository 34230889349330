import { PTWLocationProcessStatuses } from '../PTWLocationProcess';
import { PTWLocationProcessStatus } from './PTWLocationProcessStatus';

export class PTWLocationProcessProcessingStatus extends PTWLocationProcessStatus {
  public displayName = 'Procesando';

  public internalName = PTWLocationProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import {
  Container,
  Location,
  RestockingItem,
  SlottingItem,
  Task,
  TaskTypes
} from '@wms/domain';
import { createMachine } from 'xstate';
import { SendToParentMachine } from '../../core/SendToParentMachine';
import { getAnyContainerByLpn } from '../../layer-4/container/get-container-by-lpn/fetchers/get-any-container-by-lpn';
import {
  GetContainerByLpnMachine,
  GetContainerByLpnMachineId
} from '../../layer-4/container/get-container-by-lpn/machine';
import { getValidRestockingLocationByNameOrCheckDigit } from '../../layer-4/location/get-location-by-name/fetchers/get-valid-restocking-target-location-by-name-or-check-digit';
import { GetLocationByNameMachine } from '../../layer-4/location/get-location-by-name/machine';
import { manualRestockingActions } from './actions';
import { manualRestockingGuards } from './guards';
import { manualRestockingServices } from './services';

export interface ManualRestockingContext {
  container: Container | null;
  productId: number | null;
  originLocation: Location | null;
  targetLocation: Location | null;
  locationAttribute: string | null;
  restockingItem: RestockingItem | null;
  restockedQuantity: number | null;
  errorMsg: string | null;

  task: Task | null;

  externalTask: Task | null;
  taskPayload: {
    moveToBufferItemId: number | null;
    standardUnloadingContainerId: number | null;
    slottingItemId: number | null;
    taskId: number | null;
  } | null;
  slottingItem: SlottingItem | null;
}

export const DefaultManualRestockingContext: ManualRestockingContext = {
  container:         null,
  productId:         null,
  originLocation:    null,
  targetLocation:    null,
  locationAttribute: null,
  restockingItem:    null,
  restockedQuantity: null,
  errorMsg:          null,

  task: null,

  externalTask: null,
  taskPayload:  null,
  slottingItem: null
};

export const ManualRestockingMachine = createMachine(
  {
    id:     'ManualRestocking',
    schema: {
      context: {} as ManualRestockingContext
    },
    context: DefaultManualRestockingContext,
    initial: 'ScanningOriginContainer',
    states:  {
      // En ubicaciones de almacenamiento, hay solamente un posible contenedor
      ScanningOriginContainer: {
        exit:   'clearError',
        invoke: {
          id:  GetContainerByLpnMachineId,
          src: _ctx =>
            GetContainerByLpnMachine(
              'Escanear contenedor',
              getAnyContainerByLpn(
                'Ocurrió un error, por favor intente de nuevo.'
              )
            ),
          onDone: {
            actions: 'assignOriginLocation',
            target:  'CheckTask'
          },
          onError: {
            actions: 'assignError',
            target:  'ScanningOriginContainer'
          }
        }
      },
      CheckTask: {
        invoke: {
          id:     'CheckContainerTask',
          src:    'checkContainerHasStandardUnloadingContainer',
          onDone: [
            { cond: 'isNull', target: 'ScanningTargetLocation' },
            {
              target:  'ScanningTargetLocation',
              actions: [
                'assignExternalTask',
                'assignTaskPayload',
                'assignTargetLocationFromTask'
              ]
            }
          ],
          onError: {
            actions: 'assignError',
            target:  'ScanningOriginContainer'
          }
        }
      },
      ScanningTargetLocation: {
        invoke: {
          id:  'ScanTargetLocation',
          src: ctx =>
            GetLocationByNameMachine(
              'Escanee la ubicación destino',
              getValidRestockingLocationByNameOrCheckDigit(
                'Ocurrió un error, por favor reintente más tarde.',
                ctx.originLocation?.id || null,
                ctx.productId
              ),
              ctx.targetLocation
                ? {
                    name:     ctx.targetLocation!.name,
                    hint:     'Se requiere la siguiente ubicación',
                    required: true
                  }
                : undefined
            ),
          onDone: [
            {
              cond:    'targetLocationSameAsOrigin',
              actions: 'assignSameLocationError',
              target:  'ScanningTargetLocation'
            },
            {
              cond:    'targetLocationUnavailable',
              actions: 'assignUnavailableLocationError',
              target:  'ScanningTargetLocation'
            },
            {
              cond:    'targetLocationBlocked',
              actions: 'assignBlockedLocationError',
              target:  'ScanningTargetLocation'
            },
            {
              cond:   'hasTask',
              target: 'CompleteTask'
            },
            {
              actions: 'assignTargetLocation',
              target:  'CanMoveToLocation'
            }
          ]
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      CompleteTask: {
        tags:   ['loading'],
        invoke: {
          id:   SendToParentMachine.id,
          src:  SendToParentMachine,
          data: (ctx: ManualRestockingContext) => {
            const eventType =
              ctx.externalTask?.type === TaskTypes.MoveToBuffer
                ? 'MoveToBufferItemMoved'
                : 'SlottingItemSlotted';

            return {
              task:    ctx.externalTask,
              type:    eventType,
              payload: {
                ...ctx.taskPayload,
                locationId: ctx.targetLocation!.id
              }
            };
          },
          onDone: {
            target: 'CompletingMoving'
          },
          onError: {
            actions: 'assignError',
            target:  'ScanningTargetLocation'
          }
        }
      },

      CanMoveToLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'checkIfCanMoveToLocation',
          onDone: [
            {
              // cond:   'canMoveToLocation', @Comment this condition until REFACTOR CONTAINER IN PICKTOWER
              target: 'CheckingIfPickingLocation'
            },
            {
              actions: 'assignMaxNumberOfContainersExceededError',
              target:  'ScanningTargetLocation'
            }
          ],
          onError: [
            {
              actions: 'assignError',
              target:  'ScanningTargetLocation'
            }
          ]
        }
      },
      CheckingIfPickingLocation: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchLocationAttribute',
          onDone: [
            {
              cond:    'locationAttributeIsPicking',
              actions: 'assignLocationAttribute',
              target:  'CheckingRestockingLevel'
            },
            {
              target: 'CreatingRestockingItem'
            }
          ]
        }
      },
      CheckingRestockingLevel: {
        tags:   ['loading'],
        invoke: {
          src:    'checkRestockingLevel',
          onDone: [
            {
              cond:   'validRestockingLevel',
              target: 'CheckingIfRestockingItem'
            },
            {
              target: 'CreatingRestockingItem'
            }
          ],
          onError: [
            {
              actions: 'assignError',
              target:  'ScanningTargetLocation'
            }
          ]
        }
      },
      CheckingIfRestockingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'fetchRestockingItem',
          onDone: [
            {
              cond:    'pendingRestockingItem',
              actions: 'assignRestockingItem',
              target:  'MovingContainer'
            },
            {
              target: 'CreatingRestockingItem'
            }
          ]
        }
      },
      CreatingRestockingItem: {
        tags:   ['loading'],
        invoke: {
          src:    'createRestockingItem',
          onDone: [
            {
              actions: 'assignRestockingItem',
              target:  'MovingContainer'
            }
          ]
        }
      },
      MovingContainer: {
        tags:   ['loading'],
        invoke: {
          src:    'moveContainer',
          onDone: [
            {
              cond:   'existRestockingItem',
              target: 'CompleteRestockingTask'
            },
            {
              target: 'MovingContainerWithoutRestocking'
            }
          ]
        }
      },
      CompleteRestockingTask: {
        tags:   ['loading'],
        invoke: {
          src:    'completeRestockingTask',
          onDone: {
            target: 'CompletingMoving'
          }
        }
      },

      MovingContainerWithoutRestocking: {
        on: {
          CONFIRM: {
            target: 'MovingContainer'
          },
          CANCEL: {
            target: 'ScanningTargetLocation'
          }
        }
      },
      CompletingMoving: {
        on: {
          CONFIRM: {
            target: 'Finishing'
          }
        }
      },
      Finishing: {
        type: 'final'
      }
    }
  },
  {
    actions:  manualRestockingActions,
    services: manualRestockingServices,
    guards:   manualRestockingGuards
  }
);

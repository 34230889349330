import Ink from '@ink';
import { TransportOrderCompletedStatuses } from '@wms/domain/.';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { CloseTransportOrderMachine } from './machine';

export const CloseTransportOrderJob = withActorRef(CloseTransportOrderMachine)(
  ({ actorRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        {/* Cargando */}
        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Header>Cierre de OT - Cargando...</Ink.Header>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : (
            <Ink.Header>Cierre de OT</Ink.Header>
          )}
        </>

        {/* Header */}
        <>
          {current.matches('FetchTransportOrderByLabel') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Escanee bulto para conocer la OT asociada</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('ShowingTransportOrderInfo') && (
            <Ink.Box flexDirection='column'>
              <Ink.Box flexDirection='row'>
                <HighlightedInfo
                  attribute='Bulto'
                  value={current.context.transportOrder!.barcode}
                />
              </Ink.Box>
            </Ink.Box>
          )}

          {current.matches('TransportOrderClosed') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text bold color='greenBright'>
                Cierre de OT realizado
              </Ink.Text>
            </Ink.Box>
          )}
        </>

        {/* Body */}
        <>
          {current.matches('FetchTransportOrderByLabel') && (
            <Ink.Box flexDirection='column'>
              <GetItemByLabel id='FetchTransportOrderByLabel' />
            </Ink.Box>
          )}

          {current.matches('ShowingTransportOrderInfo') && (
            <Ink.Box flexDirection='column'>
              <Ink.Box flexDirection='row'>
                <HighlightedInfo
                  attribute='Se debe encontrar en'
                  value={
                    current.context.transportOrder!.requestedSorterWMSLocation
                      .location.name
                  }
                />
              </Ink.Box>
              {!TransportOrderCompletedStatuses.includes(
                current.context.transportOrder!.status as unknown as string
              ) && (
                <>
                  <Ink.Box flexDirection='row'>
                    <IntercalatedText
                      texts={['Cuenta con OT', 'sin notificar']}
                      values={[current.context.transportOrder!.id]}
                    />
                  </Ink.Box>
                  <Ink.Button
                    key='closeTransportOrder'
                    id='closeTransportOrder'
                    label='CERRAR OT'
                    onClick={() => send('CloseTransportOrder')}
                  />
                </>
              )}
              {TransportOrderCompletedStatuses.includes(
                current.context.transportOrder!.status as unknown as string
              ) && (
                <Ink.Box flexDirection='row'>
                  <Ink.Text>No cuenta con OT sin notificar</Ink.Text>
                </Ink.Box>
              )}
            </Ink.Box>
          )}

          {current.matches('ClosingTransportOrder') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Cerrando OT...</Ink.Text>
            </Ink.Box>
          )}

          {current.matches('TransportOrderClosed') && (
            <Ink.Box flexDirection='column'>
              <Ink.Box flexDirection='column'>
                <HighlightedInfo
                  attribute='La orden de transporte'
                  value={current.context.transportOrder!.id}
                />

                <HighlightedInfo
                  attribute='Asociada al bulto'
                  value={current.context.transportOrder!.barcode}
                />
                <Ink.Text>Fue cerrada con éxito</Ink.Text>
              </Ink.Box>

              <Ink.Box flexDirection='column' marginTop={2}>
                <Ink.Text>
                  El bulto se notificó como presente en ubicación
                </Ink.Text>
                <Ink.Text bold color='greenBright'>
                  {
                    current.context.transportOrder!.requestedSorterWMSLocation
                      .location.name
                  }
                </Ink.Text>
              </Ink.Box>

              <Ink.Button
                label='Cerrar otra OT'
                onClick={() => send('reset')}
              />
            </Ink.Box>
          )}

          {current.context.error && (
            <ErrorMessage bold error={current.context.error} />
          )}
        </>
      </Ink.Box>
    );
  }
);

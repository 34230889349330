import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusProcessedStatus extends ReceiptItemStatus {
  public displayName = 'Procesado';

  public internalName = ReceiptItemStatuses.Processed;

  public statusColor = '#f3ecfe';

  public textColor = '#7e3af2';
}

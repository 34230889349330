import { MachineOptions } from 'xstate';
import { UnloadingTaskContext } from './machine';

type UnloadingTaskGuards = MachineOptions<UnloadingTaskContext, any>['guards'];

export const unloadingTaskGuards: UnloadingTaskGuards = {
  hasPendingContainersToCreate: (_ctx, event) => {
    return event.data.pendingContainersToCreate.length > 0;
  },

  hasUnloadingItemsToUnload: (_ctx, event) => {
    return event.data.unloadedUnloadingItems < event.data.totalUnloadingItems;
  }
};

import { PutToWallWaveStatuses } from '../PutToWallWave';
import { PutToWallWaveStatus } from './PutToWallWaveStatus';

export class PutToWallWaveStatusProcessingStatus extends PutToWallWaveStatus {
  public displayName = 'Procesando';

  public internalName = PutToWallWaveStatuses.Processing;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

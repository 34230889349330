import { PTWLocationProcessStatuses } from '../PTWLocationProcess';
import { PTWLocationProcessStatus } from './PTWLocationProcessStatus';

export class PTWLocationProcessPendingStatus extends PTWLocationProcessStatus {
  public displayName = 'Pendiente';

  public internalName = PTWLocationProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

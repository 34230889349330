import { ReceiptItemStatuses } from '../ReceiptItem';
import { ReceiptItemStatus } from './ReceiptItemStatus';

export class ReceiptItemStatusMismatchQuantityStatus extends ReceiptItemStatus {
  public displayName = 'Productos Faltantes';

  public internalName = ReceiptItemStatuses.mismatchQuantity;

  public statusColor = '#ffd3d3';

  public textColor = '#c12424';
}

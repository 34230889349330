import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { PtwPackageInductionContext } from './machine';

type PtwPackageInductionActionsType = MachineOptions<
  PtwPackageInductionContext,
  any
>['actions'];

export const ptwPackageInductionActions: PtwPackageInductionActionsType = {
  assignLabel: assign({
    packageLabel: (_ctx, event) => {
      return event.data.label;
    }
  }),
  assignPackageData: assign({
    packingItems: (_ctx, event) => {
      return event.data.packingItems;
    },
    package: (_ctx, event) => {
      return event.data;
    },
    packageLabel: (_ctx, event) => {
      return event.data.label;
    }
  }),
  clearContextData: assign({
    packingItems: (_ctx, _event) => [],
    package:      (_ctx, _event) => null,
    packageLabel: (_ctx, _event) => ''
  }),
  assignTask: assign({
    task: (_ctx, event) => {
      return event.data;
    }
  }),
  assignError: assign({
    error: (_, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearPackingItems: assign({
    packingItems: (_ctx, _event) => {
      return [];
    }
  }),
  clearError: assign({
    error: (_ctx, _event) => {
      return null;
    }
  })
};

import { NoveltyUnloadingItemStatuses } from '../NoveltyUnloadingItem';
import { NoveltyUnloadingItemStatus } from './NoveltyUnloadingItemStatus';

export class NoveltyUnloadingItemStatusPendingStatus extends NoveltyUnloadingItemStatus {
  public displayName = 'Pendiente';

  public internalName = NoveltyUnloadingItemStatuses.Pending;

  public statusColor = '#Ffffff';
}

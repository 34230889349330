import { MachineOptions } from 'xstate';
import { UnloadingV3Context } from './machine';

type UnloadingV3Guards = MachineOptions<UnloadingV3Context, any>['guards'];

export const unloadingV3Guards: UnloadingV3Guards = {
  hasContainerTypes:         ctx => ctx.containerTypes && ctx.containerTypes.length > 0,
  hasPendingItemsToUnloaded: (_ctx, event) =>
    event.data.detailOfItems.some(item => !!item.missingQuantity),
  canUnloadedSkuInCurrentContainer: (_ctx, evt) =>
    evt.data.canUnloadedSkuInCurrentContainer,
  goToScanningSKU: context => {
    return context.alertData.target === 'ScanningSKU';
  },
  goToScanContainer: context => {
    return context.alertData.target === 'ScanContainer';
  }
};

import { MovePendingDispatchingItemStatuses } from '../MovePendingDispatchingItem';
import { MovePendingDispatchingItemStatus } from './MovePendingDispatchingItemStatus';

export class MovePendingDispatchingItemPendingStatus extends MovePendingDispatchingItemStatus {
  public displayName = 'Pendiente';

  public internalName = MovePendingDispatchingItemStatuses.Pending;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

import { MovingToSorterExitItemStatuses } from '../MovingToSorterExitItem';
import { MovingToSorterExitItemStatus } from './MovingToSorterExitItemStatus';

export class MovingToSorterExitItemStatusDeliveredStatus extends MovingToSorterExitItemStatus {
  public displayName = 'Entregado';

  public internalName = MovingToSorterExitItemStatuses.Delivered;

  public statusColor = '#ffd3d3';

  public textColor = '#c63131';
}

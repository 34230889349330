import {
  Field,
  ForeignKey,
  IsNotEmpty,
  Knows,
  Matches,
  NeededBy,
  Password,
  PrimaryKey,
  Private,
  SkfUser,
  UserEntity,
  Username
} from '@skyframe/core';
import { ApiField } from '../decorators';
import { Comment } from './Comment';
import { Document } from './Document';
import { Incident } from './Incident';
import { InventoryAdjustWave } from './InventoryAdjustWave';
import { InventoryCount } from './InventoryCount';
import { InventoryCounting } from './InventoryCounting';
import { LocationAssignment } from './LocationAssignment';
import { OrderBatch } from './OrderBatch';
import { PickingProcess } from './PickingProcess';
import { PickingWave } from './PickingWave';
import { Printer } from './Printer';
import { ReceiptBatch } from './ReceiptBatch';
import { ReceiptRejectionReport } from './ReceiptRejectionReport';
import { Task } from './Task';
import { TaskBucket } from './TaskBucket';
import { TaskRole } from './TaskRole';
import { TaskType } from './TaskType';
import { TransferOrder } from './TransferOrder';
import { UserRole } from './UserRole';
import { UserTaskRole } from './UserTaskRole';
import { Warehouse } from './Warehouse';

export const UserRoles = {
  Receiver:   'receiver',
  Storer:     'storer',
  Sorter:     'sorter',
  Preparer:   'preparer',
  Dispatcher: 'dispatcher',
  Auditor:    'auditor',
  Restocker:  'restocker',
  Counter:    'counter',
  Developer:  'Developer'
} as const;

export type UserRolesType = {
  [key: number]: keyof typeof UserRoles;
}; /** @Hack Jsonb from typescript Sequelize don't support array as value */

export const UserRolesView = {
  Receptor: {
    displayName:  'Receptor (Recepción)',
    internalName: UserRoles.Receiver
  },
  Almacenador: {
    displayName:  'Almacenador (Almacenado de Recepción)',
    internalName: UserRoles.Storer
  },
  Ordenador: {
    displayName:  'Ordenador (Ordenador de Cross Dock)',
    internalName: UserRoles.Sorter
  },

  Preparador: {
    displayName:  'Preparador (Preparación de Rutas)',
    internalName: UserRoles.Preparer
  },
  Auditor: {
    displayName:  'Auditor (Auditoría de Rutas)',
    internalName: UserRoles.Auditor
  },
  Despachador: {
    displayName:  'Despachador (Despacho de Rutas)',
    internalName: UserRoles.Dispatcher
  },
  Restockeador: {
    displayName:  'Restockeador (Restocking de ubicaciones de picking)',
    internalName: UserRoles.Restocker
  },
  Contador: {
    displayName:  'Contadores de Inventario',
    internalName: UserRoles.Counter
  },
  Developer: {
    displayName:  'Developers',
    internalName: UserRoles.Developer
  }
};

export const rolesKeys = {
  Admin:             'Admin',
  Moderator:         'Moderator',
  ModeratorInbound:  'ModeratorInbound',
  ModeratorOutbound: 'ModeratorOutbound',
  Support:           'Support',
  Base:              'Base',
  Inventarist:       'Inventarist'
} as const;

export const skfRoles = {
  [rolesKeys.Admin]:             'ADMIN',
  [rolesKeys.Moderator]:         'MODERATOR',
  [rolesKeys.ModeratorInbound]:  'MODERATOR INBOUND',
  [rolesKeys.ModeratorOutbound]: 'MODERATOR OUTBOUND',
  [rolesKeys.Support]:           'SOPORTE LOGISTICO',
  [rolesKeys.Base]:              'BASE',
  [rolesKeys.Inventarist]:       'INVENTARISTA'
} as const;

export type SkfRolesType = (typeof skfRoles)[keyof typeof skfRoles];

// Los numeros se comportan igual a el z-index en css, no importa el orden ni numero que se elija sino la diferencia que hay entre 2 roles (100 > 75)
// Se hace así para evitar usar 1,2,3,4,5 y que en un futuro se quiera meter algo entre 4 y 5, para que no tener que cambiar todos los otros roles.
export const skfRolesHierarchy = {
  [skfRoles.Admin]:             100,
  [skfRoles.Moderator]:         60,
  [skfRoles.ModeratorInbound]:  50,
  [skfRoles.ModeratorOutbound]: 50,
  [skfRoles.Support]:           75,
  [skfRoles.Base]:              0,
  [skfRoles.Inventarist]:       0
} as const;

export const SkfRolesView: Record<
  string,
  { displayName: string; internalName: SkfRolesType }
> = {
  Admin: {
    displayName:  'Administrador',
    internalName: skfRoles.Admin
  },
  Support: {
    displayName:  'Soporte Logistico',
    internalName: skfRoles.Support
  },
  Moderator: {
    displayName:  'Moderador',
    internalName: skfRoles.Moderator
  },
  ModeratorInbound: {
    displayName:  'Moderador Recepción',
    internalName: skfRoles.ModeratorInbound
  },
  ModeratorOutbound: {
    displayName:  'Moderador Preparación',
    internalName: skfRoles.ModeratorOutbound
  },
  Inventarist: {
    displayName:  'Inventarista',
    internalName: skfRoles.Inventarist
  },
  Base: {
    displayName:  'Rol base',
    internalName: skfRoles.Base
  }
};

@UserEntity()
export class User extends SkfUser {
  @ApiField()
  @PrimaryKey()
  id: number;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'employee_code' })
  employeeCode: number;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'first_name' })
  firstName: string;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Field({ name: 'last_name' })
  lastName: string;

  @ApiField()
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Username()
  username!: string;

  @Matches(/\d/, { message: 'El campo requiere al menos un número' })
  @Matches(/[A-Z]/, {
    message: 'El campo requiere al menos una letra mayúscula'
  })
  @Matches(/[a-z]/, {
    message: 'El campo requiere al menos una letra minúscula'
  })
  @IsNotEmpty({
    message: 'El campo es requerido'
  })
  @Private()
  @Password()
  password!: string;

  hhRoles: UserRolesType;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Field({ name: 'online' })
  online: boolean;

  @Knows(() => OrderBatch, 'launchedByUserId')
  orderBatch: OrderBatch;

  @ApiField()
  @ForeignKey(() => TaskBucket, { name: 'task_bucket_id' })
  taskBucketId: number;

  @Knows(() => Task, 'userId')
  tasks: Task[];

  @Knows(() => TaskBucket, 'taskBucketId')
  taskBucket: TaskBucket;

  @Knows(() => PickingWave, 'userId')
  PickedWaves: PickingWave[];

  @NeededBy(() => Comment, 'authorId')
  comment: Comment;

  @NeededBy(() => ReceiptRejectionReport, 'reporterUserId')
  receiptRejectionReport: ReceiptRejectionReport;

  @NeededBy(() => Document, 'userId')
  documents: Document[];

  @ApiField()
  @ForeignKey(() => Warehouse, { name: 'warehouse_id' })
  warehouseId: number;

  @Knows(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @NeededBy(() => Printer, 'userId')
  printer: Printer;

  @NeededBy(() => PickingProcess, 'authorId')
  pickingProcesses: PickingProcess[];

  @Knows(() => TransferOrder, 'userId')
  transferOrders: TransferOrder[];

  @NeededBy(() => InventoryCount, 'authorId')
  createdInventoryCounts: InventoryCount[];

  @NeededBy(() => InventoryCount, 'counterId')
  countedInventoryCounts: InventoryCount[];

  @Field({ name: 'heirarchy_level' })
  heirarchyLevel: number;

  @NeededBy(() => LocationAssignment, 'userId')
  locationAssignments: LocationAssignment[];

  @Knows(() => Incident, 'reporterId')
  reportedIncidents: Incident[];

  @Knows(() => Incident, 'resolverId')
  resolvedIncidents: Incident[];

  @Field({ name: 'record_id' })
  recordId: string;

  @Field({ name: 'user_role_id' })
  userRoleId: number;

  @Knows(() => UserRole, 'userRoleId')
  userRole: UserRole;

  @NeededBy(() => UserTaskRole, 'userId')
  userTaskRoles: UserTaskRole[];

  @NeededBy(() => TaskRole, 'authorId')
  authoredTaskRoles: TaskRole[];

  @NeededBy(() => InventoryCounting, 'authorId')
  inventoryCounting: InventoryCounting;

  @NeededBy(() => TaskType, 'authorId')
  authoredTaskTypes: TaskType[];

  @NeededBy(() => ReceiptBatch, 'userId')
  receiptBatch: ReceiptBatch;

  @NeededBy(() => InventoryAdjustWave, 'authorId')
  inventoryAdjustWaves: InventoryAdjustWave[];
}

import { InventoryAdjustWaveStatuses } from '../InventoryAdjustWave';
import { InventoryAdjustWaveStatus } from './InventoryAdjustWaveStatus';

export class InventoryAdjustWavePendingStatus extends InventoryAdjustWaveStatus {
  public displayName = 'Pendiente';

  public internalName = InventoryAdjustWaveStatuses.Pending;

  public statusColor = '#d7d7d7';

  public textColor = '#373636';
}

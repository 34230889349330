import {
  Entity,
  Field,
  ForeignKey,
  Knows,
  Needs,
  PrimaryKey,
  Status
} from '@skyframe/core';
import { InventoryItem } from './InventoryItem';
import { ReceiptItem } from './ReceiptItem';
import { UnloadingProcess } from './UnloadingProcess';
import {
  UnloadingItemStatus,
  UnloadingItemStatusCancelledStatus,
  UnloadingItemStatusUnloadedStatus
} from './unloading-item-status';
import { UnloadingItemStatusPendingStatus } from './unloading-item-status/UnloadingItemStatusPendingStatus';

export const UnloadingItemStatuses = {
  Pending:   'pending',
  Unloaded:  'unloaded',
  Cancelled: 'cancelled'
};

@Entity()
export class UnloadingItem {
  @PrimaryKey()
  id: number;

  @Status({
    [UnloadingItemStatuses.Pending]:   UnloadingItemStatusPendingStatus,
    [UnloadingItemStatuses.Unloaded]:  UnloadingItemStatusUnloadedStatus,
    [UnloadingItemStatuses.Cancelled]: UnloadingItemStatusCancelledStatus
  })
  status: UnloadingItemStatus;

  @ForeignKey(() => ReceiptItem, { name: 'receipt_item_id' })
  receiptItemId: number;

  @ForeignKey(() => InventoryItem, { name: 'inventory_item_id' })
  inventoryItemId: number;

  @ForeignKey(() => UnloadingProcess, { name: 'unloading_process_id' })
  unloadingProcessId: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => ReceiptItem, 'receiptItemId')
  receiptItem: ReceiptItem;

  @Knows(() => InventoryItem, 'inventoryItemId')
  inventoryItem: InventoryItem;

  @Needs(() => UnloadingProcess, 'unloadingProcessId')
  unloadingProcess: UnloadingProcess;
}

import { ReservationItemStatuses } from '../ReservationItem';
import { ReservationItemStatus } from './ReservationItemStatus';

export class ReservationItemStatusDispatchedStatus extends ReservationItemStatus {
  public displayName = 'Despachada';

  public internalName = ReservationItemStatuses.Dispatched;

  public statusColor = '#f3ecfe';

  public textColor = '#8b4df3';
}

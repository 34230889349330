import { InventoryItem, Location } from '@wms/domain/.';
import { createMachine } from 'xstate';

import { getAnyLocationByName } from '../../layer-4/location/get-location-by-name/fetchers/get-any-location-by-name';
import {
  GetLocationByNameMachine,
  GetLocationByNameMachineId
} from '../../layer-4/location/get-location-by-name/machine';
import { locationDetailActions } from './actions';
import { locationDetailGuards } from './guards';
import { locationDetailServices } from './services';

export interface Item {
  sku: string;
  name: string;
  itemsInContainersByStatus: InventoryItem[];
}

export interface LocationDetailContext {
  location: Location | null;
  items: Item[];
  locationStatus: string | null;
  operationTypes: string[];
  allowMultiSku: boolean;
  containerCount: number | null;
  lastInventoryItemInLocation: InventoryItem | null;
  error: string | null;
  neverHadItemsInlocation: boolean;
}

export const DefaultLocationDetailContext: LocationDetailContext = {
  location:                    null,
  items:                       [],
  locationStatus:              null,
  operationTypes:              [],
  allowMultiSku:               false,
  containerCount:              null,
  lastInventoryItemInLocation: null,
  error:                       null,
  neverHadItemsInlocation:     false
};

export const LocationDetailMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGED2A7ALgQwJbrACcARMHXAGwDoBlAY23QBlsAjMCgYggzCvwBuqANZ80WPARJk81eoxbsKCQagaZcGANoAGALq69iUAAdUsXBozGQAD0QAWAEwAaEAE9ETgOw6qDnQA2JwBWHR8ADgiATh1vAF94t3FyKVJyOQZmNg5uXn50IVEqFMkidNlaLMUOFUK1bCt0Qy0ARiMkEDMLJpt7BBCAZgiqQICQ6IcHCKdnVui3TwQA7yonQYdB7xDImLjE5IxU8plKKoUcriJCVEIqEwpGgDNbgFsSo7LpDPPspTqhOpNM19IYbN1LMC+ohWq1AlRooEIttFjDvKsHENWqFdrEEkkQKV8CcfvI-rk6BQwNhCABRQg3QhgzoQ3qdfpOeZUVoBQI6aLeBzY1r8hyohARVpUMI6WXhbxRPGtA6Ez7E76VABiZDoAAsKpQ8gQCkUxGq0qdqNrMHqDcpVEDtKD9ODzJDrOyYa0IiEqL5BkFBojppsIuKVlQdFMfLDsU5ZiqiRaftbbZbONdbvdHpgXoR3kmSVqdfrLQCGk0Wi6WW62aB+jMRt5vUiYmMIqHxU5kVRG9idgq9vjDhJ1XaqHbOIQ4FlmaZa1DPQhYYjuc3JUH252PDCplRBiFWiFgujFfsVehUBA4DZCxrKK6eov64gALSBcXvxPmotnMk1ChH3ddBoQQJwdEGNY9gcbxZmFUVxSDBxe37WVcXPEdjnvK0SztIC6zsRBomiX0sW9NsQ2GcMdF9HwdAiQZBkCOMEwJO9xzwmsnw9F9lwPSCfWiQZWk3Siwx3ZZ1m5ZEZXQ-FEiAA */
    id:     'LocationDetail',
    schema: {
      context: {} as LocationDetailContext
    },
    context: DefaultLocationDetailContext,
    initial: 'ScanningLocation',
    states:  {
      ScanningLocation: {
        invoke: {
          id:  GetLocationByNameMachineId,
          src: GetLocationByNameMachine(
            'Escanee la ubicación a consultar',
            getAnyLocationByName(
              'Ocurrió un error, por favor reintente más tarde.'
            )
          ),
          onDone: [
            {
              actions: 'assignLocation',
              target:  'GetLocationDetails'
            }
          ]
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      GetLocationDetails: {
        tags:   ['loading'],
        invoke: {
          src:    'getLocationDetails',
          onDone: {
            actions: [
              'assignLocationDetails',
              'assignItems',
              'assignOperationTypes'
            ],
            target: 'LocationDetails'
          },
          onError: {
            target:  'ScanningLocation',
            actions: ['assignError', 'clearDetails']
          }
        }
      },
      LocationDetails: {
        on: {
          reset: {
            target:  'ScanningLocation',
            actions: 'clearDetails'
          }
        }
      }
    }
  },
  {
    actions:  locationDetailActions,
    services: locationDetailServices,
    guards:   locationDetailGuards
  }
);

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { SlottingRollContainerContext } from './machine';

type SlottingRollContainerActions = MachineOptions<
  SlottingRollContainerContext,
  any
>['actions'];

export const slottingRollContainerActions: SlottingRollContainerActions = {
  assignItemsCount: assign({
    itemsTotal:   (_ctx, event) => event.data.itemsTotal,
    itemsSlotted: (_ctx, event) => event.data.itemsSlotted
  }),
  assignProduct: assign({
    productAssigned: (_ctx, event) => event.data.product
  }),
  assignSlottingItem: assign({
    slottingItem: (_ctx, event) => event.data.slottingItem
  }),
  assignSkuUnloadedQuantity: assign({
    skuUnloadedQuantity: (_ctx, event) => event.data.unloadedQuantity
  }),
  assignDestinySkuLocation: assign({
    skuSelectedDestinyLocationAndQuantity: (_ctx, event) =>
      event.data.destinyLocationAndQuantity || event.data
  }),
  assignQuantityDeposited: assign({
    quantityDeposited: (ctx, event) => event.data.quantity
  }),
  assignNewQuantityToSlot: assign({
    skuUnloadedQuantity: (ctx, _event) =>
      ctx.skuUnloadedQuantity! - ctx.quantityDeposited
  }),
  assignLocationScanned: assign({
    locationScanned: (_ctx, event) => event.data
  }),
  clearSkuSelectedCtx: assign({
    skuSelectedDestinyLocationAndQuantity: (_ctx, _event) => null,
    productAssigned:                       (_ctx, _event) => null,
    slottingItem:                          (_ctx, _event) => null,
    skuUnloadedQuantity:                   (_ctx, _event) => null,
    quantityDeposited:                     (_ctx, _event) => 0,
    locationScanned:                       (_ctx, _event) => null,
    availableQuantityInLocationScanned:    (_ctx, _event) => null
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  assignLocationNotAvailableError: assign({
    error: (_ctx, _event) =>
      'La ubicacion no esta disponible, por favor intente nuevamente.'
  }),
  clearError: assign({
    error: (_, __) => null
  }),
  assignIsNotParentError: assign({
    error: (_ctx, _event) =>
      'La ubicacion escaneada no se encuentra en el piso de mezzanine valido.'
  }),
  assignHasNotAvailableQuantityError: assign({
    error: (_ctx, _event) =>
      'La ubicacion no tiene la cantidad disponible para depositar.'
  }),
  assignAvailableQuantityInLocation: assign({
    availableQuantityInLocationScanned: (_ctx, event) => event.data
  }),
  assignQuantityDestinyLocation: assign({
    availableQuantityInLocationScanned: (_ctx, event) =>
      event.data.destinyLocationAndQuantity.quantity
  }),
  updateAvailableQuantityInLocationScanned: assign({
    availableQuantityInLocationScanned: (ctx, _event) =>
      ctx.availableQuantityInLocationScanned! - ctx.quantityDeposited
  }),
  assignBlockedLocationError: assign({
    error: (_ctx, _event) =>
      'No es posible realizar el movimiento ya que la ubicación escaneada se encuentra bloqueada'
  })
};

import { StandardUnloadingItemStatuses } from '../StandardUnloadingItem';
import { StandardUnloadingItemStatus } from './StandardUnloadingItemStatus';

export class StandardUnloadingItemStatusPendingStatus extends StandardUnloadingItemStatus {
  public displayName = 'Pendiente';

  public internalName = StandardUnloadingItemStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MachineOptions, assign } from 'xstate';
import { getErrorView } from '../../../shared/utils/getErrorView';
import { UnloadingV3Context } from './machine';

type UnloadingV3Actions = MachineOptions<UnloadingV3Context, any>['actions'];

export const unloadingV3Actions: UnloadingV3Actions = {
  assignStagingLocation: assign({
    stagingLocation: (_, event) => event.data.stagingLocation
  }),
  assignSKUScanned: assign({
    SKUScanned: (_, event) => event.data
  }),
  assingDetailOfItems: assign({
    detailOfItems: (_, event) => event.data.detailOfItems
  }),

  assignStandardUnloadingContainerScanned: assign({
    standardUnloadingContainerScanned: (_, event) =>
      event.data.standardUnloadingContainer
  }),
  assingContainerLabel: assign({
    containerLabel: (_, event) => event.data.lpn
  }),
  assignContainerTypes: assign({
    containerTypes: (_, event) => event.data.containerTypes
  }),
  assignContainerType: assign({
    containerType: (_, event) => event.data
  }),
  assignReceivedQuantity: assign({
    receivedQuantity: (_, event) => event.data.quantity
  }),
  assignError: assign({
    error: (_ctx, event) =>
      getErrorView(
        event.data?.data ?? event.data,
        'Ocurrió un error, por favor reintente más tarde.'
      )
  }),
  clearError: assign({
    error: (_, __) => null
  }),
  assignQuantityLeft: assign({
    quantityLeft: (ctx, event) => event.data.quantityLeft
  }),
  assignStandardUnloadingProcess: assign({
    standardUnloadingProcess: (_, event) => event.data.standardUnloadingProcess
  }),
  assingFethProductAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanningSKU'
      };
    }
  }),
  assingValidationContainerAlertData: assign({
    alertData: _ctx => {
      return {
        errorTitle:     'Error',
        errorMessage:   _ctx.error ?? '',
        positiveTarget: null,
        negativeTarget: null,
        target:         'ScanContainer'
      };
    }
  })
};

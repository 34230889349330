import { Entity, Field, ForeignKey, Needs, PrimaryKey } from '@skyframe/core';
import { ReservationItem } from './ReservationItem';

export interface ReservationItemLabelAndreaniData {
  trackId: string;
  distCenterNumber: number;
  distCenterName: string;
  rendCenterNumber: number;
  rendCenterName: string;
  width: number;
  height: number;
  length: number;
  weight: number;
}

export interface ReservationItemLabelMercadoEnviosData {
  zpl: string;
}

@Entity()
export class ReservationItemLabel {
  @PrimaryKey()
  id: number;

  @ForeignKey(() => ReservationItem, { name: 'reservation_item_id' })
  reservationItemId: number;

  @Needs(() => ReservationItem, 'reservationItemId')
  reservationItem: ReservationItem;

  @Field({ name: 'wmo_id' })
  wmoId: string;

  @Field({ name: 'index' })
  index: number;

  dataOOLL: any;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;
}

import { MachineOptions } from 'xstate';
import { ConsolidationClasificationContext } from './machine';

type ConsolidationClassificationGuardsType = MachineOptions<
  ConsolidationClasificationContext,
  any
>['guards'];

export const ConsolidationClassificationGuards: ConsolidationClassificationGuardsType =
  {
    hasClassificationContainer: (_ctx, evt) => {
      return evt.data.classificationContainer;
    },
    isDeliveryTypeHD: (_ctx, evt) => {
      return evt.data.deliveryType.name === 'home-delivery';
    },
    isLastItem: (_ctx, evt) => {
      return evt.data.isLastItem;
    }
  };

import { SlottingContainerStatuses } from '../SlottingContainer';
import { SlottingContainerStatus } from './SlottingContainerStatus';

export class SlottingContainerStatusSlottedStatus extends SlottingContainerStatus {
  public displayName = 'Guardado';

  public internalName = SlottingContainerStatuses.Slotted;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}

import { MachineOptions } from 'xstate';
import { ContainerDetailContext } from './machine';

type containerDetailGuardsType = MachineOptions<
  ContainerDetailContext,
  any
>['guards'];

export const containerDetailGuards: containerDetailGuardsType = {
  isPackageEntity: (ctx, event) => {
    return event.data.entityType === 'package';
  },
  isSortingItemEntity: (ctx, event) => {
    return event.data.entityType === 'sorting-item';
  },
  isMopSorterExitItemEntity: (ctx, event) => {
    return event.data.entityType === 'mop-sorter-exit-item';
  },
  entityTypeIsNull: (ctx, event) => {
    return !!event.data.entityType === false;
  }
};

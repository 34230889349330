import { NoveltyUnloadingContainerV2Statuses } from '../NoveltyUnloadingContainerV2';
import { NoveltyUnloadingContainerV2Status } from './NoveltyUnloadingContainerV2Status';

export class NoveltyUnloadingContainerV2StatusMovingStatus extends NoveltyUnloadingContainerV2Status {
  public displayName = 'En movimiento';

  public internalName = NoveltyUnloadingContainerV2Statuses.Moving;

  public statusColor = '#Ffffff';
}

import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';

export function getSortableBulkAtSorterExitByLabel(
  defaultErrorMessage: string
) {
  return fetchResult(defaultErrorMessage, label =>
    api.get(`sortableBulk/getSortableBulkAtSorterExitByLabel/${label}`)
  );
}

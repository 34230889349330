import { ContainerTypes } from '@wms/domain/.';
import { api } from '../../../../api/utils/axios-instance';

export const consolidationClassificationServices = {
  getClassificationItemByLabel: async (ctx, _evt) => {
    const { data } = await api.get('classification-item/label', {
      params: {
        label: ctx.label
      }
    });
    return {
      classificationItem: data.item,
      location:           data.location,
      warehouse:          data.warehouse,
      route:              data.route
    };
  },
  checkIfHasClassificationContainerByTargetWarehouse: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-container/classification-item/warehouse',
      {
        params: {
          classificationItemId: ctx.classificationItem.id,
          locationId:           ctx.classificationItemLocation.id,
          routeId:              ctx.classificationItemRoute?.id || null
        }
      }
    );
    return {
      classificationContainer: data
    };
  },
  createNewClassificationContainer: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationCreateContainer',
          payload: {
            lpn:               ctx.newContainerLabel,
            containerTypeId:   ctx.selectedContainerType.id,
            slot:              ctx.suggestedSlotPosition,
            locationId:        ctx.classificationItemLocation.id,
            targetWarehouseId: ctx.classificationItemWarehouse?.id || null,
            routeId:           ctx.classificationItemRoute?.id || null
          }
        }
      }
    );
  },
  ConsolidationClassificationClassifyItem: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationClassifyItem',
          payload: {
            classificationItemId: ctx.classificationItem.id,
            containerId:          ctx.classificationContainer.container.id
          }
        }
      }
    );
  },
  ConsolidationClassificationContainerClosed: async (ctx, _evt) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          event:   'ConsolidationClassificationContainerClosed',
          payload: {
            containerId: ctx.classificationContainer.container.id
          }
        }
      }
    );
  },
  ConsolidationClassificationContainerClosedAutomatically: async (
    ctx,
    _evt
  ) => {
    await api.get(
      'classification-process/consolidation-classification-dispatch-process-event-automatically',
      {
        params: {
          classificationProcessId:
            ctx.classificationItem.classificationProcess.id,
          classificationContainersIdsToClose:
            ctx.classificationContainersToClose
        }
      }
    );
  },
  getContainerTypeByName: async (_ctx, _evt) => {
    const { data } = await api.get('container-type/name', {
      params: {
        name: ContainerTypes.PALLETARLOG
      }
    });
    return {
      containerType: data
    };
  },
  getSuggestedSlotPosition: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-process/next-classification-container-slot-position',
      {
        params: {
          locationId: ctx.classificationItemLocation.id
        }
      }
    );
    return {
      slotPosition: data
    };
  },
  getClassificationProcessAndClassificationContainerToClose: async (
    ctx,
    _evt
  ) => {
    const { data } = await api.get('classification-container/container', {
      params: {
        containerId: ctx.containerToClose.id
      }
    });

    return {
      classificationContainer: data.classificationContainer,
      classificationItem:      data.classificationItem
    };
  },
  checkIfHasClassificationContainerByRoute: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-container/classification-item/route',
      {
        params: {
          label:      ctx.label,
          locationId: ctx.classificationItemLocation.id
        }
      }
    );
    return {
      classificationContainer: data
    };
  },
  checkDeliveryType: async (ctx, _evt) => {
    const { data } = await api.get(
      'reservation-type/classification-item/label',
      {
        params: {
          label: ctx.label
        }
      }
    );
    return {
      deliveryType: data
    };
  },
  isLastItemToClassify: async (ctx, _evt) => {
    const { data } = await api.get(
      'classification-item/is-last-item-to-classify',
      {
        params: {
          classificationItemId: ctx.classificationItem.id
        }
      }
    );
    return {
      isLastItem:        data.isLastItem,
      containersToClose: data.containersToClose
    };
  }
};

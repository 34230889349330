import { CrossdockingLocationTypes, Location } from '@wms/domain';
import { api } from '../../../../../../api/utils/axios-instance';
import { fetchResult } from '../../../../../../utils/fetch-result';
import { GetLocationByNameFn } from '../machine';

export function getCrossdockingSorterLocationByName(
  defaultErrorMessage: string
): GetLocationByNameFn {
  return fetchResult(defaultErrorMessage, name =>
    api.get<Location>('location/get-crossdocking-location-by-name', {
      params: {
        name,
        crossdockingLocationType: CrossdockingLocationTypes.Sorter
      }
    })
  );
}

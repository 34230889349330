import { MachineOptions } from 'xstate';
import { MovingToPreSorterTaskContext } from './machine';

type MovingToPreSorterTaskGuards = MachineOptions<
  MovingToPreSorterTaskContext,
  any
>['guards'];

export const movingToPreSorterTaskGuards: MovingToPreSorterTaskGuards = {
  hasPendingContainersToMoveToPreSorter: (_ctx, event) => {
    return event.data.movedContainers < event.data.totalContainers;
  }
};

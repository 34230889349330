import { PendingDispatchingPickingProcessStatuses } from '../PendingDispatchingPickingProcess';
import { PendingDispatchingPickingProcessStatus } from './PendingDispatchingPickingProcessStatus';

export class PendingDispatchingPickingProcessProcessingStatus extends PendingDispatchingPickingProcessStatus {
  public displayName = 'Procesando';

  public internalName = PendingDispatchingPickingProcessStatuses.Processing;

  public statusColor = '#e6f4ff';

  public textColor = '#3c5095';
}

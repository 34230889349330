import Ink from '@ink';
import { useMachine, useSelector } from '@xstate/react';
import axios from 'axios';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ApiProvider } from 'react-use-api';
import { Auth } from './modules/auth';
import { AutoAssignTaskRouter } from './modules/auto-assign-task/AutoAssignTaskRouter';
import { TaskContext } from './modules/auto-assign-task/TaskContext';
import { Menu } from './modules/menu';
import { ConfigurationMenu } from './modules/menu/ConfigurationMenu';
import { CrossdockingMenu } from './modules/menu/CrossdockingMenu';
import { InboundMenu } from './modules/menu/InboundMenu';
import { InventoryManagementMenu } from './modules/menu/InventoryManagementMenu';
import { LogisticsMenu } from './modules/menu/LogisticMenu';
import { OutboundMenu } from './modules/menu/OutboundMenu';
import { RootMachineProvider } from './modules/shared/Machine';
import { GenericOptions } from './modules/tasks/core/GenericOptions';
import { RootMachine } from './modules/tasks/core/RootMachine';
import { RootService } from './modules/tasks/core/RootService';
import { TaskHelp } from './modules/tasks/core/TaskHelp';
import { TaskOptions } from './modules/tasks/core/TaskOptions';
import { ChildrenTask } from './modules/tasks/core/constants';
import { PackingAuditingDesignator } from './modules/tasks/pre-layer-3/packing-auditing-designator/component';
import { PackingAuditingDesignatorMachine } from './modules/tasks/pre-layer-3/packing-auditing-designator/machine';
import { TaskSearcher } from './modules/tasks/pre-layer-3/task-searcher/component';
import { TaskIndex } from './modules/tasks/taskIndex';
import { useSession } from './session/useSession';

const useDevTools = process.env.DEV_TOOLS !== 'false';

export const Main: FC = () => {
  const { token, user, openSession, closeSession } = useSession();

  const apiContext = useMemo(
    () => ({
      settings: {
        axios: axios.create({
          baseURL:        process.env.BACKEND_HOST || 'http://localhost:8080/api/v1',
          validateStatus: status => status >= 200 && status < 300,
          responseType:   'json'
        })
      }
    }),
    [token]
  );

  const services = useMemo(() => {
    const service = new RootService(apiContext.settings.axios);
    return service.getServices();
  }, [apiContext]);

  const [state, send, interpreter] = useMachine(RootMachine, {
    devTools: useDevTools,
    services
  });

  useEffect(() => {
    if (token) {
      send('loggedIn', { session: { token, user } });
    } else {
      send('logout');
    }
  }, [token]);

  const [interceptorId, setInterceptorId] = useState<number>();
  useEffect(() => {
    const id = apiContext.settings.axios.interceptors.request.use(config => {
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    });

    setInterceptorId(id);

    return () => {
      if (interceptorId) {
        apiContext.settings.axios.interceptors.request.eject(interceptorId);
      }
    };
  }, [apiContext, token]);

  const currentTask = useSelector(
    interpreter,
    currentState => currentState.context.currentTask
  );

  const awaitingTaskId = useSelector(
    interpreter,
    currentState => currentState.context.awaitingTaskId
  );

  const helpScreenText = useSelector(
    interpreter,
    currentState => currentState.context.helpScreenText
  );

  const menuScreenItems = useSelector(
    interpreter,
    currentState => currentState.context.menuScreenItems
  );

  const InTaskView = useMemo(() => {
    if (currentTask) {
      const task = TaskIndex[currentTask.type];
      if (task) {
        const TaskComponent = task.component;
        return <TaskComponent />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [currentTask]);

  const setTask = task => {
    send('taskReceived', { task });
  };

  const backToMenu = () => {
    send('backToMenu');
  };

  useEffect(() => {
    // Atrapar refresh inintencionales
    window.onbeforeunload = function () {
      return confirm('Confirmar recarga de aplicación');
    };
  }, []);

  useEffect(() => {
    const subscription = interpreter.subscribe(state2 => {
      setTimeout(() => {
        const babyTask = (state2?.children?.RootTask as any)
          ?.state as ChildrenTask;

        if (babyTask && babyTask.tags.has('saveable') && babyTask.changed) {
          services.saveTask(state2.context, {});
        }
      }, 1); // El timeout está porque por algun motivo el subscribe se ejecuta una sola vez
    });

    return subscription.unsubscribe;
  }, [interpreter]);

  return (
    <ApiProvider context={apiContext}>
      <TaskContext.Provider value={currentTask}>
        <RootMachineProvider
          state={state}
          send={send}
          interpreter={interpreter}
        >
          {helpScreenText ? (
            <TaskHelp helpText={helpScreenText} />
          ) : menuScreenItems ? (
            <TaskOptions menuItems={menuScreenItems} />
          ) : (
            <Ink.Box minHeight={12} flexDirection='column'>
              {state.matches('login') ? (
                <Auth openSession={openSession} />
              ) : null}

              {state.matches('menu') ? (
                <Menu disconnect={closeSession} send={send} />
              ) : null}

              {state.matches('outboundMenu') ? (
                <>
                  {state.matches('outboundMenu.idle') ? (
                    <OutboundMenu send={send} />
                  ) : null}

                  {/* {state.matches('outboundMenu.inPackingTaskAssigner') ? (
                    <PackingTaskAssigner
                      actorRef={state.children[PackingTaskAssignerMachine.id]}
                    />
                  ) : null}

                  {state.matches('outboundMenu.inAuditingTaskAssigner') ? (
                    <AuditingTaskAssigner
                      actorRef={state.children[AuditingTaskAssignerMachine.id]}
                    />
                  ) : null} */}

                  {state.matches('outboundMenu.inPackingAuditingDesignator') ? (
                    <PackingAuditingDesignator
                      actorRef={
                        state.children[PackingAuditingDesignatorMachine.id]
                      }
                    />
                  ) : null}

                  {state.matches('outboundMenu.inPackingAuditingDesignatorTask')
                    ? InTaskView
                    : null}

                  {/* {state.matches('outboundMenu.inPackingTask')
                    ? InTaskView
                    : null}

                  {state.matches('outboundMenu.inAuditingTask')
                    ? InTaskView
                    : null} */}
                </>
              ) : null}

              {state.matches('logisticsMenu') ? (
                <LogisticsMenu send={send} />
              ) : null}

              {state.matches('crossdockingMenu') ? (
                <CrossdockingMenu send={send} />
              ) : null}

              {state.matches('inboundMenu') ? (
                <InboundMenu send={send} />
              ) : null}

              {state.matches('inventoryManagementMenu') ? (
                <InventoryManagementMenu send={send} />
              ) : null}

              {state.matches('configurationMenu') ? (
                <ConfigurationMenu send={send} user={user} />
              ) : null}

              {state.matches('inTaskSearcher') ? <TaskSearcher /> : null}

              {state.matches('awaitingForTask') ||
              state.matches('awaitingForAutomaticTask') ? (
                <AutoAssignTaskRouter
                  {...(awaitingTaskId ? { taskId: awaitingTaskId } : {})}
                  setTask={setTask}
                  needAutoAssign={state.matches('awaitingForAutomaticTask')}
                  backToMenu={backToMenu}
                />
              ) : null}

              {state.matches('inTask') ? InTaskView : null}

              <GenericOptions />
            </Ink.Box>
          )}
        </RootMachineProvider>
      </TaskContext.Provider>
    </ApiProvider>
  );
};

Main.displayName = 'Main';

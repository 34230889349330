import { MopSorterExitItem, Package, SortingItem } from '@wms/domain';
import { createMachine } from 'xstate';

import { getSortableBulkByLabel } from '../../layer-4/label/get-item-by-label/fetchers/get-item-by-label';
import {
  GetItemByLabelMachine,
  GetItemByLabelMachineId
} from '../../layer-4/label/get-item-by-label/machine';
import { labelDetailActions } from './actions';
import { labelDetailGuards } from './guards';
import { labelDetailServices } from './services';

export type Item = SortingItem | Package | MopSorterExitItem;

export interface LabelDetailContext {
  label: string | null;
  entity: string | null;
  items: any[] | null;
  error: string | null;
}

export const DefaultLabelDetailContext: LabelDetailContext = {
  label:  null,
  entity: null,
  items:  null,
  error:  null
};

export const LabelDetailMachine = createMachine(
  {
    id:     'LabelDetail',
    schema: {
      context: {} as LabelDetailContext
    },
    context: DefaultLabelDetailContext,
    initial: 'ScanLabel',
    states:  {
      ScanLabel: {
        invoke: {
          id:  GetItemByLabelMachineId,
          src: _ctx =>
            GetItemByLabelMachine(
              'Escanee etiqueta',
              getSortableBulkByLabel(
                'Ocurrió un error, por favor reintente más tarde.'
              ),
              {
                canScanBuckets: true
              }
            ),
          onDone: [
            {
              cond:    'entityTypeIsNull',
              actions: ['assignEmptyTypeError', 'restartValues'],
              target:  'ScanLabel'
            },
            {
              actions: ['assignEntity', 'clearError'],
              target:  'FetchDetail'
            }
          ],
          onError: {
            actions: 'assignError'
          }
        },
        on: {
          clearError: {
            actions: 'clearError'
          }
        }
      },
      FetchDetail: {
        invoke: {
          src:    'fetchItem',
          onDone: {
            actions: 'assignItem',
            target:  'Detail'
          },
          onError: {
            target:  'ScanLabel',
            actions: ['assignError', 'restartValues']
          }
        }
      },
      Detail: {
        on: {
          rescan: {
            target:  'ScanLabel',
            actions: 'restartValues'
          }
        }
      }
    }
  },
  {
    actions:  labelDetailActions,
    services: labelDetailServices,
    guards:   labelDetailGuards
  }
);

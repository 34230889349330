import { NoveltyMoveContainerToBufferContainerStatuses } from '../NoveltyMoveContainerToBufferContainer';
import { NoveltyMoveContainerToBufferContainerStatus } from './NoveltyMoveContainerToBufferContainerStatus';

export class NoveltyMoveContainerToBufferContainerStatusMovedStatus extends NoveltyMoveContainerToBufferContainerStatus {
  public displayName = 'Movido';

  public internalName = NoveltyMoveContainerToBufferContainerStatuses.Moved;

  public statusColor = '#Ffffff';
}

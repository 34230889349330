import { Entity, Field, Needs, PrimaryKey } from '@skyframe/core';
import { Warehouse } from './Warehouse';

@Entity()
export class CAI {
  @PrimaryKey()
  id: number;

  @Field({ name: 'cai_number' })
  caiNumber: number;

  @Field({ name: 'from_date' })
  fromDate: Date;

  @Field({ name: 'due_date' })
  dueDate: Date;

  @Field({ name: 'selling_point' })
  sellingPoint: number;

  @Field({ name: 'created_at' })
  createdAt: Date;

  @Field({ name: 'updated_at' })
  updatedAt: Date;

  @Field({ name: 'deleted_at' })
  deletedAt: Date;

  @Needs(() => Warehouse, 'warehouseId')
  warehouse: Warehouse;

  @Field({ name: 'warehouse_id' })
  warehouseId: number;
}

import Ink from '@ink';
import { useActor } from '@xstate/react';
import React from 'react';
import { ErrorMessage } from '../../../shared/ErrorMessage';
import { HighlightedInfo } from '../../../shared/HighlightedInfo';
import { IntercalatedText } from '../../../shared/IntercalatedText';
import { withActorRef } from '../../../shared/Machine';
import { GetItemByLabel } from '../../layer-4/label/get-item-by-label/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { DispatchHDMachine } from './machine';

export const DispatchHDTask = withActorRef(DispatchHDMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    const shouldRenderStagingLocationInfo =
      !current.hasTag('loading') &&
      !current.matches('ScanningCheckDigit') &&
      !current.matches('ConfirmingDispatch');

    const shouldRenderSeparatorNewLine =
      !current.hasTag('loading') && !current.matches('ConfirmingDispatch');

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        {/* Cargando */}
        <>
          {current.hasTag('loading') ? (
            <Ink.Box flexDirection='column'>
              <Ink.Header>
                Despacho N°{current.context.dispatchingWaveId} - Cargando...
              </Ink.Header>
              <Ink.Text>Cargando...</Ink.Text>
            </Ink.Box>
          ) : (
            <Ink.Header>
              Despacho HD N°{current.context.dispatchingWaveId} - OS N°{' '}
              {current.context.orderId ||
                current.context.dispatchingWave?.dispatchingProcess
                  ?.orderBatchId ||
                'X'}{' '}
              - {current.context.dispatchingWave?.staging?.name || ''}
            </Ink.Header>
          )}
        </>

        {/* Header */}
        <>
          {current.matches('ScanningCheckDigit') && (
            <Ink.Box flexDirection='column'>
              <IntercalatedText
                texts={['Trasladese a muelle', 'canal']}
                values={[
                  `${
                    current.context.dispatchingWave?.staging.parent.name || ''
                  },`,
                  current.context.dispatchingWave?.staging.name || ''
                ]}
              />
            </Ink.Box>
          )}

          {shouldRenderStagingLocationInfo ? (
            <HighlightedInfo
              attribute='Canal'
              value={current.context.stagingName ?? ''}
            />
          ) : null}

          {shouldRenderSeparatorNewLine ? <Ink.Text> </Ink.Text> : null}

          {current.matches('ScanningDispatchingItem') && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Escanee bulto</Ink.Text>
              <Ink.Text>
                {current.context.pickedItems} de {current.context.totalItems}{' '}
                bultos despachados
              </Ink.Text>
              <HighlightedInfo
                attribute='Bulto sugerido'
                value={current.context.suggestedDispatchingItem?.label ?? ''}
              />
            </Ink.Box>
          )}

          {current.matches({
            PartiallyDispatching: {
              UnloadingPartiallyDispatchedReservations:
                'ScanningDispatchingItemToUnload'
            }
          }) && (
            <Ink.Box flexDirection='column'>
              <Ink.Text>Escanee bulto a descargar</Ink.Text>
              <HighlightedInfo
                attribute='Bulto sugerido'
                value={current.context.suggestedDispatchingItem?.label ?? ''}
              />
              <Ink.Box flexDirection='column'>
                <GetItemByLabel id='ScanningDispatchingItemToUnload' />
              </Ink.Box>

              {current.context.error && (
                <ErrorMessage bold error={current.context.error} />
              )}
            </Ink.Box>
          )}

          {current.matches('ConfirmingDispatch') && (
            <Ink.Box flexDirection='column'>
              <IntercalatedText
                texts={['Carga en canal', 'completada.']}
                values={[current.context.dispatchingWave?.staging.name || '']}
              />
              <Ink.Text>
                {current.context.pickedItems} de {current.context.totalItems}{' '}
                bultos despachados.
              </Ink.Text>
            </Ink.Box>
          )}

          {current.matches({
            PartiallyDispatching: 'AskingToUnloadOrLoad'
          }) && (
            <Ink.Box flexDirection='column'>
              <IntercalatedText
                texts={['Se han despachado', 'reservas parcialmente']}
                values={[current.context.partiallyDispatchedReservations]}
              />
              <HighlightedInfo
                attribute='Descargar bultos'
                value={
                  current.context.labelsToUnload.length > 0
                    ? current.context.labelsToUnload.join(' | ')
                    : 'Ninguno'
                }
              />
              <HighlightedInfo
                attribute='Bultos no despachados'
                value={current.context.labelsToLoad.join(' | ')}
              />
            </Ink.Box>
          )}
        </>

        {/* Body */}
        <>
          {current.matches('ScanningCheckDigit') && (
            <Ink.Box flexDirection='column'>
              <GetLocationByCheckDigit />
              {current.context.error && (
                <ErrorMessage bold error={current.context.error} />
              )}
            </Ink.Box>
          )}

          {current.matches('ScanningDispatchingItem') && (
            <Ink.Box flexDirection='column'>
              <GetItemByLabel id='ScanningDispatchingItem' />

              {current.context.error && (
                <ErrorMessage bold error={current.context.error} />
              )}
            </Ink.Box>
          )}

          {current.matches('ConfirmingDispatch') && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                onClick={() => {
                  send('confirm');
                }}
                label='Finalizar proceso de despacho'
              />
            </Ink.Box>
          )}

          {current.matches({
            PartiallyDispatching: 'AskingToUnloadOrLoad'
          }) && (
            <Ink.Box flexDirection='column'>
              <Ink.Button
                onClick={() => {
                  send('unload');
                }}
                label='Confirmar'
              />
            </Ink.Box>
          )}
        </>
      </Ink.Box>
    );
  }
);

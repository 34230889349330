import Ink from '@ink';
import { useActor } from '@xstate/react';
import moment from 'moment';
import React from 'react';
import { withActorRef } from '../../../shared/Machine';
import { EnterLpn } from '../../capa-4/enter-lpn/EnterLpn';
import { GetContainerByLpn } from '../../layer-4/container/get-container-by-lpn/component';
import { GetLocationByCheckDigit } from '../../layer-4/location/get-location-by-check-digit/component';
import { GetSellerProductByCode } from '../../layer-4/seller-product/get-seller-product-by-code/component';
import { UnloadingTaskMachine } from './machine';

export const UnloadingTask = withActorRef(UnloadingTaskMachine)(
  ({ actorRef }) => {
    const [current, send] = useActor(actorRef);

    return (
      <Ink.Box flexDirection='column' paddingX={1}>
        <Ink.Header>
          <Ink.Text bold>
            ORDEN DE ENTRADA #{current.context.task.receiptId}
          </Ink.Text>
        </Ink.Header>

        {current.hasTag('loading') && (
          <Ink.Box flexDirection='column'>
            <Ink.Text>Cargando... </Ink.Text>
          </Ink.Box>
        )}

        {current.matches('EnteringCheckDigitScreen') && (
          <>
            <Ink.Text bold>Ingrese Dígito de control del Muelle:</Ink.Text>
          </>
        )}

        {current.matches('EnteringLpn') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Creando contenedor' +
                ' ' +
                (current.context.totalContainersToCreate -
                  current.context.pendingContainersToCreate +
                  1) +
                '/' +
                current.context.totalContainersToCreate}
            </Ink.Chalk>

            <Ink.Text bold>
              Cree contenedor del tipo&nbsp;
              <Ink.Chalk greenBright>PALLET ARLOG</Ink.Chalk>
            </Ink.Text>
            <Ink.Chalk greenBright bold>
              {current.context.currentContainer.sortable
                ? 'SORTEABLE'
                : 'NO SORTEABLE - ' +
                  moment(current.context.currentContainer?.expectedDate).format(
                    'DD/MM/YYYY'
                  )}
            </Ink.Chalk>
          </>
        )}

        {current.matches('ScanUnloadingItem') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Bulto' +
                ' ' +
                (current.context.unloadedUnloadingItems + 1) +
                ' de ' +
                current.context.totalUnloadingItems}
            </Ink.Chalk>
            <Ink.Text bold>Escanee Código de bulto </Ink.Text>
            <Ink.Chalk greenBright bold>
              {'CB Sugerido:' +
                ' ' +
                current.context.suggestedUnloadingItemCode}
            </Ink.Chalk>
          </>
        )}

        {current.matches('ScanContainer') && (
          <>
            <Ink.Chalk greenBright bold>
              {'Bulto' +
                ' ' +
                (current.context.unloadedUnloadingItems + 1) +
                ' de ' +
                current.context.totalUnloadingItems}
            </Ink.Chalk>
            <Ink.Text bold>Descargue en contenedor </Ink.Text>
            <Ink.Chalk greenBright bold>
              {current.context.suggestedContainerToUnload?.sortable
                ? 'SORTEABLE'
                : 'NO SORTEABLE - ' +
                  moment(
                    current.context.suggestedContainerToUnload?.expectedDate
                  ).format('DD/MM/YYYY')}
            </Ink.Chalk>

            <Ink.Chalk greenBright bold>
              {'Sugerido:' +
                ' ' +
                current.context.suggestedContainerToUnload?.container?.lpn}
            </Ink.Chalk>
          </>
        )}

        {/* Below separator */}

        {current.matches('EnteringCheckDigitScreen') && (
          <GetLocationByCheckDigit />
        )}

        {current.matches('EnteringLpn') && <EnterLpn />}

        {current.matches('ScanUnloadingItem') && <GetSellerProductByCode />}

        {current.matches('ScanContainer') && <GetContainerByLpn />}

        {current.matches('ContainerErrorScreen') && (
          <>
            <Ink.Text color='redBright'>{current.context.error}</Ink.Text>
            <Ink.Box
              flexDirection='row'
              justifyContent='space-between'
              paddingLeft={1}
            >
              <Ink.Button
                style={{ width: '15%' }}
                onClick={() => {
                  send('back');
                }}
                label='Volver'
              />
            </Ink.Box>
          </>
        )}

        {current.matches('ConfirmingUnloadingScreen') && (
          <>
            <Ink.Text bold>Todos los bultos han sido recepcionados</Ink.Text>
            <Ink.Text bold>¿Confirma cierre?</Ink.Text>
            <Ink.Box
              flexDirection='row'
              justifyContent='space-between'
              paddingLeft={1}
            >
              <Ink.Button
                style={{ width: '15%' }}
                onClick={() => {
                  send('confirm');
                }}
                label='Si'
              />
            </Ink.Box>
          </>
        )}
      </Ink.Box>
    );
  }
);

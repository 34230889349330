import { MovingToSorterExitProcessStatuses } from '../MovingToSorterExitProcess';
import { MovingToSorterExitProcessStatus } from './MovingToSorterExitProcessStatus';

export class MovingToSorterExitProcessStatusPendingStatus extends MovingToSorterExitProcessStatus {
  public displayName = 'Pendiente';

  public internalName = MovingToSorterExitProcessStatuses.Pending;

  public statusColor = '#fbf9da';

  public textColor = '#ffa300';
}
